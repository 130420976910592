import React, {
    DetailedHTMLProps,
    FC,
    InputHTMLAttributes,
    ReactNode,
    Ref,
    useCallback,
    useContext,
    useState,
} from "react";
import Inputmask from "inputmask";
import { ThemeContext } from "styled-components";
import { RED } from "~assets/styles/tokens/colors";
import { composeRefs } from "~helpers/composeRefs";
import { useInputMask } from "./hooks/useInputMask";
import { getFormattedString, getMaskOptions, TMaskVariant } from "./masks";
import { InputStyled, InputWrapper, LabelStyled, PrefixWrapper, SuffixWrapper, TextStyled } from "./theme";

export type TRef = Ref<HTMLInputElement>;

type TInputProps = {
    className?: string;
    label?: ReactNode;
    id?: string;
    error?: boolean;
    active?: boolean;
    errorText?: ReactNode;
    helperText?: ReactNode;
    innerRef?: TRef;
    typeMask?: TMaskVariant;
    maskOptions?: Inputmask.Options;
    prefixIcon?: ReactNode;
    suffixIcon?: ReactNode;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input: FC<TInputProps> & { getFormattedString: typeof getFormattedString } = ({
    className,
    label,
    id,
    innerRef,
    onFocus,
    onChange,
    onBlur,
    onKeyPress,
    value,
    disabled = false,
    typeMask = "none",
    maskOptions,
    error = false,
    active = false,
    errorText,
    suffixIcon,
    prefixIcon,
    helperText,
    ...props
}: TInputProps) => {
    const { color } = useContext(ThemeContext);
    const optionsMask = maskOptions || getMaskOptions(typeMask);
    const ref = useInputMask("", optionsMask);

    const [isFocused, setFocused] = useState(false);
    const [isActive, setSetActive] = useState(active);
    const [valueLocal, setLocalValue] = useState(value);
    const resultValue = onChange ? value || "" : valueLocal;
    const isError = (error || Boolean(errorText)) && !disabled;

    const handleFocus = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (disabled) return;
            setFocused(true);
            onFocus && onFocus(e);
        },
        [onFocus, disabled],
    );

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            if (disabled) return;
            setFocused(false);
            setSetActive(false);
            onBlur && onBlur(e);
        },
        [onBlur, disabled],
    );

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (disabled) return;
            setSetActive(true);
            if (onChange) {
                onChange(e);
            } else {
                setLocalValue(e.target.value);
            }
        },
        [onChange, disabled],
    );

    const handlePaste = useCallback(
        (e: React.ClipboardEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
            if (disabled) return;
            setSetActive(true);
            if (onChange) {
                onChange(e as React.ChangeEvent<HTMLInputElement>);
            } else {
                setLocalValue(e.currentTarget.value);
            }
        },
        [onChange, disabled],
    );

    const handlePressEnter = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (disabled) return;
            setSetActive(true);
            if (e.key === "Enter") {
                onKeyPress && onKeyPress(e);
            }
        },
        [onKeyPress, disabled],
    );

    return (
        <div className={className}>
            <InputWrapper isError={isError} isActive={!isError && isActive} isDisabled={disabled} isFocused={isFocused}>
                {prefixIcon && <PrefixWrapper>{prefixIcon}</PrefixWrapper>}
                {label && (
                    <LabelStyled htmlFor={id} isError={isError}>
                        {label}
                    </LabelStyled>
                )}
                <InputStyled
                    {...props}
                    ref={composeRefs(innerRef, ref)}
                    id={id}
                    value={resultValue}
                    disabled={disabled}
                    onChange={handleChange}
                    onKeyPress={handlePressEnter}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onPaste={handlePaste}
                    suf={!!suffixIcon}
                    pref={!!prefixIcon}
                />
                {suffixIcon && <SuffixWrapper>{suffixIcon}</SuffixWrapper>}
            </InputWrapper>
            {errorText && (
                <TextStyled size="text14" weight="medium" color={RED}>
                    {errorText}
                </TextStyled>
            )}
            {helperText && (
                <TextStyled size="text14" weight="medium" color={color.text.tertiary}>
                    {helperText}
                </TextStyled>
            )}
        </div>
    );
};

Input.getFormattedString = getFormattedString;

export default Input;
