import styled from "styled-components";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { isIos } from "~helpers/utils";

export const PaymentWrapper = styled.div`
    padding: ${INDENT.xs};
    border-radius: 20px;
    background: ${WHITE};
    .payment-input {
        width: 100%;
    }
    & .payment-button {
        width: 164px;
        ${adaptive.maxWidth.tablet} {
            width: 100%;
        }
    }
    & .button-apple-pay {
        display: ${isIos() ? "block" : "none"};
        & .prefix-wrap {
            width: auto;
        }
    }

    ${adaptive.minWidth.tablet} {
        .payment-input {
            max-width: 342px;
        }
        padding: ${INDENT.m};
    }
    ${adaptive.minWidth.desktopXL} {
        padding: ${INDENT.sm};
    }
    ${adaptive.minWidth.desktopXXL} {
        padding: ${INDENT.m};
    }
`;
