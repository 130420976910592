import React, { memo, useCallback, useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Hamburger } from "~atoms/hamburger";
import { TextAdaptive } from "~atoms/text-adaptive";
import { ButtonsHeader } from "~organisms/header/buttons-header";
import { NavigationHeader } from "~organisms/header/navigation-header";
import {
    HamburgerMenu,
    HeaderBackground,
    HeaderContent,
    HeaderMainContent,
    HeaderWrapper,
    LinkLogo,
} from "~organisms/header/theme";
import { ProfileSidebar } from "~organisms/profile-sidebar";
import { Sidebar } from "~organisms/sidebar";
import { HOME } from "~constants/routes";
import { useAppScreen } from "~hooks/useAppScreen";

type THeaderProps = {
    className?: string;
};

const Header = ({ className }: THeaderProps) => {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [profileBarOpen, setProfileBarOpen] = useState<boolean>(false);
    const typeScreen = useAppScreen();
    const { color } = useContext(ThemeContext);

    const handleSidebarClose = useCallback(() => {
        setSidebarOpen(false);
    }, []);

    const handleSidebarOpen = useCallback(() => {
        setSidebarOpen(true);
    }, []);

    const handleProfileSidebarOpen = useCallback(() => {
        setProfileBarOpen(true);
        setSidebarOpen(false);
    }, []);

    const handleProfileSidebarClose = useCallback(() => {
        setProfileBarOpen(false);
    }, []);

    return (
        <HeaderWrapper className={className}>
            <HeaderBackground>
                <HeaderMainContent>
                    <HamburgerMenu>
                        <Hamburger
                            className={"hamburger-button"}
                            active={sidebarOpen}
                            onClose={handleSidebarClose}
                            onOpen={handleSidebarOpen}
                        />
                        <Sidebar
                            active={sidebarOpen && !typeScreen.startsWith("desktop")}
                            onClose={handleSidebarClose}
                        />
                    </HamburgerMenu>
                    <LinkLogo href={HOME} rel="home" aria-label="На главную Национальная лотерея">
                        {/*<img src={logo} alt="Логотип Национальная лотерея" />*/}
                        <div className="logo-header">
                            <div className="logo-icon" />
                            <div className="logo-name" />
                            <div className="logo-description">
                                <TextAdaptive color={color.const.gsSolid["400"]}>
                                    в партнёрстве <br />с Национальными лотереями
                                </TextAdaptive>
                            </div>
                        </div>
                    </LinkLogo>
                    <HeaderContent className="ml64">
                        <NavigationHeader className={"header-nav"} />
                        <ButtonsHeader className={"actions-controls"} onProfileBarOpen={handleProfileSidebarOpen} />
                    </HeaderContent>
                    <ProfileSidebar
                        active={profileBarOpen && !typeScreen.startsWith("desktop")}
                        onClose={handleProfileSidebarClose}
                    />
                </HeaderMainContent>
            </HeaderBackground>
        </HeaderWrapper>
    );
};

export default memo(Header);
