import { useCallback, useEffect } from "react";
import { TMyTicketsQuery } from "~types/my-tickets";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";
import { useFetch } from "~hooks/useFetch";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

export const useGetTicketsFuture = ({ page, size = 20 }: TMyTicketsQuery) => {
    const { handleFieldChange } = useMutationHandles("myTickets");
    const api = useAppApi();
    const { add } = useNotifications();
    const store = useAppStore();

    const getTickets = useCallback(async () => {
        return await api.myTickets.getTicketsFuture({ page, size });
    }, [api.myTickets, page, size]);

    const { error, loading, data } = useFetch(getTickets);

    useEffect(() => {
        if (error) {
            add({ message: error?.data?.message ?? otherErrors.getMyTickets });
        }
        if (data) {
            const content = store.getState().myTickets.future.content || [];
            const parsedData = { pagination: data.pagination, content: [...content, ...data.content] };
            handleFieldChange("future", parsedData);
        }
    }, [data, error, add, store, handleFieldChange]);

    return { isLoading: loading, isError: !!error, data };
};
