import styled from "styled-components";
import { PRIMARY_BLUE, WHITE } from "~tokens/colors";
import { fontSize20, fontWeight500 } from "~tokens/typography";

export const MapsWrapper = styled.div`
    display: block;
    position: relative;
    padding-bottom: 60%;
    height: 0;
    width: 100%;

    .map-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const ClusterStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${WHITE};
    ${fontSize20};
    ${fontWeight500};
    width: 50px;
    height: 50px;
    background: ${PRIMARY_BLUE};
    border-radius: 50%;
`;
