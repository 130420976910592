import styled, { keyframes } from "styled-components";
import { adaptive } from "~assets/styles/tokens/screen";
import { transitionDefault } from "~assets/styles/utils";
import { WHITE } from "~tokens/colors";
import { shadowScrollTopBtn, shadowScrollTopBtnActive } from "~tokens/shadows";
import { INDENT } from "~tokens/sizes";

const fadeInAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

export const ScrollTopButtonStyled = styled.button<{ visible: boolean }>`
    opacity: ${({ visible }) => (visible ? "1" : "0")};
    visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background-color: ${WHITE};
    ${shadowScrollTopBtn};
    ${transitionDefault};
    animation: ${fadeInAnimation} 0.3s;
    z-index: 990;
    position: fixed;
    right: ${INDENT.m};
    bottom: ${INDENT.m};
    border-radius: 50%;

    &:hover {
        cursor: pointer;
        ${shadowScrollTopBtnActive};
    }

    ${adaptive.maxWidth.desktopXL} {
        display: none;
    }
`;
