import styled from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Skeleton } from "~atoms/skeleton";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

const HistoryWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    padding: ${INDENT.m};
    background-color: ${WHITE};
    border-radius: 20px;
    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm};
        flex-basis: auto;
    }
    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs};
    }
`;

const HistoryBlock = styled.div`
    &:not(:last-child) {
        margin-bottom: ${INDENT.m};
    }
    ${adaptive.maxWidth.desktopXXL} {
        &:not(:last-child) {
            margin-bottom: ${INDENT.sm};
        }
    }
    ${adaptive.maxWidth.tablet} {
        &:not(:last-child) {
            margin-bottom: ${INDENT.xs};
        }
    }
`;

const HistoryContent = styled.div``;

const ButtonStyled = styled(ButtonAdaptive)<{ visible: boolean }>`
    width: fit-content;
    display: ${({ visible }) => (visible ? "flex" : "none")};
`;

const SkeletonStyled = styled(Skeleton)`
    height: 80px;
    border-radius: 20px;
    width: 100%;
    ${adaptive.maxWidth.tablet} {
        height: 330px;
    }
`;

const SkeletonWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

export { ButtonStyled, HistoryWrapper, SkeletonStyled, SkeletonWrapper, HistoryBlock, HistoryContent };
