import { Ref, MutableRefObject } from "react";
import { isDefined } from "./isDefined";

type RefCallback<T> = (instance: T | null) => void;

// Утилита позволяет прокинуть в ref элемента несколько ref-объектов.
// Например, когда нужно достучаться по ref снаружи компонента и внутри него одновременно, когда есть 2 ref-ссылки на один элемент
// т.к. внешний ref опционален типы разрешают потенциольно undefined значения
export const composeRefs = <T extends HTMLElement>(...refs: (Ref<T> | undefined)[]): RefCallback<T> | null => {
    const filteredRefs = refs.filter(isDefined);

    if (!filteredRefs.length) return null;

    return (instance) => {
        filteredRefs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(instance);
            } else if (typeof ref === "object") {
                const mutableRef = ref as MutableRefObject<typeof instance>;
                mutableRef.current = instance;
            }
        });
    };
};
