import React from "react";
import { TBingoTypes } from "~store/tickets/types";
import PaymentBlockView from "./payment-block-view";
import { usePaymentBlockBingoLogic } from "../_hooks/usePaymentBlockBingoLogic";

type TPaymentBlockBingoProps = {
    className?: string;
    paymentId: string;
    paymentToken: string;
    ticketType: TBingoTypes;
    onClickClose: () => void;
    handleWidgetClose: () => void;
};

const PaymentBlockBingo = ({ className, ticketType, onClickClose, handleWidgetClose }: TPaymentBlockBingoProps) => {
    const { handleWalletPayment, handleCardPayment, handleApplePayPayment } = usePaymentBlockBingoLogic(
        onClickClose,
        handleWidgetClose,
        ticketType,
    );
    return (
        <PaymentBlockView
            handleWalletPayment={handleWalletPayment}
            handleCardPayment={handleCardPayment}
            handleApplePayPayment={handleApplePayPayment}
            className={className}
        />
    );
};

export default PaymentBlockBingo;
