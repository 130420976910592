import React from "react";
import { Box, Flex } from "~atoms/flex-box";
import { useAppSelector } from "~hooks/useAppSelector";
import { BalanceBox } from "./balance-box/";
import { BalanceWrapper } from "./theme";

type TBalanceProps = {
    className?: string;
};

const Balance = ({ className }: TBalanceProps) => {
    const balance = useAppSelector((state) => state.profile.data?.balance);
    // const autoPayout = useAppSelector((state) => state.profile.data?.winningsAutoPayout);
    return (
        <BalanceWrapper className={className}>
            <Flex mobile={{ $direction: "column" }}>
                <Box flexGrow={1}>
                    {/*<BalanceBox balance={balance} className="mr32 mr24--desktopxl mr0--tablet mb32--mobile" />*/}
                    <BalanceBox balance={balance} />
                </Box>
                {/*<Box flexGrow={1} tablet={{ flexGrow: 0, flexShrink: 2 }}>*/}
                {/*    <AutoPayout className="ml32 ml24--desktopxl ml0--tablet" value={autoPayout} />*/}
                {/*</Box>*/}
            </Flex>
        </BalanceWrapper>
    );
};

export default Balance;
