import React from "react";
import { getDateFromISO8601, getTicketNumber } from "~helpers/format";
import { TAlias } from "~types/products";
import { LinkStyled } from "./theme";
import { lotteryTypes } from "../helpers";

type TDate = {
    ticketId: string;
    buyDate: string;
    name: string;
    drawNum: string;
    drawDate: string;
    produtId: string;
    alias: TAlias;
    amount?: number;
    isFutureTicket: boolean;
};

export const getTicketBodyData = ({
    ticketId,
    amount,
    buyDate,
    name,
    drawNum,
    drawDate,
    produtId,
    alias,
    isFutureTicket,
}: TDate) => {
    return [
        {
            title: "Номер билета:",
            value: getTicketNumber(ticketId),
        },
        {
            title: "Цена билета:",
            value: `${amount} ₽`,
        },
        // {
        //     title: "Размер единичной лотерейной ставки:",
        //     value: `${amount} ₽`,
        // },
        {
            title: "Дата покупки билета:",
            value: buyDate,
        },
        {
            title: "Название лотереи:",
            value: name,
        },
        {
            title: "Тираж №:",
            value: isFutureTicket ? (
                drawNum
            ) : (
                <LinkStyled to={lotteryTypes[alias].toHistoryDetails(produtId, drawNum)}>{drawNum}</LinkStyled>
            ),
        },
        {
            title: "Дата тиража:",
            value: `${getDateFromISO8601(drawDate, "D MMMM YYYY")}`,
        },
        {
            title: "Место тиража:",
            value: `г. Москва`,
        },
        {
            title: "Время тиража:",
            value: getDateFromISO8601(drawDate, "HH:mm МСК"),
        },
    ];
};
