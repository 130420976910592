import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { isUndefined } from "lodash-es";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Flex } from "~atoms/flex-box";
import { ApplePayIcon, CardIcon } from "~atoms/icons";
import { Input } from "~atoms/input";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { TipGroup } from "~molecules/tip-group";
import { usePaymentLogic } from "~pages/wallet/_hooks/usePaymentLogic";
import { validatePaymentAmount } from "~pages/wallet/validations";
import { WHITE } from "~tokens/colors";
import { getMoneyFormat } from "~helpers/format";
import { useAppSelector } from "~hooks/useAppSelector";
import { PaymentWrapper } from "./theme";
import { data } from "../helpers";

type TPaymentProps = {
    className?: string;
};

type TFormValues = {
    amount: string;
};

const Payment = ({ className }: TPaymentProps) => {
    const { handleCardPayment, handleApplePayPayment } = usePaymentLogic();
    const balance = useAppSelector((state) => state.profile.data?.balance);
    const identity = useAppSelector((state) => state.profile.data?.identity);

    const {
        control,
        handleSubmit,
        formState: { isSubmitSuccessful },
        reset,
        getValues,
        setValue,
    } = useForm<TFormValues>();

    const handleTip = useCallback(
        (value: number) => {
            const amount = Number(getValues("amount") ?? 0);
            setValue("amount", String(amount + value));
        },
        [getValues, setValue],
    );

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({ amount: "" });
        }
    }, [isSubmitSuccessful, reset]);

    return (
        <PaymentWrapper className={className}>
            <TitleAdaptive size="title24" className="mb16">
                Пополнение баланса
            </TitleAdaptive>
            <Controller
                render={({ field, fieldState }) => (
                    <Input
                        id={"payment-amount"}
                        label={"Введите сумму"}
                        typeMask={"currency"}
                        autoFocus={true}
                        name={field.name}
                        placeholder={"0 ₽"}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        className={"payment-input mb16"}
                        errorText={fieldState.error?.message}
                        error={fieldState.invalid}
                        helperText={!fieldState.invalid && `Минимальная сумма ${getMoneyFormat(100)} ₽`}
                    />
                )}
                name="amount"
                control={control}
                rules={{
                    validate: {
                        amount: validatePaymentAmount(identity),
                    },
                }}
            />
            <TipGroup data={data} onChange={handleTip} className="mb32" />
            <Flex mobile={{ $direction: "column" }}>
                <ButtonAdaptive
                    variant="black"
                    className="payment-button button-apple-pay mr16 mr0--mobile mb16--mobile"
                    prefixIcon={<ApplePayIcon />}
                    onClick={handleSubmit((data) => handleApplePayPayment(data.amount, balance!))}
                    disabled={isUndefined(balance)}
                />
                <ButtonAdaptive
                    variant="primary"
                    className="payment-button"
                    prefixIcon={<CardIcon />}
                    onClick={handleSubmit((data) => handleCardPayment(data.amount, balance!))}
                    disabled={isUndefined(balance)}
                >
                    <TextAdaptive size="text16" weight="demiBold" color={WHITE}>
                        Карта
                    </TextAdaptive>
                </ButtonAdaptive>
            </Flex>
        </PaymentWrapper>
    );
};

export default Payment;
