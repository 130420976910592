import React from "react";
import styled from "styled-components";
import { AgreementList } from "./agreement-list";

const PoliticsWrapper = styled.div`
    display: block;
`;

const Politics = () => {
    return (
        <PoliticsWrapper>
            <AgreementList className="mt48 container-app" isShowTabGroup={false} />
        </PoliticsWrapper>
    );
};

export default Politics;
