import React from "react";
import styled from "styled-components";
import { MastercardIcon, MirIcon, VisaIcon } from "~atoms/icons";

type TPaymentIconsProps = {
    className?: string;
};

export const PaymentIconsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
`;

const PaymentIcons = ({ className }: TPaymentIconsProps) => {
    return (
        <PaymentIconsWrapper className={className}>
            <VisaIcon />
            <MastercardIcon />
            <MirIcon />
        </PaymentIconsWrapper>
    );
};

export default PaymentIcons;
