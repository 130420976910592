import { useCallback } from "react";
import { TBingo, TBingoTypes, TEdition } from "~store/tickets/types";
import { TBingoAlias } from "~types/products";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

type TUseGetBingoTickets = {
    alias: TBingoAlias;
    ticketType: TBingoTypes;
    size: number;
};

type TChangesArray = {
    path: string;
    value: string | number | null | boolean | undefined | TEdition[] | TBingo[];
};

export const useInitTickets = ({ alias, ticketType, size }: TUseGetBingoTickets) => {
    const { handleFieldManyChange } = useMutationHandles("tickets");
    const { products } = useAppApi();
    const { add } = useNotifications();

    const initTickets = useCallback(async () => {
        const changesArray: TChangesArray[] = [];

        const { result: fetchedProduct, error: errorFetchedProduct } = await products.getProductByAlias(alias);
        if (errorFetchedProduct || fetchedProduct === null) {
            add({ message: errorFetchedProduct?.data?.message ?? otherErrors.getProduct });
            return null;
        }
        const productId = fetchedProduct.productId;
        const jackpot = fetchedProduct.draw?.jackpot;
        const logoSrc = fetchedProduct.pictures.find((pic) => pic.type === "ICON_BIG")?.url || "";
        const name = fetchedProduct.name;

        changesArray.push(
            { path: `${ticketType}.productId`, value: productId },
            { path: `${ticketType}.jackpot`, value: jackpot },
            {
                path: `${ticketType}.name`,
                value: name,
            },
            {
                path: `${ticketType}.logoSrc`,
                value: logoSrc,
            },
        );

        const { result: editions, error: editionsError } = await products.getDraws(productId);
        if (editionsError) {
            add({ message: editionsError?.data?.message ?? otherErrors.getDraw });
            return null;
        }

        changesArray.push(
            { path: `${ticketType}.editions`, value: editions },
            { path: `${ticketType}.editionsSelected`, value: editions[0] ? [editions[0]] : [] },
        );

        const { result: ticketsData, error: ticketsError } = await products.getBingoTickets(alias, productId, size);

        if (ticketsError) {
            add({ message: ticketsError?.data?.message ?? otherErrors.getProducts });
            return null;
        }

        if (ticketsData?.length) {
            changesArray.push(
                { path: `${ticketType}.data`, value: ticketsData },
                { path: `${ticketType}.initTicket`, value: true },
            );
        }

        handleFieldManyChange(changesArray);
    }, [add, alias, handleFieldManyChange, products, size, ticketType]);

    return { initTickets };
};
