import styled from "styled-components";
import { adaptive } from "~tokens/screen";

export type TFlex = {
    $direction?: "row" | "column" | "column-reverse";
    justify?: "space-between" | "center" | "space-around" | "flex-start" | "flex-end" | "unset";
    alignItems?: "center" | "stretch" | "flex-start" | "flex-end";
    flexWrap?: "nowrap" | "wrap" | "wrap-reverse" | "unset";
    flexBasis?: string;
    flexGrow?: number;
    desktopXL?: TFlex;
    tablet?: TFlex;
    mobile?: TFlex;
};

export type TBox = {
    flexBasis?: string;
    flexGrow?: number;
    flexShrink?: number;
    desktopXL?: TBox;
    tablet?: TBox;
    mobile?: TBox;
};

export const Flex = styled.div<TFlex>`
    display: flex;
    width: 100%;
    flex-direction: ${({ $direction = "row" }) => $direction};
    justify-content: ${({ justify = "flex-start" }) => justify};
    align-items: ${({ alignItems = "flex-start" }) => alignItems};
    flex-wrap: ${({ flexWrap = "nowrap" }) => flexWrap};
    flex-grow: ${({ flexGrow = 0 }) => flexGrow};

    ${adaptive.maxWidth.desktopXXL} {
        display: flex;
        width: 100%;
        flex-direction: ${({ desktopXL }) => desktopXL?.$direction};
        justify-content: ${({ desktopXL }) => desktopXL?.justify};
        align-items: ${({ desktopXL }) => desktopXL?.alignItems};
        flex-basis: ${({ desktopXL }) => desktopXL?.flexBasis};
        flex-grow: ${({ desktopXL }) => desktopXL?.flexGrow};
        flex-wrap: ${({ desktopXL }) => desktopXL?.flexWrap};
    }

    ${adaptive.maxWidth.desktopXL} {
        display: flex;
        width: 100%;
        flex-direction: ${({ tablet }) => tablet?.$direction};
        justify-content: ${({ tablet }) => tablet?.justify};
        align-items: ${({ tablet }) => tablet?.alignItems};
        flex-basis: ${({ tablet }) => tablet?.flexBasis};
        flex-grow: ${({ tablet }) => tablet?.flexGrow};
        flex-wrap: ${({ tablet }) => tablet?.flexWrap};
    }

    ${adaptive.maxWidth.tablet} {
        display: flex;
        width: 100%;
        flex-direction: ${({ mobile }) => mobile?.$direction};
        justify-content: ${({ mobile }) => mobile?.justify};
        align-items: ${({ mobile }) => mobile?.alignItems};
        flex-basis: ${({ mobile }) => mobile?.flexBasis};
        flex-grow: ${({ mobile }) => mobile?.flexGrow};
        flex-wrap: ${({ mobile }) => mobile?.flexWrap};
    }
`;

export const Box = styled.div<TBox>`
    flex-basis: ${({ flexBasis }) => flexBasis};
    flex-grow: ${({ flexGrow }) => flexGrow};
    flex-shrink: ${({ flexShrink }) => flexShrink};
    width: inherit;

    ${adaptive.maxWidth.desktopXXL} {
        flex-basis: ${({ desktopXL }) => desktopXL?.flexBasis};
        flex-grow: ${({ desktopXL }) => desktopXL?.flexGrow};
        flex-shrink: ${({ desktopXL }) => desktopXL?.flexShrink};
    }

    ${adaptive.maxWidth.desktopXL} {
        flex-basis: ${({ tablet }) => tablet?.flexBasis};
        flex-grow: ${({ tablet }) => tablet?.flexGrow};
        flex-shrink: ${({ tablet }) => tablet?.flexShrink};
    }

    ${adaptive.maxWidth.tablet} {
        flex-basis: ${({ mobile }) => mobile?.flexBasis};
        flex-grow: ${({ mobile }) => mobile?.flexGrow};
        flex-shrink: ${({ mobile }) => mobile?.flexShrink};
    }
`;
