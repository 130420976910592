import React, { useCallback, useContext } from "react";
import { ThemeContext } from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Text } from "~atoms/text";
// import { TextAdaptive } from "~atoms/text-adaptive";
import { TLotteryTicketProps } from "~organisms/lottery-ticket/lottery-ticket";
import { getLotteryRouteById } from "~constants/routes";
import useAppHistory from "~hooks/useAppHistory";
import {
    ShortTicketWrapper,
    TicketActionZone,
    TicketActionZoneAmount,
    TicketActionZoneEdition,
    TicketBuyZone,
    TicketDescription,
    TicketDescriptionBody,
    TicketDescriptionLogo,
    TicketDescriptionPrize,
    TicketDescriptionTitle,
} from "./theme";

type TShortTicketProps = Omit<TLotteryTicketProps, "type">;

const OtherTicket = ({
    className,
    title,
    prize,
    note,
    edition,
    time,
    amount,
    backgroundUrl,
    id,
    logoSrc,
}: TShortTicketProps) => {
    const { go } = useAppHistory();
    const { color } = useContext(ThemeContext);

    const handleClick = useCallback(() => {
        go(getLotteryRouteById(id));
    }, [go, id]);
    return (
        <ShortTicketWrapper onClick={handleClick} className={className} backgroundUrl={backgroundUrl}>
            <TicketDescription>
                <TicketDescriptionLogo src={logoSrc} alt={title} draggable={false} />
                <TicketDescriptionBody>
                    <TicketDescriptionTitle color={color.background.primaryElevated} size={3} weight={"medium"}>
                        {title}
                    </TicketDescriptionTitle>
                    <TicketDescriptionPrize color={color.background.primaryElevated} size={5} weight={"medium"}>
                        {prize}
                    </TicketDescriptionPrize>
                    <Text color={color.background.primaryElevated} size={4}>
                        {note}
                    </Text>
                </TicketDescriptionBody>
            </TicketDescription>
            <TicketActionZone>
                <div>
                    <TicketActionZoneEdition color={color.text.primary} size={4}>
                        {edition}
                    </TicketActionZoneEdition>
                    <Text size={4} color={color.text.tertiary}>
                        {time}
                    </Text>
                </div>
                <TicketBuyZone>
                    <TicketActionZoneAmount
                        size="text16"
                        color={color.text.primary}
                        weight={"bold"}
                        className={"amount-text"}
                    >
                        {amount} ₽
                    </TicketActionZoneAmount>
                    <ButtonAdaptive size={"small"} intent className={"bnt-buy"}>
                        <Text size={2} weight={"medium"} color={"inherit"}>
                            Купить
                        </Text>
                    </ButtonAdaptive>
                </TicketBuyZone>
                {/* <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <TextAdaptive color={GREY_DARK} size="text14" weight="demiBold">
                        Скоро в продаже
                    </TextAdaptive>
                </div> */}
            </TicketActionZone>
        </ShortTicketWrapper>
    );
};

export default OtherTicket;
