import React from "react";
import { fontWeightBold, fontWeight600, fontWeight500, fontWeightNormal } from "~tokens/typography";
import { TextAdaptiveWrapper } from "./theme";

export type TWeight = "normal" | "medium" | "demiBold" | "bold";

export type TTextAdaptiveSize = "text12" | "text14" | "text16" | "text20";
export type TTextAdaptiveTag = "span" | "div" | "p";

type TProps = {
    tag?: TTextAdaptiveTag;
    children: React.ReactNode;
    size?: TTextAdaptiveSize;
    weight?: TWeight;
    color?: string;
    className?: string;
};

const TextAdaptive = ({ children, tag = "p", size = "text12", color, weight = "normal", className }: TProps) => {
    const resultWeight = {
        normal: fontWeightNormal,
        demiBold: fontWeight600,
        bold: fontWeightBold,
        medium: fontWeight500,
    }[weight];
    return (
        <TextAdaptiveWrapper className={className} color={color} size={size} as={tag} weight={resultWeight}>
            {children}
        </TextAdaptiveWrapper>
    );
};

export default TextAdaptive;
