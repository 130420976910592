export const clientId = "nl-web-dev";

export const stage = {
    prod: ["nl-test5998.nationallottery.ru", "nationallottery.ru"],
    test: "online.demo.nl-dev.ru",
};

export const srcWidget = stage.prod.includes(window.location.host)
    ? "https://widget.mcplat.com"
    : "https://demo-widget.1cupis.ru";
