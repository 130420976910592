import React, { ReactNode, useMemo } from "react";
import { Box, Flex } from "~atoms/flex-box";
import { Link } from "~atoms/link";
import { Text } from "~atoms/text";
import { TextAdaptive } from "~atoms/text-adaptive";
import { Title } from "~atoms/title";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { Table } from "~pages/lottery/momentary-lottery/common/table";
import { GREY_DARK, PRIMARY_BLUE, PURPLE } from "~tokens/colors";
import { getMoneyFormat } from "~helpers/format";
import { paymentProcedure } from "~constants/links";
import { TScreen, useAppScreen } from "~hooks/useAppScreen";
import { PrizeFundWrapper, TotalText } from "./theme";

type TPrizeFundProps = {
    className?: string;
    data: Record<string, unknown>[];
    jackpot: number;
    price: number;
};

export const getCol = (typeScreen: TScreen) => {
    const col: { id: string; title: string; width?: string; getValue: (value: any) => ReactNode }[] = [
        {
            id: "sizeWin",
            title: "Размер выигрыша",
            width: typeScreen === "tablet" ? "25%" : "50%",
            getValue(value: number) {
                return (
                    <TitleAdaptive size={"title24"} tag={"span"}>
                        {getMoneyFormat(value)} ₽
                    </TitleAdaptive>
                );
            },
        },
        {
            id: "numberWin",
            title: "Кол-во выигрышей",
            width: "50%",
            getValue(value: number) {
                return (
                    <TitleAdaptive size={"title24"} tag={"span"} color={GREY_DARK}>
                        {getMoneyFormat(value)}
                    </TitleAdaptive>
                );
            },
        },
    ];

    return col;
};

export const getRow = (typeScreen: TScreen) => {
    const row: { id: string; title: string; width?: string; getValue: (value: any) => ReactNode }[] = [
        {
            id: "sizeWin",
            title: "Размер выигрыша",
            width: typeScreen === "tablet" ? "25%" : "50%",
            getValue(value: number) {
                return (
                    <Text size={"text16"} weight={"demiBold"} color={PRIMARY_BLUE}>
                        {getMoneyFormat(value)} ₽
                    </Text>
                );
            },
        },
        {
            id: "numberWin",
            title: "Кол-во выигрышей",
            width: "50%",
            getValue(value: number) {
                return getMoneyFormat(value);
            },
        },
    ];

    return row;
};

const PrizeFund = ({ className, jackpot, price, data }: TPrizeFundProps) => {
    const screen = useAppScreen();
    const col = useMemo(() => getCol(screen), [screen]);
    return (
        <PrizeFundWrapper className={className}>
            <TitleAdaptive tag={"h2"} size={"title24"} className={"mb24 mb20--tablet mb16--mobile"}>
                Призовой фонд
            </TitleAdaptive>
            <Flex justify={"space-between"} tablet={{ $direction: "column-reverse" }}>
                <Box flexBasis={"400px"} tablet={{ flexBasis: "auto" }}>
                    <Table col={col} data={data} className={"mb12"} />
                    <TextAdaptive size={"text12"} weight={"medium"}>
                        <Link href={paymentProcedure}>Порядок выплаты выигрышей в лотерею</Link>
                    </TextAdaptive>
                </Box>
                <Box flexBasis={"420px"} tablet={{ flexBasis: "auto" }} className={"mb32--mobile"}>
                    <Flex $direction={"column"} tablet={{ $direction: "row" }} mobile={{ $direction: "column" }}>
                        <Box className={"mb24 mb8--mobile mr64--tablet mr0--mobile"}>
                            <Title tag={"h4"} size={"title32"} color={PURPLE} weight={"bold"}>
                                суперприз
                            </Title>
                            <TotalText tag={"span"} color={PURPLE} weight={"bold"}>
                                {getMoneyFormat(jackpot)} ₽
                            </TotalText>
                        </Box>
                        <Box>
                            <Title tag={"h4"} size={"title32"} weight={"bold"}>
                                цена билета
                            </Title>
                            <TotalText tag={"span"} weight={"bold"}>
                                {getMoneyFormat(price)} ₽
                            </TotalText>
                        </Box>
                    </Flex>
                </Box>
            </Flex>
        </PrizeFundWrapper>
    );
};

export default PrizeFund;
