import { useCallback } from "react";
import { TBingoContent, TBingoOrderData, TBingoTypes } from "~store/tickets/types";
import { TPaymentType } from "~types/order";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";

const getFullBingoTicketsData = (ticketsData: TBingoContent): TBingoOrderData => {
    return {
        tickets: ticketsData.data.filter((item) => ticketsData.ticketsSelected.includes(item.serialNum)),
        editionsSelected: ticketsData.editionsSelected,
    };
};

export const useSendOrderBingo = (ticketType: TBingoTypes) => {
    const { order } = useAppApi();
    const store = useAppStore();
    const sendOrder = useCallback(
        async (paymentType: TPaymentType) => {
            const ticketContent = store.getState().tickets[ticketType];
            const email = store.getState().profile.data?.email;
            return await order.createBingo(
                ticketContent.productId!,
                getFullBingoTicketsData(ticketContent),
                email!,
                paymentType,
            );
        },
        [store, ticketType, order],
    );

    return { sendOrder };
};
