export const getArray = (arrValues: Array<Array<number>>, arrChecked: Array<Array<number>> | []) => {
    const isChecked = arrChecked.length && (arrChecked[0].length || arrChecked[1].length);
    const arr1 = arrValues[0].map((item) => {
        return {
            value: item,
            type: isChecked ? (arrChecked[0].find((itemX) => item === itemX) ? 3 : 2) : 1,
        };
    });
    const arr2 = arrValues[1].map((item) => {
        return {
            value: item,
            type: isChecked ? (arrChecked[1].find((itemX) => item === itemX) ? 3 : 2) : 1,
        };
    });
    return [arr1, arr2];
};
