import { useCallback } from "react";
import { useGetBalance } from "~pages/profile/_hooks/useGetBalance";
import useAppApi from "~hooks/useAppApi";

export const useGetPaymentStatus = () => {
    const { order } = useAppApi();
    const { handleGetBalance } = useGetBalance();

    return useCallback(
        async (paymentId: string) => {
            const { result, error } = await order.status(paymentId);
            if (error) {
                return null;
            }
            if (result === "ACCEPTED" || result === "ACCEPTED_MOVE") {
                await handleGetBalance();
            }

            return result;
        },
        [handleGetBalance, order],
    );
};
