import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Flex } from "~atoms/flex-box";
import { Text } from "~atoms/text";
import { getMoneyFormat } from "~helpers/format";
import quotes from "./assets/quote.svg";
import {
    CommentBox,
    CommentText,
    ContentBox,
    HistoryCardWrapper,
    LotteryEmblem,
    LotteryInfo,
    NameText,
    PrizeText,
    Quotes,
    WinnerInfo,
} from "./theme";

type THistoryCardProps = {
    comment?: string;
    name?: string;
    prize?: number;
    lottery?: string;
    emblem?: string;
    className?: string;
    background?: string;
};

const HistoryCard = ({ className, background, comment, name, prize, lottery, emblem }: THistoryCardProps) => {
    const { color } = useContext(ThemeContext);

    return (
        <HistoryCardWrapper className={className} background={background}>
            <CommentBox>
                <Quotes src={quotes} alt="quotes" className="mr8" />
                <CommentText className="comment-text">{comment}</CommentText>
            </CommentBox>
            <ContentBox>
                <NameText
                    color={color.text.inverted}
                    size="text16"
                    weight="medium"
                    tag="div"
                    className="mb8 mb4--mobile name-text"
                >
                    {name}
                </NameText>
                <Flex
                    justify="space-between"
                    alignItems="center"
                    tablet={{ $direction: "column", alignItems: "flex-start", justify: "unset" }}
                    className={"lottery-content"}
                >
                    <WinnerInfo className="mb8--tablet mb4--mobile winner-info">
                        <PrizeText color={color.text.inverted} weight="medium" tag="span">{`${getMoneyFormat(
                            prize,
                        )} ₽`}</PrizeText>
                    </WinnerInfo>
                    <LotteryInfo>
                        <LotteryEmblem className="mr4">
                            <img src={emblem} alt="emblem" />
                        </LotteryEmblem>
                        <Text size="text14" weight="medium" color={color.text.inverted}>
                            {lottery}
                        </Text>
                    </LotteryInfo>
                </Flex>
            </ContentBox>
        </HistoryCardWrapper>
    );
};

export default HistoryCard;
