import { AxiosInstance } from "axios";
import qs from "qs";
import { ApiResponse } from "~services/http/types";
import { TPoliticsReq, TPoliticsRes } from "~types/politics";

const enum URLs {
    URL_CONSENT = "/agreements",
}

export const createPoliticsApi = (http: AxiosInstance) => ({
    async getPage({ system, types }: TPoliticsReq) {
        const {
            data: { error: metaError, result: meta },
        } = await http.get<ApiResponse<TPoliticsRes>>(URLs.URL_CONSENT, {
            params: { system, types },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        });

        if (meta) {
            const {
                data: { result, error },
            } = await http.get<ApiResponse<string>>(meta[0].url, { baseURL: "/" });

            return { result, error };
        }

        return { error: metaError, result: meta };
    },
});
