import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TableRules8in20Wrapper, TableStyled, TextWrapper } from "./theme";

type TTableRules8in20Props = {
    className?: string;
};

const headers = ["Совпало в «8 из 20»", "Совпало в «1 из 4»", "Выигрыш, ₽"];

const tableInfo = [
    ["8", "1", "Суперприз¹"],
    ["8", "0", "100 000"],
    ["7", "1", "5 000"],
    ["7", "0", "1 500"],
    ["6", "1", "1 000"],
    ["6", "0", "800"],
    ["5", "1", "400"],
    ["5", "0", "150"],
    ["4", "1", "150"],
];

const TableRules8in20 = ({ className }: TTableRules8in20Props) => {
    const { color } = useContext(ThemeContext);
    return (
        <TableRules8in20Wrapper className={className}>
            <TableStyled>
                <thead>
                    <tr>
                        {headers.map((item, index) => (
                            <th key={index}>
                                <TextAdaptive size={"text16"} weight={"demiBold"} color={color.text.inverted}>
                                    {item}
                                </TextAdaptive>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableInfo.map((item, idx) => (
                        <tr key={idx}>
                            {item.map((infoItem, id) => (
                                <td key={id}>
                                    <TextWrapper>
                                        <TextAdaptive size={"text20"} weight={"demiBold"} color={color.text.inverted}>
                                            {infoItem}
                                        </TextAdaptive>
                                    </TextWrapper>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </TableStyled>
        </TableRules8in20Wrapper>
    );
};

export default TableRules8in20;
