import { docs } from "~assets/docs";
import { TProductType } from "~types/products";

export type TDocsKey = keyof typeof docs;

export const getFiles = (type: TProductType, key: TDocsKey) => {
    const files = [{ col: 6, desktopXL: 6, tablet: 6, mobile: 12, ...docs.writ }];
    switch (type) {
        case "DIGITAL":
            return [...files, { col: 6, desktopXL: 6, tablet: 6, mobile: 12, ...docs.vgl1t }];
        case "BINGO":
            return [...files, { col: 6, desktopXL: 6, tablet: 6, mobile: 12, ...docs.vgl2t }];
        case "MOMENT":
            return [{ col: 6, desktopXL: 6, tablet: 6, mobile: 12, ...docs[key] }];
        default:
            return files;
    }
};
