import styled from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const BoxWrapper = styled.div<{ isActive: boolean }>`
    width: 54px;
    height: 54px;
    background: ${({ theme, isActive }) =>
        isActive ? theme.color.const.blueberry.light : theme.color.controls.secondary.default};
    border-radius: 8px;
    margin-bottom: ${INDENT.xxxs};

    button,
    button:hover {
        span {
            color: ${({ isActive, theme }) =>
                isActive ? theme.color.text.inverted : theme.color.const.gsSolid["600"]};
        }
    }

    &:not(:nth-child(10n)) {
        margin-right: ${INDENT.xxxs};
    }
    ${adaptive.maxWidth.tablet} {
        width: 42px;
        height: 42px;
        &:not(:nth-child(10n)) {
            margin-right: ${INDENT.none};
        }
        &:not(:nth-child(5n)) {
            margin-right: ${INDENT.xxxs};
        }
    }
`;

export const ButtonNumber = styled(ButtonAdaptive)`
    padding: 0px;
    width: 100%;
    height: 100%;
`;
