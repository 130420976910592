import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Grid } from "~atoms/grid";
import { TextAdaptive } from "~atoms/text-adaptive";
import { SkeletonStyled } from "~organisms/lottery-rules-header/theme";
import { useAppScreen } from "~hooks/useAppScreen";
import { BingoRulesHeaderWrapper, TextStyled, TextWrapper } from "./theme";

type TBingoRulesHeaderProps = {
    className?: string;
    name?: string;
    type: string;
    prizeFund: string;
    ticketPrice: number | null;
    mainPrize?: string;
    frequency: string;
};

const { Row, Col } = Grid;

const BingoRulesHeader = ({ className, type, prizeFund, ticketPrice, frequency }: TBingoRulesHeaderProps) => {
    const { color } = useContext(ThemeContext);

    const typeScreen = useAppScreen();
    return (
        <BingoRulesHeaderWrapper className={className}>
            <Grid>
                <Row>
                    <Col col={3} desktopXL={2.5} tablet={6}>
                        <TextWrapper>
                            <TextAdaptive size={"text16"} color={color.text.tertiary} className={"mb8 mb4--mobile"}>
                                Тип лотереи
                            </TextAdaptive>
                            <TextStyled weight={"medium"} tag={"span"} color={color.text.primary}>
                                {type}
                            </TextStyled>
                        </TextWrapper>
                    </Col>
                    <Col col={3} desktopXL={2.5} tablet={6}>
                        <TextWrapper>
                            <TextAdaptive size={"text16"} color={color.text.tertiary} className={"mb8 mb4--mobile"}>
                                Призовой фонд
                            </TextAdaptive>
                            <TextStyled weight={"medium"} tag={"span"} color={color.text.primary}>
                                {prizeFund}
                            </TextStyled>
                        </TextWrapper>
                    </Col>
                    <Col col={3} desktopXL={4} tablet={6}>
                        <TextWrapper>
                            <TextAdaptive size={"text16"} color={color.text.tertiary} className={"mb8 mb4--mobile"}>
                                {typeScreen === "mobile" ? "Мин. цена билета" : "Минимальная цена билета"}
                            </TextAdaptive>
                            {!!ticketPrice ? (
                                <TextStyled weight={"medium"} tag={"span"} color={color.text.primary}>
                                    от {ticketPrice} ₽
                                </TextStyled>
                            ) : (
                                <SkeletonStyled />
                            )}
                        </TextWrapper>
                    </Col>
                    <Col col={3} desktopXL={3} tablet={6}>
                        <TextWrapper>
                            <TextAdaptive size={"text16"} color={color.text.tertiary} className={"mb8 mb4--mobile"}>
                                Частота тиражей
                            </TextAdaptive>
                            <TextStyled weight={"medium"} tag={"span"} color={color.text.primary}>
                                {frequency}
                            </TextStyled>
                        </TextWrapper>
                    </Col>
                </Row>
            </Grid>
        </BingoRulesHeaderWrapper>
    );
};

export default BingoRulesHeader;
