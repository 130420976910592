import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { Button } from "~atoms/button";
import { duration, SidebarMainContent } from "~organisms/sidebar/theme";
import { GREY_LIGHT, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

const sidebarTransitionName = "profile-sidebar";
const headerTransitionName = "profile-sidebar";

const ProfileSidebarContent = styled(SidebarMainContent)`
    right: 0;
    left: unset;

    & .view {
        justify-content: space-between;
    }
`;

const CSSTransitionHeader = styled(CSSTransition)`
    &.${headerTransitionName}-enter {
        opacity: 0;
        transform: translateX(100vw);
    }

    &.${headerTransitionName}-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity ${duration}ms, transform ${duration}ms;
    }

    &.${headerTransitionName}-exit {
        opacity: 1;
        transform: translateX(0);
    }

    &.${headerTransitionName}-exit-active {
        opacity: 0;
        transform: translateX(100vw);
        transition: opacity ${duration}ms, transform ${duration}ms;
    }
`;

const CSSTransitionSidebar = styled(CSSTransition)`
    &.${sidebarTransitionName}-enter {
        opacity: 0;
        transform: translateX(400px);
    }

    &.${sidebarTransitionName}-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity ${duration}ms, transform ${duration}ms;
    }

    &.${sidebarTransitionName}-exit {
        opacity: 1;
        transform: translateX(0);
    }

    &.${sidebarTransitionName}-exit-active {
        opacity: 0;
        transform: translateX(400px);
        transition: opacity ${duration}ms, transform ${duration}ms;
    }
`;

const ButtonStyled = styled(Button)`
    display: flex;
    align-items: center;
    padding: ${INDENT.none};
    & > .prefix-wrap {
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
    }
`;

const LogoutButton = styled(Button)`
    margin: ${INDENT.none};
    padding: ${INDENT.none};
    border: none;
    height: unset;
`;

const SidebarHeaderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    background: ${WHITE};
    width: 100vw;
    height: 56px;
    display: flex;
    justify-content: flex-end;

    ${adaptive.maxWidth.tablet} {
        height: 44px;
    }
`;

const SidebarHeader = styled.div`
    height: 100%;
    max-width: 376px;
    width: 100%;
    padding: ${INDENT.none} ${INDENT.sm};
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${adaptive.maxWidth.tablet} {
        width: 360px;
        padding: ${INDENT.none} ${INDENT.xs};
    }
`;

const NavigationWrapper = styled.div`
    width: 100%;

    & > nav {
        position: relative;
        padding-bottom: ${INDENT.xs};

        &::after {
            display: block;
            position: absolute;
            bottom: 0;
            content: "";
            max-width: 328px;
            width: 100%;
            height: 1px;
            background-color: ${GREY_LIGHT};
        }
    }
`;

export {
    NavigationWrapper,
    SidebarHeader,
    SidebarHeaderWrapper,
    LogoutButton,
    ButtonStyled,
    CSSTransitionSidebar,
    CSSTransitionHeader,
    ProfileSidebarContent,
    headerTransitionName,
    sidebarTransitionName,
};
