import { useEffect, useRef, useState } from "react";
import { AxiosResponse } from "axios";
import { ApiResponse } from "~services/http/types";

export function useFetch<T>(queryFn: (...args: any[]) => Promise<ApiResponse<T>>) {
    const isUnmount = useRef(false);

    const [{ data, error, loading }, setInfo] = useState<{
        data: T | null;
        error: null | AxiosResponse;
        loading: boolean;
    }>({
        data: null,
        error: null,
        loading: false,
    });

    useEffect(() => {
        (async function doFetch() {
            setInfo((prevState) => ({ ...prevState, loading: true, error: null }));
            isUnmount.current = false;
            const { result, error } = await queryFn();
            if (!isUnmount.current) {
                setInfo((prevState) => ({ ...prevState, loading: false, data: result, error: error }));
            }
        })();

        return () => {
            isUnmount.current = true;
        };
    }, [queryFn]);

    return { error, loading, data };
}
