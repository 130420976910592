import styled from "styled-components";
import { disableStyle, transitionDefault } from "~assets/styles/utils";
import { GREY_DARK, GREY_DARK2, GREY_LIGHT } from "~tokens/colors";
import { INDENT } from "~tokens/sizes";

export const TipWrapper = styled.div<{ disabled?: boolean }>`
    padding: ${INDENT.xxxxs} ${INDENT.xxxs};
    background: ${GREY_DARK2};
    width: fit-content;
    height: fit-content;
    border-radius: 100px;
    ${({ disabled }) => disabled && disableStyle}
    ${transitionDefault}
    &:hover, &:active {
        cursor: pointer;
    }
    &:hover {
        background: ${GREY_DARK};
    }
    &:active {
        background: ${GREY_LIGHT};
        & > span {
            color: ${GREY_DARK2};
        }
    }
`;
