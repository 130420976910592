import React from "react";
import styled from "styled-components";
import { pluralForm } from "~helpers/format";

const TimeContainer = styled.span`
    width: 18px;
    display: inline-block;
`;

export const getStatusTextJSX = ({
    month,
    week,
    days,
    hour,
    min,
    sec,
}: {
    month: string;
    week: string;
    hour: string;
    days: string;
    min: string;
    sec: string;
}) => {
    if (+hour > 24 && +days < 7) {
        return `Состоится через ${days} ${pluralForm(+days, "день", "дня", "дней")} ${Math.floor(
            +hour % 24,
        )} ${pluralForm(+hour % 24, "час", "часа", "часов")}`;
    }

    if (+days > 7 && +week < 4) {
        return `Состоится через ${week} ${pluralForm(+week, "неделю", "недели", "недель")} ${Math.floor(
            +days % 7,
        )} ${pluralForm(+days % 7, "день", "дня", "дней")}`;
    }
    if (+week >= 4) {
        return `Состоится через ${month} ${pluralForm(+month, "месяц", "месяца", "месяцев")}`;
    }
    return (
        <>
            Состоится через <TimeContainer>{min}</TimeContainer> мин <TimeContainer>{sec}</TimeContainer> сек
        </>
    );
};
