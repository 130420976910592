import { TLotteryState, TSidebarTicketsActions } from "~store/lotteries/types";
import { MutationReducer } from "~store/mutation/reducer";

export const initLotteriesReducer = () => {
    const defaultState: TLotteryState = {
        data: [],
        isLotteriesLoaded: false,
    };
    return (state = defaultState, action: TSidebarTicketsActions): TLotteryState => {
        switch (action.type) {
            default:
                return MutationReducer<TLotteryState>("lotteries", state, action);
        }
    };
};
