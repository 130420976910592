import { validateEmailText } from "~helpers/validation";
import { emailStatus } from "~constants/error-messages";

export const emailValidation = ({ email }: { email: string }) => {
    const errors: { email?: string } = {};

    if (email === "") {
        errors.email = emailStatus.EMAIL_REQUIRED.value;
    } else if (!validateEmailText(email)) {
        errors.email = emailStatus.NOT_CORRECT_EMAIL.value;
    }

    return errors;
};
