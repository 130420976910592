import React, { useState, useEffect } from "react";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { EmptyStateLottery } from "~organisms/empty-state-lottery";
import { MyTicketStyled, ButtonWrap, TicketsWrap } from "~pages/my-tickets/theme";
import { useAppSelector } from "~hooks/useAppSelector";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useGetTicketsFuture } from "../_hooks/useGetTicketsFuture";
import { useGetTicketsPayment } from "../_hooks/useGetTicketsPayment";
import { InProgressTickets } from "../in-progress-tickets";
import { Skeletons } from "../skeletons";

const FutureTickets = () => {
    const [page, setPage] = useState(0);
    const { handleFieldChange } = useMutationHandles("myTickets");
    useGetTicketsPayment();
    useGetTicketsFuture({ page });
    const { content, pagination } = useAppSelector((state) => state.myTickets.future);
    const countInProgress = useAppSelector((state) => state.myTickets.payment.content);
    const isInProgressTickets = countInProgress?.length;
    const isFinished = pagination && pagination.totalElements === content?.length;
    const onClick = () => {
        setPage((prev) => prev + 1);
    };
    useEffect(() => {
        return () => {
            handleFieldChange("future", { content: null });
        };
    }, [handleFieldChange]);
    return (
        <TicketsWrap className="mt32">
            {!content ? (
                <Skeletons />
            ) : (
                <>
                    {isInProgressTickets ? <InProgressTickets /> : null}
                    {content.length ? (
                        <>
                            {isInProgressTickets ? (
                                <TitleAdaptive
                                    className="mt64 mt48--tablet mb32 mb24--tablet mb16--mobile"
                                    size="title38"
                                    tag="h2"
                                    weight="medium"
                                >
                                    Будущие розыгрыши
                                </TitleAdaptive>
                            ) : null}
                            {content.map((item, index) => {
                                return (
                                    <MyTicketStyled
                                        {...item}
                                        key={index}
                                        isContent="future"
                                        notLink={item.registrationStatus === "CANCELED"}
                                    />
                                );
                            })}
                            {!isFinished && (
                                <ButtonWrap className="mt64 mt48--tablet mt32--mobile mb32 mb24--tablet mb16--mobile">
                                    <ButtonAdaptive variant="secondary" onClick={onClick}>
                                        Показать еще
                                    </ButtonAdaptive>
                                </ButtonWrap>
                            )}
                        </>
                    ) : (
                        <EmptyStateLottery className={isInProgressTickets ? "mt32" : ""} title="Будущие розыгрыши" />
                    )}
                </>
            )}
        </TicketsWrap>
    );
};

export default FutureTickets;
