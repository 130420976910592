import styled from "styled-components";
import { Text } from "~atoms/text";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { text16M, text20M, text24M } from "~tokens/typography";

export const BingoRulesHeaderWrapper = styled.div`
    display: block;
`;

export const TextWrapper = styled.div`
    width: 100%;
    max-width: 257px;
    margin-bottom: ${INDENT.m};

    ${adaptive.maxWidth.desktopXXL} {
        margin-bottom: ${INDENT.sm};
    }
`;

export const TextStyled = styled(Text)`
    ${text24M}
    ${adaptive.maxWidth.desktopXXL} {
        ${text20M}
    }
    ${adaptive.maxWidth.desktopXL} {
        ${text24M}
    }
    ${adaptive.maxWidth.tablet} {
        ${text16M};
    }
`;
