import React from "react";
import { TrashIcon } from "~atoms/icons";
import { RemoveButtonWrapper, Text } from "./theme";

type TRemoveButtonProps = {
    className?: string;
    children?: React.ReactNode;
    color?: string;
    hover?: boolean;
    onClick?: () => void;
    iconColor?: string;
};

const RemoveButton = ({
    children = "Удалить",
    color,
    hover = true,
    onClick,
    className,
    iconColor,
}: TRemoveButtonProps) => {
    return (
        <RemoveButtonWrapper className={className} color={color} iconColor={iconColor} hover={hover} onClick={onClick}>
            <TrashIcon className="mr8" />
            <Text size="text16" weight="demiBold" tag="span" color={color}>
                {children}
            </Text>
        </RemoveButtonWrapper>
    );
};

export default RemoveButton;
