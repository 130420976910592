import { useCallback, useEffect, useRef, useState } from "react";
import { TEditionsHistoryDigitalItem, TTicketTypes } from "~store/tickets/types";
import { TDigitalAlias } from "~types/products";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

export const useGetHistory = (alias: TDigitalAlias, ticketType: TTicketTypes) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, forceUpdate] = useState<number>(0);
    const totalPages = useRef(0);
    const sizePage = useRef(20);
    const nextPage = useRef(0);
    const data = useRef(new Map<string, TEditionsHistoryDigitalItem[]>());
    const { handleFieldChange } = useMutationHandles("tickets");
    const { add } = useNotifications();
    const { products } = useAppApi();
    const store = useAppStore();
    const getHistory = useCallback(async () => {
        const { tickets } = store.getState();
        let productId = tickets[ticketType].productId;
        if (!productId) {
            const { result: fetchedProduct, error: errorFetchedProduct } = await products.getProductByAlias(alias);
            if (errorFetchedProduct) {
                add({ message: errorFetchedProduct?.data?.message ?? otherErrors.getProduct });
                return null;
            }
            productId = fetchedProduct && fetchedProduct.productId;
            if (!productId) return null;
            handleFieldChange(`${ticketType}.productId`, productId);
        }

        const { result, error } = await products.getDrawsHistoryDigital(productId, nextPage.current, sizePage.current);
        if (error) {
            add({ message: error?.data?.message ?? otherErrors.getHistory });
            return null;
        }

        result.content.forEach((item) => {
            if (data.current.has(item.interval)) {
                data.current.get(item.interval)!.push(item);
            } else {
                data.current.set(item.interval, [item]);
            }
        });

        totalPages.current = result.totalPages || totalPages.current;
        nextPage.current += 1;

        forceUpdate((data) => data + 1);
    }, [add, alias, handleFieldChange, products, store, ticketType]);

    useEffect(() => {
        void getHistory();
    }, [getHistory]);

    return {
        historyData: data.current,
        historyIntervals: Array.from(data.current.keys()),
        totalPages: totalPages.current,
        getHistory,
        nextPage: nextPage.current,
        isFinished: nextPage.current > totalPages.current - 1,
    };
};
