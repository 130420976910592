import React from "react";
import { TBingoTypes, TEdition } from "~store/tickets/types";
import { TBingoAlias } from "~types/products";
import SelectModalBlockView from "./select-modal-block-view";
import { useSelectModalBingoLogic } from "../_hooks/useSelectModalBingoLogic";

type TSelectModalBlockBingoProps = {
    title?: string;
    editions: TEdition[];
    editionsSelected: TEdition[];
    ticketType: TBingoTypes;
    ticketAlias: TBingoAlias;
};

const SelectModalBlockBingo = ({ title, ticketType, ticketAlias }: TSelectModalBlockBingoProps) => {
    const {
        data: { editions, editionsSelected, editionsExpired },
        cb: { handleChange, handleSelect, handleClose },
    } = useSelectModalBingoLogic(ticketAlias, ticketType);

    return (
        <SelectModalBlockView
            title={title}
            editions={editions}
            typeItem={"radio"}
            editionsSelected={editionsSelected}
            handleSelect={handleSelect}
            handleClose={handleClose}
            handleChange={handleChange}
            editionsExpired={editionsExpired}
        />
    );
};

export default SelectModalBlockBingo;
