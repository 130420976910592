import { getToken } from "~helpers/getToken";
import { AUTH_APP, TUserActions, TUserState } from "./types";
import { MutationReducer } from "../mutation/reducer";

export const initUserReducer = () => {
    const defaultState: TUserState = {
        auth: getToken(),
        roles: [],
    };

    return (state = defaultState, action: TUserActions): TUserState => {
        switch (action.type) {
            case AUTH_APP:
                return { ...state, auth: action.payload };
            default:
                return MutationReducer<TUserState>("user", state, action);
        }
    };
};
