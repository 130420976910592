import React, { memo, useCallback } from "react";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { BoxNumbers } from "./theme";
import { ButtonNumber } from "./theme";

const Box = ({ isActive, onClick, number }: { isActive: boolean; onClick: (num: number) => void; number: number }) => {
    const handleClick = useCallback(() => {
        onClick(number);
    }, [number, onClick]);

    return (
        <BoxNumbers isActive={isActive}>
            <ButtonNumber size="large" variant="transparent" onClick={handleClick}>
                <TitleAdaptive size="title20" weight="medium" tag="span" color="currentColor">
                    {number}
                </TitleAdaptive>
            </ButtonNumber>
        </BoxNumbers>
    );
};

export default memo(Box);
