import styled from "styled-components";
import { FilesBlock } from "~organisms/files-block";
import { adaptive } from "~tokens/screen";

export const FooterWrapper = styled.div``;

export const TextWrapper = styled.div`
    max-width: 676px;

    ${adaptive.maxWidth.desktopXL} {
        max-width: 672px;
    }
`;

export const Logo = styled.img`
    height: 36px;
`;

export const FilesBlockStyled = styled(FilesBlock)`
    max-width: 588px;

    ${adaptive.maxWidth.tablet} {
        & .prefix-wrap svg {
            width: 48px;
        }
    }
`;
