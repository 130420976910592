import { getAccessToken, getJWTPayload, isAccessTokenExpired, isValidToken } from "~services/auth-tokens";

export const getToken = () => {
    const token = getAccessToken();
    if (token && isValidToken(token)) {
        const payload = getJWTPayload(token);
        return !isAccessTokenExpired(payload!.exp as number);
    }
    return false;
};
