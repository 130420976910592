import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { getBingoType } from "./helpers";
import { getMissingNumbers } from "../../../bingo-lottery/common/helpers";
import { Ball, BallWrapper, BingoFieldsWrap, FieldsWrapper } from "../theme";

type TBingoFields = {
    main: Array<number>;
    className?: string;
};

const BingoFields = ({ className, main }: TBingoFields) => {
    const location = useLocation();
    const { color } = useContext(ThemeContext);
    const bingoType = getBingoType(location.pathname);
    const sub = getMissingNumbers(bingoType, main);
    return (
        <section className={className}>
            <FieldsWrapper type="BINGO">
                <BingoFieldsWrap type="main" className="mb32--tablet mb24--mobile mr64 mr0--tablet">
                    <TitleAdaptive size={"title24"} className={"mb16"} weight="medium" color={color.text.primary}>
                        Выпавшие числа
                    </TitleAdaptive>
                    <BallWrapper>
                        {main.map((item, idx) => (
                            <Ball key={idx} variant={3}>
                                {item}
                            </Ball>
                        ))}
                    </BallWrapper>
                </BingoFieldsWrap>
                <BingoFieldsWrap type="sub">
                    <TitleAdaptive size={"title24"} className={"mb16"} weight="medium" color={color.text.primary}>
                        Невыпавшие числа
                    </TitleAdaptive>
                    <BallWrapper>
                        {sub.map((item, idx) => (
                            <Ball key={idx} variant={4}>
                                {item}
                            </Ball>
                        ))}
                    </BallWrapper>
                </BingoFieldsWrap>
            </FieldsWrapper>
        </section>
    );
};

export default BingoFields;
