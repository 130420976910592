import React, { ReactNode } from "react";
import styled from "styled-components";
import { ModalWindow } from "~molecules/modal-window";
import { Footer } from "~organisms/footer";
import { Header } from "~organisms/header";
import { TabBar } from "~organisms/tab-bar";
import { adaptive } from "~tokens/screen";

type TMainProps = {
    children?: ReactNode;
};

const MainWrapper = styled.main`
    &&& {
        margin-top: 80px;
    }
    ${adaptive.maxWidth.desktopXXL} {
        &&& {
            margin-top: 56px;
        }
    }
    ${adaptive.maxWidth.tablet} {
        &&& {
            margin-top: 44px;
        }
    }
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

const HeaderStyled = styled(Header)`
    z-index: 1000;
`;

const TabBarStyled = styled(TabBar)`
    z-index: 998;
    display: none;
    ${adaptive.maxWidth.tablet} {
        display: flex;
    }
`;

const Main = ({ children }: TMainProps) => {
    return (
        <>
            <ModalWindow>
                <HeaderStyled />
                <MainWrapper>{children}</MainWrapper>
                <TabBarStyled />
                <Footer className="footer" />
            </ModalWindow>
        </>
    );
};

export default Main;
