import React, { useCallback, useContext, useRef } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { CodeBlock } from "~organisms/auth-form";
import { phoneSendStatus } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";

export const useSendSms = (setError: React.Dispatch<React.SetStateAction<string>>) => {
    const api = useAppApi();
    const activeReq = useRef(false);
    const { setContent } = useContext(ModalWindowContext);
    return useCallback(
        async (phone: string, title?: string, isReset?: boolean) => {
            if (activeReq.current) return;
            activeReq.current = true;
            const { error, result } = await api.auth.sendSms(phone);
            activeReq.current = false;
            if (error) {
                return setError(phoneSendStatus[error.data.code].value);
            }
            setContent({
                component: CodeBlock,
                props: { phone, operationId: result!.operationId, title, timerValue: result!.timerValue, isReset },
                options: { btnClose: true },
            });
        },
        [api.auth, setContent, setError],
    );
};
