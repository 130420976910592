import React, { useCallback, useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import defaultAva from "~atoms/avatar/assets/default.svg";
import { TextAdaptive } from "~atoms/text-adaptive";
import { DropdownButton } from "~molecules/dropdown-button";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { getMoneyFormat } from "~helpers/format";
import { MY_TICKETS_UPCOMING, PROFILE, WALLET } from "~constants/routes";
import useAppHistory from "~hooks/useAppHistory";
import { useAppScreen } from "~hooks/useAppScreen";
import { useAppSelector } from "~hooks/useAppSelector";
import { useLogout } from "./_hooks/useLogout";
import {
    BalanceText,
    ButtonsHeaderWrapper,
    ButtonStyled, //  SkeletonStyled
} from "./theme";
import LoginBlock from "../../auth-form/login-block/login-block";

type TButtonsHeaderProps = {
    className?: string;
    onProfileBarOpen?: () => void;
};

const ButtonsHeader = ({ className, onProfileBarOpen }: TButtonsHeaderProps) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const typeScreen = useAppScreen();
    const { setContent } = useContext(ModalWindowContext);
    const { go } = useAppHistory();
    const isAuth = useAppSelector((state) => state.user.auth);
    const isLoadedProfile = useAppSelector((state) => Boolean(state.profile.data));
    const nickname = useAppSelector((state) => state.profile.data?.nickname);
    const photo = useAppSelector((state) => state.profile.data?.photo);
    const balance = useAppSelector((state) => state.profile.data?.balance);
    const { color } = useContext(ThemeContext);

    const handleLogout = useLogout(setDropdownOpen);

    const handleOpenAuthForm = useCallback(() => {
        setContent({ component: LoginBlock, options: { btnClose: true } });
    }, [setContent]);

    const handleOpenProfile = useCallback(() => {
        go(PROFILE);
        setDropdownOpen(false);
    }, [go]);

    const handleOpenTickets = useCallback(() => {
        go(MY_TICKETS_UPCOMING);
    }, [go]);

    const handleOpenWallet = useCallback(() => {
        go(WALLET);
    }, [go]);

    const LoginButtonJSX = !isAuth && (
        <ButtonStyled variant="transparent" onClick={handleOpenAuthForm} className="login-btn">
            <div className="avatar-icon mr12" />
            <TextAdaptive size="text16" weight="demiBold">
                Войти
            </TextAdaptive>
        </ButtonStyled>
    );

    const ProfileBarJSX = isAuth && isLoadedProfile && (
        <>
            <ButtonStyled tabIndex={-1} variant="transparent" onClick={handleOpenWallet} className="cash-btn">
                <div className="wallet-icon mr12" />
                <BalanceText size="text16" weight="medium" color={color.text.primary}>
                    {getMoneyFormat(balance as number)} ₽
                </BalanceText>
            </ButtonStyled>
            <ButtonStyled variant="transparent" onClick={handleOpenTickets} className="ticket-btn">
                <div className="tickets-icon mr12" />
                <TextAdaptive size="text16" weight="medium" color={color.text.primary}>
                    Билеты
                </TextAdaptive>
            </ButtonStyled>
            <DropdownButton
                open={dropdownOpen}
                setOpen={setDropdownOpen}
                prefixIcon={photo ?? defaultAva}
                target={nickname || ""}
                targetWeight="medium"
                className="dropdown-btn"
                onClick={typeScreen.startsWith("desktop") ? undefined : onProfileBarOpen}
            >
                <ButtonStyled variant="secondary" onClick={handleOpenProfile}>
                    <div className="avatar-icon mr8" />
                    <TextAdaptive size="text16" weight="medium">
                        Мой профиль
                    </TextAdaptive>
                </ButtonStyled>
                <ButtonStyled variant="secondary" onClick={handleLogout}>
                    <TextAdaptive size="text14" weight="normal">
                        Выйти из аккаунта
                    </TextAdaptive>
                </ButtonStyled>
            </DropdownButton>
        </>
    );

    // const LoadingJSX = isAuth && !isLoadedProfile && <SkeletonStyled />;

    return (
        <ButtonsHeaderWrapper className={className}>
            {LoginButtonJSX}
            {ProfileBarJSX}
            {/* {LoadingJSX} */}
        </ButtonsHeaderWrapper>
    );
};

export default ButtonsHeader;
