import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TableRules5in50Wrapper, TableStyled, TextWrapper } from "./theme";

type TTableRules5in50Props = {
    className?: string;
};

const headers = ["Совпало в «5 из 50»", "Совпало в «2 из 8»", "Выигрыш, ₽"];

const tableInfo = [
    ["5", "2", "Суперприз¹"],
    ["5", "1", "2 000 000²"],
    ["5", "0", "50 000"],
    ["4", "2", "15 000"],
    ["4", "1", "5 000"],
    ["4", "0", "2 000"],
    ["3", "2", "1 500"],
    ["3", "1", "1 000"],
    ["2", "2", "800"],
    ["3", "0", "700"],
    ["1", "2", "500"],
    ["2", "1", "400"],
];

const TableRules5in50 = ({ className }: TTableRules5in50Props) => {
    const { color } = useContext(ThemeContext);
    return (
        <TableRules5in50Wrapper className={className}>
            <TableStyled>
                <thead>
                    <tr>
                        {headers.map((item, index) => (
                            <th key={index}>
                                <TextAdaptive size={"text14"} weight={"medium"} color={color.text.inverted}>
                                    {item}
                                </TextAdaptive>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableInfo.map((item, idx) => (
                        <tr key={idx}>
                            {item.map((infoItem, id) => (
                                <td key={id}>
                                    <TextWrapper>
                                        <TextAdaptive size={"text14"} weight={"medium"} color={color.text.inverted}>
                                            {infoItem}
                                        </TextAdaptive>
                                    </TextWrapper>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </TableStyled>
        </TableRules5in50Wrapper>
    );
};

export default TableRules5in50;
