import { TAlias } from "~types/products";

const getObj = (arrM: Array<number> = [0, 0], arrD: Array<number> = [0, 0]) => {
    return {
        mobile: { width: arrM[0], height: arrM[1] },
        desktop: { width: arrD[0], height: arrD[1] },
    };
};

export const getBingoBox = (digital: boolean, alias: TAlias) => {
    if (digital) {
        return getObj();
    }
    switch (alias) {
        case "bingo-3x3":
            return getObj([87, 57], [100, 64]);
        case "bingo-90":
            return getObj([28, 28], [44, 44]);
        case "bingo-75":
            return getObj([51, 51], [54, 54]);
        default:
            return getObj();
    }
};
