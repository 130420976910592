import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { cleanTokensData } from "~services/auth-tokens";
import { LOTTERY } from "~constants/routes";

export const connectErrorsInterceptors = (http: AxiosInstance) => {
    http.interceptors.response.use(transformRequestInterceptors, errorHandlerInterceptors);
};

function transformRequestInterceptors(response: AxiosResponse) {
    response.data = { error: null, result: response.data };
    return response;
}

async function errorHandlerInterceptors(error: AxiosError) {
    if (!error.response) {
        return { data: { error: error, result: null } };
    }

    if (error.response.status === 401) {
        if (!(error.config as AxiosRequestConfig & { notRefresh?: boolean }).notRefresh) {
            window.location.href = LOTTERY;
            cleanTokensData();
        }
        return { data: { error: error.response, result: null } };
    }

    return { data: { error: error.response, result: null } };
}
