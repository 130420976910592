import { useCallback } from "react";
import { TBingoTypes } from "~store/tickets/types";
import { useMutationHandles } from "~hooks/useMutationHandles";

export const useUpdateMyNumbers = (ticketType: TBingoTypes) => {
    const { handleFieldChange } = useMutationHandles("tickets");
    const handleUpdateMyNumbers = useCallback(
        (numbers: number[]) => {
            handleFieldChange(`${ticketType}.numbersSelected`, numbers);
        },
        [handleFieldChange, ticketType],
    );

    return { handleUpdateMyNumbers };
};
