import styled from "styled-components";
import { Button } from "~atoms/button";
import { Skeleton } from "~atoms/skeleton";
import { SelectWrapper } from "~organisms/select/theme";
import { GREY_DARK2, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const SelectModalWrapper = styled(SelectWrapper)`
    width: 100%;
`;

export const SelectModalButton = styled(Button)`
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background: ${({ theme }) => theme.color.background.primaryElevated};
    color: ${({ theme }) => theme.color.text.primary};
    border: 1px solid ${({ theme }) => theme.color.background.stroke};
    padding: ${INDENT.xxs} ${INDENT.xxxs} ${INDENT.xxs} ${INDENT.xxs};
    border-radius: 8px;
    &:hover span,
    &:focus span,
    &:hover span p,
    &:focus span p {
        color: ${({ theme }) => theme.color.text.primary};
    }
    &:active,
    &:hover {
        background: ${WHITE};
        border: 1px solid ${GREY_DARK2};
    }
    & .suffix-wrapper {
        width: 24px;
        height: 24px;
    }
    & > span {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        & > p {
            white-space: nowrap;
        }
    }
    svg {
        color: ${({ theme }) => theme.color.icons.secondary};
    }

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.xxs} ${INDENT.xxxxxs} ${INDENT.xxs} ${INDENT.xxxxs};
    }

    ${adaptive.maxWidth.tablet} {
        height: 40px;
        padding: ${INDENT.xxxs};
    }
`;

export const SkeletonStyled = styled(Skeleton)`
    height: 48px;
    width: 100%;
    border-radius: 8px;
`;
