import React from "react";
import styled from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { CheckedIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { GREEN, PRIMARY_BLUE, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";

type TSuccessProps = {
    className?: string;
    handleClose: () => void;
    handleOpenMyTickets: () => void;
};

const SuccessWrapper = styled.div`
    display: block;
`;

const CheckedIconStyled = styled(CheckedIcon)`
    width: 32px;
    height: 32px;
    color: ${GREEN};
    ${adaptive.maxWidth.tablet} {
        width: 24px;
        height: 24px;
    }
`;

const Success = ({ className, handleClose, handleOpenMyTickets }: TSuccessProps) => {
    return (
        <SuccessWrapper className={className}>
            <CheckedIconStyled className={"mb16 mb12--mobile"} />
            <TitleAdaptive size={"title24"} tag={"h4"} className={"mb16 mb12--mobile"} color={GREEN}>
                Оплата прошла успешно
            </TitleAdaptive>
            <TextAdaptive size="text16" weight="medium" className="mb8 mb4--mobile">
                Спасибо за покупку!
            </TextAdaptive>
            <TextAdaptive size="text16" weight="medium" className="mb8 mb4--mobile">
                Покупая билеты Национальной лотереи, Вы поддерживаете развитие российского спорта.
            </TextAdaptive>
            <TextAdaptive size="text16" weight="medium" className="mb48 mb32--mobile">
                Желаем победы в розыгрыше!
            </TextAdaptive>
            <ButtonAdaptive className="mb16 mauto" onClick={handleOpenMyTickets}>
                <TextAdaptive size="text16" weight="demiBold" color={WHITE}>
                    Перейти в мои билеты
                </TextAdaptive>
            </ButtonAdaptive>
            <ButtonAdaptive className="mauto" variant="secondary" onClick={handleClose}>
                <TextAdaptive size="text16" weight="demiBold" color={PRIMARY_BLUE}>
                    Остаться на странице
                </TextAdaptive>
            </ButtonAdaptive>
        </SuccessWrapper>
    );
};

export default Success;
