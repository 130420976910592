import React, { useCallback, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { TitleAdaptive } from "~atoms/title-adaptive";
import LotteryTicketsBar from "~organisms/lottery-tickets-bar/lottery-tickets-bar";
import { Page } from "~templates/page";
import { LinkBackStyled } from "~pages/lottery/momentary-lottery/common/theme";
import { useGetBalance } from "~pages/profile/_hooks/useGetBalance";
import { MY_TICKETS_UPCOMING, MY_TICKETS_WITH_WIN, MY_TICKETS_WITHOUT_WIN, PROFILE } from "~constants/routes";
import useAppHistory from "~hooks/useAppHistory";
import { useAppScreen } from "~hooks/useAppScreen";
import { FutureTickets } from "./future";
import { crumbs, tabs } from "./helpers";
import { TabGroupStyled, TicketAside, TicketsContent } from "./theme";
import { WithWinTickets } from "./with-win";
import { WithoutWinTickets } from "./without-win";

const Tickets = () => {
    const { go } = useAppHistory();
    const typeScreen = useAppScreen();

    const { handleGetBalance } = useGetBalance();

    const directionOption = typeScreen.startsWith("desktop") ? "vertical" : "horizontal";
    const { color } = useContext(ThemeContext);

    const handleClick = useCallback(
        (url) => {
            go(url);
        },
        [go],
    );

    useEffect(() => {
        void handleGetBalance();
    }, [handleGetBalance]);

    const mainContent = (
        <TicketsContent className="mb64 mb32--tablet mb0--mobile">
            <Switch>
                <Route path={MY_TICKETS_UPCOMING} component={FutureTickets} exact />
                <Route path={MY_TICKETS_WITH_WIN} component={WithWinTickets} exact />
                <Route path={MY_TICKETS_WITHOUT_WIN} component={WithoutWinTickets} exact />
            </Switch>
        </TicketsContent>
    );

    const asideContent = (
        <TicketAside className="mt32 mt16--mobile">
            <LotteryTicketsBar title={"Лотереи"} direction={directionOption} />
        </TicketAside>
    );

    const headerBlock = (
        <>
            {typeScreen === "mobile" ? (
                <LinkBackStyled href={PROFILE} className="mb32" />
            ) : (
                <Breadcrumbs data={crumbs} className="mt32 mt16--tablet" />
            )}
            <TitleAdaptive
                className="mt32 mt16--mobile"
                tag="h1"
                weight="normal"
                size="title38"
                color={color.text.inverted}
            >
                Мои билеты
            </TitleAdaptive>
            <TabGroupStyled
                className="mt56 mt32--tablet mt16--mobile"
                active={location.pathname}
                tabs={tabs}
                color={color.text.inverted}
                role="link"
                onClick={handleClick}
            />
        </>
    );
    return <Page mainContent={mainContent} asideContent={asideContent} headerBlock={headerBlock} defaultBackground />;
};

export default Tickets;
