import { AxiosInstance } from "axios";
import qs from "qs";
import { adapters } from "~adapters";
import { ApiResponse } from "~services/http/types";
import {
    TAlias,
    TBingoAlias,
    TBingoTicket,
    TDraw,
    TDrawHistory,
    TProduct,
    TProductFlags,
    TProductType,
    TTicketHistoryDetails,
} from "~types/products";

const enum URLs {
    URL_PRODUCTS = "/products",
    URL_TICKETS = "/clients/tickets",
}

export const createProductsApi = (http: AxiosInstance) => ({
    async all(types: TProductType[], flags?: TProductFlags[]) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TProduct[]>>(URLs.URL_PRODUCTS, {
            params: {
                types,
                flags,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "comma", encode: false });
            },
        });
        return { error, result: adapters.products.fromAllApi(result) };
    },

    async getProductByAlias(alias: TAlias) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TProduct>>(URLs.URL_PRODUCTS + `/alias/${alias}`);
        return { error, result };
    },

    async getDraws(id: string, size?: number) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TDraw[]>>(URLs.URL_PRODUCTS + `/${id}/draws/actual`, {
            params: {
                size,
            },
        });
        return { error, result: adapters.products.fromGetProductApi(result) };
    },

    async getDrawsHistoryDigital(id: string, page?: number, size?: number) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TDrawHistory>>(URLs.URL_PRODUCTS + `/${id}/draws/history`, {
            params: {
                page,
                size,
            },
        });
        return {
            error,
            result: { ...result, content: adapters.products.fromGetDrawsHistoryDigital(result && result.content) },
        };
    },

    async getDrawsHistoryBingo(alias: TBingoAlias, id: string, page?: number, size?: number) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TDrawHistory>>(URLs.URL_PRODUCTS + `/${id}/draws/history`, {
            params: {
                page,
                size,
            },
        });
        return {
            error,
            result: { ...result, content: adapters.products.fromGetDrawsHistoryBingo(alias, result && result.content) },
        };
    },

    async getDrawsHistoryDetails(id: string, drawNumber: string) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TTicketHistoryDetails>>(
            URLs.URL_PRODUCTS + `/${id}/draws/${drawNumber}/details`,
        );
        return {
            error,
            result: result && adapters.products.fromHistoryDetails(result),
        };
    },

    async getBingoTickets(
        ticketType: TBingoAlias,
        productId: string,
        size: number,
        selected?: string[],
        favorite?: number[],
    ) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TBingoTicket[]>>(URLs.URL_TICKETS + "/bingo", {
            params: {
                productId,
                size,
                selected,
                favorite,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "comma", encode: false });
            },
        });
        return { error, result: adapters.products.fromGetBingoTickets(result, ticketType) };
    },
});
