import React, { ReactNode } from "react";
import styled from "styled-components";
import { Box, Flex } from "~atoms/flex-box";
import { Text } from "~atoms/text";
import { GREY_DARK } from "~tokens/colors";
import { useAppScreen } from "~hooks/useAppScreen";
import { BoxStyled, ScrollbarsStyled, TableHeadersStyled, TableHorizontalStyled } from "./theme";

type TTableProps = {
    className?: string;
    row: { id: string; title: string; width?: string; getValue: (value: unknown) => ReactNode }[];
    data: Record<string, unknown>[];
};

const TableWrapper = styled.div`
    display: block;
`;

const TableHorizontal = ({ className, row, data }: TTableProps) => {
    const screen = useAppScreen();
    return (
        <TableWrapper className={className}>
            <Flex $direction={"row"} justify={"space-between"}>
                <Box flexBasis={screen === "desktop" ? "160px" : "100px"} className="mr16" flexShrink={0}>
                    <TableHeadersStyled>
                        <tbody>
                            {row.map(({ id, title }) => (
                                <tr key={id}>
                                    <td>
                                        <Text color={GREY_DARK} size={"text16"} weight={"medium"}>
                                            {title}
                                        </Text>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </TableHeadersStyled>
                </Box>
                <BoxStyled>
                    <ScrollbarsStyled>
                        <TableHorizontalStyled>
                            <tbody>
                                <tr>
                                    {data.map((item, idx) => (
                                        <td key={idx}>
                                            {row.map(
                                                ({ id, getValue }) =>
                                                    id === "sizeWin" && (
                                                        <Text
                                                            tag={"span"}
                                                            key={idx}
                                                            size={"text16"}
                                                            color={GREY_DARK}
                                                            weight={"medium"}
                                                        >
                                                            {getValue(item[id])}
                                                        </Text>
                                                    ),
                                            )}
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    {data.map((item, idx) => (
                                        <td key={idx}>
                                            {row.map(
                                                ({ id, getValue }) =>
                                                    id === "numberWin" && (
                                                        <Text
                                                            tag={"span"}
                                                            key={idx}
                                                            size={"text16"}
                                                            color={GREY_DARK}
                                                            weight={"medium"}
                                                        >
                                                            {getValue(item[id])}
                                                        </Text>
                                                    ),
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </TableHorizontalStyled>
                    </ScrollbarsStyled>
                </BoxStyled>
            </Flex>
        </TableWrapper>
    );
};

export default TableHorizontal;
