import { useCallback, Dispatch, SetStateAction } from "react";
import { TPaymentStatus } from "~types/my-tickets";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
// import { useAppStore } from "~hooks/useAppStore";
// import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

type TChangePayment = Dispatch<SetStateAction<TPaymentStatus>>;
export const useGetPayouts = (setPayment: TChangePayment) => {
    // const changeProfileBalance = useMutationHandles("profile");

    const api = useAppApi();
    const { add } = useNotifications();
    // const store = useAppStore();

    return useCallback(
        async (id: string) => {
            const { error, result } = await api.myTickets.getPayots(id);
            if (error) {
                return add({ message: error?.data?.message ?? otherErrors.getMyTicketPayouts });
            }

            if (result && result.amount) {
                setPayment("PAYOUT_PROGRESS");
                // const balance = store.getState().profile.data?.balance;
                // const amount = (balance ?? 0) + result.amount;
                // changeProfileBalance.handleFieldChange("data.balance", amount);
            }
        },
        [api.myTickets, setPayment, add],
    );
};
