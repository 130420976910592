import React from "react";
import { SkeletonWrapper } from "./theme";

export type TSkeletonProps = {
    className?: string;
    radius?: string;
    width?: string;
    height?: string;
    absolute?: boolean;
};

const Skeleton = ({ className, radius = "0px", width = "100%", height = "100%", absolute = false }: TSkeletonProps) => {
    return <SkeletonWrapper className={className} radius={radius} width={width} height={height} absolute={absolute} />;
};

export default Skeleton;
