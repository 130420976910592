import React, { Context, PropsWithChildren } from "react";
import { initApi } from "~api/index";

type TApiContext = {
    api: ReturnType<typeof initApi>;
};

export let ApiContext: Context<TApiContext>;

export const ApiProvider = ({ children, api }: PropsWithChildren<{ api: ReturnType<typeof initApi> }>) => {
    ApiContext = React.createContext<TApiContext>({
        api,
    });
    return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
};
