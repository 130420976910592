import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { getPrize, mainPrize } from "~adapters/products";
import { Text } from "~atoms/text";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { LotteryRulesHeader } from "~organisms/lottery-rules-header";
import { LegalInfo } from "~pages/lottery/common/legal-info";
import { legalText } from "~constants/handbook";
import { ticketsAlias } from "~constants/routes";
import { ticketsDigitalType } from "~constants/tickets-type";
import { useAppSelector } from "~hooks/useAppSelector";
import { TableRules8in20 } from "./table-rules-8in20";
import { ContentWrapper, RulesWrapper } from "./theme";
import { ParagraphWrapper } from "../../common/rules/theme";

type TRulesProps = {
    isActive: boolean;
};

const superPriceLottery = {
    name: "Суперплата (8 из 20)",
    type: "Числовая",
    prizeFund: "60%",
    mainPrize: getPrize(ticketsAlias.t8in20, mainPrize[ticketsAlias.t8in20]),
    frequency: "Каждый час",
};

const Rules = ({ isActive }: TRulesProps) => {
    // const jackpot = useAppSelector((state) => state.tickets[ticketsDigitalType.t8in20].jackpot);
    const amount = useAppSelector((state) => state.tickets[ticketsDigitalType.t8in20].data[0]?.amount);
    const { color } = useContext(ThemeContext);

    return (
        <RulesWrapper>
            <ContentWrapper>
                {/*{jackpot !== null && amount !== null ? (*/}
                {/*    <BannerRules8in20 jackpot={jackpot} amount={amount} className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*) : (*/}
                {/*    <SkeletonStyled className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*)}*/}
                {isActive && (
                    <LotteryRulesHeader
                        name={superPriceLottery.name}
                        type={superPriceLottery.type}
                        prizeFund={superPriceLottery.prizeFund}
                        ticketPrice={amount}
                        mainPrize={superPriceLottery.mainPrize}
                        frequency={superPriceLottery.frequency}
                        className={"mb64 mb48--tablet"}
                    />
                )}

                {/*<TitleAdaptive tag={"div"} size={"title24"} weight={"demiBold"} className={"mb24 mb16--mobile"}>*/}
                {/*    Что можно выиграть?*/}
                {/*</TitleAdaptive>*/}
                {/*<TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>*/}
                {/*    Все выигрыши в «Рапидо», кроме суперприза, фиксированы. Минимальный выигрыш — 150 рублей.*/}
                {/*    Максимальный выигрыш составляет 45 миллионов рублей + сумма суперприза.*/}
                {/*</TextAdaptive>*/}
                {/*<TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>*/}
                {/*    Если победитель использовал развернутую ставку, его выигрыш в любой категории увеличивается, кроме*/}
                {/*    выигрыша 1-й категории — суперприза.*/}
                {/*</TextAdaptive>*/}
                {/*<TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>*/}
                {/*    Суперприз получает тот, кто угадал 8 чисел в первой части поля и одно число — во второй. Минимальный*/}
                {/*    гарантированный суперприз — 200 000 рублей. Он является накапливаемым. Поэтому если в текущем тираже*/}
                {/*    никто не угадает комбинацию розыгрыша, накопленная сумма переходит на следующий тираж.*/}
                {/*</TextAdaptive>*/}
                {/*<TableRules8in20 className={"mb16"} />*/}
                {/*<Text size="text14" color={GREY_DARK} className="mb4">*/}
                {/*    * Не менее 200 ₽ на выигрышный билет*/}
                {/*</Text>*/}
                {/*<Text size="text14" color={GREY_DARK} className="mb64 mb48--tablet">*/}
                {/*    ** Не менее 100 ₽ на выигрышный билет*/}
                {/*</Text>*/}
                <ParagraphWrapper className={"mb24 mb16--mobile"}>
                    <TitleAdaptive tag={"div"} size={"title24"} weight={"demiBold"} className={"mb24 mb16--mobile"}>
                        Как играть?
                    </TitleAdaptive>
                    <TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>
                        В лотерейном билете два игровых поля.
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>
                        Заполните первое поле, выбрав восемь неповторяющихся чисел от 1 до 20 и одно число от 1 до 4
                        включительно во втором поле.
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>
                        Оплатите выбранную комбинацию, чтобы принять участие в розыгрыше.
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} weight={"medium"}>
                        Ваш билет выиграл, если выбранные Вами числа полностью или частично совпали с выигрышной
                        комбинацией:
                    </TextAdaptive>
                </ParagraphWrapper>
                <TableRules8in20 className={"mb24"} />
                <Text size="text14" color={color.text.tertiary} className="mb64 mb48--tablet">
                    ¹ Минимальный гарантированный Суперприз — {superPriceLottery.mainPrize} — делится поровну между
                    всеми выигрышными билетами по данной категории
                </Text>
                <ParagraphWrapper>
                    <LegalInfo
                        type="DIGITAL"
                        prizeInfo={legalText["digital-8x20"].prizeInfo}
                        algoInfo={legalText["digital-8x20"].algoInfo}
                    />
                </ParagraphWrapper>
            </ContentWrapper>
        </RulesWrapper>
    );
};

export default Rules;
