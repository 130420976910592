import styled, { css } from "styled-components";
import { PRIMARY_BLUE } from "~tokens/colors";
import { INDENT } from "~tokens/sizes";
import { fontWeight400, text16, text18 } from "~tokens/typography";
import { TSize } from "./tab";
import { Button } from "../button";

const getSizeStyle = (size: number) => {
    switch (size) {
        case 1:
            return css`
                ${text18};
                ${fontWeight400};
                padding: ${INDENT.xxs} ${INDENT.sm};
            `;
        case 2:
            return css``;
        case 3:
            return css`
                ${text16};
                ${fontWeight400};
                padding: ${INDENT.xxs} ${INDENT.sm};
                border-radius: 8px;
            `;
        default:
            return;
    }
};

export const TabStyled = styled(Button)<{ size: TSize }>`
    width: auto;
    white-space: nowrap;
    height: 48px;
    color: ${({ variant, color }) => (variant === "secondary" ? PRIMARY_BLUE : color)};
    ${({ size }) => getSizeStyle(size)};

    &:hover {
        color: ${PRIMARY_BLUE};
    }
`;
