import React, { useCallback, useContext } from "react";
import { ThemeContext } from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { TextAdaptive } from "~atoms/text-adaptive";
// import { LinkNext } from "~molecules/link-next";
import { FutureBlock } from "~organisms/my-ticket/info-block/future-block";
import { WinBlock } from "~organisms/my-ticket/info-block/win-block";
import { WithoutWinBlock } from "~organisms/my-ticket/info-block/withoutWin-block";
import { TIsContent } from "~organisms/my-ticket/my-ticket";
import { TMyTicketParsed } from "~store/my-tickets/types";
import { getLotteryRouteById } from "~constants/routes";
import useAppHistory from "~hooks/useAppHistory";
import { TScreen } from "~hooks/useAppScreen";
import { TicketDetailsWrapper, ButtonWrapper, TicketImgWrap, TicketWrapper, Header, TicketContainer } from "./theme";
import { TicketBody } from "../ticket-body";
import { TicketHead } from "../ticket/ticket-head";

type TTicketPageProps = {
    className?: string;
    data: TMyTicketParsed;
    isContent: TIsContent;
    typeScreen: TScreen;
    isFutureTicket: boolean;
};

const TicketDetails = ({ className, data, isContent, typeScreen, isFutureTicket }: TTicketPageProps) => {
    const { go } = useAppHistory();
    const {
        numbers,
        numbersWin,
        prize,
        ticketId,
        amount,
        draw,
        product,
        status,
        paymentStatus,
        registrationStatus,
        buyDate,
    } = data;

    const handleClick = useCallback(() => {
        go(getLotteryRouteById(data.product.alias));
    }, [go, data.product.alias]);

    const logo = product.pictures && product.pictures.find((item) => item.type === "ICON_BIG");
    const theme = useContext(ThemeContext);
    return (
        <>
            <TicketDetailsWrapper className={className}>
                <TicketWrapper className="mb32" alias={product.alias}>
                    <Header className="mb32 mb24--tablet mb16--mobile">
                        {isContent === "future" && (
                            <FutureBlock
                                paymentStatus={paymentStatus}
                                registrationStatus={registrationStatus}
                                drawDate={draw.drawDate}
                                theme={theme}
                            />
                        )}
                        {isContent === "win" && (
                            <WinBlock
                                isIntoTicket
                                paymentStatus={paymentStatus}
                                ticketNumber={ticketId}
                                typeScreen={typeScreen}
                                prize={prize}
                                status={status}
                                theme={theme}
                            />
                        )}
                        {isContent === "notWin" && (
                            <WithoutWinBlock
                                registrationStatus={registrationStatus}
                                paymentStatus={paymentStatus}
                                theme={theme}
                            />
                        )}
                    </Header>
                    <TicketContainer>
                        <TicketImgWrap $type={product.type} $alias={product.alias}>
                            <TicketHead
                                type={product.type}
                                alias={product.alias}
                                logo={logo && logo.url}
                                numbers={numbers}
                                numbersWin={numbersWin}
                            />
                            <TicketBody
                                produtId={product.productId}
                                alias={product.alias}
                                ticketId={ticketId}
                                amount={amount}
                                buyDate={buyDate}
                                name={product.name}
                                drawNum={draw.drawNum}
                                drawDate={draw.drawDate}
                                isFutureTicket={isFutureTicket}
                            />
                        </TicketImgWrap>
                    </TicketContainer>
                </TicketWrapper>
                {/* <LinkNext className="mb32">Посмотреть итоги тиража</LinkNext> */}
                <ButtonWrapper>
                    <ButtonAdaptive onClick={handleClick} size={"large"} intent>
                        <TextAdaptive size={"text16"} weight={"demiBold"} color={"inherit"}>
                            Купить еще билет этой лотереи
                        </TextAdaptive>
                    </ButtonAdaptive>
                </ButtonWrapper>
                {/* <OtherTicketsEdition className="mt64 mt48--tablet" edition={data.edition} /> */}
            </TicketDetailsWrapper>
        </>
    );
};

export default TicketDetails;
