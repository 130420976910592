import React from "react";
import { useBuyTicketsCardBingoLogic } from "~organisms/buy-tickets-card/_hooks/useBuyTicketsCardBingoLogic";
import { TBingoTypes } from "~store/tickets/types";
import BuyTicketsCardView from "./buy-tickets-card-view";

type TBuyTicketsCardBingoProps = {
    className?: string;
    ticketType: TBingoTypes;
};

const BuyTicketsCardBingo = ({ className, ticketType }: TBuyTicketsCardBingoProps) => {
    const {
        data: { ticketsCount, amount },
        cb: { handleBuyNow },
    } = useBuyTicketsCardBingoLogic(ticketType);
    return (
        <BuyTicketsCardView
            amount={amount}
            handleBuyNow={handleBuyNow}
            ticketsCount={ticketsCount}
            className={className}
            withoutEdition
        />
    );
};

export default BuyTicketsCardBingo;
