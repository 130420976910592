import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { SliderSwiper } from "~molecules/slider-swiper";
import { TItem } from "~molecules/slider-swiper/slider-swiper";
import { HeaderSliderWrapper, SlideContent, SlideWrapper } from "~organisms/header-slider/theme";
import { WHITE } from "~tokens/colors";
import { ABOUT, LOTTERY_8_IN_20 } from "~constants/routes";
import { useAppScreen } from "~hooks/useAppScreen";
import headerSlide1 from "./assets/Banner 1 - Desktop.png";
import headerSlideMobile1 from "./assets/Banner 1 - Mobile.png";
import headerSlideTablet1 from "./assets/Banner 1 - Tablet.png";
import headerSlide2 from "./assets/Banner 2 - Desktop.png";
import headerSlideMobile2 from "./assets/Banner 2 - Mobile.png";
import headerSlideTablet2 from "./assets/Banner 2 - Tablet.png";
import headerSlide3 from "./assets/Banner 3 - Desktop.png";
import headerSlideMobile3 from "./assets/Banner 3 - Mobile.png";
import headerSlideTablet3 from "./assets/Banner 3 - Tablet.png";

type THeaderSliderProps = {
    className?: string;
};

const headerSliders = [
    {
        id: 0,
        url: ABOUT,
        desktop: {
            src: headerSlide1,
            title: "",
            caption: "",
        },
        desktopXL: {
            src: headerSlide1,
            title: "",
            caption: "",
        },
        tablet: {
            src: headerSlideTablet1,
            title: "",
            caption: "",
        },
        mobile: {
            src: headerSlideMobile1,
            title: "",
            caption: "",
        },
    },
    {
        id: 1,
        url: LOTTERY_8_IN_20,
        desktop: {
            src: headerSlide2,
            title: "",
            caption: "",
        },
        desktopXL: {
            src: headerSlide2,
            title: "",
            caption: "",
        },
        tablet: {
            src: headerSlideTablet2,
            title: "",
            caption: "",
        },
        mobile: {
            src: headerSlideMobile2,
            title: "",
            caption: "",
        },
    },
    {
        id: 2,
        url: ABOUT,
        desktop: {
            src: headerSlide3,
            title: "",
            caption: "",
        },
        desktopXL: {
            src: headerSlide3,
            title: "",
            caption: "",
        },
        tablet: {
            src: headerSlideTablet3,
            title: "",
            caption: "",
        },
        mobile: {
            src: headerSlideMobile3,
            title: "",
            caption: "",
        },
    },
];

const HeaderSlider = ({ className }: THeaderSliderProps) => {
    const typeScreen = useAppScreen();
    const currentSliders = useMemo(
        () => headerSliders.map((item) => ({ id: item.id, url: item.url, ...item[typeScreen] })),
        [typeScreen],
    );

    const renderSlide = useCallback(({ id, src, title, caption, url }: TItem) => {
        return (
            <Link to={url as string}>
                <SlideWrapper key={id} backgroundUrl={src}>
                    <SlideContent>
                        <div>
                            <TitleAdaptive size={"title38"} color={WHITE} className={"mb16 mb8--mobile"}>
                                {title}
                            </TitleAdaptive>
                            <TextAdaptive size={"text16"} color={WHITE}>
                                {caption}
                            </TextAdaptive>
                        </div>
                    </SlideContent>
                </SlideWrapper>
            </Link>
        );
    }, []);

    return (
        <HeaderSliderWrapper className={className}>
            <SliderSwiper
                items={currentSliders}
                autoplay={true}
                loop={true}
                autoHeight={true}
                pagination={true}
                slidesPerView={1}
                spaceBetween={-1}
                renderSlide={renderSlide}
            />
            {/*<HeaderSliderBars>*/}
            {/*    <MobileAppBar className={"header-mobile-bar"} />*/}
            {/*</HeaderSliderBars>*/}
            {/*<MobileButtons className={"mt16"} />*/}
        </HeaderSliderWrapper>
    );
};

export default HeaderSlider;
