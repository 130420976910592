import React, { PropsWithChildren } from "react";
import { Grid } from "~atoms/grid";
import { Col } from "~atoms/grid/col";
import { Row } from "~atoms/grid/row";
import { Text } from "~atoms/text";
import { GREY_DARK2 } from "~tokens/colors";
import { TBingoTypes } from "~store/tickets/types";
import { useAppScreen } from "~hooks/useAppScreen";
import { TicketsGroupWrapper, SkeletonStyled } from "./theme";
import { TicketBingo3x3 } from "../../bingo-3x3/ticket-bingo-3x3";
import { TicketBingo75 } from "../../bingo-75/ticket-bingo-75";
import { TicketBingo90 } from "../../bingo-90/ticket-bingo-90";
import { ticketOptions } from "../helpers";

type TTicketsGroupProps = {
    className?: string;
    nameGroup?: string;
    includeMyNumbers?: boolean;
    ticketType: TBingoTypes;
    ticketsLength: number;
};

const ticketComponents: Record<TBingoTypes, (index: number, include?: boolean) => JSX.Element> = {
    bingo90(index, include) {
        return (
            <TicketBingo90
                key={index}
                idx={index}
                className={"mb40 mb32--mobile mauto--mobile"}
                includeMyNumbers={include}
            />
        );
    },

    bingo75(index, include) {
        return (
            <TicketBingo75
                key={index}
                idx={index}
                className={"mb40 mb32--mobile mauto--mobile"}
                includeMyNumbers={include}
            />
        );
    },
    bingo3x3(index, include) {
        return (
            <TicketBingo3x3
                key={index}
                idx={index}
                className={"mb40 mb32--mobile mauto--mobile"}
                includeMyNumbers={include}
            />
        );
    },
};

const TicketsGroup = ({
    ticketsLength,
    className,
    nameGroup,
    includeMyNumbers,
    ticketType,
}: PropsWithChildren<TTicketsGroupProps>) => {
    const typeScreen = useAppScreen();
    return (
        <TicketsGroupWrapper className={className}>
            {nameGroup && (
                <Text size={"text16"} className={"mb40"} color={GREY_DARK2}>
                    {nameGroup}
                </Text>
            )}
            <Grid>
                <Row>
                    {!!ticketsLength
                        ? Array(ticketsLength)
                              .fill(-1)
                              .map((_, index) => (
                                  <Col key={index} col={4} tablet={6} desktopXL={6} mobile={12}>
                                      {ticketComponents[ticketType](index, includeMyNumbers)}
                                  </Col>
                              ))
                        : Array(
                              typeScreen === "mobile"
                                  ? ticketOptions[ticketType].sizeMobile
                                  : ticketOptions[ticketType].size,
                          )
                              .fill(-1)
                              .map((_, index) => (
                                  <Col key={index} col={4} tablet={6} desktopXL={6} mobile={12}>
                                      <SkeletonStyled
                                          ticketType={ticketType}
                                          className={"mb40 mb32--mobile mauto--mobile"}
                                      />
                                  </Col>
                              ))}
                </Row>
            </Grid>
        </TicketsGroupWrapper>
    );
};

export default TicketsGroup;
