import styled from "styled-components";
import { adaptive } from "~assets/styles/tokens/screen";
import { INDENT } from "~assets/styles/tokens/sizes";
import { TextAdaptive } from "~atoms/text-adaptive";
import { fontSize32, lineHeight40, lineHeight32, fontSize24, fontWeight500 } from "~tokens/typography";

const getStyles = (isIntoTicket: boolean) => {
    if (isIntoTicket) {
        return `
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div:first-child {
                margin-right: ${INDENT.sm};
            }

            > button, > div {
                margin-bottom: 0;
            }

            > button {
                height: 52px;
            }

            ${adaptive.minWidth.tablet} {
                justify-content: flex-start;
            }
        `;
    }

    return `
            height: 74px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            padding: ${INDENT.xxs};

            ${adaptive.minWidth.tablet} {
                width: auto;
                height: auto;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: ${INDENT.xs};
            }

            ${adaptive.minWidth.desktopXL} {
                padding: ${INDENT.none};
            }
    `;
};

const Wrapper = styled.div<{ $isIntoTicket: boolean }>`
    ${({ $isIntoTicket }) => getStyles($isIntoTicket)};
`;

const WinValue = styled(TextAdaptive)`
    ${fontSize24};
    ${lineHeight32};

    ${adaptive.minWidth.tablet} {
        ${fontSize32};
        ${lineHeight40};
    }
`;

const IconWrap = styled.div`
    display: none;
    position: relative;
    top: 3px;
    color: ${({ theme }) => theme.color.icons.primary};

    ${adaptive.maxWidth.mobileSmall} {
        display: inline-block;
    }
`;

const StatusText = styled.span`
    ${fontWeight500};
    ${adaptive.maxWidth.mobileSmall} {
        display: none;
    }
`;

export { Wrapper, WinValue, StatusText, IconWrap };
