import React, { useCallback, useEffect, useState } from "react";
import { noop } from "lodash-es";
import styled from "styled-components";
import { Link } from "~atoms/link";
import { Text } from "~atoms/text";
import { useResendSms } from "~organisms/auth-form/code-block/_hooks/useResendSms";
import { ErrorText } from "~organisms/auth-form/theme";
import { INDENT } from "~tokens/sizes";
import { fontWeight500, text14 } from "~tokens/typography";
import { transformTime } from "~helpers/format";
import { phoneSendStatus, smsCodeStatus } from "~constants/error-messages";
import { useTimer } from "~hooks/useTimer";

type TSmsTimerBlockProps = {
    className?: string;
    errorCode: string;
    phone: string;
    timerValue: number;
    onResendSms: (operationId: string) => void;
};

const SmsTimerBlockWrapper = styled.div`
    display: block;

    .btn-noop {
        cursor: default;
        pointer-events: none;
    }
`;

const LinkStyled = styled(Link)`
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 ${INDENT.sm};
`;

const LinkText = styled(Text)`
    ${text14};
    ${fontWeight500};
    color: #007aff;
`;

const SmsTimerBlock = ({ className, onResendSms, phone, errorCode, timerValue }: TSmsTimerBlockProps) => {
    const { time, isTimeOver, start, restart } = useTimer({ step: -1, endTime: 0, initialTime: timerValue });
    const resendSms = useResendSms();
    const [isShowBtnSend, setShowBtnSend] = useState(false);
    const [errorResend, setErrorResend] = useState("");

    useEffect(() => {
        start();
    }, [start]);

    useEffect(() => {
        if (isTimeOver || errorCode === smsCodeStatus.NOT_REMAIN_ATTEMPTS.code) {
            setShowBtnSend(true);
        }
    }, [isTimeOver, errorCode]);

    const transformedTime = transformTime(time);

    const sendAgain = useCallback(async () => {
        const { error, result } = await resendSms(phone);

        if (error) {
            return setErrorResend(phoneSendStatus[error.data.code].value);
        }
        onResendSms(result!.operationId);
        restart(result!.timerValue);
        setShowBtnSend(false);
    }, [onResendSms, restart, setShowBtnSend, resendSms, phone]);

    const blockedRepeatBtn = !!errorResend || errorCode === smsCodeStatus.NOT_REMAIN_ATTEMPTS.code;

    return (
        <SmsTimerBlockWrapper className={className}>
            <>
                {!isShowBtnSend && (
                    <LinkStyled onClick={noop} className={"auth-form-btn btn-noop"}>
                        <LinkText>Отправить повторный код {` ${transformedTime.min}:${transformedTime.sec}`}</LinkText>
                    </LinkStyled>
                )}
                {isShowBtnSend && (
                    <LinkStyled onClick={sendAgain} className={"auth-form-btn"} disabled={blockedRepeatBtn}>
                        <LinkText>Отправить повторный код</LinkText>
                    </LinkStyled>
                )}
            </>
            {errorResend && <ErrorText className={"error-text--center mt16"}>{errorResend}</ErrorText>}
        </SmsTimerBlockWrapper>
    );
};

export default SmsTimerBlock;
