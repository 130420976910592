import styled, { DefaultTheme } from "styled-components";
import { TVariantComboCircle } from "./combo-circle";
import { TextAdaptive } from "../text-adaptive";

const getStyle = (variant: TVariantComboCircle, theme: DefaultTheme) => {
    switch (variant) {
        case 1:
            return `
                background-color: ${theme.color.const.owrlDarken["300"]};
            `;
        case 2:
        case 3:
            return `
                background-color: ${theme.color.const.gsSolid["100"]};
            `;
        case 4:
            return `
                background-color: ${theme.color.controls.secondary.default};
            `;
        default:
            break;
    }
};

const ComboCircleWrapper = styled.div<{ variant: TVariantComboCircle }>`
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: inline-block;
    text-align: center;
    ${({ variant, theme }) => getStyle(variant, theme)};
`;

const Text = styled(TextAdaptive)`
    line-height: 32px;
`;

export { ComboCircleWrapper, Text };
