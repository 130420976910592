import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { Flex } from "~atoms/flex-box";
import { TBingoTypes } from "~store/tickets/types";
import { useAppSelector } from "~hooks/useAppSelector";
import { tabs } from "./helpers";
import { DrawWrapper, TabGroupStyled, TicketsBlockWrapper } from "./theme";
import { TicketsDraw } from "../../common/tickets-draw";
import { ticketOptions } from "../helpers";
import { MyNumbers } from "../my-numbers";
import { Tickets } from "../tickets";

type TTicketsBlockProps = {
    className?: string;
    ticketType: TBingoTypes;
};

const TicketsPage = ({ className, ticketType }: TTicketsBlockProps) => {
    const [activeTab, setActiveTab] = useState("1");
    const editionsLength = useAppSelector((state) => state.tickets[ticketType].editions.length);
    const { color } = useContext(ThemeContext);

    const handleClick = (id: string) => {
        setActiveTab(id);
    };

    const tickets = activeTab === "1" && <Tickets ticketType={ticketType} />;

    const myNumbers = activeTab === "2" && <MyNumbers ticketType={ticketType} />;

    return (
        <TicketsBlockWrapper className={className}>
            <Flex justify="space-between" mobile={{ $direction: "column" }}>
                <TabGroupStyled
                    active={activeTab}
                    onClick={handleClick}
                    tabs={tabs}
                    color={color.text.primary}
                    size={3}
                    className="mb48 mb32--tablet"
                />
                <DrawWrapper
                    alignItems="center"
                    justify="flex-end"
                    tablet={{ justify: editionsLength === 1 ? "flex-end" : "flex-start", flexBasis: "auto" }}
                    mobile={{ justify: "flex-start", flexBasis: "auto" }}
                    className="mb48--tablet mb32--mobile"
                >
                    <TicketsDraw ticketType={ticketType} ticketAlias={ticketOptions[ticketType].alias} />
                </DrawWrapper>
            </Flex>
            {tickets}
            {myNumbers}
        </TicketsBlockWrapper>
    );
};

export default TicketsPage;
