import React from "react";
import styled from "styled-components";
import { LongMobileTicket } from "~organisms/lottery-ticket/long-mobile-ticket";
import { LongTicket } from "~organisms/lottery-ticket/long-ticket";
import OtherTicket from "~organisms/lottery-ticket/other-ticket/other-ticket";
import { ShortMobileTicket } from "~organisms/lottery-ticket/short-mobile-ticket";
import { ShortTicket } from "~organisms/lottery-ticket/short-ticket";
import { TAlias } from "~types/products";

export type TSizeTicket = "long" | "short" | "long-mobile" | "short-mobile" | "other";

export type TLotteryTicketProps = {
    id: TAlias;
    className?: string;
    title: string;
    productId?: string;
    prize: string | null;
    note: string | null;
    edition: string;
    backgroundUrl: string;
    time: string;
    logoSrc: string;
    amount: string | undefined;
    type: TSizeTicket;
    active: boolean;
};

const LotteryTicketWrapper = styled.div`
    display: block;
    cursor: pointer;
`;

const LotteryTicket = ({ type, ...props }: TLotteryTicketProps) => {
    return (
        <LotteryTicketWrapper className={props.className}>
            {type === "long" && <LongTicket {...props} />}
            {type === "long-mobile" && <LongMobileTicket {...props} />}
            {type === "short" && <ShortTicket {...props} />}
            {type === "short-mobile" && <ShortMobileTicket {...props} />}
            {type === "other" && <OtherTicket {...props} />}
        </LotteryTicketWrapper>
    );
};

export default LotteryTicket;
