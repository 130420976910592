import React from "react";
import { TTicketOptions } from "~pages/lottery/types";
import { TTicketTypes } from "~store/tickets/types";
import PaymentBlockView from "./payment-block-view";
import { usePaymentBlockDigitalLogic } from "../_hooks/usePaymentBlockDigitalLogic";

type TPaymentBlockBingoProps = {
    className?: string;
    paymentId: string;
    paymentToken: string;
    ticketType: TTicketTypes;
    onClickClose: () => void;
    ticketOptions: TTicketOptions;
    handleWidgetClose: () => void;
};

const PaymentBlockBingo = ({
    className,
    ticketType,
    onClickClose,
    ticketOptions,
    handleWidgetClose,
}: TPaymentBlockBingoProps) => {
    const { handleWalletPayment, handleCardPayment, handleApplePayPayment } = usePaymentBlockDigitalLogic(
        onClickClose,
        handleWidgetClose,
        ticketType,
        ticketOptions,
    );
    return (
        <PaymentBlockView
            handleWalletPayment={handleWalletPayment}
            handleCardPayment={handleCardPayment}
            handleApplePayPayment={handleApplePayPayment}
            className={className}
        />
    );
};

export default PaymentBlockBingo;
