import React, { useContext, useRef } from "react";
import styled from "styled-components";
import { AccessTimeIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { useGetPaymentStatus } from "~organisms/buy-tickets-card/_hooks/useGetPaymentStatus";
import { StatusBlock } from "~organisms/buy-tickets-card/status-block";
import { ORANGE, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

type TWaitProps = {
    className?: string;
    paymentId: string;
    handleClose: () => void;
    handleOpenMyTickets: () => void;
};

const WaitWrapper = styled.div`
    display: block;
`;

const CheckedIconWrapper = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: ${INDENT.xxxxs};
    background: ${ORANGE};
    display: inline-block;
    ${adaptive.maxWidth.tablet} {
        width: 24px;
        height: 24px;
        padding: ${INDENT.xxxxs};
    }
`;

const CheckedIconStyled = styled(AccessTimeIcon)`
    color: ${WHITE};
    width: 24px;
    height: 24px;
    ${adaptive.maxWidth.tablet} {
        width: 16px;
        height: 16px;
    }
`;

const Wait = ({ className, paymentId, handleClose, handleOpenMyTickets }: TWaitProps) => {
    const setStatus = useGetPaymentStatus();
    const timer = useRef<{ cnt: number; id: number }>({ cnt: 0, id: 0 });
    const { setContent } = useContext(ModalWindowContext);

    setTimeout(async function checkTransaction() {
        if (timer.current.cnt === 5) {
            return setContent({
                component: StatusBlock,
                props: { typeStatus: "CANCELED" },
                options: { btnClose: true, btnVariant: "secondary", borderRadius: 16 },
            });
        }
        const status = await setStatus(paymentId);

        if (status === "CANCELED") {
            return setContent({
                component: StatusBlock,
                props: { typeStatus: status },
                options: { btnClose: true, btnVariant: "secondary", borderRadius: 16 },
            });
        }

        if (status === "ACCEPTED" || status === "ACCEPTED_MOVE") {
            return setContent({
                component: StatusBlock,
                props: { typeStatus: status, handleClose, handleOpenMyTickets },
                options: { onClickClose: handleClose, btnClose: true, btnVariant: "secondary", borderRadius: 16 },
            });
        }
        timer.current.cnt += 1;
        setTimeout(checkTransaction, 1000);
    }, 1000);

    return (
        <WaitWrapper className={className}>
            <CheckedIconWrapper className={"mb16"}>
                <CheckedIconStyled />
            </CheckedIconWrapper>
            <TitleAdaptive size={"title24"} tag={"h4"} className={"mb16 mb12--mobile"} color={ORANGE}>
                Оплата в процессе
            </TitleAdaptive>
            <TextAdaptive size="text16" weight="medium">
                Пожалуйста, не закрывайте окно
            </TextAdaptive>
        </WaitWrapper>
    );
};

export default Wait;
