import React from "react";
import { Table } from "~molecules/table";
import { TDigitalEditionDetails } from "~types/products";
import { col, getTableData } from "./helpers";

type TDigitalTable = {
    digitalDetails: TDigitalEditionDetails[];
    className?: string;
};

const DigitalTable = ({ digitalDetails, className }: TDigitalTable) => {
    return <Table className={className} data={getTableData(digitalDetails)} scheme={col} tableType="solid" />;
};

export default DigitalTable;
