import { css } from "styled-components";
import { adaptive } from "./tokens/screen";
import { INDENT } from "./tokens/sizes";

export const indentClassNames = css`
    /* MARGIN TOP */

    .mt64 {
        margin-top: ${INDENT.xxxl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.xxxl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.xxxl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.xxxl};
            }
        }
    }

    .mt60 {
        margin-top: ${INDENT.xxls};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.xxls};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.xxls};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.xxls};
            }
        }
    }

    .mt56 {
        margin-top: ${INDENT.xxl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.xxl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.xxl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.xxl};
            }
        }
    }

    .mt48 {
        margin-top: ${INDENT.xl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.xl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.xl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.xl};
            }
        }
    }

    .mt44 {
        margin-top: ${INDENT.ls};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.ls};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.ls};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.ls};
            }
        }
    }

    .mt40 {
        margin-top: ${INDENT.l};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.l};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.l};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.l};
            }
        }
    }

    .mt36 {
        margin-top: ${INDENT.ms};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.ms};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.ms};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.ms};
            }
        }
    }

    .mt32 {
        margin-top: ${INDENT.m};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.m};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.m};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.m};
            }
        }
    }

    .mt24 {
        margin-top: ${INDENT.sm};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.sm};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.sm};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.sm};
            }
        }
    }

    .mt20 {
        margin-top: ${INDENT.s};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.s};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.s};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.s};
            }
        }
    }

    .mt16 {
        margin-top: ${INDENT.xs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.xs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.xs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.xs};
            }
        }
    }

    .mt12 {
        margin-top: ${INDENT.xxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.xxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.xxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.xxs};
            }
        }
    }

    .mt8 {
        margin-top: ${INDENT.xxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.xxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.xxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.xxxs};
            }
        }
    }

    .mt4 {
        margin-top: ${INDENT.xxxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.xxxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.xxxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.xxxxs};
            }
        }
    }

    .mt2 {
        margin-top: ${INDENT.xxxxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.xxxxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.xxxxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.xxxxxs};
            }
        }
    }

    .mt0 {
        margin-top: ${INDENT.none};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: ${INDENT.none};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: ${INDENT.none};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: ${INDENT.none};
            }
        }
    }
    .mtauto {
        margin-top: auto;

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-top: auto;
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-top: auto;
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-top: auto;
            }
        }
    }

    /* MARGIN BOTTOM */

    .mb64 {
        margin-bottom: ${INDENT.xxxl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.xxxl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.xxxl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.xxxl};
            }
        }
    }

    .mb60 {
        margin-bottom: ${INDENT.xxls};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.xxls};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.xxls};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.xxls};
            }
        }
    }

    .mb56 {
        margin-bottom: ${INDENT.xxl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.xxl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.xxl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.xxl};
            }
        }
    }

    .mb48 {
        margin-bottom: ${INDENT.xl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.xl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.xl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.xl};
            }
        }
    }

    .mb44 {
        margin-bottom: ${INDENT.ls};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.ls};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.ls};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.ls};
            }
        }
    }

    .mb40 {
        margin-bottom: ${INDENT.l};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.l};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.l};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.l};
            }
        }
    }

    .mb36 {
        margin-bottom: ${INDENT.ms};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.ms};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.ms};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.ms};
            }
        }
    }

    .mb32 {
        margin-bottom: ${INDENT.m};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.m};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.m};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.m};
            }
        }
    }

    .mb24 {
        margin-bottom: ${INDENT.sm};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.sm};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.sm};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.sm};
            }
        }
    }

    .mb20 {
        margin-bottom: ${INDENT.s};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.s};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.s};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.s};
            }
        }
    }

    .mb16 {
        margin-bottom: ${INDENT.xs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.xs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.xs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.xs};
            }
        }
    }

    .mb12 {
        margin-bottom: ${INDENT.xxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.xxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.xxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.xxs};
            }
        }
    }

    .mb8 {
        margin-bottom: ${INDENT.xxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.xxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.xxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.xxxs};
            }
        }
    }

    .mb4 {
        margin-bottom: ${INDENT.xxxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.xxxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.xxxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.xxxxs};
            }
        }
    }

    .mb2 {
        margin-bottom: ${INDENT.xxxxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.xxxxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.xxxxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.xxxxxs};
            }
        }
    }

    .mb0 {
        margin-bottom: ${INDENT.none};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: ${INDENT.none};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: ${INDENT.none};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: ${INDENT.none};
            }
        }
    }

    .mbauto {
        margin-bottom: auto;

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-bottom: auto;
            }
        }
        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-bottom: auto;
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-bottom: auto;
            }
        }
    }

    /* MARGIN LEFT */

    .ml64 {
        margin-left: ${INDENT.xxxl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.xxxl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.xxxl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.xxxl};
            }
        }
    }

    .ml56 {
        margin-left: ${INDENT.xxl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.xxl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.xxl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.xxl};
            }
        }
    }

    .ml48 {
        margin-left: ${INDENT.xl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.xl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.xl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.xl};
            }
        }
    }

    .ml40 {
        margin-left: ${INDENT.l};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.l};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.l};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.l};
            }
        }
    }

    .ml32 {
        margin-left: ${INDENT.m};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.m};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.m};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.m};
            }
        }
    }

    .ml24 {
        margin-left: ${INDENT.sm};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.sm};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.sm};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.sm};
            }
        }
    }

    .ml20 {
        margin-left: ${INDENT.s};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.s};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.s};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.s};
            }
        }
    }

    .ml16 {
        margin-left: ${INDENT.xs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.xs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.xs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.xs};
            }
        }
    }

    .ml12 {
        margin-left: ${INDENT.xxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.xxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.xxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.xxs};
            }
        }
    }

    .ml8 {
        margin-left: ${INDENT.xxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.xxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.xxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.xxxs};
            }
        }
    }

    .ml4 {
        margin-left: ${INDENT.xxxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.xxxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.xxxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.xxxxs};
            }
        }
    }

    .ml2 {
        margin-left: ${INDENT.xxxxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.xxxxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.xxxxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.xxxxxs};
            }
        }
    }

    .ml0 {
        margin-left: ${INDENT.none};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: ${INDENT.none};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: ${INDENT.none};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: ${INDENT.none};
            }
        }
    }

    .mlauto {
        margin-left: auto;

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: auto;
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: auto;
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: auto;
            }
        }
    }

    /* MARGIN RIGHT */

    .mr64 {
        margin-right: ${INDENT.xxxl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.xxxl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.xxxl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.xxxl};
            }
        }
    }

    .mr56 {
        margin-right: ${INDENT.xxl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.xxl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.xxl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.xxl};
            }
        }
    }

    .mr48 {
        margin-right: ${INDENT.xl};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.xl};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.xl};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.xl};
            }
        }
    }

    .mr40 {
        margin-right: ${INDENT.l};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.l};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.l};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.l};
            }
        }
    }

    .mr32 {
        margin-right: ${INDENT.m};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.m};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.m};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.m};
            }
        }
    }

    .mr24 {
        margin-right: ${INDENT.sm};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.sm};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.sm};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.sm};
            }
        }
    }

    .mr20 {
        margin-right: ${INDENT.s};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.s};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.s};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.s};
            }
        }
    }

    .mr16 {
        margin-right: ${INDENT.xs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.xs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.xs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.xs};
            }
        }
    }

    .mr12 {
        margin-right: ${INDENT.xxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.xxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.xxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.xxs};
            }
        }
    }

    .mr8 {
        margin-right: ${INDENT.xxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.xxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.xxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.xxxs};
            }
        }
    }

    .mr4 {
        margin-right: ${INDENT.xxxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.xxxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.xxxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.xxxxs};
            }
        }
    }

    .mr2 {
        margin-right: ${INDENT.xxxxxs};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.xxxxxs};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.xxxxxs};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.xxxxxs};
            }
        }
    }

    .mr0 {
        margin-right: ${INDENT.none};

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: ${INDENT.none};
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: ${INDENT.none};
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: ${INDENT.none};
            }
        }
    }

    .mrauto {
        margin-right: auto;

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-right: auto;
            }
        }
        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-right: auto;
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-right: auto;
            }
        }
    }

    /* MARGIN LEFT AND RIGHT*/

    .mauto {
        margin-left: auto;
        margin-right: auto;

        &--desktopxl {
            ${adaptive.maxWidth.desktopXXL} {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &--tablet {
            ${adaptive.maxWidth.desktopXL} {
                margin-left: auto;
                margin-right: auto;
            }
        }

        &--mobile {
            ${adaptive.maxWidth.tablet} {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
`;
