import styled from "styled-components";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { Linkback } from "~atoms/linkback";
import { Text } from "~atoms/text";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeightBold } from "~tokens/typography";

const HeadContent = styled.div`
    width: 100%;
    padding: ${INDENT.m};
    background-color: ${WHITE};
    border-radius: 20px;

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm};
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs};
    }
`;

const MomentaryContent = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    padding: ${INDENT.m};
    background-color: ${WHITE};
    border-radius: 20px;

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm};
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs};
    }
`;

const Statistics = styled.section`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 29.51%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
`;

const LinkMomentaryWrapper = styled.div`
    margin-bottom: 165px;
`;

const LinkBackStyled = styled(Linkback)`
    color: ${WHITE};

    span {
        color: ${WHITE};
    }
`;

const BreadcrumbsStyled = styled(Breadcrumbs)`
    display: inline-block;
    margin-bottom: 32px;
`;

const TitleStyled = styled.img`
    height: 120px;

    ${adaptive.maxWidth.desktopXXL} {
        height: 80px;
    }

    ${adaptive.maxWidth.tablet} {
        height: 64px;
    }
`;

const TitleBlockWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: row;

    ${adaptive.maxWidth.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const ParagraphWrapper = styled.div`
    max-width: 798px;

    ${adaptive.maxWidth.desktopXXL} {
        max-width: unset;
    }
`;

const TableWrapper = styled.div``;

const PrizeFundWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${adaptive.maxWidth.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const TotalText = styled(Text)`
    font-size: 60px;
    line-height: 60px;
    font-feature-settings: initial;
    font-variant-numeric: initial;
    white-space: nowrap;

    ${fontWeightBold}
    ${adaptive.maxWidth.tablet} {
        font-size: 44px;
        line-height: 44px;
    }
`;

export {
    BreadcrumbsStyled,
    LinkBackStyled,
    Statistics,
    MomentaryContent,
    TitleStyled,
    TitleBlockWrapper,
    ParagraphWrapper,
    TableWrapper,
    PrizeFundWrapper,
    HeadContent,
    TotalText,
    LinkMomentaryWrapper,
};
