import styled from "styled-components";
import { Skeleton } from "~atoms/skeleton";
import { LotteryTicket } from "~organisms/lottery-ticket";
import { INDENT } from "~tokens/sizes";

export const LotteryTicketStyled = styled(LotteryTicket)`
    max-width: 342px;
    width: 100%;

    img {
        height: 32px;
    }
`;

export const SkeletonStyled = styled(Skeleton)<{ $direction: "horizontal" | "vertical" }>`
    height: ${(props) => (props.$direction === "horizontal" ? "179px" : "202px")};
    border-radius: 20px;
    width: 100%;
`;

export const LotteryTicketsWrapper = styled.div<{ $direction: "horizontal" | "vertical" }>`
    .ticket-card {
        ${(props) => props.$direction === "horizontal" && "min-width: 328px;"};
        margin-bottom: ${(props) => (props.$direction === "horizontal" ? INDENT.none : INDENT.sm)};
        cursor: pointer;
    }
`;
