import { useCallback, useEffect } from "react";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useFetch } from "~hooks/useFetch";
import { useNotifications } from "~hooks/useNotifications";

export const useGetHistoryDetails = (id: string, drawNumber: string) => {
    const api = useAppApi();
    const { add } = useNotifications();

    const getDetails = useCallback(async () => {
        return await api.products.getDrawsHistoryDetails(id, drawNumber);
    }, [api.products, id, drawNumber]);

    const { error, loading, data } = useFetch(getDetails);

    useEffect(() => {
        if (error) {
            add({ message: error?.data?.message ?? otherErrors.getMyTicket });
        }
    }, [add, error]);

    return { isLoading: loading, isError: !!error, data };
};
