import audit2020 from "./10АЗ_безусловно_положительное_2020_compressed.pdf";
import statement from "./Заявление_на_выплату_выигрыша.pdf";
import writ from "./Приказ_Минфина_России_от_20_01_2020_N_9н.pdf";
import vgl1t from "./Приложение 1_ВГЛ_1Т.pdf";
import vgl2t from "./Приложение 1_ВГЛ_2Т.pdf";
import vgl1b from "./Приложение 6_ВГЛ-1Б.pdf";
import vgl3b from "./Приложение 8_ВГЛ-3Б.pdf";
import vgl6b from "./Приложение 11_ВГЛ-6Б.pdf";
import vgl8b from "./Приложение 13_ВГЛ-8Б.pdf";
import audit2019 from "./СЛ_Аудиторское_заключение_2019.pdf";
import agreement from "./Согласие_на_обработку_персональных_данных.pdf";

export const docs = {
    writ: { title: "Приказ_Минфина_России_от_20_01_2020_N_9н.pdf", link: writ },
    vgl1t: { title: "Приложение 1_ВГЛ_1Т.pdf", link: vgl1t },
    vgl2t: { title: "Приложение 1_ВГЛ_2Т.pdf", link: vgl2t },
    vgl1b: { title: "Приложение 6_ВГЛ-1Б.pdf", link: vgl1b },
    vgl3b: { title: "Приложение 8_ВГЛ-3Б.pdf", link: vgl3b },
    vgl6b: { title: "Приложение 11_ВГЛ-6Б.pdf", link: vgl6b },
    vgl8b: { title: "Приложение 13_ВГЛ-8Б.pdf", link: vgl8b },
    agreement: { title: "Форма согласия участника лотереи на обработку персональных данных", link: agreement },
    statement: { title: "Заявление на выплату выигрыша", link: statement },
    audit2019: { title: "Аудиторское заключение за 2019 год", link: audit2019 },
    audit2020: { title: "Аудиторское заключение за 2020 год", link: audit2020 },
};
