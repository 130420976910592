import styled, { FlattenSimpleInterpolation, SimpleInterpolation } from "styled-components";

export const TextWrapper = styled.div<{
    size: FlattenSimpleInterpolation;
    color?: string;
    weight: SimpleInterpolation;
}>`
    color: ${({ color, theme }) => (color ? color : theme.color.text.primary)};
    ${(props) => props.size}
    &&& {
        ${({ weight }) => weight}
    }
`;
