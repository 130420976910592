import React, { useMemo, PropsWithChildren, useContext } from "react";
import { ThemeContext } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import {
    BoxNumbersSelected,
    FieldNumbersSelected,
    FieldTitle,
    TicketHeader,
    FieldBody,
    TicketHeaderContent,
    HeaderLogo,
    HeaderTitle,
    HeaderEdition,
    TicketHeaderNumbers,
    HeaderPrice,
} from "./theme";

type THeaderProps = {
    title: string;
    titleTicket: string;
    logoSrc: string;
    amount: number | null;
    maxNumField1: number;
    maxNumField2: number;
    titleField1: string;
    titleField2: string;
    selectedNum: Array<number>[];
};

const Header = ({
    title,
    selectedNum,
    amount,
    maxNumField1,
    maxNumField2,
    titleTicket,
    titleField1,
    titleField2,
    children,
    logoSrc,
}: PropsWithChildren<THeaderProps>) => {
    const numbersField1 = useMemo(() => {
        return selectedNum[0].length >= maxNumField1
            ? selectedNum[0]
            : Array(maxNumField1)
                  .fill(-1)
                  .map((item, idx) => (selectedNum[0][idx] ? selectedNum[0][idx] : item));
    }, [maxNumField1, selectedNum]);

    const numbersField2 = useMemo(() => {
        return selectedNum[1].length >= maxNumField2
            ? selectedNum[1]
            : Array(maxNumField2)
                  .fill(-1)
                  .map((item, idx) => (selectedNum[1][idx] ? selectedNum[1][idx] : item));
    }, [maxNumField2, selectedNum]);

    const { color } = useContext(ThemeContext);

    return (
        <TicketHeader>
            <TicketHeaderContent className="mt44--mobile">
                <HeaderTitle>
                    <TitleAdaptive
                        tag="h3"
                        color={color.text.inverted}
                        size="title20"
                        weight="medium"
                        className="mr16 mr0--tablet mb4--tablet"
                    >
                        {titleTicket}
                    </TitleAdaptive>
                    <HeaderPrice tag="span" color={color.text.inverted} weight="medium" size="text16">
                        Цена: {amount} ₽
                    </HeaderPrice>
                </HeaderTitle>
                <HeaderLogo src={logoSrc} alt={title} />
                <HeaderEdition>
                    <TextAdaptive
                        size="text14"
                        tag="p"
                        color={color.text.inverted}
                        className="mr12 mr0--tablet mb4--tablet"
                    >
                        Выберите тираж
                    </TextAdaptive>
                    {children}
                </HeaderEdition>
            </TicketHeaderContent>
            <TicketHeaderNumbers justify="center" mobile={{ $direction: "column" }} className={"mauto--mobile"}>
                <FieldNumbersSelected
                    className="mb8--mobile field1"
                    flexShrink={0}
                    flexBasis={"408px"}
                    desktopXL={{ flexBasis: "312px" }}
                    tablet={{ flexBasis: "408px" }}
                    mobile={{ flexBasis: "auto" }}
                >
                    <FieldTitle
                        color={color.text.inverted}
                        tag="p"
                        size="text14"
                        weight="medium"
                        className="mb4 mb0--mobile mr8--mobile"
                    >
                        {titleField1}
                    </FieldTitle>
                    <FieldBody className="field-body">
                        {numbersField1.map((num, idx) => (
                            <BoxNumbersSelected key={idx} className={num >= 0 ? "active" : undefined}>
                                <TitleAdaptive
                                    color={color.const.gsSolid["900"]}
                                    size="title20"
                                    tag="span"
                                    weight="medium"
                                >
                                    {num >= 0 ? num : ""}
                                </TitleAdaptive>
                            </BoxNumbersSelected>
                        ))}
                    </FieldBody>
                </FieldNumbersSelected>
                <FieldNumbersSelected
                    className={"field2"}
                    flexShrink={0}
                    flexBasis={"240px"}
                    desktopXL={{ flexBasis: "200px" }}
                    tablet={{ flexBasis: "105px" }}
                    mobile={{ flexBasis: "auto" }}
                >
                    <FieldTitle
                        color={color.text.inverted}
                        tag="p"
                        size="text14"
                        weight="medium"
                        className="mb4 mb0--mobile mr8--mobile"
                    >
                        {titleField2}
                    </FieldTitle>
                    <FieldBody className="field-body">
                        {numbersField2.map((num, idx) => (
                            <BoxNumbersSelected key={idx} className={num >= 0 ? "active" : undefined}>
                                <TitleAdaptive
                                    color={color.const.gsSolid["900"]}
                                    size="title20"
                                    tag="span"
                                    weight="medium"
                                >
                                    {num >= 0 ? num : ""}
                                </TitleAdaptive>
                            </BoxNumbersSelected>
                        ))}
                    </FieldBody>
                </FieldNumbersSelected>
            </TicketHeaderNumbers>
        </TicketHeader>
    );
};

export default Header;
