import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { BreadcrumbsWrapper, LinkWrap, LiStyled, StyledLink } from "./theme";
import { Text } from "../text";

type TBreadcrumbsProps = {
    className?: string;
    data: Array<{ title: string; path: string; exact?: boolean }>;
};

const Breadcrumbs = ({ data, className }: TBreadcrumbsProps) => {
    const { color } = useContext(ThemeContext);

    return (
        <BreadcrumbsWrapper className={className}>
            {data.map((item, idx) => {
                const { title, path } = item;
                return (
                    <LiStyled key={idx}>
                        <LinkWrap>
                            <StyledLink key={idx} to={path} exact={item.exact}>
                                <Text className="crumb-text" tag="div" size="text14" color={color.text.inverted}>
                                    {title}
                                </Text>
                            </StyledLink>
                            <Text
                                className="crumb-separator"
                                tag="div"
                                size="text14"
                                weight="medium"
                                color={color.text.inverted}
                            >
                                /
                            </Text>
                        </LinkWrap>
                    </LiStyled>
                );
            })}
        </BreadcrumbsWrapper>
    );
};

export default Breadcrumbs;
