import React from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { getMoneyFormat } from "~helpers/format";
import { TBingoEditionDetails } from "~types/products";

export const col = [
    {
        id: "tourName",
        width: "33%",
        name: "Тур",
        getValue(value: number) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "winTicketCount",
        width: "33%",
        name: "Выигравших билетов",
        getValue(value: string) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "winAmount",
        width: "33%",
        name: "Выигрыш",
        getValue(value: number) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {getMoneyFormat(value)} ₽
                </TextAdaptive>
            );
        },
    },
];

export const getTableData = (data: TBingoEditionDetails[]) => {
    return data.map((item) => {
        const { tourName, winTicketCount, winAmount } = item;
        return {
            tourName,
            winTicketCount,
            winAmount,
        };
    });
};
