import React, { Ref, ReactNode, DetailedHTMLProps, ButtonHTMLAttributes } from "react";
import { Link } from "~atoms/link";
import { ButtonAdaptiveStyled } from "./theme";

export type TVariant = "primary" | "secondary" | "tertiary" | "transparent" | "black";

export type TSize = "small" | "large";

export type TButtonAdaptiveProps = {
    variant?: TVariant;
    intent?: boolean;
    size?: TSize;
    href?: string;
    innerRef?: Ref<HTMLButtonElement>;
    prefixIcon?: ReactNode;
    suffixIcon?: ReactNode;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const ButtonAdaptive = ({
    children,
    variant = "primary",
    size = "large",
    className,
    innerRef,
    prefixIcon,
    suffixIcon,
    href,
    ...props
}: TButtonAdaptiveProps) => {
    const component = (
        <ButtonAdaptiveStyled {...props} ref={innerRef} className={className} variant={variant} size={size}>
            {prefixIcon && (
                <span role="img" className="prefix-wrap">
                    {prefixIcon}
                </span>
            )}
            <span>{children}</span>
            {suffixIcon && (
                <span role="img" className="suffix-wrap">
                    {suffixIcon}
                </span>
            )}
        </ButtonAdaptiveStyled>
    );

    if (href) {
        return <Link href={href}>{component}</Link>;
    }

    return component;
};

export default ButtonAdaptive;
