import React from "react";
import { docs } from "~assets/docs";
import { Grid } from "~atoms/grid";
import { Col } from "~atoms/grid/col";
import { Row } from "~atoms/grid/row";
import { PDFIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import logo from "./assets/logo.svg";
import { FooterWrapper, Logo, FilesBlockStyled, TextWrapper } from "./theme";

type TFooterProps = {
    className?: string;
};

const { audit2019, audit2020 } = docs;

const files = [
    {
        title: audit2019.title,
        link: audit2019.link,
        col: 6,
        desktopXL: 6,
        tablet: 6,
        mobile: 12,
    },
    {
        title: audit2020.title,
        link: audit2020.link,
        col: 6,
        desktopXL: 6,
        tablet: 6,
        mobile: 12,
    },
];

const Footer = ({ className }: TFooterProps) => {
    return (
        <FooterWrapper className={className}>
            <Grid>
                <Row>
                    <Col col={9.1} tablet={12} className="mb40 mb32--tablet mb16--mobile">
                        <Logo src={logo} alt="Национальная лотерея" className="mb32 mb24--tablet mb16--mobile" />
                        <TextWrapper>
                            <TextAdaptive size="text16" weight="medium" className="mb32 mb24--tablet mb16--mobile">
                                Национальная лотерея — это государственная лотерея, которая проводится на основании
                                распоряжения Правительства Российской Федерации от 29 августа 2019 г. № 1921-р.
                            </TextAdaptive>
                            <TextAdaptive size="text16" weight="medium">
                                Организатор лотереи: Министерство финансов Российской Федерации. Оператор лотереи: ООО
                                «Спортивные Лотереи».
                            </TextAdaptive>
                        </TextWrapper>
                    </Col>
                </Row>
            </Grid>
            <FilesBlockStyled files={files} prefixIcon={<PDFIcon />} />
        </FooterWrapper>
    );
};

export default Footer;
