export const breakpoints = {
    mobileSmall: 360,
    mobile: 425,
    tablet: 768,
    desktopXL: 1280,
    desktopXXL: 1440,
    desktopXXL2: 1472,
};

export const adaptive = {
    minWidth: {
        mobileSmall: `@media only screen and (min-width: ${breakpoints.mobileSmall}px)`,
        mobile: `@media only screen and (min-width: ${breakpoints.mobile}px)`,
        tablet: `@media only screen and (min-width: ${breakpoints.tablet}px)`,
        desktopXL: `@media only screen and (min-width: ${breakpoints.desktopXL}px)`,
        desktopXXL: `@media only screen and (min-width: ${breakpoints.desktopXXL}px)`,
        desktopXXL2: `@media only screen and (min-width: ${breakpoints.desktopXXL2}px)`,
    },
    maxWidth: {
        mobileSmall: `@media only screen and (max-width: ${breakpoints.mobileSmall - 1}px)`,
        mobile: `@media only screen and (max-width: ${breakpoints.mobile - 1}px)`,
        tablet: `@media only screen and (max-width: ${breakpoints.tablet - 1}px)`,
        desktopXL: `@media only screen and (max-width: ${breakpoints.desktopXL - 1}px)`,
        desktopXXL: `@media only screen and (max-width: ${breakpoints.desktopXXL - 1}px)`,
        desktopXXL2: `@media only screen and (max-width: ${breakpoints.desktopXXL2 - 1}px)`,
    },
};
