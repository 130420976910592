import React from "react";
import { useGetLotteryDataDigital } from "~organisms/lottery-data/_hooks/useGetLotteryDataDigital";
import LotteryDataView from "~organisms/lottery-data/lottery-data-view";
import { TTicketTypes } from "~store/tickets/types";
import { TDigitalAlias } from "~types/products";
import { digitalAlias } from "~constants/routes";

type TLotteryDataDigitalProps = {
    ticketType: TTicketTypes;
    className?: string;
    withoutEdition?: boolean;
    autonomous?: boolean;
};

const digitalTypes: Record<TTicketTypes, TDigitalAlias> = {
    ticket4in20: digitalAlias.t4in20,
    ticket5in50: digitalAlias.t5in50,
    ticket5in36: digitalAlias.t5in36,
    ticket8in20: digitalAlias.t8in20,
};

const LotteryDataDigital = ({
    ticketType,
    className,
    withoutEdition = false,
    autonomous = false,
}: TLotteryDataDigitalProps) => {
    const { nextEdition, isJackpot, jackpot } = useGetLotteryDataDigital(
        digitalTypes[ticketType],
        ticketType,
        autonomous,
    );

    return (
        <LotteryDataView
            ticketType={ticketType}
            className={className}
            isJackpot={isJackpot}
            jackpot={jackpot}
            nextEdition={nextEdition}
            withoutEdition={withoutEdition}
        />
    );
};

export default LotteryDataDigital;
