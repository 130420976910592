import mechtallion from "~organisms/last-winners-block/assets/mechtallion.png";
import winner1 from "~organisms/last-winners-block/assets/winner_1.png";
import winner2 from "~organisms/last-winners-block/assets/winner_2.png";
import winner3 from "~organisms/last-winners-block/assets/winner_3.png";
import winner4 from "~organisms/last-winners-block/assets/winner_4.png";
import winner5 from "~organisms/last-winners-block/assets/winner_5.png";
import winner6 from "~organisms/last-winners-block/assets/winner_6.png";
import { TWinnerMock } from "~organisms/last-winners-block/helper";
import { LOTTERY, WINNINGS } from "~constants/routes";

export const crumbs = [
    {
        title: "Лотереи",
        path: LOTTERY,
        exact: true,
    },
    {
        title: "Победители",
        path: WINNINGS,
        exact: true,
    },
];

export const winners: TWinnerMock[] = [
    {
        name: "Елизавета Р.",
        avatar: winner1,
        amount: "20 000 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "Bounty Hunter",
        avatar: winner2,
        amount: "4 125 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "jenya_1",
        avatar: winner3,
        amount: "7 127 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "Crystal Maiden",
        avatar: winner4,
        amount: "5 874 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "User12345",
        avatar: winner5,
        amount: "70 000 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "User23456",
        avatar: winner6,
        amount: "1 200 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "Елизавета Р.",
        avatar: winner1,
        amount: "20 000 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "Bounty Hunter",
        avatar: winner2,
        amount: "4 125 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "jenya_1",
        avatar: winner3,
        amount: "7 127 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "Crystal Maiden",
        avatar: winner4,
        amount: "5 874 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "User12345",
        avatar: winner5,
        amount: "70 000 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "User23456",
        avatar: winner6,
        amount: "1 200 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "Елизавета Р.",
        avatar: winner1,
        amount: "20 000 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "Bounty Hunter",
        avatar: winner2,
        amount: "4 125 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "jenya_1",
        avatar: winner3,
        amount: "7 127 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "Crystal Maiden",
        avatar: winner4,
        amount: "5 874 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "User12345",
        avatar: winner5,
        amount: "70 000 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
    {
        name: "User23456",
        avatar: winner6,
        amount: "1 200 ₽",
        lottery: "Мечталион",
        emblem: mechtallion,
        draw: "Тираж № 0127",
    },
];
