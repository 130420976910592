import React, { useCallback, useMemo, useRef } from "react";
import { Navigation, Pagination, A11y, Autoplay, SwiperOptions } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavigationOptions, Swiper as SwiperClass } from "swiper/types";
import { SliderSwiperWrapper, ArrowButton, ArrowStyled } from "~molecules/slider-swiper/theme";

export type TItem = {
    id: string | number;
    src: string;
    title: string;
    caption?: string;
    [key: string]: unknown;
};

type TSliderSwiperProps = {
    autoplay?: boolean;
    pagination?: boolean;
    navigation?: boolean;
    loop?: boolean;
    spaceBetween?: number;
    slidesPerView?: number;
    className?: string;
    autoHeight?: boolean;
    items: TItem[];
    breakpoints?: {
        [width: number]: SwiperOptions;
        [ratio: string]: SwiperOptions;
    };
    renderSlide: (item: TItem) => JSX.Element;
};

type TArrow = {
    type?: string;
    className?: string;
    onClick?: () => void;
};

const Arrow = ({ type, onClick, className = "" }: TArrow) => {
    return (
        <ArrowButton variant={"secondary"} onClick={onClick} className={`${className} arrow-btn-${type}`}>
            <ArrowStyled type={type} />
        </ArrowButton>
    );
};

const paginationSettings = {
    clickable: true,
};

const autoplaySettings = {
    delay: 5000,
    disableOnInteraction: false,
};

const SliderSwiper = ({
    className,
    items,
    renderSlide,
    pagination = false,
    navigation = false,
    loop = false,
    autoplay = false,
    autoHeight = false,
    spaceBetween = 24,
    slidesPerView = 3,
    breakpoints = {},
}: TSliderSwiperProps) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const initNavigation = useCallback((swiper: SwiperClass) => {
        setTimeout(() => {
            (swiper.params.navigation as NavigationOptions).prevEl = prevRef.current;
            (swiper.params.navigation as NavigationOptions).nextEl = nextRef.current;

            swiper.navigation.destroy();
            swiper.navigation.init();
            swiper.navigation.update();
        });
    }, []);

    const slidesJSX = useMemo(
        () => items.map((item) => <SwiperSlide key={item.id}>{renderSlide(item)}</SwiperSlide>),
        [items, renderSlide],
    );
    return (
        <SliderSwiperWrapper className={className} navigation={navigation}>
            <Swiper
                onSwiper={navigation ? initNavigation : undefined}
                loop={loop}
                breakpoints={breakpoints}
                autoHeight={autoHeight}
                autoplay={autoplay && autoplaySettings}
                modules={[Pagination, A11y, Autoplay, Navigation]}
                spaceBetween={spaceBetween}
                slidesPerView={slidesPerView}
                pagination={pagination && paginationSettings}
                navigation={
                    navigation && {
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }
                }
            >
                {slidesJSX}
            </Swiper>
            <div ref={prevRef}>
                <Arrow type={"prev"} />
            </div>
            <div ref={nextRef}>
                <Arrow type={"next"} />
            </div>
        </SliderSwiperWrapper>
    );
};

export default SliderSwiper;
