import styled from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { GREY_DARK2, GREY_LIGHT } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { ticketsColor } from "~constants/tickets-type";

export const Bingo3x3Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;
    max-width: 320px;
    width: 100%;
    ${adaptive.maxWidth.desktopXXL} {
        max-width: 368px;
    }
    ${adaptive.maxWidth.desktopXL} {
        max-width: 320px;
    }
    ${adaptive.maxWidth.tablet} {
        width: 296px;
        max-width: unset;
    }
    ${adaptive.maxWidth.mobileSmall} {
        zoom: 0.86;
        @supports not (zoom: 0.86) {
            & {
                transform: scale(0.86);
                transform-origin: 0 50%;
            }
        }
    }
    &:hover {
        cursor: pointer;
    }
`;

export const MaskWrapper = styled.div`
    border-radius: 10px;
    overflow: hidden;
    mask-image: linear-gradient(to bottom, black 0, black 36px, transparent 36px, transparent 100%),
        radial-gradient(circle at left 48px, transparent 9px, black 10px, black 50%, transparent 0),
        radial-gradient(circle at right 48px, transparent 9px, black 10px, black 50%, transparent 0),
        linear-gradient(to bottom, transparent 0, transparent 60px, black 60px, black 100%);
    ${adaptive.maxWidth.tablet} {
        mask-image: linear-gradient(to bottom, black 0, black 32px, transparent 32px, transparent 100%),
            radial-gradient(circle at left 44px, transparent 9px, black 10px, black 50%, transparent 0),
            radial-gradient(circle at right 44px, transparent 9px, black 10px, black 50%, transparent 0),
            linear-gradient(to bottom, transparent 0, transparent 56px, black 56px, black 100%);
    }
`;

export const TicketHeader = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${INDENT.xxxs};
    background: ${({ selected }) => (selected ? ticketsColor["bingo-3x3"] : GREY_DARK2)};
    position: relative;
    &:after {
        position: absolute;
        bottom: -1px;
        left: 0;
        content: "";
        width: 100%;
        height: 1px;
        z-index: 1;
        background: ${({ selected }) => (selected ? ticketsColor["bingo-3x3"] : GREY_DARK2)};
        mask-image: linear-gradient(90deg, transparent 0, transparent 4px, black 4px, black 8px);
        mask-repeat: repeat;
        mask-size: 8px 100%;
    }
`;

export const TicketCheckbox = styled.span<{ selected: boolean }>`
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: ${({ selected, theme }) =>
        selected ? `${theme.color.const.gsSolid["0"]}` : `${theme.color.icons.secondary}`};
    border: 1px solid
        ${({ selected, theme }) =>
            selected ? `${theme.color.const.gsSolid["0"]}` : `${theme.color.background.stroke}`};
    background-color: ${({ selected, theme }) =>
        selected ? `${theme.color.accent.positive}` : `${theme.color.const.gsSolid["0"]}`};
`;

export const HeaderLogo = styled.img`
    height: 32px;
    ${adaptive.maxWidth.tablet} {
        height: 28px;
    }
`;
export const TicketBody = styled.div`
    background-color: ${GREY_LIGHT};
    padding: ${INDENT.xxs} ${INDENT.none};
    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xxxs} ${INDENT.none};
    }
`;

export const FieldWrapper = styled.div`
    width: 256px;
`;

export const TicketFooter = styled.div<{ selected: boolean }>`
    padding: ${INDENT.xxxxs} ${INDENT.none};
    background: ${({ selected }) => (selected ? ticketsColor["bingo-3x3"] : GREY_DARK2)};
    & > p {
        width: fit-content;
    }
`;

export const HeaderPrice = styled(TextAdaptive)`
    width: fit-content;
    background: ${({ theme }) => theme.color.const.owrlLighten["200"]};
    border-radius: 8px;
    padding: ${INDENT.xxxxs} ${INDENT.xxxs};
`;
