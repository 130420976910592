import styled from "styled-components";
import { fieldsOffset, mainContentWrapper } from "~assets/styles/utils";
import { logoFooterIcon, logoFooterIconHovered } from "~assets/svg/icons";
import { Row } from "~atoms/grid/row";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const RowStyled = styled(Row)`
    box-shadow: ${({ theme }) => `inset 0px -1px 0px ${theme.color.blocks.footer.delimiter}`};
`;

export const FooterWrapper = styled.footer`
    display: block;
    background: ${({ theme }) => theme.color.blocks.footer.background};
    padding: ${INDENT.m} 0;

    ${adaptive.maxWidth.desktopXL} {
        padding: ${INDENT.sm} 0;
    }

    ${adaptive.maxWidth.tablet} {
        padding-bottom: calc(60px + ${INDENT.xs});
    }

    &.buy-ticket-bar-active {
        ${adaptive.maxWidth.desktopXXL} {
            padding-bottom: calc(68px + ${INDENT.sm});
        }

        ${adaptive.maxWidth.tablet} {
            padding-bottom: calc(60px + ${INDENT.ls} + ${INDENT.xs});
        }
    }
`;

export const FooterContent = styled.section`
    ${mainContentWrapper}
    ${fieldsOffset}
`;

export const LinkLogo = styled.a`
    background-color: transparent;
    text-decoration: none;
    flex-shrink: 0;

    & .footer-logo-icon {
        ${logoFooterIcon};
    }

    &:hover .footer-logo-icon {
        ${logoFooterIconHovered};
    }
`;

export const LinkStyled = styled.a`
    color: ${({ theme }) => theme.color.blocks.footer.text.link};
    cursor: pointer;
`;

export const NavigationMediaWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const BottomRowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const BottomWrapper = styled.div`
    position: relative;
    margin-top: ${INDENT.sm};
`;

export const BottomContentWrapper = styled.div`
    display: flex;

    ${adaptive.maxWidth.tablet} {
        margin-bottom: ${INDENT.xxxxs};
        display: block;
    }
`;

export const BottomTextWrapper = styled.div`
    max-width: 586px;

    ${adaptive.maxWidth.desktopXL} {
        max-width: 472px;
    }
`;

export const LogoWrapper = styled.div`
    max-width: 358px;
    width: 100%;

    ${adaptive.maxWidth.desktopXXL} {
        max-width: 314px;
    }

    ${adaptive.maxWidth.desktopXL} {
        max-width: 264px;
    }
`;

export const BottomInfoWrapper = styled.div`
    display: flex;

    ${adaptive.maxWidth.desktopXL} {
        margin-top: ${INDENT.xxxs};
    }

    ${adaptive.maxWidth.tablet} {
        margin-top: ${INDENT.xxxs};
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
`;

export const WrapperAdultFrame = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.blocks.footer.note.background};
    width: 32px;
    height: 32px;
    border-radius: 4px;

    border: 1px solid ${({ theme }) => theme.color.blocks.footer.note.border};
    position: absolute;
    right: 0;
    bottom: 0;

    ${adaptive.maxWidth.tablet} {
        top: ${INDENT.none};
    }
`;
