import { isNil } from "lodash-es";

export type TValue = string | number | null | undefined | boolean;

export const phoneValidation = (value: TValue) => {
    if (isNil(value)) return false;
    return value.toString().length === 10;
};
export const isTrue = (value: TValue) => {
    if (isNil(value)) return false;
    return !!value;
};

export const passwordValidation = (value: string) =>
    /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])[\da-zA-Z!@#$%^&*{}_?~/|+'`=-]{8,20}$/.test(value);

const reNickname = /^[a-zA-Z0-9!#$%&'()*+,-./<=>^`"_\[\]\\]+$|^[а-яёА-ЯЁ0-9!#$%&'()*+,-./<=>^`"_\[\]\\]+$/;

const reName = /^(?!["'-\s])(?!.*["'-\s]{2})(?!.*["'-\s]$)[а-яёА-ЯЁ"'-\s]+$/;

// Старая регулярка для валидации почты
// const reEmail =
//     /^[a-zA-Z0-9.!*$&^`|~#%'+/?{}=\-_]+@((?:[a-zA-Z0-9!#$%&'()*{}_?~+-./=^`"|]+\.)|(?:[а-яёА-ЯЁ0-9!#$%&'()*{}_?~+-./=^`"|]+\.))+[a-zA-Z]+$|^[а-яёА-ЯЁ0-9.!*$&^`|~#%'+/?{}=\-_]+@((?:[a-zA-Z0-9!#$%&'()*{}_?~+-./=^`"|]+\.)|(?:[а-яёА-ЯЁ0-9!#$%&'()*{}_?~+-./=^`"|]+\.))+[а-яёА-ЯЁ]+$|^[a-zA-Z0-9.!*$&^`|~#%'+/?{}=\-_]+@((?:[a-zA-Z0-9!#$%&'()*{}_?~+-./=^`"|]+\.)|(?:[а-яёА-ЯЁ0-9!#$%&'()*{}_?~+-./=^`"|]+\.))+[а-яёА-ЯЁ]+$|^[а-яёА-ЯЁ0-9.!*$&^`|~#%'+/?{}=\-_]+@((?:[a-zA-Z0-9!#$%&'()*{}_?~+-./=^`"|]+\.)|(?:[а-яёА-ЯЁ0-9!#$%&'()*{}_?~+-./=^`"|]+\.))+[a-zA-Z]+$/;

// Актуальная регулярка для валидации почты (как на бекенде)
const strPattern = new RegExp(
    "^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+|[а-яёА-ЯЁ0-9!#$%&'*+/=?^_`{|}~-]+)@" +
        "(" +
        "((([a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+|[а-яёА-ЯЁ0-9!#$%&'*+/=?^_`{|}~.-]+)\\.)*([а-яёА-ЯЁ0-9!#$%&'*+/=?^_`{|}~.-]+)\\.([а-яёА-ЯЁ]+))" +
        "|" +
        "((([a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+)\\.)+([a-zA-Z]+))" +
        ")$",
);
export const validateEmailText = (value: string) => strPattern.test(value);
export const validateNickname = (value: string) => reNickname.test(value);
export const validateName = (value: string) => reName.test(value);
