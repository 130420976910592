import React, { useEffect } from "react";
import { useBuyTicketsCardDigitalLogic } from "~organisms/buy-tickets-card/_hooks/useBuyTicketsCardDigitalLogic";
import { TTicketOptions } from "~pages/lottery/types";
import { TTicketTypes } from "~store/tickets/types";
import BuyTicketsBarView from "./buy-tickets-bar-view";

type TBuyTicketsBarProps = {
    className?: string;
    ticketType: TTicketTypes;
    ticketOptions: TTicketOptions;
};

const BuyTicketsBarDigital = ({ className, ticketType, ticketOptions }: TBuyTicketsBarProps) => {
    const {
        data: { ticketsCount, editionsCount, amount },
        cb: { handleBuyNow },
    } = useBuyTicketsCardDigitalLogic(ticketType, ticketOptions);

    const footer = document.querySelector(".footer");

    useEffect(() => {
        ticketsCount !== 0
            ? footer?.classList.add("buy-ticket-bar-active")
            : footer?.classList.remove("buy-ticket-bar-active");
    }, [footer?.classList, ticketsCount]);

    if (ticketsCount === 0) {
        return null;
    }

    return (
        <BuyTicketsBarView
            amount={amount}
            handleBuyNow={handleBuyNow}
            ticketsCount={ticketsCount}
            editionsCount={editionsCount}
            className={className}
        />
    );
};

export default BuyTicketsBarDigital;
