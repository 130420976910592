import { PlayingIcon, CloseIcon, RestoreIcon } from "~atoms/icons";
import { dateDifference, getTimeToStart, isDateExpired } from "~helpers/format";
import { TFuture } from "./future-block";

export const getFutureStatus = ({ drawDate, registrationStatus, paymentStatus, theme }: TFuture) => {
    if (registrationStatus === "CANCELED" && paymentStatus === "ACCEPTED") {
        return {
            color: theme.color.accent.negative,
            text: "Билет не зарегистрирован",
            iconColor: theme.color.accent.negative,
            icon: CloseIcon,
        };
    }

    const newDrawDate = drawDate?.slice(0, drawDate.lastIndexOf(":")) || null;
    const { weeks, days, hours, mins } = dateDifference(newDrawDate);
    const time = getTimeToStart(weeks, days, hours, mins);
    if (!isDateExpired(newDrawDate ?? time)) {
        return {
            color: theme.color.text.tertiary,
            text: `Состоится через ${time}`,
            iconColor: theme.color.icons.secondary,
            icon: RestoreIcon,
        };
    }
    return {
        color: theme.color.text.tertiary,
        text: "Идет розыгрыш",
        iconColor: theme.color.icons.secondary,
        icon: PlayingIcon,
    };
};
