import { useCallback, useEffect, useRef, useState } from "react";
import { TWinnerMock } from "~organisms/last-winners-block/helper";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useNotifications } from "~hooks/useNotifications";

export const useGetWinnersHistory = () => {
    const [winnersHistory, setWinnersHistory] = useState<TWinnerMock[]>([]);
    const totalPages = useRef(0);
    const sizePage = useRef(20);
    const nextPage = useRef(0);
    const { add } = useNotifications();
    const { winners } = useAppApi();

    const getWinnersHistory = useCallback(async () => {
        const { result, error } = await winners.getPaginatedWinners(nextPage.current, sizePage.current);
        if (error) {
            add({ message: error?.data?.message ?? otherErrors.getWinnersHistory });
            return null;
        }
        totalPages.current = result.totalPages || totalPages.current;
        nextPage.current += 1;
        setWinnersHistory((data) => [...data, ...result.content]);
    }, [add, winners]);

    useEffect(() => {
        void getWinnersHistory();
    }, [getWinnersHistory]);

    return {
        winnersHistory,
        sizePage,
        totalPages,
        getWinnersHistory,
        isFinished: nextPage.current > totalPages.current - 1,
    };
};
