import headBingo3x3 from "~pages/lottery/assets/bingo/3x3/head.png";
import headBingo75 from "~pages/lottery/assets/bingo/75/head.png";
import headBingo90 from "~pages/lottery/assets/bingo/90/head.png";
import head4in20 from "~pages/lottery/assets/digital/4-20/head.png";
import head5in36 from "~pages/lottery/assets/digital/5-36/head.png";
import head5in50 from "~pages/lottery/assets/digital/5-50/head.png";
import head8in20 from "~pages/lottery/assets/digital/8-20/head.png";
import { Rules as RulesBingo3x3 } from "~pages/lottery/bingo-lottery/bingo-3x3/rules";
import { Rules as RulesBingo75 } from "~pages/lottery/bingo-lottery/bingo-75/rules";
import { Rules as RulesBingo90 } from "~pages/lottery/bingo-lottery/bingo-90/rules";
import { Rules as Rules4in20 } from "~pages/lottery/digital-lottery/lottery-4in20/rules";
import { Rules as Rules5in36 } from "~pages/lottery/digital-lottery/lottery-5in36/rules";
import { Rules as Rules5in50 } from "~pages/lottery/digital-lottery/lottery-5in50/rules";
import { Rules as Rules8in20 } from "~pages/lottery/digital-lottery/lottery-8in20/rules";
import { getTicketNumber } from "~helpers/format";
import { TTicketStatus } from "~types/my-tickets";
import {
    getDetailsHistory4in20,
    getDetailsHistory8in20,
    getDetailsHistory5in36,
    getDetailsHistory5in50,
    getDetailsHistoryBingo90,
    getDetailsHistoryBingo75,
    getDetailsHistoryBingo3x3,
} from "~constants/routes";
import {
    getTicketDetailById,
    HOME,
    MY_TICKETS_UPCOMING,
    MY_TICKETS_WITH_WIN,
    MY_TICKETS_WITHOUT_WIN,
    ticketsAlias,
} from "~constants/routes";
import { ticketsBingoType, ticketsDigitalType } from "~constants/tickets-type";
import { TLotteryData } from "./my-ticket-page";

const getRoutes = (isFutureTicket = false, status: TTicketStatus | null) => {
    if (isFutureTicket) {
        return MY_TICKETS_UPCOMING;
    }

    if (!isFutureTicket && status === "NOT_WIN") {
        return MY_TICKETS_WITHOUT_WIN;
    }

    return MY_TICKETS_WITH_WIN;
};

const tabs = (id: string) => [
    {
        id: getTicketDetailById(id),
        title: "Билет",
    },
    {
        id: getTicketDetailById(id) + "/rules",
        title: "Как играть?",
    },
];

const crumbs = (id: string, isFutureTicket = false, status: TTicketStatus | null = null) => [
    {
        title: "Главная",
        path: HOME,
        exact: true,
    },
    {
        title: "Мои билеты",
        path: getRoutes(isFutureTicket, status),
        exact: true,
    },
    {
        title: `Билет № ${getTicketNumber(id)}`,
        path: getTicketDetailById(id),
        exact: false,
    },
];

export const getStatus = (isFutureTicket = false, status: TTicketStatus | null = null) => {
    if (!isFutureTicket && !status) return "payment";
    if (isFutureTicket) {
        return "future";
    } else {
        return status === "NOT_WIN" ? "notWin" : "win";
    }
};

const lotteryTypes: Record<string, TLotteryData> = {
    [ticketsAlias.t4in20]: {
        headJackpot: ticketsDigitalType.t4in20,
        headImg: head4in20,
        rulesPage: Rules4in20,
        toHistoryDetails: getDetailsHistory4in20,
    },
    [ticketsAlias.t5in36]: {
        headJackpot: ticketsDigitalType.t5in36,
        headImg: head5in36,
        rulesPage: Rules5in36,
        toHistoryDetails: getDetailsHistory5in36,
    },
    [ticketsAlias.t5in50]: {
        headJackpot: ticketsDigitalType.t5in50,
        headImg: head5in50,
        rulesPage: Rules5in50,
        toHistoryDetails: getDetailsHistory5in50,
    },
    [ticketsAlias.t8in20]: {
        headJackpot: ticketsDigitalType.t8in20,
        headImg: head8in20,
        rulesPage: Rules8in20,
        toHistoryDetails: getDetailsHistory8in20,
    },
    [ticketsAlias.b90]: {
        headJackpot: ticketsBingoType.b90,
        headImg: headBingo90,
        rulesPage: RulesBingo90,
        toHistoryDetails: getDetailsHistoryBingo90,
    },
    [ticketsAlias.b75]: {
        headJackpot: ticketsBingoType.b75,
        headImg: headBingo75,
        rulesPage: RulesBingo75,
        toHistoryDetails: getDetailsHistoryBingo75,
    },
    [ticketsAlias.b3x3]: {
        headJackpot: ticketsBingoType.b3x3,
        headImg: headBingo3x3,
        rulesPage: RulesBingo3x3,
        toHistoryDetails: getDetailsHistoryBingo3x3,
    },
};

export { tabs, crumbs, lotteryTypes };
