import React, { useCallback, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { Skeleton } from "~atoms/skeleton";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { useGetProducts } from "~organisms/lottery-info/_hooks/useGetProducts";
import { Page } from "~templates/page";
import { crumbs, getComponent, tabs } from "~pages/draw-history/helpers";
import { DrawHistoryWrapper, HeaderBlock, TabGroupStyled } from "~pages/draw-history/theme";
import { LinkBackStyled } from "~pages/lottery/momentary-lottery/common/theme";
import { WHITE } from "~tokens/colors";
import { getDrawHistoryById, LOTTERY } from "~constants/routes";
import useAppHistory from "~hooks/useAppHistory";
import { useAppScreen } from "~hooks/useAppScreen";
import { useAppSelector } from "~hooks/useAppSelector";

const DrawHistory = () => {
    const { go } = useAppHistory();
    const { handleGetProducts } = useGetProducts();
    const location = useLocation();
    const lotteries = useAppSelector((state) => state.lotteries.data);
    const typeScreen = useAppScreen();
    const handleClick = useCallback(
        (url) => {
            go(url);
        },
        [go],
    );

    useEffect(() => {
        if (!lotteries.length) void handleGetProducts();
    }, [handleGetProducts, lotteries.length]);

    const mainContent = (
        <DrawHistoryWrapper>
            {!lotteries.length ? (
                <Skeleton height={"400px"} width={"100%"} />
            ) : (
                <Switch>
                    {lotteries.map((item) => (
                        <Route
                            key={item.id}
                            path={getDrawHistoryById(item.id)}
                            component={getComponent(item.id)}
                            exact
                        />
                    ))}
                    <Redirect to={getDrawHistoryById(lotteries[0].id)} />
                </Switch>
            )}
        </DrawHistoryWrapper>
    );
    const headerBlock = (
        <HeaderBlock>
            {typeScreen === "mobile" ? (
                <LinkBackStyled href={LOTTERY} className="mb24" />
            ) : (
                <Breadcrumbs data={crumbs} className="mb24" />
            )}

            <TitleAdaptive tag={"h1"} size={"title38"} color={WHITE} className="mb24 mb16--mobile">
                Архив тиражей
            </TitleAdaptive>
            {!lotteries.length ? (
                <Skeleton height={"48px"} width={"100%"} />
            ) : (
                <TabGroupStyled
                    className={"mb24"}
                    onClick={handleClick}
                    tabs={tabs(lotteries)}
                    role={"link"}
                    color={WHITE}
                    size={3}
                    active={location.pathname}
                />
            )}
        </HeaderBlock>
    );

    return <Page asideContent={true} defaultBackground mainContent={mainContent} headerBlock={headerBlock} />;
};

export default DrawHistory;
