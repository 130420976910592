import React from "react";
import { BackgroundHeadWrapper } from "./theme";

type TBackgroundHeadProps = {
    className?: string;
    img?: string;
    children?: React.ReactNode;
    withImg?: boolean;
    withDefaultBackground?: boolean;
    height?: string;
    background?: string;
};

const BackgroundHead = ({
    className,
    img,
    withImg = false,
    withDefaultBackground = false,
    height = "424px",
    background,
    children,
}: TBackgroundHeadProps) => {
    return (
        <BackgroundHeadWrapper
            className={className}
            height={height}
            img={withImg && img ? img : null}
            background={background}
            withImg={withImg}
            withDefaultBackground={withDefaultBackground}
        >
            {children}
        </BackgroundHeadWrapper>
    );
};

export default BackgroundHead;
