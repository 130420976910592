import React, { useCallback, useContext } from "react";
import { ThemeContext } from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Text } from "~atoms/text";
import { TextAdaptive } from "~atoms/text-adaptive";
import { getLotteryRouteById } from "~constants/routes";
import { ticketsSlogan } from "~constants/tickets-type";
import useAppHistory from "~hooks/useAppHistory";
import {
    LongTicketWrapper,
    TicketActionZone,
    TicketActionZoneAmount,
    TicketActionZoneEdition,
    TicketActionZonePlaceholder,
    TicketDescription,
    TicketDescriptionBody,
    TicketDescriptionLogo,
    TicketDescriptionPrize,
    TicketDescriptionSlogan,
    TicketDescriptionTitle,
    TicketActionTime,
} from "./theme";
import { TLotteryTicketProps } from "../lottery-ticket";

type TLongTicketProps = Omit<TLotteryTicketProps, "type">;
const LongTicket = ({
    className,
    title,
    prize,
    note,
    edition,
    time,
    amount,
    backgroundUrl,
    id,
    logoSrc,
    active,
}: TLongTicketProps) => {
    const { go } = useAppHistory();
    const { color } = useContext(ThemeContext);

    const handleClick = useCallback(() => {
        go(getLotteryRouteById(id));
    }, [go, id]);

    return (
        <LongTicketWrapper className={className} backgroundUrl={backgroundUrl} onClick={handleClick}>
            <TicketDescription>
                <TicketDescriptionLogo src={logoSrc} alt={title} draggable={false} />
                <TicketDescriptionBody>
                    {active ? (
                        <>
                            <TicketDescriptionTitle color={color.text.inverted}>{title}</TicketDescriptionTitle>
                            <TicketDescriptionPrize color={color.text.inverted}>{prize}</TicketDescriptionPrize>
                            <Text color={color.text.inverted} size={2} weight={"medium"}>
                                {note}
                            </Text>
                        </>
                    ) : (
                        <TicketDescriptionSlogan color={color.text.inverted} size="text20" weight={"demiBold"}>
                            {ticketsSlogan[id]}
                        </TicketDescriptionSlogan>
                    )}
                </TicketDescriptionBody>
            </TicketDescription>
            <TicketActionZone>
                {active ? (
                    <>
                        <TicketActionZoneEdition color={color.text.primary}>{edition}</TicketActionZoneEdition>
                        <TicketActionTime color={color.text.tertiary}>{time}</TicketActionTime>
                        <TicketActionZoneAmount weight={"bold"} color={color.text.primary}>
                            {amount} ₽
                        </TicketActionZoneAmount>
                        <ButtonAdaptive size={"small"} intent>
                            <Text size="text16" weight={"medium"} color={color.text.primary}>
                                Купить билет
                            </Text>
                        </ButtonAdaptive>
                    </>
                ) : (
                    <TicketActionZonePlaceholder>
                        <TextAdaptive size={"text16"} color={color.text.tertiary} className={"mb24"}>
                            Нет активных тиражей
                        </TextAdaptive>
                        <TextAdaptive size={"text16"} color={color.text.primarylink}>
                            Как играть?
                        </TextAdaptive>
                    </TicketActionZonePlaceholder>
                )}

                {/* <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                    <ButtonAdaptive size={"small"} intent>
                        <Text size={3} weight={"demiBold"} color={"inherit"}>
                            Скоро в продаже
                        </Text>
                    </ButtonAdaptive>
                </div> */}
            </TicketActionZone>
        </LongTicketWrapper>
    );
};

export default LongTicket;
