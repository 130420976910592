import React from "react";
import { Link } from "react-router-dom";
import { BLACK } from "~tokens/colors";
import { TabStyled } from "./theme";

export type TSize = 1 | 2 | 3;

type TTabProps = {
    children: React.ReactNode;
    size?: TSize;
    active?: boolean;
    href?: string;
    role?: string;
    prefixIcon?: React.ReactNode;
    color?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const Tab = ({ active, children, onClick, href, size = 1, prefixIcon, color = BLACK, ...props }: TTabProps) => {
    const type = active ? "secondary" : "transparent";
    const component = (
        <TabStyled
            {...props}
            className={active ? "active-tab" : undefined}
            prefixIcon={prefixIcon}
            variant={type}
            color={color}
            onClick={onClick}
            size={size}
        >
            {children}
        </TabStyled>
    );

    if (href) {
        return <Link to={href}>{component}</Link>;
    }
    return component;
};

export default Tab;
