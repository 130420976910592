import React, { useCallback, useContext, useRef } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { authStatus } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useAppAuth } from "~hooks/useAppAuth";

export const useOnLogin = (setError: React.Dispatch<React.SetStateAction<string>>) => {
    const api = useAppApi();
    const allowedRequest = useRef(true);
    const { setUserAuth } = useAppAuth();
    const { onClose } = useContext(ModalWindowContext);
    return useCallback(
        async (phone: string, password: string) => {
            if (!allowedRequest.current) return;
            allowedRequest.current = false;
            setError("");
            const { error, result } = await api.auth.login({ phoneNumber: phone, password });
            if (error) {
                allowedRequest.current = true;
                if (error.data.code === authStatus.GATEWAY_AUTH_INVALID_CREDENTIAL.code) {
                    return setError(authStatus.GATEWAY_AUTH_INVALID_CREDENTIAL.value);
                }

                if (error.data.code === authStatus.GATEWAY_AUTH_LOCKED.code) {
                    return setError(authStatus.GATEWAY_AUTH_LOCKED.value);
                }

                return;
            }
            setUserAuth(result!.access_token);
            onClose();
        },
        [setError, api.auth, onClose, setUserAuth],
    );
};
