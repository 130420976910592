import styled, { css } from "styled-components";
import { adaptive } from "~assets/styles/tokens/screen";
import { WHITE } from "~tokens/colors";
import { INDENT } from "~tokens/sizes";
import { TCardP, TCardR } from "./card";

const sizeOne = css`
    padding: ${INDENT.xs};

    ${adaptive.minWidth.tablet} {
        padding: ${INDENT.sm};
    }

    ${adaptive.minWidth.desktopXL} {
        padding: ${INDENT.m};
    }
`;

const getPadding = (p: TCardP, adaptive: boolean) => {
    switch (p) {
        case 1:
            if (adaptive) {
                return sizeOne;
            }
            return `padding: ${INDENT.m}`;
        case 2:
            return `padding: ${INDENT.sm}`;
        case 3:
            return `padding: ${INDENT.xs}`;
        default:
            break;
    }
};

const getRadius = (r: TCardR) => {
    switch (r) {
        case 1:
            return INDENT.s;
        case 2:
            return INDENT.xxs;
        default:
            break;
    }
};

export const CardStyled = styled.div<{ p: TCardP; r: TCardR; adaptive: boolean }>`
    background-color: ${WHITE};
    border-radius: ${({ r }) => getRadius(r)};
    ${({ p, adaptive }) => getPadding(p, adaptive)};
`;
