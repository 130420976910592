import styled from "styled-components";
import iconCheckbox from "~atoms/checkbox/assets/checkbox.svg";
import { BLACK, GREY_DARK2, RED, WHITE } from "~tokens/colors";

export const CheckboxWrapper = styled.span`
    position: relative;
    display: block;
    input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        width: 18px;
        height: 18px;
    }
    input[type="checkbox"]:checked + label > span:before {
        transform: scale(1, 1);
    }

    input[type="checkbox"]:checked + label:after {
        background-color: ${({ theme }) => theme.color.controls.primary.default};
        border: 2px solid transparent;
    }

    // disabled
    input[type="checkbox"]:disabled + label {
        opacity: 0.3;
        cursor: default;
        // background-color: ${GREY_DARK2};
        &:hover {
            box-shadow: unset;
            border-radius: unset;
        }
    }
`;

export const getColor = (isError?: boolean) => {
    if (isError) return RED;

    return BLACK;
};

export const CheckboxLabel = styled.label<{ error?: boolean }>`
    user-select: none;
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 18px;
    font-size: 16px;
    line-height: 24px;

    &:after {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        border-radius: 3px;
        border: 2px solid ${(props) => getColor(props.error)};
        background-color: ${WHITE};
        top: 0;
        left: 0;
        transition: 0.2s;
    }
`;

export const CheckboxLabelText = styled.span`
    &:before {
        content: "";
        transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
        display: block;
        width: 10px;
        height: 10px;
        left: 4px;
        top: 5px;
        position: absolute;
        background-image: url(${iconCheckbox});
        background-repeat: no-repeat;
        background-size: contain;
        transform: scale(0, 0);
        z-index: 1;
    }
    font-size: 16px;
`;
