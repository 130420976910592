import { Link } from "react-router-dom";
import styled from "styled-components";
import { BLUE_NATIVE } from "~assets/styles/tokens/colors";
import { adaptive } from "~assets/styles/tokens/screen";
import { Skeleton } from "~atoms/skeleton";

const EmptyStateLotteryWrapper = styled.div`
    min-height: 600px;
`;

const TextWrap = styled.div``;

const LinkStyled = styled(Link)`
    color: ${BLUE_NATIVE};
`;

// Убрать по ненадобности
const LotteriesWrap = styled.div`
    zoom: 0.9;

    ${adaptive.maxWidth.mobileSmall} {
        zoom: 0.8;
    }

    ${adaptive.minWidth.tablet} {
        zoom: 1;
    }
`;

// Убрать по ненадобности
const SkeletonStyled = styled(Skeleton)`
    height: 224px;
    border-radius: 8px;
    ${adaptive.maxWidth.tablet} {
        height: 180px;
    }
`;

export { EmptyStateLotteryWrapper, LotteriesWrap, LinkStyled, TextWrap, SkeletonStyled };
