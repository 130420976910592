import styled from "styled-components";
import { transitionDefault } from "~assets/styles/utils";
import { Text } from "~atoms/text";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeight400, text16 } from "~tokens/typography";

export const NavigationMainWrapper = styled.div`
    display: block;

    ${adaptive.maxWidth.desktopXL} {
        display: none;
    }
`;

export const HeadingTextStyled = styled(Text)`
    margin-bottom: ${INDENT.xs};
    color: ${({ theme }) => theme.color.blocks.footer.text.primary};
`;

export const LinkList = styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
`;

export const LinkListItem = styled.li`
    margin-bottom: ${INDENT.xs};

    ${adaptive.maxWidth.mobile} {
        width: calc(50% - ${INDENT.xxxxs});
        margin-right: ${INDENT.none};
    }

    & > .footer-link {
        ${text16};
        ${fontWeight400};
        ${transitionDefault};
        color: ${({ theme }) => theme.color.blocks.footer.text.link};
        text-decoration: underline;

        &:hover {
            color: ${({ theme }) => theme.color.blocks.footer.text.linkActive};
        }
    }

    & > .footer-link.active {
        color: ${({ theme }) => theme.color.blocks.footer.text.linkActive};
    }
`;
