import React from "react";
import { TWeight } from "~atoms/text-adaptive/text-adaptive";
import { TTitleAdaptiveSize } from "~atoms/title-adaptive/title-adaptive";
import {
    title38,
    title24,
    title16,
    title12,
    title20,
    title32,
    fontWeightNormal,
    fontWeight500,
    fontWeight600,
    fontWeightBold,
} from "~tokens/typography";
import { TitleWrapper } from "./theme";

type TSizeTitle = 1 | 2 | 3 | 4 | "title32" | TTitleAdaptiveSize;

type TProps = {
    tag: "h1" | "h2" | "h3" | "h4" | "div" | "span";
    children: React.ReactNode;
    size?: TSizeTitle;
    color?: string;
    className?: string;
    weight?: TWeight;
};

const Title = ({ children, tag, size = 1, color, className, weight = "demiBold" }: TProps) => {
    const resultSize = {
        1: title38,
        2: title24,
        3: title16,
        4: title12,
        title12: title12,
        title16: title16,
        title20: title20,
        title24: title24,
        title32: title32,
        title38: title38,
    }[size];

    const resultWeight = {
        normal: fontWeightNormal,
        medium: fontWeight500,
        demiBold: fontWeight600,
        bold: fontWeightBold,
    }[weight];

    return (
        <TitleWrapper className={className} color={color} size={resultSize} as={tag} weight={resultWeight}>
            {children}
        </TitleWrapper>
    );
};

export default Title;
