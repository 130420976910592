import { Link } from "react-router-dom";
import styled from "styled-components";
import { INDENT } from "~assets/styles/tokens/sizes";
import { adaptive } from "~tokens/screen";

const LinkStyled = styled(Link)`
    color: ${({ theme }) => theme.color.text.primarylink};
`;

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    > div:last-child {
        margin-bottom: 0;
    }
    height: 476px;
    padding: ${INDENT.xs};

    ${adaptive.minWidth.tablet} {
        height: 304px;
        padding: ${INDENT.m} ${INDENT.sm};
    }

    ${adaptive.minWidth.desktopXL} {
        height: auto;
        min-width: 428px;
    }

    ${adaptive.minWidth.desktopXXL} {
        min-width: 505px;
    }
`;

const RowWrapper = styled.div`
    > span {
        white-space: nowrap;
    }
    display: flex;
    flex-direction: column;
    align-items: start;

    ${adaptive.minWidth.tablet} {
        flex-direction: row;
        align-items: center;
    }
`;

export { InfoWrapper, RowWrapper, LinkStyled };
