import React, { useCallback, useContext, useRef } from "react";
import { ThemeContext } from "styled-components";
import { TBox } from "~atoms/flex-box/flex-box";
import { pluralForm } from "~helpers/format";
import { useAppScreen } from "~hooks/useAppScreen";
import Box from "./box";
import { CountText, FieldNumbersBody, FieldNumbersHeader, FieldNumbersWrapper, TitleText } from "./theme";

type TFieldNumbersProps = {
    id: number;
    className?: string;
    title: string;
    values: number[];
    maxNum: number;
    onChange: (id: number, values: number[]) => void;
} & TBox;

const FieldNumbers2 = ({
    id,
    className,
    values,
    onChange,
    title,
    maxNum,
    flexShrink,
    flexBasis,
    flexGrow,
    tablet,
    mobile,
    desktopXL,
}: TFieldNumbersProps) => {
    const selectedNum = useRef(new Set([...values]));
    selectedNum.current = new Set([...values]);
    const typeScreen = useAppScreen();
    const { color } = useContext(ThemeContext);

    const handleSelected = useCallback(
        (value: number) => {
            if (selectedNum.current.has(value)) {
                selectedNum.current.delete(value);
            } else {
                if (selectedNum.current.size === maxNum) return;
                selectedNum.current.add(value);
            }

            onChange(id, [...selectedNum.current]);
        },
        [maxNum, id, onChange, selectedNum],
    );
    return (
        <FieldNumbersWrapper
            className={className}
            flexShrink={flexShrink}
            flexBasis={flexBasis}
            flexGrow={flexGrow}
            tablet={tablet}
            mobile={mobile}
            desktopXL={desktopXL}
        >
            <FieldNumbersHeader className="mb8 mb4--tablet">
                <TitleText tag="h3" size="title20" weight="medium">
                    {title}
                </TitleText>
                <CountText
                    tag="span"
                    color={selectedNum.current.size === maxNum ? `${color.text.positive}` : `${color.text.tertiary}`}
                    size="text14"
                >
                    {typeScreen.startsWith("desktop")
                        ? `Выбрано ${selectedNum.current.size} ${pluralForm(
                              selectedNum.current.size,
                              "число",
                              "числа",
                              "чисел",
                          )} из ${maxNum}`
                        : `${selectedNum.current.size}/${maxNum}`}
                </CountText>
            </FieldNumbersHeader>
            <FieldNumbersBody>
                {Array<number>(4)
                    .fill(1)
                    .map((item, i) => (
                        <Box
                            key={item + i}
                            isActive={selectedNum.current.has(item + i)}
                            onClick={handleSelected}
                            number={item + i}
                        />
                    ))}
            </FieldNumbersBody>
        </FieldNumbersWrapper>
    );
};

export default FieldNumbers2;
