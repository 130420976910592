import styled from "styled-components";
import { Text } from "~atoms/text";
import { TTicketDirection } from "~pages/lottery/momentary-lottery/common/head-content-block/head-content-block";
import { adaptive } from "~tokens/screen";
import { fontWeightBold } from "~tokens/typography";

export const TicketImageWrapper = styled.div<{ ticketDirection: TTicketDirection }>`
    display: block;

    img {
        width: 100%;
        height: auto;
        min-width: 320px;
        max-width: ${({ ticketDirection }) => ticketDirection === "horizontal" && "520px"};

        ${adaptive.maxWidth.desktopXL} {
            max-width: ${({ ticketDirection }) => ticketDirection === "horizontal" && "inherit"};
        }

        ${adaptive.maxWidth.tablet} {
            min-width: auto;
        }
    }

    ${adaptive.maxWidth.desktopXL} {
        width: 100%;
    }

    ${adaptive.maxWidth.tablet} {
        display: none;
    }
`;

export const TitleBlockTextWrapper = styled.div`
    max-width: 503px;
`;

export const TotalText = styled(Text)`
    font-size: 60px;
    line-height: 60px;
    white-space: nowrap;

    ${fontWeightBold}
    ${adaptive.maxWidth.tablet} {
        font-size: 38px;
        line-height: 38px;
    }
`;
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const TopContentWrapper = styled.div``;

export const BottomContentWrapper = styled.div``;
