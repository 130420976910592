import { Dispatch } from "react";
import { ACTIONS_TYPE, TMutationActions } from "./types";
import { AppState } from "../rootReducer";

export const eventFieldChange =
    (form: keyof AppState) =>
    (path: string, value: unknown) =>
    (dispatch: Dispatch<TMutationActions>): void => {
        dispatch({
            type: ACTIONS_TYPE.EVENT_FIELD_CHANGE,
            payload: {
                path,
                value,
            },
            meta: form,
        });
    };

export const eventFieldManyChange =
    (form: keyof AppState) =>
    (
        changes: Array<{
            path: string;
            value: unknown;
        }>,
    ) =>
    (dispatch: Dispatch<TMutationActions>): void => {
        dispatch({
            type: ACTIONS_TYPE.EVENT_FIELD_MANY_CHANGE,
            payload: changes,
            meta: form,
        });
    };
