import React, { useCallback, useContext, useMemo, useState } from "react";
import { ThemeContext } from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Flex } from "~atoms/flex-box";
import { EditIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { useGetBingoTickets } from "~pages/lottery/bingo-lottery/_hooks/useGetBingoTickets";
import { useUpdateMyNumbers } from "~pages/lottery/bingo-lottery/_hooks/useUpdateMyNumbers";
import { TicketsGroup } from "~pages/lottery/bingo-lottery/common/tickets-group";
import { TBingoTypes } from "~store/tickets/types";
import { useAppScreen } from "~hooks/useAppScreen";
import { useAppSelector } from "~hooks/useAppSelector";
import { useNotifications } from "~hooks/useNotifications";
import { Box } from "./box";
import { BoxNumbersSelected, MatrixNumbers, MyNumbersWrapper, NumbersContent, ResultNumbers } from "./theme";
import { ticketOptions } from "../helpers";

type TMyNumbersProps = {
    className?: string;
    ticketType: TBingoTypes;
};

const MyNumbers = ({ className, ticketType }: TMyNumbersProps) => {
    const typeScreen = useAppScreen();
    const { handleUpdateMyNumbers } = useUpdateMyNumbers(ticketType);
    const { handleGetBingoTickets, handleRefreshBingoTickets } = useGetBingoTickets({
        alias: ticketOptions[ticketType].alias,
        ticketType: ticketType,
        size: typeScreen === "mobile" ? ticketOptions[ticketType].sizeMobile : ticketOptions[ticketType].size,
        includeMyNumbers: true,
    });
    const ticketsLength = useAppSelector((state) => state.tickets[ticketType].data.length);
    const numbersSelected = useAppSelector((state) => state.tickets[ticketType].numbersSelected);
    const [selNumbers, setSelNumbers] = useState(new Set<number>(numbersSelected));
    const [mode, setMode] = useState<"view" | "input">("input");
    const { add } = useNotifications();
    const { color } = useContext(ThemeContext);

    const handleUpdateTickets = useCallback(() => {
        handleRefreshBingoTickets();
        void handleGetBingoTickets();
    }, [handleGetBingoTickets, handleRefreshBingoTickets]);

    const numbersField = useMemo(() => {
        const selectedNum = [...selNumbers];
        return selectedNum.length >= ticketOptions[ticketType].maxNumField
            ? selectedNum
            : Array(ticketOptions[ticketType].maxNumField)
                  .fill(-1)
                  .map((item, idx) => (selectedNum[idx] ? selectedNum[idx] : item));
    }, [selNumbers, ticketType]);

    const handleSelected = useCallback(
        (value: number) => {
            setSelNumbers((values) => {
                if (values.has(value)) {
                    values.delete(value);
                } else {
                    if (values.size === ticketOptions[ticketType].maxNumField) return values;
                    values.add(value);
                }
                return new Set(values);
            });
        },
        [ticketType],
    );

    const handleSearch = useCallback(() => {
        if (selNumbers.size === 0) {
            return add({ message: "Выберите число" });
        }
        handleUpdateMyNumbers(Array.from(selNumbers));
        handleRefreshBingoTickets();
        void handleGetBingoTickets();
        setMode("view");
    }, [selNumbers, handleUpdateMyNumbers, handleRefreshBingoTickets, handleGetBingoTickets, add]);

    const matrixJSX = mode === "input" && (
        <>
            <MatrixNumbers className="mb32 mb24--tablet mb16--mobile">
                {Array<number>(ticketOptions[ticketType].maxNumbers)
                    .fill(1)
                    .map((item, i) => {
                        return (
                            <Box
                                key={item + i}
                                isActive={selNumbers.has(item + i)}
                                onClick={handleSelected}
                                number={item + i}
                            />
                        );
                    })}
            </MatrixNumbers>
            <ButtonAdaptive size="large" intent onClick={handleSearch}>
                Показать билеты
            </ButtonAdaptive>
        </>
    );

    const groupTicketsJSX = mode === "view" && (
        <Flex alignItems={"center"} $direction={"column"} className={"mt16--desktopxl mt0--tablet"}>
            <TicketsGroup
                ticketsLength={ticketsLength}
                // nameGroup={"Полное совпадение ваших чисел"}
                className={"mb0--mobile"}
                ticketType={ticketType}
                includeMyNumbers
            />
            {/*<TicketsGroup ticketsLength={2} nameGroup={"Частичное совпадение ваших чисел"} includeMyNumbers />*/}
            <ButtonAdaptive
                size="large"
                className="mb32 mb24--desktopxl mb16--mobile mt20 mt8--desktopxl mt0--mobile"
                onClick={handleUpdateTickets}
            >
                Показать другие билеты
            </ButtonAdaptive>
        </Flex>
    );

    const buttonChangeJSX = mode === "view" && (
        <ButtonAdaptive
            variant={"secondary"}
            size={"small"}
            className={"ml8 ml0--mobile mt16--mobile mb24--mobile"}
            prefixIcon={<EditIcon />}
            onClick={() => setMode("input")}
        >
            Изменить
        </ButtonAdaptive>
    );

    return (
        <MyNumbersWrapper className={className}>
            <TitleAdaptive tag={"h4"} size={"title24"} className={"mb8"} color={color.text.headline} weight="normal">
                Выберите свои числа
            </TitleAdaptive>
            <TextAdaptive size={"text16"} className={"mb20"} color={color.text.primary}>
                Можно выбрать от 1 до {ticketOptions[ticketType].maxNumField} чисел
            </TextAdaptive>
            <ResultNumbers className={"mb32 mb16--mobile"}>
                <NumbersContent>
                    {numbersField.map((num, idx) => (
                        <BoxNumbersSelected
                            key={idx}
                            $active={num >= 0}
                            $focus={selNumbers.size === idx}
                            ticketType={ticketType}
                            className="mb8--mobile"
                        >
                            <TitleAdaptive color={color.text.inverted} size="title20" tag="span" weight="normal">
                                {num >= 0 ? num : ""}
                            </TitleAdaptive>
                        </BoxNumbersSelected>
                    ))}
                </NumbersContent>
                {buttonChangeJSX}
            </ResultNumbers>
            {matrixJSX}
            {groupTicketsJSX}
        </MyNumbersWrapper>
    );
};

export default MyNumbers;
