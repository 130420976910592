import React, { FormEventHandler, useCallback, useRef, useState } from "react";

type TFormOptions<T> = { validate?: (form: T) => Record<string, string>; onSubmit?: (form: T) => void };
export const useForm = <T extends Record<string, unknown>>(initialValues: T, options?: TFormOptions<T>) => {
    const initialState = useRef(initialValues);
    const [errors, setErrors] = useState<Partial<Record<string, string>>>({});
    const isSubmitting = useRef(false);
    const [form, setForm] = useState(initialValues);

    const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
        async (event) => {
            if (event) event.preventDefault();

            if (!options) return;
            const { validate, onSubmit } = options;

            if (isSubmitting.current) return;
            isSubmitting.current = true;

            if (validate) {
                const errors = validate(form);
                setErrors(errors);

                if (Object.keys(errors).length === 0) {
                    onSubmit && (await onSubmit(form));
                }
            } else {
                onSubmit && (await onSubmit(form));
            }

            isSubmitting.current = false;
        },
        [form, options],
    );

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        setForm((prevValues) => ({
            ...prevValues,
            [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value,
        }));
    }, []);

    const handleReset = useCallback(() => {
        setForm(initialState.current);
    }, []);

    return {
        handleChange,
        handleReset,
        handleSubmit,
        form,
        errors,
    };
};
