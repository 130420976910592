import styled, { css } from "styled-components";
import { Text } from "~atoms/text";
import { Title } from "~atoms/title";
import { RED } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeight500, text14, title24 } from "~tokens/typography";

export const AuthHeader = styled(Title)`
    ${title24};
    margin-bottom: ${INDENT.xxs};
`;

export const AuthDesc = styled(Text)`
    ${text14};
    margin-bottom: ${INDENT.m};
`;

export const wrapperAuthForm = css`
    width: 300px;
    min-height: 288px;
    margin: ${INDENT.xl};

    ${adaptive.maxWidth.desktopXXL} {
        min-height: 288px;
        margin: ${INDENT.xl} ${INDENT.xs};
    }

    ${adaptive.maxWidth.tablet} {
        min-height: 224px;
        margin: ${INDENT.xs} ${INDENT.none};
    }

    .auth-field-form {
        margin-bottom: ${INDENT.xs};
    }

    .auth-form-btn {
        margin-top: ${INDENT.xs};
        width: 100%;
    }

    .auth-create-acc-btn {
        margin-top: ${INDENT.m};
        width: 100%;
    }
`;

export const LinkWrapper = styled.div`
    text-align: center;
`;

export const ErrorText = styled(Text)`
    color: ${RED};
    ${text14};
    ${fontWeight500};

    &.error-text--center {
        text-align: center;
    }
`;
