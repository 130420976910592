import { useCallback } from "react";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useNotifications } from "~hooks/useNotifications";

export const useStartIdentificationIdentity = () => {
    const api = useAppApi();
    const { add } = useNotifications();
    return useCallback(async () => {
        const { result, error } = await api.profile.startIdentificationIdentity();
        if (error) {
            return add({ message: error?.data?.message ?? otherErrors.getIdentityEsia });
        }
        if (result) {
            location.href = result.linkUrl;
        }
    }, [add, api.profile]);
};
