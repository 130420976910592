import { useCallback } from "react";
import { TWalletPaymentType } from "~types/wallet";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";

export const useSendPayment = () => {
    const { wallet } = useAppApi();
    const store = useAppStore();
    const sendPayment = useCallback(
        async (paymentType: TWalletPaymentType, amount: number) => {
            const email = store.getState().profile.data?.email;
            return await wallet.createPayment(amount, paymentType, email);
        },
        [store, wallet],
    );

    return { sendPayment };
};
