import { useCallback } from "react";
import useAppApi from "~hooks/useAppApi";

export const useResendSms = () => {
    const api = useAppApi();
    return useCallback(
        async (phone: string) => {
            return await api.auth.sendSms(phone);
        },
        [api],
    );
};
