import { useCallback, useContext, useRef } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { TLoginReqData } from "~types/auth";
import useAppApi from "~hooks/useAppApi";
import { useAppAuth } from "~hooks/useAppAuth";

export const useSetPassword = (setError: React.Dispatch<React.SetStateAction<string>>) => {
    const api = useAppApi();
    const allowedRequest = useRef(true);
    const { setUserAuth } = useAppAuth();
    const { onClose } = useContext(ModalWindowContext);

    return useCallback(
        async (loginData: TLoginReqData, isReset?: boolean) => {
            if (!allowedRequest.current) return;
            allowedRequest.current = false;
            const { error, result } = isReset ? await api.auth.reset(loginData) : await api.auth.login(loginData);
            if (error) {
                allowedRequest.current = true;
                setError(error?.data?.message);
                return;
            }
            setError("");
            setUserAuth(result!.access_token);
            onClose();
        },
        [onClose, api.auth, setUserAuth, setError],
    );
};
