import React from "react";
import { BingoCombinations } from "~organisms/bingo-combinations";
import { TOptions } from "~organisms/bingo-combinations/bingo-combinations";

type TBingoCombo = {
    main: Array<number>;
    options: TOptions;
    selectNumbers?: Array<number>;
    sub?: Array<number>;
};

const BingoCombo = ({ main, sub, options, selectNumbers }: TBingoCombo) => {
    return (
        <>
            {main && (
                <BingoCombinations
                    data={main}
                    winningNumbers={selectNumbers}
                    options={options}
                    withColorNumbers={Boolean(selectNumbers?.length)}
                />
            )}
            {sub && (
                <BingoCombinations
                    className="mt4"
                    data={sub}
                    winningNumbers={selectNumbers}
                    options={options}
                    withColorNumbers={Boolean(selectNumbers?.length)}
                />
            )}
        </>
    );
};

export default BingoCombo;
