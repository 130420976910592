import { LOTTERY, MOMENTARY_2 } from "~constants/routes";

export const crumbs = [
    {
        title: "Лотереи",
        path: LOTTERY,
        exact: true,
    },
    {
        title: "Полет желаний",
        path: MOMENTARY_2,
        exact: true,
    },
];
