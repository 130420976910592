import styled from "styled-components";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { TProductType, TAlias } from "~types/products";
import { ticketsColor } from "~constants/tickets-type";

const getHeadSize = (alias: TAlias, type: TProductType) => {
    if (type === "DIGITAL") {
        return `
            height: 137px;

            ${adaptive.minWidth.tablet} {
                height: 187px;
            }
        `;
    }

    switch (alias) {
        case "bingo-3x3":
            return `
                height: 238px;

                ${adaptive.minWidth.tablet} {
                    height: 291px;
                }
        `;
        case "bingo-90":
            return `
                height: 243px;

                ${adaptive.minWidth.tablet} {
                    height: 371px;
                }
                ${adaptive.minWidth.desktopXL}{
                    padding: ${INDENT.xs} ${INDENT.xxxxxs};
                }
                ${adaptive.minWidth.desktopXXL}{
                    padding: ${INDENT.xs};
                }
        `;
        case "bingo-75":
            return `
                height: 326px;

                ${adaptive.minWidth.tablet} {
                    height: 373px;
                }
        `;
        default:
            break;
    }
};

const CombinationInfoWrapper = styled.div<{ $alias: TAlias; $type: TProductType }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: ${INDENT.xxxs} ${INDENT.xs} ${INDENT.xs} ${INDENT.xs};
    background: ${({ $alias }) => ticketsColor[$alias]};

    &:after {
        position: absolute;
        bottom: -1px;
        left: 0;
        content: "";
        width: 100%;
        height: 1px;
        z-index: 1;
        background: ${({ $alias }) => ticketsColor[$alias]};
        mask-image: linear-gradient(to right, transparent 0, transparent 4px, black 4px, black 8px);
        mask-repeat: repeat;
        mask-size: 8px 100%;
    }

    ${adaptive.minWidth.tablet} {
        padding: ${INDENT.xs};
    }

    ${({ $alias, $type }) => getHeadSize($alias, $type)};

    ${adaptive.minWidth.desktopXL} {
        height: auto;
        &:after {
            left: unset;
            bottom: unset;
            right: -1px;
            top: 0;
            height: 100%;
            width: 1px;
            mask-image: linear-gradient(to bottom, transparent 0, transparent 4px, black 4px, black 8px);
            mask-size: 100% 8px;
        }
    }
`;

const getBingoWrapSize = (alias: TAlias) => {
    switch (alias) {
        case "bingo-3x3":
            return `
                width: 265px;

                ${adaptive.minWidth.tablet} {
                    width: 304px;
                }
        `;
        case "bingo-90":
            return `
                width: 268px;

                ${adaptive.minWidth.tablet} {
                        width: 412px;
                }
            `;
        case "bingo-75":
            return `
                width: 263px;

                    ${adaptive.minWidth.tablet} {
                        width: 278px;
                    }
            `;
        default:
            break;
    }
};

const BingoWrap = styled.div<{ alias: TAlias }>`
    ${({ alias }) => getBingoWrapSize(alias)};
`;

const LogoImg = styled.img<{ $type: TProductType }>`
    height: 32px;

    &.dg-img {
        ${adaptive.minWidth.desktopXL} {
            margin-bottom: ${({ $type }) => $type === "DIGITAL" && "92px"};
        }
    }
`;

export { CombinationInfoWrapper, LogoImg, BingoWrap };
