import React, { useState, useEffect } from "react";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { EmptyStateLottery } from "~organisms/empty-state-lottery";
import { useAppSelector } from "~hooks/useAppSelector";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useGetTickets } from "../_hooks/useGetTickets";
import { Skeletons } from "../skeletons";
import { MyTicketStyled, TicketsWrap, ButtonWrap } from "../theme";

const WithWinTickets = () => {
    const [page, setPage] = useState(0);
    const { handleFieldChange } = useMutationHandles("myTickets");
    useGetTickets({ page, statuses: ["WIN", "WIN_PAYED"] });
    const { content, pagination } = useAppSelector((state) => state.myTickets.tickets);
    const isFinished = pagination && pagination.totalElements === content?.length;
    const onClick = () => {
        setPage((prev) => prev + 1);
    };
    useEffect(() => {
        return () => {
            handleFieldChange("tickets", { content: null });
        };
    }, [handleFieldChange]);
    return (
        <TicketsWrap className="mt32">
            {!content ? (
                <Skeletons />
            ) : (
                <>
                    {content.length ? (
                        <>
                            {content.map((item, index) => {
                                return <MyTicketStyled {...item} key={index} isContent="win" />;
                            })}
                            {!isFinished && (
                                <ButtonWrap className="mt64 mt48--tablet mt32--mobile mb32 mb24--tablet mb16--mobile">
                                    <ButtonAdaptive variant="secondary" onClick={onClick}>
                                        Показать еще
                                    </ButtonAdaptive>
                                </ButtonWrap>
                            )}
                        </>
                    ) : (
                        <EmptyStateLottery title="С выигрышем" />
                    )}
                </>
            )}
        </TicketsWrap>
    );
};

export default WithWinTickets;
