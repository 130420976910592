import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { Ball, BallWrapper, Field, FieldsWrapper } from "../theme";

type TDigitalFields = {
    main: Array<number>;
    sub: Array<number>;
    className?: string;
};

const DigitalFields = ({ className, main, sub }: TDigitalFields) => {
    const { color } = useContext(ThemeContext);
    return (
        <section className={className}>
            <TitleAdaptive size={"title24"} weight="normal" className={"mb16"}>
                Выигрышная комбинация
            </TitleAdaptive>
            <FieldsWrapper type="DIGITAL">
                <Field className="mb8">
                    <TextAdaptive size="text14" color={color.text.tertiary} className="mb4 mr12 mr0--tablet">
                        Поле 1
                    </TextAdaptive>
                    <BallWrapper>
                        {main.map((item, idx) => (
                            <Ball key={idx} variant={3}>
                                {item}
                            </Ball>
                        ))}
                    </BallWrapper>
                </Field>
                <Field>
                    <TextAdaptive size="text14" color={color.text.tertiary} className="mb4 mr12 mr0--tablet">
                        Поле 2
                    </TextAdaptive>
                    <BallWrapper>
                        {sub.map((item, idx) => (
                            <Ball key={idx} variant={3}>
                                {item}
                            </Ball>
                        ))}
                    </BallWrapper>
                </Field>
            </FieldsWrapper>
        </section>
    );
};

export default DigitalFields;
