import { useCallback } from "react";
import { TEdition, TTicketTypes } from "~store/tickets/types";
import { useMutationHandles } from "~hooks/useMutationHandles";

export const useUpdateTicketEditions = (ticketType: TTicketTypes, number: number) => {
    const { handleFieldChange } = useMutationHandles("tickets");
    const handleUpdateTicketEditions = useCallback(
        (editionsSelected: TEdition[]) => {
            handleFieldChange(`${ticketType}.data.${number}.editionsSelected`, editionsSelected);
        },
        [handleFieldChange, number, ticketType],
    );
    return { handleUpdateTicketEditions };
};
