export const tabs = [
    {
        id: "1",
        title: "Выбор билетов",
    },
    {
        id: "2",
        title: "Свои числа",
    },
];
