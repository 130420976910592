import React from "react";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { Maps } from "~organisms/maps";

type TMapProps = {
    className?: string;
};

const Map = ({ className }: TMapProps) => {
    return (
        <div id={"map"} className={className}>
            <TitleAdaptive tag={"h2"} size={"title24"} className={"mb24 mb20--tablet mb16--mobile"}>
                Где купить
            </TitleAdaptive>
            <Maps />
        </div>
    );
};

export default Map;
