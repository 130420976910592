import Scrollbars from "react-custom-scrollbars";
import styled from "styled-components";
import { Box } from "~atoms/flex-box";
import { GREY_LIGHT } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const TableHeadersStyled = styled.table`
    width: 100%;
    border-spacing: 0;

    td {
        text-align: left;
        height: 44px;

        ${adaptive.maxWidth.desktopXXL} {
            height: 54px;
        }
    }
`;

export const BoxStyled = styled(Box)`
    overflow: auto;
    width: 100%;
    height: 110px;

    ${adaptive.maxWidth.desktopXXL} {
        height: 127px;
    }
`;

export const TableHorizontalStyled = styled.table`
  width: max-content;
  border-spacing: ${INDENT.xxxxs} 0;

  tbody tr:first-child {
    td {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  tbody tr:last-child {
    td {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  td {
    text-align: center;
    width: 96px;
    height: 44px;
    background: ${GREY_LIGHT};

    ${adaptive.maxWidth.desktopXXL} {
      width: 91px;
      height: 54px;
    }
  }
}
`;

export const TableStyled = styled.table`
    border-collapse: separate;
    border-spacing: 0 ${INDENT.xxxxs};
    width: 100%;

    tbody tr {
        background: ${GREY_LIGHT};

        td:first-child {
            border-left-style: solid;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        td:last-child {
            border-right-style: solid;
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;
        }
    }

    th {
        padding: ${INDENT.xxxxs} ${INDENT.sm} ${INDENT.xxxxs} ${INDENT.none};
        text-align: right;
    }

    td {
        text-align: right;
        padding: ${INDENT.xs} ${INDENT.sm};
    }
`;

export const ScrollbarsStyled = styled(Scrollbars)``;
