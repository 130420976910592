import styled from "styled-components";
import { disableStyle } from "~assets/styles/utils";
import { INDENT } from "~tokens/sizes";
import { fontFamily, fontWeightNormal } from "~tokens/typography";

export const LinkWrapper = styled.a<{
    disabled: boolean | undefined;
    intent: boolean | undefined;
}>`
    display: inline-flex;
    align-items: center;
    ${fontFamily};
    ${fontWeightNormal};
    color: ${({ intent, theme }) => (intent ? theme.color.text.primary : theme.color.text.primarylink)};
    &:hover,
    &:active {
        color: ${({ intent, theme }) => (intent ? theme.color.accent.active : theme.color.text.primarylink)};
    }

    &[disabled] {
        color: ${({ theme }) => theme.color.controls.inactive};
        ${disableStyle}
    }

    .prefix-wrap,
    .suffix-wrap {
        display: inline-block;
        vertical-align: middle;
        line-height: 4px;
    }

    .prefix-wrap {
        margin-right: ${INDENT.xxs};
    }

    .suffix-wrap {
        margin-left: ${INDENT.xxs};
    }
`;
