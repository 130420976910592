import React, { ForwardedRef, ReactNode, useCallback, useContext } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { SelectModalBlockBingo } from "~organisms/select-modal/select-modal-block";
import SelectModalView from "~organisms/select-modal/select-modal-view";
import { getDateFromISO8601 } from "~helpers/format";
import { TBingoTypes } from "~store/tickets/types";
import { TBingoAlias } from "~types/products";
import { useAppSelector } from "~hooks/useAppSelector";
import { SkeletonStyled } from "./theme";

type TVariant = "filled" | "outlined";

export type TSelectModalProps = {
    className?: string;
    multiple?: boolean;
    autoWidth?: boolean;
    variant?: TVariant;
    suffixIcon?: ReactNode;
    ticketType: TBingoTypes;
    ticketAlias: TBingoAlias;
};

const SelectModalBingo = React.forwardRef(function SelectModal(
    props: TSelectModalProps,
    ref: ForwardedRef<HTMLDivElement>,
) {
    const { className, autoWidth, suffixIcon, ticketType, ticketAlias } = props;
    const { setContent } = useContext(ModalWindowContext);
    const firstDate = useAppSelector((state) => state.tickets[ticketType].editionsSelected[0].drawDate);
    const firstEdition = useAppSelector((state) => state.tickets[ticketType].editionsSelected[0]?.edition);

    const handleOpenSelectModal = useCallback(() => {
        setContent({
            component: SelectModalBlockBingo,
            props: {
                title: "Выберите тираж",
                ticketType,
                ticketAlias,
            },
            options: {
                btnClose: true,
                btnVariant: "secondary",
                btnIntent: false,
                borderRadius: 12,
            },
        });
    }, [setContent, ticketAlias, ticketType]);

    return (
        <>
            {!!firstDate && !!firstEdition ? (
                <SelectModalView
                    ref={ref}
                    title={`${getDateFromISO8601(firstDate, "DD MMM, в HH:mm")}, Тираж № ${firstEdition}`}
                    suffixIcon={suffixIcon}
                    autoWidth={autoWidth}
                    className={className}
                    handleOpen={handleOpenSelectModal}
                />
            ) : (
                <SkeletonStyled />
            )}
        </>
    );
});

export default SelectModalBingo;
