import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { Grid } from "~atoms/grid";
import { Col } from "~atoms/grid/col";
import { Row } from "~atoms/grid/row";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { AnotherWinnersBar } from "~organisms/another-winners-bar";
import { Page } from "~templates/page";
import { useAppScreen } from "~hooks/useAppScreen";
import { useGetWinnersHistory } from "./_hooks/useGetWinnersHistory";
import { crumbs } from "./helpers";
import { ButtonStyled, SkeletonStyled, TitleBlock, WinnerCardStyled, WinningsContent } from "./theme";

const Winnings = () => {
    const typeScreen = useAppScreen();
    const { color } = useContext(ThemeContext);

    const { winnersHistory, isFinished, getWinnersHistory } = useGetWinnersHistory();

    const mainContent = (
        <WinningsContent className="mb64 mb48--tablet">
            <TitleBlock className="mb8 mb0--mobile">
                <TitleAdaptive size="title24" weight="medium">
                    Недавние победы
                </TitleAdaptive>
            </TitleBlock>
            <Grid>
                <Row>
                    {!!winnersHistory?.length
                        ? winnersHistory.map((item, index) => (
                              <Col col={6} tablet={12} key={index} className="mt24 mt16--mobile">
                                  <WinnerCardStyled {...item} background={color.background.primaryElevated} />
                              </Col>
                          ))
                        : Array(10)
                              .fill(0)
                              .map((_, idx) => (
                                  <Col col={6} tablet={12} key={idx} className="mt24 mt16--mobile">
                                      <SkeletonStyled />
                                  </Col>
                              ))}
                </Row>
            </Grid>
            <ButtonStyled
                size="large"
                variant="secondary"
                className="mb32 mb24--tablet mb16--mobile mt64 mt48--tablet mt32--mobile mauto"
                onClick={getWinnersHistory}
                visible={!isFinished}
            >
                <TextAdaptive size="text16" weight="demiBold">
                    Показать еще
                </TextAdaptive>
            </ButtonStyled>
        </WinningsContent>
    );

    const asideContent = (
        <AnotherWinnersBar
            className="mb64 mb32--tablet mb0--mobile"
            title={"Истории победителей"}
            direction={typeScreen.startsWith("desktop") ? "vertical" : "horizontal"}
        />
    );

    const headerBlock = <Breadcrumbs data={crumbs} className="mb32 mb16--mobile" />;

    return (
        <Page
            mainContent={mainContent}
            asideContent={asideContent}
            defaultBackground
            title={"Победители"}
            headerBlock={headerBlock}
        />
    );
};

export default Winnings;
