import React, { useCallback } from "react";
import styled from "styled-components";
import { EyeIcon, EyeOffIcon } from "~atoms/icons";

type TIconPasswordProps = {
    className?: string;
    inputType: "password" | "text";
    setInputType: React.Dispatch<React.SetStateAction<{ confirm: "password" | "text"; password: "password" | "text" }>>;
    fieldName: "confirm" | "password";
    isShow?: boolean;
};

const IconPasswordWrapper = styled.button`
    background: transparent;
    display: inline-block;
`;

const IconPassword = ({ isShow = false, inputType, className, setInputType, fieldName }: TIconPasswordProps) => {
    const handleClick = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            setInputType((prevState) => ({
                ...prevState,
                [fieldName]: inputType === "password" ? "text" : "password",
            }));
        },
        [setInputType, fieldName, inputType],
    );

    if (!isShow) return null;
    return (
        <IconPasswordWrapper className={className} type={"button"} onClick={handleClick} tabIndex={-1}>
            {inputType === "password" ? <EyeIcon /> : <EyeOffIcon />}
        </IconPasswordWrapper>
    );
};

export default IconPassword;
