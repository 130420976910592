import React, { memo } from "react";
import styled, { css } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { BLACK, GREEN, GREY_DARK2 } from "~tokens/colors";
import { adaptive } from "~tokens/screen";

type TFieldNumbersProps = {
    data: Array<number>;
    options: TOptions;
    selectedNumbers?: Array<number>;
    winningNumbers?: Array<number>;
    color?: string;
    className?: string;
    textColor?: string;
    withColorNumbers?: boolean;
};

type TBoxSizing = {
    width: number;
    height: number;
};

export type TOptions = {
    mobile: TBoxSizing;
    desktop: TBoxSizing;
};

const FieldNumbersWrapper = styled.div`
    border-radius: 8px;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin: -1px;

    ${adaptive.maxWidth.desktopXXL} {
        //transform: scale(0.87);
        transform: scale(1);
    }

    ${adaptive.maxWidth.desktopXL} {
        transform: none;
    }
`;

const BoxNumbersSelected = css`
    &::after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 0;
        border-color: transparent ${({ theme }) => theme.color.const.blueberry.light} transparent transparent;
    }
`;

const BoxNumbers = styled.div<{ options: TOptions; color?: string; selected?: boolean }>`
    width: ${({ options }) => `${options.desktop.width}px`};
    height: ${({ options }) => `${options.desktop.height}px`};
    display: flex;
    margin: 1px;
    /* flex-grow: 1; */
    justify-content: center;
    align-items: center;
    position: relative;
    ${({ selected }) => selected && BoxNumbersSelected}
    background: ${({ color, theme }) => (color ? color : theme.color.const.gsSolid["100"])};
    ${adaptive.maxWidth.tablet} {
        width: ${({ options }) => `${options.mobile.width}px`};
        height: ${({ options }) => `${options.mobile.height}px`};
    }
`;

const BingoCombinations = memo(
    ({
        className,
        data,
        options,
        selectedNumbers,
        winningNumbers,
        color,
        withColorNumbers,
        textColor,
    }: TFieldNumbersProps) => {
        const defaultTextColor = textColor ?? BLACK;
        const getColorOfText = (num: number) => {
            return winningNumbers?.includes(num) ? GREEN : GREY_DARK2;
        };
        return (
            <FieldNumbersWrapper className={className}>
                {data.map((item, index) => {
                    return (
                        <BoxNumbers
                            key={index}
                            options={options}
                            selected={selectedNumbers?.includes(item)}
                            color={color}
                        >
                            <TextAdaptive
                                size="text16"
                                tag="span"
                                weight="medium"
                                color={withColorNumbers ? getColorOfText(item) : defaultTextColor}
                            >
                                {item ? item : ""}
                            </TextAdaptive>
                        </BoxNumbers>
                    );
                })}
            </FieldNumbersWrapper>
        );
    },
);

BingoCombinations.displayName = "FieldNumbers";
export default BingoCombinations;
