import Scrollbars from "react-custom-scrollbars";
import styled from "styled-components";
import { mainContentWrapper } from "~assets/styles/utils";
import { fieldsOffset } from "~assets/styles/utils";
import { GREY_DARK, GREY_LIGHT, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import {
    fontSize10,
    fontSize12,
    fontSize14,
    fontSize28,
    lineHeight14,
    lineHeight18,
    lineHeight32,
} from "~tokens/typography";

export const HeaderSliderWrapper = styled.div`
    display: block;
    position: relative;
    width: 100%;
    &&& .swiper-wrapper {
        height: 100% !important;
    }
    &&& .swiper {
        & > .swiper-pagination {
            bottom: 8%;
            .swiper-pagination-bullet {
                background-color: transparent;
                border: 1px solid ${WHITE};
            }
            .swiper-pagination-bullet-active {
                background-color: ${WHITE};
            }
            ${adaptive.maxWidth.desktopXXL} {
                .swiper-pagination-bullet {
                    width: 16px;
                    height: 16px;
                }
            }
            ${adaptive.maxWidth.tablet} {
                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                }
                bottom: 2px;
            }
        }
    }
`;

export const SlideWrapper = styled.div<{ backgroundUrl: string }>`
    position: relative;
    width: 100%;
    height: 350px;
    background-image: url(${(props) => props.backgroundUrl});
    background-repeat: no-repeat;
    background-position: 50% 0;
    ${adaptive.maxWidth.desktopXXL2} {
        background-position: 48% 0;
    }
    ${adaptive.maxWidth.desktopXXL} {
        background-position: 50% 0;
    }
    ${adaptive.maxWidth.tablet} {
        height: 200px;
    }

    ${adaptive.maxWidth.mobileSmall} {
        background-position: 30% 0;
    }
`;

export const SlideContent = styled.div`
    ${mainContentWrapper}
    ${fieldsOffset}


    div {
        width: 45%;
        padding-top: 100px;
        padding-left: 100px;

        ${adaptive.maxWidth.desktopXXL} {
            padding-top: 48px;
            padding-left: 8px;

            h5 {
                ${fontSize28}
                ${lineHeight32}
            }
        }
        ${adaptive.maxWidth.tablet} {
            padding-top: 20px;
            padding-left: 4px;
            width: 50%;
            h5 {
                ${fontSize14}
                ${lineHeight18}
            }
            p {
                ${fontSize12}
                ${lineHeight14}
            }
        }
        ${adaptive.maxWidth.mobileSmall} {
            padding-top: 20px;
            width: 50%;
            h5 {
                ${fontSize12}
                ${lineHeight18}
            }
            p {
                ${fontSize10}
                ${lineHeight14}
            }
        }
    }
`;

export const HeaderSliderBars = styled.div`
    width: 708px;
    margin: ${INDENT.none} ${INDENT.auto};
    position: relative;
    height: 120px;
    margin-top: -50px;
    z-index: 1;

    ${adaptive.maxWidth.tablet} {
        display: none;
    }
`;

export const ScrollbarsStyled = styled(Scrollbars)`
    width: 100%;
    height: 100%;
    & > .view {
        display: flex;
        justify-content: center;
        align-items: center;

        ${adaptive.maxWidth.desktopXXL} {
            flex-direction: column;
        }
        ${adaptive.maxWidth.tablet} {
            flex-direction: column;
            flex-direction: row;
            align-items: baseline;
            justify-content: center;
        }
        ${adaptive.maxWidth.mobile} {
            justify-content: flex-start;
            &&& {
                overflow-x: scroll;
            }
        }
    }
    & > .track-vertical,
    & > .thumb-vertical {
        display: none;
    }

    & > .track-horizontal {
        display: none;
        height: 7px;
        width: 100%;
        background: ${GREY_LIGHT};
        border-radius: 100px;
        padding: 2px;
        left: 0;
        bottom: 0;
        & > .thumb-horizontal {
            height: 5px;
            background: ${GREY_DARK};
            border-radius: 100px;
        }
        ${adaptive.maxWidth.mobile} {
            display: block;
        }
    }
`;
