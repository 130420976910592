import React, { useCallback } from "react";
import { CardList } from "~molecules/card-list";
import emblemMomentary1 from "~organisms/our-winners-area/assets/momentary1.png";
import { getWinnersHistory, WINNERS_1 } from "~constants/routes";
import { HistoryCardPlaceholder, HistoryCardStyled, LinkStyled, Wrapper } from "./theme";

type TAnotherWinners = {
    className?: string;
    direction?: "vertical" | "horizontal";
    title?: string;
    id?: number;
};

type TStoryItem = {
    id: number;
    background: string;
    comment: string;
    title: string;
    prize: number;
    lottery: string;
    name: string;
    src: string;
    url: string;
};

const stories: TStoryItem[] = [
    {
        id: 1,
        background: "linear-gradient(90deg, #7ED0F4 0%, #7CABFB 100%)",
        comment: "Оплатили ТО автомобиля",
        title: "История 1",
        prize: 25000,
        lottery: "Летим к мечте",
        name: "Нина и Дмитрий Майоровы",
        src: emblemMomentary1,
        url: WINNERS_1,
    },
    // {
    //     id: 2,
    //     background: "linear-gradient(90deg, #974AAE 0%, #5B2B94 100%)",
    //     comment: "Я собираюсь в путешествие!",
    //     title: "История 2",
    //     prize: 120000,
    //     lottery: "5 скорость",
    //     name: "Андрей Колесников",
    //     src: emblem5in36,
    //     url: WINNERS_2,
    // },
    // {
    //     id: 3,
    //     background: "linear-gradient(90deg, #E54EA4 0%, #7A388D 100%)",
    //     comment: "Потрачу выигрыш на учебу",
    //     title: "История 3",
    //     prize: 80000,
    //     lottery: "Супер 8",
    //     name: "Анна Морозова",
    //     src: emblem8in20,
    //     url: WINNERS_3,
    // },
];

const getStories = (stories: TStoryItem[], index?: number) => {
    return stories.filter((item) => item.id !== index);
};

const AnotherWinners = ({ title = "Другие истории побед", direction, className, id }: TAnotherWinners) => {
    const renderCards = useCallback(
        () => (
            <>
                {getStories(stories, id).map((item) => (
                    <LinkStyled
                        to={getWinnersHistory(item.id)}
                        key={item.id}
                        className={"mb24 mb0--tablet mr16--tablet"}
                    >
                        <HistoryCardStyled
                            background={item.background}
                            comment={item.comment}
                            prize={item.prize}
                            lottery={item.lottery}
                            name={item.name}
                            emblem={item.src}
                        />
                    </LinkStyled>
                ))}

                <HistoryCardPlaceholder />
            </>
        ),
        [id],
    );
    return (
        <Wrapper className={className}>
            <CardList
                title={title}
                direction={direction}
                renderCards={renderCards}
                isDrag={direction === "horizontal"}
            />
        </Wrapper>
    );
};

export default AnotherWinners;
