import { useCallback } from "react";
import { TBingoTypes, TEdition } from "~store/tickets/types";
import { useMutationHandles } from "~hooks/useMutationHandles";

export const useUpdateBingoEditions = (ticketType: TBingoTypes) => {
    const { handleFieldChange } = useMutationHandles("tickets");
    const handleUpdateBingoEditions = useCallback(
        (editionsSelected: TEdition[]) => {
            handleFieldChange(`${ticketType}.editionsSelected`, editionsSelected);
        },
        [handleFieldChange, ticketType],
    );
    return { handleUpdateBingoEditions };
};
