import styled from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Text } from "~atoms/text";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontSize18, text14 } from "~tokens/typography";

export const LongMobileTicketWrapper = styled.a<{ backgroundUrl: string }>`
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 54.87%;
    background-image: url(${(props) => props.backgroundUrl});
    background-size: cover;
    background-repeat: no-repeat;
    cursor: default;
`;

export const TicketDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 73%;
    width: 100%;
    padding: ${INDENT.xxs};
`;

export const TicketDescriptionLogo = styled.img`
    height: 44px;
    ${adaptive.maxWidth.tablet} {
        height: 32px;
    }
`;

export const TicketDescriptionBody = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

export const TicketDescriptionTitle = styled(Text)`
    margin-bottom: ${INDENT.xxxxs};
`;

export const TicketDescriptionPrize = styled(Text)`
    margin-bottom: ${INDENT.xxxxs};
`;

export const TicketDescriptionSlogan = styled(Text)`
    max-width: 220px;
`;

export const TicketActionZone = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 27%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${INDENT.xxxs} ${INDENT.xxs};
`;

export const TicketActionZoneEdition = styled(Text)`
    margin-bottom: -${INDENT.xxxxxs};
`;

export const TicketBuyZone = styled.div`
    display: flex;
    align-items: center;
`;

export const TicketActionZoneAmount = styled(Text)`
    ${fontSize18};
    padding-right: ${INDENT.xs};
`;

export const ButtonAdaptiveStyled = styled(ButtonAdaptive)`
    padding: ${INDENT.xxxxs} ${INDENT.xs};
    ${text14};
`;

export const TicketActionZonePlaceholder = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
`;
