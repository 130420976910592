import { TBingoTypes } from "~store/tickets/types";

export const getBingoType = (location: string): TBingoTypes => {
    if (location.indexOf("bingo-90") > 0) {
        return "bingo90";
    } else if (location.indexOf("bingo-75") > 0) {
        return "bingo75";
    }
    return "bingo3x3";
};
