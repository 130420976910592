import React, { useCallback, useState } from "react";
import { DefaultTheme } from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { AcceptedIcon, RestoreIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { Status } from "~molecules/status";
import { useGetPayouts } from "~pages/my-tickets/_hooks/useGetPayouts";
import { getMoneyFormat } from "~helpers/format";
import { TPaymentStatus, TTicketStatus } from "~types/my-tickets";
import { TScreen } from "~hooks/useAppScreen";
import { IconWrap, StatusText, Wrapper } from "./theme";

type TWinBlock = {
    typeScreen: TScreen;
    status: TTicketStatus;
    paymentStatus: TPaymentStatus;
    ticketNumber: string;
    prize: number;
    isIntoTicket?: boolean;
    theme: DefaultTheme;
};

const WinBlock = ({
    status,
    prize,
    typeScreen,
    ticketNumber,
    paymentStatus,
    isIntoTicket = false,
    theme,
}: TWinBlock) => {
    const [localPayment, setPayment] = useState(paymentStatus);
    const mobile = typeScreen === "mobile";
    const handleClick = useGetPayouts(setPayment);
    const onGetGain = useCallback(
        (e) => {
            e.preventDefault();
            handleClick(ticketNumber);
        },
        [handleClick, ticketNumber],
    );
    return (
        <Wrapper $isIntoTicket={isIntoTicket}>
            <div className="mb16 mb4--tablet mb0--mobile">
                <TextAdaptive size="text16" tag="div" color={theme.color.text.tertiary}>
                    Выигрыш
                </TextAdaptive>
                <TitleAdaptive weight={"medium"} color={theme.color.text.positive} size={"title24"}>
                    {getMoneyFormat(prize)} ₽
                </TitleAdaptive>
            </div>
            {localPayment === "PAYOUT_PROGRESS" ? (
                <Status
                    size="text16"
                    weight="normal"
                    icon={<RestoreIcon />}
                    color={theme.color.text.tertiary}
                    iconColor={theme.color.icons.secondary}
                >
                    Выплата в процессе
                </Status>
            ) : (
                <>
                    {status === "WIN_PAYED" ? (
                        <Status
                            className="mb4--mobile"
                            size="text16"
                            weight="normal"
                            color={theme.color.text.primary}
                            iconColor={theme.color.icons.primary}
                        >
                            <StatusText>{mobile ? "Получен" : "Выигрыш получен"}</StatusText>
                        </Status>
                    ) : (
                        <ButtonAdaptive className="mb4--mobile" intent size="small" onClick={onGetGain}>
                            <IconWrap>
                                <AcceptedIcon />
                            </IconWrap>
                            <StatusText>Получить {mobile ? "" : "выигрыш"}</StatusText>
                        </ButtonAdaptive>
                    )}
                </>
            )}
        </Wrapper>
    );
};

export default WinBlock;
