import styled from "styled-components";
import { adaptive } from "~assets/styles/tokens/screen";
import { INDENT } from "~assets/styles/tokens/sizes";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";

const CollapseWrapper = styled.div`
    padding: ${INDENT.xs};
    margin: -${INDENT.xs};
    background-color: ${({ theme }) => theme.color.background.primary};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    ${adaptive.minWidth.tablet} {
        padding: ${INDENT.sm};
        margin: -${INDENT.sm};
    }

    ${adaptive.minWidth.desktopXXL} {
        padding: ${INDENT.m};
        margin: -${INDENT.m};
    }
`;

const InProgressCount = styled.div`
    position: relative;
    top: -5px;
    text-align: center;
    display: inline-block;
    background-color: ${({ theme }) => theme.color.accent.accentPrimary};
    border-radius: 50%;
    width: 24px;
    height: 24px;

    > div {
        line-height: 26px;
    }
`;

const TextCount = styled(TextAdaptive)`
    line-height: 24px;
`;

const TitleCollapseText = styled(TitleAdaptive)`
    max-width: 224px;

    ${adaptive.minWidth.tablet} {
        max-width: 100%;
    }
`;

const TextCollapse = styled(TextAdaptive)`
    max-width: 230px;

    ${adaptive.minWidth.tablet} {
        max-width: 100%;
    }
`;

const IconWrap = styled.div<{ color: string }>`
    color: ${({ color }) => color};
`;

const CollapseTitleWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.color.background.primary};
`;

const TextWrap = styled.div`
    text-align: left;
`;

const ArrowIconWrap = styled.div`
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.background.primaryElevated};
`;

export {
    InProgressCount,
    IconWrap,
    TitleCollapseText,
    TextCollapse,
    TextCount,
    CollapseTitleWrap,
    TextWrap,
    ArrowIconWrap,
    CollapseWrapper,
};
