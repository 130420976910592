import styled from "styled-components";
import { Skeleton } from "~atoms/skeleton";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontSize38, fontSize56, fontWeight600, lineHeight48, lineHeight68, lineHeight84 } from "~tokens/typography";

export const BalanceWrapper = styled.div`
    border-radius: 20px;
    padding: ${INDENT.xs};
    background: ${WHITE};
    ${adaptive.minWidth.tablet} {
        padding: ${INDENT.m};
    }
`;

export const BalanceText = styled.span`
    ${fontSize56}
    ${lineHeight84}
    ${fontWeight600}
  	${adaptive.maxWidth.desktopXXL} {
        ${lineHeight68};
    }
    ${adaptive.maxWidth.tablet} {
        ${fontSize38}
        ${lineHeight48}
    }
`;

export const SkeletonStyled = styled(Skeleton)`
    border-radius: 10px;
    height: 84px;
    width: 250px;
    ${adaptive.maxWidth.desktopXXL} {
        height: 68px;
    }
    ${adaptive.maxWidth.tablet} {
        height: 48px;
    }
`;
