import styled from "styled-components";
import { transitionDefault } from "~assets/styles/utils";
import {
    avatarIcon,
    avatarIconFilled,
    ticketIcon,
    ticketIconFilled,
    walletIcon,
    walletIconFilled,
} from "~assets/svg/icons";
import { Button } from "~atoms/button";
import { Skeleton } from "~atoms/skeleton";
import { TextAdaptive } from "~atoms/text-adaptive";
import { PURPLE, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const ButtonsSidebarWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    background-color: ${WHITE};
    max-width: 320px;
    border-radius: 8px;
    padding: ${INDENT.xs};
`;

export const TabBodyWrapper = styled.div`
    display: flex;
    align-items: center;

    .tab-icon {
        margin-right: ${INDENT.xxs};
    }
`;

export const TabWrapper = styled.div`
    max-width: calc(320px - ${INDENT.xs});

    &:after {
        display: block;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #ebebeb;
        margin: ${INDENT.xxxs} ${INDENT.none};
    }

    & > button {
        max-height: 40px;
        height: 100%;
        width: 100%;
    }

    & > button {
        text-align: left;
        width: 100%;
    }

    &:last-of-type:after {
        display: none;
    }
`;

export const ButtonsHeaderWrapper = styled.div`
    display: flex;

    & > a,
    & > button,
    & > .dropdown-btn {
        margin-left: ${INDENT.sm};
    }

    & button {
        padding: ${INDENT.none};

        & > span {
            display: flex;
            align-items: center;
        }

        & .avatar-icon {
            ${avatarIcon}
        }

        & .wallet-icon {
            ${walletIcon};
        }

        & .tickets-icon {
            ${ticketIcon};
        }
    }

    & button:hover {
        & .avatar-icon {
            ${avatarIconFilled}
        }

        & .wallet-icon {
            ${walletIconFilled};
        }

        & .tickets-icon {
            ${ticketIconFilled};
        }
    }

    & > .login-btn .prefix-wrap {
        margin-right: ${INDENT.xxxs};
    }

    .basket-btn > span {
        height: 24px;
    }
`;

export const ButtonStyled = styled(Button)`
    display: flex;
    align-items: center;

    &:hover > span p {
        ${transitionDefault};
        color: ${PURPLE};
    }

    & > .prefix-wrap {
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
    }
`;

export const BalanceText = styled(TextAdaptive)`
    white-space: nowrap;
`;

export const SkeletonStyled = styled(Skeleton)`
    height: 40px;
    width: 400px;
    ${adaptive.maxWidth.tablet} {
        width: 48px;
        border-radius: 16px;
        margin-left: 24px;
    }
`;
