import React, { useState, useEffect, useCallback, useRef, ReactNode } from "react";
import { Skeleton } from "~atoms/skeleton";
import { Tab } from "~atoms/tab";
import { TabGroupWrapper } from "./theme";

type TTabGroupProps = {
    tabs: Array<{ id: string; title: ReactNode; icon?: ReactNode; href?: string }>;
    className?: string;
    size?: 1 | 2 | 3;
    active?: string;
    role?: string;
    onClick?: (id: string) => void;
    color?: string;
    loading?: boolean;
};

const TabGroup = ({ tabs, size = 1, onClick, active, color, className, role, loading = false }: TTabGroupProps) => {
    const [isActive, setActive] = useState(active as string | "");
    const tabList = useRef<HTMLUListElement>(null);
    const handleClick = useCallback(
        (id: string) => () => {
            setActive(id);
            onClick && onClick(id);
        },
        [onClick],
    );

    useEffect(() => {
        setTimeout(() => {
            if (tabList.current) {
                tabList.current
                    .querySelector(".active-tab")!
                    .scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
            }
        }, 200);
    }, [isActive]);

    useEffect(() => {
        if (active && active !== isActive) {
            setActive(active);
        }
    }, [active, isActive]);

    return (
        <TabGroupWrapper ref={tabList} className={className}>
            {loading ? (
                <Skeleton height="48px" radius="8px" />
            ) : (
                <>
                    {tabs.map((item) => {
                        const { title, id, icon, href } = item;
                        return (
                            <li key={id}>
                                <Tab
                                    prefixIcon={icon}
                                    size={size}
                                    onClick={handleClick(id)}
                                    active={id === isActive}
                                    color={color}
                                    role={role}
                                    href={href}
                                >
                                    {title}
                                </Tab>
                            </li>
                        );
                    })}
                </>
            )}
        </TabGroupWrapper>
    );
};

export default TabGroup;
