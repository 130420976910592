import styled from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { commonWrapperModalWindow } from "~pages/wallet/theme";

export const VerificationBlockWrapper = styled.div`
    ${commonWrapperModalWindow};
`;

export const AmountText = styled(TextAdaptive)`
    white-space: nowrap;
`;
