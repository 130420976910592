import { TBasketActions, TBasketState } from "./types";
import { MutationReducer } from "../mutation/reducer";

export const initBasketReducer = () => {
    const defaultState: TBasketState = {
        data: null,
    };

    return (state = defaultState, action: TBasketActions): TBasketState => {
        switch (action.type) {
            default:
                return MutationReducer<TBasketState>("basket", state, action);
        }
    };
};
