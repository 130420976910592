import styled, { DefaultTheme } from "styled-components";
import { transitionDefault } from "~assets/styles/utils";
import { TextAdaptive } from "~atoms/text-adaptive";

const getHover = (theme: DefaultTheme) => {
    return `
        &:hover {
             > span {
                color: ${theme.color.text.primary};
            }
            
            > svg {
                color: ${theme.color.icons.primary};
            }
        }
    `;
};

const RemoveButtonWrapper = styled.button<{ color?: string; iconColor?: string; hover: boolean }>`
    background-color: transparent;
    ${transitionDefault};
    color: ${({ color, theme }) => (color ? color : theme.color.text.tertiary)};

    & > svg {
        color: ${({ iconColor, theme }) => (iconColor ? iconColor : theme.color.icons.secondary)};
    }

    ${({ hover, theme }) => hover && getHover(theme)}
`;

const Text = styled(TextAdaptive)<{ color?: string }>`
    position: relative;
    bottom: 5px;
    color: ${({ color, theme }) => (color ? color : theme.color.text.tertiary)};
`;

export { RemoveButtonWrapper, Text };
