import { useCallback } from "react";
import { TWalletPayoutType } from "~types/wallet";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";

export const useSendPayout = () => {
    const { wallet } = useAppApi();
    const store = useAppStore();
    const sendPayout = useCallback(
        async (paymentType: TWalletPayoutType, amount: number) => {
            const email = store.getState().profile.data?.email;
            return await wallet.createPayout(amount, paymentType, email);
        },
        [store, wallet],
    );

    return { sendPayout };
};
