import { Link } from "react-router-dom";
import styled from "styled-components";
import { INDENT } from "~tokens/sizes";

export const LinkbackWrapperStyled = styled(Link)`
    display: inline-block;
    color: ${({ theme }) => theme.color.controls.tertiary};
    & span,
    & svg {
        vertical-align: middle;
    }

    & span {
        margin-left: ${INDENT.xxxs};
    }
`;
