import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Input } from "~atoms/input";
import { useCheckCode } from "~organisms/auth-form/code-block/_hooks/useCheckCode";
import { AuthDesc, AuthHeader, ErrorText, wrapperAuthForm } from "~organisms/auth-form/theme";
import { maskPhoneWithDots } from "~helpers/format";
import { TCodeStatusErrorType } from "~types/auth";
import { smsCodeStatus } from "~constants/error-messages";
import { useForm } from "~hooks/useForm";
import { SmsTimerBlock } from "./sms-timer-block";

type TCodeBlockProps = {
    className?: string;
    phone?: string;
    title?: string;
    isReset?: boolean;
    timerValue: number;
    operationId: string;
};

const CodeBlockWrapper = styled.div`
    ${wrapperAuthForm}
`;

const CodeBlock = ({
    className,
    phone = "",
    title = "Регистрация",
    operationId,
    isReset,
    timerValue,
}: TCodeBlockProps) => {
    const [errorCode, setErrorCode] = useState("");
    const [operationIdValue, setOperationId] = useState(operationId);

    const { form, handleChange, handleReset } = useForm({
        code: "",
    });

    const checkCode = useCheckCode(setErrorCode, isReset);

    useEffect(() => {
        if (form.code.length === 4) {
            void checkCode({ code: form.code, title, operationId: operationIdValue, phoneNumber: phone });
        }
    }, [title, checkCode, form.code, operationIdValue, phone]);

    const onResendSms = useCallback(
        (operationId: string) => {
            handleReset();
            setErrorCode("");
            setOperationId(operationId);
        },
        [handleReset, setErrorCode, setOperationId],
    );

    const handleSubmit = useCallback((event: any) => {
        event.preventDefault();
    }, []);

    return (
        <CodeBlockWrapper className={className}>
            <AuthHeader tag={"h4"}>{title}</AuthHeader>
            <AuthDesc>
                Введите СМС-код, отправленный на номер <span> {maskPhoneWithDots(phone)}</span>
            </AuthDesc>
            <form onSubmit={handleSubmit}>
                <Input
                    id={"auth-code"}
                    label={"Смс код"}
                    name={"code"}
                    placeholder="0000"
                    autoFocus={true}
                    typeMask={"sms_code"}
                    value={form.code}
                    onChange={handleChange}
                />
                {errorCode && (
                    <ErrorText className={"mt16"}>{smsCodeStatus[errorCode as TCodeStatusErrorType].value}</ErrorText>
                )}
            </form>
            <SmsTimerBlock onResendSms={onResendSms} phone={phone} errorCode={errorCode} timerValue={timerValue} />
        </CodeBlockWrapper>
    );
};

export default CodeBlock;
