import styled from "styled-components";
import { INDENT } from "~assets/styles/tokens/sizes";

const StatusWrapper = styled.div<{ $textCenter: boolean }>`
    display: flex;
    align-items: center;
    text-align: ${({ $textCenter }) => $textCenter && "center"};
`;

const TelIconWrap = styled.span<{ color?: string }>`
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: ${INDENT.xxxs};
    color: ${({ color, theme }) => (color ? color : theme.color.icons.secondary)};
`;

export { StatusWrapper, TelIconWrap };
