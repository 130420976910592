import React, { useState, useCallback, useContext } from "react";
import { ThemeContext } from "styled-components";
import { Collapse } from "~atoms/collapse";
import { Grid } from "~atoms/grid";
import { Title } from "~atoms/title";
import { QuestionsWrapper, TitleBlock } from "./theme";

type TQuestionsProps = {
    className?: string;
    data: {
        title: string;
        id: string;
        content: JSX.Element;
        col?: number;
        tablet?: number;
        mobile?: number;
        desktopXL?: number;
    }[];
};

const { Row, Col } = Grid;

const Questions = ({ className, data }: TQuestionsProps) => {
    const [isOpenId, setOpenId] = useState("");
    const { color } = useContext(ThemeContext);
    const onChange = useCallback((id: string, open: boolean) => {
        setOpenId(open ? id : "");
    }, []);
    return (
        <QuestionsWrapper className={className}>
            <TitleBlock>
                <Title tag="h2" size={1} color={color.text.headline} weight="medium">
                    Вопросы и ответы
                </Title>
            </TitleBlock>
            {data.map((item) => {
                const { id, title, content, col, tablet, mobile, desktopXL } = item;
                const open = isOpenId === id;
                return (
                    <Collapse
                        id={id}
                        weight="medium"
                        title={title}
                        key={id}
                        open={open}
                        onChange={onChange}
                        className="question-header"
                    >
                        <Grid>
                            <Row>
                                <Col
                                    col={col || 12}
                                    desktopXL={desktopXL || 12}
                                    tablet={tablet || 12}
                                    mobile={mobile || 12}
                                >
                                    {content}
                                </Col>
                            </Row>
                        </Grid>
                    </Collapse>
                );
            })}
        </QuestionsWrapper>
    );
};

export default Questions;
