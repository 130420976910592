import React, { useCallback } from "react";
import styled from "styled-components";
import { EyeIcon, EyeOffIcon } from "~atoms/icons";

type TIconPasswordProps = {
    className?: string;
    inputType: "password" | "text";
    setInputType: React.Dispatch<React.SetStateAction<{ password: "password" | "text" }>>;
    fieldName: "password";
    isShow?: boolean;
};

const IconPasswordWrapper = styled.span`
    background: transparent;
    display: inline-block;
    cursor: pointer;
`;

const IconPassword = ({ isShow = false, inputType, className, setInputType, fieldName }: TIconPasswordProps) => {
    const handleClick = useCallback(() => {
        setInputType((prevState) => ({
            ...prevState,
            [fieldName]: inputType === "password" ? "text" : "password",
        }));
    }, [setInputType, fieldName, inputType]);

    if (!isShow) return null;
    return (
        <IconPasswordWrapper className={className} onClick={handleClick}>
            {inputType === "password" ? <EyeIcon /> : <EyeOffIcon />}
        </IconPasswordWrapper>
    );
};

export default IconPassword;
