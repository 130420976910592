import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { CardList } from "~molecules/card-list";
import pic1 from "~organisms/instant-lotteries-slider/assets/pic1.png";
import pic2 from "~organisms/instant-lotteries-slider/assets/pic2.png";
import pic3 from "~organisms/instant-lotteries-slider/assets/pic3.png";
import { MomentaryTicket } from "~organisms/momentary-tickets-bar/momentary-ticket";
import { INDENT } from "~tokens/sizes";
import { MOMENTARY_1, MOMENTARY_2, MOMENTARY_3 } from "~constants/routes";

type TMomentaryTicketsBarProps = {
    className?: string;
    direction?: "vertical" | "horizontal";
    title?: string;
};

const MomentaryTicketsBarWrapper = styled.div`
    display: block;
`;

const MomentaryTicketStyled = styled(MomentaryTicket)`
    &:not(:last-child) {
        margin-bottom: ${INDENT.m};
    }

    img {
        width: 100%;
        height: auto;
    }
`;

const listLotteries = [
    {
        id: MOMENTARY_1,
        title: "Летим к мечте",
        prize: "Главный приз 500 000 ₽",
        srcImg: pic1,
    },
    {
        id: MOMENTARY_2,
        title: "Полет желаний",
        prize: "Главный приз 100 000 ₽",
        srcImg: pic2,
    },
    {
        id: MOMENTARY_3,
        title: "Путь к удаче",
        prize: "Главный приз 100 000 ₽",
        srcImg: pic3,
    },
    // {
    //     id: MOMENTARY_4,
    //     title: "Счастливый билет. Рим",
    //     prize: "Главный приз 350 000 ₽",
    //     srcImg: pic4,
    // },
    // {
    //     id: MOMENTARY_5,
    //     title: "Счастливый билет. Париж",
    //     prize: "Главный приз 350 000 ₽",
    //     srcImg: pic5,
    // },
    // {
    //     id: MOMENTARY_6,
    //     title: "Счастливый билет. Сочи",
    //     prize: "Главный приз 350 000 ₽",
    //     srcImg: pic6,
    // },
    // {
    //     id: MOMENTARY_7,
    //     title: "Счастливый билет. Казань",
    //     prize: "Главный приз 350 000 ₽",
    //     srcImg: pic7,
    // },
    // {
    //     id: MOMENTARY_8,
    //     title: "Побег с поезда",
    //     prize: "Главный приз 1 000 000 ₽",
    //     srcImg: pic8,
    // },
    // {
    //     id: MOMENTARY_9,
    //     title: "Золотое дело",
    //     prize: "Главный приз 1 000 000 ₽",
    //     srcImg: pic9,
    // },
];

const MomentaryTicketsBar = ({ title = "Другие лотереи", direction, className }: TMomentaryTicketsBarProps) => {
    const location = useLocation();
    const renderCards = useCallback(
        () => (
            <>
                {listLotteries
                    .filter((lottery) => lottery.id !== location.pathname)
                    .map((item, index) => (
                        <MomentaryTicketStyled
                            key={index}
                            url={item.id}
                            srcImg={item.srcImg}
                            title={item.title}
                            prize={item.prize}
                            className={"ml-ticket"}
                        />
                    ))}
            </>
        ),
        [location.pathname],
    );

    return (
        <MomentaryTicketsBarWrapper className={className}>
            <CardList
                title={title}
                direction={direction}
                renderCards={renderCards}
                isDrag={direction === "horizontal"}
            />
        </MomentaryTicketsBarWrapper>
    );
};

export default MomentaryTicketsBar;
