import React from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { getMoneyFormat } from "~helpers/format";
import { TDigitalEditionDetails } from "~types/products";

export const col = [
    {
        id: "category",
        width: "15%",
        name: "Категория",
        getValue(value: number) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "guessNumbers",
        width: "14.5%",
        name: "Угаданных чисел",
        getValue(value: string) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "winCombinationCount",
        width: "20%",
        name: "Выигравших комбинаций",
        getValue(value: number) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "ticketWinAmount",
        width: "15%",
        name: "Выигрыш",
        getValue(value: number) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {getMoneyFormat(value)} ₽
                </TextAdaptive>
            );
        },
    },
    {
        id: "sumWinAmount",
        width: "15%",
        name: "Сумма выигрышей",
        getValue(value: number) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {getMoneyFormat(value)} ₽
                </TextAdaptive>
            );
        },
    },
];

export const getTableData = (data: TDigitalEditionDetails[]) => {
    return data.map((item) => {
        const {
            categoryNumber,
            ticketWinAmount,
            sumWinAmount,
            winCombinationCount,
            guessedNumberMainFieldCount,
            guessedNumberAdditionalFieldCount,
        } = item;
        return {
            category: categoryNumber,
            guessNumbers: `${guessedNumberMainFieldCount} | ${guessedNumberAdditionalFieldCount}`,
            winCombinationCount,
            ticketWinAmount,
            sumWinAmount: sumWinAmount ?? 0,
        };
    });
};
