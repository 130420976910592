import { useCallback } from "react";
import { TTicketOptions } from "~pages/lottery/types";
import { TTicketTypes } from "~store/tickets/types";
import { TPaymentType } from "~types/order";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";
import { getFullFillTickets } from "./useGetBuyTicketsInfoDigital";

export const useSendOrderDigital = (ticketType: TTicketTypes, ticketOptions: TTicketOptions) => {
    const { order } = useAppApi();
    const store = useAppStore();
    const sendOrder = useCallback(
        async (paymentType: TPaymentType) => {
            const ticketContent = store.getState().tickets[ticketType];
            const email = store.getState().profile.data?.email;
            return await order.createDigital(
                ticketContent.productId!,
                getFullFillTickets(ticketContent.data, ticketOptions),
                email!,
                paymentType,
            );
        },
        [store, ticketType, order, ticketOptions],
    );

    return { sendOrder };
};
