import React, { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "~atoms/loader";
import ProtectedRoute from "~organisms/protected-route/protected-route";
import { Main } from "~templates/main";
import { Wallet } from "~pages/wallet";
import {
    CONTACTS,
    MY_TICKETS,
    MY_TICKETS_BY_ID,
    POLITICS_WEB_BY_ID,
    PROFILE,
    WALLET,
    WINNERS_HISTORY,
} from "~constants/routes";
import { useAppInit } from "~hooks/useAppInit";
import { useAppSelector } from "~hooks/useAppSelector";
import { useScrollToTop } from "~hooks/useScrollToTop";
import { Contacts } from "./contacts";
import { MyTicketPage } from "./my-ticket-page";
import PoliticsWeb from "./politics/politics-web";
import { useGetProfile } from "./profile/_hooks/useGetProfile";
import { routes } from "./routes";

const Profile = React.lazy(() => import("./profile"));
const MyTickets = React.lazy(() => import("./my-tickets/my-tickets"));
const WinnersHistory = React.lazy(() => import("./winners-history/winners-history"));

const LoaderStyled = styled(Loader)`
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    z-index: 10000;
`;

export default function Index() {
    const isAuth = useAppSelector((state) => state.user.auth);
    const guardAuth = useMemo(() => [() => isAuth], [isAuth]);
    useScrollToTop();
    useGetProfile();
    useAppInit();

    return (
        <Main>
            <React.Suspense fallback={<LoaderStyled />}>
                <Switch>
                    {routes.map((route) => (
                        <Route key={route.url} path={route.url} component={route.component} />
                    ))}
                    <Route path={WINNERS_HISTORY} component={WinnersHistory} />
                    <Route path={POLITICS_WEB_BY_ID} component={PoliticsWeb} />
                    <Route path={CONTACTS} component={Contacts} />
                    <ProtectedRoute guards={guardAuth} path={MY_TICKETS} component={MyTickets} />
                    <ProtectedRoute guards={guardAuth} path={MY_TICKETS_BY_ID} component={MyTicketPage} />
                    <ProtectedRoute guards={guardAuth} path={PROFILE} component={Profile} />
                    <ProtectedRoute guards={guardAuth} path={WALLET} component={Wallet} />
                    {/*<Route path={STORE_REDIRECT} component={StoreRedirect} />*/}
                    {/*<Route path={BASKET} component={Basket} />*/}
                    {/*<Route path={FEEDBACK} component={Feedback} />*/}
                    <Redirect from={"*"} to={routes[0].url} />
                </Switch>
            </React.Suspense>
        </Main>
    );
}
