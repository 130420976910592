import { Link } from "react-router-dom";
import styled from "styled-components";
import { adaptive } from "~assets/styles/tokens/screen";
import { INDENT } from "~assets/styles/tokens/sizes";
import { HistoryCard } from "~organisms/history-card";
import backgroundMobile from "~organisms/our-winners-area/assets/PlaceholderMobile.png";
import { fontSize14, fontSize18, fontSize20, lineHeight18, lineHeight24, lineHeight26 } from "~tokens/typography";

const Wrapper = styled.div``;

const LinkStyled = styled(Link)<{ direction?: "vertical" | "horizontal" }>`
    // &:not(:last-child) {
    //     margin-right: ${INDENT.xs};
    // }
    //
    // ${adaptive.minWidth.desktopXL} {
    //     &:not(:last-child) {
    //         // margin-right: ${INDENT.none};
    //         margin-bottom: ${INDENT.sm};
    //     }
    // }
    flex-shrink: 0;
    width: 100%;

    ${adaptive.maxWidth.desktopXL} {
        width: 229px;
    }
    ${adaptive.maxWidth.tablet} {
        width: 278px;
    }
`;

const HistoryCardStyled = styled(HistoryCard)`
    height: 188px;
    padding: ${INDENT.xs};

    & .comment-text {
        ${lineHeight24};
        ${fontSize18};
    }
    & .name-text {
        ${fontSize14};
        ${lineHeight18};
        margin-bottom: ${INDENT.xxxxs};
    }

    & .lottery-content {
        flex-direction: column;
        align-items: flex-start;
        justify-content: unset;

        & .winner-info {
            margin-bottom: ${INDENT.xxxxs};
            & > span {
                ${fontSize20};
                ${lineHeight26};
            }
        }
    }
`;

const HistoryCardPlaceholder = styled.a`
    height: 188px;
    width: 100%;
    margin: 0;
    ${adaptive.maxWidth.desktopXL} {
        max-width: 229px;
    }
    ${adaptive.maxWidth.tablet} {
        min-width: 278px;
    }

    background-image: url(${backgroundMobile});
    background-repeat: no-repeat;
    border-radius: 12px;
`;

export { Wrapper, LinkStyled, HistoryCardStyled, HistoryCardPlaceholder };
