import React, { useCallback, useContext } from "react";
import { NavLink } from "react-router-dom";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import LoginBlock from "~organisms/auth-form/login-block/login-block";
import { getMoneyFormat } from "~helpers/format";
import { LOTTERY, MY_TICKETS_UPCOMING, WALLET } from "~constants/routes";
import { useAppSelector } from "~hooks/useAppSelector";
import { LinkText, NavigationTabBarWrapper } from "./theme";

type TNavigationTabBarProps = {
    className?: string;
};

const NavigationTabBar = ({ className }: TNavigationTabBarProps) => {
    const isAuth = useAppSelector((state) => state.user.auth);
    const balance = useAppSelector((state) => state.profile.data?.balance);
    const { setContent } = useContext(ModalWindowContext);

    const handleOpenAuthForm = useCallback(() => {
        setContent({ component: LoginBlock, options: { btnClose: true } });
    }, [setContent]);

    return (
        <NavigationTabBarWrapper className={className}>
            <NavLink role={"button"} to={LOTTERY} itemProp="url" className={"tab-bar-link"}>
                <div className="home-icon mb4" />
                <LinkText>Лотереи</LinkText>
            </NavLink>
            {/*<NavLink role={"button"} to={WINNINGS} itemProp="url" className={"tab-bar-link"}>*/}
            {/*    <div className="cup-icon mb4" />*/}
            {/*    <LinkText>Победители</LinkText>*/}
            {/*</NavLink>*/}
            {isAuth ? (
                <>
                    <NavLink role={"button"} to={MY_TICKETS_UPCOMING} itemProp="url" className={"tab-bar-link"}>
                        <div className="ticket-icon mb4" />
                        <LinkText>Билеты</LinkText>
                    </NavLink>
                    <NavLink role={"button"} to={WALLET} itemProp="url" className={"tab-bar-link"}>
                        <div className="wallet-icon mb4" />
                        <LinkText>{getMoneyFormat(balance as number)} ₽</LinkText>
                    </NavLink>
                </>
            ) : (
                <>
                    <div className={"tab-bar-link"} onClick={handleOpenAuthForm}>
                        <div className="ticket-icon mb4" />
                        <LinkText>Билеты</LinkText>
                    </div>
                    <div className={"tab-bar-link"} onClick={handleOpenAuthForm}>
                        <div className="wallet-icon mb4" />
                        <LinkText>0 ₽</LinkText>
                    </div>
                </>
            )}
        </NavigationTabBarWrapper>
    );
};

export default NavigationTabBar;
