import { useCallback } from "react";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

export const useGetBalance = () => {
    const { handleFieldChange } = useMutationHandles("profile");
    const api = useAppApi();
    const { add } = useNotifications();
    const handleGetBalance = useCallback(async () => {
        const { result, error } = await api.profile.getBalance();
        if (error) {
            return add({ message: error?.data?.message ?? otherErrors.updateBalance });
        }
        handleFieldChange("data.balance", result);
    }, [add, api.profile, handleFieldChange]);

    return { handleGetBalance };
};
