import React from "react";
import { useGetLotteryDataBingo } from "~organisms/lottery-data/_hooks/useGetLotteryDataBingo";
import LotteryDataView from "~organisms/lottery-data/lottery-data-view";
import { TBingoTypes } from "~store/tickets/types";
import { TBingoAlias } from "~types/products";
import { bingoAlias } from "~constants/routes";

type TLotteryDataBingoProps = {
    ticketType: TBingoTypes;
    className?: string;
    withoutEdition?: boolean;
};

const bingoTypes: Record<TBingoTypes, TBingoAlias> = {
    bingo90: bingoAlias.b90,
    bingo75: bingoAlias.b75,
    bingo3x3: bingoAlias.b3x3,
};

const LotteryDataBingo = ({ ticketType, className, withoutEdition = false }: TLotteryDataBingoProps) => {
    const { nextEdition, isJackpot, jackpot } = useGetLotteryDataBingo(bingoTypes[ticketType], ticketType);

    return (
        <LotteryDataView
            ticketType={ticketType}
            className={className}
            isJackpot={isJackpot}
            jackpot={jackpot}
            nextEdition={nextEdition}
            withoutEdition={withoutEdition}
        />
    );
};

export default LotteryDataBingo;
