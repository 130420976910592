import { css } from "styled-components";
import { adaptive } from "~tokens/screen";
import sprite from "./icons-sprite.svg";
import logoFooter from "./logo-footer-sprite.svg";
import logoHeader from "./logo-header-icon.svg";
import logoHeaderName from "./logo-name.svg";
import logoSprite from "./logo-sprite.svg";

export const cupIcon = css`
    background: url(${sprite}) no-repeat;
    width: 24px;
    height: 24px;
    background-position: -52px 0;
`;

export const cupIconFilled = css`
    background-position: -78px 0;
    ${cupIcon}
`;

export const walletIcon = css`
    background: url(${sprite}) no-repeat;
    width: 24px;
    height: 24px;
    background-position: -208px 0;
`;

export const walletIconFilled = css`
    background-position: -234px 0;
    ${walletIcon}
`;

export const ticketIcon = css`
    background: url(${sprite}) no-repeat;
    width: 24px;
    height: 24px;
    background-position: -156px 0;
`;

export const ticketIconFilled = css`
    background-position: -182px 0;
    ${ticketIcon}
`;

export const basketIcon = css`
    background: url(${sprite}) no-repeat;
    width: 24px;
    height: 24px;
    background-position: -104px 0;
`;

export const basketIconFilled = css`
    background-position: -130px 0;
    ${basketIcon}
`;

export const avatarIcon = css`
    background: url(${sprite}) no-repeat;
    width: 24px;
    height: 24px;
    background-position: -260px 0;
`;

export const avatarIconFilled = css`
    background-position: -286px 0;
    ${avatarIcon}
`;

export const homeIcon = css`
    background: url(${sprite}) no-repeat;
    width: 24px;
    height: 24px;
    background-position: 0 0;
`;

export const homeIconFilled = css`
    background-position: -26px 0;
    ${homeIcon}
`;

export const logoIcon = css`
    background: url(${logoSprite}) no-repeat;
    width: 132px;
    height: 32px;
    background-position: 0 0;
    ${adaptive.maxWidth.tablet} {
        width: 108px;
        height: 26px;
        background-size: cover;
    }
`;

export const logoHeaderIcon = css`
    background: url(${logoHeader}) no-repeat;
    width: 29px;
    height: 29px;
    background-position: 0 0;
`;

export const logoName = css`
    background: url(${logoHeaderName}) no-repeat;
    width: 105px;
    height: 25px;
    background-position: 0 0;
`;

export const logoIconHovered = css`
    background-position: -137px 0;
    ${logoIcon}
    ${adaptive.maxWidth.tablet} {
        background-position: -111.5px 0;
    }
`;

export const logoFooterIcon = css`
    background: url(${logoFooter}) no-repeat;
    width: 169px;
    height: 41px;
    background-position: 0 0;
    filter: ${({ theme }) => theme.color.blocks.footer.filter.logo};
`;

export const logoFooterIconHovered = css`
    background-position: -174px 0;
    ${logoFooterIcon}
`;
