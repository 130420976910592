import { AxiosInstance } from "axios";
import { ApiResponse } from "~services/http/types";
import { TWalletPaymentResData, TWalletPaymentType, TWalletPayoutResData, TWalletPayoutType } from "~types/wallet";

const enum URLs {
    URL_WALLET = "/clients/wallet",
}

export const createWalletApi = (http: AxiosInstance) => ({
    async createPayment(amount: number, paymentType: TWalletPaymentType, email?: string) {
        const data = {
            amount,
            paymentType,
            email,
        };
        const {
            data: { error, result },
        } = await http.post<ApiResponse<TWalletPaymentResData>>(URLs.URL_WALLET + "/refill", data);

        return { error, result };
    },

    async createPayout(amount: number, paymentType: TWalletPayoutType, email?: string) {
        const data = {
            amount,
            paymentType,
            email,
        };
        const {
            data: { error, result },
        } = await http.post<ApiResponse<TWalletPayoutResData>>(URLs.URL_WALLET + "/withdrawal", data);

        return { error, result };
    },
});
