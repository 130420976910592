export enum INDENT {
    xxxxxs = "2px",
    xxxxs = "4px",
    x6 = "6px",
    xxxs = "8px",
    x10 = "10px",
    xxs = "12px",
    xs = "16px",
    s = "20px",
    sm = "24px",
    s28 = "28px",
    m = "32px",
    ms = "36px",
    l = "40px",
    ls = "44px",
    xl = "48px",
    xxl = "56px",
    xxls = "60px",
    xxxl = "64px",
    none = "0px",
    auto = "auto",
}
