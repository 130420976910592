import styled from "styled-components";
import { PURPLE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const OfflineBlockWrapper = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    background-color: ${PURPLE};
    border-radius: 4px;
    padding: ${INDENT.xxxs} ${INDENT.xxs} ${INDENT.xxxs} ${INDENT.xxs};

    ${adaptive.maxWidth.tablet} {
        width: 100%;
        justify-content: center;
    }
`;

export const IconWrapper = styled.div`
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
`;
