import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { eventFieldChange, eventFieldManyChange } from "~store/mutation/actions";
import { AppState } from "~store/rootReducer";

type TReturnMutationHandles = {
    handleFieldChange: <T>(path: string, value: T) => void;
    handleFieldManyChange: <T extends { path: string; value: unknown }>(changes: Array<T>) => void;
};

export type TFieldChange = {
    path: string;
    value: unknown;
};

export const useMutationHandles = (form: keyof AppState): TReturnMutationHandles => {
    const dispatch = useDispatch();

    const handleFieldChangeForm = eventFieldChange(form);
    const handleFieldManyChangeForm = eventFieldManyChange(form);

    return {
        handleFieldChange: useCallback(
            (path: string, value: unknown) => dispatch(handleFieldChangeForm(path, value)),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [],
        ),
        handleFieldManyChange: useCallback(
            (changes: TFieldChange[]) => dispatch(handleFieldManyChangeForm(changes)),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [],
        ),
    };
};
