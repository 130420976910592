import { TMyTicketsActions, TMyTicketsState } from "./types";
import { MutationReducer } from "../mutation/reducer";

export const initMyTicketsReducer = () => {
    const defaultState: TMyTicketsState = {
        payment: {
            waitingClosed: 0,
            content: null,
            pagination: null,
        },
        future: {
            content: null,
            pagination: null,
        },
        tickets: {
            content: null,
            pagination: null,
        },
        addedTickets: {
            state: null,
            tickets: [{}, {}, {}, {}],
        },
    };

    return (state = defaultState, action: TMyTicketsActions): TMyTicketsState => {
        switch (action.type) {
            default:
                return MutationReducer<TMyTicketsState>("myTickets", state, action);
        }
    };
};
