import styled from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { isIos } from "~helpers/utils";

export const PaymentBlockWrapper = styled.div`
    width: 320px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 164px 106px;
`;

export const PaymentContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    & .button-apple-pay .prefix-wrap {
        width: auto;
    }
`;

export const ApplePayButton = styled(ButtonAdaptive)`
    display: ${isIos() ? "block" : "none"};
`;
