import styled from "styled-components";
import { Skeleton } from "~atoms/skeleton";
import { SelectItem } from "~organisms/select/select-item";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const SelectModalBlockWrapper = styled.div`
    width: 300px;
    margin: ${INDENT.xs};
    ${adaptive.maxWidth.mobile} {
        width: 256px;
        margin: 0;
    }
`;

export const ModalControls = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const ModalBody = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: ${INDENT.xxxs} ${INDENT.none} ${INDENT.sm} ${INDENT.none};
`;

export const SelectModalItem = styled(SelectItem)`
    & .select-text {
        flex-direction: row;
        align-items: center;
        & > span {
            margin-right: ${INDENT.xxxs};
        }
    }
`;

export const SkeletonStyled = styled(Skeleton)`
    height: 28px;
    width: 100%;
    border-radius: 8px;
    &:first-child {
        margin-top: ${INDENT.m};
    }
`;
