import styled from "styled-components";
import { TabGroup } from "~molecules/tab-group";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const DrawHistoryWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    background-color: ${WHITE};
    border-radius: 20px;
    padding: ${INDENT.m};
    //max-width: 1074px;

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm};
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs};
    }
`;

export const TabGroupStyled = styled(TabGroup)``;

export const HeaderBlock = styled.div``;
