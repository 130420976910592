import styled from "styled-components";
import { Flex } from "~atoms/flex-box";
import { Skeleton } from "~atoms/skeleton";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { TBackgroundTicket } from "./ticket-5in50";

export const Ticket5in50Wrapper = styled.div<{ background: TBackgroundTicket }>`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    position: relative;
    height: 605px;
    width: 100%;
    border-radius: 20px;
    mask-image: radial-gradient(circle at left 163px, transparent 16px, black 17px, black 65%, transparent 0),
        radial-gradient(circle at right 163px, transparent 16px, black 17px, black 65%, transparent 0);
    ${adaptive.maxWidth.desktopXXL} {
        height: 496px;
    }
    ${adaptive.maxWidth.desktopXL} {
        height: 557px;
        mask-image: radial-gradient(circle at left 185px, transparent 16px, black 17px, black 65%, transparent 0),
            radial-gradient(circle at right 185px, transparent 16px, black 17px, black 65%, transparent 0);
    }
    ${adaptive.maxWidth.tablet} {
        height: 1007px;
        mask-image: linear-gradient(to bottom, black 0, black 200px, transparent 200px, transparent 100%),
            radial-gradient(circle at left 261px, transparent 16px, black 16px, black 30%, transparent 0),
            radial-gradient(circle at right 261px, transparent 16px, black 16px, black 30%, transparent 0),
            linear-gradient(to bottom, transparent 0, transparent 309px, black 309px, black 100%);
    }
`;

export const TicketBody = styled(Flex)`
    background: ${({ theme }) => theme.color.background.primaryElevated};
    padding: ${INDENT.xs} ${INDENT.none};
    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs} ${INDENT.x10};
    }
`;

export const FieldsNumbersBody = styled(Flex)`
    ${adaptive.maxWidth.tablet} {
        max-width: 226px;
        & .field2 .field-body {
            max-width: 184px;
        }
    }
`;

export const TicketFooterWrapper = styled.section`
    display: flex;
    align-items: center;
    height: 64px;
    flex-shrink: 0;
    background: ${({ theme }) => theme.color.background.primary};
    padding: ${INDENT.xxs} ${INDENT.none};
    width: 100%;
    & .btn-remove-mobile {
        display: none;
    }

    ${adaptive.maxWidth.tablet} {
        height: 48px;
        & .ticket-controls {
            position: absolute;
            display: flex;
            left: 0;
            align-items: center;
            top: 261px;
            height: 48px;
            width: 100%;
            background: ${({ theme }) => theme.color.background.primary};
            padding: ${INDENT.xxs} ${INDENT.m};
        }
        & .btn-remove {
            display: none;
        }
        & .btn-remove-mobile {
            display: block;
            height: 48px;
        }
    }
    ${adaptive.maxWidth.mobile} {
        & .ticket-controls {
            padding: ${INDENT.xxs} ${INDENT.xs};
        }
    }
`;

export const SkeletonStyled = styled(Skeleton)`
    border-radius: 20px;
    height: 561px;
    mask-image: radial-gradient(circle at left 163px, transparent 16px, black 17px, black 65%, transparent 0),
        radial-gradient(circle at right 163px, transparent 16px, black 17px, black 65%, transparent 0);
    ${adaptive.maxWidth.desktopXXL} {
        height: 496px;
    }
    ${adaptive.maxWidth.desktopXL} {
        height: 557px;
        mask-image: radial-gradient(circle at left 185px, transparent 16px, black 17px, black 65%, transparent 0),
            radial-gradient(circle at right 185px, transparent 16px, black 17px, black 65%, transparent 0);
    }
    ${adaptive.maxWidth.tablet} {
        height: 1007px;
        mask-image: linear-gradient(to bottom, black 0, black 200px, transparent 200px, transparent 100%),
            radial-gradient(circle at left 261px, transparent 16px, black 16px, black 30%, transparent 0),
            radial-gradient(circle at right 261px, transparent 16px, black 16px, black 30%, transparent 0),
            linear-gradient(to bottom, transparent 0, transparent 309px, black 309px, black 100%);
    }
`;
