import * as services from "./services";
import {
    QUERY_TYPE,
    ACTIONS_TYPE,
    TMutationActions,
    TEventFieldChangeAction,
    TEventFieldManyChangeAction,
} from "./types";
import { AppState } from "../rootReducer";

export const MutationReducer = <T>(form: keyof AppState, state: T, action: TMutationActions) => {
    const mutationActionsType = `${action.meta}.${action.type}`;
    switch (mutationActionsType) {
        case `${form}.${ACTIONS_TYPE.EVENT_FIELD_CHANGE}`: {
            const { path, value } = (action as TEventFieldChangeAction).payload;
            const query = services.generateQuery(path, QUERY_TYPE.$set, value);
            return services.updateState(state, query);
        }
        case `${form}.${ACTIONS_TYPE.EVENT_FIELD_MANY_CHANGE}`: {
            (action as TEventFieldManyChangeAction).payload.forEach(({ path, value }) => {
                const query = services.generateQuery(path, QUERY_TYPE.$set, value);
                state = services.updateState(state, query);
            });
            return state;
        }

        default:
            return state;
    }
};
