import { TBingoTypes, TTicketTypes } from "~store/tickets/types";
import { TAlias } from "~types/products";

export const ticketsDigitalType: Record<string, TTicketTypes> = {
    t4in20: "ticket4in20",
    t8in20: "ticket8in20",
    t5in36: "ticket5in36",
    t5in50: "ticket5in50",
};

export const ticketsBingoType: Record<string, TBingoTypes> = {
    b90: "bingo90",
    b75: "bingo75",
    b3x3: "bingo3x3",
};

export const ticketsSlogan: Record<TAlias, string> = {
    "digital-5x36": "На скорости побед!",
    "digital-8x20": "На скорости побед!",
    "digital-5x50": "Большие призы для большой страны!",
    "digital-4x20": "",
    "bingo-90": "Большие призы для большой страны!",
    "bingo-75": "Большие призы для большой страны!",
    "bingo-3x3": "Большие призы для большой страны!",
};

export const ticketsColor: Record<TAlias, string> = {
    "digital-5x36": "linear-gradient(90deg, #984BAF 0%, #5B2B94 100%);",
    "digital-8x20": "linear-gradient(90deg, #E84FA5 0%, #7A388D 100%);",
    "digital-5x50": "linear-gradient(90deg, #447ACF 0%, #1451B1 100%);",
    "digital-4x20": "linear-gradient(85.19deg, #6C67D5 3.42%, #261B70 104.62%);",
    "bingo-90": "linear-gradient(90deg, #78C0F2 0%, #6D9DE2 100%)",
    "bingo-75": "linear-gradient(90deg, #FBB225 0%, #F37914 100%)",
    "bingo-3x3": "linear-gradient(90deg, #69AEDA 0%, #834AB1 100%)",
};
