import styled from "styled-components";
import { Skeleton } from "~atoms/skeleton";
import { adaptive } from "~tokens/screen";
import { TBingoTypes } from "~store/tickets/types";

const ticketHeight: Record<TBingoTypes, { desktop: number; mobile: number }> = {
    bingo90: {
        desktop: 296,
        mobile: 275,
    },
    bingo75: {
        desktop: 346,
        mobile: 334,
    },
    bingo3x3: {
        desktop: 273,
        mobile: 240,
    },
};

export const TicketsGroupWrapper = styled.div`
    display: block;
    width: 100%;
    ${adaptive.maxWidth.tablet} {
        text-align: center;
    }
`;

export const SkeletonStyled = styled(Skeleton)<{ ticketType: TBingoTypes }>`
    height: ${({ ticketType }) => `${ticketHeight[ticketType].desktop}px`};
    mask-image: linear-gradient(to bottom, black 0, black 36px, transparent 36px, transparent 100%),
        radial-gradient(circle at left 48px, transparent 9px, black 10px, black 50%, transparent 0),
        radial-gradient(circle at right 48px, transparent 9px, black 10px, black 50%, transparent 0),
        linear-gradient(to bottom, transparent 0, transparent 60px, black 60px, black 100%);
    max-width: 320px;
    width: 100%;
    border-radius: 10px;
    ${adaptive.maxWidth.desktopXXL} {
        max-width: 368px;
    }
    ${adaptive.maxWidth.desktopXL} {
        max-width: 320px;
    }
    ${adaptive.maxWidth.tablet} {
        max-width: 296px;
        mask-image: linear-gradient(to bottom, black 0, black 32px, transparent 32px, transparent 100%),
            radial-gradient(circle at left 44px, transparent 9px, black 10px, black 50%, transparent 0),
            radial-gradient(circle at right 44px, transparent 9px, black 10px, black 50%, transparent 0),
            linear-gradient(to bottom, transparent 0, transparent 56px, black 56px, black 100%);
        height: ${({ ticketType }) => `${ticketHeight[ticketType].mobile}px`};
    }
`;
