import React from "react";
import { TooltipProps } from "react-tooltip";
import { WHITE } from "~tokens/colors";
import { StyledTooltip } from "./theme";

const Tooltip = ({ id, effect = "solid", place = "top", ...props }: TooltipProps) => {
    return (
        <StyledTooltip {...props} className="custom-tooltip" arrowColor={WHITE} id={id} effect={effect} place={place} />
    );
};

export default Tooltip;
