import React from "react";
import styled from "styled-components";
import { ComboCircle } from "~atoms/combo-circle";
import { TextAdaptive } from "~atoms/text-adaptive";
import { getMissingNumbers } from "~pages/lottery/bingo-lottery/common/helpers";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { lineHeight20, lineHeight24 } from "~tokens/typography";
// import { getMoneyFormat } from "~helpers/format";
import { ticketsBingoType } from "~constants/tickets-type";

const Ball = styled(ComboCircle)`
    margin-bottom: ${INDENT.xxxxs};
    border-radius: 6px;
    &:not(:last-child) {
        margin-right: ${INDENT.xxxxs};
    }
    ${adaptive.maxWidth.tablet} {
        width: 24px;
        height: 24px;
        & > div {
            ${lineHeight24};
        }
    }
`;

const BallWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &:not(:last-child) {
        margin-bottom: ${INDENT.xxxxs};
    }
`;

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 250px;
    & > p {
        flex-basis: 42px;
    }
    ${adaptive.maxWidth.desktopXXL} {
        flex-direction: column;
        align-items: flex-start;
        & > p {
            ${lineHeight20};
            flex-basis: auto;
        }
    }
    ${adaptive.maxWidth.tablet} {
        max-width: unset;
    }
`;

export const scheme = [
    {
        id: "date",
        width: "20%",
        name: "Дата",
        getValue(value: string) {
            return (
                <TextAdaptive weight={"demiBold"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "time",
        width: "17%",
        name: "Время, МСК",
        getValue(value: string) {
            return (
                <TextAdaptive weight={"demiBold"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "edition",
        width: "17%",
        name: "Тираж №",
        getValue(value: string) {
            return (
                <TextAdaptive weight={"demiBold"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "numbers",
        width: "30%",
        name: "Невыпавшие числа",
        getValue(values: number[]) {
            return (
                <FieldWrapper>
                    <BallWrapper>
                        {getMissingNumbers(ticketsBingoType.b75, values).map((item, idx) => (
                            <Ball variant={4} key={idx}>
                                {item}
                            </Ball>
                        ))}
                    </BallWrapper>
                </FieldWrapper>
            );
        },
    },
    // {
    //     id: "prize",
    //     width: "16%",
    //     name: "Суперприз, ₽",
    //     getValue(value: number) {
    //         return (
    //             <TextAdaptive weight={"demiBold"} size={"text16"}>
    //                 {getMoneyFormat(value)}
    //             </TextAdaptive>
    //         );
    //     },
    // },
];
