import React, { PropsWithChildren, ReactNode, useCallback, useEffect, useRef } from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import {
    CSSTransitionContentStyled,
    DropdownButtonContent,
    DropdownButtonTarget,
    DropdownButtonWrapper,
    duration,
    transitionContentName,
    DropdownPrefixIcon,
} from "~molecules/dropdown-button/theme";

type TDropdownButtonProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    target: string;
    targetWeight?: "normal" | "medium" | "demiBold" | "bold";
    onClick?: () => void;
    prefixIcon?: string;
    suffixIcon?: ReactNode;
    className?: string;
};

const DropdownButton = ({
    open,
    setOpen,
    target,
    children,
    prefixIcon,
    suffixIcon,
    targetWeight = "normal",
    className,
    onClick,
}: PropsWithChildren<TDropdownButtonProps>) => {
    const dropdownButtonRef = useRef<HTMLDivElement>(null);

    const handleOuterClick = useCallback(
        (event: MouseEvent) => {
            if (open && dropdownButtonRef.current && !dropdownButtonRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        },
        [open, setOpen, dropdownButtonRef],
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleOuterClick);
        return () => {
            document.removeEventListener("mousedown", handleOuterClick);
        };
    }, [handleOuterClick, open]);

    const handleClick = useCallback(() => {
        setOpen(!open);
    }, [open, setOpen]);

    return (
        <DropdownButtonWrapper className={className} ref={dropdownButtonRef}>
            <DropdownButtonTarget variant="transparent" onClick={onClick ? onClick : handleClick}>
                {prefixIcon && <DropdownPrefixIcon src={prefixIcon} className={"dropdown-prefix-icon"} />}
                <TextAdaptive size="text16" weight={targetWeight} className={"dropdown-text-target"}>
                    {target}
                </TextAdaptive>
                {suffixIcon && (
                    <span role="img" className="suffix-wrapper">
                        {suffixIcon}
                    </span>
                )}
            </DropdownButtonTarget>
            <CSSTransitionContentStyled
                in={open}
                timeout={duration}
                classNames={"dropdown-content " + transitionContentName}
                unmountOnExit
            >
                <DropdownButtonContent>{children}</DropdownButtonContent>
            </CSSTransitionContentStyled>
        </DropdownButtonWrapper>
    );
};

export default DropdownButton;
