import { TBingo, TBingoOrderData, TTicket } from "~store/tickets/types";
import { TOrderReqDataBingo, TOrderReqDataDigital, TPaymentType } from "~types/order";

export const toCreateApiDigital = (
    productId: string,
    tickets: TTicket[],
    email: string,
    paymentType: TPaymentType,
): TOrderReqDataDigital => {
    return {
        productId,
        amount: tickets.reduce((sum, ticket) => (ticket.amount ?? 0) * ticket.editionsSelected.length + sum, 0),
        paymentType,
        email,
        tickets: tickets.map((ticket) => ({
            combination: {
                roundNum: 1,
                main: ticket.numbersSelected[0],
                sub: ticket.numbersSelected[1],
            },
            draws: ticket.editionsSelected.map((edition) => edition.edition),
        })),
    };
};

export const toCreateApiBingo = (
    productId: string,
    ticketsData: TBingoOrderData,
    email: string,
    paymentType: TPaymentType,
): TOrderReqDataBingo => {
    return {
        productId,
        amount:
            ticketsData.tickets.reduce((sum: number, ticket: TBingo) => (ticket.amount ?? 0) + sum, 0) *
            ticketsData.editionsSelected.length,
        paymentType,
        email,
        tickets: ticketsData.tickets.map((ticket) => ({
            ticketId: ticket.serialNum,
            draws: ticketsData.editionsSelected.map((edition) => edition.edition),
        })),
    };
};
