import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { LegalInfo } from "~pages/lottery/common/legal-info";
import { legalText } from "~constants/handbook";
import { ticketsBingoType } from "~constants/tickets-type";
import { useAppSelector } from "~hooks/useAppSelector";
import { ContentWrapper, RulesWrapper } from "./theme";
import { BingoRulesHeader } from "../../common/bingo-rules-header";

type TRulesProps = {
    isActive: boolean;
};

const bingo90 = {
    type: "Бинго",
    prizeFund: "65%",
    frequency: "1 раз в неделю",
};

const Rules = ({ isActive }: TRulesProps) => {
    // const jackpot = useAppSelector((state) => state.tickets[ticketsBingoType.b90].jackpot);
    const amount = useAppSelector((state) => state.tickets[ticketsBingoType.b90].data[0]?.amount);
    const { color } = useContext(ThemeContext);
    return (
        <RulesWrapper>
            <ContentWrapper>
                {/*{jackpot !== null && amount !== null ? (*/}
                {/*    <BannerRulesBingo90 jackpot={jackpot} amount={amount} className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*) : (*/}
                {/*    <SkeletonStyled className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*)}*/}
                {isActive && (
                    <BingoRulesHeader
                        className="mb32 mb24--tablet"
                        type={bingo90.type}
                        prizeFund={bingo90.prizeFund}
                        ticketPrice={amount}
                        frequency={bingo90.frequency}
                    />
                )}
                <TitleAdaptive
                    className="mb24 mb16--mobile"
                    tag="h3"
                    size="title38"
                    weight="medium"
                    color={color.text.headline}
                >
                    Как играть?
                </TitleAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    Формирование лотерейной комбинации осуществляется на двух игровых полях. Каждое игровое поле состоит
                    из 27 ячеек (3 строк и 9 столбцов), содержащих 15 неповторяющихся чисел в диапазоне от 1 до 90
                    включительно.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" weight="medium" color={color.text.primary}>
                    Выигрышная комбинация определяется путем формирования случайной последовательности из
                    неповторяющихся чисел в диапазоне от 1 до 90 включительно в ходе розыгрыша призового фонда в
                    соответствующем тираже.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" weight="medium" color={color.text.primary}>
                    Билет считается выигрышным, если размещенные в игровом поле числа частично или полностью совпали с
                    числами, выпавшими в ходе розыгрыша призового фонда в соответствующем тираже тиража, и при этом
                    составили выигрышную лотерейную комбинацию по результатам розыгрыша.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" weight="medium" color={color.text.primary}>
                    Выигрыши определяются в несколько туров. Размер выигрыша зависит от того, в каком туре образовались
                    совпадения чисел лотерейной комбинации с числами выигрышной комбинации. Общее количество туров,
                    необходимое для розыгрыша призового фонда и категории распределения призового фонда, определяются
                    Оператором лотереи в рамках конкретного тиража.
                </TextAdaptive>
                <TextAdaptive size="text16" color={color.text.primary}>
                    Первая категория распределения призового фонда (Суперприз) «Джекпот»:
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    выигрывают билеты, в которых на ходу, установленном Оператором лотереи, совпадут 15 из 30 чисел,
                    находящихся в двух игровых полях билета, с выпавшими в процессе розыгрыша числами.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    Последующее распределение призового фонда в основном розыгрыше происходит по категориям:
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        &quot;Первый тур&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых все 5 чисел в любой горизонтальной строке любого игрового поля билета
                    (верхнего или нижнего) раньше, чем в других билетах, совпадут с выпавшими в процессе розыгрыша
                    числами.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        &quot;Второй тур&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых все 15 чисел одного из игровых полей билета (верхнего или нижнего)
                    раньше, чем в других билетах, совпадут с выпавшими в процессе розыгрыша числами.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        &quot;Третий тур&quot; -
                    </TextAdaptive>{" "}
                    и последующие туры - выигрывают билеты, в которых все 30 чисел двух игровых полей билета раньше, в
                    других билетах, совпадут с выпавшими в процессе розыгрыша числами.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        &quot;Четвертый тур&quot; -
                    </TextAdaptive>{" "}
                    проведение розыгрыша на один ход больше, чем последний ход «Третьего тура», выигрывают билеты, в
                    которых все 30 чисел двух игровых полей билета других совпадут с выпавшими в процессе розыгрыша
                    числами, с учетом дополнительного хода. Решение о проведении данного тура розыгрыша Оператор лотереи
                    вправе принять как до, так и во время проведения розыгрыша призового фонда тиража.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        &quot;Пятый тур&quot; -
                    </TextAdaptive>{" "}
                    проведение розыгрыша на два хода больше, чем последний ход «Третьего тура», выигрывают билеты, в
                    которых все 30 чисел двух игровых полей билета других совпадут с выпавшими в процессе розыгрыша
                    числами, с учетом дополнительного хода. Решение о проведении данного тура розыгрыша Оператор лотереи
                    вправе принять как до, так и во время проведения розыгрыша призового фонда тиража.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" weight="medium" color={color.text.primary}>
                    После того как основной розыгрыш завершен, по решению Оператора лотереи, может проводится
                    дополнительный розыгрыш по одной или более категориям:
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        &quot;Числа удачи&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых на установленных Оператором лотереи ходах выпавшие в ходе розыгрыша
                    числа совпадут со всеми числами удачи, указанными в билете дополнительно к 30 числам, формирующим
                    игровые поля. Необходимое для выигрыша совпадение игровой комбинации участника лотереи с выпавшими в
                    процессе розыгрыша числами, состав игровой комбинации и расположение чисел игровой комбинации на
                    билете определяются Оператором лотереи в рамках конкретного розыгрыша тиража.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        &quot;Шанс на удачу&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых по окончании основного розыгрыша все невыпавшие в основном розыгрыше
                    числа окажутся в одном из игровых полей билета (верхнем или нижнем).
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        &quot;Супершанс&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых по окончании основного розыгрыша все невыпавшие в основном розыгрыше
                    числа окажутся в обеих полях билета (верхнем или нижнем).
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        &quot;Счастливый билет&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых номер билета оканчивается на определенную в ходе розыгрыша выигрышную
                    комбинацию цифр. Необходимое для выигрыша совпадение игровой комбинации участника лотереи с
                    выпавшими в процессе розыгрыша числами, состав игровой комбинации и расположение чисел игровой
                    комбинации на билете определяются Оператором лотереи в рамках конкретного тиража.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" color={color.text.primary}>
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        &quot;Счастливый случай&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых на установленном Оператором лотереи ходу (или раньше), совпадут от 5 до
                    30 чисел (точное значение определяется Оператором лотереи), находящихся в игровом поле (игровых
                    полях) билета, с выпавшими в процессе розыгрыша числами. Необходимое для выигрыша совпадение
                    лотерейной комбинации участника лотереи с выпавшими в процессе розыгрыша числами, состав лотерейной
                    комбинации и расположение чисел комбинации в билете определяются Оператором лотереи в рамках
                    конкретного тиража.
                </TextAdaptive>
                <TextAdaptive className="mb64 mb48--tablet" size="text16" color={color.text.primary}>
                    Решение о проведении дополнительного розыгрыша и используемым в конкретном розыгрыше категориям
                    Оператор лотереи вправе принять как до, так и во время розыгрыша призового фонда тиража.
                </TextAdaptive>
                <LegalInfo
                    type="BINGO"
                    prizeInfo={legalText["bingo-90"].prizeInfo}
                    algoInfo={legalText["bingo-90"].algoInfo}
                />
            </ContentWrapper>
        </RulesWrapper>
    );
};

export default Rules;
