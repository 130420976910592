import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { CheckboxLabel, CheckboxLabelText, CheckboxWrapper } from "./theme";

type TCheckboxProps = {
    label?: React.ReactNode;
    error?: boolean;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Checkbox = ({
    id,
    error,
    label,
    disabled = false,
    checked = false,
    className,
    onChange,
    ...props
}: TCheckboxProps) => {
    const toggleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        onChange && onChange(e);
    };

    return (
        <CheckboxWrapper className={className}>
            <input {...props} type="checkbox" checked={checked} onChange={toggleCheck} disabled={disabled} id={id} />
            <CheckboxLabel htmlFor={id} error={error}>
                <CheckboxLabelText>{label}</CheckboxLabelText>
            </CheckboxLabel>
        </CheckboxWrapper>
    );
};

export default Checkbox;
