import styled from "styled-components";
import { transitionDefault } from "~assets/styles/utils";
import { Text } from "~atoms/text";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import {
    fontSize14,
    fontSize18,
    fontSize20,
    fontSize24,
    fontSize32,
    fontStyleItalic,
    fontWeight500,
    lineHeight18,
    lineHeight20,
    lineHeight24,
    lineHeight26,
    lineHeight30,
    lineHeight32,
    lineHeight42,
} from "~tokens/typography";

export const HistoryCardWrapper = styled.div<{ background?: string }>`
    padding: ${INDENT.sm};
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 185px;
    border-radius: 12px;
    position: relative;

    ${({ background }) => `background: ${background}`};

    ${adaptive.maxWidth.desktopXL} {
        padding: ${INDENT.xs};
        height: 228px;
    }

    ${adaptive.maxWidth.tablet} {
        height: 188px;
    }

    ${adaptive.minWidth.desktopXL} {
        &:after {
            background: rgba(29, 21, 79, 0.4);
            ${transitionDefault};
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }

        &:hover:after {
            opacity: 1;
        }
    }
`;

export const CommentBox = styled.div`
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
`;

export const Quotes = styled.img``;

export const CommentText = styled.span`
    ${fontSize24};
    ${fontStyleItalic};
    ${lineHeight32};
    ${fontWeight500};
    color: ${({ theme }) => theme.color.text.inverted};

    ${adaptive.maxWidth.desktopXXL} {
        ${lineHeight24};
        ${fontSize20};
    }

    ${adaptive.maxWidth.tablet} {
        ${fontSize18}
    }
`;

export const ContentBox = styled.div`
    flex-shrink: 0;
`;

export const WinnerInfo = styled.div`
    display: flex;
    flex-direction: column;
`;
export const LotteryInfo = styled.div`
    display: flex;
    align-items: center;

    & p {
        white-space: nowrap;
    }
`;

export const NameText = styled(Text)`
    ${lineHeight20};

    ${adaptive.maxWidth.desktopXL} {
        ${fontSize14};
        ${lineHeight18};
    }

    ${adaptive.maxWidth.tablet} {
        max-width: unset;
    }
`;
export const PrizeText = styled(Text)`
    white-space: nowrap;
    ${fontSize32};
    ${lineHeight42};

    ${adaptive.maxWidth.desktopXL} {
        ${fontSize24};
        ${lineHeight30};
    }

    ${adaptive.maxWidth.desktopXXL} {
        ${fontSize20};
        ${lineHeight26};
    }
`;

export const LotteryEmblem = styled.div`
    flex-shrink: 0;
    width: 30px;
    height: 30px;

    & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;
