import { useCallback, useContext, useEffect, useState } from "react";
import Loader from "~atoms/loader/loader";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import LoginBlock from "~organisms/auth-form/login-block/login-block";
import { TBingoTypes } from "~store/tickets/types";
import { otherErrors } from "~constants/error-messages";
import { MY_TICKETS_UPCOMING } from "~constants/routes";
import useAppHistory from "~hooks/useAppHistory";
import { useAppSelector } from "~hooks/useAppSelector";
import { useNotifications } from "~hooks/useNotifications";
import { useGetBuyTicketsInfoBingo } from "./useGetBuyTicketsInfoBingo";
import { useSendOrderBingo } from "./useSendOrderBingo";
import { EmailBlock } from "../email-block/";
import { PaymentBlockBingo } from "../payment-block";
import { WidgetBlock } from "../widget-block";

export const useBuyTicketsCardBingoLogic = (ticketType: TBingoTypes) => {
    const { ticketsCount, editionsCount, amount } = useGetBuyTicketsInfoBingo(ticketType);
    const { sendOrder } = useSendOrderBingo(ticketType);
    const initProfile = useAppSelector((state) => state.profile.data !== null);
    const email = useAppSelector((state) => state.profile.data?.email);
    const balance = useAppSelector((state) => state.profile.data?.balance);
    const { add } = useNotifications();
    const { go } = useAppHistory();

    const isAuth = useAppSelector((state) => state.user.auth);

    const [clicked, setClicked] = useState<boolean>(false);

    const { onClose, setContent } = useContext(ModalWindowContext);

    const onClickClose = useCallback(() => {
        setClicked(false);
    }, []);

    const handleOpenAuthForm = useCallback(() => {
        setContent({ component: LoginBlock, options: { btnClose: true, onClickClose } });
        setClicked(true);
    }, [setContent, onClickClose]);

    const handleWidgetClose = useCallback(
        (success: boolean) => {
            if (!success) return;
            go(MY_TICKETS_UPCOMING);
        },
        [go],
    );

    const handlePaymentOrder = useCallback(async () => {
        setContent({
            component: Loader,
        });
        if (balance && balance >= amount) {
            setContent({
                component: PaymentBlockBingo,
                props: { ticketType, onClickClose, handleWidgetClose },
                options: { onClickClose, btnClose: true, btnVariant: "secondary", btnIntent: false },
            });
        } else {
            const { error, result } = await sendOrder("ACQUIRING_CARD");
            if (error) {
                add({ message: error?.data?.message ?? otherErrors.sendOrder });
                setClicked(false);
                return onClose();
            }
            if (result && result.paymentToken && result.paymentId) {
                const { paymentId, paymentToken } = result;
                setContent({
                    component: WidgetBlock,
                    props: {
                        paymentId,
                        paymentToken,
                        ticketType,
                        handleClose: handleWidgetClose,
                    },
                    options: { onClickClose },
                });
            }
        }
        setClicked(false);
    }, [setContent, balance, amount, ticketType, onClickClose, sendOrder, add, onClose, handleWidgetClose]);

    const handleBuyNow = useCallback(() => {
        if (ticketsCount === 0) return add({ message: otherErrors.emptyTicket });
        isAuth ? setClicked(true) : handleOpenAuthForm();
    }, [ticketsCount, add, isAuth, handleOpenAuthForm]);

    useEffect(() => {
        if (clicked && isAuth && initProfile) {
            if (!email) {
                return setContent({
                    component: EmailBlock,
                    props: { email },
                    options: { btnClose: true, onClickClose },
                });
            }

            void handlePaymentOrder();
        }
    }, [clicked, isAuth, email, setContent, ticketType, handlePaymentOrder, initProfile, onClickClose]);

    return {
        data: { ticketsCount, editionsCount, amount },
        cb: { handleBuyNow },
    };
};
