const prizeData: Record<string, unknown>[] = [
    { sizeWin: 100000, numberWin: 1 },
    { sizeWin: 50000, numberWin: 3 },
    { sizeWin: 25000, numberWin: 5 },
    { sizeWin: 10000, numberWin: 10 },
    { sizeWin: 5000, numberWin: 15 },
    { sizeWin: 2000, numberWin: 25 },
    { sizeWin: 1000, numberWin: 500 },
    { sizeWin: 500, numberWin: 1000 },
    { sizeWin: 400, numberWin: 2000 },
    { sizeWin: 300, numberWin: 4000 },
    { sizeWin: 200, numberWin: 6000 },
    { sizeWin: 100, numberWin: 8000 },
    { sizeWin: 60, numberWin: 15000 },
    { sizeWin: 50, numberWin: 20000 },
    { sizeWin: 30, numberWin: 250000 },
];

const jackpot = 100000;

const price = 30;

export { prizeData, jackpot, price };
