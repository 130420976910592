import React from "react";
import styled from "styled-components";
import { Failed } from "~organisms/buy-tickets-card/status-block/failed";
import { Success } from "~organisms/buy-tickets-card/status-block/success";
import { Wait } from "~organisms/buy-tickets-card/status-block/wait";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { TOrderPaymentStatus } from "~types/order";

type TStatusBlockProps = {
    className?: string;
    paymentId?: string;
    typeStatus?: TOrderPaymentStatus;
    handleClose: () => void;
    handleOpenMyTickets: () => void;
};

const StatusBlockWrapper = styled.div`
    display: block;
    padding: ${INDENT.xl} ${INDENT.xs};
    width: 428px;
    ${adaptive.maxWidth.tablet} {
        width: 320px;
        padding: ${INDENT.sm} ${INDENT.none};
    }
`;

const StatusBlock = ({
    typeStatus,
    className,
    paymentId = "",
    handleClose,
    handleOpenMyTickets,
}: TStatusBlockProps) => {
    return (
        <StatusBlockWrapper className={className}>
            {(typeStatus === "ACCEPTED" || typeStatus === "ACCEPTED_MOVE") && (
                <Success handleClose={handleClose} handleOpenMyTickets={handleOpenMyTickets} />
            )}
            {typeStatus === "CANCELED" && <Failed />}
            {(typeStatus === "PROGRESS" || typeStatus === "CREATED") && (
                <Wait handleClose={handleClose} handleOpenMyTickets={handleOpenMyTickets} paymentId={paymentId} />
            )}
        </StatusBlockWrapper>
    );
};

export default StatusBlock;
