import React, { memo } from "react";
import { TMyTicketParsed } from "~store/my-tickets/types";
import { getTicketDetailById } from "~constants/routes";
import { useAppScreen } from "~hooks/useAppScreen";
import { ArrowBlock } from "./arrow-block";
import { InfoBlock } from "./info-block";
import { ArrowWrap, InfoWrap, TicketWrapper, TitleWrap, MaskWrapper } from "./theme";
import { TitleBlock } from "./title-block";

export type TIsContent = "future" | "win" | "notWin" | "payment";

type TMyTicketProps = {
    isContent: TIsContent;
    className?: string;
    notLink?: boolean;
} & TMyTicketParsed;

const MyTicket = ({
    className,
    draw,
    product,
    ticketId,
    numbers,
    prize,
    numbersWin,
    status,
    paymentStatus,
    registrationStatus,
    notLink = false,
    isContent = "future",
}: TMyTicketProps) => {
    const typeScreen = useAppScreen();
    const logo = product?.pictures.find((item) => item.type === "ICON_BIG")!.url || undefined;

    return (
        <TicketWrapper to={!notLink ? getTicketDetailById(ticketId) : "#"} className={className} $notLink={notLink}>
            <MaskWrapper>
                <TitleWrap>
                    <TitleBlock alias={product?.alias} logo={logo} time={draw.time} drawNum={draw.drawNum} />
                </TitleWrap>
                <InfoWrap>
                    <InfoBlock
                        type={product?.type || "DIGITAL"}
                        typeScreen={typeScreen}
                        prize={prize}
                        numbers={numbers}
                        ticketNumber={ticketId}
                        isContent={isContent}
                        status={status}
                        drawDate={draw.drawDate}
                        paymentStatus={paymentStatus}
                        registrationStatus={registrationStatus}
                        numbersWin={numbersWin}
                        notLink={notLink}
                    />
                </InfoWrap>
                <ArrowWrap $notLink={notLink}>
                    <ArrowBlock />
                </ArrowWrap>
            </MaskWrapper>
        </TicketWrapper>
    );
};

export default memo(MyTicket);
