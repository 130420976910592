import React from "react";
import { Table } from "~molecules/table";
import { TBingoEditionDetails } from "~types/products";
import { col, getTableData } from "./helpers";

type TBingoTable = {
    bingoDetails: TBingoEditionDetails[];
    className?: string;
};

const BingoTable = ({ bingoDetails, className }: TBingoTable) => {
    return <Table className={className} data={getTableData(bingoDetails)} scheme={col} tableType="solid" />;
};

export default BingoTable;
