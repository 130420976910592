import React, { useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { TItem } from "~molecules/slider-swiper/slider-swiper";
import { OfflineBlock } from "~organisms/offline-block";
import { breakpoints } from "~tokens/screen";
import { MOMENTARY_1, MOMENTARY_2, MOMENTARY_3 } from "~constants/routes";
import pic1 from "./assets/pic1.png";
import pic2 from "./assets/pic2.png";
import pic3 from "./assets/pic3.png";
import { ContentWrap, FigureStyled, HeaderWrap, ImageWrapper, SliderSwiperStyled, TitleSlide, Wrapper } from "./theme";

type TNewLotteriesProps = {
    className?: string;
};

const lotteryList: { id: number; src: string; url: string; title: string; caption: string }[] = [
    { id: 0, src: pic1, url: MOMENTARY_1, title: "Летим к мечте", caption: "Главный приз 500 000 ₽" },
    { id: 1, src: pic2, url: MOMENTARY_2, title: "Полет желаний", caption: "Главный приз 100 000 ₽" },
    { id: 2, src: pic3, url: MOMENTARY_3, title: "Путь к удаче", caption: "Главный приз 100 000 ₽" },
    // { id: 3, src: pic4, url: MOMENTARY_4, title: "Счастливый билет. Рим", caption: "Главный приз 350 000 ₽" },
    // { id: 4, src: pic5, url: MOMENTARY_5, title: "Счастливый билет. Париж", caption: "Главный приз 350 000 ₽" },
    // { id: 5, src: pic6, url: MOMENTARY_6, title: "Счастливый билет. Сочи", caption: "Главный приз 350 000 ₽" },
    // { id: 6, src: pic7, url: MOMENTARY_7, title: "Счастливый билет. Казань", caption: "Главный приз 350 000 ₽" },
    // { id: 7, src: pic8, url: MOMENTARY_8, title: "Побег с поезда", caption: "Главный приз 1 000 000 ₽" },
    // { id: 8, src: pic9, url: MOMENTARY_9, title: "Золотое дело", caption: "Главный приз 1 000 000 ₽" },
];

const breakpointsSettings = {
    [breakpoints.desktopXL]: {
        slidesPerView: 3,
    },
    [breakpoints.tablet]: {
        slidesPerView: 2,
    },
};

const InstantLotteriesSlider = ({ className }: TNewLotteriesProps) => {
    const { color } = useContext(ThemeContext);

    const renderSlide = useCallback(
        (item: TItem) => {
            return (
                <Link to={item.url as string}>
                    <FigureStyled key={item.id}>
                        <ImageWrapper>
                            <img src={item.src} alt={item.title} />
                        </ImageWrapper>
                        <TitleSlide>
                            <TitleAdaptive tag="div" size="title24" className="mb8" color={color.text.primary}>
                                {item.title}
                            </TitleAdaptive>
                            <TextAdaptive tag="div" weight="medium" size="text20" color={color.text.secondary}>
                                {item.caption}
                            </TextAdaptive>
                        </TitleSlide>
                    </FigureStyled>
                </Link>
            );
        },
        [color.text.primary, color.text.secondary],
    );
    return (
        <Wrapper className={className}>
            <ContentWrap className={"container-app"}>
                <HeaderWrap className="mb32 mb16--tablet">
                    <div>
                        <TitleAdaptive
                            className="mb24 mb16--tablet mb8--mobile"
                            tag="h2"
                            size="title38"
                            color={color.text.headline}
                        >
                            Бестиражные лотереи
                        </TitleAdaptive>
                        <OfflineBlock className="mb8" />
                        <TextAdaptive size="text16" color={color.text.primary}>
                            Покупайте билеты бестиражной лотерии в отделениях Почты России и Россельхозбанка
                        </TextAdaptive>
                    </div>
                </HeaderWrap>
                <SliderSwiperStyled
                    slidesPerView={1.2}
                    items={lotteryList}
                    breakpoints={breakpointsSettings}
                    renderSlide={renderSlide}
                />
            </ContentWrap>
        </Wrapper>
    );
};

export default InstantLotteriesSlider;
