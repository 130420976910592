import React from "react";
import { Box, Flex } from "~atoms/flex-box";
import { Text } from "~atoms/text";
import { TextAdaptive } from "~atoms/text-adaptive";
import { WHITE } from "~tokens/colors";
import { BoxStyled, TableHeadersStyled, TableRules4in20Wrapper, TableStyled, TableWrapper } from "./theme";

const headers = [
    "Категория выигрышей",
    "Совпало чисел в поле 1",
    "Совпало чисел в поле 2",
    "Распределение призового фонда (% от выручки)",
    "Распределение призового фонда СЛ* (% от выручки)",
];

const tableRow1 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
const tableRow2 = [
    "4",
    "4",
    "3",
    "4",
    "2",
    "4",
    "1",
    "4",
    "0",
    "3",
    "3",
    "2",
    "3",
    "1",
    "3",
    "0",
    "2",
    "2",
    "1",
    "2",
    "0",
];
const tableRow3 = [
    "4",
    "3",
    "4",
    "2",
    "4",
    "1",
    "4",
    "0",
    "4",
    "3",
    "2",
    "3",
    "1",
    "3",
    "0",
    "3",
    "2",
    "1",
    "2",
    "0",
    "2",
];
const tableRow4 = ["0-60", "0-60", "0-60", "0-60", "0-60", "0-60", "0-60", "0-60", "0-60", "0-60", "0-60", "0-60"];
const tableRow5 = [
    "23,36",
    "5,12",
    "1,16",
    "1,17",
    "2,04",
    "0,98",
    "7,36",
    "9,18",
    "9,32",
    "12,44",
    "100 ₽ **",
    "27,26",
];

type TTableRules4in20 = {
    className?: string;
};

const TableRules4in20 = ({ className }: TTableRules4in20) => {
    return (
        <TableRules4in20Wrapper className={className}>
            <Flex $direction={"row"} justify={"space-between"}>
                <Box flexBasis={"162px"} className={"mr20"} flexShrink={0}>
                    <TableHeadersStyled>
                        <tbody>
                            {headers.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <Text size={"text14"} weight={"medium"} color={WHITE}>
                                            {item}
                                        </Text>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </TableHeadersStyled>
                </Box>
                <BoxStyled>
                    <TableWrapper>
                        <TableStyled>
                            <tbody>
                                <tr>
                                    {tableRow1.map((item, index) => (
                                        <td colSpan={2} key={index} className={"tableRow1"}>
                                            <TextAdaptive size={"text14"} weight={"demiBold"} color={WHITE}>
                                                {item}
                                            </TextAdaptive>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    {tableRow2.map((item, index) => (
                                        <td
                                            colSpan={index === 0 || index === 9 || index === 16 ? 2 : 1}
                                            key={index}
                                            className={"rowColors"}
                                        >
                                            <TextAdaptive size={"text14"} weight={"demiBold"} color={WHITE}>
                                                {item}
                                            </TextAdaptive>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    {tableRow3.map((item, index) => (
                                        <td
                                            colSpan={index === 0 || index === 9 || index === 16 ? 2 : 1}
                                            key={index}
                                            className={"tableRow3 rowColors"}
                                        >
                                            <TextAdaptive size={"text14"} weight={"demiBold"} color={WHITE}>
                                                {item}
                                            </TextAdaptive>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    {tableRow4.map((item, index) => (
                                        <td colSpan={2} key={index} className={"tableRow4"}>
                                            <TextAdaptive size={"text14"} weight={"demiBold"} color={WHITE}>
                                                {item}
                                            </TextAdaptive>
                                        </td>
                                    ))}
                                </tr>
                                <tr>
                                    {tableRow5.map((item, index) => (
                                        <td colSpan={2} key={index} className={"tableRow5"}>
                                            <TextAdaptive size={"text14"} weight={"demiBold"} color={WHITE}>
                                                {item}
                                            </TextAdaptive>
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </TableStyled>
                    </TableWrapper>
                </BoxStyled>
            </Flex>
        </TableRules4in20Wrapper>
    );
};

export default TableRules4in20;
