import React from "react";
import { isUndefined } from "lodash-es";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { getMoneyFormat } from "~helpers/format";
import { BalanceText, SkeletonStyled } from "../theme";

type TBalanceBoxProps = {
    className?: string;
    balance?: number;
};

const BalanceBox = ({ className, balance }: TBalanceBoxProps) => {
    return (
        <div className={className}>
            <TitleAdaptive size="title24" className="mb16">
                Баланс
            </TitleAdaptive>
            {!isUndefined(balance) ? (
                <BalanceText> {getMoneyFormat(balance as number)} ₽</BalanceText>
            ) : (
                <SkeletonStyled />
            )}
        </div>
    );
};

export default BalanceBox;
