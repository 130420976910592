import React from "react";
import styled from "styled-components";
import { OFFSET } from "../settings";

type TRowProps = {
    children?: React.ReactNode;
    className?: string;
};

const RowWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -${OFFSET / 2}px;
`;

export type TRowComponent = React.FC<TRowProps>;
export default function Row({ children, className }: TRowProps) {
    return <RowWrapper className={className}>{children}</RowWrapper>;
}
