import styled from "styled-components";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const ContactsBarWrapper = styled.div`
    background: ${WHITE};
    border-radius: 20px;
    padding: ${INDENT.m};

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm};
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs};
    }
`;

export const Header = styled(TitleAdaptive)`
    display: inline-block;
`;

export const AddressWrapper = styled.div``;

export const ContactWrapper = styled.div``;

export const Link = styled.a``;
