import React, { useEffect } from "react";
import { useBuyTicketsCardBingoLogic } from "~organisms/buy-tickets-card/_hooks/useBuyTicketsCardBingoLogic";
import { TBingoTypes } from "~store/tickets/types";
import BuyTicketsBarView from "./buy-tickets-bar-view";

type TBuyTicketsBarProps = {
    className?: string;
    ticketType: TBingoTypes;
};

const BuyTicketsBarBingo = ({ className, ticketType }: TBuyTicketsBarProps) => {
    const {
        data: { ticketsCount, editionsCount, amount },
        cb: { handleBuyNow },
    } = useBuyTicketsCardBingoLogic(ticketType);

    const footer = document.querySelector(".footer");

    useEffect(() => {
        ticketsCount !== 0
            ? footer?.classList.add("buy-ticket-bar-active")
            : footer?.classList.remove("buy-ticket-bar-active");
    }, [footer?.classList, ticketsCount]);

    if (ticketsCount === 0) {
        return null;
    }

    return (
        <BuyTicketsBarView
            amount={amount}
            handleBuyNow={handleBuyNow}
            ticketsCount={ticketsCount}
            editionsCount={editionsCount}
            className={className}
        />
    );
};

export default BuyTicketsBarBingo;
