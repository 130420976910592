import styled, { FlattenSimpleInterpolation, SimpleInterpolation } from "styled-components";

export const TitleWrapper = styled.div<{
    size: FlattenSimpleInterpolation;
    weight: SimpleInterpolation;
    color?: string;
}>`
    color: ${({ color, theme }) => (color ? color : theme.color.text.headline)};
    ${(props) => props.size}

    &&& {
        ${({ weight }) => weight}
    }
`;
