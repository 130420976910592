import React from "react";
import { TTextAdaptiveSize, TTextAdaptiveTag, TWeight } from "~atoms/text-adaptive/text-adaptive";
import {
    text20,
    text16,
    text16M,
    text14,
    text14M,
    text14B,
    text12,
    text12M,
    text24,
    text24M,
    fontWeightNormal,
    fontWeight600,
    fontWeightBold,
    fontWeight500,
} from "~tokens/typography";
import { TextWrapper } from "./theme";

type TSizeText = 1 | 2 | 3 | 4 | 5 | "1" | "2" | "3" | "4" | "2m" | "3m" | "3b" | "4m" | "5m" | TTextAdaptiveSize;

type TProps = {
    tag?: TTextAdaptiveTag;
    children: React.ReactNode;
    size?: TSizeText;
    color?: string;
    weight?: TWeight;
    className?: string;
};

const Text = ({ children, tag = "p", size = 1, color, className, weight = "normal" }: TProps) => {
    const resultSize = {
        1: text20,
        2: text16,
        3: text14,
        4: text12,
        5: text24,
        "2m": text16M,
        "3m": text14M,
        "3b": text14B,
        "4m": text12M,
        "5m": text24M,
        text12: text12,
        text14: text14,
        text16: text16,
        text20: text20,
    }[size];
    const resultWeight = {
        normal: fontWeightNormal,
        demiBold: fontWeight600,
        bold: fontWeightBold,
        medium: fontWeight500,
    }[weight];
    return (
        <TextWrapper className={className} color={color} size={resultSize} weight={resultWeight} as={tag}>
            {children}
        </TextWrapper>
    );
};

export default Text;
