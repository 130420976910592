type Nullable<T> = T | null | undefined;

/**
 * Generic Type Guard function for filtering array of type.
 * Allows to avoid implicit type conversion while filtering array from null|undefined values
 *
 * Example:
 * (productIds.map((key) => products[key]).filter(Boolean) as ProductItem[])
 *
 * Usage:
 * productIds.map((key) => products[key]).filter(isDefined)
 
 * @param {Nullable<T>} element
 * @returns {element is NonNullable<T>}
 */
export const isDefined = <T>(element: Nullable<T>): element is NonNullable<T> => element !== null;
