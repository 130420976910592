import styled from "styled-components";
import { adaptive } from "~assets/styles/tokens/screen";
import { CardBox } from "~atoms/box";
import { TICKET_GREY } from "~tokens/colors";
import { TAlias, TProductType } from "~types/products";

// import { TScreen } from "~hooks/useAppScreen";
// import digital4in20Desk from "../assets/4-20-Desktop-Tablet.png";
// import digital4in20Mob from "../assets/4-20-Mobile.png";
// import digital5in36Desk from "../assets/5-36-Desktop-Tablet.png";
// import digital5in36Mob from "../assets/5-36-Mobile.png";
// import digital5in50Desk from "../assets/5-50-Desktop-Tablet.png";
// import digital5in50Mob from "../assets/5-50-Mobile.png";
// import digital8in20Desk from "../assets/8-20-Desktop-Tablet.png";
// import digital8in20Mob from "../assets/8-20-Mobile.png";
// import bingo3x3Desk from "../assets/Bingo-3x3-Desktop-Tablet.png";
// import bingo3x3Mob from "../assets/Bingo-3x3-Mobile.png";
// import bingo75Desk from "../assets/Bingo-75-Desktop-Tablet.png";
// import bingo75Mob from "../assets/Bingo-75-Mobile.png";
// import bingo90Desk from "../assets/Bingo-90-Desktop-Tablet.png";
// import bingo90Mob from "../assets/Bingo-90-Mobile.png";

const TicketDetailsWrapper = styled(CardBox)`
    display: flex;
    flex-flow: column nowrap;
    height: fit-content;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

// const getBackgroundImg = (screen: TScreen, lotteryType: TAlias) => {
//     switch (lotteryType) {
//         case "digital-5x36":
//             if (screen === "mobile") {
//                 return digital5in36Mob;
//             }
//             return digital5in36Desk;
//         case "digital-5x50":
//             if (screen === "mobile") {
//                 return digital5in50Mob;
//             }
//             return digital5in50Desk;
//         case "digital-8x20":
//             if (screen === "mobile") {
//                 return digital8in20Mob;
//             }
//             return digital8in20Desk;
//         case "digital-4x20":
//             if (screen === "mobile") {
//                 return digital4in20Mob;
//             }
//             return digital4in20Desk;
//         case "bingo-3x3":
//             if (screen === "mobile") {
//                 return bingo3x3Mob;
//             }
//             return bingo3x3Desk;
//         case "bingo-90":
//             if (screen === "mobile") {
//                 return bingo90Mob;
//             }
//             return bingo90Desk;
//         case "bingo-75":
//             if (screen === "mobile") {
//                 return bingo75Mob;
//             }
//             return bingo75Desk;
//         default:
//             if (screen === "mobile") {
//                 return digital5in36Mob;
//             }
//             return digital5in36Desk;
//     }
// };

const TicketWrapper = styled.div<{ alias: TAlias }>`
    ${adaptive.minWidth.tablet} {
        width: ${({ alias }) => (alias === "bingo-90" ? "476px" : "438px")};
    }

    ${adaptive.minWidth.desktopXL} {
        width: 100%;
    }
`;

const TicketImgWrap = styled.div<{ $alias: TAlias; $type: TProductType }>`
    display: flex;
    flex-direction: column;
    border: 1px solid ${TICKET_GREY};
    border-radius: 14px;
    overflow: hidden;
    mask-image: linear-gradient(
            to bottom,
            black 0,
            black calc(100% - 488px),
            transparent calc(100% - 488px),
            transparent 100%
        ),
        radial-gradient(circle at left calc(100% - 476px), transparent 10px, black 11px, black 40%, transparent 0),
        radial-gradient(circle at right calc(100% - 476px), transparent 10px, black 11px, black 40%, transparent 0),
        linear-gradient(to bottom, transparent 0, transparent calc(100% - 464px), black calc(100% - 464px), black 100%);
    ${adaptive.minWidth.tablet} {
        mask-image: linear-gradient(
                to bottom,
                black 0,
                black calc(100% - 316px),
                transparent calc(100% - 316px),
                transparent 100%
            ),
            radial-gradient(circle at left calc(100% - 304px), transparent 10px, black 11px, black 65%, transparent 0),
            radial-gradient(circle at right calc(100% - 304px), transparent 10px, black 11px, black 65%, transparent 0),
            linear-gradient(
                to bottom,
                transparent 0,
                transparent calc(100% - 292px),
                black calc(100% - 292px),
                black 100%
            );
    }
    ${adaptive.minWidth.desktopXL} {
        mask-image: linear-gradient(
                to right,
                black 0,
                black calc(100% - 440px),
                transparent calc(100% - 440px),
                transparent 100%
            ),
            radial-gradient(circle at calc(100% - 428px) top, transparent 10px, black 11px, black 50%, transparent 0),
            radial-gradient(circle at calc(100% - 428px) bottom, transparent 10px, black 11px, black 50%, transparent 0),
            linear-gradient(
                to right,
                transparent 0,
                transparent calc(100% - 416px),
                black calc(100% - 416px),
                black 100%
            );
    }
    ${adaptive.minWidth.desktopXXL} {
        mask-image: linear-gradient(
                to right,
                black 0,
                black calc(100% - 518px),
                transparent calc(100% - 518px),
                transparent 100%
            ),
            radial-gradient(circle at calc(100% - 505px) top, transparent 10px, black 11px, black 50%, transparent 0),
            radial-gradient(circle at calc(100% - 505px) bottom, transparent 10px, black 11px, black 50%, transparent 0),
            linear-gradient(
                to right,
                transparent 0,
                transparent calc(100% - 492px),
                black calc(100% - 492px),
                black 100%
            );
    }
    ${adaptive.minWidth.tablet} {
        border-radius: 16px;
    }

    ${adaptive.minWidth.desktopXL} {
        flex-direction: row;
    }
`;

const Header = styled.div``;

const TicketContainer = styled.div`
    position: relative;
    overflow: hidden;
    &:before,
    &:after {
        display: block;
        content: "";
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 1px solid ${TICKET_GREY};
        z-index: 1;
        top: calc(100% - 476px - 11px);
        position: absolute;
    }
    &:before {
        left: -11px;
    }
    &:after {
        right: -11px;
    }

    ${adaptive.minWidth.tablet} {
        &:before,
        &:after {
            top: calc(100% - 304px - 11px);
        }
    }
    ${adaptive.minWidth.desktopXL} {
        &:before,
        &:after {
            right: unset;
            top: unset;
            bottom: unset;
            left: calc(100% - 428px - 11px);
        }
        &:before {
            top: -11px;
        }
        &:after {
            bottom: -11px;
        }
    }
    ${adaptive.minWidth.desktopXXL} {
        &:before,
        &:after {
            left: calc(100% - 505px - 11px);
        }
    }
`;

export { TicketDetailsWrapper, ButtonWrapper, TicketWrapper, TicketImgWrap, Header, TicketContainer };
