import styled, { keyframes } from "styled-components";
import { TSkeletonProps } from "./skeleton";

const getSize = (absolute?: boolean, width?: string, height?: string) => {
    if (absolute) {
        return `
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          right: 0;
          z-index: 99999;
        `;
    }

    return `
      width: ${width};
      height: ${height};
    `;
};

const skeletonGlow = keyframes`
  0% {
    background: rgba(206,217,224,.2);
    border-color: rgba(206,217,224,.2);
  }
  100% {
    background: rgba(92,112,128,.2);
    border-color: rgba(92,112,128,.2);
  }
`;

export const SkeletonWrapper = styled.div<TSkeletonProps>`
    border-radius: ${({ radius }) => radius};
    animation: ${skeletonGlow} 1s linear infinite alternate;
    pointer-events: none;
    ${({ absolute, width, height }) => getSize(absolute, width, height)};
`;
