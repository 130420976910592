import React, { useCallback } from "react";
import { Input } from "~atoms/input";
import { Text } from "~atoms/text";
import { Title } from "~atoms/title";
import { emailValidation } from "~organisms/buy-tickets-card/email-block/validation";
import { useUpdateProfile } from "~pages/profile/_hooks/useUpdateProfile";
import { isTrue } from "~helpers/validation";
import { otherErrors } from "~constants/error-messages";
import { useForm } from "~hooks/useForm";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";
import { ButtonStyled, EmailBlockWrapper } from "./theme";

type TEmailBlockProps = {
    email?: string;
    handleSuccess?: () => void;
};

const EmailBlock = ({ email = "", handleSuccess }: TEmailBlockProps) => {
    const handleUpdate = useUpdateProfile();
    const { handleFieldChange } = useMutationHandles("profile");
    const { add } = useNotifications();
    const onSubmit = useCallback(
        async (form) => {
            const { error, result } = await handleUpdate({ email: form.email });
            if (error) {
                return add({ message: error?.data?.message ?? otherErrors.setEmail });
            }
            if (!error && result) {
                handleFieldChange("data.email", result.email);
                handleSuccess && handleSuccess();
            }
        },
        [handleUpdate, add, handleFieldChange, handleSuccess],
    );

    const { form, errors, handleSubmit, handleChange } = useForm(
        {
            email,
        },
        { onSubmit, validate: emailValidation },
    );

    return (
        <EmailBlockWrapper
            style={{ width: "438px", display: "flex", height: "438px", justifyContent: "center", alignItems: "center" }}
        >
            <form onSubmit={handleSubmit}>
                <Title tag={"h4"} size={"title24"} weight={"demiBold"} className={"mb8"}>
                    Укажите свой адрес электронной почты
                </Title>
                <Text size={"text14"} className={"mb32"}>
                    На этот e-mail будут приходить электронные чеки об оплате
                </Text>
                <Input
                    id={"auth-phone"}
                    label={"Введите email"}
                    autoFocus={true}
                    name={"email"}
                    value={form.email}
                    error={isTrue(errors.email)}
                    errorText={errors.email}
                    onChange={handleChange}
                    className={"mb16"}
                />
                <ButtonStyled type={"submit"} className={"mauto"}>
                    Продолжить
                </ButtonStyled>
            </form>
        </EmailBlockWrapper>
    );
};

export default EmailBlock;
