import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { MastercardIcon, MirIcon, VisaIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import { LinkStyled, LiStyled, PaymentAreaWrapper, PaymentIconsWrapper, UlStyled } from "./theme";

type TPaymentAreaProps = {
    className?: string;
};

type TPaymentMethod = {
    title: string;
    href: string;
    icon: React.ReactNode;
};

const paymentMethods = [
    {
        title: "Visa",
        href: "https://www.visa.com.ru/",
        icon: <VisaIcon />,
    },
    {
        title: "MasterCard",
        href: "https://www.mastercard.ru/",
        icon: <MastercardIcon />,
    },
    {
        title: "Mir",
        href: "https://privetmir.ru/",
        icon: <MirIcon />,
    },
];

const PaymentArea = ({ className }: TPaymentAreaProps) => {
    const { color } = useContext(ThemeContext);
    return (
        <PaymentAreaWrapper className={className}>
            <TextAdaptive size={"text14"} className={"mb4"} color={color.blocks.footer.text.primary}>
                Способы оплаты
            </TextAdaptive>
            <PaymentIconsWrapper className={"mb4"}>
                <UlStyled>
                    {paymentMethods.map((method: TPaymentMethod) => (
                        <LiStyled key={method.title} className="method-link">
                            <LinkStyled href={method.href} target="_blank" rel="noreferrer" title={method.title}>
                                {method.icon}
                            </LinkStyled>
                        </LiStyled>
                    ))}
                </UlStyled>
            </PaymentIconsWrapper>
            <TextAdaptive size={"text12"} color={color.blocks.footer.text.secondary}>
                Мы гарантируем безопасность всех способов оплаты и не сохраняем ваши данные
            </TextAdaptive>
        </PaymentAreaWrapper>
    );
};

export default PaymentArea;
