import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export default function useAppHistory() {
    const history = useHistory();

    const go = useCallback(
        (step: string, state?: unknown) => {
            history.push(step, state);
        },
        [history],
    );

    return { go };
}
