import React from "react";
import { TWeight } from "~atoms/text-adaptive/text-adaptive";
import { fontWeightBold, fontWeight600, fontWeight500, fontWeightNormal } from "~tokens/typography";
import { TitleAdaptiveWrapper } from "./theme";

export type TTitleAdaptiveSize = "title12" | "title16" | "title20" | "title24" | "title38";
export type TTitleAdaptiveTag = "h1" | "h2" | "h3" | "h4" | "h5" | "div" | "span";

type TProps = {
    tag?: TTitleAdaptiveTag;
    children: React.ReactNode;
    size?: TTitleAdaptiveSize;
    color?: string;
    className?: string;
    weight?: TWeight;
};

const TitleAdaptive = ({ children, tag = "h5", size = "title12", color, className, weight = "demiBold" }: TProps) => {
    const resultWeight = {
        normal: fontWeightNormal,
        medium: fontWeight500,
        demiBold: fontWeight600,
        bold: fontWeightBold,
    }[weight];
    return (
        <TitleAdaptiveWrapper className={className} color={color} size={size} as={tag} weight={resultWeight}>
            {children}
        </TitleAdaptiveWrapper>
    );
};

export default TitleAdaptive;
