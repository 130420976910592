import React from "react";
import { Link } from "~atoms/link";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { AmountText } from "~pages/wallet/verification-block/theme";
import { getParsedBalance, getPhoneHelpFormat } from "~helpers/format";
import { nationalLotteryPhone } from "~constants/links";

type TVerificationFullProps = {
    limit: number;
};

const VerificationFull = ({ limit }: TVerificationFullProps) => {
    return (
        <>
            <TitleAdaptive size="title24" className="mb8">
                Превышение лимита кошелька
            </TitleAdaptive>
            <TextAdaptive size="text16" className="mb8" weight="medium">
                Доступный лимит кошелька: {getParsedBalance(limit)} ₽.
            </TextAdaptive>
            <TextAdaptive size="text16" className="mb8" weight="medium">
                Для увеличения лимита кошелька до{" "}
                <AmountText size="text16" tag="span" weight="demiBold">
                    600 000 ₽
                </AmountText>{" "}
                необходимо пройти полную идентификацию личности.
            </TextAdaptive>
            <TextAdaptive size="text16" className="mb8" weight="medium">
                Для прохождения идентификации обратитесь в нашу техподдержку по номеру{" "}
                <Link intent href={`tel:${nationalLotteryPhone}`}>
                    <TextAdaptive size="text16" className="mb8" weight="medium" tag="span">
                        {getPhoneHelpFormat(nationalLotteryPhone)}
                    </TextAdaptive>
                </Link>
            </TextAdaptive>
        </>
    );
};

export default VerificationFull;
