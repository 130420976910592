import styled, { css } from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Col } from "~atoms/grid/col";
import { Text } from "~atoms/text";
import { GREY_DARK2, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeight500 } from "~tokens/typography";

export const BuyTicketsCardWrapper = styled.div`
    width: 100%;
    z-index: 1;
    background-color: ${WHITE};
    border-radius: 20px;
`;

export const CardHeader = styled.div`
    height: 56px;
    padding: ${INDENT.xs} ${INDENT.none} ${INDENT.xxxs};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.color.background.secondaryElevated};
    border-radius: 20px 20px 0 0;

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.xxs};
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xxxs};
    }
`;

export const CardNumbersBlock = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.color.background.stroke};
`;

export const LineDotted = styled.div`
    border-bottom: 1px dashed ${GREY_DARK2};
    flex-grow: 1;
    transform: translateY(-3px);
`;

export const CardContent = styled.div`
    padding: ${INDENT.sm} ${INDENT.m} ${INDENT.m};
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${({ theme }) => theme.color.background.primaryElevated};

    & > button {
        width: 100%;
        ${fontWeight500};
    }

    & > .add-btn .prefix-wrap {
        display: none;
    }

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm} ${INDENT.ls};
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs} ${INDENT.xxs} ${INDENT.xxs};
    }
`;

export const CardNumbers = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
`;

export const CardTotal = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    &&& h2 {
        ${fontWeight500};
    }
`;

export const ButtonText = styled(Text)`
    color: ${({ theme }) => theme.color.icons.primary};
`;

export const commonWrapperModalWindow = css`
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${adaptive.maxWidth.tablet} {
        width: 360px;
    }
`;

export const ButtonStyled = styled(ButtonAdaptive)`
    width: 100%;
`;

export const ColStyled = styled(Col)`
    ${adaptive.maxWidth.desktopXXL} {
        &:first-child {
            order: 1;
        }
    }

    ${adaptive.maxWidth.tablet} {
        &:first-child {
            order: 0;
        }
    }
`;
