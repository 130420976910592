import React from "react";
import { FacebookIcon, InstagramIcon, OdnoklassnikiIcon, VkIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import {
    SocialMediaIconsWrapper,
    SocialMediaAreaWrapper,
    UlStyled,
    LiStyled,
    LinkStyled,
} from "~organisms/social-media-area/theme";

type TSocialMediaAreaProps = {
    className?: string;
};

type TSocialMedia = {
    title: string;
    href: string;
    icon: React.ReactNode;
};

const socialMediaArr = [
    {
        title: "Одноклассники",
        href: "https://ok.ru/group/58784470794349",
        icon: <OdnoklassnikiIcon />,
    },
    {
        title: "ВКонтакте",
        href: "https://vk.com/nationallotteryofficial",
        icon: <VkIcon />,
    },
    {
        title: "Instagram",
        href: "https://www.instagram.com/nationallottery.official/",
        icon: <InstagramIcon />,
    },
    {
        title: "Facebook",
        href: "https://www.facebook.com/nationallotteryofficial/",
        icon: <FacebookIcon />,
    },
];

const SocialMediaArea = ({ className }: TSocialMediaAreaProps) => {
    return (
        <SocialMediaAreaWrapper className={className}>
            <TextAdaptive size={"text14"} weight={"medium"} className={"mb8"}>
                Национальная лотерея в соцсетях
            </TextAdaptive>
            <SocialMediaIconsWrapper>
                <UlStyled>
                    {socialMediaArr.map((socialMedia: TSocialMedia) => (
                        <LiStyled key={socialMedia.title} className="soc-link">
                            <LinkStyled
                                href={socialMedia.href}
                                target="_blank"
                                rel="noreferrer"
                                title={socialMedia.title}
                            >
                                {socialMedia.icon}
                            </LinkStyled>
                        </LiStyled>
                    ))}
                </UlStyled>
            </SocialMediaIconsWrapper>
        </SocialMediaAreaWrapper>
    );
};

export default SocialMediaArea;
