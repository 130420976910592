import React, { memo, PropsWithChildren, useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { Map, ObjectManager, ObjectManagerFeature, YMaps, YMapsApi, ZoomControl } from "react-yandex-maps";
import { TOffice } from "~organisms/maps/offices-list";
import iconMark from "./assets/pin-office.svg";
import { ClusterStyled, MapsWrapper } from "./theme";

type TMapsProps = {
    className?: string;
};

const mapData = {
    center: [55.751574, 37.573856],
    zoom: 13,
    controls: [],
};

const createIconContentLayout = (ymaps: YMapsApi) => {
    return ymaps.templateLayoutFactory.createClass(
        renderToString(<ClusterStyled>$[properties.geoObjects.length]</ClusterStyled>),
    );
};

const getFeatures = (officesList: TOffice[]) =>
    officesList.map((office) => {
        return {
            type: "Feature",
            id: office.latitude,
            geometry: {
                type: "Point",
                coordinates: [office.latitude, office.longitude],
            },
            properties: {
                balloonContentBody: renderToString(<div>{office.title}</div>),
            },
            options: {
                iconImageHref: iconMark,
                iconImageSize: [40, 46],
                iconLayout: "default#image",
                iconImageOffset: [-17, -46],
                hideIconOnBalloonOpen: true,
            },
            office,
        };
    });

const Maps = ({ className }: PropsWithChildren<TMapsProps>) => {
    const [ymaps, setYmaps] = useState<YMapsApi | null>(null);
    const [officesList, setOfficesList] = useState<TOffice[]>([]);
    const myMap = useRef<any>(null);

    useEffect(() => {
        (async () => {
            const { officesList: offices } = await import("./offices-list");
            setOfficesList(offices);
        })();
    }, []);

    return (
        <MapsWrapper className={className}>
            <YMaps
                query={{
                    lang: "ru_RU",
                    load: "Placemark,Map,Clusterer,templateLayoutFactory,geocode",
                }}
            >
                <Map
                    defaultState={mapData}
                    className={"map-container"}
                    instanceRef={(ref) => (myMap.current = ref)}
                    onLoad={(ymaps) => setYmaps(ymaps)}
                >
                    <ObjectManager
                        options={{
                            clusterize: true,
                            groupByCoordinates: false,
                            gridSize: 64,
                            clusterIcons: [
                                {
                                    size: [50, 50],
                                    offset: [-25, -25],
                                },
                            ],
                            clusterIconContentLayout: ymaps && createIconContentLayout(ymaps),
                            hideIconOnBalloonOpen: false,
                            clusterNumbers: [50],
                            balloonOffset: [-2, -1],
                            balloonPanelMaxMapArea: 0,
                        }}
                        objects={{
                            preset: "islands#greenDotIcon",
                        }}
                        features={getFeatures(officesList) as ObjectManagerFeature[]}
                        modules={["objectManager.addon.objectsBalloon", "objectManager.addon.objectsHint"]}
                    />
                    <ZoomControl options={{ size: "small", position: { top: 108, right: 20 } }} />
                </Map>
            </YMaps>
        </MapsWrapper>
    );
};

export default memo(Maps);
