import styled from "styled-components";
import { PURPLE, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const ContactsContent = styled.div`
    display: flex;
    flex-flow: column nowrap;
    padding: ${INDENT.m};
    background-color: ${WHITE};
    border-radius: 20px;

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm};
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs};
    }
`;

export const ContactsAside = styled.div``;

export const MapContent = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 52.43%;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

export const TextWrapper = styled.div`
    width: 100%;
    max-width: 432px;
`;

export const ContactWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const IconWrapper = styled.div`
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    & > svg {
        color: ${PURPLE};
        flex-shrink: 0;
    }
`;
