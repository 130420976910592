import React, { Context, PropsWithChildren, useCallback, useState } from "react";
import { TVariant } from "~atoms/button-adaptive/button-adaptive";
import { CloseIcon } from "~atoms/icons";
import {
    ButtonClose,
    CSSTransitionStyled,
    duration,
    ModalBackground,
    ModalWindowWrapper,
    transitionName,
} from "~molecules/modal-window/theme";

type TModalWindowContext = {
    content: TContent | null;
    setContent: (content: TContent) => void;
    onClose: () => void;
};

type TContent = {
    component: React.ComponentType<any>;
    props?: Record<string, any>;
    options?: {
        btnClose?: boolean;
        btnVariant?: TVariant;
        btnIntent?: boolean;
        borderRadius?: number;
        onClickClose?: () => void;
    };
};

type TModalWindowProps = {
    className?: string;
};

export const ModalWindowContext: Context<TModalWindowContext> = React.createContext<TModalWindowContext>({
    content: null,
    setContent: () => null,
    onClose: () => null,
});
const ModalWindow = ({ className, children }: PropsWithChildren<TModalWindowProps>) => {
    const [isShow, setShow] = useState(false);
    const [content, setContent] = useState<TContent | null>(null);

    const handleSetContent = useCallback((content: TContent) => {
        setContent(content);
        document.body.style.overflow = "hidden";
        setShow(true);
    }, []);

    const handleClose = useCallback(() => {
        document.body.style.overflow = "";
        setShow(false);
    }, []);

    const handleClickClose = useCallback(() => {
        handleClose();
        if (content?.options?.onClickClose) {
            content.options.onClickClose();
        }
    }, [content?.options, handleClose]);

    const handleClearContent = useCallback(() => {
        setContent(null);
    }, []);

    return (
        <ModalWindowContext.Provider value={{ content, setContent: handleSetContent, onClose: handleClose }}>
            <CSSTransitionStyled
                in={isShow}
                timeout={duration}
                classNames={"modal-wrapper " + transitionName}
                appear
                unmountOnExit
                onExited={handleClearContent}
            >
                <ModalWindowWrapper className={className} borderRadius={content?.options?.borderRadius}>
                    {content?.options?.btnClose && (
                        <ButtonClose
                            onClick={handleClickClose}
                            variant={content.options.btnVariant ?? "primary"}
                            intent={content.options.btnIntent ?? true}
                        >
                            <CloseIcon />
                        </ButtonClose>
                    )}
                    {content && <content.component {...content.props} />}
                </ModalWindowWrapper>
            </CSSTransitionStyled>
            {isShow && <ModalBackground />}
            {children}
        </ModalWindowContext.Provider>
    );
};

export default ModalWindow;
