import React, { useCallback } from "react";
import { Text } from "~atoms/text";
import { TLotteryTicketProps } from "~organisms/lottery-ticket/lottery-ticket";
import { BLUE_NATIVE, GREY_DARK, WHITE } from "~tokens/colors";
import { getLotteryRouteById } from "~constants/routes";
import { ticketsSlogan } from "~constants/tickets-type";
import useAppHistory from "~hooks/useAppHistory";
import {
    ButtonAdaptiveStyled,
    LongMobileTicketWrapper,
    TicketActionZone,
    TicketActionZoneAmount,
    TicketActionZoneEdition,
    TicketActionZonePlaceholder,
    TicketBuyZone,
    TicketDescription,
    TicketDescriptionBody,
    TicketDescriptionLogo,
    TicketDescriptionPrize,
    TicketDescriptionTitle,
    TicketDescriptionSlogan,
} from "./theme";

type TLongMobileTicketProps = Omit<TLotteryTicketProps, "type">;

const LongMobileTicket = ({
    id,
    className,
    title,
    prize,
    note,
    edition,
    time,
    amount,
    backgroundUrl,
    logoSrc,
    active,
}: TLongMobileTicketProps) => {
    const { go } = useAppHistory();

    const handleClick = useCallback(() => {
        go(getLotteryRouteById(id));
    }, [go, id]);
    return (
        <LongMobileTicketWrapper onClick={handleClick} className={className} backgroundUrl={backgroundUrl}>
            <TicketDescription>
                <TicketDescriptionLogo src={logoSrc} alt={title} draggable={false} />
                <TicketDescriptionBody>
                    {active ? (
                        <>
                            <TicketDescriptionTitle color={WHITE} size={3} weight={"demiBold"}>
                                {title}
                            </TicketDescriptionTitle>
                            <TicketDescriptionPrize color={WHITE} size={5} weight={"demiBold"}>
                                {prize}
                            </TicketDescriptionPrize>
                            <Text color={WHITE} size={3} weight={"medium"}>
                                {note}
                            </Text>
                        </>
                    ) : (
                        <TicketDescriptionSlogan color={WHITE} size="text16" weight={"demiBold"}>
                            {ticketsSlogan[id]}
                        </TicketDescriptionSlogan>
                    )}
                </TicketDescriptionBody>
            </TicketDescription>
            <TicketActionZone>
                {active ? (
                    <>
                        <div>
                            <TicketActionZoneEdition size={4} weight={"medium"}>
                                {edition}
                            </TicketActionZoneEdition>
                            <Text size={4} weight={"medium"} color={GREY_DARK}>
                                {time}
                            </Text>
                        </div>
                        <TicketBuyZone>
                            <TicketActionZoneAmount weight={"demiBold"}>{amount} ₽</TicketActionZoneAmount>
                            <ButtonAdaptiveStyled size={"small"} intent>
                                <Text size={3} weight={"demiBold"} color={"inherit"}>
                                    Купить
                                </Text>
                            </ButtonAdaptiveStyled>
                        </TicketBuyZone>
                    </>
                ) : (
                    <TicketActionZonePlaceholder>
                        <Text size={"text14"} color={GREY_DARK}>
                            Нет активных тиражей
                        </Text>
                        <Text size={"text14"} color={BLUE_NATIVE}>
                            Как играть?
                        </Text>
                    </TicketActionZonePlaceholder>
                )}

                {/* <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <ButtonAdaptiveStyled size={"small"} intent>
                        <Text size={3} weight={"demiBold"} color={"inherit"}>
                            Скоро в продаже
                        </Text>
                    </ButtonAdaptiveStyled>
                </div> */}
            </TicketActionZone>
        </LongMobileTicketWrapper>
    );
};

export default LongMobileTicket;
