import styled from "styled-components";
import { INDENT } from "~tokens/sizes";

export const PaymentAreaWrapper = styled.div`
    max-width: 314px;
`;

export const PaymentIconsWrapper = styled.div`
    display: flex;
    justify-content: start;
`;

export const UlStyled = styled.ul`
    display: flex;
`;

export const LiStyled = styled.li`
    cursor: pointer;
    height: 43px;

    & + .method-link {
        margin-left: ${INDENT.xxxs};
    }
`;

export const LinkStyled = styled.a`
    position: relative;
    display: inline-block;
`;
