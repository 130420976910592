import styled from "styled-components";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeight500 } from "~tokens/typography";

export const TicketsBlockWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: fit-content;
    padding: ${INDENT.m};
    background-color: ${({ theme }) => theme.color.background.primaryElevated};
    border-radius: 20px;
    & > button {
        ${fontWeight500};
    }
    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm};
    }
    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs};
    }
`;
