import step1 from "./assets/rules/1.png";
import step2 from "./assets/rules/2.png";
import step3 from "./assets/rules/3.png";

export const rulesList = [
    {
        title: "Шаг 1.",
        desc: "Удалите защитный слой с игрового поля «Стирать здесь».",
        note: "Билет считается недействительным при повреждении поля с надписью «НЕ СТИРАТЬ».",
        img: step1,
    },
    {
        title: "Шаг 2.",
        desc: "Видите три одинаковых суммы? Вы выиграли! Ваш выигрыш равен этой сумме.",
        note: "",
        img: step2,
    },
    {
        title: "Шаг 3.",
        desc: "Если на игровом поле три одинаковых суммы и символ божьей коровки, Ваш выигрыш увеличивается в пять раз.",
        note: "",
        img: step3,
    },
];
