import { ACTIONS_TYPE, TNotificationActions, TNotificationState } from "./types";

export const initNotificationReducer = () => {
    const defaultState: TNotificationState = [];
    return (state = defaultState, action: TNotificationActions) => {
        switch (action.type) {
            case ACTIONS_TYPE.ADD:
                return [...state, action.payload];
            case ACTIONS_TYPE.REMOVE:
                return state.filter((msg) => msg.id !== action.payload);
            default:
                return state;
        }
    };
};
