import { css } from "styled-components";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const commonWrapperModalWindow = css`
    width: 412px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${INDENT.xl} ${INDENT.xs} ${INDENT.xs} ${INDENT.xs};
    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.m} ${INDENT.xxxs} ${INDENT.xxxs} ${INDENT.xxxs};
    }
    ${adaptive.maxWidth.tablet} {
        width: 328px;
    }
`;
