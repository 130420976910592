import React, { useCallback, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled, { ThemeContext } from "styled-components";
import { Button } from "~atoms/button";
import { Input } from "~atoms/input";
import { Link } from "~atoms/link";
import { Text } from "~atoms/text";
import { phoneValidationMessage } from "~organisms/auth-form/common-validation";
import { useCreateAccount } from "~organisms/auth-form/login-block/_hooks/useCreateAccount";
import { useOnLogin } from "~organisms/auth-form/login-block/_hooks/useOnLogin";
import { useSetPhoneBlock } from "~organisms/auth-form/login-block/_hooks/useSetPhoneBlock";
import { IconPassword } from "~organisms/auth-form/login-block/icon-password";
import { AuthHeader, ErrorText, LinkWrapper, wrapperAuthForm } from "~organisms/auth-form/theme";
import { RED } from "~tokens/colors";
import { isTrue } from "~helpers/validation";

type TLoginBlockProps = {
    className?: string;
};

type TFormValues = {
    phone: string;
    password: string;
};

const LoginBlockWrapper = styled.div`
    ${wrapperAuthForm}
`;

const LoginBlock = ({ className }: TLoginBlockProps) => {
    const setPhoneBlock = useSetPhoneBlock();
    const createAccount = useCreateAccount();
    const { color } = useContext(ThemeContext);
    const [errorResponse, setErrorResponse] = useState("");
    const handleLogin = useOnLogin(setErrorResponse);

    const { handleSubmit, control, getValues } = useForm<TFormValues>();

    const onSubmit = useCallback(
        async (form) => {
            await handleLogin(form.phone, form.password);
        },
        [handleLogin],
    );

    const [inputType, setInputType] = useState<{ password: "password" | "text" }>({
        password: "password",
    });

    const handleForgotPassword = useCallback(
        (event) => {
            event.preventDefault();
            void setPhoneBlock(getValues("phone"));
        },
        [getValues, setPhoneBlock],
    );

    const handleCreateAccount = useCallback(
        (event) => {
            event.preventDefault();
            void createAccount();
        },
        [createAccount],
    );

    return (
        <LoginBlockWrapper className={className}>
            <AuthHeader tag={"h4"}>Вход</AuthHeader>
            <Text className="mb32" size="text14" weight="normal">
                Новый пользователь? <Link onClick={handleCreateAccount}>Зарегистрируйтесь</Link>
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <Input
                            id={"auth-phone"}
                            label={"Телефон"}
                            typeMask={"phone"}
                            autoFocus={true}
                            autoComplete="username"
                            name={field.name}
                            placeholder={"+7"}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            className={"auth-field-form"}
                            error={fieldState.invalid || isTrue(errorResponse)}
                            errorText={!isTrue(errorResponse) && fieldState.error?.message}
                        />
                    )}
                    name="phone"
                    control={control}
                    rules={{
                        validate: phoneValidationMessage,
                    }}
                />
                <Controller
                    render={({ field }) => (
                        <Input
                            id={"auth-password"}
                            label={"Пароль"}
                            type={inputType.password}
                            name={field.name}
                            autoComplete="current-password"
                            placeholder={"Введите ваш пароль"}
                            value={field.value}
                            suffixIcon={
                                <IconPassword
                                    fieldName={"password"}
                                    isShow={isTrue(field.value)}
                                    setInputType={setInputType}
                                    inputType={inputType.password}
                                />
                            }
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            error={isTrue(errorResponse)}
                        />
                    )}
                    name="password"
                    control={control}
                />
                {errorResponse && (
                    <ErrorText className={"mt16"} size="text14" weight="medium" color={RED}>
                        {errorResponse}
                    </ErrorText>
                )}
                <Button type={"submit"} className={"auth-form-btn auth-field-form"}>
                    <Text size="text14" weight="demiBold" color={color.text.primary}>
                        Войти
                    </Text>
                </Button>
                <LinkWrapper>
                    <Link onClick={handleForgotPassword}>Забыли пароль?</Link>
                </LinkWrapper>
            </form>
        </LoginBlockWrapper>
    );
};

export default LoginBlock;
