import { useCallback, useEffect, useState } from "react";
import { breakpoints } from "~tokens/screen";

export type TScreen = "mobile" | "tablet" | "desktop" | "desktopXL";
const queriesMedia: { query: string; cb: (matches: boolean) => TScreen }[] = [
    {
        query: `(max-width: ${breakpoints.desktopXXL - 1}px)`,
        cb: (matches: boolean) => (matches ? "desktopXL" : "desktop"),
    },
    {
        query: `(max-width: ${breakpoints.desktopXL - 1}px)`,
        cb: (matches: boolean) => (matches ? "tablet" : "desktopXL"),
    },
    {
        query: `(max-width: ${breakpoints.tablet - 1}px)`,
        cb: (matches: boolean) => (matches ? "mobile" : "tablet"),
    },
];

const getCurrentTypeScreen = () => {
    let typeScreen: TScreen = "desktop";

    if (window.matchMedia(`(max-width: ${breakpoints.desktopXXL - 1}px)`).matches) {
        typeScreen = "desktopXL";
    }

    if (window.matchMedia(`(max-width: ${breakpoints.desktopXL - 1}px)`).matches) {
        typeScreen = "tablet";
    }

    if (window.matchMedia(`(max-width: ${breakpoints.tablet - 1}px)`).matches) {
        typeScreen = "mobile";
    }

    return typeScreen;
};

export function useAppScreen() {
    const [typeScreen, setTypeScreen] = useState<TScreen>(getCurrentTypeScreen());

    const handleMediaQuery = useCallback(
        (cb: (matches: boolean) => TScreen) => (mediaQuery: MediaQueryListEvent) => {
            setTypeScreen(cb(mediaQuery.matches));
        },
        [],
    );

    useEffect(() => {
        const matchMediaList: { mediaQuery: MediaQueryList; cb: (mediaQuery: MediaQueryListEvent) => void }[] = [];
        queriesMedia.forEach((item) => {
            const mediaQuery = window.matchMedia(item.query);
            const cb = handleMediaQuery(item.cb);
            mediaQuery.addListener(cb);
            matchMediaList.push({ mediaQuery, cb });
        });

        return () => {
            matchMediaList.forEach((item) => {
                item.mediaQuery.removeListener(item.cb);
            });
        };
    }, [handleMediaQuery]);

    return typeScreen;
}
