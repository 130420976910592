const LOCAL_ACCESS_NAME = "accessToken";

function setTokens(access: string) {
    if (!__SERVER__) {
        localStorage.setItem(LOCAL_ACCESS_NAME, access);
    }
}

function cleanTokensData() {
    if (!__SERVER__) {
        localStorage.removeItem(LOCAL_ACCESS_NAME);
    }
}

function getAccessToken() {
    if (!__SERVER__) {
        return localStorage.getItem(LOCAL_ACCESS_NAME);
    }
}

const isAccessTokenExpired = (accessTokenExpDate: number) => {
    const nowTime = Math.floor(new Date().getTime() / 1000);

    return accessTokenExpDate - 10 <= nowTime;
};

function getJWTPayload(token: string) {
    return parseJWT(token).payload;
}

function isValidToken(token: string) {
    return !!token && !!parseJWT(token).header && !!parseJWT(token).payload;
}

function parseJWT(token: string) {
    const parts = token.split(".");

    return {
        header: parsePart(parts[0]),
        payload: parsePart(parts[1]),
        sign: parts[2],
    };
}

function parsePart(str: string) {
    try {
        return JSON.parse(window.atob(str)) as Record<string, unknown>;
    } catch (e) {
        return null;
    }
}

export { setTokens, cleanTokensData, getAccessToken, isAccessTokenExpired, getJWTPayload, isValidToken };
