import styled from "styled-components";
import { Skeleton } from "~atoms/skeleton";
import { TextAdaptive } from "~atoms/text-adaptive";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { TProductType } from "~types/products";

const LotteryDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 340px;
    ${adaptive.maxWidth.tablet} {
        max-width: unset;
    }
`;

const LotteryContent = styled.div<{ $borderColor: string; $type: TProductType }>`
    display: flex;
    flex-direction: ${({ $type }) => ($type === "DIGITAL" ? "column" : "column-reverse")};
    // padding-left: ${INDENT.xs};
    // border-left: 3px solid ${({ $borderColor }) => $borderColor};
    // ${adaptive.maxWidth.tablet} {
    //     padding-left: ${INDENT.xxxs};
    //     border-left: 2px solid ${({ $borderColor }) => $borderColor};
    // }
`;

const NextEdition = styled(TextAdaptive)`
    padding: ${INDENT.xxxs} ${INDENT.xs};
    background: ${({ theme }) => theme.color.const.owrlDarken["300"]};
    border-radius: 4px;
    width: fit-content;
    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xxxxs} ${INDENT.xxxs};
    }
`;

const SkeletonEdition = styled(Skeleton)`
    border-radius: 4px;
    width: 306px;
    height: 40px;
    ${adaptive.maxWidth.tablet} {
        width: 240px;
        height: 28px;
    }
`;

const SkeletonJackpot = styled(Skeleton)`
    border-radius: 4px;
    height: 38px;
    ${adaptive.maxWidth.tablet} {
        height: 32px;
    }
`;

export { SkeletonEdition, NextEdition, LotteryContent, LotteryDataWrapper, SkeletonJackpot };
