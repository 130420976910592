import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { CardListWrapper, CardsWrapper, TitleWrapper } from "~molecules/card-list/theme";

type TCardListProps = {
    className?: string;
    title: string;
    isDrag?: boolean;
    direction?: "vertical" | "horizontal";
    renderCards: (props: {
        direction: "vertical" | "horizontal";
        handleDown: (event: React.MouseEvent<HTMLDivElement>) => void;
        handleMove: (event: React.MouseEvent<HTMLDivElement>) => void;
        handleUp: (event: React.MouseEvent<HTMLDivElement>) => void;
    }) => JSX.Element;
};

const blockTransitionLink = (container: HTMLDivElement) => {
    container.querySelectorAll("a").forEach((link) => (link.style.pointerEvents = "none"));
    return () => container.querySelectorAll("a").forEach((link) => (link.style.pointerEvents = "auto"));
};

const CardList = ({ renderCards, className, direction = "vertical", title, isDrag = false }: TCardListProps) => {
    const container = useRef<HTMLDivElement>(null);
    const pos = useRef({ top: 0, left: 0, x: 0, y: 0 });
    const unblockLink = useRef<() => void>();
    const [isDown, setDown] = useState<boolean | null>(null);
    const { color } = useContext(ThemeContext);

    const handleMove = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            if (!isDrag) return;
            if (!isDown) return;
            unblockLink.current = blockTransitionLink(container.current!);
            const dx = event.clientX - pos.current.x;
            const dy = event.clientY - pos.current.y;

            container.current!.scrollTop = pos.current.top - dy;
            container.current!.scrollLeft = pos.current.left - dx;
        },
        [isDrag, isDown, pos],
    );

    const handleUp = useCallback(() => {
        if (!isDrag) return;
        if (unblockLink.current) {
            setTimeout(unblockLink.current);
        }
        setDown(false);
        container.current!.classList.remove("dragged");
    }, [isDrag]);

    const handleDown = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            if (!isDrag) return;
            setDown(true);
            container.current!.classList.add("dragged");
            pos.current = {
                left: container.current!.scrollLeft,
                top: container.current!.scrollTop,
                x: event.clientX,
                y: event.clientY,
            };
        },
        [isDrag],
    );

    useEffect(() => {
        if (isDown === true) {
            document.addEventListener("mouseup", handleUp as any);
            document.addEventListener("mousedown", handleDown as any);
        }

        if (isDown === false) {
            document.removeEventListener("mouseup", handleUp as any);
            document.removeEventListener("mousedown", handleDown as any);
        }
    }, [isDown, handleDown, handleUp]);

    return (
        <CardListWrapper className={className} $direction={direction}>
            {direction === "vertical" ? (
                <TitleWrapper className={"mb16"}>
                    <TitleAdaptive size="title24" tag="h3" weight="medium" color={color.text.headline}>
                        {title}
                    </TitleAdaptive>
                </TitleWrapper>
            ) : (
                <TitleAdaptive size="title38" tag="h3" weight="medium" className={"mb24"} color={color.text.headline}>
                    {title}
                </TitleAdaptive>
            )}
            <CardsWrapper
                onMouseMove={handleMove}
                onMouseDown={handleDown}
                onMouseUp={handleUp}
                $direction={direction}
                ref={container}
                $isDrag={isDrag}
            >
                {renderCards({ direction, handleDown, handleMove, handleUp })}
            </CardsWrapper>
        </CardListWrapper>
    );
};

export default CardList;
