import { useCallback } from "react";
import { isDateExpired } from "~helpers/format";
import { TEdition, TTicketTypes } from "~store/tickets/types";
import { TDigitalAlias } from "~types/products";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

type TChangesArray = {
    path: string;
    value: string | number | undefined | boolean | TEdition[] | TEdition;
};

export const useGetDraw = (alias: TDigitalAlias, ticketType: TTicketTypes) => {
    const { handleFieldManyChange } = useMutationHandles("tickets");
    const { products } = useAppApi();
    const store = useAppStore();
    const { add } = useNotifications();
    const getDraw = useCallback(
        async (idx: number) => {
            const { tickets } = store.getState();
            const changesArray: TChangesArray[] = [];
            const { result: fetchedProduct, error: errorFetchedProduct } = await products.getProductByAlias(alias);
            if (errorFetchedProduct) {
                add({ message: errorFetchedProduct?.data?.message ?? otherErrors.getProduct });
                return null;
            }

            let productId = tickets[ticketType].productId;

            const editionsSelected =
                tickets[ticketType].data[idx]?.editionsSelected.filter((item) => !isDateExpired(item.saleDate)) || [];

            if (!productId) {
                productId = fetchedProduct && fetchedProduct.productId;
                if (!productId) return null;
            }
            changesArray.push(
                { path: `${ticketType}.productId`, value: productId },
                {
                    path: `${ticketType}.jackpot`,
                    value: fetchedProduct?.draw?.jackpot,
                },
                {
                    path: `${ticketType}.data.${idx}.amount`,
                    value: fetchedProduct!.rounds.find((round) => round.roundNum === 1)?.price,
                },
                {
                    path: `${ticketType}.logoSrc`,
                    value: fetchedProduct!.pictures.find((pic) => pic.type === "ICON_BIG")?.url || "",
                },
                {
                    path: `${ticketType}.data.${idx}.init`,
                    value: true,
                },
            );

            const { result, error } = await products.getDraws(productId);
            if (error) {
                add({ message: error?.data?.message ?? otherErrors.getDraw });
                return null;
            }
            if (result.length) {
                changesArray.push({ path: `${ticketType}.data.${idx}.editions`, value: result });
                editionsSelected.length
                    ? changesArray.push({ path: `${ticketType}.data.${idx}.editionsSelected`, value: editionsSelected })
                    : changesArray.push({
                          path: `${ticketType}.data.${idx}.editionsSelected`,
                          value: [result.find((item) => !isDateExpired(item.saleDate))!],
                      });
            }

            handleFieldManyChange(changesArray);
        },
        [ticketType, store, products, alias, handleFieldManyChange, add],
    );

    return { getDraw };
};
