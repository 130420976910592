import step1 from "./assets/rules/1.png";
import step2 from "./assets/rules/2.png";
import step3 from "./assets/rules/3.png";

export const rulesList = [
    {
        title: "Шаг 1.",
        desc: "Удалите защитный слой с игрового поля «Стирать здесь».",
        note: "Билет считается недействительным при повреждении поля с надписью «НЕ СТИРАТЬ».",
        img: step1,
    },
    {
        title: "Шаг 2.",
        desc: "Видите три одинаковых символа в одной строке? Вы выиграли! Ваш выигрыш указан в конце строки.",
        note: "",
        img: step2,
    },
    {
        title: "Шаг 3.",
        desc: "Если в обеих строках по три одинаковых символа, Ваш выигрыш суммируется.",
        note: "",
        img: step3,
    },
];
