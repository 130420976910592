import { useEffect } from "react";
import { useGetDraw } from "~pages/lottery/bingo-lottery/_hooks/useGetDraw";
import { TBingoTypes } from "~store/tickets/types";
import { TBingoAlias } from "~types/products";
import { useAppSelector } from "~hooks/useAppSelector";

export const useGetLotteryDataBingo = (alias: TBingoAlias, ticketType: TBingoTypes, autonomous?: boolean) => {
    const { getDraw } = useGetDraw(alias, ticketType);
    const nextEdition = useAppSelector((state) => state.tickets[ticketType].editions[0]?.drawDate);
    const jackpot = useAppSelector((state) => state.tickets[ticketType].jackpot);
    const isJackpot = jackpot !== null;

    useEffect(() => {
        if (autonomous) {
            void getDraw();
        }
    }, [autonomous, getDraw]);

    return { nextEdition, jackpot, isJackpot };
};
