import { adapters } from "~adapters";
import { TBasket } from "~types/basket";

const result: TBasket = [
    {
        alias: "digital-4x20",
        name: "Мечталлион",
        productId: "0001",
        rounds: [{ roundNum: 1, price: 100 }],
        expired: false,
        combination: [
            [7, 6, 7, 7, 8],
            [1, 2],
        ],
        type: "DIGITAL",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001156",
            jackpot: 0,
            productId: "0001",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-4x20",
        name: "Мечталлион",
        productId: "0001",
        rounds: [{ roundNum: 1, price: 100 }],
        expired: false,
        combination: [
            [7, 6, 7, 7, 8],
            [1, 2],
        ],
        type: "DIGITAL",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001156",
            jackpot: 0,
            productId: "0001",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-4x20",
        name: "Мечталлион",
        productId: "0001",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "DIGITAL",
        combination: [[7, 6, 7, 7, 8, 1, 2, 3], [1]],
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001156",
            jackpot: 0,
            productId: "0001",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-4x20",
        name: "Мечталлион",
        productId: "0001",
        rounds: [{ roundNum: 1, price: 100 }],
        expired: false,
        combination: [
            [7, 6, 7, 7, 8],
            [1, 2],
        ],
        type: "DIGITAL",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001160",
            jackpot: 0,
            productId: "0001",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-4x20",
        name: "Мечталлион",
        productId: "0001",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "DIGITAL",
        combination: [[7, 6, 7, 7, 8, 1, 2, 3], [1]],
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001160",
            jackpot: 0,
            productId: "0001",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-4x20",
        name: "Мечталлион",
        productId: "0001",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "DIGITAL",
        combination: [[7, 6, 7, 7, 8, 1, 2, 3], [1]],
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001160",
            jackpot: 0,
            productId: "0001",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-4x20",
        name: "Мечталлион",
        productId: "0001",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "DIGITAL",
        combination: [[7, 6, 7, 7, 8, 1, 2, 3], [1]],
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001160",
            jackpot: 0,
            productId: "0001",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-5x36",
        name: "Суперплата",
        productId: "0002",
        expired: false,
        rounds: [{ roundNum: 1, price: 100 }],
        combination: [
            [7, 6, 7, 7, 8],
            [1, 2],
        ],
        type: "DIGITAL",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001156",
            jackpot: 0,
            productId: "0002",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-5x36",
        name: "Суперплата",
        productId: "0002",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "DIGITAL",
        combination: [[7, 6, 7, 7, 8, 1, 2, 3], [1]],
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001156",
            jackpot: 0,
            productId: "0002",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-5x50",
        name: "Суперплата",
        productId: "0003",
        expired: false,
        rounds: [{ roundNum: 1, price: 100 }],
        combination: [
            [7, 6, 7, 7, 8],
            [1, 2],
        ],
        type: "DIGITAL",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001156",
            jackpot: 0,
            productId: "0003",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-5x50",
        name: "Суперплата",
        productId: "0003",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "DIGITAL",
        combination: [[7, 6, 7, 7, 8, 1, 2, 3], [1]],
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001156",
            jackpot: 0,
            productId: "0003",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-8x20",
        name: "Мечталлион",
        productId: "0004",
        expired: false,
        rounds: [{ roundNum: 1, price: 100 }],
        combination: [
            [7, 6, 7, 7, 8],
            [1, 2],
        ],
        type: "DIGITAL",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001156",
            jackpot: 0,
            productId: "0004",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-8x20",
        name: "Мечталлион",
        productId: "0004",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "DIGITAL",
        combination: [[7, 6, 7, 7, 8, 1, 2, 3], [1]],
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001156",
            jackpot: 0,
            productId: "0004",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-8x20",
        name: "Мечталлион",
        productId: "0004",
        expired: true,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "DIGITAL",
        combination: [[7, 6, 7, 7, 8, 1, 2, 3], [1]],
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001150",
            jackpot: 0,
            productId: "0004",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-4x20",
        name: "Мечталлион",
        productId: "0001",
        rounds: [{ roundNum: 1, price: 100 }],
        expired: true,
        combination: [
            [7, 6, 7, 7, 8],
            [1, 2],
        ],
        type: "DIGITAL",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001151",
            jackpot: 0,
            productId: "0004",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "digital-4x20",
        name: "Мечталлион",
        productId: "0001",
        expired: true,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "DIGITAL",
        combination: [[7, 6, 7, 7, 8, 1, 2, 3], [1]],
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001151",
            jackpot: 0,
            productId: "0004",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "bingo-90",
        name: "Бинго-90",
        productId: "0005",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "BINGO",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001171",
            jackpot: 0,
            productId: "0005",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "bingo-90",
        name: "Бинго-90",
        productId: "0005",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "BINGO",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001171",
            jackpot: 0,
            productId: "0005",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "bingo-3x3",
        name: "Бинго-3x3",
        productId: "0006",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "BINGO",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001181",
            jackpot: 0,
            productId: "0006",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
    {
        alias: "bingo-3x3",
        name: "Бинго-3x3",
        productId: "0006",
        expired: false,
        rounds: [{ roundNum: 1, price: 300 }],
        type: "BINGO",
        draw: {
            drawDate: "2021-11-16T15:34:26.159",
            drawNum: "001181",
            jackpot: 0,
            productId: "0006",
            saleDate: "2021-11-16T15:34:26.159",
        },
    },
];

export const createBasketApi = () => ({
    async getBasketData() {
        return { error: false, result: adapters.basket.getDataFromRes(result) };
    },
});
