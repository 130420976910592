import { AxiosInstance } from "axios";
import { adapters } from "~adapters";
import { ApiResponse } from "~services/http/types";
import { TBingoOrderData, TTicket } from "~store/tickets/types";
import {
    TOrderPaymentStatus,
    TOrderReqDataBingo,
    TOrderReqDataDigital,
    TOrderResData,
    TPaymentType,
} from "~types/order";

const enum URLs {
    URL_ORDERS = "/orders",
}

export const createOrderApi = (http: AxiosInstance) => ({
    async createDigital(productId: string, tickets: TTicket[], email: string, paymentType: TPaymentType) {
        const data: TOrderReqDataDigital = adapters.order.toCreateApiDigital(productId, tickets, email, paymentType);
        const {
            data: { error, result },
        } = await http.post<ApiResponse<TOrderResData>>(URLs.URL_ORDERS, data);
        return { error, result };
    },
    async createBingo(productId: string, ticketsData: TBingoOrderData, email: string, paymentType: TPaymentType) {
        const data: TOrderReqDataBingo = adapters.order.toCreateApiBingo(productId, ticketsData, email, paymentType);
        const {
            data: { error, result },
        } = await http.post<ApiResponse<TOrderResData>>(URLs.URL_ORDERS, data);
        return { error, result };
    },
    async status(paymentId: string) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TOrderPaymentStatus>>(URLs.URL_ORDERS + `/payments/${paymentId}/status`);
        return { error, result };
    },
});
