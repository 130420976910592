import { useCallback } from "react";
import { TBingoTypes } from "~store/tickets/types";
import { TBingoAlias } from "~types/products";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

type TUseGetBingoTickets = {
    alias: TBingoAlias;
    ticketType: TBingoTypes;
    size: number;
    includeMyNumbers?: boolean;
};

export const useGetBingoTickets = ({ alias, ticketType, size, includeMyNumbers = false }: TUseGetBingoTickets) => {
    const { handleFieldChange } = useMutationHandles("tickets");
    const { products } = useAppApi();
    const store = useAppStore();
    const { add } = useNotifications();
    const handleRefreshBingoTickets = useCallback(() => {
        handleFieldChange(`${ticketType}.data`, []);
    }, [handleFieldChange, ticketType]);

    const handleGetBingoTickets = useCallback(async () => {
        const { tickets } = store.getState();
        let productId = tickets[ticketType].productId;

        if (!productId) {
            const { result: fetchedProduct, error: errorFetchedProduct } = await products.getProductByAlias(alias);
            if (errorFetchedProduct) {
                add({ message: errorFetchedProduct?.data?.message ?? otherErrors.getProduct });
                return null;
            }
            productId = fetchedProduct && fetchedProduct.productId;
            if (!productId) return null;
        }

        const numbersSelected = includeMyNumbers ? tickets[ticketType].numbersSelected : [];
        const ticketsSelected = tickets[ticketType].ticketsSelected;

        const { result, error } = await products.getBingoTickets(
            alias,
            productId,
            size,
            ticketsSelected,
            numbersSelected,
        );

        if (error) {
            add({ message: error?.data?.message ?? otherErrors.getProducts });
            return null;
        }

        if (result?.length) {
            handleFieldChange(`${ticketType}.data`, result);
        }
    }, [add, alias, handleFieldChange, includeMyNumbers, products, size, store, ticketType]);
    return { handleGetBingoTickets, handleRefreshBingoTickets };
};
