import styled from "styled-components";
import { TabGroup } from "~molecules/tab-group";
import { BuyTicketsBarBingo } from "~organisms/buy-tickets-card/buy-tickets-bar";
import { adaptive } from "~tokens/screen";
import { shadowBottomBar } from "~tokens/shadows";
import { INDENT } from "~tokens/sizes";

export const LogoWrapper = styled.div``;

export const TabGroupStyled = styled(TabGroup)`
    max-width: 600px;
    margin-bottom: ${INDENT.m};
`;

export const TicketsAside = styled.div`
    display: flex;
    flex-direction: column;
    @supports (position: sticky) {
        & {
            position: sticky;
            top: calc(80px + ${INDENT.s});
        }
    }

    ${adaptive.maxWidth.desktopXL} {
        position: static;
    }
`;

export const BingoContent = styled.div``;

export const BuyTicketsCardWrapper = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? "block" : "none")};
`;

export const BuyTicketsBarStyled = styled(BuyTicketsBarBingo)<{ show: boolean }>`
    display: none;

    ${adaptive.maxWidth.desktopXL} {
        ${shadowBottomBar};
        display: ${({ show }) => (show ? "block" : "none")};
    }

    ${adaptive.maxWidth.tablet} {
        box-shadow: none;
    }
`;

export const HeaderContentWrapper = styled.div`
    display: flex;
    max-width: 1050px;
    justify-content: space-between;
    align-items: center;

    ${adaptive.maxWidth.desktopXXL} {
        max-width: 808px;
    }

    ${adaptive.maxWidth.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const HeaderBlock = styled.div``;
