import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { ComboCircleWrapper, Text } from "./theme";

export type TVariantComboCircle = 1 | 2 | 3 | 4;

type TComboCircleProps = {
    className?: string;
    variant?: TVariantComboCircle;
    children: React.ReactNode;
};

const ComboCircle = ({ children, className, variant = 1 }: TComboCircleProps) => {
    const { color } = useContext(ThemeContext);
    const colorVariant = {
        1: color.text.inverted,
        2: color.text.tertiary,
        3: color.text.positive,
        4: color.text.primary,
    }[variant];

    return (
        <ComboCircleWrapper className={className} variant={variant}>
            <Text color={colorVariant} size="text16" tag="div">
                {children}
            </Text>
        </ComboCircleWrapper>
    );
};

export default ComboCircle;
