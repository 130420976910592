import styled from "styled-components";
import { INDENT } from "~assets/styles/tokens/sizes";
import { TextAdaptive } from "~atoms/text-adaptive";
import { Status } from "~molecules/status";
import { DigitalCombinations } from "~organisms/digital-combinations";
import { adaptive } from "~tokens/screen";
import { TProductType } from "~types/products";
import { TICKET_GREY, WHITE } from "./../../../../assets/styles/tokens/colors";
import { TIsContent } from "../my-ticket";

const Wrapper = styled.div`
    height: 100%;
    background: ${WHITE};
    padding: ${INDENT.none};
    display: flex;
    flex-direction: column;

    ${adaptive.minWidth.tablet} {
        flex-direction: row;
    }

    ${adaptive.minWidth.desktopXXL} {
        padding: ${INDENT.sm};
        justify-content: space-between;
        align-items: center;
    }
`;

const InfoLeftBlock = styled.div<{ $type: TProductType; $isContent: TIsContent }>`
    min-height: ${({ $type }) => ($type === "BINGO" ? "68px" : "146px")};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${INDENT.xxs};

    ${adaptive.minWidth.tablet} {
        min-height: 100%;
        width: 60%;
        padding: ${INDENT.sm} ${INDENT.xs};
    }

    ${adaptive.minWidth.desktopXL} {
        width: 60%;
        margin: ${INDENT.none} ${INDENT.xs};
        padding: ${INDENT.none};
    }

    ${adaptive.minWidth.desktopXXL} {
        width: ${({ $isContent }) => ($isContent === "win" ? "65%" : "55%")};
        margin: ${INDENT.none};
    }
`;

const InfoRightBlock = styled.div<{ $isContent: TIsContent; $notLink: boolean }>`
    min-height: 74px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px dashed ${TICKET_GREY};

    ${adaptive.minWidth.tablet} {
        min-height: 100%;
        width: 40%;
        padding: ${INDENT.none} 10px;
        border-left: 1px dashed ${TICKET_GREY};
        border-top: none;
    }

    ${adaptive.minWidth.desktopXL} {
        /* justify-content: ${({ $isContent }) => ($isContent === "notWin" ? "center" : "flex-start")}; */
        justify-content: flex-start;
        padding: ${INDENT.none};
        padding-left: ${({ $isContent }) => ($isContent !== "win" ? INDENT.m : INDENT.none)};
        padding-right: ${INDENT.xs};
        border: 0;
    }

    ${adaptive.minWidth.desktopXXL} {
        width: ${({ $isContent }) => ($isContent === "win" ? `calc(36% - 24px)` : `calc(45% - 24px)`)};
        padding: ${INDENT.none};
        padding-left: ${({ $isContent, $notLink }) => $isContent !== "win" && !$notLink && INDENT.ls};
    }
`;

const TicketNumber = styled(TextAdaptive)<{ $type: TProductType }>`
    text-align: ${({ $type }) => ($type === "BINGO" ? "center" : "initial")};

    ${adaptive.minWidth.desktopXL} {
        text-align: initial;
    }
`;

const DigitalCombinationsStyled = styled(DigitalCombinations)`
    margin-top: ${INDENT.sm};

    ${adaptive.minWidth.desktopXL} {
        margin-top: ${INDENT.xs};
    }
`;

const StatusText = styled(TextAdaptive)`
    width: 100%;
    text-align: center;

    ${adaptive.minWidth.tablet} {
        text-align: initial;
    }

    ${adaptive.minWidth.desktopXL} {
        width: 186px;
    }
`;

const StatusFuture = styled(Status)`
    text-align: center;

    ${adaptive.minWidth.desktopXL} {
        text-align: initial;
    }
`;

const StatusWrap = styled.div`
    display: flex;
    flex-direction: column;

    .ticket-next-edition {
        margin-top: ${INDENT.xxxxs};
        margin-left: ${INDENT.auto};

        ${adaptive.minWidth.tablet} {
            margin-left: ${INDENT.xxs};
        }

        ${adaptive.minWidth.desktopXL} {
            margin-left: ${INDENT.none};
        }

        ${adaptive.minWidth.desktopXXL} {
            margin-left: ${INDENT.m};
        }
    }
`;

export {
    Wrapper,
    StatusWrap,
    StatusFuture,
    StatusText,
    InfoLeftBlock,
    InfoRightBlock,
    DigitalCombinationsStyled,
    TicketNumber,
};
