import React from "react";
import { GiftIcon } from "~atoms/icons";
import {
    ButtonStyled,
    ContentWrapper,
    GiftTicketBarWrapper,
    IconWrapper,
    TextWrapper,
    TitleStyled,
    TextStyled,
} from "~organisms/gift-ticket-bar/theme";
import { ADD_TICKETS } from "~constants/routes";
import useAppHistory from "~hooks/useAppHistory";

type TGiftTicketBarProps = {
    className?: string;
    mobile?: boolean;
};

const GiftTicketBar = ({ className, mobile }: TGiftTicketBarProps) => {
    const { go } = useAppHistory();

    const onClick = () => {
        go(ADD_TICKETS);
    };
    return (
        <GiftTicketBarWrapper className={className} mobile={mobile}>
            <ContentWrapper mobile={mobile}>
                <IconWrapper mobile={mobile} className="gift-icon">
                    <GiftIcon />
                </IconWrapper>
                <TextWrapper>
                    <TitleStyled tag="h3" size={2} mobile={mobile}>
                        Билет в подарок!
                    </TitleStyled>
                    <TextStyled size={3}>
                        Зарегистрируйте 4 билета моментальной лотереи и получите 5-й билет в подарок!
                    </TextStyled>
                </TextWrapper>
            </ContentWrapper>
            <ButtonStyled size="large" mobile={mobile} onClick={onClick} role="link">
                Добавить билеты
            </ButtonStyled>
        </GiftTicketBarWrapper>
    );
};

export default GiftTicketBar;
