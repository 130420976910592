import styled from "styled-components";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import backgroundDesktop from "./assets/PlaceholderDesktop.png";
import backgroundMobile from "./assets/PlaceholderMobile.png";
import backgroundTablet from "./assets/PlaceholderTablet.png";

export const OurWinnersAreaWrapper = styled.div`
    display: block;
`;

export const HistoryBlockWrapper = styled.div``;

export const ContentWrapper = styled.div`
    ${adaptive.maxWidth.tablet} {
        & .history-block-grid {
            overflow: auto;
            padding-bottom: ${INDENT.xxs};
            & > div {
                min-width: 604px;
            }
        }
    }
`;

export const HistoryImageWrapper = styled.div``;

export const BigWinnersWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${INDENT.ls};
    margin-top: ${INDENT.xs};
`;

export const HistoryCardPlaceholder = styled.div`
    height: 185px;
    width: 100%;
    background-image: url(${backgroundDesktop});
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    overflow: hidden;
    ${adaptive.maxWidth.desktopXL} {
        height: 225px;
        background-image: url(${backgroundTablet});
        background-repeat: no-repeat;
        background-size: cover;
    }
    ${adaptive.maxWidth.tablet} {
        height: 188px;
        background-image: url(${backgroundMobile});
        background-repeat: no-repeat;
    }
`;
