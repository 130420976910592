import styled from "styled-components";
import { Skeleton } from "~atoms/skeleton";
import { FilesBlock } from "~organisms/files-block";
import { adaptive } from "~tokens/screen";

const FilesBlockStyled = styled(FilesBlock)`
    ${adaptive.maxWidth.tablet} {
        & .prefix-wrap svg {
            width: 44px;
        }
    }
`;

const Wrapper = styled.div``;

const LogoSkeletonStyled = styled(Skeleton)`
    height: 44px;
    width: 200px;
    border-radius: 4px;

    ${adaptive.maxWidth.tablet} {
        height: 32px;
        width: 150px;
    }
`;

export { FilesBlockStyled, Wrapper, LogoSkeletonStyled };
