import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { ArrowIcon } from "~atoms/icons";
import { getText } from "../helpers";
import {
    InProgressCount,
    CollapseTitleWrap,
    TextWrap,
    TextCount,
    TextCollapse,
    ArrowIconWrap,
    TitleCollapseText,
} from "../theme";

type TCollapseTitle = {
    count: number;
};

const CollapseTitle = ({ count }: TCollapseTitle) => {
    const pending = true;
    const { text, title } = getText(pending);
    const { color } = useContext(ThemeContext);
    return (
        <CollapseTitleWrap>
            <TextWrap>
                <TitleCollapseText weight="medium" className="mb12 mb8--mobile" tag="div" size="title24">
                    {title}
                    <InProgressCount className="ml16">
                        <TextCount tag="div" size="text14">
                            {count}
                        </TextCount>
                    </InProgressCount>
                </TitleCollapseText>
                <TextCollapse tag="div" size="text14" color={color.text.headline}>
                    {text}
                </TextCollapse>
            </TextWrap>
            <ArrowIconWrap>
                <ArrowIcon className="collapse-arrow" />
            </ArrowIconWrap>
        </CollapseTitleWrap>
    );
};

export default CollapseTitle;
