import React, { ReactNode } from "react";
import styled from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { GREY_DARK } from "~tokens/colors";
import { TableStyled } from "./theme";

type TTableProps = {
    className?: string;
    col: { id: string; title: string; width?: string; getValue: (value: unknown) => ReactNode }[];
    data: Record<string, unknown>[];
};

const TableWrapper = styled.div`
    display: block;
`;

const Table = ({ className, col, data }: TTableProps) => {
    return (
        <TableWrapper className={className}>
            <TableStyled>
                <thead>
                    <tr>
                        {col.map(({ id, title, width = "auto" }) => (
                            <th key={id} style={{ width }}>
                                <TextAdaptive color={GREY_DARK} size={"text16"}>
                                    {title}
                                </TextAdaptive>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, idx) => (
                        <tr key={idx}>
                            {col.map(({ id, getValue }) => (
                                <td key={id}>{getValue(item[id])}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </TableStyled>
        </TableWrapper>
    );
};

export default Table;
