import styled from "styled-components";
import { isTouchCapable } from "~helpers/utils";

const TabGroupWrapper = styled.ul`
    display: flex;
    overflow-x: auto;
    ${() => isTouchCapable() && "::-webkit-scrollbar {display: none;}"};
`;

export { TabGroupWrapper };
