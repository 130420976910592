import { useCallback } from "react";
import { emptyTicket } from "~store/tickets/reducer";
import { TTicket, TTicketTypes } from "~store/tickets/types";
import { TDigitalAlias } from "~types/products";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

export const useInitTickets = (alias: TDigitalAlias, ticketType: TTicketTypes) => {
    const { handleFieldManyChange } = useMutationHandles("tickets");
    const { products } = useAppApi();
    const { add } = useNotifications();

    const initTickets = useCallback(
        async (cntTickets = 1) => {
            const startDataObj: TTicket = { ...emptyTicket, init: true };
            const { result: fetchedProduct, error: errorFetchedProduct } = await products.getProductByAlias(alias);
            if (errorFetchedProduct || fetchedProduct === null) {
                add({ message: errorFetchedProduct?.data?.message ?? otherErrors.getProduct });
                return null;
            }

            const productId = fetchedProduct.productId;
            const jackpot = fetchedProduct.draw?.jackpot;
            const logoSrc = fetchedProduct.pictures.find((pic) => pic.type === "ICON_BIG")?.url || "";
            const name = fetchedProduct.name;

            startDataObj.amount = fetchedProduct.rounds.find((round) => round.roundNum === 1)?.price || null;

            const { result, error } = await products.getDraws(fetchedProduct.productId);
            if (error) {
                add({ message: error?.data?.message ?? otherErrors.getDraw });
                return null;
            }

            startDataObj.editions = result;
            startDataObj.editionsSelected = result[0] ? [result[0]] : [];

            handleFieldManyChange([
                { path: `${ticketType}.productId`, value: productId },
                {
                    path: `${ticketType}.jackpot`,
                    value: jackpot,
                },
                {
                    path: `${ticketType}.name`,
                    value: name,
                },
                {
                    path: `${ticketType}.logoSrc`,
                    value: logoSrc,
                },
                {
                    path: `${ticketType}.initTicket`,
                    value: true,
                },

                ...Array(cntTickets)
                    .fill(-1)
                    .map((_, idx) => ({
                        path: `${ticketType}.data.${idx}`,
                        value: startDataObj,
                    })),
            ]);
        },
        [ticketType, products, alias, handleFieldManyChange, add],
    );

    return { initTickets };
};
