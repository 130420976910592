import React from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { LotteryRulesHeader } from "~organisms/lottery-rules-header";
import { LegalInfo } from "~pages/lottery/common/legal-info";
import { GREY_DARK } from "~tokens/colors";
import { legalText } from "~constants/handbook";
import { ticketsDigitalType } from "~constants/tickets-type";
import { useAppSelector } from "~hooks/useAppSelector";
import { TableRules4in20 } from "./table-rules-4in20";
import { ContentWrapper, RulesWrapper } from "./theme";

type TRulesProps = {
    isActive: boolean;
};

const superPriceLottery = {
    name: "Суперплата (4 из 20)",
    type: "Числовая",
    prizeFund: "60%",
    mainPrize: "500 000 ₽",
    frequency: "3 раза в неделю",
};

const Rules = ({ isActive }: TRulesProps) => {
    // const jackpot = useAppSelector((state) => state.tickets[ticketsDigitalType.t4in20].jackpot);
    const amount = useAppSelector((state) => state.tickets[ticketsDigitalType.t4in20].data[0]?.amount);

    return (
        <RulesWrapper>
            <ContentWrapper>
                {/*{jackpot !== null && amount !== null ? (*/}
                {/*    <BannerRules4in20 jackpot={jackpot} amount={amount} className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*) : (*/}
                {/*    <SkeletonStyled className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*)}*/}

                {isActive && (
                    <LotteryRulesHeader
                        name={superPriceLottery.name}
                        type={superPriceLottery.type}
                        prizeFund={superPriceLottery.prizeFund}
                        ticketPrice={amount}
                        mainPrize={superPriceLottery.mainPrize}
                        frequency={superPriceLottery.frequency}
                    />
                )}
                <TitleAdaptive tag={"div"} size={"title24"} weight={"demiBold"} className={"mb24 mb16--mobile"}>
                    Что можно выиграть?
                </TitleAdaptive>
                <TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>
                    Выиграть можно от 100 ₽ до нескольких сотен миллионов. Минимальный гарантированный суперприз — 50
                    000 000 ₽.
                </TextAdaptive>
                <TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>
                    Всего в «Спортлото «4 из 20» 12 категорий выигрыша. Сумма выигрыша зависит от того, сколько чисел из
                    выпавшей комбинации вы угадали.
                </TextAdaptive>
                <TableRules4in20 className={"mb24 mb16--mobile"} />
                <TextAdaptive size={"text16"} weight={"medium"} color={GREY_DARK} className={"mb24 mb16--mobile"}>
                    *Распределение призового фонда, принятого за 100% после начисления фиксированных выигрышей. Выигрыш
                    по одному билету определяется следующим образом: сумма призового фонда данной категории делится на
                    количество выигрышных билетов в этой категории.
                </TextAdaptive>
                <TextAdaptive size={"text16"} weight={"medium"} color={GREY_DARK} className={"mb24 mb16--mobile"}>
                    **Фиксированный выигрыш на один выигрышный билет.
                </TextAdaptive>
                <TitleAdaptive tag={"div"} size={"title24"} weight={"demiBold"} className={"mb24 mb16--mobile"}>
                    Как играть?
                </TitleAdaptive>
                <TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>
                    Минимальная лотерейная комбинация состоит из 4 неповторяющихся чисел от 1 до 20 включительно в
                    первом игровом поле и 4 неповторяющихся чисел от 1 до 20 включительно во втором игровом поле.
                </TextAdaptive>
                <TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>
                    Билет считается выигрышным, если указанные в нем числа лотерейной комбинации полностью или частично
                    совпали с числами выигрышной комбинации по результатам розыгрыша призового фонда в соответствующем
                    тираже.
                </TextAdaptive>
                <TextAdaptive size={"text16"} weight={"medium"} className={"mb64 mb56--tablet mb32--mobile"}>
                    Выигрышная комбинация определяется путем формирования 4 неповторяющихся чисел от 1 до 20
                    включительно для первого игрового поля и 4 неповторяющихся чисел от 1 до 20 включительно для второго
                    игрового поля.
                </TextAdaptive>
                <LegalInfo
                    type="DIGITAL"
                    prizeInfo={legalText["digital-4x20"].prizeInfo}
                    algoInfo={legalText["digital-4x20"].algoInfo}
                />
            </ContentWrapper>
        </RulesWrapper>
    );
};

export default Rules;
