import styled from "styled-components";
import { Skeleton } from "~atoms/skeleton";
import { adaptive } from "~tokens/screen";

const SkeletonText = styled(Skeleton)`
    width: 140px;
    border-radius: 4px;
    height: 32px;
    ${adaptive.maxWidth.tablet} {
        height: 28px;
    }
`;

export { SkeletonText };
