import React from "react";
import { TVariantComboCircle } from "~atoms/combo-circle/combo-circle";
import { TextAdaptive } from "~atoms/text-adaptive";
import { WHITE } from "~tokens/colors";
import { TMyTicketParsed } from "~store/my-tickets/types";
import { TDigitalAlias } from "~types/products";
import { getArray } from "./helpers";
import { BallsWrapper, Ball, FieldWrapper } from "./theme";

export type TTicketBlockProps = {
    className?: string;
    fieldColor?: string;
    alias?: TDigitalAlias;
} & Pick<TMyTicketParsed, "numbers" | "numbersWin">;

const DigitalCombinations = ({ className, numbers, numbersWin, fieldColor = WHITE, alias }: TTicketBlockProps) => {
    const arr = getArray(numbers, numbersWin);

    return (
        <BallsWrapper className={className}>
            <FieldWrapper className="mb8">
                <TextAdaptive className="mr8" size="text12" color={fieldColor}>
                    Поле 1
                </TextAdaptive>
                {arr[0].map((item, idx) => {
                    return (
                        <Ball variant={item.type as TVariantComboCircle} alias={alias} key={idx}>
                            {item.value}
                        </Ball>
                    );
                })}
            </FieldWrapper>
            {arr[1].length ? (
                <FieldWrapper>
                    <TextAdaptive className="mr8" size="text12" color={fieldColor}>
                        Поле 1
                    </TextAdaptive>
                    {arr[1].map((item, idx) => {
                        return (
                            <Ball variant={item.type as TVariantComboCircle} alias={alias} key={idx}>
                                {item.value}
                            </Ball>
                        );
                    })}
                </FieldWrapper>
            ) : null}
        </BallsWrapper>
    );
};

export default DigitalCombinations;
