import { useMemo } from "react";
import { TBingo, TBingoTypes } from "~store/tickets/types";
import { useAppSelector } from "~hooks/useAppSelector";

export const getSelectedBingoTickets = (tickets: TBingo[], ticketsSelected: string[]) => {
    return tickets.filter((item) => ticketsSelected.includes(item.serialNum));
};
export const useGetBuyTicketsInfoBingo = (ticketType: TBingoTypes) => {
    const tickets = useAppSelector((state) =>
        getSelectedBingoTickets(state.tickets[ticketType].data, state.tickets[ticketType].ticketsSelected),
    );
    const editionsCount = useAppSelector((state) => state.tickets[ticketType].editionsSelected.length);
    const productId = useAppSelector((state) => state.tickets[ticketType].productId);

    const amount = useMemo(() => {
        return tickets.reduce((accum, item): number => {
            return accum + (item.amount ?? 0) * editionsCount;
        }, 0);
    }, [editionsCount, tickets]);

    return { ticketsCount: tickets.length, editionsCount, amount, productId };
};
