import styled from "styled-components";
import { TDirectionType } from "~organisms/lottery-tickets-bar/lottery-tickets-bar";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { isTouchCapable } from "~helpers/utils";

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CardsWrapper = styled.div<{ $direction: TDirectionType; $isDrag: boolean }>`
    ${({ $isDrag }) => (isTouchCapable() || $isDrag) && "::-webkit-scrollbar {display: none;}"};
    display: flex;
    ${({ $direction }) => ($direction === "vertical" ? "flex-wrap: wrap;" : "flex-wrap: nowrap;")};
    ${({ $direction }) => $direction === "horizontal" && "overflow: auto;"};
    ${({ $direction }) => ($direction === "vertical" ? "flex-direction: column;" : "flex-direction: row;")};

    & > div {
        ${({ $direction }) => $direction === "horizontal" && "min-width: 310px;"};
        ${({ $direction }) => $direction === "horizontal" && `margin-right:${INDENT.xs};`};
    }

    user-select: auto;
    //transform: scale(1);

    &.dragged {
        user-select: none;
        //transform: scale(1.02);
        user-drag: none;
    }
`;

export const CardListWrapper = styled.div<{ $direction: TDirectionType }>`
    width: 100%;
    ${({ $direction }) => $direction === "vertical" && `background-color: ${WHITE};`};
    ${({ $direction }) => $direction === "vertical" && "border-radius: 20px;"};
    ${({ $direction }) => $direction === "vertical" && `padding: ${INDENT.m};`};
    ${adaptive.maxWidth.desktopXXL} {
        ${({ $direction }) => $direction === "vertical" && `padding: ${INDENT.sm};`};
    }
`;
