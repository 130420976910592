import React from "react";
import { ArrowLongIcon } from "~atoms/icons";
import { Wrapper, ArrowButton } from "./theme";

const ArrowBlock = () => {
    return (
        <Wrapper>
            <ArrowButton>
                <ArrowLongIcon />
            </ArrowButton>
        </Wrapper>
    );
};

export default ArrowBlock;
