import React, { useCallback, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Checkbox } from "~atoms/checkbox";
import { Radio } from "~atoms/radio";
import { TextAdaptive } from "~atoms/text-adaptive";
import { GREY_DARK, GREY_DARK2 } from "~tokens/colors";
import { INDENT } from "~tokens/sizes";
import { getDateFromISO8601 } from "~helpers/format";

type TSelectItemProps = {
    date: string;
    edition: string;
    onChange: (id: string, isSelected: boolean) => void;
    isSelected: boolean | undefined;
    className?: string;
    typeItem?: "checkbox" | "radio";
    disabled?: boolean;
};

const SelectItemWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`;

const SelectText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: ${INDENT.xxxs};
`;

const CheckboxStyled = styled(Checkbox)`
    & > label:after {
        border-radius: 4px;
        border: 1px solid ${GREY_DARK2};
    }
`;

const RadioStyled = styled(Radio)`
    & > label:after {
        border: 1px solid ${({ theme }) => theme.color.controls.inactive};
    }
`;

const SelectItem = ({
    onChange,
    date,
    edition,
    className,
    isSelected,
    typeItem = "checkbox",
    disabled,
}: TSelectItemProps) => {
    const { color } = useContext(ThemeContext);
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(edition, e.target.checked);
        },
        [onChange, edition],
    );
    const LabelText = (
        <SelectText className="select-text">
            <TextAdaptive tag="span" size="text16" color={color.text.primary}>
                {getDateFromISO8601(date, "DD MMM, в HH:mm")}
            </TextAdaptive>
            <TextAdaptive tag="p" size="text14" color={GREY_DARK}>
                Тираж № {edition}
            </TextAdaptive>
        </SelectText>
    );
    return (
        <SelectItemWrapper className={className}>
            {typeItem === "checkbox" ? (
                <CheckboxStyled
                    id={`select-item-${edition}`}
                    onChange={handleChange}
                    label={LabelText}
                    checked={isSelected}
                    disabled={disabled}
                />
            ) : (
                <RadioStyled
                    id={`select-item-${edition}`}
                    onChange={handleChange}
                    label={LabelText}
                    checked={isSelected}
                    disabled={disabled}
                />
            )}
        </SelectItemWrapper>
    );
};

export default SelectItem;
