import { isUndefined } from "lodash-es";
import { TClientProfileIdentity, TClientStatusIdentityType } from "~types/client";
import { otherErrors, paymentStatus, payoutStatus } from "~constants/error-messages";
import { identityStatus } from "~constants/handbook";

export const paymentAmounts = {
    MIN_AMOUNT: 100,
    MAX_AMOUNT_ANON: 15000,
    MAX_AMOUNT_BASIC_IDENTIFICATION: 60000,
    MAX_AMOUNT_FULL_IDENTIFICATION: 595000,
};

export const payoutAmounts = {
    MIN_AMOUNT: 1000,
    MAX_AMOUNT_FULL_IDENTIFICATION: 595000,
};

export const validatePaymentAmount = (identity?: TClientProfileIdentity) => (amount: string) => {
    if (isUndefined(identity)) return otherErrors.getProfile;

    const paymentAmount = Number(amount);

    if (paymentAmount < paymentAmounts.MIN_AMOUNT) {
        return paymentStatus.WRONG_MIN_AMOUNT.getValue(paymentAmounts.MIN_AMOUNT);
    }

    if (isIdentityAccepted(identity.full) && paymentAmount > paymentAmounts.MAX_AMOUNT_FULL_IDENTIFICATION) {
        return paymentStatus.WRONG_MAX_AMOUNT_FULL_IDENTIFICATION.getValue(
            paymentAmounts.MAX_AMOUNT_FULL_IDENTIFICATION,
        );
    }
};

export const validatePayoutAmount = (balance?: number, identity?: TClientProfileIdentity) => (amount: string) => {
    if (isUndefined(balance) || isUndefined(identity)) return otherErrors.getProfile;

    const payoutAmount = Number(amount);

    if (
        (isIdentityAccepted(identity.basic) || isIdentityAccepted(identity.full)) &&
        payoutAmount < payoutAmounts.MIN_AMOUNT
    ) {
        return payoutStatus.WRONG_MIN_AMOUNT.getValue(payoutAmounts.MIN_AMOUNT);
    }
    if ((isIdentityAccepted(identity.basic) || isIdentityAccepted(identity.full)) && payoutAmount > balance) {
        return payoutStatus.WRONG_MAX_AMOUNT.value;
    }
    if (isIdentityAccepted(identity.full) && payoutAmount > payoutAmounts.MAX_AMOUNT_FULL_IDENTIFICATION) {
        return payoutStatus.WRONG_MAX_AMOUNT_FULL_IDENTIFICATION.getValue(payoutAmounts.MAX_AMOUNT_FULL_IDENTIFICATION);
    }
};

export const isIdentityAccepted = (identity: TClientStatusIdentityType) => {
    return identity === identityStatus.ACCEPTED;
};

export const getAnonymousPaymentErrorCode = (amount: number) => {
    return amount > paymentAmounts.MAX_AMOUNT_BASIC_IDENTIFICATION
        ? paymentStatus.WRONG_MAX_AMOUNT_ANON_OVER_MAX_BASIC.code
        : paymentStatus.WRONG_MAX_AMOUNT_ANON_UNDER_MAX_BASIC.code;
};
