import React from "react";
import { SkeletonStyled } from "~pages/my-tickets/theme";

const Skeletons = () => {
    return (
        <>
            {Array(3)
                .fill(1)
                .map((_, idx) => (
                    <SkeletonStyled key={idx} />
                ))}
        </>
    );
};

export default Skeletons;
