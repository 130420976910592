import styled from "styled-components";
import { BLACK, GREY_DARK, GREY_DARK2, PURPLE, RED, WHITE } from "~assets/styles/tokens/colors";
import { disableStyle, transitionDefault } from "~assets/styles/utils";
import { INDENT } from "~tokens/sizes";
import { fontWeight400, fontWeightNormal, text14, text16 } from "~tokens/typography";
import { TextAdaptive } from "../text-adaptive";

type TInputState = "isError" | "isDisabled" | "isActive" | "isFocused";
type TInputIcons = {
    pref?: boolean;
    suf?: boolean;
};

const getColor = (isActive: boolean, isError: boolean) => {
    if (isActive) return PURPLE;
    if (isError) return RED;

    return GREY_DARK2;
};

const getHoverColor = (isError: boolean) => {
    if (isError) return RED;
    return PURPLE;
};

const getInputPadding = ({ pref, suf }: TInputIcons) => {
    return `${INDENT.xxs} ${suf ? INDENT.l : INDENT.xxs} ${INDENT.xxs} ${pref ? INDENT.l : INDENT.xxs}`;
};

const InputWrapper = styled.div<Record<TInputState, boolean>>`
    position: relative;
    color: ${({ isError }) => (isError ? RED : BLACK)};
    ${({ isDisabled }) => isDisabled && disableStyle};

    input {
        border-color: ${({ isActive, isError }) => getColor(isActive, isError)};

        &:hover {
            border-color: ${({ isError }) => getHoverColor(isError)};
        }

        &:-webkit-autofill {
            -webkit-background-clip: text;
        }
    }
`;

const PrefixWrapper = styled.div`
    position: absolute;
    bottom: 2px;
    left: ${INDENT.xxs};
`;

const SuffixWrapper = styled.div`
    position: absolute;
    bottom: 2px;
    right: ${INDENT.xxs};
`;

const InputStyled = styled.input<TInputIcons>`
    width: 100%;
    height: 40px;
    padding: ${({ suf, pref }) => getInputPadding({ pref, suf })};
    border-radius: 8px;
    border: 1px solid;
    color: inherit;

    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }

    ${text16};
    ${transitionDefault};
    ${fontWeight400};

    &::placeholder {
        color: ${GREY_DARK};
        ${fontWeightNormal};
    }

    &:disabled {
        background: ${WHITE};
        color: ${GREY_DARK};
    }
`;

const LabelStyled = styled.label<{ isError: boolean }>`
    ${text14};
    ${fontWeight400};
    margin-bottom: ${INDENT.xxxxs};
    display: inline-block;
    color: ${({ isError, theme }) => (isError ? RED : theme.color.text.primary)};
`;

const TextStyled = styled(TextAdaptive)`
    position: relative;
    margin-top: ${INDENT.xxxxs};
`;

export { InputStyled, LabelStyled, InputWrapper, PrefixWrapper, SuffixWrapper, TextStyled };
