import { useCallback, useEffect, useState } from "react";

export type Config = {
    endTime: number | null;
    initialTime: number;
    interval: number;
    onTimeOver?: () => void;
    step: number;
};

export type ReturnValue = {
    time: number;
    isRunning: boolean;
    isTimeOver: boolean;
    pause: () => void;
    reset: () => void;
    start: () => void;
    restart: (time?: number) => void;
};

export const useTimer = ({
    initialTime = 0,
    interval = 1000,
    step = 1,
    endTime,
    onTimeOver,
}: Partial<Config> = {}): ReturnValue => {
    const [time, setTime] = useState<number>(initialTime);
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [isTimeOver, setIsTimeOver] = useState<boolean>(false);

    const reset = useCallback(() => {
        setIsRunning(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialTime, setIsRunning]);

    const start = useCallback(() => {
        if (isTimeOver) {
            reset();
        }

        setIsRunning(true);
    }, [reset, isTimeOver]);

    const restart = useCallback(
        (newTime: number = initialTime) => {
            setIsTimeOver(false);
            setTime(newTime);
            setIsRunning(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [initialTime],
    );

    const pause = useCallback(() => {
        setIsRunning(false);
    }, []);

    useEffect(() => {
        if (isRunning && time === endTime) {
            setIsRunning(false);
            setIsTimeOver(true);
            if (typeof onTimeOver === "function") {
                onTimeOver();
            }
        }
    }, [endTime, onTimeOver, time, isRunning]);

    useEffect(() => {
        let intervalId: number | null = null;

        if (isRunning) {
            intervalId = window.setInterval(() => {
                setTime((previousTime) => previousTime + step);
            }, interval);
        } else if (intervalId) {
            clearInterval(intervalId);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isRunning, step, interval]);

    return { time, isTimeOver, isRunning, pause, reset, start, restart };
};
