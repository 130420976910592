import React from "react";
import { Box, Flex } from "~atoms/flex-box";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { MissionWrapper } from "./theme";

type TMissionProps = {
    className?: string;
};

const Mission = ({ className }: TMissionProps) => {
    return (
        <MissionWrapper className={className}>
            <Flex justify={"space-between"} alignItems={"flex-start"} mobile={{ $direction: "column" }}>
                <Box
                    flexBasis={"430px"}
                    tablet={{ flexBasis: "324px" }}
                    mobile={{ flexBasis: "auto" }}
                    className={"mb32--mobile mr24 mr38--tablet mr0--mobile"}
                >
                    <TitleAdaptive tag={"h3"} size={"title24"} className={"mb24 mb16--tablet mb12--mobile"}>
                        Наша миссия
                    </TitleAdaptive>
                    <TextAdaptive size={"text16"} weight="medium">
                        Являться честной лотереей высокого уровня, помогающей людям и стране.
                    </TextAdaptive>
                </Box>
                <Box flexBasis={"320px"} mobile={{ flexBasis: "auto" }}>
                    <TitleAdaptive tag={"h3"} size="title24">
                        Национальная лотерея — волшебный билет к вашей мечте
                    </TitleAdaptive>
                </Box>
            </Flex>
        </MissionWrapper>
    );
};

export default Mission;
