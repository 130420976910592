import { useCallback, useContext } from "react";
import Loader from "~atoms/loader/loader";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { EmailBlock } from "~organisms/buy-tickets-card/email-block";
import { WidgetBlock } from "~organisms/buy-tickets-card/widget-block";
import { useGetBalance } from "~pages/profile/_hooks/useGetBalance";
import { useSendPayment } from "~pages/wallet/_hooks/useSendPayment";
import { getAnonymousPaymentErrorCode, isIdentityAccepted, paymentAmounts } from "~pages/wallet/validations";
import { VerificationBlock } from "~pages/wallet/verification-block";
import { TWalletPaymentType } from "~types/wallet";
import { otherErrors, paymentStatus } from "~constants/error-messages";
import { useAppSelector } from "~hooks/useAppSelector";
import { useNotifications } from "~hooks/useNotifications";

export const usePaymentLogic = () => {
    const { sendPayment } = useSendPayment();
    const { handleGetBalance } = useGetBalance();
    const { add } = useNotifications();
    const initProfile = useAppSelector((state) => state.profile.data !== null);
    const email = useAppSelector((state) => state.profile.data?.email);
    const { onClose, setContent } = useContext(ModalWindowContext);
    const identity = useAppSelector((state) => state.profile.data?.identity);

    const handleEmailChangeSuccess = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleWidgetSuccess = useCallback(() => {
        void handleGetBalance();
    }, [handleGetBalance]);

    const handlePayment = useCallback(
        async (amount: string, balance: number, paymentType: TWalletPaymentType) => {
            setContent({
                component: Loader,
            });
            if (!initProfile || !identity) return add({ message: otherErrors.getProfile });

            if (!email) {
                return setContent({
                    component: EmailBlock,
                    props: { email, handleSuccess: handleEmailChangeSuccess },
                    options: { btnClose: true, onClickClose: onClose },
                });
            }

            const paymentAmount = Number(amount);

            const totalAmount = paymentAmount + balance;

            if (
                !isIdentityAccepted(identity?.basic) &&
                !isIdentityAccepted(identity?.full) &&
                totalAmount > paymentAmounts.MAX_AMOUNT_ANON
            ) {
                return setContent({
                    component: VerificationBlock,
                    props: {
                        code: getAnonymousPaymentErrorCode(totalAmount),
                    },
                    options: { btnClose: true, onClickClose: onClose, borderRadius: 16, btnVariant: "secondary" },
                });
            }

            if (
                isIdentityAccepted(identity?.basic) &&
                !isIdentityAccepted(identity?.full) &&
                totalAmount > paymentAmounts.MAX_AMOUNT_BASIC_IDENTIFICATION
            ) {
                return setContent({
                    component: VerificationBlock,
                    props: {
                        code: paymentStatus.WRONG_MAX_AMOUNT_BASIC_IDENTIFICATION.code,
                    },
                    options: { btnClose: true, onClickClose: onClose, borderRadius: 16, btnVariant: "secondary" },
                });
            }

            const { error, result } = await sendPayment(paymentType, paymentAmount);
            if (error) {
                add({ message: error?.data?.message ?? otherErrors.sendPayment });
                return onClose();
            }
            if (result && result.paymentToken && result.paymentId) {
                const { paymentId, paymentToken } = result;
                setContent({
                    component: WidgetBlock,
                    props: { paymentId, paymentToken, handleSuccess: handleWidgetSuccess },
                    options: { onClickClose: onClose },
                });
            }
        },
        [
            add,
            email,
            handleEmailChangeSuccess,
            handleWidgetSuccess,
            identity,
            initProfile,
            onClose,
            sendPayment,
            setContent,
        ],
    );

    const handleCardPayment = useCallback(
        (amount: string, balance: number) => {
            void handlePayment(amount, balance, "CARD4LOT");
        },
        [handlePayment],
    );

    const handleApplePayPayment = useCallback(
        (amount: string, balance: number) => {
            void handlePayment(amount, balance, "CARD_APPLE_PAY4LOT");
        },
        [handlePayment],
    );

    return { handleCardPayment, handleApplePayPayment };
};
