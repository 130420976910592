import { TDigitalAlias, TBingoAlias } from "~types/products";

export const NAMES_ROUTE_ID = {
    politicsId: "/:politicsId",
    lotteryId: "/:lotteryId",
    myTicketId: "/:myTicketId",
    productId: "/:productId",
    drawNumber: "/:drawNumber",
};

const historyDetails = NAMES_ROUTE_ID.productId + NAMES_ROUTE_ID.drawNumber;

export const digitalAlias: Record<string, TDigitalAlias> = {
    t4in20: "digital-4x20",
    t8in20: "digital-8x20",
    t5in36: "digital-5x36",
    t5in50: "digital-5x50",
};

export const bingoAlias: Record<string, TBingoAlias> = {
    b90: "bingo-90",
    b75: "bingo-75",
    b3x3: "bingo-3x3",
};

export const ticketsAlias = { ...digitalAlias, ...bingoAlias };

export const HOME = "/";
export const LOTTERY = "/lottery";
export const BASKET = "/basket";
export const WINNINGS = "/winnings";
export const ABOUT = "/about";
export const STORE_REDIRECT = "/sr";
export const PAYMENT = "/payment";
export const FEEDBACK = "/feedback";
export const CONTACTS = "/contacts";
export const WALLET = "/wallet";

export const POLITICS = "/politics";
export const POLITICS_WEB = "/politics/web";
export const getPoliticsWebRouteById = (id: string) => `${POLITICS_WEB}/${id}`;
export const NL_TERMS_OF_USE = getPoliticsWebRouteById("nl-terms_of_use");
export const NL_PERSONAL_DATA = getPoliticsWebRouteById("nl-personal_data");
export const CUPIS_TERMS_OF_USE = getPoliticsWebRouteById("cupis-terms_of_use");
export const CUPIS_PERSONAL_DATA = getPoliticsWebRouteById("cupis-personal_data");
export const COOKIES_ACCEPTING = getPoliticsWebRouteById("nl-cookie");

export const WINNERS_HISTORY = "/winners-history";
export const getWinnersHistory = (id: number) => `${WINNERS_HISTORY}/${id}`;
export const WINNERS_1 = getWinnersHistory(1);
export const WINNERS_2 = getWinnersHistory(2);
export const WINNERS_3 = getWinnersHistory(3);

export const getLotteryRouteById = (id: string) => `${LOTTERY}/${id}`;
export const getTicketDetailById = (id: string) => `${MY_TICKETS_DETAIL}/${id}`;
export const getPaymentUrl = (paymentId: string, paymentToken: string) =>
    `${PAYMENT}/?p=${paymentId}&t=${paymentToken}`;

export const POLITICS_BY_ID = POLITICS + NAMES_ROUTE_ID.politicsId;
export const POLITICS_WEB_BY_ID = POLITICS_WEB + NAMES_ROUTE_ID.politicsId;
export const LOTTERY_BY_ID = LOTTERY + NAMES_ROUTE_ID.lotteryId;
export const MOMENTARY_1 = getLotteryRouteById("momentary1");
export const MOMENTARY_2 = getLotteryRouteById("momentary2");
export const MOMENTARY_3 = getLotteryRouteById("momentary3");
export const MOMENTARY_8 = getLotteryRouteById("momentary8");
export const MOMENTARY_9 = getLotteryRouteById("momentary9");
export const MOMENTARY_4 = getLotteryRouteById("momentary4");
export const MOMENTARY_5 = getLotteryRouteById("momentary5");
export const MOMENTARY_6 = getLotteryRouteById("momentary6");
export const MOMENTARY_7 = getLotteryRouteById("momentary7");
export const ADD_TICKETS = "/add-tickets";

export const LOTTERY_4_IN_20 = getLotteryRouteById(digitalAlias.t4in20);
export const LOTTERY_4_IN_20_RULES = LOTTERY_4_IN_20 + "/rules";
export const LOTTERY_4_IN_20_HISTORY = LOTTERY_4_IN_20 + "/history";
export const LOTTERY_4_IN_20_HISTORY_DETAILS = LOTTERY_4_IN_20_HISTORY + historyDetails;
export const getDetailsHistory4in20 = (product: string, edition: string) =>
    `${LOTTERY_4_IN_20_HISTORY}/${product}/${edition}`;

export const LOTTERY_8_IN_20 = getLotteryRouteById(digitalAlias.t8in20);
export const LOTTERY_8_IN_20_RULES = LOTTERY_8_IN_20 + "/rules";
export const LOTTERY_8_IN_20_HISTORY = LOTTERY_8_IN_20 + "/history";
export const LOTTERY_8_IN_20_HISTORY_DETAILS = LOTTERY_8_IN_20_HISTORY + historyDetails;
export const getDetailsHistory8in20 = (product: string, edition: string) =>
    `${LOTTERY_8_IN_20_HISTORY}/${product}/${edition}`;

export const LOTTERY_5_IN_36 = getLotteryRouteById(digitalAlias.t5in36);
export const LOTTERY_5_IN_36_RULES = LOTTERY_5_IN_36 + "/rules";
export const LOTTERY_5_IN_36_HISTORY = LOTTERY_5_IN_36 + "/history";
export const LOTTERY_5_IN_36_HISTORY_DETAILS = LOTTERY_5_IN_36_HISTORY + historyDetails;
export const getDetailsHistory5in36 = (product: string, edition: string) =>
    `${LOTTERY_5_IN_36_HISTORY}/${product}/${edition}`;

export const LOTTERY_5_IN_50 = getLotteryRouteById(digitalAlias.t5in50);
export const LOTTERY_5_IN_50_RULES = LOTTERY_5_IN_50 + "/rules";
export const LOTTERY_5_IN_50_HISTORY = LOTTERY_5_IN_50 + "/history";
export const LOTTERY_5_IN_50_HISTORY_DETAILS = LOTTERY_5_IN_50_HISTORY + historyDetails;
export const getDetailsHistory5in50 = (product: string, edition: string) =>
    `${LOTTERY_5_IN_50_HISTORY}/${product}/${edition}`;

export const LOTTERY_BINGO_90 = getLotteryRouteById(bingoAlias.b90);
export const LOTTERY_BINGO_90_RULES = LOTTERY_BINGO_90 + "/rules";
export const LOTTERY_BINGO_90_HISTORY = LOTTERY_BINGO_90 + "/history";
export const LOTTERY_BINGO_90_HISTORY_DETAILS = LOTTERY_BINGO_90_HISTORY + historyDetails;
export const getDetailsHistoryBingo90 = (product: string, edition: string) =>
    `${LOTTERY_BINGO_90_HISTORY}/${product}/${edition}`;

export const LOTTERY_BINGO_75 = getLotteryRouteById(bingoAlias.b75);
export const LOTTERY_BINGO_75_RULES = LOTTERY_BINGO_75 + "/rules";
export const LOTTERY_BINGO_75_HISTORY = LOTTERY_BINGO_75 + "/history";
export const LOTTERY_BINGO_75_HISTORY_DETAILS = LOTTERY_BINGO_75_HISTORY + historyDetails;
export const getDetailsHistoryBingo75 = (product: string, edition: string) =>
    `${LOTTERY_BINGO_75_HISTORY}/${product}/${edition}`;

export const LOTTERY_BINGO_3X3 = getLotteryRouteById(bingoAlias.b3x3);
export const LOTTERY_BINGO_3X3_RULES = LOTTERY_BINGO_3X3 + "/rules";
export const LOTTERY_BINGO_3X3_HISTORY = LOTTERY_BINGO_3X3 + "/history";
export const LOTTERY_BINGO_3X3_HISTORY_DETAILS = LOTTERY_BINGO_3X3_HISTORY + historyDetails;
export const getDetailsHistoryBingo3x3 = (product: string, edition: string) =>
    `${LOTTERY_BINGO_3X3_HISTORY}/${product}/${edition}`;

export const MY_TICKETS = "/my-tickets";
export const MY_TICKETS_UPCOMING = MY_TICKETS + "/future";
export const MY_TICKETS_WITH_WIN = MY_TICKETS + "/with-win";
export const MY_TICKETS_WITHOUT_WIN = MY_TICKETS + "/without-win";

export const MY_TICKETS_DETAIL = "/my-tickets-detail";
export const MY_TICKETS_BY_ID = MY_TICKETS_DETAIL + NAMES_ROUTE_ID.myTicketId;
export const MY_TICKETS_RUlES = MY_TICKETS_BY_ID + "/rules";

export const PROFILE = "/profile";
export const PROFILE_EDIT = PROFILE + "/edit";

export const DRAW_HISTORY = "/draw-history";
export const getDrawHistoryById = (id: string) => `${DRAW_HISTORY}/${id}/history`;
export const DRAW_HISTORY_LOTTERY_4_IN_20 = getDrawHistoryById(digitalAlias.t4in20);
export const DRAW_HISTORY_LOTTERY_5_IN_36 = getDrawHistoryById(digitalAlias.t5in36);
export const DRAW_HISTORY_LOTTERY_5_IN_50 = getDrawHistoryById(digitalAlias.t5in50);
export const DRAW_HISTORY_LOTTERY_8_IN_20 = getDrawHistoryById(digitalAlias.t8in20);
export const DRAW_HISTORY_LOTTERY_BINGO_3X3 = getDrawHistoryById(bingoAlias.b3x3);
export const DRAW_HISTORY_LOTTERY_BINGO_75 = getDrawHistoryById(bingoAlias.b75);
export const DRAW_HISTORY_LOTTERY_BINGO_90 = getDrawHistoryById(bingoAlias.b90);

type TRoutesList = typeof LOTTERY | typeof WINNINGS | typeof ABOUT | typeof DRAW_HISTORY;
export const routesTitle: Record<TRoutesList, string> = {
    "/lottery": "Лотереи",
    "/draw-history": "Архив тиражей",
    "/winnings": "Победители",
    "/about": "О компании",
};
