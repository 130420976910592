import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { getTicketNumber } from "~helpers/format";
import { TPaymentStatus, TTicketStatus } from "~types/my-tickets";
import { TDigitalAlias, TProductType } from "~types/products";
import { TScreen } from "~hooks/useAppScreen";
import { FutureBlock } from "./future-block";
import { PaymentBlock } from "./payment-block";
import { DigitalCombinationsStyled, InfoLeftBlock, InfoRightBlock, TicketNumber, Wrapper } from "./theme";
import { WinBlock } from "./win-block";
import { WithoutWinBlock } from "./withoutWin-block";
import { TIsContent } from "../my-ticket";

type TInfoBlock = {
    ticketNumber: string;
    numbers: Array<Array<number>>;
    numbersWin: Array<Array<number>>;
    isContent: TIsContent;
    typeScreen: TScreen;
    paymentStatus: TPaymentStatus;
    registrationStatus: TPaymentStatus;
    status: TTicketStatus;
    type: TProductType;
    drawDate: string;
    prize: number;
    notLink: boolean;
    alias?: TDigitalAlias;
};

const InfoBlock = ({
    ticketNumber,
    numbers,
    numbersWin,
    drawDate,
    paymentStatus,
    typeScreen,
    isContent,
    status,
    prize,
    registrationStatus,
    type,
    alias,
    notLink,
}: TInfoBlock) => {
    const theme = useContext(ThemeContext);
    return (
        <Wrapper>
            <InfoLeftBlock $type={type} $isContent={isContent}>
                {!!ticketNumber && (
                    <TicketNumber $type={type} tag="div" size="text16" color={theme.color.text.primary}>
                        Информация об электронном лотерейном билете № {getTicketNumber(ticketNumber)}
                    </TicketNumber>
                )}
                {type === "DIGITAL" && (
                    <DigitalCombinationsStyled
                        numbers={numbers}
                        numbersWin={numbersWin}
                        fieldColor={theme.color.text.primary}
                        alias={alias}
                    />
                )}
            </InfoLeftBlock>
            <InfoRightBlock $isContent={isContent} $notLink={notLink}>
                {isContent === "future" && (
                    <FutureBlock
                        registrationStatus={registrationStatus}
                        paymentStatus={paymentStatus}
                        drawDate={drawDate}
                        theme={theme}
                    />
                )}
                {isContent === "payment" && (
                    <PaymentBlock paymentStatus={paymentStatus} registrationStatus={registrationStatus} theme={theme} />
                )}
                {isContent === "win" && (
                    <WinBlock
                        ticketNumber={ticketNumber}
                        paymentStatus={paymentStatus}
                        status={status}
                        prize={prize}
                        typeScreen={typeScreen}
                        theme={theme}
                    />
                )}
                {isContent === "notWin" && (
                    <WithoutWinBlock
                        registrationStatus={registrationStatus}
                        paymentStatus={paymentStatus}
                        theme={theme}
                    />
                )}
            </InfoRightBlock>
        </Wrapper>
    );
};

export default InfoBlock;
