import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import {
    WinnerBlockWrapper,
    TextWrapper,
    ContentWrapper,
    IconWrapper,
    LotteryInfoWrapper,
    LotteryContentWrapper,
} from "./theme";

type TWinner = {
    name: string;
    avatar: string;
    amount: string;
    lottery: string;
    emblem: string;
    draw: string;
    className?: string;
    background?: string;
};

const WinnerCard = ({ name, avatar, amount, lottery, emblem, draw, className, background }: TWinner) => {
    const { color } = useContext(ThemeContext);

    return (
        <WinnerBlockWrapper className={className} background={background}>
            <ContentWrapper className="winner-content">
                <IconWrapper className="avatar-icon mr12 mr8--tablet">
                    <img src={avatar} alt="avatar" />
                </IconWrapper>
                <TextWrapper>
                    <TextAdaptive size={"text14"} weight={"medium"} className={"mb8 mb4--tablet mb0--mobile"}>
                        {name}
                    </TextAdaptive>
                    <TitleAdaptive tag={"span"} weight={"medium"} color={color.text.primary} size={"title20"}>
                        {amount}
                    </TitleAdaptive>
                </TextWrapper>
            </ContentWrapper>
            <LotteryInfoWrapper className="content-desktop">
                <IconWrapper className="lottery-info-icon mr8">
                    <img src={emblem} alt="emblem" />
                </IconWrapper>
                <TextWrapper className="lottery-info">
                    <LotteryContentWrapper className="mb4">
                        <IconWrapper className="lottery-info-icon-small mr4">
                            <img src={emblem} alt="emblem" />
                        </IconWrapper>
                        <TextAdaptive size="text16" weight="medium">
                            {lottery}
                        </TextAdaptive>
                    </LotteryContentWrapper>
                    <TextAdaptive size={"text14"} color={color.text.tertiary} weight={"medium"} className={"draw-text"}>
                        {draw}
                    </TextAdaptive>
                </TextWrapper>
            </LotteryInfoWrapper>
        </WinnerBlockWrapper>
    );
};

export default WinnerCard;
