import React from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { LegalInfo } from "~pages/lottery/common/legal-info";
import { legalText } from "~constants/handbook";
import { ticketsBingoType } from "~constants/tickets-type";
import { useAppSelector } from "~hooks/useAppSelector";
import { ContentWrapper, RulesWrapper } from "./theme";
import { BingoRulesHeader } from "../../common/bingo-rules-header";

type TRulesProps = {
    isActive: boolean;
};

const bingo75 = {
    type: "Бинго",
    prizeFund: "65%",
    frequency: "Ежедневно, каждые 15 мин.",
};

const Rules = ({ isActive }: TRulesProps) => {
    // const jackpot = useAppSelector((state) => state.tickets[ticketsBingoType.b75].jackpot);
    const amount = useAppSelector((state) => state.tickets[ticketsBingoType.b75].data[0]?.amount);
    return (
        <RulesWrapper>
            <ContentWrapper>
                {/*{jackpot !== null && amount !== null ? (*/}
                {/*    <BannerRulesBingo75 jackpot={jackpot} amount={amount} className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*) : (*/}
                {/*    <SkeletonStyled className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*)}*/}
                {isActive && (
                    <BingoRulesHeader
                        className="mb32 mb24--tablet"
                        type={bingo75.type}
                        prizeFund={bingo75.prizeFund}
                        ticketPrice={amount}
                        frequency={bingo75.frequency}
                    />
                )}
                <TitleAdaptive className="mb24 mb16--mobile" tag="h3" size="title38" weight="medium">
                    Как играть?
                </TitleAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    Формирование лотерейной комбинации осуществляется на игровом поле. Каждое игровое поле состоит из 25
                    ячеек (5 строк и 5 столбцов), содержащих 24 неповторяющихся числа в диапазоне от 1 до 75
                    включительно. Центральная ячейка считается заполненной и дополняет любую комбинацию.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    Выигрышная комбинация определяется путем формирования случайной последовательности из
                    неповторяющихся чисел в диапазоне от 1 до 75 включительно в ходе розыгрыша призового фонда в
                    соответствующем тираже.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    Билет считается выигрышным, если размещенные в игровом поле числа частично или полностью совпали с
                    числами, выпавшими в ходе розыгрыша призового фонда в соответствующем тираже тиража, и при этом
                    составили выигрышную лотерейную комбинацию по результатам розыгрыша.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    Выигрыши определяются в несколько туров. Общее количество туров, необходимое для розыгрыша призового
                    фонда, определяются Оператором лотереи в рамках конкретного тиража.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" weight="medium">
                    Категории распределения призового фонда:
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    <TextAdaptive tag="span" className="mb24 mb16--mobile" size="text16" weight="medium">
                        1. Первая категория выигрышей - &quot;Джекпот&quot;
                    </TextAdaptive>{" "}
                    может разыгрываться на основе следующих принципов, решение об использовании которых в рамках
                    конкретного тиража принимается Оператором лотереи:
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    Принцип розыгрыша N 3: выигрывают билеты, в которых на объявленном ходу или раньше все 24 числа
                    игрового поля билета раньше других совпадут с выпавшими в процессе розыгрыша числами. В случае
                    определения нескольких победителей в данной категории, призовой фонд данной категории делится между
                    ними. Билет, выигравший в категории &quot;Джекпот&quot; не может участвовать в розыгрыше четвертой
                    и/или пятой категорий.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" weight="medium">
                    2. Последующие категории выигрышей распределения призового фонда:
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    <TextAdaptive tag="span" className="mb24" size="text16" weight="medium">
                        Вторая категория выигрышей - &quot;Первый тур&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых от 4 до 7 чисел (точное количество чисел определяется Оператором
                    лотереи) совпадут с выпавшими в процессе розыгрыша числами до хода, установленного Оператором
                    лотереи. Необходимое для выигрыша совпадение игровой комбинации участника лотереи с выпавшими в
                    процессе розыгрыша числами, состав игровой комбинации и расположение чисел игровой комбинации на
                    билете определяются Оператором лотереи в рамках конкретного тиража. Билет, выигравший в категории,
                    продолжает участвовать в розыгрыше последующих категорий.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    <TextAdaptive tag="span" className="mb24 mb16--mobile" size="text16" weight="medium">
                        Третья категория выигрышей - &quot;Второй тур&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых от 8 до 16 чисел (точное количество чисел определяется Оператором
                    лотереи) совпадут с выпавшими в процессе розыгрыша числами до хода, установленного Оператором
                    лотереи. Необходимое для выигрыша совпадение игровой комбинации участника лотереи с выпавшими в
                    процессе розыгрыша числами, состав игровой комбинации и расположение чисел игровой комбинации на
                    билете определяются Оператором лотереи в рамках конкретного тиража. Билет, выигравший в категории,
                    продолжает участвовать в розыгрыше последующих категорий.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    <TextAdaptive tag="span" className="mb24 mb16--mobile" size="text16" weight="medium">
                        Четвертая категория выигрышей - &quot;Третий тур&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых все 24 числа игрового поля билета совпадут с выпавшими в процессе
                    розыгрыша числами до хода, установленного Оператором лотереи, но после последнего хода розыгрыша
                    &quot;Джекпот&quot;. Билет, выигравший в категории, не участвует в розыгрыше пятой категории.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    <TextAdaptive tag="span" className="mb24 mb16--mobile" size="text16" weight="medium">
                        Пятая категория выигрышей - &quot;Карточка&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых все 24 числа игрового поля билета совпадут с выпавшими в процессе
                    розыгрыша числами до хода, установленного Оператором лотереи, но после последнего хода розыгрыша
                    четвертой категории.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    <TextAdaptive tag="span" className="mb24 mb16--mobile" size="text16" weight="medium">
                        Шестая категория выигрышей - &quot;Счастливый номер&quot; -
                    </TextAdaptive>{" "}
                    выигрывают билеты, в которых номер билета оканчивается на определенную в ходе розыгрыша выигрышную
                    комбинацию цифр (последние цифры выпавших чисел в ходах и/или турах, установленных Оператором
                    лотереи, или невыпавших чисел).
                </TextAdaptive>
                <TextAdaptive className="mb64 mb48--tablet" size="text16">
                    Необходимое для выигрыша совпадение игровой комбинации участника лотереи с выпавшими в процессе
                    розыгрыша числами, состав игровой комбинации и расположение чисел игровой комбинации на билете
                    определяются Оператором лотереи в рамках конкретного тиража. Решение о проведении данного розыгрыша
                    Оператор лотереи вправе принять как до, так и во время розыгрыша призового фонда тиража.
                </TextAdaptive>
                <LegalInfo
                    type="BINGO"
                    prizeInfo={legalText["bingo-75"].prizeInfo}
                    algoInfo={legalText["bingo-75"].algoInfo}
                />
            </ContentWrapper>
        </RulesWrapper>
    );
};

export default Rules;
