import { LOTTERY, MOMENTARY_3 } from "~constants/routes";

export const crumbs = [
    {
        title: "Лотереи",
        path: LOTTERY,
        exact: true,
    },
    {
        title: "Путь к удаче",
        path: MOMENTARY_3,
        exact: true,
    },
];
