import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { PDFIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { getPhoneHelpFormat } from "~helpers/format";
import { TProductType } from "~types/products";
import { nationalLotteryPhone } from "~constants/links";
import { getFiles, TDocsKey } from "./helpers";
import { FilesBlockStyled, Wrapper } from "./theme";

type TLegalInfo = {
    className?: string;
    algoInfo: string;
    prizeInfo: string;
    type: TProductType;
    keyDoc?: TDocsKey;
};

const LegalInfo = ({ className, algoInfo, prizeInfo, type = "DIGITAL", keyDoc = "writ" }: TLegalInfo) => {
    const { color } = useContext(ThemeContext);
    const files = getFiles(type, keyDoc);
    return (
        <Wrapper className={className}>
            <TitleAdaptive
                tag={"div"}
                size={"title24"}
                weight={"medium"}
                className={"mb16 mb12--mobile"}
                color={color.text.headline}
            >
                Правовая информация
            </TitleAdaptive>
            <TextAdaptive size={"text16"} className={"mb16 mb12--mobile"} color={color.text.primary}>
                {algoInfo}
            </TextAdaptive>
            <TextAdaptive size={"text16"} className={"mb16 mb12--mobile"} color={color.text.primary}>
                Срок проведения лотереи — до 29 августа 2034 г.
            </TextAdaptive>
            <TextAdaptive size={"text16"} className={"mb16 mb12--mobile"} color={color.text.primary}>
                Призовой фонд — {prizeInfo} от выручки.
            </TextAdaptive>
            <TextAdaptive size={"text16"} className={"mb16 mb12--mobile"} color={color.text.primary}>
                Лотерея проводится на основании распоряжения Правительства Российской Федерации от 29 августа 2019 г. N
                1921-р.
            </TextAdaptive>
            <TextAdaptive size={"text16"} color={color.text.primary}>
                Организатор лотереи: Министерство финансов Российской Федерации
            </TextAdaptive>
            <TextAdaptive size={"text16"} className={"mb32"}>
                Оператор лотереи: ООО «Спортивные Лотереи», тел. {getPhoneHelpFormat(nationalLotteryPhone)}
            </TextAdaptive>
            <FilesBlockStyled files={files} margin="mb16--mobile" prefixIcon={<PDFIcon />} />
        </Wrapper>
    );
};

export default LegalInfo;
