import { combineReducers, $CombinedState } from "redux";
import { connectRouter } from "connected-react-router";
import { initLotteriesReducer } from "~store/lotteries/reducer";
import { initBasketReducer } from "./basket/reducer";
import { initDictionariesReducer } from "./dictionaries/reducer";
import { history } from "./middleware";
import { initMyTicketsReducer } from "./my-tickets/reducer";
import { initNotificationReducer } from "./notification/reducer";
import { initProfileReducer } from "./profile/reducer";
import { initTicketsReducer } from "./tickets/reducer";
import { initUserReducer } from "./user/reducer";
import { initWinningsReducer } from "./winnings/reducer";

export const initReducer = () => {
    const router = connectRouter<any>(history);

    const rootReducer = combineReducers({
        router,
        user: initUserReducer(),
        dictionaries: initDictionariesReducer(),
        notification: initNotificationReducer(),
        tickets: initTicketsReducer(),
        profile: initProfileReducer(),
        myTickets: initMyTicketsReducer(),
        basket: initBasketReducer(),
        lotteries: initLotteriesReducer(),
        winnings: initWinningsReducer(),
    });

    return rootReducer;
};
const rootReducer = initReducer();
export type AppState = Omit<ReturnType<typeof rootReducer>, typeof $CombinedState>;
