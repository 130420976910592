import React, { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import { TextAdaptive } from "~atoms/text-adaptive";
import { LinkList, NavigationSidebarWrapper } from "./theme";

export type TNavigationSidebarItem = {
    id: string;
    title: string;
    icon: string;
    link: string;
};
type TNavigationMainProps = {
    onClose?: () => void;
    className?: string;
    navigationItems: Array<TNavigationSidebarItem>;
};

const NavigationSidebar = ({ onClose, className, navigationItems }: PropsWithChildren<TNavigationMainProps>) => {
    return (
        <NavigationSidebarWrapper className={className} role="navigation">
            <LinkList>
                {navigationItems.map(({ link, title, icon }) => (
                    <NavLink
                        key={link}
                        role="button"
                        to={link}
                        itemProp="url"
                        className="sidebar-link"
                        onClick={onClose}
                    >
                        <div className={icon} />
                        <TextAdaptive size="text16" weight="medium" className="ml12">
                            {title}
                        </TextAdaptive>
                    </NavLink>
                ))}
            </LinkList>
        </NavigationSidebarWrapper>
    );
};

export default NavigationSidebar;
