import { useCallback, useContext, useEffect, useState } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { useGetDraw } from "~pages/lottery/digital-lottery/_hooks/useGetDraw";
import { useUpdateTicketEditions } from "~pages/lottery/digital-lottery/_hooks/useUpdateTicketEditions";
import { isDateExpired } from "~helpers/format";
import { TEdition, TTicketTypes } from "~store/tickets/types";
import { TDigitalAlias } from "~types/products";
import { useAppSelector } from "~hooks/useAppSelector";
import { useMutationHandles } from "~hooks/useMutationHandles";

export const useSelectModalDigitalLogic = (ticketAlias: TDigitalAlias, ticketType: TTicketTypes, idx: number) => {
    const { handleFieldChange } = useMutationHandles("tickets");
    const { getDraw } = useGetDraw(ticketAlias, ticketType);
    const { handleUpdateTicketEditions } = useUpdateTicketEditions(ticketType, idx);
    const ticket = useAppSelector((state) => state.tickets[ticketType].data[idx]);
    const { editions, editionsSelected } = ticket;
    const [currentEditions, setEditions] = useState<TEdition[]>(editionsSelected);

    const { onClose } = useContext(ModalWindowContext);

    const handleSelect = useCallback(() => {
        handleUpdateTicketEditions(currentEditions);
        onClose();
    }, [currentEditions, handleUpdateTicketEditions, onClose]);

    const handleChange = useCallback(
        (edition: string, isSelected: boolean) => {
            if (isSelected) {
                setEditions((values) => [...values, editions.find((item) => item.edition === edition)!]);
            } else {
                setEditions((values) => {
                    if (values.length === 1) {
                        return values;
                    }
                    return values.filter((item) => item.edition !== edition);
                });
            }
        },
        [setEditions, editions],
    );

    useEffect(() => {
        if (isDateExpired(editions[0]?.drawDate)) {
            void getDraw(idx);
        }
    }, [editions, getDraw, idx]);

    useEffect(() => {
        if (isDateExpired(editionsSelected[0].saleDate)) {
            const editionsSelectedFiltered = editionsSelected.filter((item) => !isDateExpired(item.saleDate));
            const editionsSelectedCurrent = editionsSelectedFiltered.length
                ? editionsSelectedFiltered
                : [editions.find((item) => !isDateExpired(item.saleDate))];
            handleFieldChange(`${ticketType}.data.${idx}.editionsSelected`, editionsSelectedCurrent);
        }
    }, [editions, editionsSelected, handleFieldChange, idx, ticketType]);

    useEffect(() => {
        setEditions(editionsSelected);
    }, [editionsSelected]);

    return {
        data: {
            editionsSelected: currentEditions,
            editions: editions,
            editionsExpired: isDateExpired(editions[0]?.drawDate),
        },
        cb: {
            handleSelect,
            handleChange,
            handleClose: onClose,
        },
    };
};
