import styled from "styled-components";
import { adaptive } from "~assets/styles/tokens/screen";
import { INDENT } from "~assets/styles/tokens/sizes";
import { text12 } from "~assets/styles/tokens/typography";
import { TextAdaptive } from "~atoms/text-adaptive";

const TicketImg = styled.img`
    height: 24px;

    ${adaptive.minWidth.tablet} {
        height: 32px;
    }
`;

const Wrapper = styled.div<{ $bg: string }>`
    padding: ${INDENT.xs};
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: ${({ $bg }) => $bg};
    &:after {
        position: absolute;
        bottom: -1px;
        left: 0;
        content: "";
        width: 100%;
        height: 1px;
        z-index: 1;
        background: ${({ $bg }) => $bg};
        mask-image: linear-gradient(to right, transparent 0, transparent 4px, black 4px, black 8px);
        mask-repeat: repeat;
        mask-size: 8px 100%;
    }

    ${adaptive.minWidth.tablet} {
        padding: ${INDENT.xxs} ${INDENT.sm};
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    ${adaptive.minWidth.desktopXL} {
        padding: ${INDENT.sm};
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 0;
        &:after {
            left: unset;
            bottom: unset;
            right: -1px;
            top: 0;
            height: 100%;
            width: 1px;
            mask-image: linear-gradient(to bottom, transparent 0, transparent 4px, black 4px, black 8px);
            mask-size: 100% 8px;
        }
    }

    /* ${adaptive.minWidth.desktopXXL} {
        padding: ${INDENT.sm};
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    } */
`;

const TextBlock = styled.div`
    display: flex;
    align-items: center;

    /* 320px */
    ${adaptive.maxWidth.mobile} {
        white-space: nowrap;
    }

    ${adaptive.minWidth.tablet} {
        display: block;
    }
`;

const TextDate = styled(TextAdaptive)`
    ${adaptive.maxWidth.tablet} {
        ${text12};
    }
`;

export { TicketImg, Wrapper, TextBlock, TextDate };
