import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Button } from "~atoms/button";
import { Input } from "~atoms/input";
import { useSendSms } from "~organisms/auth-form/_hooks/useSendSms";
import { phoneValidationMessage } from "~organisms/auth-form/common-validation";
import { AuthDesc, AuthHeader, ErrorText, wrapperAuthForm } from "~organisms/auth-form/theme";

type TPhoneBlockProps = {
    className?: string;
    phone?: string;
    title?: string;
    isReset?: boolean;
};

type TFormValues = {
    phone: string;
};

const PhoneBlockWrapper = styled.div`
    ${wrapperAuthForm}
`;

const RecoveryPasswordBlock = ({
    className,
    title = "Восстановление пароля",
    phone = "",
    isReset,
}: TPhoneBlockProps) => {
    const [errorResend, setErrorResend] = useState("");
    const sendSms = useSendSms(setErrorResend);

    const { handleSubmit, control } = useForm<TFormValues>({
        defaultValues: {
            phone: phone,
        },
    });

    const onSubmit = useCallback(
        (form) => {
            void sendSms(form.phone, title, isReset);
        },
        [sendSms, title, isReset],
    );

    return (
        <PhoneBlockWrapper className={className}>
            <AuthHeader tag={"h4"}>{title}</AuthHeader>
            <AuthDesc>Введите номер телефона, на который зарегистрирован Ваш аккаунт</AuthDesc>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <Input
                            id={"auth-phone"}
                            label={"Телефон"}
                            typeMask={"phone"}
                            autoFocus={true}
                            name={field.name}
                            placeholder={"+7"}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            className={"auth-field-form"}
                            errorText={fieldState.error?.message}
                            error={fieldState.invalid}
                        />
                    )}
                    name="phone"
                    control={control}
                    rules={{
                        validate: {
                            phone: phoneValidationMessage,
                        },
                    }}
                />
                <Button className={"auth-form-btn auth-field-form"} type={"submit"}>
                    Продолжить
                </Button>
                {errorResend && <ErrorText className={"error-text--center mb16"}>{errorResend}</ErrorText>}
            </form>
        </PhoneBlockWrapper>
    );
};

export default RecoveryPasswordBlock;
