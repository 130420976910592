import { AxiosInstance } from "axios";
import { adapters } from "~adapters";
import { ApiResponse } from "~services/http/types";
import { TWinner, TWinners } from "~types/winners";

const enum URLs {
    URL_WINNERS = "/winners",
}

export const createWinnersApi = (http: AxiosInstance) => ({
    async getPaginatedWinners(page?: number, size?: number) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TWinners>>(URLs.URL_WINNERS, {
            params: {
                page,
                size,
            },
        });
        return { error, result: { ...result, content: adapters.winners.fromGetWinners(result && result.content) } };
    },

    async getTopWinners(size: number) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TWinner[]>>(URLs.URL_WINNERS + `/top/${size}`);
        return { error, result: adapters.winners.fromGetWinners(result) };
    },
});
