import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { ArrowDropdownIcon } from "~atoms/icons";
import { RemoveButton } from "~atoms/remove-button";
import { SelectModalDigital } from "~organisms/select-modal";
import TicketFooter from "~organisms/ticket-footer/ticket-footer";
import { useGetDraw } from "~pages/lottery/digital-lottery/_hooks/useGetDraw";
import { useUpdateTicketNumbers } from "~pages/lottery/digital-lottery/_hooks/useUpdateTicketNumbers";
import { TTicketOptions } from "~pages/lottery/types";
import { digitalAlias } from "~constants/routes";
import { ticketsDigitalType } from "~constants/tickets-type";
import { useAppSelector } from "~hooks/useAppSelector";
import ticketDesktop from "./assets/5-50 - Desktop.png";
import ticketMobile from "./assets/5-50 - Mobile.png";
import ticketTablet from "./assets/5-50 - Tablet.png";
import { FieldNumbers1, FieldNumbers2 } from "./field-numbers";
import { Header } from "./header";
import { FieldsNumbersBody, SkeletonStyled, Ticket5in50Wrapper, TicketBody, TicketFooterWrapper } from "./theme";

export type TBackgroundTicket = {
    backgroundUrlDesktop: string;
    backgroundUrlTablet: string;
    backgroundUrlMobile: string;
};

const background: TBackgroundTicket = {
    backgroundUrlDesktop: ticketDesktop,
    backgroundUrlTablet: ticketTablet,
    backgroundUrlMobile: ticketMobile,
};

type TTicket5in50Props = {
    idx: number;
    showRemoveBtn?: boolean;
    ticketOptions: TTicketOptions;
    onRemove: (idx: number) => () => void;
    onReset: (idx: number) => () => void;
    className?: string;
};

const Ticket5in50 = ({
    className,
    idx,
    ticketOptions,
    onRemove,
    onReset,
    showRemoveBtn = false,
}: TTicket5in50Props) => {
    const { getDraw } = useGetDraw(digitalAlias.t5in50, ticketsDigitalType.t5in50);
    const { handleUpdateTicketNumbers, handleUpdateAllTicket } = useUpdateTicketNumbers(ticketsDigitalType.t5in50);

    const ticket = useAppSelector((state) => state.tickets[ticketsDigitalType.t5in50].data[idx]);
    const logoSrc = useAppSelector((state) => state.tickets[ticketsDigitalType.t5in50].logoSrc);
    const { editionsSelected, numbersSelected, amount, init } = ticket;

    const selectRef = useRef<HTMLDivElement>(null);

    const isResettable = useMemo(() => {
        return numbersSelected.reduce((flag: boolean, item): boolean => {
            return flag || !!item.length;
        }, false);
    }, [numbersSelected]);

    const handleUpdateTicket = useCallback(
        (number: number, numbersSelected: number[]) => handleUpdateTicketNumbers(idx)(number, numbersSelected),
        [handleUpdateTicketNumbers, idx],
    );

    const handleUpdateAllTickets = useCallback(
        (numbersSelected: number[][]) => handleUpdateAllTicket(idx)(numbersSelected),
        [handleUpdateAllTicket, idx],
    );

    const handleRemove = useCallback(() => onRemove(idx)(), [onRemove, idx]);
    const handleReset = useCallback(() => onReset(idx)(), [onReset, idx]);

    useEffect(() => {
        if (!init) void getDraw(idx);
    }, [getDraw, idx, init]);

    return (
        <>
            {!init ? (
                <SkeletonStyled className={className} />
            ) : (
                <Ticket5in50Wrapper className={className} background={background}>
                    <Header
                        logoSrc={logoSrc}
                        amount={amount}
                        maxNumField1={ticketOptions.field1.generateNum}
                        maxNumField2={ticketOptions.field2.generateNum}
                        titleField1={"Поле 1"}
                        titleField2={"Поле 2"}
                        titleTicket={`Билет № ${idx + 1}`}
                        selectedNum={numbersSelected}
                        title={`Билет № ${idx + 1}`}
                    >
                        <SelectModalDigital
                            ref={selectRef}
                            autoWidth
                            suffixIcon={<ArrowDropdownIcon />}
                            className={"select-modal"}
                            idx={idx}
                            ticketType={ticketsDigitalType.t5in50}
                            ticketAlias={digitalAlias.t5in50}
                            title={`Выбрано ${editionsSelected.length}`}
                        />
                    </Header>
                    <TicketBody className="mt48--mobile" flexGrow={1} mobile={{ alignItems: "center" }}>
                        <FieldsNumbersBody
                            className={"mauto--mobile"}
                            justify="center"
                            mobile={{
                                $direction: "column",
                            }}
                        >
                            <FieldNumbers1
                                id={0}
                                className={"mr64 mr56--desktopxl mr32--tablet mr0--mobile mb8--tablet"}
                                maxNum={ticketOptions.field1.generateNum}
                                title={"Поле 1"}
                                values={numbersSelected[0]}
                                onChange={handleUpdateTicket}
                                flexShrink={0}
                                flexBasis={"612px"}
                                desktopXL={{ flexBasis: "392px" }}
                                tablet={{ flexBasis: "476px" }}
                                mobile={{ flexBasis: "auto" }}
                            />
                            <FieldNumbers2
                                id={1}
                                className={"field2"}
                                maxNum={ticketOptions.field2.generateNum}
                                title={"Поле 2"}
                                values={numbersSelected[1]}
                                onChange={handleUpdateTicket}
                                flexShrink={0}
                                flexBasis={"240px"}
                                desktopXL={{ flexBasis: "152px" }}
                                tablet={{ flexBasis: "111px" }}
                                mobile={{ flexBasis: "auto" }}
                            />
                        </FieldsNumbersBody>
                    </TicketBody>
                    <TicketFooterWrapper>
                        <TicketFooter
                            id={idx}
                            showRemoveBtn={showRemoveBtn}
                            options={ticketOptions}
                            className="ticket-controls"
                            onChange={handleUpdateAllTickets}
                            onReset={handleReset}
                            onRemove={handleRemove}
                            isResettable={isResettable}
                        />
                        {showRemoveBtn && (
                            <RemoveButton className="btn-remove-mobile mauto--mobile" onClick={handleRemove}>
                                Удалить
                            </RemoveButton>
                        )}
                    </TicketFooterWrapper>
                </Ticket5in50Wrapper>
            )}
        </>
    );
};

export default Ticket5in50;
