import ReactDOM from "react-dom";
import { connectAuthInterceptors } from "~services/http/interceptors/auth";
import { connectErrorsInterceptors } from "~services/http/interceptors/errors";
import { setVersionBuild } from "~services/logger";
import { getApp } from "./app";

const renderDOM = __DEV__ ? ReactDOM.render : ReactDOM.render;

(async function () {
    setVersionBuild();
    const context: Record<string, unknown> = { isSSR: window.hasOwnProperty("SSR") };
    if (context.isSSR) {
        context.initialState = (window as any).SSR.initialState;
    }

    const { app, http } = await getApp(context);
    connectAuthInterceptors(http);
    connectErrorsInterceptors(http);

    renderDOM(app, document.getElementById("app"));
})();
