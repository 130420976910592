import { TMutationActions } from "../mutation/types";

export type TUserState = {
    auth: boolean;
    roles: string[];
};

export const AUTH_APP = "AUTH_APP";
export type TActionAuth = {
    type: typeof AUTH_APP;
    payload: boolean;
};
export type TUserActions = TMutationActions | TActionAuth;
