import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { Avatar } from "~atoms/avatar";
import { Hamburger } from "~atoms/hamburger";
import { Text } from "~atoms/text";
import { useLogout } from "~organisms/header/buttons-header/_hooks/useLogout";
import { NavigationSidebar } from "~organisms/navigation-sidebar";
import { TNavigationSidebarItem } from "~organisms/navigation-sidebar/navigation-sidebar";
import {
    ButtonStyled,
    CSSTransitionHeader,
    CSSTransitionSidebar,
    headerTransitionName,
    LogoutButton,
    NavigationWrapper,
    ProfileSidebarContent,
    SidebarHeader,
    SidebarHeaderWrapper,
    sidebarTransitionName,
} from "~organisms/profile-sidebar/theme";
import {
    duration,
    ScrollbarsStyled,
    ScrollbarThumbVertical,
    ScrollbarTrackVertical,
    SidebarBackground,
} from "~organisms/sidebar/theme";
import { getMoneyFormat } from "~helpers/format";
import { MY_TICKETS_UPCOMING, PROFILE, WALLET } from "~constants/routes";
import { useAppSelector } from "~hooks/useAppSelector";

type TProfileSidebarProps = {
    active: boolean;
    onClose: () => void;
    className?: string;
};

const ProfileSidebarWrapper = styled.div``;

const profileSidebarNavigation: (balance: number) => TNavigationSidebarItem[] = (balance) => {
    return [
        { id: "profile", icon: "avatar-icon", title: "Мой профиль", link: PROFILE },
        { id: "ticket", icon: "tickets-icon", title: "Билеты", link: MY_TICKETS_UPCOMING },
        { id: "wallet", icon: "wallet-icon", title: `Кошелек ${getMoneyFormat(balance)} ₽`, link: WALLET },
    ];
};

const ProfileSidebar = ({ className, active, onClose }: TProfileSidebarProps) => {
    const profileSidebarClassname = className ? className : sidebarTransitionName;
    const nickname = useAppSelector((state) => state.profile.data?.nickname);
    const balance = useAppSelector((state) => state.profile.data?.balance);
    const photo = useAppSelector((state) => state.profile.data?.photo);
    const profileSBNavData = profileSidebarNavigation(balance ?? 0);
    const handleLogout = useLogout();

    useEffect(() => {
        active ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "visible");
    }, [active]);

    const handleUserLogout = useCallback(() => {
        onClose();
        void handleLogout();
    }, [handleLogout, onClose]);

    return (
        <ProfileSidebarWrapper>
            <CSSTransitionHeader in={active} timeout={duration} unmountOnExit classNames={headerTransitionName}>
                <SidebarHeaderWrapper className={headerTransitionName}>
                    <SidebarHeader>
                        <ButtonStyled
                            variant="transparent"
                            className="profile-btn"
                            prefixIcon={<Avatar src={photo} size={4} />}
                        >
                            <Text size="text16" weight="medium" tag="p">
                                {nickname || ""}
                            </Text>
                        </ButtonStyled>
                        <Hamburger active={active} onClose={onClose} />
                    </SidebarHeader>
                </SidebarHeaderWrapper>
            </CSSTransitionHeader>
            <CSSTransitionSidebar in={active} timeout={duration} unmountOnExit classNames={profileSidebarClassname}>
                <ProfileSidebarContent className={profileSidebarClassname}>
                    <ScrollbarsStyled
                        renderTrackVertical={(props) => (
                            <ScrollbarTrackVertical {...props} className="track-vertical" />
                        )}
                        renderThumbVertical={(props) => (
                            <ScrollbarThumbVertical {...props} className="thumb-vertical" />
                        )}
                        renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" />}
                        renderView={(props) => <div {...props} className="view" />}
                        autoHide
                    >
                        <NavigationWrapper>
                            <NavigationSidebar navigationItems={profileSBNavData} onClose={onClose} />
                            <LogoutButton variant="transparent" className="mt16--tablet" onClick={handleUserLogout}>
                                <Text size="text16" weight="medium">
                                    Выйти из аккаунта
                                </Text>
                            </LogoutButton>
                        </NavigationWrapper>
                    </ScrollbarsStyled>
                </ProfileSidebarContent>
            </CSSTransitionSidebar>
            {active && <SidebarBackground onClick={onClose} />}
        </ProfileSidebarWrapper>
    );
};

export default ProfileSidebar;
