import React, { useCallback } from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import Table from "~molecules/table/table";
import { useGetHistory } from "~pages/lottery/bingo-lottery/_hooks/useGetHistory";
import { bingoAlias, getDetailsHistoryBingo75 } from "~constants/routes";
import { ticketsBingoType } from "~constants/tickets-type";
import useAppHistory from "~hooks/useAppHistory";
import { scheme } from "./scheme";
import { ButtonStyled, HistoryBlock, HistoryContent, HistoryWrapper, SkeletonStyled, SkeletonWrapper } from "./theme";

const History = () => {
    const { historyData, getHistory, isFinished } = useGetHistory(bingoAlias.b75, ticketsBingoType.b75);
    const { go } = useAppHistory();

    const handleClickRow = useCallback(
        (item: Record<string, unknown>) => {
            const { productId, edition } = item;
            go(getDetailsHistoryBingo75(productId as string, edition as string));
        },
        [go],
    );

    return (
        <HistoryWrapper>
            <HistoryContent>
                {!!historyData?.size ? (
                    Array.from(historyData).map(([interval, data]) => (
                        <HistoryBlock key={interval} className="history-block">
                            <TitleAdaptive size={"title24"} tag={"h2"} className={"mb16 mb8--mobile"}>
                                {interval}
                            </TitleAdaptive>
                            <Table data={data} scheme={scheme} onClickRow={handleClickRow} tableType="separate" />
                        </HistoryBlock>
                    ))
                ) : (
                    <SkeletonWrapper>
                        {Array(10)
                            .fill(0)
                            .map((_, idx) => (
                                <SkeletonStyled key={idx} className="mt16" />
                            ))}
                    </SkeletonWrapper>
                )}
            </HistoryContent>
            <ButtonStyled
                size="large"
                variant="secondary"
                className="mb32 mb24--tablet mb16--mobile mt64 mt48--tablet mt32--mobile mauto"
                onClick={getHistory}
                visible={!isFinished}
            >
                <TextAdaptive size="text16" weight="demiBold">
                    Показать еще
                </TextAdaptive>
            </ButtonStyled>
        </HistoryWrapper>
    );
};

export default History;
