import { Dispatch } from "react";
import {
    ACTIONS_TYPE,
    TActionAddNotificationAction,
    TMessage,
    TRemoveNotificationAction,
} from "~store/notification/types";

export const actionRemoveNotification = (id: number) => (dispatch: Dispatch<TRemoveNotificationAction>) => {
    dispatch({
        type: ACTIONS_TYPE.REMOVE,
        payload: id,
    });
};

export const actionAddNotification = (msg: TMessage) => (dispatch: Dispatch<TActionAddNotificationAction>) => {
    dispatch({
        type: ACTIONS_TYPE.ADD,
        payload: msg,
    });
};
