import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { isDateExpired } from "~helpers/format";
import { TEdition } from "~store/tickets/types";
import { ModalBody, ModalControls, SelectModalBlockWrapper, SelectModalItem, SkeletonStyled } from "./theme";

type TSelectModalBlockViewProps = {
    title?: string;
    typeItem?: "checkbox" | "radio";
    editions: TEdition[];
    editionsSelected: TEdition[];
    handleClose: () => void;
    handleSelect: () => void;
    handleChange: (edition: string, isSelected: boolean) => void;
    editionsExpired?: boolean;
};

const SelectModalBlockView = ({
    title,
    editions,
    editionsSelected,
    handleChange,
    handleClose,
    handleSelect,
    editionsExpired,
    typeItem,
}: TSelectModalBlockViewProps) => {
    const { color } = useContext(ThemeContext);

    if (editionsExpired) {
        return (
            <SelectModalBlockWrapper>
                {Array(6)
                    .fill(0)
                    .map((_, index) => (
                        <SkeletonStyled key={index} className="mt16" />
                    ))}
            </SelectModalBlockWrapper>
        );
    }

    return (
        <SelectModalBlockWrapper>
            <TitleAdaptive size="title24" tag="span" color={color.text.primary}>
                {title}
            </TitleAdaptive>
            <ModalBody>
                {!!editions.length
                    ? editions.map(({ saleDate, drawDate, edition }) => (
                          <SelectModalItem
                              typeItem={typeItem}
                              date={drawDate}
                              edition={edition}
                              isSelected={!!editionsSelected.find((item) => item.edition === edition)}
                              key={edition}
                              className={"mt12 mb12"}
                              onChange={handleChange}
                              disabled={isDateExpired(saleDate)}
                          />
                      ))
                    : Array(6)
                          .fill(0)
                          .map((_, index) => <SkeletonStyled key={index} className="mt16" />)}
            </ModalBody>
            <ModalControls>
                <ButtonAdaptive variant="secondary" onClick={handleClose} className="mr24">
                    Отмена
                </ButtonAdaptive>
                <ButtonAdaptive variant="primary" onClick={handleSelect}>
                    Выбрать
                </ButtonAdaptive>
            </ModalControls>
        </SelectModalBlockWrapper>
    );
};

export default SelectModalBlockView;
