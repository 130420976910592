import styled from "styled-components";
import { WHITE } from "~tokens/colors";
import { shadowTabBar } from "~tokens/shadows";

const TabBarWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${WHITE};
    ${shadowTabBar};
`;

export { TabBarWrapper };
