import { getRoundsArrayDigital } from "~adapters/products";
import { getTicketNumber, getDateFromISO8601 } from "~helpers/format";
import { TAddedTickets, TMyTicket } from "~types/my-tickets";
import { TPaginationInfo } from "~types/utils";

export const fromAddedTickesData = (res: TAddedTickets) => {
    const { state, tickets } = res;

    for (let i = 0; i < state.totalCount; i++) {
        if (tickets[i]) {
            tickets[i] = {
                ...tickets[i],
                ticketNumber: getTicketNumber(tickets[i].ticketNumber as string),
            };
        } else {
            tickets.push({});
        }
    }
    return {
        state: {
            ...state,
            cancelDate: getDateFromISO8601(state.cancelDate),
            bonusDate: getDateFromISO8601(state.bonusDate),
        },
        tickets,
    };
};

export const fromTicketsArrayData = (arr: TMyTicket[] | []) => {
    return arr.map((item) => {
        return {
            ...item,
            numbers: item.combination ? getRoundsArrayDigital(item.combination.main, item.combination.sub) : [],
            numbersWin:
                item.winCombination && item.winCombination.length
                    ? getRoundsArrayDigital(item.winCombination[0].main, item.winCombination[0].sub)
                    : [],
            buyDate: item.buyDate ? getDateFromISO8601(item.buyDate, "D MMMM YYYY") : "-",
            draw: {
                ...item.draw,
                time: getDateFromISO8601(item.draw.drawDate, "D MMMM, в HH:mm"),
            },
        };
    });
};

export const getParsedTicket = (obj: TMyTicket) => {
    return fromTicketsArrayData([obj])[0];
};

export const getPagiObj = <T>(result: T & TPaginationInfo) => {
    const { number, totalPages, size, totalElements } = result;
    return {
        number,
        size,
        totalPages,
        totalElements,
    };
};
