import styled, { DefaultTheme } from "styled-components";
import { TTableTypes } from "~molecules/table/table";
import { GREY_DARK } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeightNormal } from "~tokens/typography";

export const getTableMobileStyles = (type: TTableTypes, theme: DefaultTheme) => {
    switch (type) {
        case "separate": {
            return `
            	${adaptive.maxWidth.tablet} {
            		thead {
            			display: none;
        			}
        			tr {
           			 	display: block;
            			&:not(:last-child) {
            		   	 	margin-bottom: ${INDENT.xxxs};
           				 }
        			}
        			td {
            			display: block;
            			padding: ${INDENT.xxxs} ${INDENT.none};
            			color: ${theme.color.text.primary};
            			&:before {
            		   	 	content: attr(data-label);
            		    	${fontWeightNormal};
            		    	display: inline-block;
            		    	color: ${GREY_DARK};
            		    	margin-bottom: ${INDENT.xxxxs};
            			}
            			&:last-child {
            		    	margin-bottom: ${INDENT.xxxs};
            			}
        			}
        			}
       		`;
        }
        case "solid": {
            return `
				.solid-table-header {
        			display: none;
    			}
    			${adaptive.maxWidth.tablet} {
      			  thead {
           			  display: none;
        		  }
       			  tbody {
           			  display: flex;
            	      padding-bottom: ${INDENT.xxs};
        	      }
        		  .solid-table-header {
            		  display: flex;
            		  flex-direction: column;
            		  width: max-content;
            		  pointer-events: none;
            	  	  th {
                		color: ${theme.color.text.secondary};
        		      }
        		  }
        		  td {
        		      text-align: center;
        		  }
        		  td,
        		  th {
        		      height: 52px;
        		      flex-shrink: 0;
        		      border-bottom: 1px solid ${theme.color.background.stroke};
        		  }
        		  tr {
        		      display: flex;
        		      border: none;
        		      flex-direction: column;
        		  }
        		  tr:not(:first-child) {
        		      width: 80px;
            		  overflow: auto;
        		  }
    		  }
			`;
        }
        default: {
            return;
        }
    }
};

export const TableWrapper = styled.div<{ tableType: TTableTypes }>`
    display: block;
    ${adaptive.maxWidth.tablet} {
        overflow: ${({ tableType }) => (tableType === "solid" ? "auto" : "unset")};
    }
`;

export const TableStyled = styled.table<{ tableType: TTableTypes }>`
    border-collapse: collapse;
    border-spacing: 10px;
    width: 100%;
    tr {
        border-bottom: 1px solid ${({ theme }) => theme.color.background.stroke};
    }

    tbody {
        tr {
            &:hover {
                background: ${({ theme }) => theme.color.const.owrlDarken["50"]};
            }
        }
    }
    thead tr {
        vertical-align: top;
    }
    th,
    td {
        padding: ${INDENT.xs} ${INDENT.none};
        text-align: left;
    }
    ${({ tableType, theme }) => getTableMobileStyles(tableType, theme)}
`;
