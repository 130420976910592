import styled from "styled-components";
import {
    avatarIcon,
    avatarIconFilled,
    basketIcon,
    basketIconFilled,
    cupIcon,
    cupIconFilled,
    homeIcon,
    homeIconFilled,
    ticketIcon,
    ticketIconFilled,
    walletIcon,
    walletIconFilled,
} from "~assets/svg/icons";
import { BLACK, PURPLE } from "~tokens/colors";
import { INDENT } from "~tokens/sizes";

const NavigationSidebarWrapper = styled.nav`
    display: flex;
    align-items: center;
`;

const LinkList = styled.div`
    display: flex;
    flex-flow: column nowrap;
    max-width: 328px;
    width: 100%;

    & > .sidebar-link {
        display: flex;
        flex-flow: row;
        transition: 0.3s ease;
        justify-content: flex-start;
        align-items: center;
        color: ${BLACK};
        margin-bottom: ${INDENT.xs};

        &:last-child {
            margin-bottom: ${INDENT.none};
        }

        & p {
            white-space: nowrap;
        }

        & .avatar-icon {
            ${avatarIcon}
        }

        & .wallet-icon {
            ${walletIcon};
        }

        & .tickets-icon {
            ${ticketIcon};
        }

        & .home-icon {
            ${homeIcon};
        }

        & .cup-icon {
            ${cupIcon};
        }

        & .basket-icon {
            ${basketIcon};
        }
    }

    & > .sidebar-link.active {
        & p {
            color: ${PURPLE};
        }

        & .avatar-icon {
            ${avatarIconFilled}
        }

        & .wallet-icon {
            ${walletIconFilled};
        }

        & .tickets-icon {
            ${ticketIconFilled};
        }

        & .home-icon {
            ${homeIconFilled};
        }

        & .cup-icon {
            ${cupIconFilled};
        }

        & .basket-icon {
            ${basketIconFilled};
        }
    }
`;

export { LinkList, NavigationSidebarWrapper };
