import React, { ReactNode, useCallback, useContext } from "react";
import { ThemeContext } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TableStyled, TableWrapper } from "./theme";

export type TTableTypes = "separate" | "solid";

type TTableProps = {
    className?: string;
    scheme: { id: string; name: string; width?: string; getValue: (value: any) => ReactNode }[];
    data?: Record<string, unknown>[];
    onClickRow?: (item: Record<string, unknown>) => void;
    tableType: TTableTypes;
};

const Table = ({ className, scheme, data, onClickRow, tableType }: TTableProps) => {
    const { color } = useContext(ThemeContext);
    const handleClickRow = useCallback(
        (item: Record<string, unknown>) => {
            onClickRow && onClickRow(item);
        },
        [onClickRow],
    );
    return (
        <TableWrapper className={className} tableType={tableType}>
            <TableStyled tableType={tableType}>
                <thead>
                    <tr>
                        {scheme.map(({ id, name, width = "auto" }) => (
                            <th key={id} style={{ width }}>
                                <TextAdaptive color={color.text.tertiary} size={"text16"}>
                                    {name}
                                </TextAdaptive>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableType === "solid" && (
                        <tr className={"solid-table-header"}>
                            {scheme.map(({ id, name }) => (
                                <th key={id}>{name}</th>
                            ))}
                        </tr>
                    )}
                    {data?.map((item, idx) => (
                        <tr key={idx} onClick={handleClickRow.bind(null, item)}>
                            {scheme.map(({ id, getValue, name }) => (
                                <td key={id} data-label={name}>
                                    {getValue(item[id])}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </TableStyled>
        </TableWrapper>
    );
};

export default Table;
