import styled from "styled-components";
import { fieldsOffset, mainContentWrapper } from "~assets/styles/utils";
import { logoHeaderIcon, logoName } from "~assets/svg/icons";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { shadowHeader } from "~tokens/shadows";
import { INDENT } from "~tokens/sizes";

const HeaderWrapper = styled.header`
    position: fixed;
    top: 0;
    height: 80px;
    width: 100vw;

    ${adaptive.maxWidth.desktopXXL} {
        height: 56px;
    }
    ${adaptive.maxWidth.tablet} {
        height: 48px;
    }
`;

const HeaderBackground = styled.div`
    background-color: ${WHITE};
    ${shadowHeader};
    width: 100%;
    height: 100%;
`;

const HamburgerMenu = styled.div`
    display: none;
    margin-right: ${INDENT.m};
    ${adaptive.maxWidth.desktopXL} {
        display: block;
    }
    ${adaptive.maxWidth.tablet} {
        margin-right: ${INDENT.sm};
    }
`;

const HeaderMainContent = styled.section`
    padding: ${INDENT.s} ${INDENT.none};
    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.xxxs} ${INDENT.none};
    }
    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xxxxs} ${INDENT.none};
    }
    display: flex;
    align-items: center;
    ${mainContentWrapper}
    ${fieldsOffset}
`;

const LinkLogo = styled.a`
    background-color: transparent;
    text-decoration: none;
    flex-shrink: 0;
    & .logo-header {
        display: flex;
        align-items: center;
    }
    & .logo-icon {
        ${logoHeaderIcon};
    }
    & .logo-name {
        margin: 0 20px 0 8px;
        ${logoName};
        ${adaptive.maxWidth.desktopXL} {
            display: none;
        }
    }
    & .logo-description {
        ${adaptive.maxWidth.desktopXL} {
            display: none;
        }
    }
`;

const HeaderContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${adaptive.maxWidth.desktopXL} {
        .header-nav {
            display: none;
        }
        .actions-controls .dropdown-prefix-icon {
            height: 36px;
            width: 36px;
        }
        justify-content: flex-end;
    }
    ${adaptive.maxWidth.tablet} {
        &&& {
            margin: 0;
        }
        .header-nav {
            display: none;
        }
        & > .actions-controls {
            .cash-btn,
            .ticket-btn,
            .basket-btn,
            .dropdown-btn .dropdown-text-target {
                display: none;
            }
        }
        & > .actions-controls {
            flex-basis: 82px;
            & > a,
            & > button {
                margin: 0;
            }
        }
    }
`;

export { HeaderWrapper, HamburgerMenu, HeaderBackground, HeaderMainContent, HeaderContent, LinkLogo };
