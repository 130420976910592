import React, { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { transitionDefault } from "~assets/styles/utils";
import { routes } from "~pages/routes";
import { PURPLE } from "~tokens/colors";
import { INDENT } from "~tokens/sizes";
import { text16 } from "~tokens/typography";

type TNavigationHeaderProps = {
    className?: string;
};

const NavigationHeaderWrapper = styled.nav`
    display: flex;
    align-items: center;
`;

export const LinkList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const LinkListItem = styled.li`
    & > .header-link {
        white-space: nowrap;
        ${text16};
        padding: ${INDENT.xxs};
        color: ${({ theme }) => theme.color.text.primary};
        ${transitionDefault};

        &:hover {
            color: ${PURPLE};
        }
    }

    & > .header-link.active {
        color: ${PURPLE};
    }
`;

const NavigationHeader = ({ className }: PropsWithChildren<TNavigationHeaderProps>) => {
    return (
        <NavigationHeaderWrapper className={className} role="navigation">
            <LinkList>
                {routes.map(({ title, url }) => (
                    <LinkListItem key={url} itemProp="name">
                        <NavLink to={url} itemProp="url" className={"header-link"}>
                            {title}
                        </NavLink>
                    </LinkListItem>
                ))}
            </LinkList>
        </NavigationHeaderWrapper>
    );
};

export default NavigationHeader;
