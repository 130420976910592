import { isTrue, passwordValidation, phoneValidation } from "~helpers/validation";
import { agreementStatus, passwordStatus, phoneStatus } from "~constants/error-messages";

export const phoneValidationMessage = (value: string) => {
    if (value === "") {
        return phoneStatus.PHONE_REQUIRED.value;
    } else if (!phoneValidation(value)) {
        return phoneStatus.NOT_CORRECT_PHONE.value;
    }
};

export const agreement = (value: boolean | null, errors: Record<string, unknown>) => {
    if (!isTrue(value)) {
        errors.agreement = agreementStatus.NOT_CORRECT_AGREEMENT.value;
    }
};

export const agreementValidationMessage = (value: boolean | null) => {
    if (!isTrue(value)) {
        return agreementStatus.NOT_CORRECT_AGREEMENT.value;
    }
};

export const passwordValidationMessage = (value: string) => {
    if (!value || value.length < 8) {
        return passwordStatus.WRONG_MIN_LENGTH.value;
    } else if (value.length > 20) {
        return passwordStatus.WRONG_MAX_LENGTH.value;
    } else if (!passwordValidation(value)) {
        return passwordStatus.NOT_CORRECT_PASS.value;
    }
};

export const comparePasswordValidationMessage = (pass1: string, pass2: string) => {
    if (pass1 !== pass2) {
        return passwordStatus.DO_NOT_MATCH.value;
    }
};
