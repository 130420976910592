import styled from "styled-components";
import { CardBox } from "~atoms/box";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Text } from "~atoms/text";
import { Title } from "~atoms/title";
import { INDENT } from "~tokens/sizes";
import { fontWeight600, fontWeight500, text16M, text18 } from "~tokens/typography";

export const GiftTicketBarWrapper = styled(CardBox)<{ mobile?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 100px;
    width: 100%;
    padding: ${INDENT.xxs} ${INDENT.sm};

    ${({ mobile }) =>
        mobile &&
        `
        min-height: 300px;
        min-width: 240px;
        padding: ${INDENT.m};
        justify-content: flex-start;
        flex-direction: column;
  `}
`;

export const ContentWrapper = styled.div<{ mobile?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 392px;
    width: 100%;

    ${({ mobile }) =>
        mobile &&
        `
    flex-direction: column;
        align-items: flex-start;
  `}
`;

export const TextWrapper = styled.div`
    max-width: 315px;
    width: 100%;
`;

export const TitleStyled = styled(Title)<{ mobile?: boolean }>`
    margin-bottom: ${INDENT.xxxs};
    ${fontWeight600}
    ${({ mobile }) => mobile && text18}
    ${({ mobile }) => mobile && fontWeight500}
`;

export const TextStyled = styled(Text)`
    &&& {
        ${fontWeight500};
    }
`;

export const IconWrapper = styled.div<{ mobile?: boolean }>`
    display: flex;
    color: ${({ theme }) => theme.color.icons.primary};
    justify-content: center;
    margin-right: ${INDENT.xs};
    width: 64px;
    height: 64px;
    background: ${({ theme }) => theme.color.controls.primary.default};
    align-items: center;
    border-radius: 50%;
    ${({ mobile }) =>
        mobile &&
        `
    margin-bottom: ${INDENT.xs};
  `}
`;

export const ButtonStyled = styled(ButtonAdaptive)<{ mobile?: boolean }>`
    ${({ mobile }) =>
        mobile &&
        `
        padding: ${INDENT.xs} ${INDENT.m};
        ${text16M};
        margin-top: ${INDENT.sm};
        align-self: flex-start;
  `}
`;
