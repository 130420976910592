import React from "react";
import defaultAvatar from "./assets/default.svg";
import { AvatarStyled } from "./theme";

export type TSize = 1 | 2 | 3 | 4;

type TAvatarProps = {
    className?: string;
    src?: string | null;
    size: TSize;
};

const Avatar = ({ src, size, className }: TAvatarProps) => {
    return <AvatarStyled className={className} src={src ?? defaultAvatar} alt="Аватар" size={size} />;
};

export default Avatar;
