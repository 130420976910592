import { AxiosInstance } from "axios";
import { createAuthApi } from "~api/auth";
import { createBasketApi } from "~api/basket";
import { createConfigurationApi } from "~api/configuration";
import { createMyTicketsApi } from "~api/my-tickets";
import { createOrderApi } from "~api/order";
import { createPoliticsApi } from "~api/politics";
import { createProductsApi } from "~api/products";
import { createProfileApi } from "~api/profile";
import { createWalletApi } from "~api/wallet";
import { createWinnersApi } from "~api/winners";

export const initApi = (http: AxiosInstance) => ({
    auth: createAuthApi(http),
    configuration: createConfigurationApi(http),
    profile: createProfileApi(http),
    myTickets: createMyTicketsApi(http),
    products: createProductsApi(http),
    winners: createWinnersApi(http),
    basket: createBasketApi(),
    order: createOrderApi(http),
    politics: createPoliticsApi(http),
    wallet: createWalletApi(http),
});

export type TAppApi = ReturnType<typeof initApi>;
