import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { getPrize, mainPrize } from "~adapters/products";
import { Text } from "~atoms/text";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { LotteryRulesHeader } from "~organisms/lottery-rules-header";
import { LegalInfo } from "~pages/lottery/common/legal-info";
import { legalText } from "~constants/handbook";
import { ticketsAlias } from "~constants/routes";
import { ticketsDigitalType } from "~constants/tickets-type";
import { useAppSelector } from "~hooks/useAppSelector";
import { TableRules5in50 } from "./table-rules-5in50";
import { ContentWrapper, RulesWrapper } from "./theme";
import { ParagraphWrapper } from "../../common/rules/theme";

type TRulesProps = {
    isActive: boolean;
};

const superPriceLottery = {
    name: "Мечталион (5 из 50)",
    type: "Числовая",
    prizeFund: "60%",
    mainPrize: getPrize(ticketsAlias.t5in50, mainPrize[ticketsAlias.t5in50]),
    frequency: "Каждую субботу",
};

const Rules = ({ isActive }: TRulesProps) => {
    // const jackpot = useAppSelector((state) => state.tickets[ticketsDigitalType.t5in50].jackpot);
    const amount = useAppSelector((state) => state.tickets[ticketsDigitalType.t5in50].data[0]?.amount);
    const { color } = useContext(ThemeContext);
    return (
        <RulesWrapper>
            <ContentWrapper>
                {/*{jackpot !== null && amount !== null ? (*/}
                {/*    <BannerRules5in50 jackpot={jackpot} amount={amount} className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*) : (*/}
                {/*    <SkeletonStyled className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*)}*/}
                {isActive && (
                    <LotteryRulesHeader
                        name={superPriceLottery.name}
                        type={superPriceLottery.type}
                        prizeFund={superPriceLottery.prizeFund}
                        ticketPrice={amount}
                        mainPrize={superPriceLottery.mainPrize}
                        frequency={superPriceLottery.frequency}
                        className={"mb64 mb48--tablet"}
                    />
                )}
                {/*<TitleAdaptive tag={"div"} size={"title24"} weight={"demiBold"} className={"mb24 mb16--mobile"}>*/}
                {/*    Что можно выиграть?*/}
                {/*</TitleAdaptive>*/}
                {/*<TextAdaptive size={"text16"} weight={"medium"} className={"mb24 mb16--mobile"}>*/}
                {/*    Минимальный гарантированный суперприз — ХХХ ХХХ ХХХ рублей. Чтобы его выиграть, нужно угадать пять*/}
                {/*    чисел в поле 1 и два числа в поле 2.*/}
                {/*</TextAdaptive>*/}
                {/*<TableRules5in50 className={"mb16"} />*/}
                {/*<Text size="text14" color={GREY_DARK} className="mb4">*/}
                {/*    * Не менее 200 ₽ на выигрышный билет*/}
                {/*</Text>*/}
                {/*<Text size="text14" color={GREY_DARK} className="mb64 mb48--tablet">*/}
                {/*    ** Не менее 100 ₽ на выигрышный билет*/}
                {/*</Text>*/}
                <div className={"mb24 mb16--mobile"}>
                    <TitleAdaptive tag={"div"} size={"title24"} weight={"medium"} className={"mb24 mb16--mobile"}>
                        Как играть?
                    </TitleAdaptive>
                    <TextAdaptive size={"text16"} className={"mb24 mb16--mobile"}>
                        В лотерейном билете два игровых поля.
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} className={"mb24 mb16--mobile"}>
                        Выберите пять неповторяющихся чисел от 1 до 50 в первом поле и два неповторяющихся числа от 1 до
                        8 во втором поле.
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} className={"mb24 mb16--mobile"}>
                        Оплатите выбранную комбинацию, чтобы принять участие в розыгрыше.
                    </TextAdaptive>
                    <TextAdaptive size={"text16"}>
                        Ваш билет выиграл, если выбранные Вами числа полностью или частично совпали с выигрышной
                        комбинацией:
                    </TextAdaptive>
                </div>
                <TableRules5in50 className={"mb24"} />
                <Text size="text14" color={color.text.tertiary} className="mb24">
                    ¹ Минимальный гарантированный Суперприз — {superPriceLottery.mainPrize} — делится поровну между
                    всеми выигрышными билетами по данной категории
                </Text>
                <Text size="text14" color={color.text.tertiary} className="mb64 mb48--tablet">
                    ² Сумма выигрыша делится поровну между всеми выигрышными билетами в рамках категории.
                </Text>
                <ParagraphWrapper>
                    <LegalInfo
                        type="DIGITAL"
                        prizeInfo={legalText["digital-5x50"].prizeInfo}
                        algoInfo={legalText["digital-5x50"].algoInfo}
                    />
                </ParagraphWrapper>
            </ContentWrapper>
        </RulesWrapper>
    );
};

export default Rules;
