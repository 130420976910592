import React from "react";
import styled from "styled-components";
import { Col } from "./col";
import { TColComponent } from "./col/col";
import { Row } from "./row";
import { TRowComponent } from "./row/row";

type TGridProps = {
    children?: React.ReactNode;
    className?: string;
};

const GridWrapper = styled.div`
    display: block;
`;

const Grid: React.FC<TGridProps> & {
    Col: TColComponent;
    Row: TRowComponent;
} = ({ children, className }: TGridProps) => {
    return <GridWrapper className={className}>{children}</GridWrapper>;
};

Grid.Col = Col;
Grid.Row = Row;

export default Grid;
