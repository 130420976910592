import { ABOUT, LOTTERY } from "~constants/routes";

export const crumbs = [
    {
        title: "Лотереи",
        path: LOTTERY,
        exact: true,
    },
    {
        title: "О компании",
        path: ABOUT,
        exact: true,
    },
];
