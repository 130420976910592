import * as auth from "./auth";
import * as basket from "./basket";
import * as myTickets from "./my-tickets";
import * as order from "./order";
import * as products from "./products";
import * as profile from "./profile";
import * as winners from "./winners";

export const adapters = {
    auth,
    profile,
    products,
    myTickets,
    winners,
    order,
    basket,
};
