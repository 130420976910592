import React, { PropsWithChildren } from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { WHITE } from "~tokens/colors";
import { TipWrapper } from "./theme";

type TTipProps = {
    className?: string;
    disabled?: boolean;
    onClick: () => void;
};

const Tip = ({ children, className, disabled = false, onClick }: PropsWithChildren<TTipProps>) => {
    return (
        <TipWrapper className={className} disabled={disabled} onClick={onClick}>
            <TextAdaptive size="text16" weight="demiBold" color={WHITE} tag="span">
                {children}
            </TextAdaptive>
        </TipWrapper>
    );
};

export default Tip;
