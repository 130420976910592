import React, { useState } from "react";
import { Button } from "~atoms/button";
import { Link } from "~atoms/link";
import { Text } from "~atoms/text";
import { COOKIES_ACCEPTING } from "~constants/routes";
import { CookiesAcceptWrapper } from "./theme";

const CookiesAlert = () => {
    const [isCookies, setAcceptCookies] = useState(localStorage.getItem("isCookies"));

    const onAccept = () => {
        setAcceptCookies(Date());
        localStorage.setItem("isCookies", Date());
    };

    if (isCookies) {
        return null;
    }

    return (
        <CookiesAcceptWrapper>
            <Text size={2}>
                Для повышения удобства работы с личным кабинетом используются файлы{" "}
                <Link href={COOKIES_ACCEPTING}>cookie.</Link> В cookie содержатся данные о прошлых посещениях сайта.
                Если вы не хотите, чтобы эти данные обрабатывались, отключите cookie в настройках браузера.
            </Text>
            <Button onClick={onAccept}>Принять и закрыть</Button>
        </CookiesAcceptWrapper>
    );
};

export default CookiesAlert;
