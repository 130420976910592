import {
    MY_TICKETS,
    MY_TICKETS_UPCOMING,
    MY_TICKETS_WITH_WIN,
    MY_TICKETS_WITHOUT_WIN,
    LOTTERY,
} from "~constants/routes";

const tabs = [
    {
        id: MY_TICKETS_UPCOMING,
        title: "Будущие розыгрыши",
    },
    {
        id: MY_TICKETS_WITH_WIN,
        title: "C выигрышем",
    },
    {
        id: MY_TICKETS_WITHOUT_WIN,
        title: "Без выигрыша",
    },
];

const crumbs = [
    {
        title: "Главная",
        path: LOTTERY,
    },
    {
        title: "Билеты",
        path: MY_TICKETS,
    },
];

export { tabs, crumbs };
