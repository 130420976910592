import styled from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { commonWrapperModalWindow } from "~organisms/buy-tickets-card/theme";
import { GREEN, GREY_LIGHT, PRIMARY_BLUE, RED, WHITE } from "~tokens/colors";
import { INDENT } from "~tokens/sizes";

export const widgetColors = {
    body: {
        backgroundColor: "#F1F3F9",
    },
    header: {
        backgroundColor: "#FFFFFF",
        color: "#070728",
        success: {
            backgroundColor: "rgb(10, 134, 43)",
            color: "#070728",
        },
        error: {
            backgroundColor: "rgb(184, 32, 32)",
            color: "#070728",
        },
    },
    main: {
        description: {
            backgroundColor: WHITE,
        },
        loader: {
            type: "nationallottery",
        },
        pockets: {
            layout: {
                backgroundColor: WHITE,
            },
            field: {
                backgroundColor: WHITE,
                checkbox: {
                    primary: {
                        borderColor: "#DCDBE1",
                        checked: {
                            borderColor: "transparent",
                            backgroundColor: "#1D154F",
                        },
                    },
                },
                input: {
                    primary: {
                        placeholder: "#838192",
                        borderColor: "#DCDBE1",
                        fill: "#838192",
                        color: "#070728",
                        hover: {
                            fill: "#838192",
                        },
                        focus: {
                            borderColor: "#838192",
                            fill: "#838192",
                            valid: {
                                borderColor: GREEN,
                                fill: "#838192",
                            },
                            invalid: {
                                borderColor: RED,
                                fill: "#838192",
                            },
                        },
                        input: {
                            borderColor: GREY_LIGHT,
                            fill: "#838192",
                            valid: {
                                borderColor: GREEN,
                                fill: "#838192",
                            },
                            invalid: {
                                borderColor: RED,
                                fill: "#838192",
                            },
                        },
                        blur: {
                            borderColor: GREY_LIGHT,
                            fill: "#838192",
                            valid: {
                                borderColor: GREEN,
                                fill: "#838192",
                            },
                            invalid: {
                                borderColor: RED,
                                fill: "#838192",
                            },
                        },
                        valid: {
                            borderColor: GREEN,
                            fill: "#838192",
                        },
                        invalid: {
                            borderColor: RED,
                            fill: "#838192",
                        },
                    },
                },
            },
            button: {
                contained: {
                    default: {
                        color: WHITE,
                        backgroundColor: PRIMARY_BLUE,
                        borderColor: "transparent",
                        borderRadius: "4px",
                        hover: {
                            color: WHITE,
                        },
                    },
                    primary: {
                        color: WHITE,
                        backgroundColor: PRIMARY_BLUE,
                        borderColor: "transparent",
                        borderRadius: "4px",
                        hover: {
                            backgroundColor: PRIMARY_BLUE,
                        },
                    },
                },
                text: {
                    default: {
                        color: "rgba(56, 57, 60, 0.6)",
                        backgroundColor: "rgba(56, 57, 60, 0)",
                        borderColor: "transparent",
                        borderRadius: "4px",
                        hover: {
                            color: "#38393c",
                            backgroundColor: "rgba(56, 57, 60, 0.08)",
                        },
                    },
                },
                outline: {
                    primary: {
                        color: PRIMARY_BLUE,
                        backgroundColor: "transparent",
                        borderColor: PRIMARY_BLUE,
                        borderRadius: "4px",
                    },
                },
            },
        },
    },
    text: {
        color: {
            primary: "#070728",
            secondary: "#838192",
        },
    },
    icon: {
        color: {
            primary: GREEN,
        },
    },
};

export const WidgetBlockWrapper = styled.div`
    display: block;
    ${commonWrapperModalWindow};
`;

export const PaymentPage = styled.div`
    width: 100%;
    min-height: 430px;
    iframe {
        width: 100%;
        min-height: 430px;
    }
`;

export const ButtonClose = styled(ButtonAdaptive)`
    position: absolute;
    display: flex;
    border: none;
    border-radius: 50%;
    padding: ${INDENT.xxxxs};
    width: 24px;
    height: 24px;
    top: ${INDENT.xxxs};
    right: ${INDENT.xxxs};
`;
