import styled from "styled-components";
import { Text } from "~atoms/text";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const ShortMobileTicketWrapper = styled.a<{ backgroundUrl: string }>`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 38.41%;
    background-image: url(${(props) => props.backgroundUrl});
    background-size: cover;
    background-repeat: no-repeat;
    cursor: default;
`;

export const TicketDescription = styled.div`
    display: flex;
    position: absolute;
    align-items: center;
    align-items: flex-start;
    top: 0px;
    left: 0px;
    height: 62%;
    width: 100%;
    padding: ${INDENT.xxs};
`;

export const TicketDescriptionLogo = styled.img`
    height: 44px;
    ${adaptive.maxWidth.tablet} {
        height: 32px;
    }
`;

export const TicketDescriptionBody = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: ${INDENT.xs};
`;

export const TicketDescriptionTitle = styled(Text)`
    margin-bottom: ${INDENT.xxxxs};
`;

export const TicketDescriptionPrize = styled(Text)`
    margin-bottom: ${INDENT.xxxxs};
`;

export const TicketDescriptionSlogan = styled(Text)`
    max-width: 220px;
`;

export const TicketActionZone = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 38%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${INDENT.xxxs};
`;

export const TicketActionZoneEdition = styled(Text)`
    margin-bottom: ${INDENT.xxxxs};
`;

export const TicketBuyZone = styled.div`
    display: flex;
    align-items: center;
`;

export const TicketActionZoneAmount = styled(Text)`
    padding-right: ${INDENT.xs};
`;

export const TicketActionZonePlaceholder = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: ${INDENT.none} ${INDENT.xxxxs};
    flex-grow: 1;
`;
