import React from "react";
import styled from "styled-components";
import { fieldsOffset } from "~assets/styles/utils";
import { Button } from "~atoms/button";
import { Text } from "~atoms/text";
import { WHITE } from "~tokens/colors";
import { normalize } from "~tokens/normalize";
import { shadow100 } from "~tokens/shadows";
import { INDENT } from "~tokens/sizes";

const FallbackPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: 100vh;
    ${fieldsOffset};
`;

const DivStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${INDENT.l} ${INDENT.l};
    max-width: 500px;
    width: 100%;
    border-radius: 8px;
    background: ${WHITE};
    ${shadow100};
    ${normalize};
    p {
        margin-bottom: ${INDENT.sm};
    }
`;

export default function FallbackPage() {
    return (
        <FallbackPageWrapper>
            <DivStyled>
                <Text weight={"demiBold"}>Не получилось, попробуйте ещё раз</Text>
                <Text>
                    Мы уже знаем об ошибке и скоро во всём разберёмся. Если это был небольшой сбой или проблема с
                    интернетом — прямо сейчас поможет перезагрузка.
                </Text>
                <Button onClick={() => window.location.reload()}>
                    <Text tag={"span"} color={WHITE} size={"text16"}>
                        Перезагрузить страницу
                    </Text>
                </Button>
            </DivStyled>
        </FallbackPageWrapper>
    );
}
