import styled, { css, SimpleInterpolation } from "styled-components";
import { TTitleAdaptiveSize } from "~atoms/title-adaptive/title-adaptive";
import { adaptive } from "~tokens/screen";
import { fontWeight600, title12, title16, title18, title20, title24, title32, title38 } from "~tokens/typography";

export const getSize = (size: TTitleAdaptiveSize): SimpleInterpolation => {
    switch (size) {
        case "title12": {
            return css`
                ${title12}
            `;
        }
        case "title16": {
            return css`
                ${title16}
                ${fontWeight600}
            `;
        }
        case "title20": {
            return css`
                ${title20}
                &&& {
                    ${fontWeight600}
                }
                ${adaptive.maxWidth.tablet} {
                    ${title18}
                }
            `;
        }
        case "title24": {
            return css`
                ${title24}
                &&& {
                    ${fontWeight600}
                }
                ${adaptive.maxWidth.tablet} {
                    ${title20}
                }
            `;
        }
        case "title38": {
            return css`
                ${title38}
                &&& {
                    ${fontWeight600};
                }
                ${adaptive.maxWidth.desktopXL} {
                    ${title32}
                }
                ${adaptive.maxWidth.tablet} {
                    ${title24}
                }
            `;
        }
        default: {
            return;
        }
    }
};

export const TitleAdaptiveWrapper = styled.div<{
    size: TTitleAdaptiveSize;
    weight: SimpleInterpolation;
    color?: string;
}>`
    color: ${({ color, theme }) => (color ? color : theme.color.text.headline)};
    ${({ size }) => getSize(size)}
    &&& {
        ${({ weight }) => weight}
    }
`;
