import { LOTTERY, MOMENTARY_1 } from "~constants/routes";

export const crumbs = [
    {
        title: "Лотереи",
        path: LOTTERY,
        exact: true,
    },
    {
        title: "Летим к мечте",
        path: MOMENTARY_1,
        exact: true,
    },
];
