import {
    getDetailsHistory5in36,
    LOTTERY,
    LOTTERY_5_IN_36,
    LOTTERY_5_IN_36_HISTORY,
    LOTTERY_5_IN_36_RULES,
} from "~constants/routes";

const tabs = [
    {
        id: LOTTERY_5_IN_36,
        title: "Билеты",
    },
    {
        id: LOTTERY_5_IN_36_RULES,
        title: "Как играть?",
    },
    {
        id: LOTTERY_5_IN_36_HISTORY,
        title: "История тиражей",
    },
];

const crumbs = (path: string, productId: string, name: string) => {
    const arrCrumbs = [
        {
            title: "Лотереи",
            path: LOTTERY,
            exact: true,
        },
        {
            title: name,
            path: LOTTERY_5_IN_36,
            exact: true,
        },
    ];
    if (path.startsWith(LOTTERY_5_IN_36_HISTORY)) {
        arrCrumbs.push({
            title: "История тиражей",
            path: LOTTERY_5_IN_36_HISTORY,
            exact: true,
        });
    }

    if (path.startsWith(LOTTERY_5_IN_36_HISTORY + "/")) {
        const edition = path.replace(`${LOTTERY_5_IN_36_HISTORY}/${productId}/`, "");
        productId &&
            arrCrumbs.push({
                title: `Результаты тиража № ${edition}`,
                path: getDetailsHistory5in36(productId, edition),
                exact: false,
            });
    }

    if (path.startsWith(LOTTERY_5_IN_36_RULES)) {
        arrCrumbs.push({
            title: "Как играть?",
            path: LOTTERY_5_IN_36_RULES,
            exact: true,
        });
    }

    return arrCrumbs;
};

export { tabs, crumbs };
