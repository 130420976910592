import styled from "styled-components";
import { SECONDARY_GRADIENT } from "~tokens/colors";

export const BackgroundHeadWrapper = styled.div<{
    img: string | null;
    withImg: boolean;
    withDefaultBackground: boolean;
    height: string;
    background?: string;
}>`
    position: absolute;
    z-index: -1;
    width: 100%;
    height: ${({ height }) => height};
    left: 0;
    overflow: hidden;
    background-image: ${({ img, withImg }) => withImg && `url(${img})`};
    background-size: cover;
    background: ${({ background, withDefaultBackground }) => (withDefaultBackground ? SECONDARY_GRADIENT : background)};
`;
