import Scrollbars from "react-custom-scrollbars";
import { CSSTransition } from "react-transition-group";
import styled, { keyframes } from "styled-components";
import { GiftTicketBar } from "~organisms/gift-ticket-bar";
import { SocialMediaArea } from "~organisms/social-media-area";
import { GREY_DARK, GREY_LIGHT, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeight500, lineHeight24, text16M } from "~tokens/typography";

const transitionName = "sidebar-menu";
const duration = 500;

const fadeInAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const CSSTransitionStyled = styled(CSSTransition)`
    &.${transitionName}-enter {
        opacity: 0;
        transform: translateX(-400px);
    }
    &.${transitionName}-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity ${duration}ms, transform ${duration}ms;
    }
    &.${transitionName}-exit {
        opacity: 1;
        transform: translateX(0);
    }
    &.${transitionName}-exit-active {
        opacity: 0;
        transform: translateX(-400px);
        transition: opacity ${duration}ms, transform ${duration}ms;
    }
`;

const SidebarBackground = styled.div`
    position: fixed;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.4);
    animation: ${fadeInAnimation} 0.3s ease;
`;

const SidebarMainContent = styled.div`
    position: fixed;
    z-index: -1;
    max-width: 376px;
    width: 100%;
    top: 0;
    left: 0;
    max-height: calc(100vh - ${INDENT.xxl});
    height: 100%;
    margin-top: ${INDENT.xxl};
    background-color: ${WHITE};
    overflow: hidden;
    filter: drop-shadow(0 0);
    ${adaptive.maxWidth.tablet} {
        max-width: 360px;
        margin-top: ${INDENT.ls};
        max-height: calc(100vh - ${INDENT.ls});
        .gift-sidebar {
            display: none;
        }
    }
`;

const SidebarContent = styled.div`
    display: flex;
    flex-flow: column nowrap;
`;

const SidebarFooter = styled.div`
    width: 100%;
    ${adaptive.maxWidth.tablet} {
        position: relative;
        padding-top: ${INDENT.xs};
        &::before {
            display: block;
            position: absolute;
            top: 0;
            content: "";
            max-width: 328px;
            width: 100%;
            height: 1px;
            background-color: ${GREY_LIGHT};
        }
    }
`;

const NavigationWrapper = styled.div`
    position: relative;
    width: 100%;
    & > .sidebar-info,
    & > .sidebar-support {
        display: block;
    }
    & .collapse-content div {
        margin-bottom: 0;
    }
    padding: ${INDENT.xxxs} ${INDENT.none};
    &::before {
        display: block;
        position: absolute;
        top: 0;
        content: "";
        max-width: 328px;
        width: 100%;
        height: 1px;
        background-color: ${GREY_LIGHT};
    }
    ${adaptive.maxWidth.tablet} {
        padding-top: ${INDENT.none};
        padding-bottom: ${INDENT.xs};
    }
`;

const SocialMediaAreaStyled = styled(SocialMediaArea)`
    ${adaptive.maxWidth.mobile} {
        & ul li {
            margin: 0 4px;
        }
    }
`;

const GiftTicketBarStyled = styled(GiftTicketBar)`
    background: ${GREY_LIGHT};
    padding: ${INDENT.xs};
    min-height: unset;
    min-weight: unset;
    max-height: 192px;
    height: 100%;
    max-width: 328px;
    width: 100%;
    border-radius: 8px;
    & > div {
        position: relative;
    }
    flex-direction: column;
    align-items: flex-start;
    & .gift-icon {
        position: absolute;
        left: 0;
        top: 0;
        margin: ${INDENT.none};
        & > svg {
            height: 32px;
            width: 32px;
        }
    }
    & > button {
        margin-top: ${INDENT.xs};
        padding: ${INDENT.xxs} ${INDENT.sm};
        ${fontWeight500};
    }

    & h3 {
        ${text16M};
        ${lineHeight24};
        margin: ${INDENT.xxxxs} ${INDENT.none} ${INDENT.xxs} ${INDENT.l};
    }
    ${adaptive.maxWidth.tablet} {
        display: none;
    }
`;

const ScrollbarsStyled = styled(Scrollbars)`
    width: 100%;
    height: 100%;
    & > .view {
        padding: ${INDENT.sm} ${INDENT.sm} ${INDENT.xl};
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
    }
    & > .track-vertical {
        height: 100%;
        right: 0;
        & > .thumb-vertical {
            background: ${GREY_DARK};
        }
    }
    & > .track-horizontal {
        display: none;
    }

    ${adaptive.maxWidth.tablet} {
        .view {
            padding: ${INDENT.sm} ${INDENT.xs} ${INDENT.xxl};
        }
    }
`;

const ScrollbarTrackVertical = styled.div`
    width: 8px !important;
    border-radius: 100px;
    padding: 2px;
`;

const ScrollbarThumbVertical = styled.div`
    width: 4px !important;
    border-radius: 100px;
`;

export {
    transitionName,
    duration,
    ScrollbarsStyled,
    SidebarBackground,
    CSSTransitionStyled,
    SidebarContent,
    SidebarFooter,
    SidebarMainContent,
    NavigationWrapper,
    GiftTicketBarStyled,
    SocialMediaAreaStyled,
    ScrollbarThumbVertical,
    ScrollbarTrackVertical,
};
