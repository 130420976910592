import React, { useContext, useEffect } from "react";
import { ThemeContext } from "styled-components";
import { Grid } from "~atoms/grid";
import { useGetProducts } from "~organisms/lottery-info/_hooks/useGetProducts";
import { LotteryTicket } from "~organisms/lottery-ticket";
import { TSizeTicket } from "~organisms/lottery-ticket/lottery-ticket";
import { INDENT } from "~tokens/sizes";
import { useAppScreen } from "~hooks/useAppScreen";
import { useAppSelector } from "~hooks/useAppSelector";
import { GridStyled, SkeletonStyled, TitleBlock } from "./theme";

type TLotteryInfoProps = {
    className?: string;
    title?: boolean;
};

const { Row, Col } = Grid;

const LotteryInfo = ({ className, title = true }: TLotteryInfoProps) => {
    const { handleGetProducts } = useGetProducts();
    const { color } = useContext(ThemeContext);
    const typeScreen = useAppScreen();
    const lotteries = useAppSelector((state) => state.lotteries.data);

    useEffect(() => {
        if (!lotteries.length) void handleGetProducts();
    }, [handleGetProducts, lotteries.length]);

    return (
        <div className={className}>
            {title && (
                <TitleBlock tag="h2" size={1} color={color.text.headline} weight="medium">
                    Лотереи
                </TitleBlock>
            )}
            <GridStyled>
                {!!lotteries.length ? (
                    <Row>
                        {lotteries.map((item, index) => {
                            const meta = item[typeScreen];

                            return (
                                <Col
                                    col={item.desktop.col}
                                    tablet={item.tablet.col}
                                    mobile={item.mobile.col}
                                    key={index}
                                    style={{ marginBottom: INDENT.sm }}
                                >
                                    <LotteryTicket
                                        id={item.id}
                                        productId={item.productId}
                                        type={meta.type as TSizeTicket}
                                        backgroundUrl={meta.url as TSizeTicket}
                                        title={item.title}
                                        prize={item.prize}
                                        logoSrc={item.logoSrc}
                                        note={item.note}
                                        edition={item.edition}
                                        time={item.time}
                                        amount={item.amount}
                                        active={item.active}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                ) : (
                    <Row>
                        {Array(4)
                            .fill(1)
                            .map((_, idx) => (
                                <Col key={idx} col={6} tablet={12} style={{ marginBottom: INDENT.sm }}>
                                    <SkeletonStyled />
                                </Col>
                            ))}
                    </Row>
                )}
            </GridStyled>
        </div>
    );
};

export default LotteryInfo;
