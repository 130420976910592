import { css } from "styled-components";

export const fontFamily = `
  font-family: Primary, Trebuchet MS, sans-serif;
  font-weight: inherit;
  font-feature-settings: 'lnum';
  font-variant-numeric: lining-nums;
`;

export const fontWeightNormal = `
  font-weight: normal;
`;

export const fontWeightBold = `
  font-weight: 700;
`;

export const fontWeight600 = `
  font-weight: 600;
`;

export const fontWeight550 = `
  font-weight: 550;
`;

export const fontWeight500 = `
  font-weight: 500;
`;

export const fontWeight400 = `
  font-weight: 400;
`;

export const fontWeight300 = `
  font-weight: 300;
`;

export const fontSize10 = css`
    font-size: 10px;
`;

export const fontSize12 = css`
    font-size: 12px;
`;

export const fontSize14 = css`
    font-size: 14px;
`;

export const fontSize16 = css`
    font-size: 16px;
`;

export const fontSize18 = css`
    font-size: 18px;
`;

export const fontSize20 = css`
    font-size: 20px;
`;

export const fontSize22 = css`
    font-size: 22px;
`;

export const fontSize24 = css`
    font-size: 24px;
`;

export const fontSize26 = css`
    font-size: 26px;
`;

export const fontSize28 = css`
    font-size: 28px;
`;

export const fontSize32 = css`
    font-size: 32px;
`;

export const fontSize36 = css`
    font-size: 36px;
`;

export const fontSize38 = css`
    font-size: 38px;
`;

export const fontSize42 = css`
    font-size: 42px;
`;

export const fontSize56 = css`
    font-size: 56px;
`;

export const fontSize64 = css`
    font-size: 56px;
`;

export const lineHeight12 = css`
    line-height: 12px;
`;

export const lineHeight14 = css`
    line-height: 14px;
`;

export const lineHeight16 = css`
    line-height: 16px;
`;

export const lineHeight18 = css`
    line-height: 18px;
`;

export const lineHeight20 = css`
    line-height: 20px;
`;

export const lineHeight24 = css`
    line-height: 24px;
`;

export const lineHeight26 = css`
    line-height: 26px;
`;

export const lineHeight28 = css`
    line-height: 28px;
`;

export const lineHeight30 = css`
    line-height: 30px;
`;

export const lineHeight32 = css`
    line-height: 32px;
`;

export const lineHeight34 = css`
    line-height: 34px;
`;

export const lineHeight36 = css`
    line-height: 36px;
`;

export const lineHeight38 = css`
    line-height: 38px;
`;

export const lineHeight40 = css`
    line-height: 40px;
`;

export const lineHeight42 = css`
    line-height: 42px;
`;
export const lineHeight45 = css`
    line-height: 45px;
`;

export const lineHeight48 = css`
    line-height: 48px;
`;

export const lineHeight52 = css`
    line-height: 52px;
`;

export const lineHeight60 = css`
    line-height: 60px;
`;

export const lineHeight68 = css`
    line-height: 68px;
`;

export const lineHeight84 = css`
    line-height: 84px;
`;

export const letterSpacingNormal = css`
    letter-spacing: normal;
`;

export const letterSpacingS = css`
    letter-spacing: 0.15em;
`;

export const letterSpacingXS = css`
    letter-spacing: 0.12em;
`;

export const textUpperCase = css`
    text-transform: uppercase;
`;

export const textCapitalize = css`
    text-transform: capitalize;
`;

export const textEllipsis = `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

// Для применения в FL, для прохождения проверки линтера

export const letterSpacingInherit = css`
    letter-spacing: inherit;
`;

export const textLowerCase = css`
    text-transform: lowercase;
`;

export const textNoneTransform = css`
    text-transform: none;
`;

export const textInheritTransform = css`
    text-transform: inherit;
`;

// Заголовки

export const title12 = css`
    ${fontSize12};
    ${lineHeight12};
    ${fontFamily};
    ${fontWeightBold};
`;

export const title16 = css`
    ${fontSize16};
    ${lineHeight20};
    ${fontFamily};
    ${fontWeight600};
`;

export const title18 = css`
    ${fontSize18};
    ${lineHeight24};
    ${fontFamily};
    ${fontWeight600};
`;

export const title20 = css`
    ${fontSize20};
    ${lineHeight28};
    ${fontFamily};
    ${fontWeight600};
`;

export const title24 = css`
    ${fontSize24};
    ${lineHeight32};
    ${fontFamily};
    ${fontWeight500};
`;

export const title32 = css`
    ${fontSize32};
    ${lineHeight32};
    ${fontFamily};
    ${fontWeight600};
`;

export const title38 = css`
    ${fontSize38};
    ${lineHeight38};
    ${fontFamily};
    ${fontWeight600};
`;

// Текст, M - weight = 600, B - weight = 700

export const text10 = css`
    ${fontSize10};
    ${lineHeight14};
    ${fontFamily};
    ${fontWeight500};
`;
export const text10M = css`
    ${text10};
    ${lineHeight14};
    ${fontFamily};
    ${fontWeight600};
`;
export const text10B = css`
    ${text10};
    ${fontWeightBold};
`;

export const text12 = css`
    ${fontSize12}
    ${lineHeight18}
    ${fontFamily}
    ${fontWeightNormal}
`;

export const text12M = css`
    ${text12}
    ${lineHeight18}
    ${fontFamily}
    ${fontWeight500}
`;

export const text14 = css`
    ${fontSize14};
    ${lineHeight20};
    ${fontFamily};
    ${fontWeight500};
`;

export const text14M = css`
    ${text14};
    ${lineHeight20};
    ${fontFamily};
    ${fontWeight600};
`;

export const text14B = css`
    ${text14};
    ${fontWeightBold};
`;

export const text16 = css`
    ${fontSize16};
    ${lineHeight24};
    ${fontFamily};
    ${fontWeight500};
`;

export const text16M = css`
    ${text16};
    ${lineHeight20};
    ${fontFamily};
    ${fontWeight600};
`;

export const text18 = css`
    ${fontSize18};
    ${lineHeight24};
    ${fontFamily};
    ${fontWeightNormal};
`;

export const text18M = css`
    ${text18};
    ${lineHeight24};
    ${fontFamily};
    ${fontWeight600};
`;

export const text20 = css`
    ${fontSize20};
    ${lineHeight30};
    ${fontFamily};
    ${fontWeightNormal};
`;

export const text20M = css`
    ${text20};
    ${lineHeight30};
    ${fontFamily};
    ${fontWeight600};
`;

export const text24 = css`
    ${fontSize24};
    ${lineHeight24};
    ${fontFamily};
    ${fontWeightNormal};
`;

export const text24M = css`
    ${text24};
    ${fontWeight600};
`;

export const text32 = css`
    ${fontSize32};
    ${lineHeight38}
    ${fontFamily};
    ${fontWeightNormal};
`;

export const text32M = css`
    ${text32};
    ${fontWeight600};
`;

export const textEllipsisStyle = `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const fontStyleItalic = `
    font-style: italic;
`;
