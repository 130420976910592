import { RESET_TICKETS, TAllTicketsTypeState, TTicketsActions } from "./types";
import { MutationReducer } from "../mutation/reducer";

export const emptyTicket = {
    amount: null,
    editionsSelected: [],
    editions: [],
    numbersSelected: [[], []],
    init: null,
};

export const initTicketsReducer = () => {
    const defaultState: TAllTicketsTypeState = {
        ticket4in20: {
            initTicket: false,
            name: "",
            data: [],
            productId: null,
            jackpot: null,
            logoSrc: "",
            editionsHistory: {
                data: [],
                totalPages: null,
            },
        },
        ticket5in36: {
            initTicket: false,
            name: "",
            data: [],
            productId: null,
            jackpot: null,
            logoSrc: "",
            editionsHistory: {
                data: [],
                totalPages: null,
            },
        },
        ticket8in20: {
            initTicket: false,
            name: "",
            data: [],
            productId: null,
            jackpot: null,
            logoSrc: "",
            editionsHistory: {
                data: [],
                totalPages: null,
            },
        },
        ticket5in50: {
            initTicket: false,
            name: "",
            data: [],
            productId: null,
            jackpot: null,
            logoSrc: "",
            editionsHistory: {
                data: [],
                totalPages: null,
            },
        },
        bingo90: {
            initTicket: false,
            name: "",
            data: [],
            ticketsSelected: [],
            productId: null,
            editionsSelected: [],
            editions: [],
            jackpot: null,
            logoSrc: "",
            numbersSelected: [],
            editionsHistory: {
                data: [],
                totalPages: null,
            },
        },

        bingo75: {
            initTicket: false,
            name: "",
            data: [],
            ticketsSelected: [],
            productId: null,
            editionsSelected: [],
            editions: [],
            jackpot: null,
            logoSrc: "",
            numbersSelected: [],
            editionsHistory: {
                data: [],
                totalPages: null,
            },
        },
        bingo3x3: {
            initTicket: false,
            name: "",
            data: [],
            ticketsSelected: [],
            productId: null,
            editionsSelected: [],
            editions: [],
            jackpot: null,
            logoSrc: "",
            numbersSelected: [],
            editionsHistory: {
                data: [],
                totalPages: null,
            },
        },
    };

    return (state = defaultState, action: TTicketsActions): TAllTicketsTypeState => {
        switch (action.type) {
            case RESET_TICKETS:
                return defaultState;
            default:
                return MutationReducer<TAllTicketsTypeState>("tickets", state, action);
        }
    };
};
