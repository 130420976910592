import { AxiosInstance } from "axios";
import qs from "qs";
import { adapters } from "~adapters";
import { ApiResponse } from "~services/http/types";
import {
    TAddedTickets,
    TAddedOneTicket,
    TStateAddedTicket,
    TMyTickets,
    TMyTicket,
    TMyTicketsInProgress,
    TMyTicketsQuery,
    TMyTicketPayout,
} from "~types/my-tickets";
import { TPaginationInfo } from "~types/utils";

const enum URLs {
    URL_TICKETS_MOMENT = "/tickets/bonuses/moment",
    URL_TICKETS = "/clients/tickets",
}

type TMyticketsDefault = Omit<TMyTickets, "pagination"> & TPaginationInfo;
type TMyticketsInProgressDefault = Omit<TMyTicketsInProgress, "pagination">;

export const createMyTicketsApi = (http: AxiosInstance) => ({
    async getTickets(obj: TMyTicketsQuery) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TMyticketsDefault>>(URLs.URL_TICKETS, {
            params: { ...obj, statuses: obj.statuses?.join(",") },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        });
        return {
            error,
            result: result && {
                content: adapters.myTickets.fromTicketsArrayData(result.content),
                pagination: adapters.myTickets.getPagiObj<TMyticketsDefault>(result),
            },
        };
    },

    async getTicketsPayment() {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TMyticketsInProgressDefault>>(URLs.URL_TICKETS + "/payment");
        return {
            error,
            result: result && {
                waitingClosed: result.waitingClosed,
                content: adapters.myTickets.fromTicketsArrayData(result.tickets),
            },
        };
    },

    async getTicketsFuture(obj: TMyTicketsQuery) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TMyticketsDefault>>(URLs.URL_TICKETS + "/feature", {
            params: { ...obj },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        });
        return {
            error,
            result: result && {
                content: adapters.myTickets.fromTicketsArrayData(result.content),
                pagination: adapters.myTickets.getPagiObj<TMyticketsDefault>(result),
            },
        };
    },

    async getTicket(id: string) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TMyTicket>>(URLs.URL_TICKETS + `/${id}`);
        return {
            error,
            result: result && adapters.myTickets.getParsedTicket(result),
        };
    },

    async getAddedTickets() {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TAddedTickets>>(URLs.URL_TICKETS_MOMENT);
        return { error, result: adapters.myTickets.fromAddedTickesData(result as TAddedTickets) };
    },

    async addTicket(ticketNumber: string, code: string) {
        const {
            data: { error, result },
        } = await http.put<ApiResponse<TAddedOneTicket>>(URLs.URL_TICKETS_MOMENT, {
            ticketNumber,
            code,
        });
        return { error, result };
    },

    async getBonusTicket() {
        const {
            data: { error, result },
        } = await http.post<ApiResponse<TStateAddedTicket>>(URLs.URL_TICKETS_MOMENT);
        return { error, result };
    },

    async getPayots(ticketId: string) {
        const {
            data: { error, result },
        } = await http.post<ApiResponse<TMyTicketPayout>>("/clients/payouts", [{ ticketId }]);
        return { error, result };
    },
});
