import styled from "styled-components";
import { Flex } from "~atoms/flex-box";
import { TabGroup } from "~molecules/tab-group";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeight500 } from "~tokens/typography";

export const TicketsBlockWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: fit-content;
    padding: ${INDENT.m};
    background-color: ${({ theme }) => theme.color.background.primaryElevated};
    border-radius: 20px;
    & > button {
        ${fontWeight500};
    }
    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm};
    }
    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs};
    }
`;

export const TabGroupStyled = styled(TabGroup)`
    width: 100%;
    align-self: flex-start;
    button {
        color: ${({ theme }) => theme.color.text.tertiary};

        &.active-tab {
            background: ${({ theme }) => theme.color.controls.primary.default};
            color: ${({ theme }) => theme.color.text.primary};
        }
    }
`;

export const DrawWrapper = styled(Flex)`
    & span {
        white-space: nowrap;
    }
    & .select-bingo {
        max-width: 290px;
        ${adaptive.maxWidth.tablet} {
            max-width: unset;
        }
    }
`;
