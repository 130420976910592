import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { getAccessToken, cleanTokensData, setTokens } from "~services/auth-tokens";
import { createAuthApi } from "~api/auth";

export const connectAuthInterceptors = (http: AxiosInstance) => {
    http.interceptors.request.use(addAccessToken);
    http.interceptors.response.use(noopRequestInterceptors, authInterceptors(http));
};

function authInterceptors(http: AxiosInstance) {
    return async function (error: AxiosError) {
        if (
            error.response?.status !== 401 ||
            (error.config as AxiosRequestConfig & { notRefresh?: boolean }).notRefresh
        ) {
            return Promise.reject(error);
        }

        cleanTokensData();
        const { result } = await createAuthApi(http).refresh();
        if (!result) {
            return Promise.reject(error);
        }

        setTokens(result.access_token);
        return axios(addAccessToken(error.config));
    };
}

function noopRequestInterceptors(response: AxiosResponse) {
    return response;
}

function addAccessToken(request: AxiosRequestConfig & { notRefresh?: boolean }) {
    const token = getAccessToken();

    if (token !== null && !request.notRefresh) {
        request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
}
