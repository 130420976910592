import styled from "styled-components";
import { TSize } from "./avatar";

const getAvatarSize = (size: TSize) => {
    switch (size) {
        case 1:
            return `
                width: 160px;
                height: 160px;`;
        case 2:
            return `
                width: 100px;
                height: 100px;`;
        case 3:
            return `
                width: 60px;
                height: 60px;`;
        case 4:
            return `
                width: 36px;
                height: 36px;`;
        default:
            break;
    }
};

export const AvatarStyled = styled.img<{ size: TSize }>`
    ${({ size }) => getAvatarSize(size)};
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
`;
