import { TProfileActions, TProfileState } from "./types";
import { MutationReducer } from "../mutation/reducer";

export const initProfileReducer = () => {
    const defaultState: TProfileState = {
        data: null,
    };

    return (state = defaultState, action: TProfileActions): TProfileState => {
        switch (action.type) {
            default:
                return MutationReducer<TProfileState>("profile", state, action);
        }
    };
};
