import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { useAppParams } from "~hooks/useAppParams";
import { useGetHistoryDetails } from "./_hooks/useGetHistoryDetails";
import { BingoFields } from "./bingo";
import { DigitalFields } from "./digital";
import { bottomFields, topFields } from "./helpers";
import { TableEdition } from "./table";
import { HistoryDetailsWrapper, Note, SkeletonStyled } from "./theme";

type THistoryDetailsProps = {
    className?: string;
};

const HistoryDetails = ({ className }: THistoryDetailsProps) => {
    const { productId, drawNumber } = useAppParams();
    const { color } = useContext(ThemeContext);
    const { data } = useGetHistoryDetails(productId, drawNumber);
    if (!data)
        return (
            <HistoryDetailsWrapper>
                <SkeletonStyled height="600px" radius="18px" />
            </HistoryDetailsWrapper>
        );
    const { type, date, time, main, sub, jackpotAmount, winAmount, ticketCount, digitalDetails, bingoDetails } = data;

    return (
        <HistoryDetailsWrapper className={className}>
            <section className={"mb64 mb48--mobile"}>
                <TitleAdaptive size={"title24"} weight="medium" className={"mb16"} tag={"h1"}>
                    Итоги тиража № {drawNumber}
                </TitleAdaptive>
                <>
                    {topFields(date, time).map((item, idx) => (
                        <div className={item.className} key={idx}>
                            <TextAdaptive size={"text16"} tag={"span"} color={color.text.secondary} className={"mr8"}>
                                {item.label}
                            </TextAdaptive>
                            <TextAdaptive size={"text16"} tag={"span"}>
                                {item.value}
                            </TextAdaptive>
                        </div>
                    ))}
                </>
            </section>
            {type === "DIGITAL" && <DigitalFields className={"mb64 mb48--mobile"} main={main} sub={sub} />}
            {type === "BINGO" && <BingoFields className={"mb64 mb48--mobile"} main={main} />}
            <section className={"mb64 mb48--mobile"}>
                {bottomFields(ticketCount, winAmount, jackpotAmount).map((item, idx) => (
                    <div className={item.className} key={idx}>
                        <TextAdaptive weight="medium" className={"mr8"} tag={"span"} size={"text16"}>
                            {item.label}
                        </TextAdaptive>
                        <TextAdaptive weight="medium" tag={"span"} size={"text16"}>
                            {item.value}
                        </TextAdaptive>
                    </div>
                ))}
                <Note weight="medium" size="text14" color={color.text.tertiary}>
                    В архиве тиража отображается сумма суперприза на начало тиража, которая разыгрывалась в этом тираже.
                </Note>
            </section>
            <section>
                <TitleAdaptive size={"title24"} className={"mb16"} weight="medium">
                    Итоги розыгрыша
                </TitleAdaptive>
                <TableEdition type={type} digitalDetails={digitalDetails} bingoDetails={bingoDetails} />
            </section>
        </HistoryDetailsWrapper>
    );
};

export default HistoryDetails;
