import { TBingoTypes } from "~store/tickets/types";
import { TBingoAlias } from "~types/products";
import { bingoAlias } from "~constants/routes";

type TTicketData = {
    alias: TBingoAlias;
    maxNumbers: number;
    maxNumField: number;
    size: number;
    sizeMobile: number;
    numbersArray: number[];
};

export const ticketOptions: Record<TBingoTypes, TTicketData> = {
    bingo90: {
        alias: bingoAlias.b90,
        maxNumbers: 90,
        maxNumField: 7,
        size: 12,
        sizeMobile: 10,
        numbersArray: Array(90)
            .fill(-1)
            .map((_, idx) => ++idx),
    },
    bingo75: {
        alias: bingoAlias.b75,
        maxNumbers: 75,
        maxNumField: 5,
        size: 12,
        sizeMobile: 10,
        numbersArray: Array(75)
            .fill(-1)
            .map((_, idx) => ++idx),
    },
    bingo3x3: {
        alias: bingoAlias.b3x3,
        maxNumbers: 30,
        maxNumField: 3,
        size: 12,
        sizeMobile: 10,
        numbersArray: Array(30)
            .fill(-1)
            .map((_, idx) => ++idx),
    },
};

export const getMissingNumbers = (ticketType: TBingoTypes, values: number[]): number[] => {
    const missingNumbers: number[] = [];
    const targetNumbers = new Set<number>(values);
    ticketOptions[ticketType].numbersArray.forEach((number) => {
        if (!targetNumbers.has(number)) missingNumbers.push(number);
    });
    return missingNumbers;
};
