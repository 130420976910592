const version = __BUILD_VERSION__;
const date = new Date(__DATE_BUILD__);

export const setVersionBuild = () => {
    const D = getFormatWithZero(date.getDate());
    const M = getFormatWithZero(date.getMonth() + 1);
    const Y = date.getFullYear();
    const h = getFormatWithZero(date.getHours());
    const min = getFormatWithZero(date.getMinutes());
    const sec = getFormatWithZero(date.getSeconds());

    console.info("%c version ", "background: #067709; color: #ffffff", version);
    console.info("%c build date ", "background: #067709; color: #ffffff", `${D}.${M}.${Y} ${h}:${min}:${sec}`);
};

const getFormatWithZero = (value: number) => {
    return `0${value}`.slice(-2);
};
