import React from "react";
import styled from "styled-components";
import { BLACK } from "~assets/styles/tokens/colors";

type THamburgerProps = {
    active: boolean;
    onClose?: () => void;
    onOpen?: () => void;
    className?: string;
};

const HamburgerWrapper = styled.div<Omit<THamburgerProps, "className" | "onClose" | "onOpen">>`
    width: 24px;
    height: 20px;
    cursor: pointer;
    div {
        width: 24px;
        height: 4px;
        border-radius: 100px;
        transform-origin: center;
        transition: all 0.3s linear;
        background-color: ${BLACK};
        &:nth-child(1) {
            transform: ${({ active }) => (active ? "translateY(8px) rotate(45deg)" : "rotate(0)")};
        }
        &:nth-child(2) {
            opacity: ${({ active }) => (active ? "0" : "1")};
        }
        &:nth-child(3) {
            transform: ${({ active }) => (active ? "translateY(-8px) rotate(-45deg)" : "rotate(0)")};
        }
`;

const Hamburger = ({ className, active, onClose, onOpen }: THamburgerProps) => {
    return (
        <HamburgerWrapper className={className} active={active} onClick={active ? onClose : onOpen} role={"button"}>
            <div className="mb4" />
            <div className="mb4" />
            <div />
        </HamburgerWrapper>
    );
};

export default Hamburger;
