import React, { useCallback, useRef } from "react";
import { Tip } from "~atoms/tip";
import { TTipsData } from "./helpers";
import { TipGroupWrapper } from "./theme";

type TTipGroupProps = {
    className?: string;
    margin?: string;
    data: TTipsData[];
    onChange: (value: number, all?: boolean) => void;
};

const TipGroup = ({ className, margin, data, onChange }: TTipGroupProps) => {
    const tipList = useRef<HTMLUListElement>(null);
    const handleClick = useCallback(
        (payload: number, all?: boolean) => () => {
            onChange && onChange(payload, all);
        },
        [onChange],
    );

    return (
        <TipGroupWrapper ref={tipList} className={className}>
            {data.map((item, idx) => {
                const { title, value, all } = item;
                return (
                    <Tip
                        key={idx}
                        onClick={handleClick(value, all)}
                        className={idx !== data.length - 1 ? margin || "mr8 mb8" : ""}
                    >
                        {title}
                    </Tip>
                );
            })}
        </TipGroupWrapper>
    );
};

export default TipGroup;
