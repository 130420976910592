import styled from "styled-components";
import { Text } from "~atoms/text";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeight500, text12, text14, text16, text24, text32 } from "~tokens/typography";

export const LongTicketWrapper = styled.div<{ backgroundUrl: string }>`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 31.6%;
    background-image: url(${(props) => props.backgroundUrl});
    background-size: cover;
    background-repeat: no-repeat;
`;

export const TicketDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 68.4%;
    height: 100%;
    font-size: 16px;
    padding: ${INDENT.xs};
`;

export const TicketDescriptionLogo = styled.img`
    height: 44px;

    ${adaptive.maxWidth.tablet} {
        height: 32px;
    }
`;

export const TicketDescriptionBody = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const TicketDescriptionTitle = styled(Text)`
    margin-bottom: ${INDENT.xxxxs};
    ${text24}
    ${fontWeight500}
    
    ${adaptive.maxWidth.tablet} {
        ${text14}
    }
`;

export const TicketDescriptionPrize = styled(Text)`
    ${text32};
    line-height: 45px;
    margin-bottom: ${INDENT.xxxxs};

    ${adaptive.maxWidth.tablet} {
        ${text24}
    }
`;

export const TicketDescriptionSlogan = styled(Text)`
    max-width: 220px;
`;

export const TicketActionZone = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 31.6%;
    height: 100%;
    align-items: center;
    padding: ${INDENT.sm};
`;

export const TicketActionZoneEdition = styled(Text)`
    margin-bottom: ${INDENT.xxxxs};
    ${text16}

    ${adaptive.maxWidth.tablet} {
        ${text12}
    }
`;

export const TicketActionTime = styled(Text)`
    ${text16}

    ${adaptive.maxWidth.tablet} {
        ${text12}
    }
`;

export const TicketActionZoneAmount = styled(Text)`
    ${text32};
    line-height: 28px;
    flex-grow: 1;
    display: flex;
    align-items: center;

    ${adaptive.maxWidth.tablet} {
        ${text16}
    }
`;

export const TicketActionZonePlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
`;
