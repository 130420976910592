import React, { useCallback } from "react";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { useGetBingoTickets } from "~pages/lottery/bingo-lottery/_hooks/useGetBingoTickets";
import { ticketOptions } from "~pages/lottery/bingo-lottery/common/helpers";
import { TicketsGroup } from "~pages/lottery/bingo-lottery/common/tickets-group";
import { TBingoTypes } from "~store/tickets/types";
import { useAppScreen } from "~hooks/useAppScreen";
import { useAppSelector } from "~hooks/useAppSelector";

type TTicketsProps = {
    ticketType: TBingoTypes;
};

const Tickets = ({ ticketType }: TTicketsProps) => {
    const typeScreen = useAppScreen();
    const { handleGetBingoTickets, handleRefreshBingoTickets } = useGetBingoTickets({
        alias: ticketOptions[ticketType].alias,
        ticketType: ticketType,
        size: typeScreen === "mobile" ? ticketOptions[ticketType].sizeMobile : ticketOptions[ticketType].size,
    });
    const ticketsLength = useAppSelector((state) => state.tickets[ticketType].data.length);

    const handleUpdateTickets = useCallback(() => {
        handleRefreshBingoTickets();
        void handleGetBingoTickets();
    }, [handleGetBingoTickets, handleRefreshBingoTickets]);

    return (
        <>
            <TicketsGroup ticketsLength={ticketsLength} ticketType={ticketType} />
            <ButtonAdaptive
                size="large"
                className="mb32 mb24--desktopxl mb16--mobile mt20 mt8--desktopxl mt0--mobile"
                onClick={handleUpdateTickets}
            >
                Показать другие билеты
            </ButtonAdaptive>
        </>
    );
};

export default Tickets;
