import styled from "styled-components";
import { PURPLE } from "~tokens/colors";

export const MissionWrapper = styled.div``;

export const Circle = styled.span`
    display: inline-block;
    background-color: ${PURPLE};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 6px;
`;
