import React, { ReactNode, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Grid } from "~atoms/grid";
import { Col } from "~atoms/grid/col";
import { Row } from "~atoms/grid/row";
import { Link } from "~atoms/link";
import { TextAdaptive } from "~atoms/text-adaptive";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export type TFiles = {
    title: string;
    link: string;
    col: number;
    tablet: number;
    mobile: number;
    desktopXL: number;
};

type TFilesBlockProps = {
    className?: string;
    files: TFiles[];
    prefixIcon?: ReactNode;
    margin?: string;
};

const FilesBlockWrapper = styled.section`
    display: block;
`;

const FileStyled = styled.div`
    & .prefix-wrap {
        margin-right: ${INDENT.xxxs};

        svg {
            color: ${({ theme }) => theme.color.icons.secondary};

            ${adaptive.maxWidth.tablet} {
                width: 32px;
                height: auto;
            }
        }
    }
`;

const TextStyled = styled(TextAdaptive)`
    word-break: break-all;
`;

const FilesBlock = ({ className, files, prefixIcon, margin = "mb32 mb24--tablet mb16--mobile" }: TFilesBlockProps) => {
    const { color } = useContext(ThemeContext);
    return (
        <FilesBlockWrapper className={className}>
            <Grid>
                <Row>
                    {files.map((file, idx) => (
                        <Col
                            key={idx}
                            col={file.col}
                            desktopXL={file.desktopXL}
                            tablet={file.tablet}
                            mobile={file.mobile}
                            className={idx !== files.length - 1 ? margin : ""}
                        >
                            <FileStyled>
                                <Link download={true} href={file.link} intent prefixIcon={prefixIcon}>
                                    <TextStyled size={"text16"} tag={"span"} color={color.text.primary}>
                                        {file.title}
                                    </TextStyled>
                                </Link>
                            </FileStyled>
                        </Col>
                    ))}
                </Row>
            </Grid>
        </FilesBlockWrapper>
    );
};

export default FilesBlock;
