import React from "react";
import { TDigitalEditionDetails, TBingoEditionDetails, TProductType } from "~types/products";
import { BingoTable } from "./bingo";
import { DigitalTable } from "./digital";

type TTableEdition = {
    type: TProductType;
    digitalDetails?: TDigitalEditionDetails[];
    bingoDetails?: TBingoEditionDetails[];
};

const TableEdition = ({ type, digitalDetails, bingoDetails }: TTableEdition) => {
    return (
        <>
            {type === "DIGITAL" && <DigitalTable digitalDetails={digitalDetails!} />}
            {type === "BINGO" && <BingoTable bingoDetails={bingoDetails!} />}
        </>
    );
};

export default TableEdition;
