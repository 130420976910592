import React, { useCallback, useEffect, useMemo, useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { BuyTicketsCardDigital } from "~organisms/buy-tickets-card";
import { LotteryDataDigital } from "~organisms/lottery-data";
import { LotteryTicketsBar } from "~organisms/lottery-tickets-bar";
import { Page } from "~templates/page";
import { useResetTickets } from "~pages/lottery/_hooks/useResetTickets";
import { useInitTickets } from "~pages/lottery/digital-lottery/_hooks/useInitTickets";
import { LinkBackStyled } from "~pages/lottery/momentary-lottery/common/theme";
import {
    digitalAlias,
    LOTTERY,
    LOTTERY_8_IN_20,
    LOTTERY_8_IN_20_HISTORY,
    LOTTERY_8_IN_20_HISTORY_DETAILS,
    LOTTERY_8_IN_20_RULES,
} from "~constants/routes";
import { ticketsColor, ticketsDigitalType } from "~constants/tickets-type";
import useAppHistory from "~hooks/useAppHistory";
import { useAppScreen } from "~hooks/useAppScreen";
import { useAppSelector } from "~hooks/useAppSelector";
import { useElementOnScreen } from "~hooks/useElementOnScreen";
import { crumbs, tabs } from "./helpers";
import History from "./history/history";
import Rules from "./rules/rules";
import {
    BuyTicketsBarStyled,
    BuyTicketsCardWrapper,
    HeaderBlock,
    HeaderContentWrapper,
    LogoStyled,
    LogoWrapper,
    TabGroupStyled,
    TicketsAside,
    TicketsContent,
} from "./theme";
import TicketsPage from "./tickets-page/tickets-page";
import { HistoryDetails } from "../../common/history-details";
import { LogoSkeletonStyled } from "../../common/legal-info/theme";

const ticketOptions = {
    field1: {
        total: 20,
        generateNum: 8,
    },
    field2: {
        total: 4,
        generateNum: 1,
    },
};

const observerOptions = {
    root: null,
    rootMargin: "50px 0px 50px 0px",
    threshold: 0.8,
};

const col = {
    mainContent: {
        desktopXL: 8,
    },
    asideContent: {
        desktopXL: 4,
    },
};

const Lottery8in20 = () => {
    const isActive = useAppSelector(
        (state) => state.lotteries.data.find((item) => item.id === digitalAlias.t8in20)?.active ?? true,
    );
    const { data, isLotteriesLoaded } = useAppSelector((state) => state.lotteries);
    const [isCardVisible, cardRef] = useElementOnScreen<HTMLDivElement>(observerOptions);
    const logoSrc = useAppSelector((state) => state.tickets[ticketsDigitalType.t8in20].logoSrc);
    const productId = useAppSelector((state) => state.tickets[ticketsDigitalType.t8in20].productId);
    const init = useAppSelector((state) => state.tickets[ticketsDigitalType.t8in20].initTicket);
    const name = useAppSelector((state) => state.tickets[ticketsDigitalType.t8in20].name);
    const { color } = useContext(ThemeContext);

    const { initTickets } = useInitTickets(digitalAlias.t8in20, ticketsDigitalType.t8in20);
    const { resetTickets } = useResetTickets();
    const { go } = useAppHistory();
    const location = useLocation();
    const typeScreen = useAppScreen();
    const defaultTicketsCnt = typeScreen.startsWith("desktop") ? 2 : 1;

    const handleClick = useCallback(
        (url) => {
            go(url);
        },
        [go],
    );

    const directionOption = "horizontal";
    const isHistoryDetailsPath = location.pathname.indexOf(productId!) > 0;
    const isCardShow = location.pathname === LOTTERY_8_IN_20 || typeScreen.startsWith("desktop");

    const activePath = location.pathname.startsWith(LOTTERY_8_IN_20_HISTORY)
        ? LOTTERY_8_IN_20_HISTORY
        : location.pathname;

    useEffect(() => {
        if (!init) {
            void initTickets(defaultTicketsCnt);
        }
    }, [initTickets, init, defaultTicketsCnt]);

    useEffect(() => resetTickets, [resetTickets]);

    const tabsData = useMemo(() => (isActive ? tabs : tabs.filter((item) => item.id !== LOTTERY_8_IN_20)), [isActive]);

    const mainContent = useMemo(
        () => (
            <TicketsContent className="mb64--tablet mb48--mobile">
                <Switch>
                    {isActive && (
                        <Route
                            path={LOTTERY_8_IN_20}
                            render={() => (
                                <TicketsPage ticketOptions={ticketOptions} defaultTicketsCnt={defaultTicketsCnt} />
                            )}
                            exact
                        />
                    )}
                    <Route path={LOTTERY_8_IN_20_RULES} render={() => <Rules isActive={isActive} />} exact />
                    <Route path={LOTTERY_8_IN_20_HISTORY} component={History} exact />
                    <Route path={LOTTERY_8_IN_20_HISTORY_DETAILS} component={HistoryDetails} exact />
                    {isActive ? <Redirect to={LOTTERY_8_IN_20} /> : <Redirect to={LOTTERY_8_IN_20_RULES} />}
                </Switch>
                {typeScreen.startsWith("desktop") && (
                    <LotteryTicketsBar
                        className="mt64"
                        title="Другие лотереи"
                        direction={directionOption}
                        lotteryType={"digital-8x20"}
                    />
                )}
            </TicketsContent>
        ),
        [isActive, typeScreen, defaultTicketsCnt],
    );

    const asideContent = useMemo(
        () => (
            <TicketsAside>
                {isActive && isLotteriesLoaded && (
                    <BuyTicketsCardWrapper ref={cardRef} show={isCardShow} className={"mb64--tablet mb48--mobile"}>
                        <BuyTicketsCardDigital
                            className="buy-ticket-card"
                            ticketOptions={ticketOptions}
                            ticketType={ticketsDigitalType.t8in20}
                        />
                    </BuyTicketsCardWrapper>
                )}
                {!typeScreen.startsWith("desktop") && (
                    <LotteryTicketsBar
                        title={"Другие лотереи"}
                        direction={directionOption}
                        lotteryType={"digital-8x20"}
                    />
                )}
                {isActive && (
                    <BuyTicketsBarStyled
                        ticketOptions={ticketOptions}
                        ticketType={ticketsDigitalType.t8in20}
                        show={!isCardVisible}
                    />
                )}
            </TicketsAside>
        ),
        [cardRef, isActive, isCardShow, isCardVisible, isLotteriesLoaded, typeScreen],
    );

    const headerBlock = useMemo(
        () => (
            <HeaderBlock>
                {typeScreen === "mobile" ? (
                    <LinkBackStyled href={isHistoryDetailsPath ? LOTTERY_8_IN_20_HISTORY : LOTTERY} className="mb16" />
                ) : (
                    <Breadcrumbs
                        data={crumbs(location.pathname, productId!, name)}
                        className="mb24 mt8--desktopxl mt0--tablet mb16--tablet"
                    />
                )}
                {isActive ? (
                    <HeaderContentWrapper className="mb32--tablet">
                        <LogoWrapper className="mb32 mb24--tablet">
                            {logoSrc ? <LogoStyled src={logoSrc} alt="Лотерея 8 из 20" /> : <LogoSkeletonStyled />}
                        </LogoWrapper>
                        {data.length ? <LotteryDataDigital ticketType={ticketsDigitalType.t8in20} /> : null}
                    </HeaderContentWrapper>
                ) : (
                    <LogoWrapper className="mb32 mb24--tablet">
                        {logoSrc ? <LogoStyled src={logoSrc} alt="Лотерея 8 из 20" /> : <LogoSkeletonStyled />}
                    </LogoWrapper>
                )}

                <TabGroupStyled
                    active={activePath}
                    onClick={handleClick}
                    tabs={tabsData}
                    role={"link"}
                    color={color.text.inverted}
                    size={3}
                    loading={!isLotteriesLoaded}
                />
            </HeaderBlock>
        ),
        [
            typeScreen,
            isHistoryDetailsPath,
            location.pathname,
            productId,
            isActive,
            logoSrc,
            name,
            activePath,
            handleClick,
            tabsData,
            isLotteriesLoaded,
            data.length,
            color.text.inverted,
        ],
    );

    return (
        <Page
            mainContent={mainContent}
            headerBackground={ticketsColor["digital-8x20"]}
            asideContent={asideContent}
            headerBlock={headerBlock}
            col={col}
        />
    );
};

export default Lottery8in20;
