import styled from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { lineHeight24 } from "~tokens/typography";

export const PayoutWrapper = styled.div`
    padding: ${INDENT.xs};
    border-radius: 20px;
    background: ${WHITE};
    .payout-input {
        width: 100%;
    }
    ${adaptive.minWidth.tablet} {
        .payout-input {
            max-width: 342px;
        }
        padding: ${INDENT.m};
    }
    ${adaptive.minWidth.desktopXL} {
        padding: ${INDENT.sm};
    }
    ${adaptive.minWidth.desktopXXL} {
        padding: ${INDENT.m};
    }
`;

export const ButtonPayout = styled(ButtonAdaptive)`
    ${adaptive.maxWidth.tablet} {
        width: 100%;
        & p {
            ${lineHeight24};
        }
    }
`;
