import React from "react";
import { TEdition, TTicketTypes } from "~store/tickets/types";
import { TDigitalAlias } from "~types/products";
import SelectModalBlockView from "./select-modal-block-view";
import { useSelectModalDigitalLogic } from "../_hooks/useSelectModalDigitalLogic";

type TSelectModalBlockDigitalProps = {
    title?: string;
    editions: TEdition[];
    editionsSelected: TEdition[];
    idx: number;
    ticketType: TTicketTypes;
    ticketAlias: TDigitalAlias;
};

const SelectModalBlockDigital = ({ title, idx, ticketType, ticketAlias }: TSelectModalBlockDigitalProps) => {
    const {
        data: { editions, editionsSelected, editionsExpired },
        cb: { handleChange, handleSelect, handleClose },
    } = useSelectModalDigitalLogic(ticketAlias, ticketType, idx);

    return (
        <SelectModalBlockView
            title={title}
            editions={editions}
            editionsSelected={editionsSelected}
            handleSelect={handleSelect}
            handleClose={handleClose}
            handleChange={handleChange}
            editionsExpired={editionsExpired}
        />
    );
};

export default SelectModalBlockDigital;
