import React from "react";
import styled from "styled-components";
import { CloseIcon } from "~atoms/icons";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { RED, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

type TFailedProps = {
    className?: string;
};

const FailedWrapper = styled.div`
    display: block;
`;
const CloseIconWrapper = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: ${INDENT.xxxxs};
    background: ${RED};
    display: inline-block;
    ${adaptive.maxWidth.tablet} {
        width: 24px;
        height: 24px;
        padding: ${INDENT.xxxxs};
    }
`;

const CloseIconStyled = styled(CloseIcon)`
    color: ${WHITE};
    width: 24px;
    height: 24px;
    ${adaptive.maxWidth.tablet} {
        width: 16px;
        height: 16px;
    }
`;
const Failed = ({ className }: TFailedProps) => {
    return (
        <FailedWrapper className={className}>
            <CloseIconWrapper className={"mb16"}>
                <CloseIconStyled />
            </CloseIconWrapper>
            <TitleAdaptive size={"title24"} tag={"h4"} className={"mb16 mb12--mobile"} color={RED}>
                Оплата не прошла
            </TitleAdaptive>
        </FailedWrapper>
    );
};

export default Failed;
