import styled, { css, DefaultTheme, SimpleInterpolation } from "styled-components";
import { BLACK, BLUE_NATIVE, GREY_DARK2, GREY_LIGHT, PRIMARY_BLUE, WHITE } from "~assets/styles/tokens/colors";
import { fontWeight500, text14, text16M } from "~assets/styles/tokens/typography";
import { disableStyle, transitionDefault } from "~assets/styles/utils";
import { TSize, TVariant } from "~atoms/button-adaptive/button-adaptive";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

const getVariantStyles = (variant: TVariant, theme: DefaultTheme): SimpleInterpolation => {
    switch (variant) {
        case "primary": {
            return `
                color: ${theme.color.text.primary};
                background: ${theme.color.controls.primary.default};  
                background-size: 200%;
                background-position: left;
                
                &:hover {
                    background: ${theme.color.accent.warning};  
                };
                &:active {
                     background: ${theme.color.accent.warning}; 
                };
            `;
        }
        case "secondary": {
            return `
                color: ${theme.color.text.quaternary};
                background: ${theme.color.controls.secondary.default};  
                &:hover {
                    background: ${theme.color.controls.secondary.hover};  
                };
                &:active {
                    background: ${theme.color.controls.secondary.active};  
                };
            `;
        }
        case "tertiary": {
            return `
                color: ${BLUE_NATIVE};
                background: ${GREY_LIGHT};
                &:hover {
                    color: #005FC6;
                };
                &:active {
                    color: #005FC6;

                };
            `;
        }
        case "transparent": {
            return `
                color: ${BLACK};
                background: transparent;             
                &:hover {
                    color: ${PRIMARY_BLUE};
                };
                &:active {
                    color: ${PRIMARY_BLUE};
                };
            `;
        }
        case "black": {
            return `
                color: ${WHITE};
                background-color: ${BLACK};
                &:hover {
                    background-color: "#414040";
                };
                &:active {
                    background-color: "#414040";
                };
            `;
        }

        default: {
            return;
        }
    }
};

const getSize = (size: TSize): SimpleInterpolation => {
    switch (size) {
        case "large": {
            return css`
                padding: ${INDENT.xs} ${INDENT.m};
                ${text16M};
                ${adaptive.maxWidth.tablet} {
                    padding: ${INDENT.xxs} ${INDENT.sm};
                    ${text14};
                }
            `;
        }
        case "small": {
            return css`
                padding: ${INDENT.xxxs} ${INDENT.xs};
                ${text16M};
                ${adaptive.maxWidth.tablet} {
                    padding: ${INDENT.xxxxs} ${INDENT.xs};
                    ${text14};
                }
            `;
        }
        default: {
            return;
        }
    }
};

export const ButtonAdaptiveStyled = styled.button<{ variant: TVariant; size: TSize }>`
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    ${transitionDefault};
    ${({ variant, theme }) => getVariantStyles(variant, theme)}
    ${({ size }) => getSize(size)}
    white-space: nowrap;
    ${fontWeight500}

    & > span {
        display: flex;
        align-items: center;
    }

    .prefix-wrap,
    .suffix-wrap {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
    }

    .prefix-wrap {
        margin-right: ${INDENT.xxxs};
    }

    .suffix-wrap {
        margin-left: ${INDENT.xxxs};
    }

    &:disabled {
        ${disableStyle};
        background: ${GREY_LIGHT};
        color: ${GREY_DARK2};
    }
`;
