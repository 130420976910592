import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { ArrowDropdownIcon } from "~atoms/icons";
import { RemoveButton } from "~atoms/remove-button";
import { SelectModalDigital } from "~organisms/select-modal";
import TicketFooter from "~organisms/ticket-footer/ticket-footer";
import { useGetDraw } from "~pages/lottery/digital-lottery/_hooks/useGetDraw";
import { useUpdateTicketNumbers } from "~pages/lottery/digital-lottery/_hooks/useUpdateTicketNumbers";
import { TTicketOptions } from "~pages/lottery/types";
import { digitalAlias } from "~constants/routes";
import { ticketsDigitalType } from "~constants/tickets-type";
import { useAppSelector } from "~hooks/useAppSelector";
import { FieldNumbers1, FieldNumbers2 } from "./field-numbers";
import { Header } from "./header";
import { FieldsNumbersBody, SkeletonStyled, Ticket5in36Wrapper, TicketBody, TicketFooterWrapper } from "./theme";

export type TBackgroundTicket = {
    backgroundUrlDesktop: string;
    backgroundUrlTablet: string;
    backgroundUrlMobile: string;
};

type TTicket5in36Props = {
    idx: number;
    showRemoveBtn?: boolean;
    ticketOptions: TTicketOptions;
    onRemove: (idx: number) => () => void;
    onReset: (idx: number) => () => void;
    className?: string;
};

const Ticket5in36 = ({ idx, className, ticketOptions, onReset, onRemove, showRemoveBtn = true }: TTicket5in36Props) => {
    const { getDraw } = useGetDraw(digitalAlias.t5in36, ticketsDigitalType.t5in36);
    const { handleUpdateTicketNumbers, handleUpdateAllTicket } = useUpdateTicketNumbers(ticketsDigitalType.t5in36);

    const ticket = useAppSelector((state) => state.tickets[ticketsDigitalType.t5in36].data[idx]);
    const logoSrc = useAppSelector((state) => state.tickets[ticketsDigitalType.t5in36].logoSrc);
    const { editionsSelected, numbersSelected, amount, init } = ticket;

    const selectRef = useRef<HTMLDivElement>(null);

    const isResettable = useMemo(() => {
        return numbersSelected.reduce((flag: boolean, item): boolean => {
            return flag || !!item.length;
        }, false);
    }, [numbersSelected]);

    const handleUpdateTicket = useCallback(
        (number: number, numbersSelected: number[]) => handleUpdateTicketNumbers(idx)(number, numbersSelected),
        [handleUpdateTicketNumbers, idx],
    );

    const handleUpdateAllTickets = useCallback(
        (numbersSelected: number[][]) => handleUpdateAllTicket(idx)(numbersSelected),
        [handleUpdateAllTicket, idx],
    );

    const handleRemove = useCallback(() => onRemove(idx)(), [onRemove, idx]);
    const handleReset = useCallback(() => onReset(idx)(), [onReset, idx]);

    useEffect(() => {
        if (!init) void getDraw(idx);
    }, [getDraw, idx, init]);

    return (
        <>
            {!init ? (
                <SkeletonStyled className={className} />
            ) : (
                <Ticket5in36Wrapper className={className}>
                    <Header
                        amount={amount}
                        logoSrc={logoSrc}
                        maxNumField1={ticketOptions.field1.generateNum}
                        maxNumField2={ticketOptions.field2.generateNum}
                        titleField1={"Поле 1"}
                        titleField2={"Поле 2"}
                        titleTicket={`Билет № ${idx + 1}`}
                        selectedNum={numbersSelected}
                        title={`Билет № ${idx + 1}`}
                    >
                        <SelectModalDigital
                            ref={selectRef}
                            autoWidth
                            suffixIcon={<ArrowDropdownIcon />}
                            className={"select-modal"}
                            idx={idx}
                            ticketType={ticketsDigitalType.t5in36}
                            ticketAlias={digitalAlias.t5in36}
                            title={`Выбрано ${editionsSelected.length}`}
                        />
                    </Header>
                    <TicketBody className="mt48--mobile" flexGrow={1} mobile={{ alignItems: "center" }}>
                        <FieldsNumbersBody
                            className={"mauto--mobile"}
                            justify="center"
                            mobile={{
                                $direction: "column",
                            }}
                        >
                            <FieldNumbers1
                                id={0}
                                className={"mb16--mobile field1"}
                                maxNum={ticketOptions.field1.generateNum}
                                title={"Поле 1"}
                                values={numbersSelected[0]}
                                onChange={handleUpdateTicket}
                                flexShrink={0}
                                flexBasis={"582px"}
                                desktopXL={{ flexBasis: "352px" }}
                                tablet={{ flexBasis: "428px" }}
                                mobile={{ flexBasis: "auto" }}
                            />
                            <FieldNumbers2
                                id={1}
                                className={"field2"}
                                maxNum={ticketOptions.field2.generateNum}
                                title={"Поле 2"}
                                values={numbersSelected[1]}
                                onChange={handleUpdateTicket}
                                flexShrink={0}
                                flexBasis={"252px"}
                                desktopXL={{ flexBasis: "152px" }}
                                tablet={{ flexBasis: "105px" }}
                                mobile={{ flexBasis: "auto" }}
                            />
                        </FieldsNumbersBody>
                    </TicketBody>
                    <TicketFooterWrapper>
                        <TicketFooter
                            id={idx}
                            showRemoveBtn={showRemoveBtn}
                            options={ticketOptions}
                            className="ticket-controls"
                            onChange={handleUpdateAllTickets}
                            onReset={handleReset}
                            onRemove={handleRemove}
                            isResettable={isResettable}
                        />
                        {showRemoveBtn && (
                            <RemoveButton className="btn-remove-mobile mauto--mobile" onClick={handleRemove}>
                                Удалить
                            </RemoveButton>
                        )}
                    </TicketFooterWrapper>
                </Ticket5in36Wrapper>
            )}
        </>
    );
};

export default memo(Ticket5in36);
