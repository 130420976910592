import WidgetHelper from "@cupis/widget-helper";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CloseIcon } from "~atoms/icons";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { TAllTicketTypes } from "~store/tickets/types";
import { srcWidget } from "~constants/configuration";
import useAppHistory from "~hooks/useAppHistory";
import { ButtonClose, PaymentPage, WidgetBlockWrapper, widgetColors } from "./theme";

type TWidgetBlockProps = {
    className?: string;
    paymentId: string;
    paymentToken: string;
    ticketType?: TAllTicketTypes;
    handleSuccess?: () => void;
    handleClose?: (success: boolean) => void;
};

const WidgetBlock = ({
    className,
    paymentToken,
    paymentId,
    ticketType,
    handleClose,
    handleSuccess,
}: TWidgetBlockProps) => {
    const { onClose } = useContext(ModalWindowContext);

    const { go } = useAppHistory();
    const [isShowCloseBtn, setShowCloseBtn] = useState(true);
    const paymentSuccess = useRef(false);

    useEffect(() => {
        const widget = new WidgetHelper({
            iframe: "#cupis-widget",
            src: srcWidget,

            onSuccess: () => {
                paymentSuccess.current = true;
                handleSuccess && handleSuccess();
            },
            onLoad: () => {
                setShowCloseBtn(false);
            },
            onFail: () => {
                console.info("fail");
            },
            onClose: () => {
                handleClose && handleClose(paymentSuccess.current);
                onClose();
            },
        });

        if (paymentId && paymentToken) {
            widget.options(widgetColors);
            widget.transaction({ paymentId, token: paymentToken });
        }
    }, [onClose, ticketType, paymentId, paymentToken, go, handleSuccess, handleClose]);

    return (
        <WidgetBlockWrapper className={className}>
            <PaymentPage>
                {isShowCloseBtn && (
                    <ButtonClose onClick={onClose} variant={"secondary"}>
                        <CloseIcon />
                    </ButtonClose>
                )}
                <div id="cupis-widget" />
            </PaymentPage>
        </WidgetBlockWrapper>
    );
};

export default WidgetBlock;
