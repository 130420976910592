import styled, { DefaultTheme } from "styled-components";
import { ComboCircle } from "~atoms/combo-circle";
import { TVariantComboCircle } from "~atoms/combo-circle/combo-circle";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { TDigitalAlias } from "~types/products";

const getAliasStyle = (alias: TDigitalAlias, variant: TVariantComboCircle, theme: DefaultTheme) => {
    if (variant === 1) {
        switch (alias) {
            case "digital-4x20":
                return `
                ${theme.color.const.blackberry.darkest};
            `;
            case "digital-8x20":
                return `
                ${theme.color.const.cranberry.dark};
            `;
            case "digital-5x36":
                return `
                ${theme.color.const.plum.dark};
            `;
            case "digital-5x50":
                return `
                ${theme.color.const.blueberry.darkest};
            `;
            default:
                break;
        }
    } else {
        return ``;
    }
};

const BallsWrapper = styled.div``;

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;

    /* 320px */
    ${adaptive.maxWidth.mobileSmall} {
        white-space: nowrap;
    }
`;

const Ball = styled(ComboCircle)<{ variant: TVariantComboCircle; alias?: TDigitalAlias }>`
    background-color: ${({ variant, alias, theme }) => alias && getAliasStyle(alias, variant, theme)};

    ${adaptive.maxWidth.tablet} {
        > div {
            line-height: 24px;
        }
        border-radius: 6px;
        width: 24px;
        height: 24px;
    }

    /* 320px */
    ${adaptive.maxWidth.mobileSmall} {
        width: 20px;
        height: 20px;
    }

    &:not(:last-child) {
        margin-right: ${INDENT.xxxxs};
    }
`;

export { BallsWrapper, FieldWrapper, Ball };
