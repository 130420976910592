import styled from "styled-components";
import { Button } from "~atoms/button";
import { commonWrapperModalWindow } from "~organisms/buy-tickets-card/theme";
import { INDENT } from "~tokens/sizes";

export const EmailBlockWrapper = styled.div`
    ${commonWrapperModalWindow};
`;

export const ButtonStyled = styled(Button)`
    display: block;
    margin: ${INDENT.none} ${INDENT.auto};
    width: 144px;
`;
