// Основные цвета

export const GREY_DARK = "#929EAB";
export const GREY_DARK2 = "#B9C1CD";
export const GREY_LIGHT = "#F0F2F5";
export const GREY_LIGHT2 = "#F8FAFD";
export const BLACK = "#101820";
export const RED = "#E4002B";
export const WHITE = "#FFFFFF";
export const GREEN = "#009A44";
export const BLUE_NATIVE = "#007AFF";
export const GREEN_LIGHT = "#78D64B";
export const ORANGE = "#FFBD00";

// Обновленные цвета

export const PRIMARY_GRADIENT = "linear-gradient(90deg, #553E84 0%, #1E1550 100%)";
export const SECONDARY_GRADIENT = "linear-gradient(225deg, #E994E8 0%, #A2CBFB 49.48%, #8AEDE7 100%)";
export const PRIMARY_BLUE = "#1D154F";
export const TEAL = "#8BEDE8";
export const PINK = "#E896E9";
export const YELLOW = "#D1FF00";
export const PURPLE = "#6A62D2";
export const TICKET_GREY = "#c4c4c4";

export const colors = {
    plum: {
        darkest: "#471C61",
        dark: "#6D2D79",
        normal: "#883888",
        light: "#A86EA7",
        lightest: "#F0E7F0",
    },
    cranberry: {
        darkest: "#7F3363",
        dark: "#BB4079",
        normal: "#E54887",
        light: "#E677AD",
        lightest: "#F9E7F0",
    },
    raspberry: {
        darkest: "#8E003F",
        dark: "#C51345",
        normal: "#EA1F49",
        light: "#F55F7E",
        lightest: "#FFE4E9",
    },
    orange: {
        darkest: "#BA360A",
        dark: "#E04A17",
        normal: "#F95721",
        light: "#FFA080",
        lightest: "#FBE9E7",
    },
    banana: {
        darkest: "#F37F19",
        dark: "#FAC031",
        normal: "#FBE739",
        light: "#FDF177",
        lightest: "#FFFDE8",
    },
    lime: {
        darkest: "#808201",
        dark: "#A6C100",
        normal: "#C1EB1D",
        light: "#D3F36B",
        lightest: "#F8FEE7",
    },
    apple: {
        darkest: "#027722",
        dark: "#04AA42",
        normal: "#26CD58",
        light: "#74DF8B",
        lightest: "#E8FAEB",
    },
    mint: {
        darkest: "#00724D",
        dark: "#03A17B",
        normal: "#00C19B",
        light: "#00D9BC",
        lightest: "#DFF9F5",
    },
    blueberry: {
        darkest: "#0048AA",
        dark: "#007CFF",
        normal: "#0097FD",
        light: "#45B6FC",
        lightest: "#E1F3FE",
    },
    blackberry: {
        darkest: "#002094",
        dark: "#003DB7",
        normal: "#014FCE",
        light: "#6384E0",
        lightest: "#E7EAFA",
    },
    gsSolid: {
        background: "#3E3F43",
        900: "#000000",
        800: "#1D2022",
        700: "#2C3135",
        600: "#434A51",
        500: "#626C77",
        400: "#969FA8",
        300: "#BBC1C7",
        200: "#E2E5EB",
        100: "#F2F3F7",
        0: "#FFFFFF",
    },
    owrlDarken: {
        950: "rgba(17, 20, 22, 0.95)",
        900: "rgba(20, 25, 30, 0.9)",
        800: "rgba(20, 28, 35, 0.8)",
        700: "rgba(33, 45, 57, 0.7)",
        500: "rgba(47, 64, 80, 0.5)",
        300: "rgba(32, 49, 65, 0.3)",
        120: "rgba(32, 49, 65, 0.12)",
        50: "rgba(42, 59, 75, 0.06)",
    },
    owrlLighten: {
        950: "rgba(250, 251, 255, 0.97)",
        900: "rgba(250, 251, 255, 0.92)",
        800: "rgba(250, 251, 255, 0.8)",
        700: "rgba(250, 251, 255, 0.69)",
        500: "rgba(250, 251, 255, 0.5)",
        350: "rgba(250, 251, 255, 0.35)",
        200: "rgba(250, 251, 255, 0.23)",
        150: "rgba(250, 251, 255, 0.15)",
    },
    gradient: {
        primary: "linear-gradient(270deg, #1D154F 0%, #553F85 100%)",
        secondary: "linear-gradient(225deg, #E994E8 0%, #A2CBFB 49.48%, #8AEDE7 100%",
        context4of20: "linear-gradient(85.19deg, #6C67D5 3.42%, #261B70 104.62%)",
        context5of50: "linear-gradient(90deg, #447ACF 0%, #1451B1 100%)",
        context8of20: "linear-gradient(90deg, #BF4288 0%, #7A388D 100%)",
        context5of36: "linear-gradient(90deg, #984BAF 0%, #5B2B94 100%)",
    },
};
