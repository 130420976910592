import { useCallback, useContext } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import PhoneBlock from "../../phone-block/phone-block";

export const useCreateAccount = () => {
    const { setContent } = useContext(ModalWindowContext);
    return useCallback(async () => {
        setContent({ component: PhoneBlock, options: { btnClose: true } });
    }, [setContent]);
};
