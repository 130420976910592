import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { PRIMARY_BLUE } from "~assets/styles/tokens/colors";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TAlias } from "~types/products";
import { ticketsColor } from "~constants/tickets-type";
import { TextBlock, TextDate, TicketImg, Wrapper } from "./theme";

type TTitleBlock = {
    time: string;
    drawNum: string;
    alias?: TAlias;
    logo?: string;
};

const TitleBlock = ({ time, drawNum, alias, logo }: TTitleBlock) => {
    const bg = alias ? ticketsColor[alias] : PRIMARY_BLUE;

    const { color } = useContext(ThemeContext);
    return (
        <Wrapper $bg={bg}>
            <TicketImg className="mb8--mobile" src={logo} alt="логотип билета" />
            <TextBlock>
                <TextAdaptive
                    className="mb4 mb0--tablet mr16--mobile "
                    size="text14"
                    tag="div"
                    color={color.background.secondaryElevated}
                >
                    Тираж № {drawNum}
                </TextAdaptive>
                <TextDate size="text16" tag="div" weight="medium" color={color.text.inverted}>
                    {time}
                </TextDate>
            </TextBlock>
        </Wrapper>
    );
};

export default TitleBlock;
