import { useCallback, Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";
import { cleanTokensData } from "~services/auth-tokens";
import { actionAuth } from "~store/user/actions";
import { LOTTERY } from "~constants/routes";
import useAppApi from "~hooks/useAppApi";

type TCloseDropDown = Dispatch<SetStateAction<boolean>>;
export function useLogout(setOpenDropDown?: TCloseDropDown) {
    const dispatch = useDispatch();
    const api = useAppApi();
    return useCallback(async () => {
        const { error } = await api.auth.logout();
        if (error) {
            return;
        }
        cleanTokensData();
        dispatch(actionAuth(false));
        setOpenDropDown && setOpenDropDown(false);
        window.location.href = LOTTERY;
    }, [dispatch, api.auth, setOpenDropDown]);
}
