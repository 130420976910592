import { getMoneyFormat } from "~helpers/format";
import { TLoginErrorCode } from "~types/auth";

export const phoneSendStatus = {
    TOO_MANY_ATTEMPTS: {
        code: "TOO_MANY_ATTEMPTS",
        value: "Вход в личный кабинет временно заблокирован",
    },
};

export const smsCodeStatus = {
    NOT_CORRECT_CODE: {
        code: "NOT_CORRECT_CODE",
        value: "Укажите корректный sms-код",
    },
    OUTDATED: {
        code: "OUTDATED",
        value: "Время действия sms-кода истекло. Запросите новый sms-код",
    },
    NOT_REMAIN_ATTEMPTS: {
        code: "NOT_REMAIN_ATTEMPTS",
        value: "Отправка нового кода временно недоступна",
    },
};

export const passwordStatus = {
    WRONG_MIN_LENGTH: {
        code: "WRONG_MIN_LENGTH",
        value: "Пароль должен содержать не менее 8 символов",
    },
    WRONG_MAX_LENGTH: {
        code: "WRONG_MAX_LENGTH",
        value: "Ввод более 20 символов недопустим",
    },
    NOT_CORRECT_PASS: {
        code: "NOT_CORRECT_PASS",
        value: "Пароль должен содержать строчные и прописные латинские буквы, а также цифры",
    },
    DO_NOT_MATCH: {
        code: "DO_NOT_MATCH",
        value: "Пароли не совпадают",
    },
};

export const phoneStatus = {
    PHONE_REQUIRED: {
        code: "PHONE_REQUIRED",
        value: "Не указан номер телефона",
    },
    NOT_CORRECT_PHONE: {
        code: "NOT_CORRECT_PHONE",
        value: "Некорректно указан номер телефона",
    },
};

export const emailStatus = {
    EMAIL_REQUIRED: {
        code: "EMAIL_REQUIRED",
        value: "Не указан email",
    },
    NOT_CORRECT_EMAIL: {
        code: "NOT_CORRECT_EMAIL",
        value: "Некорректно указан email",
    },
};

export const agreementStatus = {
    NOT_CORRECT_AGREEMENT: {
        code: "NOT_CORRECT_AGREEMENT",
        value: "Необходимо ознакомиться с политикой обработки данных",
    },
};

export const authStatus: Record<TLoginErrorCode, { code: TLoginErrorCode; value: string }> = {
    GATEWAY_AUTH_INVALID_CREDENTIAL: {
        code: "GATEWAY_AUTH_INVALID_CREDENTIAL",
        value: "Некорректно указан номер телефона или пароль",
    },
    GATEWAY_AUTH_LOCKED: {
        code: "GATEWAY_AUTH_LOCKED",
        value: "Вход в личный кабинет временно заблокирован",
    },
};

export const myTicketsStatus = {
    WRONG_MIN_LENGTH_NUMBER: {
        code: "WRONG_MIN_LENGTH_NUMBER",
        value: "Номер билета должен содержать не менее 12 символов",
    },
    WRONG_MIN_LENGTH_CODE: {
        code: "WRONG_MIN_LENGTH_CODE",
        value: "Проверочный код должен содержать не менее 5 символов",
    },
};

export const profileStatus = {
    NOT_CORRECT_EMAIL: {
        code: "NOT_CORRECT_EMAIL",
        value: "Некорректно указан адрес электронной почты",
    },
    NOT_CORRECT_NICKNAME: {
        code: "NOT_CORRECT_NICKNAME",
        value: "Псевдоним может содержать только буквы, цифры и специальные символы ! # $ % & ' ( ) * + , - . / < = >[  ] ^ _ ` ",
    },
    WRONG_MAX_LENGTH_NICKNAME: {
        code: "WRONG_MAX_LENGTH",
        value: "Псевдоним должен содержать не более 15 символов",
    },
    NOT_CORRECT_FIRSTNAME: {
        code: "NOT_CORRECT_FIRSTNAME",
        value: "Некорректно указано имя",
    },
    NOT_CORRECT_LASTNAME: {
        code: "NOT_CORRECT_LASTNAME",
        value: "Некорректно указана фамилия",
    },
    NOT_CORRECT_MIDDLENAME: {
        code: "NOT_CORRECT_MIDDLENAME",
        value: "Некорректно указано отчество",
    },
    NOT_CORRECT_BIRTHDAY: {
        code: "NOT_CORRECT_BIRTHDAY",
        value: "Пользователь не может быть младше 18 лет",
    },
    NOT_CORRECT_AVATAR: {
        code: "NOT_CORRECT_AVATAR",
        value: "Допустимые форматы: jpg, jpeg, png, bmp, gif, webp",
    },
};

export const payoutStatus = {
    VERIFICATION_MISSED: {
        code: "VERIFICATION_MISSED",
    },
    WRONG_MIN_AMOUNT: {
        code: "WRONG_MIN_AMOUNT",
        getValue: (amount: number) => `Минимальная сумма пополнения - ${getMoneyFormat(amount)} рублей`,
    },
    WRONG_MAX_AMOUNT: {
        code: "WRONG_MAX_AMOUNT",
        value: "Сумма вывода не может превышать сумму баланса кошелька",
    },
    WRONG_MAX_AMOUNT_FULL_IDENTIFICATION: {
        code: "WRONG_MAX_AMOUNT_FULL_IDENTIFICATION",
        getValue: (amount: number) => `Максимальная сумма вывода - ${getMoneyFormat(amount)} ₽ единоразово`,
    },
};

export const paymentStatus = {
    WRONG_MIN_AMOUNT: {
        code: "WRONG_MIN_AMOUNT",
        getValue: (amount: number) => `Минимальная сумма пополнения - ${getMoneyFormat(amount)} ₽`,
    },
    WRONG_MAX_AMOUNT_FULL_IDENTIFICATION: {
        code: "WRONG_MAX_AMOUNT_FULL_IDENTIFICATION",
        getValue: (amount: number) => `Максимальная сумма пополнения - ${getMoneyFormat(amount)} ₽ единоразово`,
    },
    WRONG_MAX_AMOUNT_ANON_UNDER_MAX_BASIC: {
        code: "WRONG_MAX_AMOUNT_ANON_LOWER_THAN_BASIC",
    },
    WRONG_MAX_AMOUNT_ANON_OVER_MAX_BASIC: {
        code: "WRONG_MAX_AMOUNT_ANON_HIGHER_THAN_BASIC",
    },
    WRONG_MAX_AMOUNT_BASIC_IDENTIFICATION: {
        code: "MAX_AMOUNT_BASIC_IDENTIFICATION",
    },
};

export const otherErrors = {
    isUniqAccount: "Пользователь с такими данными уже существует",
    getProfile: "Сбой при получении профиля",
    updateProfile: "Сбой при обновлении профиля",
    updateBalance: "Сбой при обновлении баланса",
    updateProfileTooLargeLogo: "Размер загружаемого файла не может превышать 10 Мб",
    setEmail: "Сбой при сохранении email",
    getIdentityEsia: "Ошибка подтверждения аккаунта Госуслуги",

    removeProfilePhoto: "Сбой при удалении фото",

    sendOrder: "Сбой при отправки заказа",
    sendPayment: "Сбой при пополнении",
    sendPayout: "Сбой при выводе средств",
    emptyAmount: "Введите сумму больше нуля",
    emptyTicket: "Заполните хотя бы один билет",
    getProducts: "Сбой при получении лотерей",
    getProduct: "Сбой при получении продукта",
    getDraw: "Сбой при получении тиражей",
    getHistory: "Сбой при получении истории тиражей",
    getWinnersHistory: "Сбой при получении истории победителей",
    getMyTickets: "Сбой при получении билетов",
    getMyTicket: "Сбой при получении билета",
    getMyTicketPayouts: "Ошибка при получении выплаты",
};
