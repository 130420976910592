import React, { useCallback, useContext } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { PasswordBlock } from "~organisms/auth-form";
import useAppApi from "~hooks/useAppApi";

export const useCheckCode = (setError: React.Dispatch<React.SetStateAction<string>>, isReset?: boolean) => {
    const api = useAppApi();
    const { setContent } = useContext(ModalWindowContext);
    return useCallback(
        async ({
            code,
            title,
            operationId,
            phoneNumber,
        }: {
            code: string;
            title: string;
            operationId: string;
            phoneNumber: string;
        }) => {
            const { error, result } = await api.auth.checkSmsCode(code, operationId);

            if (error) return;

            if (result!.error) {
                return setError(result!.error);
            }

            if (result!.correct) {
                setError("");
                setContent({
                    component: PasswordBlock,
                    props: { title, operationId, code, phoneNumber, isReset },
                    options: { btnClose: true },
                });
            }
        },
        [setContent, api.auth, setError, isReset],
    );
};
