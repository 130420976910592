import React, { useEffect } from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { NavigationInfo } from "~organisms/navigation-info";
import { NavigationSidebar } from "~organisms/navigation-sidebar";
import {
    CSSTransitionStyled,
    duration,
    NavigationWrapper,
    ScrollbarsStyled,
    ScrollbarThumbVertical,
    ScrollbarTrackVertical,
    SidebarBackground,
    SidebarFooter,
    SidebarMainContent,
    transitionName,
} from "~organisms/sidebar/theme";
import { getPhoneHelpFormat } from "~helpers/format";
import { nationalLotteryPhone } from "~constants/links";
import { LOTTERY, WINNINGS } from "~constants/routes";
import { useAppScreen } from "~hooks/useAppScreen";

type TSidebarProps = {
    active: boolean;
    onClose: () => void;
    className?: string;
};

const sidebarNavigation = [
    { id: "logo", icon: "home-icon", title: "Лотереи", link: LOTTERY },
    { id: "cup", icon: "cup-icon", title: "Победители", link: WINNINGS },
];

const Sidebar = ({ className, active, onClose }: TSidebarProps) => {
    const typeScreen = useAppScreen();

    useEffect(() => {
        active ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "visible");
    }, [active]);

    const sidebarClassname = className ? className : transitionName;
    return (
        <>
            <CSSTransitionStyled in={active} timeout={duration} unmountOnExit classNames={sidebarClassname}>
                <SidebarMainContent className={sidebarClassname}>
                    <ScrollbarsStyled
                        renderTrackVertical={(props) => (
                            <ScrollbarTrackVertical {...props} className="track-vertical" />
                        )}
                        renderThumbVertical={(props) => (
                            <ScrollbarThumbVertical {...props} className="thumb-vertical" />
                        )}
                        renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" />}
                        renderView={(props) => <div {...props} className="view" />}
                        autoHide
                    >
                        <NavigationSidebar
                            className="mb24 mb16--mobile"
                            onClose={onClose}
                            navigationItems={sidebarNavigation}
                        />
                        <NavigationWrapper>
                            <NavigationInfo
                                className="sidebar-info"
                                mobile={typeScreen === "mobile"}
                                onClose={onClose}
                            />
                        </NavigationWrapper>
                        <SidebarFooter className="mtauto">
                            <TextAdaptive weight="demiBold" tag="p" size="text16">
                                Горячая линия: {getPhoneHelpFormat(nationalLotteryPhone)}
                            </TextAdaptive>
                            {/*<MobileAppArea className={"mt24"} />*/}
                            {/*<SocialMediaAreaStyled className="mt24 mb0" />*/}
                        </SidebarFooter>
                    </ScrollbarsStyled>
                </SidebarMainContent>
            </CSSTransitionStyled>
            {active && <SidebarBackground onClick={onClose} />}
        </>
    );
};

export default Sidebar;
