import styled from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Skeleton } from "~atoms/skeleton";
import { WinnerCard } from "~organisms/winner-card";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const WinningsContent = styled.div`
    display: flex;
    flex-flow: column nowrap;
    padding: ${INDENT.m};
    background-color: ${({ theme }) => theme.color.background.primaryElevated};
    border-radius: 20px;

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm};
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs};
    }
`;

export const ButtonStyled = styled(ButtonAdaptive)<{ visible?: boolean }>`
    width: fit-content;
    display: ${({ visible = true }) => (visible ? "flex" : "none")};
`;

export const TitleBlock = styled.div``;

export const WinnerCardStyled = styled(WinnerCard)`
    ${adaptive.maxWidth.tablet} {
        width: 100%;
    }
    ${adaptive.maxWidth.mobile} {
        flex-direction: column;
        justify-content: space-between;
        height: 96px;
        & .winner-content {
            align-self: flex-start;
        }
        & .lottery-info {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            & > div {
                margin-bottom: 0;
            }
        }
        & .content-desktop {
            width: 100%;
        }
    }
`;

export const SkeletonStyled = styled(Skeleton)`
    height: 96px;
    border-radius: 12px;
    width: 493px;
    ${adaptive.maxWidth.desktopXXL} {
        width: unset;
    }
    ${adaptive.maxWidth.tablet} {
        height: 64px;
    }
`;
