import styled, { DefaultTheme } from "styled-components";
import { BLACK, BLUE_NATIVE, GREY_LIGHT, WHITE } from "~assets/styles/tokens/colors";
import { text14M } from "~assets/styles/tokens/typography";
import { disableStyle, transitionDefault } from "~assets/styles/utils";
import { INDENT } from "~tokens/sizes";
import { TVariant } from "./button";

const getVariantStyles = (variant: TVariant, theme: DefaultTheme) => {
    switch (variant) {
        case "primary": {
            return `
                color: ${theme.color.text.primary};
                background: ${theme.color.controls.primary.default};  
                background-size: 200%;
                background-position: left;
                
                &:hover {
                    background: ${theme.color.controls.primary.hover};  
                };
                &:active {
                     background: ${theme.color.controls.primary.active}; 
                };
            `;
        }
        case "secondary": {
            return `
                color: ${theme.color.text.quaternary};
                background: ${theme.color.controls.secondary.default};  
                &:hover {
                    background: ${theme.color.controls.secondary.hover};  
                };
                &:active {
                    background: ${theme.color.controls.secondary.active};  
                };
            `;
        }
        case "tertiary": {
            return `
                color: ${BLUE_NATIVE};
                background-color: ${GREY_LIGHT};
                border-color: ${GREY_LIGHT};

                &:hover {
                    color: #005FC6;
                };
                &:active {
                    color: #005FC6;

                };
            `;
        }
        case "transparent": {
            return `
                color: ${BLACK};
                background-color: transparent;
                border-color: transparent;
                
                &:hover {
                    color: ${theme.color.controls.secondary.hover};
                };
                &:active {
                    color: ${theme.color.controls.secondary.hover};
                };
            `;
        }
        case "black": {
            return `
                color: ${WHITE};
                background-color: ${BLACK};
                &:hover {
                    background-color: "#414040";
                };
                &:active {
                    background-color: "#414040";
                };
            `;
        }
        default: {
            return;
        }
    }
};

export const ButtonStyled = styled.button<{ variant: TVariant }>`
    position: relative;
    width: auto;
    height: 40px;
    padding: ${INDENT.none} ${INDENT.sm};
    border-radius: 4px;
    ${text14M};
    ${transitionDefault};
    ${({ variant, theme }) => getVariantStyles(variant, theme)}

    span {
        display: inline-block;
        vertical-align: middle;
    }

    .prefix-wrap,
    .suffix-wrap {
        position: relative;
        top: -3px;
        height: 20px;
        width: 20px;
    }

    .prefix-wrap {
        margin-right: ${INDENT.xxxs};
    }

    .suffix-wrap {
        margin-left: ${INDENT.xxxs};
    }

    &:disabled {
        ${disableStyle};
    }
`;
