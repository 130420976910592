import React from "react";
import { useStartIdentificationIdentity } from "~pages/profile/_hooks/useStartIdentificationIdentity";
import { paymentStatus, payoutStatus } from "~constants/error-messages";
import { VerificationBlockWrapper } from "./theme";
import { VerificationMissed, VerificationBasic, VerificationFull } from "./verification-content";

type TVerificationBlockProps = {
    className: string;
    message: string;
    code: string;
};

const verificationContent = {
    [payoutStatus.VERIFICATION_MISSED.code](confirmIdentity: () => void) {
        return <VerificationMissed onClick={confirmIdentity} />;
    },
    [paymentStatus.WRONG_MAX_AMOUNT_ANON_UNDER_MAX_BASIC.code](confirmIdentity: () => void) {
        return <VerificationBasic onClick={confirmIdentity} />;
    },
    [paymentStatus.WRONG_MAX_AMOUNT_ANON_OVER_MAX_BASIC.code]() {
        return <VerificationFull limit={15000} />;
    },
    [paymentStatus.WRONG_MAX_AMOUNT_BASIC_IDENTIFICATION.code]() {
        return <VerificationFull limit={60000} />;
    },
};

const VerificationBlock = ({ className, code }: TVerificationBlockProps) => {
    const confirmIdentity = useStartIdentificationIdentity();
    return (
        <VerificationBlockWrapper className={className}>
            {verificationContent[code](confirmIdentity)}
        </VerificationBlockWrapper>
    );
};

export default VerificationBlock;
