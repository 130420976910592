import styled from "styled-components";
import { INDENT } from "~assets/styles/tokens/sizes";
import { text14M } from "~assets/styles/tokens/typography";
import { headingXXLSX, transitionDefault } from "~assets/styles/utils";
import { PURPLE } from "~tokens/colors";

const QuestionsWrapper = styled.div`
    button {
        margin-top: ${INDENT.xs};
    }
    .question-header {
        & > div {
            color: ${({ theme }) => theme.color.text.secondary};
        }
        & div {
            ${transitionDefault};
            svg {
                color: ${({ theme }) => theme.color.icons.primary};
            }
        }
        &:hover div {
            color: ${PURPLE};
        }
    }
`;

const TitleBlock = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${INDENT.m};

    h2 {
        ${headingXXLSX}
    }

    a {
        ${text14M};
    }
`;

export { QuestionsWrapper, TitleBlock };
