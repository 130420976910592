import { css } from "styled-components";
import { adaptive, breakpoints } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { title24, title32, title38 } from "~tokens/typography";

export const headingXXLSX = css`
    ${title38}

    ${adaptive.maxWidth.tablet} {
        ${title32}
    }

    ${adaptive.maxWidth.mobileSmall} {
        ${title24}
    }
`;

export const disableStyle = css`
    opacity: 0.4;
    pointer-events: none;
`;

export const transitionDefault = css`
    transition: all 0.3s ease;
`;

export const mainContentWrapper = css`
    max-width: ${breakpoints.desktopXXL}px;
    margin-left: ${INDENT.auto};
    margin-right: ${INDENT.auto};
    width: 100%;
    ${adaptive.maxWidth.desktopXXL} {
        max-width: ${breakpoints.desktopXL}px;
    }
    ${adaptive.maxWidth.desktopXL} {
        max-width: ${breakpoints.tablet}px;
    }
    ${adaptive.maxWidth.tablet} {
        max-width: ${breakpoints.mobile}px;
    }
    ${adaptive.maxWidth.mobile} {
        max-width: none;
    }
`;

const offsetMain = INDENT.xs;
const offsetDesktopXL = INDENT.s28;
const offsetMobile = INDENT.xs;
const offsetTablet = INDENT.xs;

export const fieldsOffset = css`
    padding-left: ${offsetMain};
    padding-right: ${offsetMain};

    ${adaptive.maxWidth.desktopXXL2} {
        padding-left: ${offsetTablet};
        padding-right: ${offsetTablet};
    }

    ${adaptive.maxWidth.desktopXXL} {
        padding-left: ${offsetDesktopXL};
        padding-right: ${offsetDesktopXL};
    }

    ${adaptive.maxWidth.desktopXL} {
        padding-left: ${offsetTablet};
        padding-right: ${offsetTablet};
    }

    ${adaptive.maxWidth.mobile} {
        padding-left: ${offsetMobile};
        padding-right: ${offsetMobile};
    }
`;
