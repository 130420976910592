import { Link } from "react-router-dom";
import styled, { DefaultTheme } from "styled-components";
import { TICKET_GREY } from "~assets/styles/tokens/colors";
import { adaptive } from "~assets/styles/tokens/screen";
import { ArrowButton } from "./arrow-block/theme";
import { TicketNumber } from "./info-block/theme";

const getHoverDesk = (notLink: boolean, theme: DefaultTheme) => {
    if (notLink) return ``;

    return `
        &:hover {
            filter: drop-shadow(0px 4px 20px #00000026);
            // box-shadow: 0px 4px 20px 0px #00000026;

            ${ArrowButton}, ${TicketNumber} {
                color: ${theme.color.text.primarylink};
            }
        }
    `;
};

const TicketWrapper = styled(Link)<{ $notLink: boolean }>`
    display: inline-block;
    cursor: pointer;
    pointer-events: ${({ $notLink }) => ($notLink ? "none" : "initial")};
    transition: all 0.3s ease;
    width: 100%;
    overflow: hidden;
    border-radius: 14px;
    position: relative;
    &:before,
    &:after {
        display: block;
        content: "";
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid ${TICKET_GREY};
        z-index: 1;
        top: calc(78px - 9px);
        position: absolute;
    }
    &:before {
        left: -9px;
    }
    &:after {
        right: -9px;
    }

    ${adaptive.minWidth.tablet} {
        border-radius: 16px;
        &:before,
        &:after {
            width: 22px;
            height: 22px;
            top: calc(68px - 11px);
        }
        &:before {
            left: -11px;
        }
        &:after {
            right: -11px;
        }
    }
    ${adaptive.minWidth.desktopXL} {
        ${({ $notLink, theme }) => getHoverDesk($notLink, theme)};
        &:before,
        &:after {
            right: unset;
            top: unset;
            left: calc(25% - 11px);
        }
        &:before {
            top: -11px;
        }
        &:after {
            bottom: -11px;
        }
    }

    ${adaptive.minWidth.desktopXXL} {
        &:before,
        &:after {
            left: calc(22.3% - 11px);
        }
    }
`;

const TitleWrap = styled.div`
    width: 100%;
    height: 78px;

    ${adaptive.minWidth.tablet} {
        height: 68px;
    }

    ${adaptive.minWidth.desktopXL} {
        width: 25%;
        min-height: 164px;
        height: auto;
    }

    ${adaptive.minWidth.desktopXXL} {
        width: 22.2%;
    }
`;

const InfoWrap = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    ${adaptive.minWidth.tablet} {
        height: 172px;
        width: 100%;
    }

    ${adaptive.minWidth.desktopXL} {
        height: auto;
        width: 75%;
        /* flex-grow: 1;
        justify-content: space-between; */
    }
    ${adaptive.minWidth.desktopXXL} {
        width: 70.5%;
        flex-grow: 1;
    }
`;

const ArrowWrap = styled.div<{ $notLink: boolean }>`
    width: 7.3%;
    display: none;

    ${adaptive.minWidth.desktopXXL} {
        display: ${({ $notLink }) => ($notLink ? "none" : "block")};
    }
`;

const MaskWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    overflow: hidden;
    border: 1px solid ${TICKET_GREY};

    mask-image: linear-gradient(to bottom, black 0, black 68px, transparent 68px, transparent 100%),
        radial-gradient(circle at left 78px, transparent 8px, black 9px, black 70%, transparent 0),
        radial-gradient(circle at right 78px, transparent 8px, black 9px, black 70%, transparent 0),
        linear-gradient(to bottom, transparent 0, transparent 88px, black 88px, black 100%);

    ${adaptive.minWidth.tablet} {
        border-radius: 16px;
        mask-image: linear-gradient(to bottom, black 0, black 56px, transparent 56px, transparent 100%),
            radial-gradient(circle at left 68px, transparent 10px, black 11px, black 65%, transparent 0),
            radial-gradient(circle at right 68px, transparent 10px, black 11px, black 65%, transparent 0),
            linear-gradient(to bottom, transparent 0, transparent 80px, black 80px, black 100%);
    }
    ${adaptive.minWidth.desktopXL} {
        flex-direction: row;
        mask-image: linear-gradient(to right, black 0, black 22%, transparent 22%, transparent 100%),
            radial-gradient(circle at 25% top, transparent 10px, black 11px, black 20%, transparent 0),
            radial-gradient(circle at 25% bottom, transparent 10px, black 11px, black 20%, transparent 0),
            linear-gradient(to right, transparent 0, transparent 28%, black 28%, black 100%);
    }
    ${adaptive.minWidth.desktopXXL} {
        mask-image: linear-gradient(to right, black 0, black 20%, transparent 20%, transparent 100%),
            radial-gradient(circle at 22.2% top, transparent 10px, black 11px, black 15%, transparent 0),
            radial-gradient(circle at 22.2% bottom, transparent 10px, black 11px, black 15%, transparent 0),
            linear-gradient(to right, transparent 0, transparent 25%, black 25%, black 100%);
    }
`;

export { TicketWrapper, TitleWrap, InfoWrap, ArrowWrap, MaskWrapper };
