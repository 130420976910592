import { useCallback, useEffect } from "react";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useFetch } from "~hooks/useFetch";
import { useNotifications } from "~hooks/useNotifications";

export const useGetTicket = (id: string) => {
    const api = useAppApi();
    const { add } = useNotifications();

    const getTicket = useCallback(async () => {
        return await api.myTickets.getTicket(id);
    }, [api.myTickets, id]);

    const { error, loading, data } = useFetch(getTicket);

    useEffect(() => {
        if (error) {
            add({ message: error?.data?.message ?? otherErrors.getMyTicket });
        }
    }, [add, error]);

    return { isLoading: loading, isError: !!error, data };
};
