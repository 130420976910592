import styled from "styled-components";
import { TICKET_GREY, WHITE } from "~tokens/colors";

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px dashed ${TICKET_GREY};
    background: ${WHITE};
`;

const ArrowButton = styled.div`
    display: inline-block;
`;

export { ArrowButton, Wrapper };
