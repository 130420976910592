import Inputmask from "inputmask";

const currencyMask = {
    alias: "currency",
    showMaskOnHover: false,
    autoUnmask: true,
    rightAlign: false,
    digitsOptional: false,
    groupSeparator: " ",
    digits: 0,
    shortcuts: null,
    allowMinus: false,
    radixPoint: "",
};

const phoneMask = {
    mask: "+7 (999) 999-99-99",
    placeholder: "+7 (___) ___-__-__",
    autoUnmask: true,
    showMaskOnHover: false,
};

const dateMask = {
    mask: "99.99.9999",
    placeholder: "дд.мм.гггг",
    showMaskOnHover: false,
};

const passportMask = {
    mask: "9999 999999",
    placeholder: "_",
    autoUnmask: true,
    showMaskOnHover: false,
};

const ticketMask = {
    mask: "9999 9999 9999",
    placeholder: "_",
    autoUnmask: true,
    showMaskOnHover: false,
};

const ticketCode = {
    mask: "99999",
    placeholder: "_",
    autoUnmask: true,
    showMaskOnHover: false,
};

const smsCode = {
    mask: "9999",
    placeholder: "_",
    autoUnmask: true,
    showMaskOnHover: false,
};

const dateTimeMask = {
    mask: "99.99.9999 99:99",
    placeholder: "дд.мм.гггг --:--",
    showMaskOnHover: false,
};

export type TMaskVariant =
    | "currency"
    | "none"
    | "phone"
    | "date"
    | "datetime"
    | "passport"
    | "ticket"
    | "ticket_code"
    | "sms_code";

export const getMaskOptions = (type: TMaskVariant): Inputmask.Options => {
    return {
        currency: currencyMask,
        phone: phoneMask,
        date: dateMask,
        passport: passportMask,
        datetime: dateTimeMask,
        ticket: ticketMask,
        ticket_code: ticketCode,
        sms_code: smsCode,
        none: {},
    }[type];
};

export const getFormattedString = (value: string, type: TMaskVariant = "none") =>
    Inputmask.format(value, getMaskOptions(type));
