import { compose } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware as createRouterMiddleware } from "connected-react-router";
import { createBrowserHistory, createMemoryHistory } from "history";

export interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
    __INITIALISED__?: boolean;
    __STORE__?: any;
}

const history = __SERVER__
    ? createMemoryHistory()
    : createBrowserHistory({
          basename: "/",
      });
const routerMiddleware = createRouterMiddleware(history);
const devtools =
    !__SERVER__ &&
    (window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
    });
const composeEnhancers = !__SERVER__ && __DEV__ && devtools ? devtools : compose;

const middleware = [thunk, routerMiddleware];

export { history, composeEnhancers, middleware };
