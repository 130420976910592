import React from "react";
import styled from "styled-components";
import { Grid } from "~atoms/grid";
import { Col } from "~atoms/grid/col";
import { Row } from "~atoms/grid/row";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { StepCard } from "./step-card";

type TRule = {
    title: string;
    desc: string;
    note: string;
    img: string;
};
type TGameStepsProps = {
    className?: string;
    title: string;
    rulesList: TRule[];
    col?: {
        desktop: number;
        desktopXl?: number;
        tablet?: number;
        mobile?: number;
    };
};

const GameStepsWrapper = styled.section`
    display: block;
`;

const GameSteps = ({ rulesList, className, title, col }: TGameStepsProps) => {
    return (
        <GameStepsWrapper className={className}>
            <TitleAdaptive size={"title24"} tag={"h2"}>
                {title}
            </TitleAdaptive>
            <Grid>
                <Row>
                    {rulesList.map((rule, idx) => (
                        <Col
                            key={idx}
                            col={col?.desktop || 4}
                            desktopXL={col?.desktopXl || 12}
                            tablet={col?.tablet || 12}
                            mobile={col?.mobile || 12}
                            className={"mt24"}
                        >
                            <StepCard
                                imgSrc={rule.img}
                                imgTitle={rule.title}
                                descStep={rule.desc}
                                noteStep={rule.note}
                                titleStep={rule.title}
                            />
                        </Col>
                    ))}
                </Row>
            </Grid>
        </GameStepsWrapper>
    );
};

export default GameSteps;
