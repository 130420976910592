import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { GREY_DARK2 } from "~tokens/colors";
import { TAllTicketTypes } from "~store/tickets/types";
import { ticketOptions } from "../lottery-data-view";
import { LotteryContent } from "../theme";

type TLotteryContentDigitalProps = {
    className?: string;
    isJackpot?: boolean;
    // jackpot?: number | null;
    ticketType: TAllTicketTypes;
};

const LotteryContentDigital = ({ ticketType, isJackpot, className }: TLotteryContentDigitalProps) => {
    const { color } = useContext(ThemeContext);
    return (
        <LotteryContent
            $borderColor={isJackpot ? ticketOptions[ticketType].borderColor : GREY_DARK2}
            $type={ticketOptions[ticketType].type}
            className={className}
        >
            <TitleAdaptive tag="span" weight="medium" color={color.text.inverted} size="title24">
                {ticketOptions[ticketType].title}
            </TitleAdaptive>
            {/*{isJackpot ? (*/}
            {/*    <TitleAdaptive className={"mb8 mb4--tablet"} tag="span" color={WHITE} size="title38">*/}
            {/*        {ticketOptions[ticketType].title}*/}
            {/*        /!*{getMoneyFormat(jackpot)} ₽*!/*/}
            {/*    </TitleAdaptive>*/}
            {/*) : (*/}
            {/*    <SkeletonJackpot className="mb8 mb4--tablet" />*/}
            {/*)}*/}

            {/*{isJackpot ? (*/}
            {/*    <TextAdaptive size="text16" weight="demiBold" color={WHITE}>*/}
            {/*        {ticketOptions[ticketType].subtitle}*/}
            {/*    </TextAdaptive>*/}
            {/*) : (*/}
            {/*    <SkeletonText />*/}
            {/*)}*/}
        </LotteryContent>
    );
};

export default LotteryContentDigital;
