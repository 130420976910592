import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { StaticRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter as Router } from "connected-react-router";
import { ServerStyleSheet, StyleSheetManager, ThemeProvider } from "styled-components";
import GlobalStyle from "~assets/styles/globals";
import { beelineTheme } from "~assets/styles/theme";
import { CookiesAlert } from "~organisms/cookies-alert";
import { Notifications } from "~organisms/notifications";
import { Politics } from "~pages/politics";
import { initHttp } from "~services/http/http";
import { history } from "~store/middleware";
import { AppState } from "~store/rootReducer";
import { initStore } from "~store/store";
import { POLITICS_BY_ID } from "~constants/routes";
import { initApi } from "~api";
import { ApiProvider } from "~api/context";
import Index from "./pages";
import FallbackPage from "./pages/fallback";

type TContext = {
    url?: string;
    isSSR?: boolean;
    initialState?: AppState;
};

export async function getApp(context: TContext) {
    let store = initStore();
    const http = initHttp();
    const api = initApi(http);

    if (context.isSSR) {
        store = initStore(context.initialState);
    } else {
        // Загрузка данных на сервере
    }
    const sheet = new ServerStyleSheet();

    const app = createApp({ url: context.url, store, api, sheet });
    return { app, store, http, sheet, api };
}

function createApp({
    url = "/",
    store,
    api,
    sheet,
}: {
    url?: string;
    store: ReturnType<typeof initStore>;
    api: ReturnType<typeof initApi>;
    sheet: ServerStyleSheet;
}) {
    return <App store={store} url={url} api={api} sheet={sheet} />;
}

const App = ({
    url = "/",
    store,
    api,
    sheet,
}: {
    url?: string;
    store: ReturnType<typeof initStore>;
    api: ReturnType<typeof initApi>;
    sheet: ServerStyleSheet;
}) => {
    if (__SERVER__) {
        return (
            <StyleSheetManager sheet={sheet.instance}>
                <ErrorBoundary FallbackComponent={() => <FallbackPage />}>
                    <ApiProvider api={api}>
                        <StaticRouter location={url} context={{}}>
                            <Provider store={store}>
                                <Index />
                            </Provider>
                        </StaticRouter>
                    </ApiProvider>
                    <GlobalStyle />
                </ErrorBoundary>
            </StyleSheetManager>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={() => <FallbackPage />}>
            <ApiProvider api={api}>
                <Provider store={store}>
                    <ThemeProvider theme={beelineTheme}>
                        <Router history={history}>
                            <Switch>
                                <Route path={POLITICS_BY_ID} component={Politics} exact />
                                <Route
                                    render={() => (
                                        <>
                                            <CookiesAlert />
                                            <Notifications />
                                            <Index />
                                        </>
                                    )}
                                />
                            </Switch>
                        </Router>
                    </ThemeProvider>
                </Provider>
                <GlobalStyle theme={beelineTheme} />
            </ApiProvider>
        </ErrorBoundary>
    );
};
