import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { getDateFromISO8601 } from "~helpers/format";
import { TAllTicketTypes } from "~store/tickets/types";
import { TProductType } from "~types/products";
import { digitalAlias } from "~constants/routes";
import { ticketsSlogan } from "~constants/tickets-type";
import { LotteryContentBingo } from "./lottery-content-bingo";
import { LotteryContentDigital } from "./lottery-content-digital";
import { LotteryDataWrapper, NextEdition, SkeletonEdition } from "./theme";

type LotteryDataViewProps = {
    className?: string;
    withoutEdition?: boolean;
    isJackpot?: boolean;
    jackpot?: number | null;
    nextEdition?: string;
    ticketType: TAllTicketTypes;
};

type TTicketData = {
    title: string;
    subtitle: string;
    borderColor: string;
    type: TProductType;
};

export const ticketOptions: Record<TAllTicketTypes, TTicketData> = {
    ticket4in20: {
        title: ticketsSlogan[digitalAlias.t4in20],
        subtitle: "",
        borderColor: "#7B82E7",
        type: "DIGITAL",
    },
    ticket5in36: {
        title: ticketsSlogan[digitalAlias.t5in36],
        subtitle: "",
        borderColor: "#DB95D3",
        type: "DIGITAL",
    },
    ticket5in50: {
        title: ticketsSlogan[digitalAlias.t5in50],
        subtitle: "",
        borderColor: "#87BEFF",
        type: "DIGITAL",
    },
    ticket8in20: {
        title: ticketsSlogan[digitalAlias.t8in20],
        subtitle: "",
        borderColor: "#EB62A4",
        type: "DIGITAL",
    },
    bingo90: {
        title: "от",
        subtitle: "Cуперприз",
        borderColor: "#A0D8FF",
        type: "BINGO",
    },
    bingo75: {
        title: "от",
        subtitle: "Cуперприз",
        borderColor: "#FFE073",
        type: "BINGO",
    },
    bingo3x3: {
        title: "от",
        subtitle: "Cуперприз",
        borderColor: "#9865BD",
        type: "BINGO",
    },
};

const LotteryDataView = ({
    className,
    withoutEdition,
    isJackpot,
    ticketType,
    nextEdition,
    jackpot,
}: LotteryDataViewProps) => {
    const { color } = useContext(ThemeContext);

    return (
        <LotteryDataWrapper className={className}>
            {!withoutEdition && (
                <>
                    {nextEdition ? (
                        <NextEdition
                            className="mb16 mb12--mobile"
                            tag="span"
                            size="text16"
                            weight="medium"
                            color={color.text.inverted}
                        >
                            Ближайший тираж: {getDateFromISO8601(nextEdition, "DD MMM в HH:mm")}
                        </NextEdition>
                    ) : (
                        <SkeletonEdition className="mb16 mb12--mobile" />
                    )}
                </>
            )}
            {ticketOptions[ticketType].type === "DIGITAL" && (
                <LotteryContentDigital ticketType={ticketType} isJackpot={isJackpot} />
            )}
            {ticketOptions[ticketType].type === "BINGO" && (
                <LotteryContentBingo ticketType={ticketType} jackpot={jackpot} isJackpot={isJackpot} />
            )}
        </LotteryDataWrapper>
    );
};

export default LotteryDataView;
