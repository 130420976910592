import dayjs from "dayjs";
import "dayjs/locale/ru";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { isNil } from "lodash-es";
import { TClientBalance, TClientProfileIdentity, TIdentity } from "~types/client";
import { identityStatus } from "~constants/handbook";

dayjs.extend(utc);
dayjs.locale("ru");
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(duration);

export const getPhone = (phone: string) => "7" + phone;

// Получить строку
export const getString = (param: any) => {
    if (typeof param === "number") {
        return param + "";
    }

    return typeof param === "string" ? param : "";
};

export const getDateFromISO8601 = (value: string, format = "DD.MM.YYYY") => {
    return dayjs(value).format(format);
};

export const stringISOpattern = /(\d{2})\.(\d{2})\.(\d{4})/;

export const getDateFormat = (value: string, dateFormat: string, startFormat = "YYYY-MM-DD HH:mm:ss.SSS") => {
    return dayjs(value, startFormat).format(dateFormat);
};

// Получить последние 10 цифр телефона
export const getPhoneNumbers = (str: string | null | undefined) => {
    if (isNil(str)) return "";
    if (str.length < 11) return str;
    return str.slice(1);
};

// Получить значение формата "+7 999 99 99"
export const getPhoneFormated = (str: string) => {
    if (str.length > 10) return "";

    return str.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/i, "+7 $1 $2 $3 $4");
};

// Получить значение формата "8 800 333-7-333"
export const getPhoneHelpFormat = (str: string) => {
    if (str.length > 11) return "";

    return str.replace(/(\d{1})(\d{3})(\d{3})(\d{1})(\d{3})/i, "$1 $2 $3-$4-$5");
};

// Получить номер билета с отступами после каждых 4 знаков
export const getTicketNumber = (value: string) => {
    if (value.length < 4) return value;
    return value.match(/.{1,4}/g)?.join(" ");
};

export const maskPhoneWithDots = (str: string) => {
    if (!str) return "";

    if (str.indexOf("***") !== -1) {
        return str;
    }
    return str.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/i, "+7 ($1) ***-$3-$4");
};

// Отпарсить баланс, округление к нижнему значению
export const getParsedBalance = (balance: TClientBalance | number) => {
    if (!balance) return 0;

    if (typeof balance === "number") return balance;

    return Math.floor(balance.amount);
};

/*
 * Склонение существительных с числительными
 *
 * @param {number} num число, от которого будет зависеть форма слова
 * @param {string} form1 первая форма слова - День, Месяц, Год
 * @param {string} form2 вторая форма слова - Дня, Месяца, Года
 * @param {string} form3 третья форма слова - Дней, Месяцев, Лет
 *
 * @returns {string}
 */
export const pluralForm = (num: number, form1: string, form2: string, form3: string): string => {
    const n = Math.abs(num) % 100;
    const n1 = n % 10;

    if (n > 10 && n < 20) {
        return form3;
    }

    if (n1 > 1 && n1 < 5) {
        return form2;
    }

    if (n1 === 1) {
        return form1;
    }

    return form3;
};

export const getMoneyFormat = (value?: number | string | null) => {
    if (isNil(value)) return "";
    return String(value).replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$& ");
};

export const dateDifference = (date: string | null) => {
    const time: { weeks: number; days: number; hours: number; mins: number } = { weeks: 0, days: 0, hours: 0, mins: 0 };
    if (!date) return time;
    const currentData = dayjs().tz("Europe/Moscow").format("YYYY-MM-DDTHH:mm:ss.SSS");
    const parsedDate = dayjs(date);
    const diff = parsedDate.diff(currentData);
    time.weeks = dayjs.duration(diff).weeks();
    time.days = dayjs.duration(diff).days() % 7;
    time.hours = dayjs.duration(diff).hours();
    time.mins = !dayjs.duration(diff).minutes() && dayjs.duration(diff).seconds() ? 1 : dayjs.duration(diff).minutes();
    return time;
};

export const getTimeToStart = (w: number, d: number, h: number, m: number) => {
    if (!w && !d && !h && !m) return;
    const weeks = w ? `${w} нед` : "";
    const days = d ? `${d} дн` : "";
    const hour = h ? `${h} ч` : "";
    const min = m ? `${m} мин` : "";
    return `${weeks} ${days} ${hour} ${min}`;
};

export const isDateExpired = (date: string | undefined) => {
    if (!date) return;
    return dayjs(dayjs().tz("Europe/Moscow").format("YYYY-MM-DDTHH:mm:ss.SSS")).diff(dayjs(date)) > 0;
};

export const dateDifferenceInSecond = (date: string | null) => {
    if (!date) return 0;
    const currentData = dayjs().tz("Europe/Moscow").format("YYYY-MM-DDTHH:mm:ss.SSS");
    const parsedDate = dayjs(date);
    return parsedDate.diff(currentData, "second", false);
};

export const transformTime = (sec: number) => {
    if (sec < 0) {
        return {
            month: "0",
            week: "0",
            days: "0",
            hour: "0",
            sec: "0",
            min: "0",
        };
    }
    const min = Math.floor(sec / 60);
    const hour = Math.floor(min / 60);
    const days = Math.floor(hour / 24);
    const week = Math.floor(days / 7);
    const month = Math.floor(week / 4);
    return {
        month: String(month),
        week: String(week),
        days: String(days),
        hour: String(hour),
        min: min < 10 ? `0${min}` : String(min),
        sec: `0${Math.ceil(sec % 60)}`.slice(-2),
    };
};

export const getIdentityProfileStatus = (identity: TIdentity[] | null): TClientProfileIdentity => {
    if (identity === null) return { basic: identityStatus.UNKNOWN, full: identityStatus.UNKNOWN };
    return {
        basic: identity.find((item) => item.type === "ESIA")?.status || identityStatus.UNKNOWN,
        full: identity.find((item) => item.type === "CUPIS_FULL")?.status || identityStatus.UNKNOWN,
    };
};
