import { useMemo } from "react";
import { TTicketOptions } from "~pages/lottery/types";
import { TTicket, TTicketTypes } from "~store/tickets/types";
import { useAppSelector } from "~hooks/useAppSelector";

export const getFullFillTickets = (tickets: TTicket[], options: TTicketOptions) => {
    return tickets.filter((item) => {
        return (
            item.numbersSelected[0].length === options.field1.generateNum &&
            item.numbersSelected[1].length === options.field2.generateNum &&
            item.editionsSelected.length !== 0
        );
    });
};
export const useGetBuyTicketsInfoDigital = (ticketType: TTicketTypes, ticketOptions: TTicketOptions) => {
    const tickets = useAppSelector((state) => getFullFillTickets(state.tickets[ticketType].data, ticketOptions));
    const productId = useAppSelector((state) => state.tickets[ticketType].productId);

    const ticketsCount = useMemo(() => {
        return tickets.filter((item) => item).length;
    }, [tickets]);

    const editionsCount = useMemo(() => {
        return tickets.reduce((accum, item) => {
            return accum + item.editionsSelected.length;
        }, 0);
    }, [tickets]);

    const amount = useMemo(() => {
        return tickets.reduce((accum, item): number => {
            return accum + (item.amount ?? 0) * item.editionsSelected.length;
        }, 0);
    }, [tickets]);

    return { tickets, ticketsCount, editionsCount, amount, productId };
};
