import Scrollbars from "react-custom-scrollbars";
import styled from "styled-components";
import { DropdownButton } from "~molecules/dropdown-button";
import { TSelectProps } from "~organisms/select/select";
import { BLACK, GREY_DARK, GREY_DARK2, GREY_LIGHT, WHITE } from "~tokens/colors";
import { INDENT } from "~tokens/sizes";

export const SelectWrapper = styled.div<Pick<TSelectProps, "autoWidth">>`
    display: block;
    width: ${({ autoWidth }) => (autoWidth ? "auto" : "174px")};
`;

export const DropdownSelect = styled(DropdownButton)`
    width: 100%;
    & > button {
        background: ${WHITE};
        border: 1px solid ${GREY_DARK2};
        padding: ${INDENT.xxs};
        border-radius: 8px;
        width: 100%;
        height: 48px;
        &:hover span,
        &:focus span,
        &:hover span p,
        &:focus span p {
            color: ${BLACK};
        }
        & > span {
            width: 100%;
            justify-content: space-between;
        }
    }
    & > .dropdown-content {
        display: block;
        height: 246px;
        z-index: 5;
        width: 100%;
        top: calc(50px + ${INDENT.xxxxs});
        background: ${WHITE};
        border: 1px solid ${GREY_DARK2};
        padding: ${INDENT.xxs} ${INDENT.xxxs} ${INDENT.xxs} ${INDENT.xxs};
        border-radius: 8px;
        &:before {
            display: none;
        }
    }
`;

export const ScrollbarsStyled = styled(Scrollbars)`
    width: 100%;
    height: 100%;
    & > .view {
        & > div:first-child {
            margin-top: 0;
        }
        & > div:last-child {
            margin-top: 0;
        }
    }
    & .track-vertical {
        width: 7px;
        height: 100%;
        background: ${GREY_LIGHT};
        border-radius: 100px;
        padding: 2px;
        right: 0;
    }
    & .thumb-vertical {
        width: 3px;
        background: ${GREY_DARK};
        border-radius: 100px;
    }
`;
