import { capitalize } from "lodash-es";
import { getDateFromISO8601, getMoneyFormat, getString } from "~helpers/format";
import { TTicket } from "~store/lotteries/types";
import {
    TBingo,
    TBingoFields,
    TEdition,
    TEditionsHistoryBingoItem,
    TEditionsHistoryDigitalItem,
} from "~store/tickets/types";
import {
    TAlias,
    TBingoAlias,
    TBingoTicket,
    TDraw,
    TDrawHistoryItem,
    TPicture,
    TProduct,
    TTicketHistoryDetails,
} from "~types/products";
import { bingoAlias, ticketsAlias } from "~constants/routes";
import other4in20 from "../components/organisms/lottery-ticket/other-ticket/assets/4-20 - Another Lottery - Desktop.png";
import other5in36 from "../components/organisms/lottery-ticket/other-ticket/assets/5-36 - Another Lottery - Desktop.png";
import other5in50 from "../components/organisms/lottery-ticket/other-ticket/assets/5-50 - Another Lottery - Desktop.png";
import other8in20 from "../components/organisms/lottery-ticket/other-ticket/assets/8-20 - Another Lottery - Desktop.png";
import otherBingo3x3 from "../components/organisms/lottery-ticket/other-ticket/assets/Bingo-3x3 - Another Lottery - Desktop.png";
import otherBingo75 from "../components/organisms/lottery-ticket/other-ticket/assets/Bingo-75 - Another Lottery - Desktop.png";
import otherBingo90 from "../components/organisms/lottery-ticket/other-ticket/assets/Bingo-90 - Another Lottery - Desktop.png";

const orderTickets: Record<TAlias, number> = {
    "digital-4x20": 1,
    "digital-5x36": 2,
    "digital-5x50": 3,
    "digital-8x20": 4,
    "bingo-3x3": 5,
    "bingo-90": 6,
    "bingo-75": 7,
};

export const mainPrize: Partial<Record<TAlias, number>> = {
    "digital-5x36": 5000000,
    "digital-5x50": 10000000,
    "digital-8x20": 1000000,
};

export const getPrize = (alias: TAlias, amount?: number) => {
    const prize = !amount ? 0 : amount;

    // if (typeof hardCodePrize[alias] !== "undefined") {
    //     prize = hardCodePrize[alias] as number;
    // }

    return `${getMoneyFormat(prize)} ₽`;
};

export const getBingoMapping: Record<TBingoAlias, (array: number[]) => TBingoFields> = {
    "bingo-90": (array: number[]) => {
        return {
            main: array.slice(0, 27),
            sub: array.slice(27, 54),
        };
    },
    "bingo-75": (array: number[]) => {
        return {
            main: array.slice(0, 25),
        };
    },
    "bingo-3x3": (array: number[]) => {
        return {
            main: array.slice(0, 9),
        };
    },
};

export const getRoundsArrayDigital = (mainRounds: number[], subRounds: number[]): Array<number[]> => {
    if (!Array.isArray(mainRounds) || !Array.isArray(subRounds)) return [];
    return [mainRounds, subRounds];
};

// export const getRoundsArrayBingo = (rounds: number[], bingoAlias: TBingoAlias): Array<number[]> => {
//     if (!Array.isArray(rounds)) return [];
//     const { main, sub } = getBingoHistoryMapping[bingoAlias](rounds);
//     return sub ? [main, sub] : [main];
// };

const getSettingsImg = (alias: TAlias | null, pictures: TPicture[]) => {
    const images: Record<
        TAlias,
        {
            desktop: { col: number; type: "long" | "long-mobile" | "short" | "short-mobile"; url: string };
            desktopXL: { col: number; type: "long" | "long-mobile" | "short" | "short-mobile"; url: string };
            tablet: { col: number; type: "long" | "long-mobile" | "short" | "short-mobile"; url: string };
            mobile: { col: number; type: "long" | "long-mobile" | "short" | "short-mobile"; url: string };
            other: { col: number; type: "other"; url: string };
        }
    > = {
        "digital-4x20": {
            desktop: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            desktopXL: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            tablet: { col: 12, type: "long", url: pictures.find((pic) => pic.type === "MIDDLE")?.url || "" },
            mobile: { col: 12, type: "long-mobile", url: pictures.find((pic) => pic.type === "SMALL")?.url || "" },
            other: { col: 12, type: "other", url: getString(other4in20) },
        },
        "digital-5x36": {
            desktop: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            desktopXL: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            tablet: { col: 12, type: "long", url: pictures.find((pic) => pic.type === "MIDDLE")?.url || "" },
            mobile: { col: 12, type: "long-mobile", url: pictures.find((pic) => pic.type === "SMALL")?.url || "" },
            other: { col: 12, type: "other", url: getString(other5in36) },
        },
        "digital-5x50": {
            desktop: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            desktopXL: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            tablet: { col: 12, type: "long", url: pictures.find((pic) => pic.type === "MIDDLE")?.url || "" },
            mobile: { col: 12, type: "long-mobile", url: pictures.find((pic) => pic.type === "SMALL")?.url || "" },
            other: { col: 12, type: "other", url: getString(other5in50) },
        },
        "digital-8x20": {
            desktop: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            desktopXL: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            tablet: { col: 12, type: "long", url: pictures.find((pic) => pic.type === "MIDDLE")?.url || "" },
            mobile: { col: 12, type: "long-mobile", url: pictures.find((pic) => pic.type === "SMALL")?.url || "" },
            other: { col: 12, type: "other", url: getString(other8in20) },
        },
        "bingo-90": {
            desktop: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            desktopXL: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            tablet: { col: 12, type: "long", url: pictures.find((pic) => pic.type === "MIDDLE")?.url || "" },
            mobile: {
                col: 12,
                type: "long-mobile",
                url: pictures.find((pic) => pic.type === "SMALL")?.url || "",
            },
            other: { col: 12, type: "other", url: getString(otherBingo90) },
        },
        "bingo-75": {
            desktop: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            desktopXL: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            tablet: { col: 12, type: "long", url: pictures.find((pic) => pic.type === "MIDDLE")?.url || "" },
            mobile: {
                col: 12,
                type: "long-mobile",
                url: pictures.find((pic) => pic.type === "SMALL")?.url || "",
            },
            other: { col: 12, type: "other", url: getString(otherBingo75) },
        },
        "bingo-3x3": {
            desktop: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            desktopXL: { col: 6, type: "long", url: pictures.find((pic) => pic.type === "BIG")?.url || "" },
            tablet: { col: 12, type: "long", url: pictures.find((pic) => pic.type === "MIDDLE")?.url || "" },
            mobile: { col: 12, type: "long-mobile", url: pictures.find((pic) => pic.type === "SMALL")?.url || "" },
            other: { col: 12, type: "other", url: getString(otherBingo3x3) },
        },
    };

    if (!alias) {
        return {
            desktop: { col: 6, type: "long", url: "" },
            desktopXL: { col: 6, type: "long", url: "" },
            tablet: { col: 12, type: "long", url: "" },
            mobile: { col: 12, type: "long-mobile", url: "" },
            other: { col: 12, type: "other", url: "" },
        } as TTicket;
    }

    return images[alias];
};

const includeLottery = new Set([
    ticketsAlias.t5in50,
    ticketsAlias.t8in20,
    ticketsAlias.t5in36,
    bingoAlias.b90,
    bingoAlias.b75,
    bingoAlias.b3x3,
]);

export const fromAllApi = (products: TProduct[] | null): TTicket[] => {
    if (!products) {
        return [];
    }

    return products
        .filter(({ draw, alias }) => !!draw && includeLottery.has(alias))
        .map(({ name, productId, alias, draw, rounds, pictures }) => {
            return {
                id: alias,
                productId: productId,
                title: "Cуперприз",
                name: name,
                prize: `от ${getPrize(alias, draw?.jackpot)}`,
                // note: ticketsSlogan[alias as TDigitalAlias] || "",
                note: "",
                edition: `Тираж № ${draw!.drawNum}`,
                logoSrc: pictures.find((pic) => pic.type === "ICON_BIG")?.url || "",
                logoSrcSmall: pictures.find((pic) => pic.type === "ICON_SMALL")?.url || "",
                amount: getMoneyFormat(rounds.find((round) => round.roundNum === 1)?.price),
                time: getDateFromISO8601(draw!.drawDate),
                active: !!draw?.drawNum && !!draw?.drawDate && !!draw?.saleDate,
                ...getSettingsImg(alias, pictures),
            };
        })
        .sort((pic1, pic2) => orderTickets[pic1.id] - orderTickets[pic2.id]);
};

export const fromGetProductApi = (draw: TDraw[] | null): TEdition[] => {
    if (!draw) {
        return [];
    }

    return draw.map((item) => ({
        saleDate: item.saleDate,
        drawDate: item.drawDate,
        edition: item.drawNum,
        id: item.productId,
    }));
};

export const fromGetDrawsHistoryDigital = (draw: TDrawHistoryItem[] | null): Array<TEditionsHistoryDigitalItem> => {
    if (!draw) {
        return [];
    }

    return draw.map(({ drawDate, drawNum, rounds, jackpot, productId }) => {
        return {
            productId,
            date: getDateFromISO8601(drawDate, "D MMMM"),
            time: getDateFromISO8601(drawDate, "HH:mm"),
            edition: drawNum,
            numbers: getRoundsArrayDigital(rounds[0].main, rounds[0].sub),
            prize: jackpot,
            interval: capitalize(getDateFromISO8601(drawDate, "MMMM, YYYY")),
        };
    });
};

export const fromGetDrawsHistoryBingo = (
    alias: TBingoAlias,
    draw: TDrawHistoryItem[] | null,
): Array<TEditionsHistoryBingoItem> => {
    if (!draw) {
        return [];
    }

    return draw.map(({ drawDate, drawNum, rounds, jackpot, productId }) => {
        return {
            productId,
            date: getDateFromISO8601(drawDate, "D MMMM"),
            time: getDateFromISO8601(drawDate, "HH:mm"),
            edition: drawNum,
            numbers: rounds[0].main,
            prize: jackpot,
            interval: capitalize(getDateFromISO8601(drawDate, "MMMM, YYYY")),
        };
    });
};

export const fromGetBingoTickets = (arr: TBingoTicket[] | null, ticketType: TBingoAlias): TBingo[] => {
    if (!arr) {
        return [];
    }

    return arr.map(({ combination, ticketId, amount }) => ({
        serialNum: ticketId,
        fields: getBingoMapping[ticketType](combination),
        amount: amount,
    }));
};

export const fromHistoryDetails = (result: TTicketHistoryDetails) => {
    return {
        ...result,
        date: getDateFromISO8601(result.drawDate, "DD MMMM YYYY"),
        time: getDateFromISO8601(result.drawDate, "HH:mm МСК"),
    };
};
