import React from "react";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { CallIcon, MailIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import { Link } from "~organisms/contacts-bar/theme";
import { Maps } from "~organisms/maps";
import { Page } from "~templates/page";
import { crumbs } from "~pages/contacts/helpers";
import { ContactsContent, ContactWrapper, IconWrapper, TextWrapper } from "~pages/contacts/theme";
import { getPhoneHelpFormat } from "~helpers/format";
import { nationalLotteryEmail, nationalLotteryPhone } from "~constants/links";

const col = {
    mainContent: {
        desktop: 9,
        desktopXL: 9,
    },
};

const Contacts = () => {
    const mainContent = (
        <ContactsContent className="mb64 mb48--tablet mb32--mobile">
            <TextWrapper className="mb32 mb24--tablet mb16--mobile">
                <TextAdaptive className="mb8" size="text16" weight="demiBold">
                    Юридический адрес:
                </TextAdaptive>
                <TextAdaptive size="text16" weight="medium">
                    Россия, 142210, Московская область, г. Серпухов, Клубный пер., д. 1/2, пом. 203
                </TextAdaptive>
            </TextWrapper>
            <TextWrapper className="mb32 mb24--tablet mb16--mobile">
                <TextAdaptive className="mb8" size="text16" weight="demiBold">
                    Почтовый адрес:
                </TextAdaptive>
                <TextAdaptive size="text16" weight="medium">
                    115114 г. Москва АЯ 1БЦ-1 ООО «Спортивные Лотереи»
                </TextAdaptive>
            </TextWrapper>
            <ContactWrapper className="mb32 mb24--tablet mb20--mobile">
                <IconWrapper className="mr8">
                    <CallIcon />
                </IconWrapper>
                <Link href={`tel:${nationalLotteryPhone}`}>
                    <TextAdaptive size="text16" weight="medium">
                        {getPhoneHelpFormat(nationalLotteryPhone)}
                    </TextAdaptive>
                </Link>
            </ContactWrapper>
            <ContactWrapper className="mb32 mb24--tablet mb20--mobile">
                <IconWrapper className="mr8">
                    <MailIcon />
                </IconWrapper>
                <Link href={`mailto:${nationalLotteryEmail}`}>
                    <TextAdaptive size="text16" weight="medium">
                        {nationalLotteryEmail}
                    </TextAdaptive>
                </Link>
            </ContactWrapper>
            <TextAdaptive size="text16" weight="demiBold">
                Оператор лотереи:
            </TextAdaptive>
            <TextAdaptive className="mb32 mb24--tablet mb32--mobile" size="text16" weight="demiBold">
                ООО «Спортивные Лотереи»
            </TextAdaptive>
            <Maps />
        </ContactsContent>
    );

    const headerBlock = <Breadcrumbs data={crumbs} className="mb32 mb24--tablet" />;

    return <Page defaultBackground title={"Контакты"} mainContent={mainContent} headerBlock={headerBlock} col={col} />;
};

export default Contacts;
