import { CONTACTS, HOME } from "~constants/routes";

export const crumbs = [
    {
        title: "Главная",
        path: HOME,
        exact: true,
    },
    {
        title: "Контакты",
        path: CONTACTS,
        exact: true,
    },
];
