import React, { ForwardedRef, ReactNode, useCallback, useContext } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { SelectModalBlockDigital } from "~organisms/select-modal/select-modal-block";
import SelectModalView from "~organisms/select-modal/select-modal-view";
import { TTicketTypes } from "~store/tickets/types";
import { TDigitalAlias } from "~types/products";

type TVariant = "filled" | "outlined";

export type TSelectModalProps = {
    className?: string;
    multiple?: boolean;
    autoWidth?: boolean;
    variant?: TVariant;
    suffixIcon?: ReactNode;
    idx: number;
    title: string;
    ticketType: TTicketTypes;
    ticketAlias: TDigitalAlias;
};

const SelectModalDigital = React.forwardRef(function SelectModal(
    props: TSelectModalProps,
    ref: ForwardedRef<HTMLDivElement>,
) {
    const { className, autoWidth, idx, suffixIcon, ticketType, ticketAlias, title } = props;
    const { setContent } = useContext(ModalWindowContext);

    const handleOpenSelectModal = useCallback(() => {
        setContent({
            component: SelectModalBlockDigital,
            props: {
                title: "Выберите количество тиражей",
                idx,
                ticketType,
                ticketAlias,
            },
            options: {
                btnClose: true,
                btnVariant: "secondary",
                btnIntent: false,
                borderRadius: 12,
            },
        });
    }, [idx, setContent, ticketAlias, ticketType]);

    return (
        <SelectModalView
            ref={ref}
            title={title}
            suffixIcon={suffixIcon}
            autoWidth={autoWidth}
            className={className}
            handleOpen={handleOpenSelectModal}
        />
    );
});

export default SelectModalDigital;
