export const shadowBtn = `box-shadow: 0px 0px 0px 2px #00000033`;
export const shadowBtnActive = `box-shadow: 0px 0px 0px 4px #00000033`;
export const shadowField = `box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.2)`;
export const shadow100Value = "0px 4px 30px 0px #00000026";
export const shadowDropdownButtonContent = "box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15)";
export const shadowHeader = "box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.15)";
export const shadowBottomBar = "box-shadow: 0px -6px 25px rgba(0, 0, 0, 0.15)";
export const shadow100 = `box-shadow: ${shadow100Value}`;
export const shadowBasketTicket = `box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1)`;
export const shadowScrollTopBtn = "box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15)";
export const shadowScrollTopBtnActive = "box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15)";
export const shadowBuyTicketsBar = "box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.15)";
export const shadowTabBar = "box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05)";
