import React from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { getMoneyFormat } from "~helpers/format";
import {
    BarContent,
    BarNumbers,
    BarNumbersBlock,
    BarTotal,
    ButtonPay,
    ButtonPriceText,
    ButtonText,
    BuyTicketsBarWrapper,
} from "./theme";

type TBuyTicketsCardView = {
    amount: number;
    handleBuyNow: () => void;
    className?: string;
    ticketsCount?: number;
    editionsCount?: number;
};

const BuyTicketsBarView = ({ className, ticketsCount, editionsCount, amount, handleBuyNow }: TBuyTicketsCardView) => {
    return (
        <BuyTicketsBarWrapper className={className}>
            <BarContent>
                <BarNumbersBlock>
                    <BarNumbers>
                        <TextAdaptive size="text16" tag="span" weight="medium">
                            Билетов
                        </TextAdaptive>
                        <TextAdaptive size="text16" tag="p" weight="medium">
                            {ticketsCount}
                        </TextAdaptive>
                    </BarNumbers>
                    <BarNumbers>
                        <TextAdaptive size="text16" tag="span" weight="medium">
                            Количество тиражей
                        </TextAdaptive>
                        <TextAdaptive size="text16" tag="p" weight="medium">
                            {editionsCount}
                        </TextAdaptive>
                    </BarNumbers>
                </BarNumbersBlock>
                <BarTotal>
                    <TitleAdaptive tag="h2" size="title24" weight="medium">
                        Итого
                    </TitleAdaptive>
                    <TitleAdaptive tag="h2" size="title24" className="ml24" weight="medium">
                        {getMoneyFormat(amount)} ₽
                    </TitleAdaptive>
                </BarTotal>
                <ButtonPay size="large" intent onClick={handleBuyNow}>
                    <ButtonText>Оплатить сейчас</ButtonText>
                    <ButtonPriceText>
                        &nbsp; &bull; &nbsp;
                        {getMoneyFormat(amount)} ₽
                    </ButtonPriceText>
                </ButtonPay>
            </BarContent>
        </BuyTicketsBarWrapper>
    );
};

export default BuyTicketsBarView;
