import { TMutationActions } from "../mutation/types";

export type TTicket = {
    amount: number | null;
    editionsSelected: TEdition[] | [];
    numbersSelected: TNumbersArray[];
    editions: TEdition[];
    init: boolean | null;
};

export type TBingoFields = {
    main: Array<number>;
    sub?: Array<number>;
};

export type TBingo = {
    serialNum: string;
    fields: TBingoFields;
    amount: number;
};

export type TNumbersArray = number[] | [];

export type TEdition = {
    saleDate: string;
    drawDate: string;
    edition: string;
    id: string;
};

export type TEditionsHistoryDigitalItem = {
    date: string;
    time: string;
    edition: string;
    numbers: Array<number[]>;
    prize: number;
    interval: string;
};

export type TEditionsHistoryBingoItem = {
    date: string;
    time: string;
    edition: string;
    numbers: Array<number>;
    prize: number;
    interval: string;
};

export type TEditionsHistoryDigital = {
    data: TEditionsHistoryDigitalItem[] | [];
    totalPages: number | null;
};
export type TEditionsHistoryBingo = {
    data: TEditionsHistoryBingoItem[] | [];
    totalPages: number | null;
};

export type TTicketContent = {
    initTicket: boolean;
    data: Array<TTicket> | [];
    productId: null | string;
    logoSrc: string;
    editionsHistory: TEditionsHistoryDigital;
    jackpot: null | number;
    name: string;
};

export type TBingoContent = {
    initTicket: boolean;
    data: Array<TBingo> | [];
    ticketsSelected: string[];
    productId: null | string;
    logoSrc: string;
    editionsHistory: TEditionsHistoryBingo;
    editionsSelected: TEdition[] | [];
    editions: TEdition[] | [];
    jackpot: null | number;
    numbersSelected: number[] | [];
    name: string;
};

export type TBingoOrderData = {
    tickets: TBingo[];
    editionsSelected: TEdition[];
};

export type TTicketsState = {
    ticket4in20: TTicketContent;
    ticket8in20: TTicketContent;
    ticket5in36: TTicketContent;
    ticket5in50: TTicketContent;
};

export type TBingoState = {
    bingo90: TBingoContent;
    bingo75: TBingoContent;
    bingo3x3: TBingoContent;
};

export type TAllTicketsTypeState = TTicketsState & TBingoState;

export type TTicketTypes = keyof TTicketsState;
export type TBingoTypes = keyof TBingoState;

export type TAllTicketTypes = TTicketTypes | TBingoTypes;

export const RESET_TICKETS = "RESET_TICKETS";
export type TResetTicketsAction = {
    type: typeof RESET_TICKETS;
};

export type TTicketsActions = TMutationActions | TResetTicketsAction;
