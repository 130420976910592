import React, { memo, useCallback } from "react";
import { DiceBottomIcon, DiceEvenIcon, DiceIcon, DiceOddIcon, DiceTopIcon, EraserIcon } from "~atoms/icons";
import { RemoveButton } from "~atoms/remove-button";
import { Tooltip } from "~atoms/tooltip";
import {
    DistributionTypesWrapper,
    IconButton,
    IconWrapper,
    ResetButton,
    TicketFooterWrapper,
    RemoveButtonWrapper,
} from "~organisms/ticket-footer/theme";

type TTicketFooterProps = {
    id: number;
    showRemoveBtn?: boolean;
    onChange: (value: Array<Array<number>>) => void;
    className?: string;
    onReset?: () => void;
    onRemove?: () => void;
    isResettable: boolean;
    options: {
        field1: {
            total: number;
            generateNum: number;
        };
        field2: {
            total: number;
            generateNum: number;
        };
    };
};

const generateRandomIntegerInRange = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

const checkEvenOddHandlers = {
    odd: (number: number, array: Set<number>) => {
        if (number % 2 !== 0) array.add(number);
    },
    even: (number: number, array: Set<number>) => {
        if (number % 2 === 0) array.add(number);
    },
};

const TicketFooter = memo(
    ({
        id,
        onChange,
        className,
        onReset,
        onRemove,
        isResettable,
        options,
        showRemoveBtn = true,
    }: TTicketFooterProps) => {
        const generateRandomArray = useCallback(
            ({
                min,
                max,
                type,
                generateNum,
            }: {
                min: number;
                max: number;
                type?: "even" | "odd";
                generateNum: number;
            }) => {
                const checkEvenOdd = type && checkEvenOddHandlers[type as keyof typeof checkEvenOddHandlers];
                const resultArray = new Set<number>();
                while (resultArray.size !== generateNum) {
                    const randomInteger = generateRandomIntegerInRange(min, max);
                    checkEvenOdd ? checkEvenOdd(randomInteger, resultArray) : resultArray.add(randomInteger);
                }
                return Array.from(resultArray);
            },
            [],
        );

        const handleRandomDice = useCallback(() => {
            onChange([
                generateRandomArray({ min: 1, max: options.field1.total, generateNum: options.field1.generateNum }),
                generateRandomArray({ min: 1, max: options.field2.total, generateNum: options.field2.generateNum }),
            ]);
        }, [generateRandomArray, onChange, options]);

        const handleEvenDice = useCallback(() => {
            onChange([
                generateRandomArray({
                    min: 1,
                    max: options.field1.total,
                    generateNum: options.field1.generateNum,
                    type: "even",
                }),
                generateRandomArray({
                    min: 1,
                    max: options.field2.total,
                    generateNum: options.field2.generateNum,
                    type: "even",
                }),
            ]);
        }, [generateRandomArray, onChange, options]);

        const handleOddDice = useCallback(() => {
            onChange([
                generateRandomArray({
                    min: 1,
                    max: options.field1.total,
                    generateNum: options.field1.generateNum,
                    type: "odd",
                }),
                generateRandomArray({
                    min: 1,
                    max: options.field2.total,
                    generateNum: options.field2.generateNum,
                    type: "odd",
                }),
            ]);
        }, [generateRandomArray, onChange, options]);

        const handleTopDice = useCallback(() => {
            onChange([
                generateRandomArray({
                    min: 1,
                    max: Math.floor(options.field1.total / 2),
                    generateNum: options.field1.generateNum,
                }),
                generateRandomArray({
                    min: 1,
                    max: Math.floor(options.field2.total / 2),
                    generateNum: options.field2.generateNum,
                }),
            ]);
        }, [generateRandomArray, onChange, options]);

        const handleBottomDice = useCallback(() => {
            onChange([
                generateRandomArray({
                    min: Math.floor(options.field1.total / 2) + 1,
                    max: options.field1.total,
                    generateNum: options.field1.generateNum,
                }),
                generateRandomArray({
                    min: Math.floor(options.field2.total / 2) + 1,
                    max: options.field2.total,
                    generateNum: options.field2.generateNum,
                }),
            ]);
        }, [generateRandomArray, onChange, options]);

        return (
            <TicketFooterWrapper className={className}>
                <RemoveButtonWrapper className="ml32 ml24--tablet ml0--mobile">
                    {showRemoveBtn && (
                        <RemoveButton className="btn-remove" onClick={onRemove}>
                            Удалить
                        </RemoveButton>
                    )}
                </RemoveButtonWrapper>
                <DistributionTypesWrapper>
                    <IconWrapper data-tip="Случайно" data-for={`dice${id}`}>
                        <IconButton onClick={handleRandomDice}>
                            <DiceIcon />
                        </IconButton>
                        <Tooltip id={`dice${id}`} place="top" />
                    </IconWrapper>
                    <IconWrapper data-tip="Нечетные числа" data-for={`dice-odd${id}`}>
                        <IconButton onClick={handleOddDice}>
                            <DiceOddIcon />
                        </IconButton>
                        <Tooltip id={`dice-odd${id}`} place="top" />
                    </IconWrapper>
                    <IconWrapper data-tip="Четные числа" data-for={`dice-even${id}`}>
                        <IconButton onClick={handleEvenDice}>
                            <DiceEvenIcon />
                        </IconButton>
                        <Tooltip id={`dice-even${id}`} place="top" />
                    </IconWrapper>
                    <IconWrapper data-tip="Случайные из верхней половины поля" data-for={`dice-top${id}`}>
                        <IconButton onClick={handleTopDice}>
                            <DiceTopIcon />
                        </IconButton>
                        <Tooltip id={`dice-top${id}`} place="top" />
                    </IconWrapper>
                    <IconWrapper data-tip="Случайные из нижней половины поля" data-for={`dice-bottom${id}`}>
                        <IconButton onClick={handleBottomDice}>
                            <DiceBottomIcon />
                        </IconButton>
                        <Tooltip id={`dice-bottom${id}`} place="top" />
                    </IconWrapper>
                </DistributionTypesWrapper>
                <ResetButton
                    prefixIcon={<EraserIcon />}
                    variant="transparent"
                    onClick={onReset}
                    className="btn-reset"
                    isResettable={isResettable}
                >
                    Очистить
                </ResetButton>
            </TicketFooterWrapper>
        );
    },
);

TicketFooter.displayName = "TicketFooter";

export default TicketFooter;
