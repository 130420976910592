import styled from "styled-components";
import { Skeleton } from "~atoms/skeleton";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const LotteriesWrapper = styled.div`
    display: block;
`;

export const RulesWrapper = styled.div`
    display: block;
`;

export const ContentWrapper = styled.div`
  height: fit-content;
  padding: ${INDENT.m};
  background-color: ${WHITE};
  border-radius: 20px;

  ${adaptive.maxWidth.desktopXXL} {
    padding: ${INDENT.sm};
  }

  ${adaptive.maxWidth.tablet} {
    padding: ${INDENT.xs};
`;

export const SkeletonStyled = styled(Skeleton)`
    border-radius: 18px;
    height: 432px;
    ${adaptive.maxWidth.desktopXXL} {
        height: 420px;
    }
    ${adaptive.maxWidth.tablet} {
        height: 584px;
    }
`;
