import styled, { css, SimpleInterpolation } from "styled-components";
import { TTextAdaptiveSize } from "~atoms/text-adaptive/text-adaptive";
import { adaptive } from "~tokens/screen";
import { text10, text12, text14, text16, text18, text20 } from "~tokens/typography";

const getSize = (size: TTextAdaptiveSize): SimpleInterpolation => {
    switch (size) {
        case "text12": {
            return css`
                ${text12}
                ${adaptive.maxWidth.tablet} {
                    ${text10}
                }
            `;
        }
        case "text14": {
            return css`
                ${text14}
                ${adaptive.maxWidth.tablet} {
                    ${text12}
                }
            `;
        }
        case "text16": {
            return css`
                ${text16}
                ${adaptive.maxWidth.tablet} {
                    ${text14}
                }
            `;
        }
        case "text20": {
            return css`
                ${text20}
                ${adaptive.maxWidth.desktopXXL} {
                    ${text18}
                }
                ${adaptive.maxWidth.tablet} {
                    ${text16}
                }
            `;
        }
        default: {
            return;
        }
    }
};

export const TextAdaptiveWrapper = styled.div<{
    size: TTextAdaptiveSize;
    weight: SimpleInterpolation;
    color?: string;
}>`
    color: ${({ color, theme }) => (color ? color : theme.color.text.primary)};
    ${({ size }) => getSize(size)}
    &&& {
        ${({ weight }) => weight}
    }
`;
