import { useCallback } from "react";
import { emptyTicket } from "~store/tickets/reducer";
import { TTicketTypes } from "~store/tickets/types";
import { useMutationHandles } from "~hooks/useMutationHandles";

export const useAddTicket = (ticketType: TTicketTypes) => {
    const { handleFieldChange } = useMutationHandles("tickets");
    const handleAddTicket = useCallback(
        (idx: number) => {
            handleFieldChange(`${ticketType}.data.${idx}`, emptyTicket);
        },
        [handleFieldChange, ticketType],
    );

    return { handleAddTicket };
};
