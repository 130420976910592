import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Button } from "~atoms/button";
import { ArrowIcon } from "~atoms/icons";
import { GREY_DARK, RED } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const SliderSwiperWrapper = styled.div<{ navigation: boolean }>`
    display: block;
    position: relative;

    .swiper-wrapper {
        -webkit-transform-style: preserve-3d;

        .swiper-slide {
            -webkit-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
        }
    }
    .swiper-pagination-bullets {
        & > .swiper-pagination-bullet {
            width: 12px;
            height: 12px;
            display: inline-block;
            border-radius: 50%;
            margin: ${INDENT.none} ${INDENT.xxxs};
            opacity: 1;
            background: ${GREY_DARK};
            cursor: pointer;
            &.swiper-pagination-bullet-active {
                opacity: 1;
                background: ${RED};
            }
        }
    }
    .arrow-btn-prev,
    .arrow-btn-next {
        display: ${({ navigation }) => (navigation ? "flex" : "none")};
    }

    .swiper-button-disabled {
        display: none;
    }
`;

export const ArrowButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 50%;
    z-index: 10;
    top: 50%;
    padding: 0;
    height: 44px;
    width: 44px;
    color: ${RED};

    transform: translate(0, -50%);
    &.arrow-btn-next {
        right: -22px;
    }

    &.arrow-btn-prev {
        left: -22px;
    }

    span {
        padding-top: ${INDENT.xxxxs};
    }

    ${adaptive.maxWidth.tablet} {
        display: none;
    }
`;

export const ArrowStyled = styled(ArrowIcon)`
    transform: ${(props) => props.type === "prev" && "rotate(180deg)"};
`;
