import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { HeaderSlider } from "~organisms/header-slider";
import { InstantLotteriesSlider } from "~organisms/instant-lotteries-slider";
import { LotteryInfo } from "~organisms/lottery-info";
import OurWinnersArea from "~organisms/our-winners-area/our-winners-area";
import { Questions } from "~organisms/questions";
import { data } from "~organisms/questions/helpers";
import { Bingo3x3 } from "~pages/lottery/bingo-lottery/bingo-3x3";
import { Bingo75 } from "~pages/lottery/bingo-lottery/bingo-75";
import { Bingo90 } from "~pages/lottery/bingo-lottery/bingo-90";
import { Lottery5in36 } from "~pages/lottery/digital-lottery/lottery-5in36";
import { Lottery5in50 } from "~pages/lottery/digital-lottery/lottery-5in50";
import { Lottery8in20 } from "~pages/lottery/digital-lottery/lottery-8in20";
import { MomentaryLottery as MomentaryLottery1 } from "~pages/lottery/momentary-lottery/momentary1";
import { MomentaryLottery as MomentaryLottery2 } from "~pages/lottery/momentary-lottery/momentary2";
import { MomentaryLottery as MomentaryLottery3 } from "~pages/lottery/momentary-lottery/momentary3";
import {
    LOTTERY,
    LOTTERY_5_IN_36,
    LOTTERY_5_IN_50,
    LOTTERY_8_IN_20,
    LOTTERY_BINGO_3X3,
    LOTTERY_BINGO_75,
    LOTTERY_BINGO_90,
    MOMENTARY_1,
    MOMENTARY_2,
    MOMENTARY_3,
} from "~constants/routes";

const Lottery = () => {
    return (
        <Switch>
            <Route exact path={LOTTERY}>
                <HeaderSlider className={"mt--headerSlider"} />
                <LotteryInfo className={"mt32 container-app"} />
                <OurWinnersArea className={"mt64 mb64 mb48--mobile container-app"} />
                <InstantLotteriesSlider />
                <Questions data={data} className={"container-app mt64 mb64"} />
            </Route>
            {/*<Route path={LOTTERY_4_IN_20} component={Lottery4in20} />*/}
            <Route path={LOTTERY_8_IN_20} component={Lottery8in20} />
            <Route path={LOTTERY_5_IN_36} component={Lottery5in36} />
            <Route path={LOTTERY_5_IN_50} component={Lottery5in50} />
            <Route path={LOTTERY_BINGO_90} component={Bingo90} />
            <Route path={LOTTERY_BINGO_75} component={Bingo75} />
            <Route path={LOTTERY_BINGO_3X3} component={Bingo3x3} />
            <Route path={MOMENTARY_1} component={MomentaryLottery1} exact />
            <Route path={MOMENTARY_2} component={MomentaryLottery2} exact />
            <Route path={MOMENTARY_3} component={MomentaryLottery3} exact />
            {/*<Route path={MOMENTARY_4} component={MomentaryLottery4} exact />*/}
            {/*<Route path={MOMENTARY_5} component={MomentaryLottery5} exact />*/}
            {/*<Route path={MOMENTARY_6} component={MomentaryLottery6} exact />*/}
            {/*<Route path={MOMENTARY_7} component={MomentaryLottery7} exact />*/}
            {/*<Route path={MOMENTARY_8} component={MomentaryLottery8} exact />*/}
            {/*<Route path={MOMENTARY_9} component={MomentaryLottery9} exact />*/}
            <Redirect to={LOTTERY} />
        </Switch>
    );
};

export default Lottery;
