import React, { useEffect } from "react";
import styled from "styled-components";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { Box, Flex } from "~atoms/flex-box";
import { Page } from "~templates/page";
import { useGetBalance } from "~pages/profile/_hooks/useGetBalance";
import { Balance } from "./balance";
import { crumbs } from "./helpers";
import { Payment } from "./payment";
import { Payout } from "./payout";

const Content = styled.div``;

const col = {
    mainContent: {
        desktop: 9,
        desktopXL: 9,
    },
};

const Wallet = () => {
    const { handleGetBalance } = useGetBalance();

    useEffect(() => {
        void handleGetBalance();
    }, [handleGetBalance]);

    const mainContent = (
        <Content>
            <Balance className="mb32 mb16--mobile" />
            <Flex tablet={{ $direction: "column" }}>
                <Box flexGrow={1}>
                    <Payment className="mr32 mr24--desktopxl mr0--tablet mb32--tablet mb16--mobile" />
                </Box>
                <Box flexGrow={1}>
                    <Payout />
                </Box>
            </Flex>
        </Content>
    );

    const headerBlock = <Breadcrumbs data={crumbs} className="mb32 mb24--tablet" />;

    return (
        <Page mainContent={mainContent} headerBlock={headerBlock} title={"Мой кошелек"} defaultBackground col={col} />
    );
};

export default Wallet;
