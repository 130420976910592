import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    cleanTokensData,
    getAccessToken,
    getJWTPayload,
    isAccessTokenExpired,
    isValidToken,
    setTokens,
} from "~services/auth-tokens";
import { actionAuth } from "~store/user/actions";
import useAppApi from "~hooks/useAppApi";

let isAuth = false;
export function useAppInit() {
    const token = getAccessToken();
    const dispatch = useDispatch();
    const { auth } = useAppApi();

    useEffect(() => {
        (async function () {
            if (token && isValidToken(token)) {
                const payload = getJWTPayload(token);
                isAuth = !isAccessTokenExpired(payload!.exp as number);
            }
            if (isAuth) {
                dispatch(actionAuth(true));
            } else {
                cleanTokensData();
                const { result } = await auth.refresh();
                if (result && result.access_token) {
                    setTokens(result.access_token);
                    dispatch(actionAuth(true));
                }
            }
        })();
    }, [auth, dispatch, token]);
}
