import React from "react";
import HistoryBingo3x3 from "~pages/lottery/bingo-lottery/bingo-3x3/history/history";
import HistoryBingo75 from "~pages/lottery/bingo-lottery/bingo-75/history/history";
import HistoryBingo90 from "~pages/lottery/bingo-lottery/bingo-90/history/history";
import History5in36 from "~pages/lottery/digital-lottery/lottery-5in36/history/history";
import History5in50 from "~pages/lottery/digital-lottery/lottery-5in50/history/history";
import History8in20 from "~pages/lottery/digital-lottery/lottery-8in20/history/history";
import { TTicket } from "~store/lotteries/types";
import { TAlias } from "~types/products";
import { bingoAlias, digitalAlias, DRAW_HISTORY, getDrawHistoryById, LOTTERY } from "~constants/routes";

export const crumbs = [
    {
        title: "Главная",
        path: LOTTERY,
        exact: true,
    },
    {
        title: "Архив тиражей",
        path: DRAW_HISTORY,
        exact: false,
    },
];

export const tabs = (lotteries: TTicket[]) => {
    return lotteries.map((item) => ({
        id: getDrawHistoryById(item.id),
        title: item.name,
        icon: <img src={item.logoSrcSmall} alt={item.id} />,
    }));
};

export const getComponent = (id: TAlias) => {
    if (id === digitalAlias.t5in50) return History5in50;
    if (id === digitalAlias.t5in36) return History5in36;
    if (id === digitalAlias.t8in20) return History8in20;
    if (id === bingoAlias.b3x3) return HistoryBingo3x3;
    if (id === bingoAlias.b75) return HistoryBingo75;
    if (id === bingoAlias.b90) return HistoryBingo90;
};
