import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { Button } from "~atoms/button";
import { GREY_LIGHT, PURPLE, WHITE } from "~tokens/colors";
import { shadowDropdownButtonContent } from "~tokens/shadows";
import { INDENT } from "~tokens/sizes";

export const transitionContentName = "dropdown-button";
export const duration = 300;

export const DropdownButtonWrapper = styled.div`
    display: block;
    position: relative;
`;
export const DropdownButtonContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${INDENT.xxxs} ${INDENT.xs};
    border-radius: 8px;
    height: auto;
    min-width: 182px;
    background-color: ${WHITE};
    ${shadowDropdownButtonContent};
    position: absolute;
    top: calc(40px + ${INDENT.s} / 2);
    left: 50%;
    transform: translateX(-50%);

    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-bottom: 8px solid ${WHITE};
        top: -7px;
        left: 50%;
        transform: translateX(-50%);
    }

    &&& button {
        padding: 0;
        margin-bottom: ${INDENT.xxxs};

        .prefix-wrap {
            margin-right: ${INDENT.xxxs};
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    & > button,
    & > a {
        width: 100%;

        &:after {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            bottom: -${INDENT.xxxs};
            background-color: ${GREY_LIGHT};
        }

        &:last-child::after {
            display: none;
        }
    }
`;

export const CSSTransitionContentStyled = styled(CSSTransition)`
    &.${transitionContentName}-enter {
        opacity: 0;
        transform: translate(-50%, -50px);
    }

    &.${transitionContentName}-enter-active {
        opacity: 1;
        transform: translate(-50%, 0);
        transition: opacity ${duration}ms, transform ${duration}ms;
    }

    &.${transitionContentName}-exit {
        opacity: 1;
        transform: translate(-50%, 0);
    }

    &.${transitionContentName}-exit-active {
        opacity: 0;
        transform: translate(-50%, -50px);
        transition: opacity ${duration}ms, transform ${duration}ms;
    }
`;

export const DropdownPrefixIcon = styled.img`
    margin-right: ${INDENT.xxxs};
    height: 24px;
    width: 24px;
    border-radius: 50%;
`;

export const DropdownButtonTarget = styled(Button)`
    width: max-content;
    display: flex;
    align-items: center;

    &:hover > span p {
        color: ${PURPLE};
    }

    & > span {
        display: flex;
        align-items: center;
    }

    & > span .suffix-wrapper {
        width: 24px;
        height: 24px;
    }
`;
