import { useCallback, useContext } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { useResetTickets } from "~pages/lottery/_hooks/useResetTickets";
import { TBingoTypes } from "~store/tickets/types";
import { otherErrors } from "~constants/error-messages";
import { MY_TICKETS_UPCOMING } from "~constants/routes";
import useAppHistory from "~hooks/useAppHistory";
import { useNotifications } from "~hooks/useNotifications";
import { useSendOrderBingo } from "./useSendOrderBingo";
import { StatusBlock } from "../status-block";
import { WidgetBlock } from "../widget-block";

export const usePaymentBlockBingoLogic = (
    onClickClose: () => void,
    handleWidgetClose: () => void,
    ticketType: TBingoTypes,
) => {
    const { setContent, onClose } = useContext(ModalWindowContext);
    const { resetTickets } = useResetTickets();
    const { sendOrder } = useSendOrderBingo(ticketType);
    const { add } = useNotifications();
    const { go } = useAppHistory();

    const handleOpenMyTickets = useCallback(() => {
        go(MY_TICKETS_UPCOMING);
        onClose();
    }, [go, onClose]);

    const handleClose = useCallback(() => {
        resetTickets();
        onClickClose();
        onClose();
    }, [onClickClose, onClose, resetTickets]);

    const handleWalletPayment = useCallback(async () => {
        const { error, result } = await sendOrder("WALLET_PAY");
        if (error) {
            add({ message: error?.data?.message ?? otherErrors.sendOrder });
            onClickClose();
            return onClose();
        }
        if (result && result.paymentId && result.status && result.amount) {
            const { paymentId, status: typeStatus } = result;
            setContent({
                component: StatusBlock,
                props: { paymentId, typeStatus, handleClose, handleOpenMyTickets },
                options: { borderRadius: 16 },
            });
        }
    }, [add, handleClose, handleOpenMyTickets, onClickClose, onClose, sendOrder, setContent]);

    const handleCardPayment = useCallback(async () => {
        const { error, result } = await sendOrder("ACQUIRING_CARD");
        if (error) {
            add({ message: error?.data?.message ?? otherErrors.sendOrder });
            onClickClose();
            return onClose();
        }
        if (result && result.paymentToken && result.paymentId) {
            const { paymentId, paymentToken } = result;
            setContent({
                component: WidgetBlock,
                props: {
                    paymentId,
                    paymentToken,
                    ticketType,
                    handleClose: handleWidgetClose,
                },
                options: { onClickClose },
            });
        }
    }, [add, handleWidgetClose, onClickClose, onClose, sendOrder, setContent, ticketType]);

    const handleApplePayPayment = useCallback(async () => {
        const { error, result } = await sendOrder("CARD_APPLE_PAY_ACQUIRING");
        if (error) {
            add({ message: error?.data?.message ?? otherErrors.sendOrder });
            onClickClose();
            return onClose();
        }
        if (result && result.paymentToken && result.paymentId) {
            const { paymentId, paymentToken } = result;
            setContent({
                component: WidgetBlock,
                props: {
                    paymentId,
                    paymentToken,
                    ticketType,
                    handleClose: handleWidgetClose,
                },
                options: { onClickClose },
            });
        }
    }, [add, handleWidgetClose, onClickClose, onClose, sendOrder, setContent, ticketType]);

    return { handleCardPayment, handleWalletPayment, handleApplePayPayment };
};
