import styled from "styled-components";
import { CardBox } from "~atoms/box";
import { ComboCircle } from "~atoms/combo-circle";
import { Skeleton } from "~atoms/skeleton";
import { TextAdaptive } from "~atoms/text-adaptive";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { lineHeight34, lineHeight48 } from "~tokens/typography";
import { TProductType } from "~types/products";

const HistoryDetailsWrapper = styled(CardBox)`
    display: flex;
    flex-flow: column nowrap;

    ${adaptive.maxWidth.desktopXXL} {
        flex-basis: auto;
    }
`;

const getFieldsColumns = (type: TProductType) => {
    if (type === "DIGITAL") {
        return `
            ${adaptive.maxWidth.tablet} {
                flex-direction: column;
            }
        `;
    }

    return `
        ${adaptive.maxWidth.desktopXL} {
            flex-direction: column;
        }
    `;
};

const FieldsWrapper = styled.div<{ type: TProductType }>`
    display: flex;
    ${({ type }) => getFieldsColumns(type)};
`;

const Field = styled.div`
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-right: ${INDENT.m};
    }
`;

const BingoFieldsWrap = styled.div<{ type: "main" | "sub" }>`
    width: 100%;

    ${adaptive.minWidth.tablet} {
        width: ${({ type }) => (type === "main" ? "476px" : "100%")};
    }

    ${adaptive.minWidth.desktopXL} {
        width: ${({ type }) => (type === "main" ? "356px" : "241px")};
    }

    ${adaptive.minWidth.desktopXXL} {
        width: ${({ type }) => (type === "main" ? "476px" : "241px")};
    }
`;

const BallWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -${INDENT.xxxxxs};
`;

const Ball = styled(ComboCircle)`
    margin: ${INDENT.xxxxxs};

    ${adaptive.minWidth.tablet} {
        width: 44px;
        height: 44px;

        & > div {
            ${lineHeight48};
        }
    }

    ${adaptive.minWidth.desktopXL} {
        width: 32px;
        height: 32px;

        & > div {
            ${lineHeight34};
        }
    }

    ${adaptive.minWidth.desktopXXL} {
        width: 44px;
        height: 44px;

        & > div {
            ${lineHeight48};
        }
    }
`;

const Note = styled(TextAdaptive)`
    max-width: 554px;
`;

const SkeletonStyled = styled(Skeleton)``;

export { HistoryDetailsWrapper, BingoFieldsWrap, FieldsWrapper, Field, BallWrapper, Ball, Note, SkeletonStyled };
