import { About } from "~pages/about";
import { DrawHistory } from "~pages/draw-history";
import { Lottery } from "~pages/lottery";
import { Winnings } from "~pages/winnings";
import { ABOUT, DRAW_HISTORY, LOTTERY, routesTitle, WINNINGS } from "~constants/routes";

export const routes = [
    {
        url: LOTTERY,
        title: routesTitle[LOTTERY],
        component: Lottery,
    },
    {
        url: DRAW_HISTORY,
        title: routesTitle[DRAW_HISTORY],
        component: DrawHistory,
    },
    {
        url: WINNINGS,
        title: routesTitle[WINNINGS],
        component: Winnings,
    },
    {
        url: ABOUT,
        title: routesTitle[ABOUT],
        component: About,
    },
];
