import styled from "styled-components";
import { Linkback } from "~atoms/linkback";
import { WHITE } from "~tokens/colors";

const PageWrapper = styled.div``;

const HeaderContent = styled.div``;

const LinkbackStyled = styled(Linkback)`
    color: ${WHITE};
    span {
        color: ${WHITE};
    }
`;

export { PageWrapper, HeaderContent, LinkbackStyled };
