import styled from "styled-components";
import { TColorsTheme } from "~tokens/palette/types";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { TBingoTypes } from "~store/tickets/types";

const getTicketStyle = (ticketType: TBingoTypes, color: TColorsTheme) => {
    switch (ticketType) {
        case "bingo3x3":
            return `
                ${color.const.blueberry.light};
            `;
        case "bingo75":
            return `
                ${color.const.banana.darkest};
            `;
        case "bingo90":
            return `
                ${color.const.blueberry.light};
            `;
        default:
            break;
    }
};

export const MyNumbersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const MatrixNumbers = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 644px;
    height: fit-content;
    background: ${({ theme }) => theme.color.background.primaryElevated};
    border-radius: 20px;
    padding: ${INDENT.xs} ${INDENT.xs} ${INDENT.xxxs} ${INDENT.xs};
    ${adaptive.maxWidth.tablet} {
        width: 274px;
        height: fit-content;
    }
`;

export const BoxNumbersSelected = styled.div<{ $active: boolean; $focus: boolean; ticketType: TBingoTypes }>`
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ $active, theme: { color }, ticketType }) =>
        $active ? getTicketStyle(ticketType, color) : color.controls.secondary.default};
    border-radius: 8px;
    &:not(:last-child) {
        margin-right: ${INDENT.xxxs};
    }
    border: ${({ $focus, theme: { color } }) => `1px solid ${$focus ? color.background.stroke : "transparent"}`};
    ${adaptive.maxWidth.desktopXXL} {
        width: 42px;
        height: 42px;
    }
`;

export const ResultNumbers = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & > button {
        width: fit-content;
    }
    ${adaptive.maxWidth.tablet} {
        flex-direction: column;
        align-items: center;
    }
`;

export const NumbersContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
