const prizeData: Record<string, unknown>[] = [
    { sizeWin: 100000, numberWin: 1 },
    { sizeWin: 5000, numberWin: 4 },
    { sizeWin: 2000, numberWin: 20 },
    { sizeWin: 1000, numberWin: 2000 },
    { sizeWin: 500, numberWin: 1400 },
    { sizeWin: 200, numberWin: 2000 },
    { sizeWin: 100, numberWin: 5000 },
    { sizeWin: 40, numberWin: 31000 },
    { sizeWin: 20, numberWin: 250000 },
];

const jackpot = 100000;

const price = 20;

export { prizeData, jackpot, price };
