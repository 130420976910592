import { useCallback } from "react";
import { TClient, TClientDefault } from "~types/client";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";

export const useUpdateProfile = () => {
    const api = useAppApi();
    const store = useAppStore();
    return useCallback(
        async (part: Partial<TClient> = {}) => {
            const profile = store.getState().profile.data;
            const resultProfile = { ...profile, ...part };
            return await api.profile.updateProfile(resultProfile as TClientDefault);
        },
        [api.profile, store],
    );
};
