import { useCallback } from "react";
import { TTicket, TTicketTypes } from "~store/tickets/types";
import { useAppStore } from "~hooks/useAppStore";
import { useMutationHandles } from "~hooks/useMutationHandles";

export const useRemoveTicket = (ticketType: TTicketTypes) => {
    const { handleFieldChange } = useMutationHandles("tickets");
    const store = useAppStore();
    const handleRemoveTicket = useCallback(
        (number: number) => {
            const { tickets } = store.getState();
            const resultData = tickets[ticketType].data as TTicket[];
            handleFieldChange(
                `${ticketType}.data`,
                resultData.filter((_, idx) => idx !== number),
            );
        },
        [handleFieldChange, store, ticketType],
    );
    return { handleRemoveTicket };
};
