import React from "react";
import { PinOfflineIcon } from "~atoms/icons";
import { Text } from "~atoms/text";
import { IconWrapper, OfflineBlockWrapper } from "~organisms/offline-block/theme";
import { WHITE } from "~tokens/colors";

type TOfflineBlockProps = {
    className?: string;
};

const OfflineBlock = ({ className }: TOfflineBlockProps) => {
    return (
        <OfflineBlockWrapper className={className}>
            <IconWrapper className="mr8 mr4--mobile">
                <PinOfflineIcon />
            </IconWrapper>

            <Text size="text14" weight="demiBold" color={WHITE}>
                Продажа билетов только офлайн
            </Text>
        </OfflineBlockWrapper>
    );
};

export default OfflineBlock;
