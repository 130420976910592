import { AxiosInstance } from "axios";
import { adapters } from "~adapters";
import { ApiResponse } from "~services/http/types";
import { getParsedBalance } from "~helpers/format";
import { TClientBalance, TClientDefault, TClientResponse, TIdentityRes, TProfilePhoto } from "~types/client";

const enum URLs {
    URL_PROFILE = "/clients/profile",
}

export const createProfileApi = (http: AxiosInstance) => ({
    async getProfileData() {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TClientResponse>>(URLs.URL_PROFILE);
        return {
            error,
            result: adapters.profile.fromProfileData(result as TClientResponse),
        };
    },

    async updateProfile(data: TClientDefault) {
        const parsedData = adapters.profile.updateProfilePayload(data);
        const {
            data: { error, result },
        } = await http.put<ApiResponse<TClientDefault>>(URLs.URL_PROFILE, parsedData);
        return { error, result: adapters.profile.fromProfileData(result as TClientResponse) };
    },

    async changePrivacy(anonymous: boolean) {
        const {
            data: { error, result },
        } = await http.put<ApiResponse<never>>(URLs.URL_PROFILE + "/restrictions", { anonymous });
        return { error, result };
    },

    async uploadPhoto(file: File) {
        const formData = new FormData();
        formData.append("file", file);
        const {
            data: { error, result },
        } = await http.put<ApiResponse<TProfilePhoto>>(URLs.URL_PROFILE + `/photo`, formData);
        return { error, result };
    },

    async removePhoto() {
        const {
            data: { error, result },
        } = await http.delete<ApiResponse<TProfilePhoto>>(URLs.URL_PROFILE + `/photo`);
        return { error, result };
    },

    async startIdentificationIdentity() {
        const {
            data: { error, result },
        } = await http.post<ApiResponse<TIdentityRes>>(URLs.URL_PROFILE + "/identity/esia");
        return {
            error,
            result,
        };
    },

    async getBalance() {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TClientBalance>>(URLs.URL_PROFILE + "/balance");
        return {
            error,
            result: getParsedBalance(result),
        };
    },
});
