import React, { useMemo } from "react";
import { Link } from "~atoms/link";
import { TextAdaptive } from "~atoms/text-adaptive";
import { Title } from "~atoms/title";
import { GameSteps } from "~molecules/game-steps";
import { MomentaryTicketsBar } from "~organisms/momentary-tickets-bar";
import { Page } from "~templates/page";
import { LegalInfo } from "~pages/lottery/common/legal-info";
import { HeadContentBlock } from "~pages/lottery/momentary-lottery/common/head-content-block";
import { Map } from "~pages/lottery/momentary-lottery/common/map";
import { getCol, getRow } from "~pages/lottery/momentary-lottery/common/prize-fund/prize-fund";
import { Table } from "~pages/lottery/momentary-lottery/common/table";
import TableHorizontal from "~pages/lottery/momentary-lottery/common/table-horizontal/table-horizontal";
import ticket from "~pages/lottery/momentary-lottery/momentary3/assets/ticket.png";
import { paymentProcedure } from "~constants/links";
import { LOTTERY } from "~constants/routes";
import { useAppScreen } from "~hooks/useAppScreen";
import headerMobile from "./assets/ml-header-mobile.png";
import { crumbs } from "./helpers";
import { jackpot, price, prizeData } from "./prize";
import { rulesList } from "./rules";
import {
    BreadcrumbsStyled,
    HeadContent,
    LinkBackStyled,
    LinkMomentaryWrapper,
    MomentaryContent,
    ParagraphWrapper,
    PrizeFundWrapper,
    TableWrapper,
} from "../common/theme";

const MomentaryLottery = () => {
    const typeScreen = useAppScreen();
    const row = useMemo(() => getRow(typeScreen), [typeScreen]);
    const col = useMemo(() => getCol(typeScreen), [typeScreen]);
    const mainContent = (
        <>
            <HeadContent className="mb24 mb16--mobile">
                <HeadContentBlock
                    jackpot={jackpot}
                    price={price}
                    screen={typeScreen}
                    ticketImg={ticket}
                    ticketDirection="horizontal"
                />
            </HeadContent>
            <MomentaryContent className="mb64 mb48--mobile">
                <GameSteps title="Как играть" rulesList={rulesList} className="mb64 mb48--mobile" />
                <TableWrapper className="mb64 mb48--mobile">
                    <PrizeFundWrapper className="mb24">
                        <Title tag={"h2"} size={"title24"} className="mb8--mobile">
                            Призовой фонд
                        </Title>
                        <TextAdaptive size={"text16"} weight={"medium"}>
                            <Link href={paymentProcedure}>Порядок выплаты выигрышей в лотерею</Link>
                        </TextAdaptive>
                    </PrizeFundWrapper>
                    {typeScreen === "mobile" ? (
                        <Table col={col} data={prizeData} />
                    ) : (
                        <TableHorizontal row={row} data={prizeData} />
                    )}
                </TableWrapper>
                <Map className="mb64 mb48--mobile" />
                <ParagraphWrapper>
                    <LegalInfo
                        type="MOMENT"
                        keyDoc="vgl1b"
                        prizeInfo="50%"
                        algoInfo="Алгоритм определения выигрышей Таблица № 11, ВГЛ-1Б."
                    />
                </ParagraphWrapper>
            </MomentaryContent>
        </>
    );

    const asideContent = (
        <MomentaryTicketsBar direction={typeScreen.startsWith("desktop") ? "vertical" : "horizontal"} />
    );

    const headerBlock =
        typeScreen === "mobile" ? (
            <LinkMomentaryWrapper>
                <LinkBackStyled href={LOTTERY} className="mb24" />
            </LinkMomentaryWrapper>
        ) : (
            <BreadcrumbsStyled data={crumbs} className="mb32 mb24--desktopxl" />
        );

    return (
        <Page
            title={typeScreen !== "mobile" && "Путь к удаче"}
            mainContent={mainContent}
            asideContent={asideContent}
            headerBlock={headerBlock}
            defaultBackground={typeScreen !== "mobile"}
            headerWithImg
            headerHeight={"360px"}
            headerImg={headerMobile}
        />
    );
};
export default MomentaryLottery;
