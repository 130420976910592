import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const transitionName = "modal-window";
export const duration = 500;

export const ModalWindowWrapper = styled.div<{ borderRadius?: number }>`
    position: fixed;
    z-index: 1002;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: ${({ borderRadius }) => (borderRadius ? `${borderRadius}px` : "4px")};
    padding: ${INDENT.xs};
    max-width: 100%;
`;

export const CSSTransitionStyled = styled(CSSTransition)`
    &.${transitionName}-enter {
        opacity: 0;
        transform: translate(-50%, -70%);
    }
    &.${transitionName}-enter-active {
        opacity: 1;
        transform: translate(-50%, -50%);
        transition: opacity ${duration}ms, transform ${duration}ms;
    }
    &.${transitionName}-exit {
        transform: translate(-50%, -50%);
        opacity: 1;
    }
    &.${transitionName}-exit-active {
        opacity: 0;
        transform: translate(-50%, -70%);
        transition: opacity ${duration}ms, transform ${duration}ms;
    }
`;

export const ModalBackground = styled.div`
    filter: blur(1px);
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.4);
`;

export const ButtonClose = styled(ButtonAdaptive)`
    position: absolute;
    right: ${INDENT.xs};
    top: ${INDENT.xs};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 4px;
    margin-left: auto;
    padding: ${INDENT.xxxxs};
    & > span {
        max-height: 24px;
    }
    ${adaptive.maxWidth.tablet} {
        top: ${INDENT.xxxs};
        right: ${INDENT.xxxs};
    }
`;
