import styled from "styled-components";
import { Box } from "~atoms/flex-box";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const TableRules4in20Wrapper = styled.div`
    background: linear-gradient(95.03deg, #5d4eaa 4.29%, #261b70 124.87%);
    border-radius: 20px;
    padding: ${INDENT.s};

    ${adaptive.maxWidth.desktopXXL} {
        padding-left: ${INDENT.xs};
        padding-right: ${INDENT.xs};
    }
`;

export const TableHeadersStyled = styled.table`
    width: 100%;
    border-spacing: ${INDENT.xxxxs};

    tbody td {
        text-align: left;
        height: 80px;
    }
`;

export const BoxStyled = styled(Box)`
    overflow: auto;
`;

export const TableWrapper = styled.div`
    width: 764px;
    height: 432px;

    ${adaptive.maxWidth.tablet} {
        width: 764px;
    }
`;

export const TableStyled = styled.table`
    width: 100%;
    border-spacing: ${INDENT.xxxxs};

    tbody td {
        text-align: center;
        width: 28px;
        height: 80px;

        ${adaptive.maxWidth.tablet} {
            width: 28px;
        }

        &:nth-child(2n + 1) {
            background: rgba(16, 24, 32, 0.3);
        }

        &:nth-child(2n) {
            background: rgba(16, 24, 32, 0.15);
        }

        &.tableRow1 {
            border-radius: 12px 12px 0 0;
        }

        &.rowColors {
            background: rgba(16, 24, 32, 0.15);

            &:nth-child(1) {
                background: rgba(16, 24, 32, 0.3);
            }

            &:nth-child(4) {
                background: rgba(16, 24, 32, 0.3);
            }

            &:nth-child(5) {
                background: rgba(16, 24, 32, 0.3);
            }

            &:nth-child(8) {
                background: rgba(16, 24, 32, 0.3);
            }

            &:nth-child(9) {
                background: rgba(16, 24, 32, 0.3);
            }

            &:nth-child(11) {
                background: rgba(16, 24, 32, 0.3);
            }

            &:nth-child(12) {
                background: rgba(16, 24, 32, 0.3);
            }

            &:nth-child(15) {
                background: rgba(16, 24, 32, 0.3);
            }

            &:nth-child(16) {
                background: rgba(16, 24, 32, 0.3);
            }

            &:nth-child(18) {
                background: rgba(16, 24, 32, 0.3);
            }

            &:nth-child(19) {
                background: rgba(16, 24, 32, 0.3);
            }
        }

        &.tableRow3 {
            border-radius: 0 0 12px 12px;
        }

        &.tableRow4 {
            border-radius: 12px;
        }

        &.tableRow5 {
            border-radius: 12px;
        }

        &.tableRow3double {
            background: transparent;
        }
    }
`;
