import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Grid } from "~atoms/grid";
import { TextAdaptive } from "~atoms/text-adaptive";
import { NavigationInfo } from "~organisms/navigation-info";
import { getPhoneHelpFormat } from "~helpers/format";
import { nationalLotteryPhone } from "~constants/links";
import { HOME, NL_PERSONAL_DATA } from "~constants/routes";
import { NavigationMain } from "./navigation-main";
import { PaymentArea } from "./payment-area";
import {
    BottomContentWrapper,
    BottomInfoWrapper,
    BottomTextWrapper,
    BottomWrapper,
    FooterContent,
    FooterWrapper,
    LinkLogo,
    LinkStyled,
    LogoWrapper,
    RowStyled,
    WrapperAdultFrame,
} from "./theme";

type TFooterProps = {
    className?: string;
};

const { Col } = Grid;
const Footer = ({ className }: TFooterProps) => {
    const { color } = useContext(ThemeContext);
    return (
        <FooterWrapper className={className}>
            <FooterContent>
                <Grid>
                    <RowStyled>
                        <Col col={3} tablet={6} mobile={12}>
                            <NavigationMain />
                        </Col>
                        <Col col={6} tablet={6} mobile={12}>
                            <NavigationInfo />
                        </Col>
                        {/*<Col col={3} tablet={6} mobile={12}>*/}
                        {/*    <NavigationMediaWrapper>*/}
                        {/*        <MobileAppArea className="mb32" />*/}
                        {/*        <SocialMediaArea className="mb32 mb24--tablet mb32--mobile" />*/}
                        {/*    </NavigationMediaWrapper>*/}
                        {/*</Col>*/}
                        <Col col={3} tablet={6} mobile={12}>
                            <PaymentArea className="mb24" />
                        </Col>
                    </RowStyled>
                </Grid>
                <BottomWrapper>
                    <BottomContentWrapper>
                        <LogoWrapper className={"mb20--mobile"}>
                            <LinkLogo href={HOME} rel="home" aria-label="На главную Национальная лотерея">
                                {/*<img src={logo} alt="Логотип Национальной лотереи" />*/}
                                <div className="footer-logo-icon" />
                            </LinkLogo>
                        </LogoWrapper>

                        <BottomTextWrapper>
                            <TextAdaptive size={"text16"} color={color.blocks.footer.text.primary}>
                                Горячая линия: &nbsp;
                                <LinkStyled href={`tel:${nationalLotteryPhone}`}>
                                    {getPhoneHelpFormat(nationalLotteryPhone)}
                                </LinkStyled>
                            </TextAdaptive>
                            <TextAdaptive size={"text12"} color={color.blocks.footer.text.secondary} className={"mt4"}>
                                Лотереи проводятся на основании распоряжения Правительства Российской Федерации от 29
                                августа 2019 г. № 1921-р. Организатор лотерей: Министерство финансов Российской
                                Федерации. Оператор лотерей: ООО «Спортивные Лотереи». Тел.{" "}
                                {getPhoneHelpFormat(nationalLotteryPhone)}
                            </TextAdaptive>
                        </BottomTextWrapper>
                    </BottomContentWrapper>
                    <BottomInfoWrapper>
                        <LogoWrapper>
                            <TextAdaptive size={"text12"} color={color.blocks.footer.text.secondary}>
                                ©2021-2022 ООО «Спортивные Лотереи»
                            </TextAdaptive>
                        </LogoWrapper>
                        <BottomTextWrapper>
                            <TextAdaptive size={"text12"}>
                                <LinkStyled
                                    href={NL_PERSONAL_DATA}
                                    target={"blank"}
                                    color={color.blocks.footer.text.secondary}
                                >
                                    Политика обработки персональных данных
                                </LinkStyled>
                            </TextAdaptive>
                        </BottomTextWrapper>
                    </BottomInfoWrapper>
                    <WrapperAdultFrame>
                        <TextAdaptive size={"text14"} weight={"medium"} color={color.blocks.footer.note.color}>
                            +18
                        </TextAdaptive>
                    </WrapperAdultFrame>
                </BottomWrapper>
            </FooterContent>
        </FooterWrapper>
    );
};

export default Footer;
