import React, { useEffect, useState } from "react";
import { ArrowScrollTopIcon } from "~atoms/icons";
import { ScrollTopButtonStyled } from "./theme";

type TScrollTopButtonProps = {
    className?: string;
};

const ScrollTopButton = ({ className }: TScrollTopButtonProps) => {
    const [visible, setVisible] = useState<boolean>(false);

    const handleScroll = () => {
        if (window.scrollY > 350) {
            setVisible(true);
            return;
        }
        setVisible(false);
    };

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        document.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    });

    return (
        <ScrollTopButtonStyled className={className} onClick={handleClick} visible={visible}>
            <span role="img" className="icon-wrapper">
                <ArrowScrollTopIcon />
            </span>
        </ScrollTopButtonStyled>
    );
};

export default ScrollTopButton;
