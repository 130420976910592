import { useEffect } from "react";
import { useResetTickets } from "~pages/lottery/_hooks/useResetTickets";
import { useAddTicket } from "~pages/lottery/digital-lottery/_hooks/useAddTicket";
import { useGetDraw } from "~pages/lottery/digital-lottery/_hooks/useGetDraw";
import { TTicketTypes } from "~store/tickets/types";
import { TDigitalAlias } from "~types/products";
import { useAppSelector } from "~hooks/useAppSelector";

export const useGetLotteryDataDigital = (alias: TDigitalAlias, ticketType: TTicketTypes, autonomous: boolean) => {
    const { getDraw } = useGetDraw(alias, ticketType);
    const { handleAddTicket } = useAddTicket(ticketType);
    const { resetTickets } = useResetTickets();
    const nextEdition = useAppSelector((state) => state.tickets[ticketType].data[0]?.editions[0]?.drawDate);
    const jackpot = useAppSelector((state) => state.tickets[ticketType].jackpot);
    const isJackpot = jackpot !== null;

    useEffect(() => {
        if (autonomous) {
            handleAddTicket(0);
            void getDraw(0);
        }
        return resetTickets;
    }, [getDraw, autonomous, ticketType, resetTickets, handleAddTicket]);

    return { nextEdition, jackpot, isJackpot };
};
