import { DefaultTheme } from "styled-components";
import { AccessTimeIcon, CloseIcon } from "~atoms/icons";
import { TPaymentStatus } from "~types/my-tickets";

export const getStatusProps = (
    registrationStatus: TPaymentStatus,
    paymentStatus: TPaymentStatus,
    theme: DefaultTheme,
) => {
    if (registrationStatus === "CANCELED" && paymentStatus === "ACCEPTED") {
        return {
            color: theme.color.accent.negative,
            text: "Билет не зарегистрирован",
            iconColor: theme.color.accent.negative,
            icon: CloseIcon,
        };
    }
    switch (registrationStatus) {
        case "PROGRESS":
            return {
                color: theme.color.text.tertiary,
                text: "В процессе оплаты",
                iconColor: theme.color.icons.secondary,
                icon: AccessTimeIcon,
            };
        case "CANCELED":
            return {
                color: theme.color.text.negative,
                text: "Отклонено",
                iconColor: theme.color.accent.negative,
                icon: CloseIcon,
            };
        default:
        case "PROGRESS":
            return {
                color: theme.color.text.tertiary,
                text: "В процессе оплаты",
                iconColor: theme.color.icons.secondary,
                icon: AccessTimeIcon,
            };
    }
};
