import React, { useCallback, useContext } from "react";
import { ThemeContext } from "styled-components";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { BoxWrapper, ButtonNumber } from "./theme";

type TBoxProps = {
    className?: string;
    isActive: boolean;
    onClick: (num: number) => void;
    number: number;
};

const Box = ({ isActive, onClick, number, className }: TBoxProps) => {
    const { color } = useContext(ThemeContext);

    const handleClick = useCallback(() => {
        onClick(number);
    }, [number, onClick]);

    return (
        <BoxWrapper className={className} isActive={isActive}>
            <ButtonNumber size="large" variant="transparent" onClick={handleClick}>
                <TitleAdaptive size="title20" weight="normal" tag="span" color={color.text.primary}>
                    {number}
                </TitleAdaptive>
            </ButtonNumber>
        </BoxWrapper>
    );
};

export default Box;
