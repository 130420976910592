import React, { useEffect } from "react";
import { CardList } from "~molecules/card-list";
import { useGetProducts } from "~organisms/lottery-info/_hooks/useGetProducts";
import { TTicket } from "~store/lotteries/types";
import { TAlias } from "~types/products";
import { useAppSelector } from "~hooks/useAppSelector";
import { LotteryTicketStyled, LotteryTicketsWrapper, SkeletonStyled } from "./theme";

export type TDirectionType = "vertical" | "horizontal";
type TLotteryTicketsBarProps = {
    className?: string;
    title: string;
    direction: TDirectionType;
    lotteryType?: TAlias;
};

const otherLotteries = (lotteriesArray: TTicket[], type: TAlias) => {
    return lotteriesArray.filter((lottery) => lottery.id !== type);
};

const LotteryTicketsBar = ({ className, title, direction, lotteryType }: TLotteryTicketsBarProps) => {
    const { handleGetProducts } = useGetProducts();
    const lotteries = useAppSelector((state) =>
        lotteryType ? otherLotteries(state.lotteries.data, lotteryType) : state.lotteries.data,
    );
    useEffect(() => {
        if (!lotteries.length) void handleGetProducts();
    }, [handleGetProducts, lotteries.length]);
    return (
        <LotteryTicketsWrapper $direction={direction}>
            <CardList
                title={title}
                className={className}
                direction={direction}
                isDrag={direction === "horizontal"}
                renderCards={({ direction }) => (
                    <>
                        {!lotteries.length
                            ? Array(4)
                                  .fill(0)
                                  .map((_, idx) => <SkeletonStyled $direction={direction} key={idx} className="mt16" />)
                            : lotteries.map((item, index) => (
                                  <LotteryTicketStyled
                                      key={index}
                                      id={item.id}
                                      logoSrc={item.logoSrc}
                                      type={direction === "horizontal" ? item.mobile.type : item.other.type}
                                      backgroundUrl={direction === "horizontal" ? item.mobile.url : item.other.url}
                                      title={item.title}
                                      prize={item.prize}
                                      note={item.note}
                                      edition={item.edition}
                                      time={item.time}
                                      amount={item.amount}
                                      className={"ticket-card"}
                                      active={item.active}
                                  />
                              ))}
                    </>
                )}
            />
        </LotteryTicketsWrapper>
    );
};

export default LotteryTicketsBar;
