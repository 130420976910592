import { TWinnerMock } from "~organisms/last-winners-block/helper";
import { getMoneyFormat } from "~helpers/format";
import { TWinner } from "~types/winners";

export const fromGetWinners = (winners: TWinner[] | null): TWinnerMock[] => {
    if (!winners) {
        return [];
    }

    return winners.map(({ profile, prize, product, draw }) => {
        return {
            name: profile?.name,
            avatar: profile?.url,
            amount: `${getMoneyFormat(prize)} ₽`,
            lottery: product?.name,
            emblem: product?.url,
            draw: `Тираж № ${draw?.drawNum}`,
        };
    });
};
