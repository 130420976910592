import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import { useAppSelector } from "~hooks/useAppSelector";
import { useNotifications } from "~hooks/useNotifications";

const transitionName = "notifications";

const TransitionGroupStyled = styled(TransitionGroup)`
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    z-index: 10000;
    padding: 10px 10px 0 0;
`;

const CSSTransitionStyled = styled(CSSTransition)`
    &.${transitionName}-enter {
        opacity: 0;
    }
    &.${transitionName}-enter-active {
        transition: opacity 0.5s;
        opacity: 1;
    }

    &.${transitionName}-exit-active {
        transition: opacity 0.5s;
        opacity: 0;
    }
`;

const ToastStyled = styled.div`
    margin: 0 0 10px 0;
    background: rgb(0 0 0 / 80%);
    padding: 12px;
    border-radius: 8px;
    color: white;
`;

const Notifications = () => {
    const notifications = useAppSelector((state) => state.notification);
    const { remove } = useNotifications();
    const messages = notifications.map((note) => {
        return (
            <CSSTransitionStyled key={note.id} timeout={500} classNames={transitionName} unmountOnExit>
                <ToastStyled onClick={() => remove(note.id)}>{note.message}</ToastStyled>
            </CSSTransitionStyled>
        );
    });
    return <TransitionGroupStyled>{messages}</TransitionGroupStyled>;
};

export default Notifications;
