import React from "react";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { TextAdaptive } from "~atoms/text-adaptive";
import { Page } from "~templates/page";
import { WHITE } from "~tokens/colors";
import backgroundTopDesktop1280 from "./assets/Banner - Desktop - 1280 - 1.png";
import backgroundBottomDesktop1280 from "./assets/Banner - Desktop - 1280 - 2.png";
import backgroundTopDesktop1920 from "./assets/Banner - Desktop - 1920 - 1.png";
import backgroundBottomDesktop1920 from "./assets/Banner - Desktop - 1920 - 2.png";
import backgroundTopMobile from "./assets/Banner - Mobile - 1.png";
import backgroundBottomMobile from "./assets/Banner - Mobile - 2.png";
import backgroundTopTablet from "./assets/Banner - Tablet - 1.png";
import backgroundBottomTablet from "./assets/Banner - Tablet - 2.png";
import { Footer } from "./footer";
import { crumbs } from "./helpers";
import { Mission } from "./mission";
import { Principles } from "./principles";
import { AboutContent, Banner, SplitLine, TextBlock, TitleStyled } from "./theme";

const col = {
    mainContent: {
        desktop: 9,
        desktopXL: 9,
    },
};

const About = () => {
    const mainContent = (
        <AboutContent className="mb64 mb48--tablet mb32--mobile">
            <Mission className="mb64 mb48--tablet mb32--mobile" />
            <Banner
                className="mb32 mb24--tablet mb16--mobile"
                backgroundDesktop1920={backgroundTopDesktop1920}
                backgroundDesktop1280={backgroundTopDesktop1280}
                backgroundTablet={backgroundTopTablet}
                backgroundMobile={backgroundTopMobile}
                backgroundPosition="right"
                tall
            >
                <TextBlock>
                    <TitleStyled size="title24" tag="h4" color={WHITE} className="mb16 mb8--tablet">
                        Каждый купленный билет — помощь всей стране!
                    </TitleStyled>
                    <TextAdaptive size="text16" weight="medium" color={WHITE}>
                        Участвуя в лотерее, мы вносим вклад в развитие социальных проектов России, а также оказываем
                        поддержку российскому спорту.
                    </TextAdaptive>
                </TextBlock>
            </Banner>
            <Banner
                className="mb64 mb48--tablet mb32--mobile"
                backgroundDesktop1920={backgroundBottomDesktop1920}
                backgroundDesktop1280={backgroundBottomDesktop1280}
                backgroundTablet={backgroundBottomTablet}
                backgroundMobile={backgroundBottomMobile}
                backgroundPosition="left"
            >
                <TextAdaptive size="text16" weight="medium" color={WHITE}>
                    Мы поддерживаем ответственное отношение к игре и стремимся к тому, чтобы лотерея приносила
                    положительные эмоции и не вредила качеству жизни.
                </TextAdaptive>
            </Banner>
            <Principles className="mb64 mb48--tablet mb24--mobile" />
            <SplitLine className="mb64 mb48--tablet mb32--mobile" />
            <Footer />
        </AboutContent>
    );

    const headerBlock = <Breadcrumbs data={crumbs} className="mb32 mb24--tablet" />;

    return (
        <Page title={"О компании"} defaultBackground mainContent={mainContent} headerBlock={headerBlock} col={col} />
    );
};

export default About;
