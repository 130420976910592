import { useCallback, useContext } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import LoginBlock from "../login-block";

export const useLogin = () => {
    const { setContent } = useContext(ModalWindowContext);
    return useCallback(async () => {
        setContent({ component: LoginBlock, options: { btnClose: true } });
    }, [setContent]);
};
