import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Box, Flex } from "~atoms/flex-box";
import { TextAdaptive } from "~atoms/text-adaptive";
import { GREY_DARK } from "~tokens/colors";
import { adaptive } from "~tokens/screen";

type TStepCardProps = {
    className?: string;
    imgSrc: string;
    imgTitle: string;
    titleStep: string;
    descStep: string;
    noteStep: string;
};

const StepCardWrapper = styled.div`
    display: block;

    img {
        width: 100%;
        height: auto;

        ${adaptive.maxWidth.desktopXXL} {
            max-width: 320px;
        }

        ${adaptive.maxWidth.tablet} {
            max-width: none;
        }
    }

    ${adaptive.maxWidth.desktopXXL} {
        max-width: 642px;
    }
`;

const StepCard = ({
    imgSrc,
    imgTitle,
    titleStep,
    descStep,
    noteStep,
    className,
}: PropsWithChildren<TStepCardProps>) => {
    return (
        <StepCardWrapper className={className}>
            <Flex
                $direction={"column"}
                desktopXL={{ $direction: "row", alignItems: "flex-start" }}
                tablet={{ $direction: "row", alignItems: "flex-start" }}
                mobile={{ $direction: "column" }}
            >
                <Box className={"mb24 mb0--desktopxl mr24--desktopxl mr0--mobile"}>
                    <img src={imgSrc} alt={imgTitle} width={299} height={207} />
                </Box>
                <Box className={"mt16--mobile"}>
                    <TextAdaptive className={"mb8 mb4--mobile"} size={"text16"} weight={"demiBold"}>
                        {titleStep}
                    </TextAdaptive>
                    <TextAdaptive className={"mb8 mb4--mobile"} size={"text16"} weight={"medium"}>
                        {descStep}
                    </TextAdaptive>
                    <TextAdaptive size={"text14"} color={GREY_DARK}>
                        {noteStep}
                    </TextAdaptive>
                </Box>
            </Flex>
        </StepCardWrapper>
    );
};

export default StepCard;
