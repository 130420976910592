import { createGlobalStyle, css, DefaultTheme } from "styled-components";
import { fieldsOffset, mainContentWrapper } from "~assets/styles/utils";
import { normalize } from "~tokens/normalize";
import { fontFamily } from "~tokens/typography";
import { fontSize14, lineHeight20 } from "~tokens/typography";
import { indentClassNames } from "./indent-classnames";
import "../fonts/fonts.css";

export const containerApp = css`
    .container-app {
        ${mainContentWrapper};
        ${fieldsOffset};
    }
`;
const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
    ${normalize}

    #app {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 320px;
        min-height: 100%;
    };

    body {
        ${fontFamily};
        background: ${({ theme }) => theme.color.background.primary};
        ${fontSize14};
        ${lineHeight20};
        ${indentClassNames};
        ${containerApp};
    };
    
    html {
      scroll-behavior: smooth;
    };
`;

export default GlobalStyle;
