import React from "react";
import { Grid } from "~atoms/grid";
import { Col } from "~atoms/grid/col";
import { Row } from "~atoms/grid/row";
import { CharityIcon, HandshakeIcon, MagicWandIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { PrinciplesWrapper, Principle } from "./theme";

type TPrinciplesProps = {
    className?: string;
};

const Principles = ({ className }: TPrinciplesProps) => {
    return (
        <PrinciplesWrapper className={className}>
            <TitleAdaptive size="title24" className="mb32 mb24--mobile mb16--mobile">
                Наши принципы
            </TitleAdaptive>
            <Grid>
                <Row>
                    <Col col={4} mobile={12}>
                        <Principle className="mb16--mobile">
                            <HandshakeIcon className="mr16 mr0--tablet mb8--tablet mb0--mobile" />
                            <TextAdaptive size="text16" weight="medium" className="ml8--mobile">
                                Честность и прозрачность
                            </TextAdaptive>
                        </Principle>
                    </Col>
                    <Col col={4} mobile={12}>
                        <Principle className="mb16--mobile">
                            <CharityIcon className="mr16 mr0--tablet mb8--tablet mb0--mobile" />
                            <TextAdaptive size="text16" weight="medium" className="ml8--mobile">
                                Вклад в развитие страны
                            </TextAdaptive>
                        </Principle>
                    </Col>
                    <Col col={4} mobile={12}>
                        <Principle>
                            <MagicWandIcon className="mr16 mr0--tablet mb8--tablet mb0--mobile" />
                            <TextAdaptive size="text16" weight="medium" className="ml8--mobile">
                                Вдохновение людей на новые мечты
                            </TextAdaptive>
                        </Principle>
                    </Col>
                </Row>
            </Grid>
        </PrinciplesWrapper>
    );
};

export default Principles;
