import styled from "styled-components";
import { adaptive } from "~assets/styles/tokens/screen";
import { text14M } from "~assets/styles/tokens/typography";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { INDENT } from "~tokens/sizes";

const getPadding = (isRender: boolean) => {
    if (!isRender) {
        return `
            padding: ${INDENT.xs} ${INDENT.sm};

                ${adaptive.minWidth.tablet} {
                    padding: ${INDENT.sm};
                }
        `;
    }

    return;
};

const CollapseBtn = styled.button<{ open: boolean; isRender: boolean }>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.color.background.primaryElevated};
    border-radius: 12px;
    svg {
        transition: transform 0.5s ease;
        color: ${({ theme }) => theme.color.text.primary};
        transform: ${({ open }) => (open ? `rotate(270deg)` : `rotate(90deg)`)};
    }

    ${({ isRender }) => getPadding(isRender)};
`;

const Content = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    width: 100%;
`;

const ContentBody = styled.div`
    margin: ${INDENT.sm} ${INDENT.none};
`;

const TitleStyled = styled(TitleAdaptive)`
    ${adaptive.maxWidth.tablet} {
        ${text14M};
    }
    text-align: left;
`;

const TextStyled = styled(TextAdaptive)`
    text-align: left;
`;

const IconWrapper = styled.div`
    display: block;
`;

export { CollapseBtn, Content, ContentBody, TitleStyled, TextStyled, IconWrapper };
