import { useCallback, useContext, useEffect, useState } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { useGetDraw } from "~pages/lottery/bingo-lottery/_hooks/useGetDraw";
import { useUpdateBingoEditions } from "~pages/lottery/bingo-lottery/_hooks/useUpdateBingoEditions";
import { isDateExpired } from "~helpers/format";
import { TBingoTypes, TEdition } from "~store/tickets/types";
import { TBingoAlias } from "~types/products";
import { useAppSelector } from "~hooks/useAppSelector";
import { useMutationHandles } from "~hooks/useMutationHandles";

export const useSelectModalBingoLogic = (ticketAlias: TBingoAlias, ticketType: TBingoTypes) => {
    const { handleFieldChange } = useMutationHandles("tickets");
    const { getDraw } = useGetDraw(ticketAlias, ticketType);
    const { handleUpdateBingoEditions } = useUpdateBingoEditions(ticketType);
    const ticket = useAppSelector((state) => state.tickets[ticketType]);
    const { editions, editionsSelected } = ticket;
    const [currentEditions, setEditions] = useState<TEdition[]>(editionsSelected);

    const { onClose } = useContext(ModalWindowContext);

    const handleSelect = useCallback(() => {
        handleUpdateBingoEditions(currentEditions);
        onClose();
    }, [currentEditions, handleUpdateBingoEditions, onClose]);

    const handleChange = useCallback(
        (edition: string) => {
            setEditions(() => [editions.find((item) => item.edition === edition)!]);
        },
        [setEditions, editions],
    );

    useEffect(() => {
        if (isDateExpired(editions[0]?.drawDate)) {
            void getDraw();
        }
    }, [editions, getDraw]);

    useEffect(() => {
        if (editionsSelected[0] && isDateExpired(editionsSelected[0].saleDate)) {
            const editionsSelectedFiltered = editionsSelected.filter((item) => !isDateExpired(item.saleDate));
            const editionsSelectedCurrent = editionsSelectedFiltered.length
                ? editionsSelectedFiltered
                : [editions.find((item) => !isDateExpired(item.saleDate))];
            handleFieldChange(`${ticketType}.editionsSelected`, editionsSelectedCurrent);
        }
    }, [editions, editionsSelected, handleFieldChange, ticketType]);

    useEffect(() => {
        setEditions(editionsSelected);
    }, [editionsSelected]);

    return {
        data: {
            editionsSelected: currentEditions,
            editions: editions,
            editionsExpired: isDateExpired(editions[0]?.drawDate),
        },
        cb: {
            handleSelect,
            handleChange,
            handleClose: onClose,
        },
    };
};
