import React, { useCallback } from "react";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { useAddTicket } from "~pages/lottery/digital-lottery/_hooks/useAddTicket";
import { useRemoveTicket } from "~pages/lottery/digital-lottery/_hooks/useRemoveTicket";
import { useUpdateTicketNumbers } from "~pages/lottery/digital-lottery/_hooks/useUpdateTicketNumbers";
import { TTicketOptions } from "~pages/lottery/types";
import { ticketsDigitalType } from "~constants/tickets-type";
import { useAppSelector } from "~hooks/useAppSelector";
import { TicketsBlockWrapper } from "./theme";
import { SkeletonStyled } from "./ticket-5in50/theme";
import Ticket5in50 from "./ticket-5in50/ticket-5in50";
import { useFocusTicket } from "../../_hooks/useFocusTicket";

type TTicketsBlockProps = {
    className?: string;
    defaultTicketsCnt: number;
    ticketOptions: TTicketOptions;
};

const TicketsPage = ({ className, ticketOptions, defaultTicketsCnt }: TTicketsBlockProps) => {
    const ticketsLength = useAppSelector((state) => state.tickets[ticketsDigitalType.t5in50].data.length);

    const { elemRef, setFocus, focusIndex } = useFocusTicket(ticketsLength);

    const { handleAddTicket } = useAddTicket(ticketsDigitalType.t5in50);
    const { handleUpdateAllTicket } = useUpdateTicketNumbers(ticketsDigitalType.t5in50);
    const { handleRemoveTicket } = useRemoveTicket(ticketsDigitalType.t5in50);

    const handleReset = useCallback(
        (idx: number) => () => {
            handleUpdateAllTicket(idx)([[], []]);
        },
        [handleUpdateAllTicket],
    );

    const handleRemove = useCallback(
        (idx: number) => () => {
            handleRemoveTicket(idx);
            setFocus(idx, "remove");
        },
        [handleRemoveTicket, setFocus],
    );

    const handleAdd = useCallback(() => {
        handleAddTicket(ticketsLength);
        setFocus(ticketsLength, "add");
    }, [handleAddTicket, ticketsLength, setFocus]);

    const tickets =
        ticketsLength === 0
            ? Array(defaultTicketsCnt)
                  .fill(-1)
                  .map((_, index) => <SkeletonStyled key={index} className={"mb32 mb24--desktopxl mb16--mobile"} />)
            : Array(ticketsLength)
                  .fill(-1)
                  .map((_, index) => (
                      <React.Fragment key={index}>
                          <Ticket5in50
                              idx={index}
                              showRemoveBtn={ticketsLength > 1}
                              onReset={handleReset}
                              onRemove={handleRemove}
                              ticketOptions={ticketOptions}
                              className={"mb32 mb24--desktopxl mb16--mobile"}
                          />
                          {index === focusIndex.current ? <div ref={elemRef} /> : null}
                      </React.Fragment>
                  ));

    return (
        <TicketsBlockWrapper className={className}>
            {tickets}
            <ButtonAdaptive
                size="large"
                onClick={handleAdd}
                className="mt32 mb32 mb24--desktopxl mt24--desktopxl mt8--tablet mb8--tablet"
            >
                Добавить еще билет
            </ButtonAdaptive>
        </TicketsBlockWrapper>
    );
};

export default TicketsPage;
