import { getIdentityProfileStatus, getParsedBalance, getPhone, getPhoneNumbers, getString } from "~helpers/format";
import { TClient, TClientDefault, TClientResponse } from "~types/client";

export const fromProfileData = (res: TClientResponse | null) => {
    if (!res) return res;
    const personData: TClient | Record<string, unknown> = { ...res };
    const strFields = ["birthday", "nickname", "firstName", "lastName", "middleName", "email"];
    (Object.keys(res) as Array<keyof TClientResponse>).forEach((key) => {
        if (strFields.indexOf(key) >= 0) {
            (personData[key] as string) = getString(res[key]);
        }
    });
    personData.phone = getPhoneNumbers(res.phone);
    personData.balance = getParsedBalance(res.balance!);
    personData.identity = getIdentityProfileStatus(res.identity);

    return personData;
};

export const updateProfilePayload = (data: TClientDefault) => {
    const newData = { ...data };
    newData["phone"] = getPhone(newData["phone"]);
    delete newData["balance"];
    return newData;
};
