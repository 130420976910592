import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { TabGroup } from "~molecules/tab-group";
import { TextContainer } from "~organisms/text-container";
import { useGetDocById } from "~organisms/text-container/_hooks/useGetDocById";
import { politicsRoutes } from "~pages/politics/routes";
import { getPoliticsWebRouteById } from "~constants/routes";
import useAppHistory from "~hooks/useAppHistory";
import { useAppParams } from "~hooks/useAppParams";

type TAgreementListProps = {
    className?: string;
    isShowTabGroup?: boolean;
};

const AgreementListWrapper = styled.div`
    display: block;
`;

const TabGroupStyled = styled(TabGroup)`
    button {
        min-width: 170px;
    }
`;

const AgreementList = ({ className, isShowTabGroup = true }: TAgreementListProps) => {
    const { politicsId } = useAppParams();
    const [system, id] = politicsId.split("-");
    const { doc } = useGetDocById(system, id);

    const { go } = useAppHistory();
    const handleChange = useCallback(
        (id: string) => {
            go(id);
        },
        [go],
    );

    const tabs = useMemo(() => {
        return politicsRoutes.map(({ url, title }) => ({ id: url, title }));
    }, []);
    return (
        <AgreementListWrapper className={className}>
            {isShowTabGroup && (
                <TabGroupStyled
                    className="mb32"
                    active={getPoliticsWebRouteById(politicsId)}
                    tabs={tabs}
                    onClick={handleChange}
                    role={"link"}
                />
            )}
            <TextContainer id="politics-docs" content={doc} />
        </AgreementListWrapper>
    );
};

export default AgreementList;
