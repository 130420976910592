import { useCallback, useContext } from "react";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import RecoveryPasswordBlock from "../../recovery-password-block/recovery-password-block";

export const useSetPhoneBlock = () => {
    const { setContent } = useContext(ModalWindowContext);
    return useCallback(
        async (phone: string) => {
            setContent({
                component: RecoveryPasswordBlock,
                props: { phone, isReset: true },
                options: { btnClose: true },
            });
        },
        [setContent],
    );
};
