import React from "react";
import styled from "styled-components";
import { ComboCircle } from "~atoms/combo-circle";
import { Text } from "~atoms/text";
import { TextAdaptive } from "~atoms/text-adaptive";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { lineHeight20, lineHeight28 } from "~tokens/typography";

// import { getMoneyFormat } from "~helpers/format";

const Ball = styled(ComboCircle)`
    &:not(:last-child) {
        margin-right: ${INDENT.xxxxs};
    }
    ${adaptive.maxWidth.tablet} {
        width: 28px;
        height: 28px;
        & > div {
            ${lineHeight28};
        }
    }
`;

const BallWrapper = styled.div`
    display: flex;
    align-items: center;
    &:not(:last-child) {
        margin-bottom: ${INDENT.xxxxs};
    }
`;

const FieldWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & > p {
        flex-basis: 42px;
    }
    ${adaptive.maxWidth.desktopXXL} {
        flex-direction: column;
        align-items: flex-start;
        & > p {
            ${lineHeight20};
            flex-basis: auto;
        }
    }
`;

const TextStyled = styled(Text)`
    color: ${({ theme }) => theme.color.text.tertiary};
`;

export const scheme = [
    {
        id: "date",
        width: "19%",
        name: "Дата",
        getValue(value: string) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "time",
        width: "18%",
        name: "Время, МСК",
        getValue(value: string) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "edition",
        width: "15%",
        name: "Тираж №",
        getValue(value: string) {
            return (
                <TextAdaptive weight={"medium"} size={"text16"}>
                    {value}
                </TextAdaptive>
            );
        },
    },
    {
        id: "numbers",
        width: "31%",
        name: "Выпавшие числа",
        getValue(value: number[][]) {
            return (
                <div>
                    <FieldWrapper className="mb8 mb4--mobile">
                        <TextStyled size="text12" color="currentColor" className="mr12 mr0--tablet">
                            Поле 1
                        </TextStyled>
                        <BallWrapper>
                            {value[0].map((item, idx) => (
                                <Ball variant={3} key={idx}>
                                    {item}
                                </Ball>
                            ))}
                        </BallWrapper>
                    </FieldWrapper>
                    <FieldWrapper>
                        <TextStyled size="text12" color="currentColor" className="mr12 mr0--tablet">
                            Поле 2
                        </TextStyled>
                        <BallWrapper>
                            {value[1].map((item, idx) => (
                                <Ball variant={3} key={idx}>
                                    {item}
                                </Ball>
                            ))}
                        </BallWrapper>
                    </FieldWrapper>
                </div>
            );
        },
    },
    // {
    //     id: "prize",
    //     width: "16%",
    //     name: "Суперприз, ₽",
    //     getValue(value: number) {
    //         return (
    //             <TextAdaptive weight={"demiBold"} size={"text16"}>
    //                 {getMoneyFormat(value)}
    //             </TextAdaptive>
    //         );
    //     },
    // },
];
