import styled from "styled-components";
import {
    cupIcon,
    cupIconFilled,
    homeIcon,
    homeIconFilled,
    ticketIcon,
    ticketIconFilled,
    walletIcon,
    walletIconFilled,
} from "~assets/svg/icons";
import { BLACK } from "~tokens/colors";
import { INDENT } from "~tokens/sizes";
import { fontSize10, fontWeight500, fontWeight600, lineHeight12 } from "~tokens/typography";

const NavigationTabBarWrapper = styled.div`
    max-width: 360px;
    width: 100%;
    display: flex;
    padding: ${INDENT.xxxs} ${INDENT.xxxxs} ${INDENT.xxxxs} ${INDENT.xxxxs};
    align-items: flex-end;
    & > .tab-bar-link {
        display: flex;
        flex-grow: 1;
        flex-basis: 18%;
        flex-shrink: 0;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        color: ${BLACK};
        .home-icon {
            ${homeIcon};
        }
        .cup-icon {
            ${cupIcon}
        }
        .wallet-icon {
            ${walletIcon}
        }
        .ticket-icon {
            ${ticketIcon}
        }
    }
    & > .tab-bar-link.active {
        & > span {
            ${fontWeight600};
        }
        .home-icon {
            ${homeIconFilled}
        }
        .cup-icon {
            ${cupIconFilled};
        }
        .wallet-icon {
            ${walletIconFilled}
        }
        .ticket-icon {
            ${ticketIconFilled}
        }
    }
`;

const LinkText = styled.span`
    ${fontSize10};
    ${fontWeight500};
    ${lineHeight12};
`;

export { NavigationTabBarWrapper, LinkText };
