import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Button } from "~atoms/button";
import { Checkbox } from "~atoms/checkbox";
import { Input } from "~atoms/input";
import { Link } from "~atoms/link";
import { Text } from "~atoms/text";
import { useSendSms } from "~organisms/auth-form/_hooks/useSendSms";
import { agreementValidationMessage, phoneValidationMessage } from "~organisms/auth-form/common-validation";
import { useLogin } from "~organisms/auth-form/login-block/_hooks/useLogin";
import { AuthHeader, ErrorText, wrapperAuthForm } from "~organisms/auth-form/theme";
import { INDENT } from "~tokens/sizes";
import { text12 } from "~tokens/typography";
import { CUPIS_PERSONAL_DATA, CUPIS_TERMS_OF_USE, NL_PERSONAL_DATA, NL_TERMS_OF_USE } from "~constants/routes";

type TPhoneBlockProps = {
    className?: string;
    phone?: string;
    title?: string;
};

type TFormValues = {
    phone: string;
    agreementNl: boolean | null;
    agreementCupis: boolean | null;
};

const PhoneBlockWrapper = styled.div`
    ${wrapperAuthForm}
`;

const PhoneBlock = ({ className, title = "Регистрация", phone = "" }: TPhoneBlockProps) => {
    const [errorResend, setErrorResend] = useState("");
    const sendSms = useSendSms(setErrorResend);
    const login = useLogin();

    const { handleSubmit, control } = useForm<TFormValues>({
        defaultValues: {
            phone: phone,
            agreementNl: null,
            agreementCupis: null,
        },
    });

    const onSubmit = useCallback(
        (form) => {
            void sendSms(form.phone);
        },
        [sendSms],
    );

    const handleLogin = useCallback(
        (event) => {
            event.preventDefault();
            void login();
        },
        [login],
    );

    return (
        <PhoneBlockWrapper className={className}>
            <AuthHeader tag={"h4"}>{title}</AuthHeader>
            <Text className="mb32" size="text14" weight="normal">
                Уже есть учетная запись? <Link onClick={handleLogin}>Войти</Link>
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <Input
                            id={"auth-phone"}
                            label={"Телефон"}
                            typeMask={"phone"}
                            autoFocus={true}
                            name={field.name}
                            placeholder={"+7"}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            className={"auth-field-form"}
                            errorText={fieldState.error?.message}
                            error={fieldState.invalid}
                        />
                    )}
                    name="phone"
                    control={control}
                    rules={{
                        validate: {
                            phone: phoneValidationMessage,
                        },
                    }}
                />
                <Button className={"auth-form-btn auth-field-form"} type={"submit"}>
                    Продолжить
                </Button>
                {errorResend && <ErrorText className={"error-text--center mb16"}>{errorResend}</ErrorText>}
                <Controller
                    render={({ field, fieldState }) => (
                        <Checkbox
                            className="mb16"
                            id={"auth-agreement-nl"}
                            name={"agreementNl"}
                            checked={!!field.value}
                            onChange={field.onChange}
                            error={fieldState.invalid}
                            label={
                                <AgreementText>
                                    Мне есть 18 лет. Я соглашаюсь на{" "}
                                    <Link href={NL_PERSONAL_DATA}>обработку персональных данных</Link> ООО
                                    &quot;Спортивные Лотереи&quot; и с{" "}
                                    <Link href={NL_TERMS_OF_USE}>договором-офертой</Link> ООО &quot;Спортивные
                                    Лотереи&quot;
                                </AgreementText>
                            }
                        />
                    )}
                    name="agreementNl"
                    control={control}
                    rules={{
                        validate: {
                            agreement: agreementValidationMessage,
                        },
                    }}
                />
                <Controller
                    render={({ field, fieldState }) => (
                        <Checkbox
                            className="mb16"
                            id={"auth-agreement-cupis"}
                            name={"agreementCupis"}
                            checked={!!field.value}
                            onChange={field.onChange}
                            error={fieldState.invalid}
                            label={
                                <AgreementText>
                                    Я соглашаюсь на{" "}
                                    <Link href={CUPIS_PERSONAL_DATA}>обработку персональных данных</Link> ООО НКО
                                    &quot;Мобильная карта&quot; и с{" "}
                                    <Link href={CUPIS_TERMS_OF_USE}>договором-офертой</Link> ООО НКО &quot;Мобильная
                                    карта&quot;
                                </AgreementText>
                            }
                        />
                    )}
                    name="agreementCupis"
                    control={control}
                    rules={{
                        validate: {
                            agreement: agreementValidationMessage,
                        },
                    }}
                />
            </form>
        </PhoneBlockWrapper>
    );
};
export const AgreementText = styled(Text)`
    ${text12};
    margin-left: ${INDENT.xxxs};
    word-break: break-word;

    & > a {
        display: inline;
        word-break: break-word;
    }
`;

export default PhoneBlock;
