import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { Input } from "~atoms/input";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { TipGroup } from "~molecules/tip-group";
import { usePayoutLogic } from "~pages/wallet/_hooks/usePayoutLogic";
import { validatePayoutAmount } from "~pages/wallet/validations";
import { PRIMARY_BLUE } from "~tokens/colors";
import { getMoneyFormat } from "~helpers/format";
import { useAppSelector } from "~hooks/useAppSelector";
import { ButtonPayout, PayoutWrapper } from "./theme";
import { data } from "../helpers";

type TPayoutProps = {
    className?: string;
};

type TFormValues = {
    amount: string;
};

const Payout = ({ className }: TPayoutProps) => {
    const { handlePayout } = usePayoutLogic();
    const balance = useAppSelector((state) => state.profile.data?.balance);
    const identity = useAppSelector((state) => state.profile.data?.identity);

    const {
        control,
        handleSubmit,
        formState: { isSubmitSuccessful },
        reset,
        getValues,
        setValue,
    } = useForm<TFormValues>();

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({ amount: "" });
        }
    }, [isSubmitSuccessful, reset]);

    const handleTip = useCallback(
        (value: number, all?: boolean) => {
            if (all) {
                return setValue("amount", String(value));
            }
            const amount = Number(getValues("amount") ?? 0);
            setValue("amount", String(amount + value));
        },
        [getValues, setValue],
    );

    const tipData = useMemo(() => {
        return balance
            ? [
                  ...data,
                  {
                      title: "Все",
                      value: balance,
                      all: true,
                  },
              ]
            : data;
    }, [balance]);

    return (
        <PayoutWrapper className={className}>
            <TitleAdaptive size="title24" className="mb16">
                Вывод средств
            </TitleAdaptive>
            <Controller
                render={({ field, fieldState }) => (
                    <Input
                        id={"payout-amount"}
                        label={"Введите сумму"}
                        typeMask={"currency"}
                        name={field.name}
                        placeholder={"0 ₽"}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        className={"payout-input mb16"}
                        errorText={fieldState.error?.message}
                        error={fieldState.invalid}
                        helperText={!fieldState.invalid && `Максимальная сумма ${getMoneyFormat(balance)} ₽`}
                    />
                )}
                name="amount"
                control={control}
                rules={{
                    validate: {
                        amount: validatePayoutAmount(balance, identity),
                    },
                }}
            />
            <TipGroup data={tipData} onChange={handleTip} className="mb32" />
            <ButtonPayout
                variant="secondary"
                size="large"
                onClick={handleSubmit((data) => handlePayout(data.amount))}
                disabled={!balance}
            >
                <TextAdaptive size="text16" weight="demiBold" color={PRIMARY_BLUE}>
                    Вывести
                </TextAdaptive>
            </ButtonPayout>
        </PayoutWrapper>
    );
};

export default Payout;
