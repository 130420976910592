import styled from "styled-components";
import { mainContentWrapper } from "~assets/styles/utils";
import { fieldsOffset } from "~assets/styles/utils";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { shadowBuyTicketsBar } from "~tokens/shadows";
import { INDENT } from "~tokens/sizes";
import { fontWeight500 } from "~tokens/typography";

export const BuyTicketsBarWrapper = styled.div`
    z-index: 999;
    width: 100vw;
    background-color: ${WHITE};
    position: fixed;
    bottom: 0;
    left: 0;
    ${shadowBuyTicketsBar};
    ${adaptive.maxWidth.tablet} {
        bottom: 60px;
    }
`;

export const BarContent = styled.div`
    ${mainContentWrapper}
    ${fieldsOffset};
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${INDENT.xxxs} 0;
    justify-content: space-between;
    max-height: 68px;
    ${adaptive.maxWidth.tablet} {
        max-height: 44px;
        justify-content: center;
    }
`;

export const BarNumbersBlock = styled.div`
    flex-basis: 205px;
    ${adaptive.maxWidth.tablet} {
        display: none;
    }
`;

export const BarNumbers = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const BarTotal = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: center;
    &&& h2 {
        ${fontWeight500};
    }
    ${adaptive.maxWidth.tablet} {
        display: none;
    }
`;

export const ButtonText = styled.span`
    ${fontWeight500};
`;

export const ButtonPriceText = styled.span`
    display: none;
    ${adaptive.maxWidth.tablet} {
        display: block;
    }
`;

export const ButtonPay = styled(ButtonAdaptive)`
    ${adaptive.maxWidth.tablet} {
        max-height: 28px;
        padding: ${INDENT.xxxxs} ${INDENT.xs};
    }
`;

export const ButtonBasket = styled(ButtonAdaptive)`
    max-height: 52px;
    padding: ${INDENT.xs};
    ${adaptive.maxWidth.tablet} {
        max-height: 44px;
        padding: ${INDENT.xxs};
    }
`;
