import styled from "styled-components";
import { WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { shadow100 } from "~tokens/shadows";
import { INDENT } from "~tokens/sizes";

export const CookiesAcceptWrapper = styled.div`
    max-width: 1200px;
    width: 90%;
    left: 50%;
    transform: translate(-50%);
    position: fixed;
    bottom: ${INDENT.sm};
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${WHITE};
    padding: ${INDENT.sm};
    border-radius: 4px;
    ${shadow100};

    p {
        margin-right: ${INDENT.xl};
        flex: 0 1 800px;
    }

    button {
        flex: 0 0 175px;
        padding: ${INDENT.none};
    }

    span {
        color: ${({ theme }) => theme.color.text.primary};
    }

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.xs};
    }

    ${adaptive.maxWidth.tablet} {
        flex-wrap: wrap;
        width: 100%;
        bottom: ${INDENT.none};
        border-radius: ${INDENT.none};

        p {
            flex-basis: 100%;
            margin-right: ${INDENT.none};
        }

        button {
            margin-top: ${INDENT.xxs};
            flex-basis: 100%;
        }
    }
`;
