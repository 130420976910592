import { useCallback } from "react";
import useAppApi from "~hooks/useAppApi";
import { useFetch } from "~hooks/useFetch";

export const useGetDocById = (system: string, id: string) => {
    const { politics } = useAppApi();
    const getDoc = useCallback(async () => {
        return await politics.getPage({ system: system.toUpperCase(), types: id.toUpperCase() });
    }, [id, politics, system]);

    const { data, error } = useFetch(getDoc);

    if (error || data === null) {
        return { doc: "" };
    }
    return { doc: data };
};
