export const minFinSite = "https://minfin.ru";
export const minFinPhone = "tel:+74959879101";

export const nationalLotteryPhone = "88003337333";
export const nationalLotteryEmail = "websupport@nationallottery.ru";
export const appStore = "https://testflight.apple.com/join/fmWMu2pP";
export const androidApp = __ANDROID_APP__;
export const paymentProcedure = "https://nationallottery.ru/rules";

// const QR = {
//     test: "https://online.demo.nl-dev.ru/sr",
//     prod: "https://nl-test5998.nationallottery.ru/sr",
// };
