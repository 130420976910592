import { RefObject, useCallback, useEffect, useRef, useState } from "react";

type TOptionsProps = {
    root: null | HTMLElement;
    rootMargin: string;
    threshold: number;
};

export const useElementOnScreen = <T>(options: TOptionsProps): [boolean, RefObject<T>] => {
    const elementRef = useRef<any>(null);
    const [isVisible, setVisible] = useState(false);

    const handleIntersection = useCallback((entries) => {
        const [entry] = entries;
        setVisible(entry.isIntersecting);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, options);
        const target = elementRef.current;
        if (target) observer.observe(target);

        return () => {
            if (target) observer.unobserve(target);
        };
    }, [handleIntersection, options]);

    return [isVisible, elementRef];
};
