import React, { useCallback } from "react";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Text } from "~atoms/text";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TLotteryTicketProps } from "~organisms/lottery-ticket/lottery-ticket";
import {
    ShortMobileTicketWrapper,
    TicketActionZone,
    TicketActionZoneAmount,
    TicketActionZoneEdition,
    TicketBuyZone,
    TicketDescription,
    TicketDescriptionBody,
    TicketDescriptionLogo,
    TicketDescriptionPrize,
    TicketDescriptionTitle,
    TicketActionZonePlaceholder,
    TicketDescriptionSlogan,
} from "~organisms/lottery-ticket/short-mobile-ticket/theme";
import { BLUE_NATIVE, GREY_DARK, WHITE } from "~tokens/colors";
import { getLotteryRouteById } from "~constants/routes";
import { ticketsSlogan } from "~constants/tickets-type";
import useAppHistory from "~hooks/useAppHistory";

type TShortMobileTicketProps = Omit<TLotteryTicketProps, "type">;

const ShortMobileTicket = ({
    className,
    title,
    prize,
    note,
    edition,
    time,
    amount,
    backgroundUrl,
    id,
    logoSrc,
    active,
}: TShortMobileTicketProps) => {
    const { go } = useAppHistory();

    const handleClick = useCallback(() => {
        go(getLotteryRouteById(id));
    }, [go, id]);
    return (
        <ShortMobileTicketWrapper className={className} backgroundUrl={backgroundUrl} onClick={handleClick}>
            <TicketDescription>
                <TicketDescriptionLogo src={logoSrc} alt={title} draggable={false} />
                <TicketDescriptionBody>
                    {active ? (
                        <>
                            <TicketDescriptionTitle color={WHITE} size={3} weight={"demiBold"}>
                                {title}
                            </TicketDescriptionTitle>
                            <TicketDescriptionPrize color={WHITE} size={5} weight={"demiBold"}>
                                {prize}
                            </TicketDescriptionPrize>
                            <Text color={WHITE} size={3} weight={"medium"}>
                                {note}
                            </Text>
                        </>
                    ) : (
                        <TicketDescriptionSlogan color={WHITE} size="text16" weight={"demiBold"}>
                            {ticketsSlogan[id]}
                        </TicketDescriptionSlogan>
                    )}
                </TicketDescriptionBody>
            </TicketDescription>
            <TicketActionZone>
                {active ? (
                    <>
                        <div>
                            <TicketActionZoneEdition size={2} weight={"medium"}>
                                {edition}
                            </TicketActionZoneEdition>
                            <Text size={2} weight={"medium"} color={GREY_DARK}>
                                {time}
                            </Text>
                        </div>
                        <TicketBuyZone>
                            <TicketActionZoneAmount weight={"demiBold"}>{amount} ₽</TicketActionZoneAmount>
                            <ButtonAdaptive size={"small"} intent>
                                <Text size={3} weight={"demiBold"} color={"inherit"}>
                                    Купить
                                </Text>
                            </ButtonAdaptive>
                        </TicketBuyZone>
                    </>
                ) : (
                    <TicketActionZonePlaceholder>
                        <TextAdaptive size={"text16"} color={GREY_DARK}>
                            Нет активных тиражей
                        </TextAdaptive>
                        <TextAdaptive size={"text16"} color={BLUE_NATIVE}>
                            Как играть?
                        </TextAdaptive>
                    </TicketActionZonePlaceholder>
                )}
            </TicketActionZone>
        </ShortMobileTicketWrapper>
    );
};

export default ShortMobileTicket;
