import { MutationReducer } from "~store/mutation/reducer";
import { TWinningsActions, TWinningsState } from "~store/winnings/types";

export const initWinningsReducer = () => {
    const defaultState: TWinningsState = {
        winners: {
            data: [],
            totalPages: null,
        },
    };

    return (state = defaultState, action: TWinningsActions): TWinningsState => {
        switch (action.type) {
            default:
                return MutationReducer("winnings", state, action);
        }
    };
};
