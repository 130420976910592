import React from "react";
import Inputmask from "inputmask";

export const useInputMask = (value: string, maskOptions: Inputmask.Options) => {
    const ref = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        Inputmask.remove(ref.current as HTMLInputElement);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        Inputmask(maskOptions).mask(ref.current as HTMLInputElement);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        Inputmask.setValue(ref.current as HTMLInputElement, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maskOptions, value]);

    return ref;
};
