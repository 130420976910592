import { ACTIONS_TYPE, TDictionariesActions, TDictionariesState } from "./types";
import { MutationReducer } from "../mutation/reducer";

export const initDictionariesReducer = () => {
    const defaultState: TDictionariesState = {};

    return (state = defaultState, action: TDictionariesActions): TDictionariesState => {
        switch (action.type) {
            case ACTIONS_TYPE.GET_DICTIONARY_BY_ID:
                return {
                    ...state,
                    ...action.payload,
                };
            default:
                return MutationReducer<TDictionariesState>("dictionaries", state, action);
        }
    };
};
