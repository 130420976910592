import styled from "styled-components";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontSize12 } from "~tokens/typography";

export const TableRules8in20Wrapper = styled.div`
    background: ${({ theme }) => theme.color.const.gradient.context8of20};
    border-radius: 16px;
    padding: ${INDENT.xxs};
`;
export const TextWrapper = styled.div``;

export const TableStyled = styled.table`
    width: 100%;
    border-spacing: ${INDENT.xxxs};
    border-collapse: separate;

    thead tr {
        th {
            height: 48px;
            text-align: center;
            width: 33%;

            ${adaptive.maxWidth.tablet} {
                width: 27%;

                p {
                    ${fontSize12};
                }
            }

            &:last-child {
                ${adaptive.maxWidth.tablet} {
                    width: 46%;
                }
            }
        }
    }

    tbody tr {
        &:first-child {
            td {
                border-radius: 12px 12px 0 0;
            }
        }

        &:last-child {
            td {
                border-radius: 0 0 12px 12px;
            }
        }
    }

    tbody td {
        height: 48px;
        text-align: center;
        width: 33%;

        &:nth-child(2n + 1) {
            background: ${({ theme }) => theme.color.const.owrlDarken["300"]};
        }

        &:nth-child(2n) {
            background: ${({ theme }) => theme.color.const.owrlDarken["120"]};
        }

        ${adaptive.maxWidth.tablet} {
            width: 27%;
        }

        &:last-child {
            ${adaptive.maxWidth.tablet} {
                width: 60%;
            }

            div {
                width: 67%;

                ${adaptive.maxWidth.tablet} {
                    width: 85%;
                }
            }

            text-align: right;
        }
    }
`;
