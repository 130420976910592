import { useCallback } from "react";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

export const useGetProducts = () => {
    const { handleFieldManyChange } = useMutationHandles("lotteries");
    const { products } = useAppApi();
    const store = useAppStore();
    const { add } = useNotifications();
    const handleGetProducts = useCallback(async () => {
        const { lotteries } = store.getState();
        const data = lotteries.data;
        if (!data.length) {
            const { result, error } = await products.all(["DIGITAL", "BINGO"]);
            if (error) {
                add({ message: error?.data?.message ?? otherErrors.getProducts });
                handleFieldManyChange([{ path: "isLotteriesLoaded", value: true }]);
            }
            handleFieldManyChange([
                { path: "data", value: result },
                { path: "isLotteriesLoaded", value: true },
            ]);
        }
    }, [add, handleFieldManyChange, products, store]);

    return { handleGetProducts };
};
