import styled from "styled-components";
import { transitionDefault } from "~assets/styles/utils";
import { TextAdaptive } from "~atoms/text-adaptive";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeight400 } from "~tokens/typography";

export const NavigationInfoWrapper = styled.div`
    display: block;

    ${adaptive.maxWidth.desktopXL} {
        display: none;
    }

    & > .navigation-collapse {
        margin-top: ${INDENT.xs};
        padding: ${INDENT.none};
        align-items: center;

        & > div {
            height: 24px;
        }
    }

    & > .collapse-content div {
        margin: ${INDENT.xs} ${INDENT.none};
    }
`;

export const HeadingTextStyled = styled(TextAdaptive)`
    margin-bottom: ${INDENT.xs};
    color: ${({ theme }) => theme.color.blocks.footer.text.primary};
`;

export const LinkList = styled.ul`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: start;
    width: 100%;
`;

export const LinkListItem = styled.li`
    margin-bottom: ${INDENT.xs};

    ${adaptive.maxWidth.mobile} {
        width: calc(50% - ${INDENT.xxxxs});
        margin-right: ${INDENT.none};
    }

    & > .footer-link p {
        text-decoration: underline;
        color: ${({ theme }) => theme.color.blocks.footer.text.link};
        ${transitionDefault};
        cursor: pointer;
        ${fontWeight400}

        &:hover {
            color: ${({ theme }) => theme.color.blocks.footer.text.linkActive};
        }
    }

    & > .footer-link.active p {
        color: ${({ theme }) => theme.color.blocks.footer.text.linkActive};
    }
`;
