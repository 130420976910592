import React from "react";
import { Box, Flex } from "~atoms/flex-box";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { OfflineBlock } from "~organisms/offline-block";
import { PRIMARY_BLUE } from "~tokens/colors";
import { getMoneyFormat } from "~helpers/format";
import { TScreen } from "~hooks/useAppScreen";
import {
    BottomContentWrapper,
    ContentWrapper,
    TicketImageWrapper,
    TitleBlockTextWrapper,
    TopContentWrapper,
    TotalText,
} from "./theme";

export type TTicketDirection = "vertical" | "horizontal";

type THeadContentBlockProps = {
    className?: string;
    screen?: TScreen;
    jackpot: number;
    price: number;
    ticketImg: string;
    ticketDirection: TTicketDirection;
};

const HeadContentBlock = ({
    className,
    screen,
    jackpot,
    price,
    ticketImg,
    ticketDirection,
}: THeadContentBlockProps) => {
    return (
        <>
            <Flex
                tablet={{ $direction: ticketDirection === "horizontal" ? "column" : "row" }}
                mobile={{ $direction: "column" }}
                className={className}
            >
                <TicketImageWrapper
                    className={
                        ticketDirection === "vertical"
                            ? "mr40 mr24--tablet mr0--mobile mb24--mobile"
                            : "mr24 mr0--tablet mb24"
                    }
                    ticketDirection={ticketDirection}
                >
                    <img src={ticketImg} alt={"Билет моментальной лотереи"} />
                </TicketImageWrapper>
                <div>
                    <ContentWrapper>
                        <TopContentWrapper
                            className={ticketDirection === "vertical" ? "mb64 mb32--tablet" : "mb32--tablet"}
                        >
                            <TitleAdaptive
                                size={"title24"}
                                tag={"h2"}
                                color={PRIMARY_BLUE}
                                className="mb16"
                                weight={"bold"}
                            >
                                Призы в каждом третьем билете!
                            </TitleAdaptive>
                            <Flex
                                $direction={screen === "tablet" && ticketDirection === "horizontal" ? "row" : "column"}
                            >
                                <Box
                                    className={
                                        ticketDirection === "horizontal"
                                            ? "mr64--tablet mr0--mobile mb16 mb0--tablet"
                                            : "mb16 mb0--mobile"
                                    }
                                >
                                    <TextAdaptive
                                        size={"text16"}
                                        color={PRIMARY_BLUE}
                                        weight={"bold"}
                                        className={"mb8 mb4--mobile"}
                                    >
                                        главный приз
                                    </TextAdaptive>
                                    <TotalText tag={"span"} color={PRIMARY_BLUE} weight={"bold"}>
                                        {getMoneyFormat(jackpot)} ₽
                                    </TotalText>
                                </Box>
                                <Box className="mt16--mobile">
                                    <TextAdaptive
                                        size={"text16"}
                                        color={PRIMARY_BLUE}
                                        weight={"bold"}
                                        className={"mb8 mb4--mobile"}
                                    >
                                        цена билета
                                    </TextAdaptive>
                                    <TotalText tag={"span"} color={PRIMARY_BLUE} weight={"bold"}>
                                        {getMoneyFormat(price)} ₽
                                    </TotalText>
                                </Box>
                            </Flex>
                        </TopContentWrapper>
                        {ticketDirection === "vertical" && (
                            <BottomContentWrapper>
                                <OfflineBlock className="mb16 mb12--mobile" />
                                <TitleBlockTextWrapper className="mb16 mb12--mobile">
                                    <TextAdaptive size="text16" weight="medium">
                                        Покупайте билеты бестиражной лотерии в отделениях Почты России и Россельхозбанка
                                    </TextAdaptive>
                                </TitleBlockTextWrapper>
                                <TextAdaptive size={"text16"} weight={"medium"}>
                                    <a href="#map">Показать карту продаж</a>
                                </TextAdaptive>
                            </BottomContentWrapper>
                        )}
                    </ContentWrapper>
                </div>
            </Flex>
            {ticketDirection === "horizontal" && (
                <BottomContentWrapper>
                    <OfflineBlock className="mb16 mb12--mobile" />
                    <TitleBlockTextWrapper className="mb16 mb12--mobile">
                        <TextAdaptive size="text16" weight="medium">
                            Покупайте билеты бестиражной лотерии в отделениях Почты России и Россельхозбанка
                        </TextAdaptive>
                    </TitleBlockTextWrapper>
                    <TextAdaptive size={"text16"} weight={"medium"}>
                        <a href="#map">Показать карту продаж</a>
                    </TextAdaptive>
                </BottomContentWrapper>
            )}
        </>
    );
};

export default HeadContentBlock;
