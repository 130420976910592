export const isTouchCapable = () => {
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch (e) {
        return false;
    }
};

export const isMobileDevice = () => {
    try {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    } catch (e) {
        return false;
    }
};

export const isIos = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);
export const isAndroid = () => /Android/i.test(navigator.userAgent);

export const feature = {
    needEsia: (phone: string) =>
        new Set([
            "9777274090",
            "9270995880",
            "9206968986",
            "9150319228",
            "9217881855",
            "9996981060",
            "9170252341",
            "9653543943",
        ]).has(phone) || __DEV__,
};
