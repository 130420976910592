import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { LinkbackWrapperStyled } from "./theme";
import { ArrowBackIcon } from "../icons";
import { Text } from "../text";

type TLinkbackProps = {
    className?: string;
    children?: React.ReactNode;
    href: string;
};

const Linkback = ({ href, children = "Назад", className }: TLinkbackProps) => {
    const { color } = useContext(ThemeContext);
    return (
        <LinkbackWrapperStyled to={href} className={className}>
            <ArrowBackIcon />
            <Text tag="span" size="2m" weight="medium" color={color.controls.inactiveTabbar}>
                {children}
            </Text>
        </LinkbackWrapperStyled>
    );
};

export default Linkback;
