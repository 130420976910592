import styled from "styled-components";
import { transitionDefault } from "~assets/styles/utils";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontWeight500 } from "~tokens/typography";

export const TicketFooterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    margin: 0 auto;
`;

export const DistributionTypesWrapper = styled.div`
    display: flex;
    flex-basis: 224px;
    flex-shrink: 0;
    justify-content: space-between;

    ${adaptive.maxWidth.tablet} {
        flex-basis: 184px;
    }
`;

export const IconWrapper = styled.div`
    max-width: 32px;
    max-height: 32px;

    .custom-tooltip {
        ${fontWeight500};
        max-width: 265px;
        width: max-content;
    }

    ${adaptive.maxWidth.tablet} {
        max-width: 24px;
        max-height: 24px;
    }
`;

export const IconButton = styled.button`
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.const.gsSolid["0"]};
    color: ${({ theme }) => theme.color.const.gsSolid["300"]};
    ${transitionDefault};

    &:hover {
        color: ${({ theme }) => theme.color.controls.secondary.hover};
    }

    ${adaptive.maxWidth.tablet} {
        width: 24px;
        height: 24px;

        svg {
            width: 24px;
            height: 24px;
        }
    }
`;

export const ResetButton = styled(ButtonAdaptive)<{ isResettable: boolean }>`
    opacity: ${({ isResettable }) => (isResettable ? "1" : "0")};
    visibility: ${({ isResettable }) => (isResettable ? "visible" : "hidden")};

    color: ${({ theme }) => theme.color.text.tertiary};

    svg {
        color: ${({ theme }) => theme.color.icons.secondary};
    }

    &:hover {
        color: ${({ theme }) => theme.color.text.primary};

        svg {
            color: ${({ theme }) => theme.color.icons.primary};
        }
    }

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.xs} ${INDENT.sm};
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xxxxs};

        span {
            display: none;
        }

        .prefix-wrap {
            display: flex;
            margin: 0;
        }
    }
`;

export const RemoveButtonWrapper = styled.div`
    width: 135px;
    ${adaptive.maxWidth.tablet} {
        display: none;
    }
`;
