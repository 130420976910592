import React from "react";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";

type TVerificationMissedProps = {
    onClick: () => void;
};

const VerificationMissed = ({ onClick }: TVerificationMissedProps) => {
    return (
        <>
            <TitleAdaptive size="title24" className="mb8">
                Подтверждение персональных данных
            </TitleAdaptive>
            <TextAdaptive size="text16" className="mb32" weight="medium">
                Для вывода средств с кошелька, в соответствии с Федеральным законом от 07.08.2001 № 115-ФЗ, Вам
                необходимо подтвердить свою личность.
            </TextAdaptive>
            <ButtonAdaptive onClick={onClick}>Подтвердить через Госуслуги</ButtonAdaptive>
        </>
    );
};

export default VerificationMissed;
