import React from "react";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Link } from "~atoms/link";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { AmountText } from "~pages/wallet/verification-block/theme";
import { GREY_DARK2 } from "~tokens/colors";
import { getPhoneHelpFormat } from "~helpers/format";
import { nationalLotteryPhone } from "~constants/links";

type TVerificationBasicProps = {
    onClick: () => void;
};

const VerificationBasic = ({ onClick }: TVerificationBasicProps) => {
    return (
        <>
            <TitleAdaptive size="title24" className="mb8">
                Превышение лимита кошелька
            </TitleAdaptive>
            <TextAdaptive size="text16" className="mb8" weight="medium">
                Доступный лимит кошелька: 15 000 ₽.
            </TextAdaptive>
            <TextAdaptive size="text16" className="mb32" weight="medium">
                Для увеличения лимита кошелька до{" "}
                <AmountText size="text16" tag="span" weight="demiBold">
                    60 000 ₽
                </AmountText>{" "}
                необходимо подтвердить свою личность.
            </TextAdaptive>
            <ButtonAdaptive className="mb16" onClick={onClick}>
                Подтвердить через Госуслуги
            </ButtonAdaptive>
            <TextAdaptive size="text14" weight="medium" color={GREY_DARK2}>
                Если у Вас нет доступа к Госуслугам, обратитесь в нашу поддержку по номеру{" "}
                <Link intent href={`tel:${nationalLotteryPhone}`}>
                    <TextAdaptive size="text14" weight="medium" tag="span" color={GREY_DARK2}>
                        {getPhoneHelpFormat(nationalLotteryPhone)}
                    </TextAdaptive>
                </Link>
            </TextAdaptive>
        </>
    );
};

export default VerificationBasic;
