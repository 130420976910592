import React from "react";
import { AcceptedIcon } from "~atoms/icons";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TTextAdaptiveSize, TWeight } from "~atoms/text-adaptive/text-adaptive";
import { StatusWrapper, TelIconWrap } from "./theme";

type TStatusProps = {
    children?: React.ReactNode;
    className?: string;
    color?: string;
    weight?: TWeight;
    size?: TTextAdaptiveSize;
    icon?: React.ReactNode;
    textCenter?: boolean;
    iconColor?: string;
};

const Status = ({
    children,
    className,
    color,
    iconColor,
    size = "text14",
    icon = <AcceptedIcon />,
    textCenter = false,
    weight,
}: TStatusProps) => {
    return (
        <StatusWrapper className={className} $textCenter={textCenter}>
            {icon && <TelIconWrap color={iconColor}>{icon}</TelIconWrap>}
            <TextAdaptive tag="div" size={size} color={color} weight={weight}>
                {children}
            </TextAdaptive>
        </StatusWrapper>
    );
};

export default Status;
