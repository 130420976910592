import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Lottery } from "~pages/lottery";
import { MyTickets } from "~pages/my-tickets";
import { Winnings } from "~pages/winnings";
import { LOTTERY, MY_TICKETS_UPCOMING, routesTitle, WINNINGS } from "~constants/routes";
import { useAppSelector } from "~hooks/useAppSelector";
import { HeadingTextStyled, LinkList, LinkListItem, NavigationMainWrapper } from "./theme";

type TNavigationMainProps = {
    className?: string;
};

const NavigationMain = ({ className }: TNavigationMainProps) => {
    const isAuth = useAppSelector((state) => state.user.auth);

    const routes = useMemo(() => {
        const routesMain = [
            {
                url: LOTTERY,
                title: routesTitle[LOTTERY],
                component: Lottery,
                isVisible: true,
            },
            {
                url: MY_TICKETS_UPCOMING,
                title: "Мои билеты",
                component: MyTickets,
                isVisible: isAuth,
            },
            {
                url: WINNINGS,
                title: routesTitle[WINNINGS],
                component: Winnings,
                isVisible: true,
            },
        ];
        return routesMain
            .filter((route) => route.isVisible)
            .map(({ title, url }) => {
                return (
                    <LinkListItem key={url} itemProp="name">
                        <NavLink to={url} itemProp="url" className={"footer-link"}>
                            {title}
                        </NavLink>
                    </LinkListItem>
                );
            });
    }, [isAuth]);

    return (
        <NavigationMainWrapper className={className}>
            <HeadingTextStyled size="text20" weight="medium">
                Билеты
            </HeadingTextStyled>
            <LinkList>{routes}</LinkList>
        </NavigationMainWrapper>
    );
};

export default NavigationMain;
