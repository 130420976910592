import React, { ReactNode, useCallback } from "react";
import { Route, Switch } from "react-router-dom";
import { CardBox } from "~atoms/box";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { Grid } from "~atoms/grid";
import { TabGroup } from "~molecules/tab-group";
import LotteryTicketsBar from "~organisms/lottery-tickets-bar/lottery-tickets-bar";
import { TIsContent } from "~organisms/my-ticket/my-ticket";
import { Page } from "~templates/page";
import { HeaderBlock } from "~pages/lottery/digital-lottery/lottery-4in20/theme";
import { LinkBackStyled } from "~pages/lottery/momentary-lottery/common/theme";
import { useGetTicket } from "~pages/my-tickets/_hooks/useGetTicket";
import { WHITE } from "~tokens/colors";
import { TMyTicketParsed } from "~store/my-tickets/types";
import { MY_TICKETS_BY_ID, MY_TICKETS_RUlES, ticketsAlias } from "~constants/routes";
import useAppHistory from "~hooks/useAppHistory";
import { useAppParams } from "~hooks/useAppParams";
import { TScreen, useAppScreen } from "~hooks/useAppScreen";
import { crumbs, getStatus, lotteryTypes, tabs } from "./helpers";
import { HeaderWrap, SkeletonStyled, TicketAside, TicketContent } from "./theme";
import { TicketDetails } from "./ticket-details";

const { Row, Col } = Grid;

const getTicketDetail = (
    data: TMyTicketParsed,
    isContent: TIsContent,
    typeScreen: TScreen,
    isFutureTicket: boolean,
) => {
    return <TicketDetails data={data} isContent={isContent} isFutureTicket={isFutureTicket} typeScreen={typeScreen} />;
};

export type TLotteryData = {
    headJackpot: string;
    headImg: string;
    rulesPage: ReactNode;
    toHistoryDetails: (productId: string, drawNumber: string) => string;
};

const Ticket = () => {
    const { go } = useAppHistory();
    const typeScreen = useAppScreen();
    const params = useAppParams();
    const defaultType = ticketsAlias.t5in36;
    const { data } = useGetTicket(params.myTicketId);
    const directionOption = typeScreen.startsWith("desktop") ? "vertical" : "horizontal";
    const handleClick = useCallback(
        (url) => {
            go(url);
        },
        [go],
    );

    const isFutureTicket = Boolean(data?.winCombination && !data.winCombination[0].main.length);
    const isContent = getStatus(isFutureTicket, data?.status);
    const Rules = lotteryTypes[data?.product.alias ?? defaultType].rulesPage;
    const breadcrumbs = crumbs(params.myTicketId, isFutureTicket, data?.status);
    const mainContent = (
        <TicketContent className="mb48--tablet mb32--mobile">
            {!data ? (
                <CardBox>
                    <SkeletonStyled />
                </CardBox>
            ) : (
                <Switch>
                    <Route
                        path={MY_TICKETS_BY_ID}
                        render={() => getTicketDetail(data, isContent, typeScreen, isFutureTicket)}
                        exact
                    />
                    <Route path={MY_TICKETS_RUlES} component={Rules as () => JSX.Element} exact />
                </Switch>
            )}
        </TicketContent>
    );

    const headerBlock = (
        <HeaderBlock>
            {typeScreen === "mobile" ? (
                <LinkBackStyled href={breadcrumbs[1].path} className="mb32" />
            ) : (
                <Breadcrumbs data={breadcrumbs} className="mb24 mb16--tablet" />
            )}

            <Grid>
                <Row>
                    <Col col={9} tablet={12}>
                        <HeaderWrap>
                            <TabGroup
                                active={location.pathname}
                                onClick={handleClick}
                                tabs={tabs(params.myTicketId)}
                                role={"link"}
                                color={WHITE}
                                size={3}
                            />
                            {/*{data?.product.alias && (*/}
                            {/*    <LotteryDataDigital*/}
                            {/*        className="mb16--mobile"*/}
                            {/*        ticketType={lotteryTypes[data?.product.alias]?.headJackpot as TTicketTypes}*/}
                            {/*        withoutEdition*/}
                            {/*        autonomous*/}
                            {/*    />*/}
                            {/*)}*/}
                            {/*{data?.product.type === "BINGO" ? (*/}
                            {/*    <LotteryDataBingo*/}
                            {/*        className="mb16--mobile"*/}
                            {/*        ticketType={*/}
                            {/*            lotteryTypes[data?.product.alias ?? defaultType].headJackpot as TBingoTypes*/}
                            {/*        }*/}
                            {/*    />*/}
                            {/*) : (*/}
                            {/*    <LotteryDataDigital*/}
                            {/*        autonomous*/}
                            {/*        className="mb16--mobile"*/}
                            {/*        ticketType={*/}
                            {/*            lotteryTypes[data?.product.alias ?? defaultType].headJackpot as TTicketTypes*/}
                            {/*        }*/}
                            {/*        withoutEdition*/}
                            {/*    />*/}
                            {/*)}*/}
                        </HeaderWrap>
                    </Col>
                </Row>
            </Grid>
        </HeaderBlock>
    );

    const asideContent = (
        <TicketAside className="mb64 mb32--tablet mb0--mobile">
            <LotteryTicketsBar title={"Другие лотереи"} direction={directionOption} lotteryType={data?.product.alias} />
        </TicketAside>
    );

    return (
        <Page
            mainContent={mainContent}
            headerBlock={headerBlock}
            asideContent={asideContent}
            headerWithImg={Boolean(data && data.product)}
            headerImg={data && data.product.alias ? lotteryTypes[data.product.alias].headImg : undefined}
        />
    );
};

export default Ticket;
