import { DefaultTheme } from "styled-components";
import { beelineColors } from "~tokens/palette/beeline";
import { mainColors } from "~tokens/palette/main";

const mainTheme: DefaultTheme = {
    color: mainColors,
};

const beelineTheme: DefaultTheme = {
    color: beelineColors,
};

export { mainTheme, beelineTheme };
