import styled from "styled-components";
import { Box, Flex } from "~atoms/flex-box";
import { TextAdaptive } from "~atoms/text-adaptive";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { ticketsColor } from "~constants/tickets-type";

export const TicketHeader = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    height: 163px;
    padding: ${INDENT.xs};
    background: ${ticketsColor["digital-5x50"]};

    &:after {
        position: absolute;
        bottom: -1px;
        left: 0;
        content: "";
        width: 100%;
        height: 1px;
        z-index: 1;
        background: ${ticketsColor["digital-5x50"]};
        mask-image: linear-gradient(90deg, transparent 0, transparent 4px, black 4px, black 8px);
        mask-repeat: repeat;
        mask-size: 8px 100%;
    }

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.xs} ${INDENT.s} ${INDENT.s28};
    }

    ${adaptive.maxWidth.desktopXL} {
        height: 185px;
        padding: ${INDENT.xxs} ${INDENT.xxs} ${INDENT.s};
    }

    ${adaptive.maxWidth.tablet} {
        height: 261px;
        padding: ${INDENT.xxxs} ${INDENT.xxs} ${INDENT.m};
    }
`;

export const TicketHeaderContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${adaptive.maxWidth.desktopXXL} {
        align-items: start;
    }
`;

export const HeaderLogo = styled.img`
    height: 44px;
    ${adaptive.maxWidth.tablet} {
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        height: 32px;
    }
`;

export const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    ${adaptive.maxWidth.desktopXL} {
        flex-direction: column;
    }
    ${adaptive.maxWidth.tablet} {
        & h3 {
            margin-bottom: ${INDENT.xxxs};
        }
    }
`;

export const HeaderPrice = styled(TextAdaptive)`
    width: auto;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: ${INDENT.xxxs} ${INDENT.xxs};

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xxxxs} ${INDENT.xxxs};
    }
`;

export const HeaderEdition = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    & > .select-modal {
        width: 153px;
    }
    & > p {
        white-space: nowrap;
    }
    ${adaptive.maxWidth.desktopXL} {
        flex-direction: column;
        align-items: start;
    }
    ${adaptive.maxWidth.tablet} {
        & > .select-modal {
            width: 130px;
        }
    }
`;

export const TicketHeaderNumbers = styled(Flex)`
    ${adaptive.maxWidth.tablet} {
        width: auto;
    }
`;

export const FieldNumbersSelected = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > span {
        white-space: nowrap;
    }
    ${adaptive.maxWidth.tablet} {
        flex-direction: row;
        align-items: center;
    }
`;

export const FieldTitle = styled(TextAdaptive)`
    text-align: left;
    ${adaptive.maxWidth.tablet} {
        width: 43px;
    }
    ${adaptive.maxWidth.mobileSmall} {
        &&& {
            margin-right: ${INDENT.xxxxxs};
        }
    }
`;

export const FieldBody = styled.div`
    display: flex;
    text-align: center;
`;

export const BoxNumbersSelected = styled.div`
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.color.const.owrlDarken["300"]};
    border-radius: 8px;

    &:not(:last-child) {
        margin-right: ${INDENT.xxxs};

        ${adaptive.maxWidth.tablet} {
            margin-right: ${INDENT.xxxxs};
        }
    }
    &.active {
        background: ${({ theme }) => theme.color.const.gsSolid["0"]};
    }
    ${adaptive.maxWidth.desktopXXL} {
        width: 32px;
        height: 32px;
        & span {
            transform: scale(0.8);
        }
    }
    ${adaptive.maxWidth.desktopXL} {
        width: 44px;
        height: 44px;
        & span {
            transform: scale(1);
        }
    }
    ${adaptive.maxWidth.tablet} {
        width: 36px;
        height: 36px;
    }
    ${adaptive.maxWidth.tablet} {
        width: 36px;
        height: 36px;
    }
`;
