import { useCallback, useEffect } from "react";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useAppSelector } from "~hooks/useAppSelector";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

export const useGetProfile = async () => {
    const { handleFieldChange } = useMutationHandles("profile");
    const auth = useAppSelector((state) => state.user.auth);
    const { add } = useNotifications();
    const api = useAppApi();
    const getProfileData = useCallback(async () => {
        return await api.profile.getProfileData();
    }, [api.profile]);

    useEffect(() => {
        async function fetchData() {
            const { result, error } = await getProfileData();
            if (error) {
                add({ message: error?.data?.message ?? otherErrors.getProfile });
            }
            if (result) {
                handleFieldChange("data", result);
            }
        }
        if (auth) {
            fetchData();
        }
    }, [auth, getProfileData, handleFieldChange, add]);
};
