import React from "react";
import { DefaultTheme } from "styled-components";
import { TPaymentStatus } from "~types/my-tickets";
import { getFutureStatus } from "./helpers";
import { StatusFuture, StatusText, StatusWrap } from "../theme";

export type TFuture = {
    drawDate: string | null;
    paymentStatus: TPaymentStatus;
    registrationStatus: TPaymentStatus;
    theme: DefaultTheme;
};

const FutureBlock = ({ drawDate, registrationStatus, paymentStatus, theme }: TFuture) => {
    const { color, text, icon } = getFutureStatus({ drawDate, registrationStatus, paymentStatus, theme });
    const Icon = icon;
    return (
        <StatusWrap>
            <StatusFuture size="text16" icon={<Icon />} color={color}>
                {text}
            </StatusFuture>
            {registrationStatus === "ACCEPTED_MOVE" && (
                <StatusText className="ticket-next-edition" tag="span" size="text14" color={theme.color.text.tertiary}>
                    Перенесен на ближайший тираж
                </StatusText>
            )}
        </StatusWrap>
    );
};

export default FutureBlock;
