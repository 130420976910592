import dayjs from "dayjs";
import { getPhone } from "~helpers/format";
import { TCodeStatus, TLoginReqData, TSmsSendStatus } from "~types/auth";
import { clientId } from "~constants/configuration";

export const fromSendSmsApi = (res: TSmsSendStatus | null) => {
    if (!res) return res;

    const date1 = dayjs(res.createTS);
    const date2 = dayjs(res.nextTS);
    const timerValue = date2.diff(date1, "second", false);

    return {
        ...res,
        timerValue,
    };
};
export const toSendSmsApi = getPhone;

export const fromCheckSmsCodeApi = (res: TCodeStatus | null) => {
    if (!res) return res;
    return {
        ...res,
        error: res.error,
    };
};

export const toLoginApi = ({ phoneNumber, password, code, operationId }: TLoginReqData) => {
    const params = new URLSearchParams();
    params.append("clientId", clientId);
    params.append("phoneNumber", getPhone(phoneNumber));

    if (password) {
        params.append("password", password);
    }
    if (code) {
        params.append("code", code);
    }
    if (operationId) {
        params.append("operationId", operationId);
    }

    return params;
};

export const addClientId = () => {
    const params = new URLSearchParams();
    params.append("clientId", clientId);

    return params;
};
