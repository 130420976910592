import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { Button } from "~atoms/button";
import { Input } from "~atoms/input";
import { comparePasswordValidationMessage, passwordValidationMessage } from "~organisms/auth-form/common-validation";
import { useSetPassword } from "~organisms/auth-form/password-block/_hooks/useSetPassword";
import { IconPassword } from "~organisms/auth-form/password-block/icon-password";
import { AuthHeader, wrapperAuthForm, ErrorText } from "~organisms/auth-form/theme";
import { isTrue } from "~helpers/validation";

type TPasswordBlockProps = {
    className?: string;
    title?: string;
    isReset?: boolean;
    phoneNumber: string;
    code: string;
    operationId: string;
};

type TFormValues = {
    confirm: string;
    password: string;
};

const PasswordBlockWrapper = styled.div`
    ${wrapperAuthForm}
`;

const PasswordBlock = ({
    className,
    title = "Придумайте пароль",
    phoneNumber,
    code,
    operationId,
    isReset,
}: TPasswordBlockProps) => {
    const [error, setError] = useState("");
    const setPassword = useSetPassword(setError);
    const [inputType, setInputType] = useState<{ confirm: "password" | "text"; password: "password" | "text" }>({
        confirm: "password",
        password: "password",
    });

    const onSubmit = useCallback(
        (form) => {
            void setPassword(
                {
                    password: form.password,
                    phoneNumber,
                    code,
                    operationId,
                },
                isReset,
            );
        },
        [setPassword, phoneNumber, code, operationId, isReset],
    );

    const { handleSubmit, control, getValues, trigger } = useForm<TFormValues>();

    return (
        <PasswordBlockWrapper className={className}>
            <AuthHeader className="mb20" tag={"h4"}>
                {title}
            </AuthHeader>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    render={({ field, fieldState }) => (
                        <Input
                            id={"auth-password"}
                            className={"mb16"}
                            label={"Новый пароль"}
                            autoFocus={true}
                            autoComplete="new-password"
                            type={inputType.password}
                            name={field.name}
                            placeholder={"Введите ваш пароль"}
                            value={field.value}
                            suffixIcon={
                                <IconPassword
                                    fieldName={"password"}
                                    isShow={isTrue(field.value)}
                                    setInputType={setInputType}
                                    inputType={inputType.password}
                                />
                            }
                            onChange={field.onChange}
                            errorText={fieldState.error?.message}
                            error={fieldState.invalid}
                        />
                    )}
                    name="password"
                    control={control}
                    rules={{
                        validate: {
                            password: passwordValidationMessage,
                        },
                    }}
                />
                <Controller
                    render={({ field, fieldState, formState }) => (
                        <Input
                            id={"auth-password-confirm"}
                            label={"Повторите пароль"}
                            type={inputType.confirm}
                            name={field.name}
                            autoComplete="new-password"
                            placeholder={"Введите ваш пароль"}
                            value={field.value}
                            suffixIcon={
                                <IconPassword
                                    fieldName={"confirm"}
                                    isShow={isTrue(field.value)}
                                    setInputType={setInputType}
                                    inputType={inputType.confirm}
                                />
                            }
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            onFocus={() => fieldState.isDirty && formState.isSubmitted && trigger("confirm")}
                            error={Boolean(formState.errors.password) ? false : fieldState.invalid}
                            errorText={Boolean(formState.errors.password) ? "" : fieldState.error?.message}
                        />
                    )}
                    name="confirm"
                    control={control}
                    rules={{
                        validate: {
                            comparePassword: (value) => comparePasswordValidationMessage(value, getValues("password")),
                        },
                    }}
                />
                {error && <ErrorText className={"mt16"}>{error}</ErrorText>}
                <Button className={"auth-form-btn"} type={"submit"}>
                    Продолжить
                </Button>
            </form>
        </PasswordBlockWrapper>
    );
};

export default PasswordBlock;
