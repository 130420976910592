import { QUERY_TYPE } from "./types";

export { default as updateState } from "immutability-helper";

export const generateQuery = (path: string, type: QUERY_TYPE, value: unknown, callback?: unknown) => {
    const keys = path.split(".");

    function generate(
        query: Record<string, unknown>,
        type: QUERY_TYPE,
        value: unknown,
        keys: string[],
        callback?: unknown,
    ): any {
        // eslint-disable-line
        const key: string = keys.splice(0, 1)[0];
        if (keys.length > 0) {
            query[key] = generate({}, type, value, keys, callback);
        } else {
            switch (type) {
                case QUERY_TYPE.$set:
                    query[key] = {
                        $set: value,
                    };
                    break;
                case QUERY_TYPE.$push:
                    query[key] = {
                        $push: [value],
                    };
                    break;
                case QUERY_TYPE.$apply:
                    query[key] = {
                        $apply: callback,
                    };
                    break;
                case QUERY_TYPE.$merge:
                    query[key] = {
                        $merge: value,
                    };
                    break;
                default:
                    console.error(query, path, type, value);
            }
        }
        return query;
    }

    return generate({}, type, value, keys, callback);
};
