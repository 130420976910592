import React from "react";
import NavigationTabBar from "~organisms/tab-bar/navigation-tab-bar/navigation-tab-bar";
import { TabBarWrapper } from "~organisms/tab-bar/theme";

type TTabBarProps = {
    className?: string;
};

const TabBar = ({ className }: TTabBarProps) => {
    return (
        <TabBarWrapper className={className} role="navigation">
            <NavigationTabBar />
        </TabBarWrapper>
    );
};

export default TabBar;
