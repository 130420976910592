import { useCallback } from "react";
import { TTicketTypes } from "~store/tickets/types";
import { useMutationHandles } from "~hooks/useMutationHandles";

export const useUpdateTicketNumbers = (ticketType: TTicketTypes) => {
    const { handleFieldChange } = useMutationHandles("tickets");
    const handleUpdateTicketNumbers = useCallback(
        (idx: number) => (number: number, numbersSelected: number[]) => {
            handleFieldChange(`${ticketType}.data.${idx}.numbersSelected.${number}`, numbersSelected);
        },
        [handleFieldChange, ticketType],
    );

    const handleUpdateAllTicket = useCallback(
        (idx: number) => (numbersSelected: number[][]) => {
            handleFieldChange(`${ticketType}.data.${idx}.numbersSelected`, numbersSelected);
        },
        [handleFieldChange, ticketType],
    );
    return { handleUpdateTicketNumbers, handleUpdateAllTicket };
};
