import { css } from "styled-components";
import { adaptive } from "~tokens/screen";
import { TColStyle } from "./col";
import { COLUMNS, OFFSET } from "../settings";

export const colWidth = css<TColStyle>`
    width: calc(100% / ${({ col }) => COLUMNS / col} - ${OFFSET}px);
    ${adaptive.maxWidth.desktopXXL} {
        width: ${({ desktopXL }) => desktopXL && `calc(100% / ${COLUMNS / desktopXL} - ${OFFSET}px)`};
    }

    ${adaptive.maxWidth.desktopXL} {
        width: ${({ tablet }) => tablet && `calc(100% / ${COLUMNS / tablet} - ${OFFSET}px)`};
    }

    ${adaptive.maxWidth.tablet} {
        width: ${({ mobile }) => mobile && `calc(100% / ${COLUMNS / mobile} - ${OFFSET}px)`};
    }

    ${adaptive.maxWidth.mobile} {
        width: ${({ mobile }) => mobile && `calc(100% / ${COLUMNS / mobile} - ${OFFSET}px)`};
    }
`;
