import React from "react";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { GREY_DARK2, WHITE } from "~tokens/colors";
import { getMoneyFormat } from "~helpers/format";
import { TAllTicketTypes } from "~store/tickets/types";
import { SkeletonText } from "./theme";
import { ticketOptions } from "../lottery-data-view";
import { LotteryContent, SkeletonJackpot } from "../theme";

type TLotteryContentBingoProps = {
    className?: string;
    isJackpot?: boolean;
    jackpot?: number | null;
    ticketType: TAllTicketTypes;
};

const LotteryContentBingo = ({ ticketType, isJackpot, jackpot, className }: TLotteryContentBingoProps) => {
    return (
        <LotteryContent
            $borderColor={isJackpot ? ticketOptions[ticketType].borderColor : GREY_DARK2}
            $type={ticketOptions[ticketType].type}
            className={className}
        >
            {isJackpot ? (
                <TitleAdaptive tag="span" color={WHITE} size="title38">
                    {ticketOptions[ticketType].title} {getMoneyFormat(jackpot)} ₽
                </TitleAdaptive>
            ) : (
                <SkeletonJackpot />
            )}

            {isJackpot ? (
                <TitleAdaptive size="title24" color={WHITE} className={"mb8 mb4--tablet"}>
                    {ticketOptions[ticketType].subtitle}
                </TitleAdaptive>
            ) : (
                <SkeletonText className="mb8 mb4--tablet" />
            )}
        </LotteryContent>
    );
};

export default LotteryContentBingo;
