import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { HistoryBlock } from "~organisms/our-winners-area/history-block";
import { OurWinnersAreaWrapper } from "~organisms/our-winners-area/theme";

type TOurWinnersAreaProps = {
    className?: string;
};

const OurWinnersArea = ({ className }: TOurWinnersAreaProps) => {
    const { color } = useContext(ThemeContext);
    return (
        <OurWinnersAreaWrapper className={className}>
            <TitleAdaptive tag={"h1"} size={"title38"} color={color.text.headline} weight="medium">
                Истории победителей
            </TitleAdaptive>
            <HistoryBlock className={"mt32"} />
            {/*<LastWinnersBlock />*/}
        </OurWinnersAreaWrapper>
    );
};

export default OurWinnersArea;
