import { AxiosInstance } from "axios";
import { ApiResponse } from "~services/http/types";
import { TEnvConfig } from "~types/configuration";

export const createConfigurationApi = (http: AxiosInstance) => ({
    async getEnvConfig() {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TEnvConfig>>("/env.json", { baseURL: "/" });
        return { error, result };
    },
    setEnvBaseUrl(baseURL: string) {
        http.defaults.baseURL = baseURL;
    },
});
