import styled from "styled-components";
import { headingXXLSX } from "~assets/styles/utils";
import { Grid } from "~atoms/grid";
import { Skeleton } from "~atoms/skeleton";
import { Title } from "~atoms/title";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
`;

const GridStyled = styled(Grid)`
    ${adaptive.maxWidth.desktopXXL} {
        zoom: 0.9;
    }

    ${adaptive.maxWidth.desktopXL} {
        zoom: 1;
    }
`;

const TitleBlock = styled(Title)`
    margin-bottom: ${INDENT.sm};
    ${headingXXLSX}
`;

const SkeletonStyled = styled(Skeleton)`
    height: 224px;
    border-radius: 8px;

    ${adaptive.maxWidth.tablet} {
        height: 180px;
    }
`;

export { TitleBlock, SkeletonStyled, GridStyled };
