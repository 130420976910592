import React, { useCallback, useEffect, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { BuyTicketsCardBingo } from "~organisms/buy-tickets-card";
import { LotteryDataBingo } from "~organisms/lottery-data";
import LotteryTicketsBar from "~organisms/lottery-tickets-bar/lottery-tickets-bar";
import { Page } from "~templates/page";
import { useResetTickets } from "~pages/lottery/_hooks/useResetTickets";
import { useInitTickets } from "~pages/lottery/bingo-lottery/_hooks/useInitTickets";
import { ticketOptions } from "~pages/lottery/bingo-lottery/common/helpers";
import { LogoStyled } from "~pages/lottery/digital-lottery/lottery-5in50/theme";
import { LinkBackStyled } from "~pages/lottery/momentary-lottery/common/theme";
import { WHITE } from "~tokens/colors";
import {
    bingoAlias,
    LOTTERY,
    LOTTERY_BINGO_90,
    LOTTERY_BINGO_90_HISTORY,
    LOTTERY_BINGO_90_HISTORY_DETAILS,
    LOTTERY_BINGO_90_RULES,
} from "~constants/routes";
import { ticketsBingoType } from "~constants/tickets-type";
import useAppHistory from "~hooks/useAppHistory";
import { useAppScreen } from "~hooks/useAppScreen";
import { useAppSelector } from "~hooks/useAppSelector";
import { useElementOnScreen } from "~hooks/useElementOnScreen";
import { crumbs, tabs } from "./helpers";
import History from "./history/history";
import Rules from "./rules/rules";
import {
    BingoContent,
    BuyTicketsBarStyled,
    BuyTicketsCardWrapper,
    HeaderBlock,
    HeaderContentWrapper,
    LogoWrapper,
    TabGroupStyled,
    TicketsAside,
} from "./theme";
import headImg from "../../assets/bingo/90/head.png";
import { HistoryDetails } from "../../common/history-details";
import { LogoSkeletonStyled } from "../../common/legal-info/theme";
import { TicketsPage } from "../common/tickets-page";

const observerOptions = {
    root: null,
    rootMargin: "50px 0px 50px 0px",
    threshold: 0.8,
};

const col = { mainContent: { desktopXL: 8 }, asideContent: { desktopXL: 4 } };

const Bingo90 = () => {
    const typeScreen = useAppScreen();
    const isActive = useAppSelector(
        (state) => state.lotteries.data.find((item) => item.id === bingoAlias.b90)?.active ?? true,
    );
    const { data, isLotteriesLoaded } = useAppSelector((state) => state.lotteries);
    const [isCardVisible, cardRef] = useElementOnScreen<HTMLDivElement>(observerOptions);
    const logoSrc = useAppSelector((state) => state.tickets[ticketsBingoType.b90].logoSrc);
    const productId = useAppSelector((state) => state.tickets[ticketsBingoType.b90].productId);
    const init = useAppSelector((state) => state.tickets[ticketsBingoType.b90].initTicket);
    const name = useAppSelector((state) => state.tickets[ticketsBingoType.b90].name);

    const { initTickets } = useInitTickets({
        alias: ticketOptions[ticketsBingoType.b90].alias,
        ticketType: ticketsBingoType.b90,
        size:
            typeScreen === "mobile"
                ? ticketOptions[ticketsBingoType.b90].sizeMobile
                : ticketOptions[ticketsBingoType.b90].size,
    });
    const { resetTickets } = useResetTickets();

    const { go } = useAppHistory();
    const location = useLocation();

    const directionOption = "horizontal";
    const isHistoryDetailsPath = location.pathname.indexOf(productId!) > 0;
    const isCardShow =
        (location.pathname === LOTTERY_BINGO_90 || typeScreen.startsWith("desktop")) && !isHistoryDetailsPath;
    const activePath = location.pathname.startsWith(LOTTERY_BINGO_90_HISTORY)
        ? LOTTERY_BINGO_90_HISTORY
        : location.pathname;

    useEffect(() => {
        if (!init) {
            void initTickets();
        }
    }, [init, initTickets]);

    useEffect(() => resetTickets, [resetTickets]);

    const handleClick = useCallback(
        (url) => {
            go(url);
        },
        [go],
    );

    const tabsData = useMemo(() => (isActive ? tabs : tabs.filter((item) => item.id !== LOTTERY_BINGO_90)), [isActive]);

    const mainContent = (
        <BingoContent className="mb48--tablet mb32--mobile">
            <Switch>
                {isActive && (
                    <Route
                        path={LOTTERY_BINGO_90}
                        render={() => <TicketsPage ticketType={ticketsBingoType.b90} />}
                        exact
                    />
                )}
                <Route path={LOTTERY_BINGO_90_RULES} render={() => <Rules isActive={isActive} />} exact />
                <Route path={LOTTERY_BINGO_90_HISTORY} component={History} exact />
                <Route path={LOTTERY_BINGO_90_HISTORY_DETAILS} component={HistoryDetails} exact />
                {isActive ? <Redirect to={LOTTERY_BINGO_90} /> : <Redirect to={LOTTERY_BINGO_90_RULES} />}
            </Switch>
            {typeScreen.startsWith("desktop") && (
                <LotteryTicketsBar
                    className="mt32"
                    title="Другие лотереи"
                    direction={directionOption}
                    lotteryType={"bingo-90"}
                />
            )}
        </BingoContent>
    );

    const asideContent = (
        <TicketsAside className="mb64 mb32--tablet mb0--mobile">
            {isActive && isLotteriesLoaded && (
                <BuyTicketsCardWrapper ref={cardRef} className="mb32" show={isCardShow}>
                    <BuyTicketsCardBingo className="buy-ticket-card" ticketType={ticketsBingoType.b90} />
                </BuyTicketsCardWrapper>
            )}
            {!typeScreen.startsWith("desktop") && (
                <LotteryTicketsBar title={"Другие лотереи"} direction={directionOption} lotteryType={"bingo-90"} />
            )}
            {isActive && (
                <BuyTicketsBarStyled ticketType={ticketsBingoType.b90} show={!isCardVisible && !isHistoryDetailsPath} />
            )}
        </TicketsAside>
    );

    const headerBlock = useMemo(
        () => (
            <HeaderBlock>
                {typeScreen === "mobile" ? (
                    <LinkBackStyled href={isHistoryDetailsPath ? LOTTERY_BINGO_90_HISTORY : LOTTERY} className="mb32" />
                ) : (
                    <Breadcrumbs data={crumbs(location.pathname, productId!, name)} className="mb24 mb16--tablet" />
                )}
                {isActive ? (
                    <HeaderContentWrapper className="mb48--tablet">
                        <LogoWrapper className="mb32 mb24--tablet">
                            {logoSrc ? <LogoStyled src={logoSrc} alt="Лотерея bingo90" /> : <LogoSkeletonStyled />}
                        </LogoWrapper>
                        {data.length ? <LotteryDataBingo ticketType={ticketsBingoType.b90} /> : null}
                    </HeaderContentWrapper>
                ) : (
                    <LogoWrapper className="mb32 mb24--tablet">
                        {logoSrc ? <LogoStyled src={logoSrc} alt="Лотерея bingo90" /> : <LogoSkeletonStyled />}
                    </LogoWrapper>
                )}

                <TabGroupStyled
                    active={activePath}
                    onClick={handleClick}
                    tabs={tabsData}
                    role={"link"}
                    color={WHITE}
                    size={3}
                    loading={!isLotteriesLoaded}
                />
            </HeaderBlock>
        ),
        [
            typeScreen,
            isHistoryDetailsPath,
            location.pathname,
            productId,
            name,
            isActive,
            logoSrc,
            data.length,
            activePath,
            handleClick,
            tabsData,
            isLotteriesLoaded,
        ],
    );

    return (
        <Page
            mainContent={mainContent}
            asideContent={asideContent}
            headerBlock={headerBlock}
            col={col}
            headerWithImg
            headerImg={headImg}
        />
    );
};

export default Bingo90;
