import React from "react";
import { NavLink } from "react-router-dom";
import { Collapse } from "~atoms/collapse";
import { TextAdaptive } from "~atoms/text-adaptive";
import { About } from "~pages/about";
import { Contacts } from "~pages/contacts";
import { ABOUT, CONTACTS, DRAW_HISTORY, routesTitle } from "~constants/routes";
import { HeadingTextStyled, LinkList, LinkListItem, NavigationInfoWrapper } from "./theme";
import DrawHistory from "../../../pages/draw-history/draw-history";

type TNavigationInfoProps = {
    className?: string;
    onClose?: () => void;
    mobile?: boolean;
};

const routesInfo = [
    {
        url: ABOUT,
        title: routesTitle[ABOUT],
        component: About,
    },
    {
        url: CONTACTS,
        title: "Контакты",
        component: Contacts,
    },
    // {
    //     url: FEEDBACK,
    //     title: "Обратная связь",
    //     component: Feedback,
    // },
    {
        url: DRAW_HISTORY,
        title: routesTitle[DRAW_HISTORY],
        component: DrawHistory,
    },
];

const NavigationInfo = ({ className, onClose, mobile = false }: TNavigationInfoProps) => {
    return (
        <NavigationInfoWrapper className={className}>
            {mobile ? (
                <Collapse text="Информация" textSize="text16" className="navigation-collapse">
                    <LinkList>
                        {routesInfo.map(({ title, url }) => (
                            <LinkListItem key={url} itemProp="name">
                                <NavLink
                                    itemProp="url"
                                    to={url}
                                    onClick={onClose}
                                    role="button"
                                    className="footer-link"
                                >
                                    <TextAdaptive size="text16" weight="medium">
                                        {title}
                                    </TextAdaptive>
                                </NavLink>
                            </LinkListItem>
                        ))}
                    </LinkList>
                </Collapse>
            ) : (
                <>
                    <HeadingTextStyled size="text20" weight="medium">
                        Информация
                    </HeadingTextStyled>
                    <LinkList>
                        {routesInfo.map(({ title, url }) => (
                            <LinkListItem key={url} itemProp="name">
                                <NavLink
                                    itemProp="url"
                                    to={url}
                                    onClick={onClose}
                                    role="button"
                                    className="footer-link"
                                >
                                    <TextAdaptive size="text16" weight="medium">
                                        {title}
                                    </TextAdaptive>
                                </NavLink>
                            </LinkListItem>
                        ))}
                    </LinkList>
                </>
            )}
        </NavigationInfoWrapper>
    );
};

export default NavigationInfo;
