import styled from "styled-components";
import { Skeleton } from "~atoms/skeleton";
import { Text } from "~atoms/text";
import { Title } from "~atoms/title";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { text12M, text14M, text16M, text24M } from "~tokens/typography";

export const LotteryRulesHeaderWrapper = styled.div`
    display: block;
`;

export const TextWrapper = styled.div`
    width: 100%;
    max-width: 257px;
    margin-bottom: ${INDENT.m};
`;

export const TitleStyled = styled(Title)`
    margin-bottom: ${INDENT.xxxs};

    ${text16M}
    ${adaptive.maxWidth.tablet} {
        ${text12M}
    }
`;

export const TextStyled = styled(Text)`
    ${text24M}
    ${adaptive.maxWidth.tablet} {
        ${text14M};
        font-weight: 500 !important;
    }
`;

export const SkeletonStyled = styled(Skeleton)`
    border-radius: 10px;
    height: 32px;
    ${adaptive.maxWidth.tablet} {
        height: 20px;
    }
`;
