import { getDateFromISO8601 } from "~helpers/format";
import { TBasket, TBasketParsed, TBasketDefault } from "~types/basket";

export const getDataFromRes = (res: TBasket) => {
    const resultParsed: TBasketParsed = {
        active: {},
        expired: {},
    };

    if (res.length === 0) return resultParsed;

    res.map((item) => {
        const parsedItem = {
            ...item,
            draw: { ...item.draw, time: getDateFromISO8601(item.draw!.drawDate, "DD MMMM YYYY") },
        };
        const expired = item.expired;
        const defaultArr = resultParsed[expired ? "expired" : "active"][parsedItem.alias] || [];
        let indexSomeEl = -1;
        (defaultArr as Array<Array<TBasketDefault>>).map((p, idx) => {
            p.find((i) => {
                if (i.draw.drawNum === parsedItem.draw.drawNum) {
                    indexSomeEl = idx;
                }
            });
        });
        if (indexSomeEl >= 0) {
            resultParsed[expired ? "expired" : "active"][parsedItem.alias][indexSomeEl] = [
                ...defaultArr[indexSomeEl],
                parsedItem,
            ];
        } else {
            resultParsed[expired ? "expired" : "active"][parsedItem.alias] = [...defaultArr, [parsedItem]];
        }
    });

    return resultParsed;
};
