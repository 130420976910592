import React, { DetailedHTMLProps, LinkHTMLAttributes, PropsWithChildren, ReactNode, useCallback } from "react";
import { LinkWrapper } from "~atoms/link/theme";

type TLinkProps = {
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
    download?: boolean;
    intent?: boolean;
    prefixIcon?: ReactNode;
    suffixIcon?: ReactNode;
} & DetailedHTMLProps<LinkHTMLAttributes<HTMLLinkElement>, HTMLLinkElement>;

const Link = ({
    children,
    onClick,
    href = "",
    className,
    disabled,
    role,
    download = false,
    intent,
    suffixIcon,
    prefixIcon,
}: PropsWithChildren<TLinkProps>) => {
    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            if (onClick) {
                return onClick(event);
            }
            if (href && typeof window !== "undefined") {
                window.open(href, "_blank");
            }
        },
        [href, onClick],
    );

    return (
        <LinkWrapper
            onClick={handleClick}
            href={href}
            className={className}
            role={role}
            disabled={disabled}
            download={download}
            intent={intent}
        >
            {prefixIcon && (
                <span role="img" className="prefix-wrap">
                    {prefixIcon}
                </span>
            )}
            {children}
            {suffixIcon && (
                <span role="img" className="suffix-wrap">
                    {suffixIcon}
                </span>
            )}
        </LinkWrapper>
    );
};

export default Link;
