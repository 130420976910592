import styled from "styled-components";
import { GREY_LIGHT } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const TableStyled = styled.table`
    border-collapse: separate;
    border-spacing: 0 ${INDENT.xxxxs};
    width: 100%;

    tbody tr {
        background: ${GREY_LIGHT};

        td:first-child {
            border-left-style: solid;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;

            ${adaptive.maxWidth.tablet} {
                text-align: left;
            }
        }

        td:last-child {
            border-right-style: solid;
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;

            ${adaptive.maxWidth.tablet} {
                text-align: right;
            }
        }
    }

    thead tr {
        ${adaptive.maxWidth.tablet} {
            th:first-child {
                text-align: left;
            }
        }
    }

    th {
        padding: ${INDENT.xxxxs} ${INDENT.sm} ${INDENT.xxxxs} ${INDENT.none};
        text-align: right;

        ${adaptive.maxWidth.tablet} {
            padding: ${INDENT.none} ${INDENT.none} ${INDENT.xs} ${INDENT.none};
        }
    }

    td {
        padding: ${INDENT.xs} ${INDENT.sm};
    }
`;
