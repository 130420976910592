import { CloseIcon } from "~atoms/icons";
import { TWithoutWin } from "./withoutWin-block";

export const getWithoutWinStatus = ({ registrationStatus, paymentStatus, theme }: TWithoutWin) => {
    if (registrationStatus === "CANCELED" && paymentStatus === "ACCEPTED") {
        return {
            color: theme.color.accent.negative,
            text: "Билет не зарегистрирован",
            iconColor: theme.color.accent.negative,
            icon: CloseIcon,
        };
    }

    if (registrationStatus === "CANCELED") {
        return {
            color: theme.color.text.negative,
            text: "Отклонено",
            iconColor: theme.color.accent.negative,
            icon: CloseIcon,
        };
    } else {
        return {
            color: theme.color.text.tertiary,
            text: "Без выигрыша",
            icon: null,
        };
    }
};
