import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { WHITE } from "~assets/styles/tokens/colors";
import { INDENT } from "~assets/styles/tokens/sizes";
import { fontWeight600 } from "~tokens/typography";

const BreadcrumbsWrapper = styled.ul``;

const LiStyled = styled.li`
    display: inline-block;
    vertical-align: middle;

    &:first-child {
        .crumb-separator {
            display: none;
        }
    }
`;

const StyledLink = styled(NavLink)`
    color: rgba(255, 255, 255, 0.8);
    & + .crumb-separator {
        color: rgba(255, 255, 255, 0.8);
    }

    .crumb-text {
        color: inherit;
    }

    &.active {
        .crumb-text,
        & + .crumb-separator {
            ${fontWeight600};
            color: ${WHITE};
        }
    }
`;

const LinkWrap = styled.div`
    display: flex;
    align-items: center;

    a {
        order: 2;
    }

    .crumb-separator {
        order: 1;
        margin: ${INDENT.none} ${INDENT.xs};
    }
`;

export { BreadcrumbsWrapper, LiStyled, LinkWrap, StyledLink };
