import React, { memo, useCallback, useContext } from "react";
import { ThemeContext } from "styled-components";
import { CheckMarkIcon } from "~atoms/icons";
import { Text } from "~atoms/text";
import { BingoCombinations } from "~organisms/bingo-combinations";
import { TOptions } from "~organisms/bingo-combinations/bingo-combinations";
import { useToggleTicket } from "~pages/lottery/bingo-lottery/_hooks/useToggleTicket";
import { getMoneyFormat } from "~helpers/format";
import { TBingo } from "~store/tickets/types";
import { ticketsBingoType } from "~constants/tickets-type";
import { useAppSelector } from "~hooks/useAppSelector";
import {
    Bingo75Wrapper,
    TicketCheckbox,
    HeaderLogo,
    HeaderPrice,
    TicketBody,
    TicketFooter,
    TicketHeader,
    MaskWrapper,
    FieldWrapper,
} from "./theme";

type TBingo75Props = {
    idx: number;
    className?: string;
    includeMyNumbers?: boolean;
};

const bingoFieldOptions: TOptions = {
    mobile: {
        width: 48,
        height: 48,
    },
    desktop: {
        width: 48,
        height: 48,
    },
};

const TicketBingo75 = ({ className, idx, includeMyNumbers }: TBingo75Props) => {
    const { handleToggleTicket } = useToggleTicket(ticketsBingoType.b75);
    const numbersSelected = useAppSelector((state) => state.tickets[ticketsBingoType.b75].numbersSelected);
    const logoSrc = useAppSelector((state) => state.tickets[ticketsBingoType.b75].logoSrc);
    const ticketsSelected = useAppSelector((state) => state.tickets[ticketsBingoType.b75].ticketsSelected);
    const ticket = useAppSelector((state) => state.tickets.bingo75.data[idx] as TBingo);
    const { serialNum, fields, amount } = ticket;
    const { color } = useContext(ThemeContext);

    const handleSelect = useCallback(() => {
        handleToggleTicket(serialNum);
    }, [handleToggleTicket, serialNum]);

    return (
        <Bingo75Wrapper className={className} onClick={handleSelect}>
            <TicketCheckbox selected={ticketsSelected.includes(serialNum)}>
                <CheckMarkIcon />
            </TicketCheckbox>
            <MaskWrapper>
                <TicketHeader selected={ticketsSelected.includes(serialNum)}>
                    {logoSrc ? <HeaderLogo src={logoSrc} width="138px" height="32px" alt="Лотерея Бинго 75" /> : null}
                    <HeaderPrice tag="span" color={color.text.inverted} weight="medium" size="text14">
                        Цена: {getMoneyFormat(amount)} ₽
                    </HeaderPrice>
                </TicketHeader>
                <TicketBody>
                    <FieldWrapper className="mauto">
                        {fields.main && (
                            <BingoCombinations
                                data={fields.main}
                                options={bingoFieldOptions}
                                selectedNumbers={includeMyNumbers ? numbersSelected : undefined}
                            />
                        )}
                    </FieldWrapper>
                </TicketBody>
                <TicketFooter selected={ticketsSelected.includes(serialNum)}>
                    <Text color={color.text.inverted} weight="medium" size="text12" className="mauto">
                        Билет № {serialNum}
                    </Text>
                </TicketFooter>
            </MaskWrapper>
        </Bingo75Wrapper>
    );
};

export default memo(TicketBingo75);
