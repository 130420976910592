import { AppState } from "../rootReducer";

export enum QUERY_TYPE {
    "$set",
    "$push",
    "$apply",
    "$merge",
}

export enum ACTIONS_TYPE {
    EVENT_FIELD_CHANGE = "EVENT_FIELD_CHANGE",
    EVENT_FIELD_MANY_CHANGE = "EVENT_FIELD_MANY_CHANGE",
}

export type TEventFieldChangeAction = {
    type: ACTIONS_TYPE.EVENT_FIELD_CHANGE;
    payload: {
        path: string;
        value: unknown;
    };
    meta: keyof AppState;
};

export type TEventFieldManyChangeAction = {
    type: ACTIONS_TYPE.EVENT_FIELD_MANY_CHANGE;
    payload: Array<{
        path: string;
        value: unknown;
    }>;
    meta: keyof AppState;
};

export type TMutationActions = TEventFieldChangeAction | TEventFieldManyChangeAction;
