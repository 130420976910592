import React from "react";
import { TTicketOptions } from "~pages/lottery/types";
import { TTicketTypes } from "~store/tickets/types";
import { useBuyTicketsCardDigitalLogic } from "./_hooks/useBuyTicketsCardDigitalLogic";
import BuyTicketsCardView from "./buy-tickets-card-view";

type TBuyTicketsCardDigitalProps = {
    className?: string;
    ticketType: TTicketTypes;
    ticketOptions: TTicketOptions;
};

const BuyTicketsCardDigital = ({ className, ticketType, ticketOptions }: TBuyTicketsCardDigitalProps) => {
    const {
        data: { ticketsCount, editionsCount, amount },
        cb: { handleBuyNow },
    } = useBuyTicketsCardDigitalLogic(ticketType, ticketOptions);

    return (
        <BuyTicketsCardView
            amount={amount}
            handleBuyNow={handleBuyNow}
            ticketsCount={ticketsCount}
            editionsCount={editionsCount}
            className={className}
        />
    );
};

export default BuyTicketsCardDigital;
