import { useCallback } from "react";
import { TBingoTypes } from "~store/tickets/types";
import { useAppStore } from "~hooks/useAppStore";
import { useMutationHandles } from "~hooks/useMutationHandles";

export const useToggleTicket = (bingoType: TBingoTypes) => {
    const { handleFieldChange } = useMutationHandles("tickets");
    const store = useAppStore();
    const handleToggleTicket = useCallback(
        (serialNum: string) => {
            const { tickets } = store.getState();
            const ticketsSelected = tickets[bingoType].ticketsSelected;
            const result = ticketsSelected.includes(serialNum)
                ? ticketsSelected.filter((id) => id !== serialNum)
                : [...ticketsSelected, serialNum];

            handleFieldChange(`${bingoType}.ticketsSelected`, result);
        },
        [bingoType, handleFieldChange, store],
    );

    return { handleToggleTicket };
};
