import axios from "axios";

export const initHttp = (baseURL: string = __BACKEND_CONTEXT__) => {
    const instance = axios.create({
        baseURL,
        timeout: 10000,
        withCredentials: true,
        headers: {
            pragma: "no-cache",
            "Cache-Control": "no-cache",
            "x-nl-partner": "d56450f7-92ec-11ec-b015-00505601724f",
        },
    });

    return instance;
};
