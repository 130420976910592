import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { actionResetTickets } from "~store/tickets/actions";

export const useResetTickets = () => {
    const dispatch = useDispatch();
    const resetTickets = useCallback(() => {
        dispatch(actionResetTickets);
    }, [dispatch]);
    return { resetTickets };
};
