import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import { TTextAdaptiveSize, TTextAdaptiveTag, TWeight } from "~atoms/text-adaptive/text-adaptive";
import { TTitleAdaptiveSize, TTitleAdaptiveTag } from "~atoms/title-adaptive/title-adaptive";
import { CollapseBtn, Content, ContentBody } from "./theme";
import { TitleDefault } from "./title-default";

type TCollapseProps = {
    id?: string;
    title?: string;
    titleSize?: TTitleAdaptiveSize;
    titleTag?: TTitleAdaptiveTag;
    text?: string;
    textTag?: TTextAdaptiveTag;
    textSize?: TTextAdaptiveSize;
    weight?: TWeight;
    className?: string;
    children?: React.ReactNode;
    open?: boolean;
    onChange?: (id: string, open: boolean) => void;
    render?: (props: any) => JSX.Element;
};
const Collapse = memo(
    ({
        id = "",
        children,
        title,
        titleSize = "title20",
        titleTag = "div",
        text,
        textSize = "text16",
        textTag = "p",
        open = false,
        className,
        weight = "demiBold",
        render,
        onChange,
    }: TCollapseProps) => {
        const ref = useRef<HTMLDivElement>(null);
        const [isOpen, setOpen] = useState(open);
        const resultOpen = onChange ? open : isOpen;
        const isRender = Boolean(render);

        useEffect(() => {
            const el = ref.current!;
            if (open) {
                el.style.maxHeight = el.scrollHeight + "px";
            } else {
                el.style.maxHeight = "";
            }
        }, [open]);

        const onClick = useCallback(
            (e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                const content = e.currentTarget.nextElementSibling as HTMLElement;
                if (resultOpen) {
                    content.style.maxHeight = "";
                } else {
                    content.style.maxHeight = content.scrollHeight + "px";
                }
                if (onChange) {
                    onChange(id, !resultOpen);
                } else {
                    setOpen(!resultOpen);
                }
            },
            [onChange, id, resultOpen],
        );

        return (
            <>
                <CollapseBtn className={className} onClick={onClick} open={resultOpen} isRender={isRender}>
                    {render ? (
                        render("123")
                    ) : (
                        <TitleDefault
                            title={title}
                            titleSize={titleSize}
                            titleTag={titleTag}
                            text={text}
                            textSize={textSize}
                            textTag={textTag}
                            weight={weight}
                        />
                    )}
                </CollapseBtn>
                <Content ref={ref} className="collapse-content">
                    <ContentBody>{children}</ContentBody>
                </Content>
            </>
        );
    },
);

Collapse.displayName = "Collapse";

export default Collapse;
