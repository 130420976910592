import React, { memo, PropsWithChildren, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Flex } from "~atoms/flex-box";
import { ArrowDropdownIcon } from "~atoms/icons";
import { Skeleton } from "~atoms/skeleton";
import { Text } from "~atoms/text";
import { SelectModalBingo } from "~organisms/select-modal";
import { useGetDraw } from "~pages/lottery/bingo-lottery/_hooks/useGetDraw";
import { ticketOptions } from "~pages/lottery/bingo-lottery/common/helpers";
import { dateDifferenceInSecond } from "~helpers/format";
import { TBingoTypes } from "~store/tickets/types";
import { TBingoAlias } from "~types/products";
import { useAppSelector } from "~hooks/useAppSelector";
import { DrawTimer } from "./draw-timer";

type TTicketsDrawProps = {
    className?: string;
    ticketType: TBingoTypes;
    ticketAlias: TBingoAlias;
};

const TicketsDrawWrapper = styled.div`
    display: block;
`;

const SkeletonStyled = styled(Skeleton)`
    width: 275px;
    height: 50px;
    border-radius: 8px;
`;

const TicketsDraw = memo(({ className, ticketType, ticketAlias }: PropsWithChildren<TTicketsDrawProps>) => {
    const editionsLength = useAppSelector((state) => state.tickets[ticketType].editions.length);
    const drawDate = useAppSelector((state) => dateDifferenceInSecond(state.tickets[ticketType].editions[0]?.drawDate));
    const { getDraw } = useGetDraw(ticketAlias, ticketType);
    const { color } = useContext(ThemeContext);

    if (!editionsLength || drawDate <= 0) {
        return <SkeletonStyled />;
    }

    return (
        <TicketsDrawWrapper className={className}>
            <Flex
                justify="flex-end"
                $direction={editionsLength === 1 ? "column" : "row"}
                tablet={{ $direction: "column", alignItems: "flex-start" }}
                alignItems={editionsLength === 1 ? "flex-start" : "center"}
            >
                <Text
                    size="text14"
                    tag="span"
                    className="mr12 mr8--desktopxl mr0--tablet mb4--tablet"
                    color={color.text.primary}
                >
                    {editionsLength === 1 ? "Ближайший тираж" : "Выберите тираж"}
                </Text>
                {editionsLength === 1 ? (
                    <DrawTimer drawDate={drawDate} refreshDraw={getDraw} />
                ) : (
                    <SelectModalBingo
                        autoWidth
                        ticketType={ticketType}
                        ticketAlias={ticketOptions[ticketType].alias}
                        suffixIcon={<ArrowDropdownIcon />}
                    />
                )}
            </Flex>
        </TicketsDrawWrapper>
    );
});
TicketsDraw.displayName = "TicketsDraw";
export default TicketsDraw;
