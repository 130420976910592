import { useCallback, useEffect, useRef } from "react";

export const useFocusTicket = (lengthTickets: number) => {
    const elemRef = useRef<HTMLDivElement>(null);
    const focusIndex = useRef<number | null>(null);

    useEffect(() => {
        if (elemRef.current) {
            elemRef.current.scrollIntoView({ block: "end", inline: "nearest" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lengthTickets]);

    const setFocus = useCallback(
        (idx: number, event: "add" | "remove") => {
            let value = idx;
            if (event === "remove") {
                if (lengthTickets - 1 === idx) {
                    value = idx - 1 < 0 ? 0 : idx - 1;
                }
            }
            focusIndex.current = value;
        },
        [lengthTickets],
    );

    return { elemRef, setFocus, focusIndex };
};
