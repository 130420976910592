import { Link } from "react-router-dom";
import styled from "styled-components";
import { transitionDefault } from "~assets/styles/utils";
import { SliderSwiper } from "~molecules/slider-swiper";
import { PURPLE, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

const Wrapper = styled.div`
    position: relative;
    padding: ${INDENT.xxxl} ${INDENT.none};
    background-color: ${WHITE};
`;

const ContentWrap = styled.div``;
const ImageWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 62.4%;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }
    ${adaptive.minWidth.desktopXXL} {
        &:after {
            background: rgba(29, 21, 79, 0.4);
            ${transitionDefault}
            border-radius: 12px;
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
        &:hover:after {
            opacity: 1;
        }
    }
`;

const TitleSlide = styled.figcaption`
    padding-top: ${INDENT.xs};
    ${adaptive.minWidth.desktopXXL} {
        & div {
            ${transitionDefault}
        }
        &:hover div {
            color: ${PURPLE};
        }
    }
`;

const FigureStyled = styled.figure``;

const HeaderWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SliderSwiperStyled = styled(SliderSwiper)`
    .arrow-btn-next,
    .arrow-btn-prev {
        top: 40%;
    }
    .arrow-btn-next {
        right: -10px;
    }

    .arrow-btn-prev {
        left: -10px;
    }
`;

const LinkStyled = styled(Link)`
    color: inherit;
`;

export { LinkStyled, Wrapper, FigureStyled, ImageWrapper, SliderSwiperStyled, TitleSlide, ContentWrap, HeaderWrap };
