import { TClientStatusIdentityType } from "~types/client";

export const orderPaymentStatus = {
    PROGRESS: {
        code: "PROGRESS",
        value: "wait",
    },
    CREATED: {
        code: "CREATED",
        value: "wait",
    }, // только что создан и не оправлен в процессинг
    CANCELED: {
        code: "CANCELED",
        value: "failed",
    },
    ACCEPTED: {
        code: "ACCEPTED",
        value: "success",
    },
};

export enum identityStatus {
    ACCEPTED = "ACCEPTED",
    REJECTED = "REJECTED",
    CREATED = "CREATED",
    UNKNOWN = "UNKNOWN",
}

export const basicIdentityProfileStatus: Record<
    TClientStatusIdentityType,
    { code: TClientStatusIdentityType; value: string }
> = {
    [identityStatus.UNKNOWN]: {
        code: identityStatus.UNKNOWN,
        value: "Аккаунт не подтверждён на Госуслугах. Некоторые возможности ограничены.",
    }, // если приходит null
    [identityStatus.REJECTED]: {
        code: identityStatus.REJECTED,
        value: "Почему-то произошла ошибка при подтверждении аккаунта на Госуслугах. Некоторые возможности всё ещё ограничены.",
    },
    [identityStatus.CREATED]: {
        code: identityStatus.CREATED,
        value: "Аккаунт в процессе подтверждения на сайте Госуслуг. Некоторые возможности ограничены. Но это ненадолго!",
    },
    [identityStatus.ACCEPTED]: {
        code: identityStatus.ACCEPTED,
        value: "Ваш аккаунт подтверждён на сайте госуслуг!",
    },
};

export const fullIdentityProfileStatus: Record<TClientStatusIdentityType, { code: TClientStatusIdentityType }> = {
    [identityStatus.UNKNOWN]: {
        code: identityStatus.UNKNOWN,
    }, // если приходит null
    [identityStatus.REJECTED]: {
        code: identityStatus.REJECTED,
    },
    [identityStatus.CREATED]: {
        code: identityStatus.CREATED,
    },
    [identityStatus.ACCEPTED]: {
        code: identityStatus.ACCEPTED,
    },
};

export enum TicketStatus {
    WIN = "WIN",
    WIN_PAYED = "WIN_PAYED",
    NOT_WIN = "NOT_WIN",
}

export const legalText = {
    "digital-4x20": {
        algoInfo: "«ВГЛ 1Т Спорт Союз», алгоритм определения выигрышей № 2.",
        prizeInfo: "60%",
    },
    "digital-8x20": {
        algoInfo: "«ВГЛ-1Т Спорт Союз», алгоритм определения выигрышей №5, таблица №13.",
        prizeInfo: "60%",
    },
    "digital-5x36": {
        algoInfo: "«ВГЛ-1Т Спорт Союз», алгоритм определения выигрышей №3, таблица №6.",
        prizeInfo: "60%",
    },
    "digital-5x50": {
        algoInfo: "«ВГЛ-1Т Спорт Союз», алгоритм определения выигрышей №1, вариант А, таблица №1.",
        prizeInfo: "60%",
    },
    "bingo-90": {
        algoInfo: "Алгоритм определения выигрышей № 2, ВГЛ-2Т Спорт Союз.",
        prizeInfo: "65%",
    },
    "bingo-75": {
        algoInfo: "Алгоритм определения выигрышей № 2, ВГЛ-2Т Спорт Союз.",
        prizeInfo: "65%",
    },
    "bingo-3x3": {
        algoInfo: "Алгоритм определения выигрышей № 2, ВГЛ-2Т Спорт Союз.",
        prizeInfo: "65%",
    },
};
