export { ReactComponent as PersonIcon } from "./svg/person.svg";
export { ReactComponent as BasketIcon } from "./svg/basket.svg";
export { ReactComponent as CloseIcon } from "./svg/close.svg";
export { ReactComponent as SearchIcon } from "./svg/search.svg";
export { ReactComponent as WarningIcon } from "./svg/warning.svg";
export { ReactComponent as CalendarIcon } from "./svg/calendar.svg";
export { ReactComponent as WalletIcon } from "./svg/wallet.svg";
export { ReactComponent as ReceiptIcon } from "./svg/receipt.svg";
export { ReactComponent as EyeOffIcon } from "./svg/eye-off.svg";
export { ReactComponent as EyeIcon } from "./svg/eye.svg";
export { ReactComponent as FacebookIcon } from "./svg/facebook.svg";
export { ReactComponent as InstagramIcon } from "./svg/instagram.svg";
export { ReactComponent as VkIcon } from "./svg/vk.svg";
export { ReactComponent as ArrowIcon } from "./svg/arrow.svg";
export { ReactComponent as OdnoklassnikiIcon } from "./svg/odnoklassniki.svg";
export { ReactComponent as TwitterIcon } from "./svg/twitter.svg";
export { ReactComponent as WhatsappIcon } from "./svg/whatsapp.svg";
export { ReactComponent as EditIcon } from "./svg/edit.svg";
export { ReactComponent as ArrowBackIcon } from "./svg/arrow-back.svg";
export { ReactComponent as FlashIcon } from "./svg/flash.svg";
export { ReactComponent as RestoreIcon } from "./svg/restore.svg";
export { ReactComponent as StarIcon } from "./svg/star.svg";
export { ReactComponent as ArrowDropdownIcon } from "./svg/arrow-dropdown.svg";
export { ReactComponent as CupIcon } from "./svg/cup.svg";
export { ReactComponent as LotteryIcon } from "./svg/lottery.svg";
export { ReactComponent as TicketIcon } from "./svg/ticket.svg";
export { ReactComponent as AndroidDownloadIcon } from "./svg/android-download.svg";
export { ReactComponent as AppStoreDownloadIcon } from "./svg/app-store-download.svg";
export { ReactComponent as MastercardIcon } from "./svg/mastercard.svg";
export { ReactComponent as MirIcon } from "./svg/mir.svg";
export { ReactComponent as VisaIcon } from "./svg/visa.svg";
export { ReactComponent as AccessTimeIcon } from "./svg/access-time.svg";
export { ReactComponent as ErrorAlertIcon } from "./svg/error-alert.svg";
export { ReactComponent as AcceptedIcon } from "./svg/accepted.svg";
export { ReactComponent as NounAnonymousIcon } from "./svg/noun-anonymous.svg";
export { ReactComponent as PersonWhiteIcon } from "./svg/person-white.svg";
export { ReactComponent as CallIcon } from "./svg/call.svg";
export { ReactComponent as MailIcon } from "./svg/mail.svg";
export { ReactComponent as GiftIcon } from "./svg/gift.svg";
export { ReactComponent as MobilePhoneIcon } from "./svg/mobile-phone.svg";
export { ReactComponent as DiceIcon } from "./svg/dice.svg";
export { ReactComponent as DiceOddIcon } from "./svg/dice-odd.svg";
export { ReactComponent as DiceEvenIcon } from "./svg/dice-even.svg";
export { ReactComponent as DiceTopIcon } from "./svg/dice-top.svg";
export { ReactComponent as DiceBottomIcon } from "./svg/dice-bottom.svg";
export { ReactComponent as ProfileIcon } from "./svg/profile.svg";
export { ReactComponent as ArrowLongIcon } from "./svg/arrowLong.svg";
export { ReactComponent as EraserIcon } from "./svg/eraser.svg";
export { ReactComponent as LogoIcon } from "./svg/logo.svg";
export { ReactComponent as FileCopyIcon } from "./svg/file-copy.svg";
export { ReactComponent as TrashIcon } from "./svg/trash.svg";
export { ReactComponent as ArrowScrollTopIcon } from "./svg/arrow-scroll-top.svg";
export { ReactComponent as CheckedIcon } from "./svg/checked.svg";
export { ReactComponent as CheckMarkIcon } from "./svg/check-mark.svg";
export { ReactComponent as PDFIcon } from "./svg/pdf.svg";
export { ReactComponent as HandshakeIcon } from "./svg/handshake.svg";
export { ReactComponent as CharityIcon } from "./svg/charity.svg";
export { ReactComponent as MagicWandIcon } from "./svg/magic-wand.svg";
export { ReactComponent as DocumentIcon } from "./svg/document.svg";
export { ReactComponent as CardIcon } from "./svg/card.svg";
export { ReactComponent as PlayingIcon } from "./svg/playing.svg";
export { ReactComponent as ApplePayIcon } from "./svg/apple-pay.svg";
export { ReactComponent as CancelIcon } from "./svg/cancel.svg";
export { ReactComponent as GosuslugiIcon } from "./svg/gosuslugi.svg";
export { ReactComponent as PdfIcon } from "./svg/pdf.svg";
export { ReactComponent as NotConfirmedIcon } from "./svg/not-confirmed.svg";
export { ReactComponent as ProfileAcceptedIcon } from "./svg/profile-accepted.svg";
export { ReactComponent as ProfileCreatedIcon } from "./svg/profile-created.svg";
export { ReactComponent as ProfileRejectedIcon } from "./svg/profile-rejected.svg";
export { ReactComponent as MenuIcon } from "./svg/menu.svg";
export { ReactComponent as CameraIcon } from "./svg/camera.svg";
export { ReactComponent as PinOfflineIcon } from "./svg/pin-offline.svg";
