import React from "react";
import { Collapse } from "~atoms/collapse";
import { useAppSelector } from "~hooks/useAppSelector";
import { CollapseTitle } from "./collapse-title";
import { CollapseWrapper } from "./theme";
import { MyTicketStyled } from "../theme";

type TInprogressTickets = {
    className?: string;
};

const InProgressTickets = ({ className }: TInprogressTickets) => {
    const { content } = useAppSelector((state) => state.myTickets.payment);
    if (!content) {
        return null;
    }
    return (
        <CollapseWrapper className={className}>
            <Collapse render={() => <CollapseTitle count={content.length} />}>
                {content.map((item, index) => {
                    return <MyTicketStyled {...item} key={index} notLink isContent="payment" />;
                })}
            </Collapse>
        </CollapseWrapper>
    );
};

export default InProgressTickets;
