import styled from "styled-components";
import { Title } from "~atoms/title";
import { PURPLE, GREY_DARK2, WHITE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { title20 } from "~tokens/typography";

export const AboutAside = styled.div`
    svg {
        color: ${PURPLE};
    }
`;

export const AboutContent = styled.div`
    display: flex;
    flex-flow: column nowrap;
    padding: ${INDENT.m};
    background-color: ${WHITE};
    border-radius: 20px;

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.sm};
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.xs};
    }
`;

export const Banner = styled.div<{
    backgroundDesktop1920: string;
    backgroundDesktop1280: string;
    backgroundTablet: string;
    backgroundMobile: string;
    backgroundPosition: "left" | "right";
    tall?: boolean;
}>`
    height: 280px;
    position: relative;
    z-index: 0;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: ${INDENT.none} 126px ${INDENT.none} 76px;
    background: url(${({ backgroundDesktop1920 }) => backgroundDesktop1920}) no-repeat;
    background-size: cover;
    justify-content: ${({ backgroundPosition }) => (backgroundPosition === "left" ? "flex-end" : "flex-start")};

    & > p,
    & > div {
        flex-basis: ${({ tall }) => (tall ? "380px" : "388px")};
    }

    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.none} ${INDENT.xxxl};
        background: url(${({ backgroundDesktop1280 }) => backgroundDesktop1280}) no-repeat;
    }

    ${adaptive.maxWidth.desktopXL} {
        padding: ${INDENT.s} ${INDENT.xxxl} ${INDENT.s} ${INDENT.sm};
        height: 186px;
        background: url(${({ backgroundTablet }) => backgroundTablet}) no-repeat;
        background-size: cover;

        & > p,
        & > div {
            flex-basis: 314px;
        }

        &:before {
            background-position: ${({ backgroundPosition }) =>
                backgroundPosition === "left" ? `${INDENT.m}` : `calc(100% - ${INDENT.m})`};
        }
    }

    ${adaptive.maxWidth.tablet} {
        background: url(${({ backgroundMobile }) => backgroundMobile}) no-repeat;
        background-size: cover;
        height: ${({ tall }) => (tall ? "400px" : "348px")};
        padding: ${INDENT.xs};
        align-items: flex-start;
        justify-content: center;

        & > p,
        & > div {
            flex-basis: 264px;
        }
    }
`;

export const TitleStyled = styled(Title)`
    ${adaptive.maxWidth.desktopXXL} {
        ${title20}
    }
`;

export const SplitLine = styled.div`
    border-bottom: 1px solid ${GREY_DARK2};
`;

export const TextBlock = styled.div``;
