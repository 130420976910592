import React, { memo, useMemo } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { HOME } from "~constants/routes";

type TProtectedRoute = {
    guards: Array<() => boolean>;
    redirectPath?: string;
    children?: React.ReactNode;
} & RouteProps;

const ProtectedRoute = memo(({ guards, component, path, redirectPath = HOME, children }: TProtectedRoute) => {
    const isAllowed = useMemo(() => guards.every((guard) => guard()), [guards]);

    if (!isAllowed && __PROD__) return <Redirect to={redirectPath} />;

    return (
        <Route component={component} path={path}>
            {children}
        </Route>
    );
});

ProtectedRoute.displayName = "ProtectedRoute";
export default ProtectedRoute;
