import React, { PropsWithChildren, useCallback } from "react";
import styled from "styled-components";
import { transitionDefault } from "~assets/styles/utils";
import { Text } from "~atoms/text";
import { Title } from "~atoms/title";
import { PURPLE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import useAppHistory from "~hooks/useAppHistory";

type TMomentaryTicketProps = {
    className?: string;
    title: string;
    prize: string;
    srcImg: string;
    url: string;
};

const TicketLink = styled.a`
  display: block;
  cursor: pointer;

  & h4, p {

`;

const ImageWrapper = styled.div`
    width: 100%;
    height: 177px;
    position: relative;

    & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 12px;
    }

    ${adaptive.minWidth.desktopXXL} {
        &:after {
            background: rgba(29, 21, 79, 0.4);
            ${transitionDefault};
            border-radius: 12px;
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }

        &:hover:after {
            opacity: 1;
        }
    }

    ${adaptive.maxWidth.desktopXXL} {
        height: 158px;
    }

    ${adaptive.maxWidth.desktopXL} {
        height: 203px;
    }
`;

const TitleWrapper = styled.div`
    & h4,
    p {
        ${transitionDefault}
    }

    ${adaptive.minWidth.desktopXXL} {
        &:hover h4,
        &:hover p {
            color: ${PURPLE};
        }
    }
`;

const MomentaryTicket = ({ title, srcImg, prize, className, url }: PropsWithChildren<TMomentaryTicketProps>) => {
    const { go } = useAppHistory();
    const handleGo = useCallback(() => {
        go(url);
    }, [url, go]);
    return (
        <div className={className}>
            <TicketLink onClick={handleGo}>
                <ImageWrapper className="mb8">
                    <img src={srcImg} alt={title} width={342} height={224} draggable={false} />
                </ImageWrapper>
                <TitleWrapper>
                    <Title tag={"h4"} size={"title20"}>
                        {title}
                    </Title>
                    <Text size={"text16"} weight={"medium"}>
                        {prize}
                    </Text>
                </TitleWrapper>
            </TicketLink>
        </div>
    );
};

export default MomentaryTicket;
