import React from "react";
import styled from "styled-components";
import { COOKIES_ACCEPTING } from "~constants/routes";
import { useAppRouteMatch } from "~hooks/useAppRouteMatch";
import { AgreementList } from "./agreement-list";

const PoliticsWrapper = styled.div`
    display: block;
`;

const PoliticsWeb = () => {
    const { isExact: isCookiePage } = useAppRouteMatch(COOKIES_ACCEPTING);

    return (
        <PoliticsWrapper>
            <AgreementList className="mt48 container-app" isShowTabGroup={!isCookiePage} />
        </PoliticsWrapper>
    );
};

export default PoliticsWeb;
