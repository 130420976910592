import styled from "styled-components";
import { PURPLE } from "~tokens/colors";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const PrinciplesWrapper = styled.div`
    & > h5 {
        text-align: center;
    }

    ${adaptive.maxWidth.tablet} {
        padding: ${INDENT.none} ${INDENT.xxs};
    }
`;

export const Principle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        color: ${PURPLE};
        width: 64px;
        height: auto;
        flex-shrink: 0;
    }

    & > p {
        max-width: 166px;
        width: 100%;
    }

    ${adaptive.maxWidth.desktopXL} {
        flex-direction: column;

        & > p {
            text-align: center;
        }
    }

    ${adaptive.maxWidth.tablet} {
        flex-direction: row;
        justify-content: initial;

        & > svg {
            width: 48px;
        }

        & > p {
            max-width: 240px;
            text-align: unset;
        }
    }
`;
