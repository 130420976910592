import { TTipsData } from "~molecules/tip-group/helpers";
import { LOTTERY, WALLET } from "~constants/routes";

export const crumbs = [
    {
        title: "Главная",
        path: LOTTERY,
        exact: true,
    },
    {
        title: "Кошелек",
        path: WALLET,
        exact: true,
    },
];

export const data: TTipsData[] = [
    {
        title: "+100 ₽",
        value: 100,
    },
    {
        title: "+200 ₽",
        value: 200,
    },
    {
        title: "+500 ₽",
        value: 500,
    },
    {
        title: "+1 000 ₽",
        value: 1000,
    },
];
