import React, { ForwardedRef, ReactNode } from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { SelectModalButton, SelectModalWrapper } from "~organisms/select-modal/theme";

type TSelectModalViewProps = {
    handleOpen: () => void;
    title: string;
    suffixIcon: ReactNode;
    className?: string;
    autoWidth?: boolean;
};

const SelectModalView = React.forwardRef(function SelectModalView(
    props: TSelectModalViewProps,
    ref: ForwardedRef<HTMLDivElement>,
) {
    const { className, autoWidth, suffixIcon, title, handleOpen } = props;
    return (
        <SelectModalWrapper
            ref={ref}
            tabIndex={0}
            role="button"
            className={className}
            autoWidth={autoWidth}
            onClick={handleOpen}
        >
            <SelectModalButton>
                <TextAdaptive size="text16" tag="p">
                    {title}
                </TextAdaptive>
                {suffixIcon && (
                    <span role="img" className="suffix-wrapper">
                        {suffixIcon}
                    </span>
                )}
            </SelectModalButton>
        </SelectModalWrapper>
    );
});

export default SelectModalView;
