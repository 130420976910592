import styled from "styled-components";
import { adaptive } from "~assets/styles/tokens/screen";
import { INDENT } from "~assets/styles/tokens/sizes";
import { CardBox } from "~atoms/box";
import { Skeleton } from "~atoms/skeleton";
import { TabGroup } from "~molecules/tab-group";
import { MyTicket } from "~organisms/my-ticket";
import { WHITE } from "~tokens/colors";

const TicketsContent = styled.div``;

const TicketAside = styled.div`
    display: flex;
    flex-direction: column;
`;

const MyTicketStyled = styled(MyTicket)`
    &:not(:last-child) {
        margin-bottom: ${INDENT.xs};
    }
`;

const TicketsWrap = styled(CardBox)``;

const ButtonWrap = styled.div`
    display: flex;
    justify-content: center;
`;

const SkeletonStyled = styled(Skeleton)`
    width: 100%;
    height: 300px;
    border-radius: 14px;
    mask-image: linear-gradient(to bottom, black 0, black 68px, transparent 68px, transparent 100%),
        radial-gradient(circle at left 78px, transparent 8px, black 9px, black 70%, transparent 0),
        radial-gradient(circle at right 78px, transparent 8px, black 9px, black 70%, transparent 0),
        linear-gradient(to bottom, transparent 0, transparent 88px, black 88px, black 100%);

    &:not(:last-child) {
        margin-bottom: ${INDENT.xs};
    }

    ${adaptive.minWidth.tablet} {
        border-radius: 16px;
        height: 240px;
        mask-image: linear-gradient(to bottom, black 0, black 56px, transparent 56px, transparent 100%),
            radial-gradient(circle at left 68px, transparent 10px, black 11px, black 65%, transparent 0),
            radial-gradient(circle at right 68px, transparent 10px, black 11px, black 65%, transparent 0),
            linear-gradient(to bottom, transparent 0, transparent 80px, black 80px, black 100%);
    }
    ${adaptive.minWidth.desktopXL} {
        height: 164px;
        mask-image: linear-gradient(to right, black 0, black 22%, transparent 22%, transparent 100%),
            radial-gradient(circle at 25% top, transparent 10px, black 11px, black 20%, transparent 0),
            radial-gradient(circle at 25% bottom, transparent 10px, black 11px, black 20%, transparent 0),
            linear-gradient(to right, transparent 0, transparent 28%, black 28%, black 100%);
    }
    ${adaptive.minWidth.desktopXXL} {
        mask-image: linear-gradient(to right, black 0, black 20%, transparent 20%, transparent 100%),
            radial-gradient(circle at 22.2% top, transparent 10px, black 11px, black 15%, transparent 0),
            radial-gradient(circle at 22.2% bottom, transparent 10px, black 11px, black 15%, transparent 0),
            linear-gradient(to right, transparent 0, transparent 25%, black 25%, black 100%);
    }
`;

const TabGroupStyled = styled(TabGroup)`
    button:not(.active-tab) {
        &:hover {
            color: ${WHITE};
        }
    }
`;

export { TicketsContent, TicketsWrap, TicketAside, MyTicketStyled, ButtonWrap, TabGroupStyled, SkeletonStyled };
