const prizeData: Record<string, unknown>[] = [
    { sizeWin: 500000, numberWin: 1 },
    { sizeWin: 25000, numberWin: 20 },
    { sizeWin: 5000, numberWin: 80 },
    { sizeWin: 1000, numberWin: 600 },
    { sizeWin: 500, numberWin: 1000 },
    { sizeWin: 250, numberWin: 10000 },
    { sizeWin: 100, numberWin: 75000 },
    { sizeWin: 50, numberWin: 300000 },
];

const jackpot = 500000;

const price = 50;

export { prizeData, jackpot, price };
