import React from "react";
import { DefaultTheme } from "styled-components";
import { Status } from "~molecules/status";
import { TPaymentStatus } from "~types/my-tickets";
import { getWithoutWinStatus } from "./helpers";

export type TWithoutWin = {
    paymentStatus: TPaymentStatus;
    registrationStatus: TPaymentStatus;
    theme: DefaultTheme;
};

const WithoutWinBlock = ({ registrationStatus, paymentStatus, theme }: TWithoutWin) => {
    const { color, text, iconColor, icon } = getWithoutWinStatus({ registrationStatus, paymentStatus, theme });
    const Icon = icon;
    return (
        <Status size="text16" weight="normal" color={color} icon={icon && <Icon />} iconColor={iconColor}>
            {text}
        </Status>
    );
};

export default WithoutWinBlock;
