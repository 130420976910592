import React, { useEffect } from "react";
import styled from "styled-components";
import { RestoreIcon } from "~atoms/icons";
import { Status } from "~molecules/status";
import { GREEN } from "~tokens/colors";
import { transformTime } from "~helpers/format";
import { useTimer } from "~hooks/useTimer";
import { getStatusTextJSX } from "./utils";

type TDrawTimerProps = {
    className?: string;
    drawDate: number;
    refreshDraw: () => void;
};

const DrawTimerWrapper = styled.div`
    display: block;
`;

const DrawTimer = ({ className, drawDate, refreshDraw }: TDrawTimerProps) => {
    const { time, isTimeOver, restart } = useTimer({ step: -1, endTime: 0, initialTime: drawDate });

    useEffect(() => {
        if (drawDate > 0) {
            restart(drawDate);
        }
    }, [drawDate, restart]);

    useEffect(() => {
        if (isTimeOver) {
            setTimeout(refreshDraw, 3000);
        }
    }, [isTimeOver, refreshDraw]);

    const transformedTime = transformTime(time);

    return (
        <DrawTimerWrapper className={className}>
            <Status icon={<RestoreIcon viewBox={"-2 0 25 25"} />} color={GREEN} size={"text16"}>
                {getStatusTextJSX(transformedTime)}
            </Status>
        </DrawTimerWrapper>
    );
};

export default DrawTimer;
