import { AxiosInstance, AxiosRequestConfig } from "axios";
import { adapters } from "~adapters";
import { ApiResponse } from "~services/http/types";
import {
    TCodeStatus,
    TLoginReqData,
    TLoginRespData,
    TLoginRespError,
    TLogoutRespData,
    TSmsSendFailedStatus,
    TSmsSendStatus,
} from "~types/auth";

const enum URLs {
    URL_VALIDATIONS = "/validations",
    URL_SSO = "/sso",
}

export const createAuthApi = (http: AxiosInstance) => ({
    async sendSms(phone: string) {
        const {
            data: { error, result },
        } = await http.post<ApiResponse<TSmsSendStatus, TSmsSendFailedStatus>>(URLs.URL_VALIDATIONS + "/sms", null, {
            params: {
                phone: adapters.auth.toSendSmsApi(phone),
            },
        });
        return { error, result: adapters.auth.fromSendSmsApi(result) };
    },
    async checkSmsCode(code: string, operationId: string) {
        const {
            data: { error, result },
        } = await http.get<ApiResponse<TCodeStatus>>(URLs.URL_VALIDATIONS + "/validate", {
            params: {
                operationId,
                code,
            },
        });
        return { error, result: adapters.auth.fromCheckSmsCodeApi(result) };
    },

    async login(loginData: TLoginReqData) {
        const { data } = await http.post<ApiResponse<TLoginRespData, TLoginRespError>>(
            URLs.URL_SSO + "/login",
            adapters.auth.toLoginApi(loginData),
            {
                notRefresh: true,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            } as AxiosRequestConfig & { notRefresh?: boolean },
        );
        return data;
    },
    async reset(resetData: TLoginReqData) {
        const { data } = await http.post<ApiResponse<TLoginRespData>>(
            URLs.URL_SSO + "/reset",
            adapters.auth.toLoginApi(resetData),
            {
                notRefresh: true,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            } as AxiosRequestConfig & { notRefresh?: boolean },
        );
        return data;
    },
    async logout() {
        const { data } = await http.post<ApiResponse<TLogoutRespData>>(
            URLs.URL_SSO + "/logout",
            adapters.auth.addClientId(),
            {
                notRefresh: true,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            } as AxiosRequestConfig & { notRefresh?: boolean },
        );
        return data;
    },
    async refresh() {
        const { data } = await http.post<ApiResponse<TLoginRespData>>(
            URLs.URL_SSO + "/refresh",
            adapters.auth.addClientId(),
            {
                notRefresh: true,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            } as AxiosRequestConfig & { notRefresh?: boolean },
        );
        return data;
    },
});
