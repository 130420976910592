import { useCallback, useEffect } from "react";
import useAppApi from "~hooks/useAppApi";
import { useFetch } from "~hooks/useFetch";
import { useMutationHandles } from "~hooks/useMutationHandles";

export const useGetTicketsPayment = () => {
    const { handleFieldChange } = useMutationHandles("myTickets");
    const api = useAppApi();

    const getTickets = useCallback(async () => {
        return await api.myTickets.getTicketsPayment();
    }, [api.myTickets]);

    const { error, loading, data } = useFetch(getTickets);

    useEffect(() => {
        if (data) {
            handleFieldChange("payment", data);
        }
    }, [data, handleFieldChange]);

    return { isLoading: loading, isError: !!error, data };
};
