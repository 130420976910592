import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TMyTicketParsed } from "~store/my-tickets/types";
import { TAlias } from "~types/products";
import { getTicketBodyData } from "./helpers";
import { InfoWrapper, RowWrapper } from "./theme";

export type TTicketBlockProps = {
    className?: string;
    produtId: string;
    name: string;
    drawNum: string;
    drawDate: string;
    alias: TAlias;
    isFutureTicket: boolean;
} & Pick<TMyTicketParsed, "ticketId" | "amount" | "buyDate">;

const TicketBody = ({
    className,
    produtId,
    ticketId,
    amount,
    buyDate,
    name,
    drawNum,
    drawDate,
    alias,
    isFutureTicket,
}: TTicketBlockProps) => {
    const { color } = useContext(ThemeContext);
    return (
        <InfoWrapper className={className}>
            {getTicketBodyData({
                ticketId,
                amount,
                buyDate,
                name,
                drawDate,
                drawNum,
                produtId,
                alias,
                isFutureTicket,
            }).map((item, idx) => {
                return (
                    <RowWrapper className="mb12" key={idx}>
                        <TextAdaptive
                            className="mb4--mobile mr4"
                            tag="span"
                            size={"text16"}
                            weight={"normal"}
                            color={color.text.tertiary}
                        >
                            {item.title}
                        </TextAdaptive>
                        <TextAdaptive tag="span" size={"text16"} weight={"normal"} color={color.text.primary}>
                            {item.value}
                        </TextAdaptive>
                    </RowWrapper>
                );
            })}
        </InfoWrapper>
    );
};

export default TicketBody;
