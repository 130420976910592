import React from "react";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { ApplePayIcon, CardIcon, WalletIcon } from "~atoms/icons";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { PaymentBlockWrapper, PaymentContent, ApplePayButton } from "./theme";
import { PaymentIcons } from "../payment-icons";

type TPaymentBlockViewProps = {
    className?: string;
    handleWalletPayment: () => void;
    handleCardPayment: () => void;
    handleApplePayPayment: () => void;
};

const PaymentBlockView = ({
    className,
    handleWalletPayment,
    handleCardPayment,
    handleApplePayPayment,
}: TPaymentBlockViewProps) => {
    return (
        <PaymentBlockWrapper className={className}>
            <TitleAdaptive tag="span" size="title24" className="mb64">
                Выберите способ оплаты
            </TitleAdaptive>
            <PaymentContent>
                <ButtonAdaptive prefixIcon={<WalletIcon />} className="mb24" intent onClick={handleWalletPayment}>
                    Кошелек
                </ButtonAdaptive>
                <ButtonAdaptive prefixIcon={<CardIcon />} className="mb8" intent onClick={handleCardPayment}>
                    Карта
                </ButtonAdaptive>
                <PaymentIcons className="mb24" />
                <ApplePayButton
                    variant="black"
                    className="button-apple-pay"
                    prefixIcon={<ApplePayIcon />}
                    onClick={handleApplePayPayment}
                />
            </PaymentContent>
        </PaymentBlockWrapper>
    );
};

export default PaymentBlockView;
