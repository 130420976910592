import React from "react";
import { TextContainerWrapper } from "~organisms/text-container/theme";

type TTextContainerProps = {
    className?: string;
    id?: string;
    content: string;
};

const TextContainer = ({ className, content, id = "" }: TTextContainerProps) => {
    return <TextContainerWrapper id={id} className={className} dangerouslySetInnerHTML={{ __html: content }} />;
};

export default TextContainer;
