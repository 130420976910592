import React from "react";
import { TTextAdaptiveSize, TTextAdaptiveTag, TWeight } from "~atoms/text-adaptive/text-adaptive";
import { TTitleAdaptiveSize, TTitleAdaptiveTag } from "~atoms/title-adaptive/title-adaptive";
import { ArrowIcon } from "../../icons";
import { IconWrapper, TextStyled, TitleStyled } from "../theme";

type TCollapseProps = {
    title?: string;
    text?: string;
    titleSize: TTitleAdaptiveSize;
    titleTag: TTitleAdaptiveTag;
    textTag: TTextAdaptiveTag;
    textSize: TTextAdaptiveSize;
    weight: TWeight;
};
const TitleDefault = ({ title, titleSize, titleTag, weight, text, textSize, textTag }: TCollapseProps) => {
    return (
        <>
            {title && (
                <TitleStyled size={titleSize} weight={weight} tag={titleTag}>
                    {title}
                </TitleStyled>
            )}
            {text && (
                <TextStyled size={textSize} weight={weight} tag={textTag}>
                    {text}
                </TextStyled>
            )}
            <IconWrapper>
                <ArrowIcon />
            </IconWrapper>
        </>
    );
};

export default TitleDefault;
