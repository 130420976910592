import styled from "styled-components";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";

export const WinnerBlockWrapper = styled.div<{ background?: string }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({ background, theme }) => (background ? background : theme.color.background.primaryElevated)};
    border: 1px solid ${({ theme }) => theme.color.background.stroke};
    height: 96px;
    border-radius: 12px;
    padding: ${INDENT.xs};
    width: 100%;
    ${adaptive.maxWidth.desktopXXL} {
        padding: ${INDENT.xxs};
    }
    ${adaptive.maxWidth.tablet} {
        height: 64px;
        padding: ${INDENT.xxxs};
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const IconWrapper = styled.div`
    &.avatar-icon {
        overflow: hidden;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        ${adaptive.maxWidth.desktopXXL} {
            width: 48px;
            height: 48px;
        }
    }

    &.lottery-info-icon {
        width: 44px;
        height: 44px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        ${adaptive.maxWidth.desktopXXL} {
            display: none;
        }
    }

    &.lottery-info-icon-small {
        display: none;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        ${adaptive.maxWidth.desktopXXL} {
            display: block;
        }
    }
`;

export const TextWrapper = styled.div`
    &.lottery-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        ${adaptive.maxWidth.desktopXXL} {
            align-items: flex-end;
        }
    }
    & > p,
    & > span {
        white-space: nowrap;
    }
`;

export const LotteryInfoWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const LotteryContentWrapper = styled.div`
    display: flex;
    align-items: center;
`;
