import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Grid } from "~atoms/grid";
import { LotteryRulesHeaderWrapper, SkeletonStyled, TextStyled, TextWrapper, TitleStyled } from "./theme";

type TLotteryRulesHeaderProps = {
    className?: string;
    name?: string;
    type: string;
    prizeFund: string;
    ticketPrice: number | null;
    mainPrize?: string;
    frequency: string;
};

const { Row, Col } = Grid;

const LotteryRulesHeader = ({ className, type, prizeFund, ticketPrice }: TLotteryRulesHeaderProps) => {
    const { color } = useContext(ThemeContext);
    return (
        <LotteryRulesHeaderWrapper className={className}>
            <Grid>
                <Row>
                    <Col col={3} tablet={6}>
                        <TextWrapper>
                            <TitleStyled color={color.text.tertiary} tag={"h3"} size="title16" weight="normal">
                                Тип лотереи
                            </TitleStyled>
                            <TextStyled weight={"medium"} tag={"span"} size="text20" color={color.text.headline}>
                                {type}
                            </TextStyled>
                        </TextWrapper>
                    </Col>
                    <Col col={3} tablet={6}>
                        <TextWrapper>
                            <TitleStyled color={color.text.tertiary} tag={"h3"} weight="normal">
                                Призовой фонд
                            </TitleStyled>
                            <TextStyled weight={"medium"} size="text20" tag={"span"} color={color.text.headline}>
                                {prizeFund}
                            </TextStyled>
                        </TextWrapper>
                    </Col>
                    <Col col={3} tablet={6}>
                        <TextWrapper>
                            <TitleStyled color={color.text.tertiary} tag={"h3"} weight="normal">
                                Цена билета
                            </TitleStyled>
                            {!!ticketPrice ? (
                                <TextStyled weight={"medium"} size="text20" tag={"span"} color={color.text.headline}>
                                    {ticketPrice} ₽
                                </TextStyled>
                            ) : (
                                <SkeletonStyled />
                            )}
                        </TextWrapper>
                    </Col>
                    {/*<Col col={3} tablet={6}>*/}
                    {/*    <TextWrapper>*/}
                    {/*        <TitleStyled color={color.text.secondary} tag={"h3"}>*/}
                    {/*            Частота тиражей*/}
                    {/*        </TitleStyled>*/}
                    {/*        <TextStyled weight={"demiBold"} tag={"span"} color={color.text.headline}>*/}
                    {/*            {frequency}*/}
                    {/*        </TextStyled>*/}
                    {/*    </TextWrapper>*/}
                    {/*</Col>*/}
                </Row>
            </Grid>
        </LotteryRulesHeaderWrapper>
    );
};

export default LotteryRulesHeader;
