import React from "react";
import styled from "styled-components";
import { colWidth } from "./styles";
import { OFFSET } from "../settings";

type TColProps = {
    children?: React.ReactNode;
    style?: Record<string, string>;
    className?: string;
} & TColStyle;

export type TColStyle = {
    col: number;
    mobile?: number;
    tablet?: number;
    desktopXL?: number;
};

const ColWrapper = styled.div<TColStyle>`
    margin: 0 ${OFFSET / 2}px;
    ${colWidth}
`;

export type TColComponent = React.FC<TColProps>;

const Col: TColComponent = ({ children, col, mobile, tablet, desktopXL, style, className }: TColProps) => {
    return (
        <ColWrapper col={col} tablet={tablet} mobile={mobile} desktopXL={desktopXL} style={style} className={className}>
            {children}
        </ColWrapper>
    );
};

export default Col;
