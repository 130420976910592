import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setTokens } from "~services/auth-tokens";
import { actionAuth } from "~store/user/actions";

export function useAppAuth() {
    const dispatch = useDispatch();
    const setUserAuth = useCallback(
        (accessToken: string) => {
            setTokens(accessToken);
            dispatch(actionAuth(true));
        },
        [dispatch],
    );

    return { setUserAuth };
}
