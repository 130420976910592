import React from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { HOME } from "~constants/routes";
// import { useGetProducts } from "~organisms/lottery-info/_hooks/useGetProducts";
// import { LotteryTicket } from "~organisms/lottery-ticket";
// import { TSizeTicket } from "~organisms/lottery-ticket/lottery-ticket";
// import { useAppScreen } from "~hooks/useAppScreen";
// import { useAppSelector } from "~hooks/useAppSelector";
import { EmptyStateLotteryWrapper, TextWrap, LinkStyled } from "./theme";

type TEmptyStateLotteryProps = {
    title: string;
    className?: string;
};

// TODO Убрать неиспользуемые styled-components в теме

const EmptyStateLottery = ({ className, title }: TEmptyStateLotteryProps) => {
    // const { handleGetProducts } = useGetProducts();
    // const typeScreen = useAppScreen();
    // const lotteries = useAppSelector((state) => state.lotteries.data);

    // useEffect(() => {
    //     if (!lotteries.length) void handleGetProducts();
    // }, [handleGetProducts, lotteries.length]);

    return (
        <EmptyStateLotteryWrapper className={className}>
            <TextWrap>
                <TitleAdaptive className="mb8" tag="h2" size="title24">
                    {title}
                </TitleAdaptive>
                <TextAdaptive tag="span" size="text16">
                    Билетов нет. Выберите <LinkStyled to={HOME}>лотерею</LinkStyled>, чтобы приобрести билет.
                </TextAdaptive>
            </TextWrap>
            {/* <LotteriesWrap>
                {!!lotteries.length
                    ? lotteries.map((item, idx) => {
                          const meta = item[typeScreen];
                          const { id, title, prize, note, edition, time, amount } = item;
                          return (
                              <LotteryTicket
                                  id={id}
                                  logoSrc={item.logoSrc}
                                  className="mt32 mt16--mobile"
                                  type={meta.type as TSizeTicket}
                                  backgroundUrl={meta.url as TSizeTicket}
                                  title={title}
                                  prize={prize}
                                  note={note}
                                  edition={edition}
                                  time={time}
                                  amount={amount}
                                  key={idx}
                              />
                          );
                      })
                    : Array(4)
                          .fill(1)
                          .map((_, idx) => <SkeletonStyled key={idx} className="mt32 mt16--mobile" />)}
            </LotteriesWrap> */}
        </EmptyStateLotteryWrapper>
    );
};

export default EmptyStateLottery;
