import React from "react";
import { CardStyled } from "./theme";

export type TCardR = 1 | 2;
export type TCardP = 1 | 2 | 3;

type TCardProps = {
    className?: string;
    p?: TCardP;
    r?: TCardR;
    adaptive?: boolean;
    children: React.ReactNode;
};

const Card = ({ children, p = 1, r = 1, adaptive = true, className }: TCardProps) => {
    return (
        <CardStyled p={p} r={r} adaptive={adaptive} className={className}>
            {children}
        </CardStyled>
    );
};

export default Card;
