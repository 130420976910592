import React from "react";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { LegalInfo } from "~pages/lottery/common/legal-info";
import { legalText } from "~constants/handbook";
import { ticketsBingoType } from "~constants/tickets-type";
import { useAppSelector } from "~hooks/useAppSelector";
import { ContentWrapper, RulesWrapper } from "./theme";
import { BingoRulesHeader } from "../../common/bingo-rules-header";

type TRulesProps = {
    isActive: boolean;
};

const bingo3x3 = {
    type: "Бинго",
    prizeFund: "65%",
    frequency: "Ежедневно, каждые 15 мин.",
};

const Rules = ({ isActive }: TRulesProps) => {
    // const jackpot = useAppSelector((state) => state.tickets[ticketsBingoType.b3x3].jackpot);
    const amount = useAppSelector((state) => state.tickets[ticketsBingoType.b3x3].data[0]?.amount);
    return (
        <RulesWrapper>
            <ContentWrapper>
                {/*{jackpot !== null && amount !== null ? (*/}
                {/*    <BannerRulesBingo3x3 jackpot={jackpot} amount={amount} className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*) : (*/}
                {/*    <SkeletonStyled className="mb64 mb48--tablet mb32--mobile" />*/}
                {/*)}*/}
                {isActive && (
                    <BingoRulesHeader
                        className="mb32 mb24--tablet"
                        type={bingo3x3.type}
                        prizeFund={bingo3x3.prizeFund}
                        ticketPrice={amount}
                        frequency={bingo3x3.frequency}
                    />
                )}
                <TitleAdaptive className="mb24 mb16--mobile" tag="h3" size="title38" weight="medium">
                    Как играть?
                </TitleAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    Формирование лотерейной комбинации осуществляется на игровом поле. Игровое поле состоит из 9 ячеек
                    (3 строки и 3 столбца), содержащих 9 неповторяющихся чисел в диапазоне от 1 до 30 включительно.
                    Выигрышная комбинация определяется путем формирования случайной последовательности из
                    неповторяющихся чисел в диапазоне от 1 до 30 включительно в ходе розыгрыша призового фонда в
                    соответствующем тираже.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    Билет считается выигрышным, если размещенные в игровом поле числа частично или полностью совпали с
                    числами, выпавшими в ходе розыгрыша призового фонда в соответствующем тираже тиража, и при этом
                    составили выигрышную лотерейную комбинацию по результатам розыгрыша.
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16" weight="medium">
                    Выигрышными лотерейными комбинациями являются:
                </TextAdaptive>
                <TextAdaptive className="mb24 mb16--mobile" size="text16">
                    <TextAdaptive tag="span" className="mb24 mb16--mobile" size="text16" weight="medium">
                        &quot;Линия&quot; -
                    </TextAdaptive>{" "}
                    3 числа в игровом поле полностью совпали с числами, которые были определены до определенного хода в
                    процессе розыгрыша, и составили линию;
                </TextAdaptive>
                <TextAdaptive className="mb64 mb48--tablet" size="text16">
                    <TextAdaptive tag="span" className="mb64 mb48--tablet" size="text16" weight="medium">
                        &quot;Поле&quot; -
                    </TextAdaptive>{" "}
                    все 9 чисел в пределах игрового поля совпали с числами, которые были определены до определенного
                    хода в процессе розыгрыша.
                </TextAdaptive>
                <LegalInfo
                    type="BINGO"
                    prizeInfo={legalText["bingo-3x3"].prizeInfo}
                    algoInfo={legalText["bingo-3x3"].algoInfo}
                />
            </ContentWrapper>
        </RulesWrapper>
    );
};

export default Rules;
