import React from "react";
import { Link } from "react-router-dom";
import { Grid } from "~atoms/grid";
import { Col } from "~atoms/grid/col";
import { Row } from "~atoms/grid/row";
import { HistoryCard } from "~organisms/history-card";
import emblemMomentary1 from "~organisms/our-winners-area/assets/momentary1.png";
import { ContentWrapper, HistoryBlockWrapper, HistoryCardPlaceholder } from "~organisms/our-winners-area/theme";
import { getWinnersHistory, WINNERS_1 } from "~constants/routes";

type THistoryBlockProps = {
    className?: string;
};

type TSliderItem = {
    id: number;
    background: string;
    comment: string;
    title: string;
    prize: number;
    lottery: string;
    name: string;
    src: string;
    url: string;
};

const stories: TSliderItem[] = [
    {
        id: 1,
        background: "linear-gradient(90deg, #7ED0F4 0%, #7CABFB 100%)",
        comment: "Оплатили ТО автомобиля",
        title: "История 1",
        prize: 25000,
        lottery: "Летим к мечте",
        name: "Нина и Дмитрий Майоровы",
        src: emblemMomentary1,
        url: WINNERS_1,
    },
    // {
    //     id: 2,
    //     background: "linear-gradient(90deg, #974AAE 0%, #5B2B94 100%)",
    //     comment: "Я собираюсь в путешествие!",
    //     title: "История 2",
    //     prize: 120000,
    //     lottery: "5 скорость",
    //     name: "Андрей Колесников",
    //     src: emblem5in36,
    //     url: WINNERS_2,
    // },
    // {
    //     id: 3,
    //     background: "linear-gradient(90deg, #E54EA4 0%, #7A388D 100%)",
    //     comment: "Потрачу выигрыш на учебу",
    //     title: "История 3",
    //     prize: 80000,
    //     lottery: "Супер 8",
    //     name: "Анна Морозова",
    //     src: emblem8in20,
    //     url: WINNERS_3,
    // },
];

// const breakpointsSettings = {
//     [breakpoints.desktopXXL]: {
//         slidesPerView: 3,
//         spaceBetween: 24,
//     },
//     [breakpoints.tablet]: {
//         slidesPerView: 3,
//         spaceBetween: 24,
//     },
// };

const HistoryBlock = ({ className }: THistoryBlockProps) => {
    // const renderSlide = useCallback((item) => {
    //     return (
    //         <Link to={getWinnersHistory(item.id)} key={item.id}>
    //             <HistoryCard
    //                 background={item.background}
    //                 comment={item.comment}
    //                 prize={item.prize}
    //                 lottery={item.lottery}
    //                 name={item.name}
    //                 emblem={item.src}
    //             />
    //         </Link>
    //     );
    // }, []);

    return (
        <HistoryBlockWrapper className={className}>
            <ContentWrapper>
                {/*<SliderSwiper*/}
                {/*    slidesPerView={1.4}*/}
                {/*    items={stories}*/}
                {/*    breakpoints={breakpointsSettings}*/}
                {/*    renderSlide={renderSlide}*/}
                {/*    spaceBetween={24}*/}
                {/*/>*/}
                <Grid className={"history-block-grid"}>
                    <Row>
                        <Col col={4} mobile={6}>
                            <Link to={getWinnersHistory(1)}>
                                <HistoryCard
                                    background={stories[0].background}
                                    comment={stories[0].comment}
                                    prize={stories[0].prize}
                                    lottery={stories[0].lottery}
                                    name={stories[0].name}
                                    emblem={stories[0].src}
                                />
                            </Link>
                        </Col>
                        <Col col={8} mobile={6}>
                            <HistoryCardPlaceholder />
                        </Col>
                    </Row>
                </Grid>
            </ContentWrapper>
        </HistoryBlockWrapper>
    );
};

export default HistoryBlock;
