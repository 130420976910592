import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { actionAddNotification, actionRemoveNotification } from "~store/notification/actions";
import { TMessageTypes } from "~store/notification/types";

type TNotificationsMsg = {
    id?: number;
    message: string;
    type?: TMessageTypes;
};
let _id = 0;
export function useNotifications() {
    const dispatch = useDispatch();

    const add = useCallback(
        ({ id = ++_id, message, type = "error" }: TNotificationsMsg, ms = 5000) => {
            dispatch(actionAddNotification({ id, message, type }));
            setTimeout(() => dispatch(actionRemoveNotification(id)), ms);
        },
        [dispatch],
    );

    const remove = useCallback(
        (idMsg: number) => {
            dispatch(actionRemoveNotification(idMsg));
        },
        [dispatch],
    );

    return { add, remove };
}
