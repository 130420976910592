import React from "react";
import { docs } from "~assets/docs";
import { PDFIcon } from "~atoms/icons";
import { Link } from "~atoms/link";
import { TextAdaptive } from "~atoms/text-adaptive";
import { FilesBlockStyled } from "~pages/lottery/common/legal-info/theme";
import { getPhoneHelpFormat } from "~helpers/format";
import { minFinPhone, minFinSite, nationalLotteryPhone } from "~constants/links";

const files = [
    {
        title: "Скачать форму согласия участника лотереи на обработку персональных данных",
        link: docs.agreement.link,
        col: 6,
        desktopXL: 6,
        tablet: 8,
        mobile: 12,
    },
    {
        title: "Скачать заявление на выплату выигрыша",
        link: docs.statement.link,
        col: 6,
        desktopXL: 6,
        tablet: 8,
        mobile: 12,
    },
];

export const data = [
    {
        id: "lottery-question-1",
        title: "Кто является организатором лотереи?",
        content: (
            <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"ml24"}>
                В соответствии с распоряжением Правительства Российской Федерации от 29 августа 2019 г. N 1921-р
                организатором всероссийских государственных лотерей в поддержку развития бокса является Министерство
                финансов Российской Федерации, а оператором — ООО «Спортивные Лотереи».
            </TextAdaptive>
        ),
        col: 8,
        desktopXL: 10,
        tablet: 12,
        mobile: 12,
    },
    {
        id: "lottery-question-2",
        title: "Как узнать полную информацию об организаторе лотереи, наименование, адреса, телефоны и т.п.?",
        content: (
            <div className={"ml24"}>
                <TextAdaptive size={"text16"} tag={"p"} weight={"demiBold"} className={"mb16"}>
                    Организатором лотереи является Министерство финансов Российской Федерации.
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"demiBold"} className={"mb16"}>
                    Место нахождения Организатора:{" "}
                    <TextAdaptive tag={"span"} size={"text16"} weight={"medium"}>
                        Россия, 109097, г. Москва, ул. Ильинка, д.9.{" "}
                    </TextAdaptive>
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"demiBold"} className={"mb16"}>
                    Телефон:{" "}
                    <Link href={minFinPhone}>
                        <TextAdaptive tag={"span"} size={"text16"} weight={"medium"}>
                            +7 495 987-91-01
                        </TextAdaptive>
                    </Link>
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"demiBold"} className={"mb32"}>
                    Сайт:{" "}
                    <Link href={minFinSite}>
                        {" "}
                        <TextAdaptive tag={"span"} size={"text16"} weight={"medium"}>
                            minfin.ru
                        </TextAdaptive>
                    </Link>
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"demiBold"} className={"mb16"}>
                    Оператор лотереи:{" "}
                    <TextAdaptive size={"text16"} tag={"span"} weight={"medium"}>
                        ООО «Спортивные Лотереи»
                    </TextAdaptive>
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"demiBold"} className={"mb16"}>
                    <b>Тел:</b>{" "}
                    <Link href={`tel:${nationalLotteryPhone}`}>
                        {" "}
                        <TextAdaptive tag={"span"} size={"text16"} weight={"medium"}>
                            {getPhoneHelpFormat(nationalLotteryPhone)}
                        </TextAdaptive>
                    </Link>
                </TextAdaptive>
            </div>
        ),
        col: 8,
        desktopXL: 10,
        tablet: 12,
        mobile: 12,
    },
    {
        id: "lottery-question-3",
        title: "Как и чем законодательно регулируется Ваша деятельность?",
        content: (
            <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"ml24"}>
                Деятельность ООО «Спортивные Лотереи» осуществляется в соответствии с действующим законодательством
                Российской Федерации и регулируется Федеральным законом от 11 ноября 2003 г. № 138-ФЗ «О лотереях» от
                11.11.2003 № 138-ФЗ.
            </TextAdaptive>
        ),
        col: 8,
        desktopXL: 10,
        tablet: 12,
        mobile: 12,
    },
    {
        id: "lottery-question-4",
        title: "В каких целях проводится Национальная лотерея?",
        content: (
            <div className={"ml24"}>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    Государственные лотереи России проводятся в целях финансирования социально значимых объектов и
                    мероприятий, в том числе мероприятий по развитию физической культуры и спорта, спорта высших
                    достижений и системы подготовки спортивного резерва.
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    Целью проведения Национальной лотереи является поддержка развития бокса.
                </TextAdaptive>
            </div>
        ),
        col: 8,
        desktopXL: 10,
        tablet: 12,
        mobile: 12,
    },
    {
        id: "lottery-question-5",
        title: "Кто может принять участие в лотереях?",
        content: (
            <div className={"ml24"}>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    Участником лотереи может быть лицо, достигшее возраста 18 лет.
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    Участие в лотерее регулируется гражданским кодексом Российской Федерации и Федеральным законом от 11
                    ноября 2003 г. № 138-ФЗ «О лотереях».
                </TextAdaptive>
            </div>
        ),
        col: 8,
        desktopXL: 10,
        tablet: 12,
        mobile: 12,
    },
    {
        id: "lottery-question-6",
        title: "Какие бывают лотереи?",
        content: (
            <div className={"ml24"}>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    Лотереи в зависимости от способа ее проведения бывают тиражные и бестиражные.
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    Тиражной называется лотерея, в которой розыгрыш призового фонда между всеми участниками проводится
                    единовременно после распространения лотерейных билетов.
                </TextAdaptive>
            </div>
        ),
        col: 8,
        desktopXL: 10,
        tablet: 12,
        mobile: 12,
    },
    {
        id: "lottery-question-7",
        title: "При выигрыше я получаю всю сумму полностью или с вычетом налогов?",
        content: (
            <div className={"ml24"}>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    В соответствии с Налоговым кодексом выигрыш в лотерею считается доходом. Для налоговых резидентов
                    ставка налога составляет 13% от суммы выигрыша. Для нерезидентов — 30% от суммы выигрыша.
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    При выигрыше до 15 000 рублей участник лотереи самостоятельно платит налог. Для этого в налоговую
                    инспекцию по месту своего жительства необходимо заявить о выигрыше, то есть подать налоговую
                    декларацию по форме 3-НДФЛ. Указанную декларацию нужно подать до 30 апреля того года, который
                    следует за годом получения выигрыша.
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"demiBold"} className={"mb16"}>
                    В декларации следует указать:
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"}>
                    — свои персональные данные (ФИО, ИНН, адрес регистрации и т.п.);
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"}>
                    — размер полученного выигрыша;
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    — расчет и сумму НДФЛ, который подлежит уплате государству.
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    Налоговые отчисления выплачиваются не позднее 15 июля года, следующего за годом получения выигрыша.
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    При выигрыше равном или превышающем 15 000 рублей налог удерживается и перечисляется в бюджет лицом,
                    выплачивающим выигрыш.
                </TextAdaptive>
            </div>
        ),
        col: 8,
        desktopXL: 10,
        tablet: 12,
        mobile: 12,
    },
    {
        id: "lottery-question-8",
        title: "Могу ли я сохранить анонимность, получив крупный приз?",
        content: (
            <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"ml24"}>
                Да. Любые данные о победителе публикуются только с его согласия.
            </TextAdaptive>
        ),
        col: 8,
        desktopXL: 10,
        tablet: 12,
        mobile: 12,
    },
    {
        id: "lottery-question-9",
        title: "Как получить выигрыш?",
        content: (
            <div className={"ml24"}>
                <FilesBlockStyled
                    files={files}
                    margin="mb16--mobile"
                    prefixIcon={<PDFIcon />}
                    className={"mb32 mb24--mobile"}
                />
                <TextAdaptive size={"text16"} tag={"p"} weight={"demiBold"} className={"mb16"}>
                    Выигрыши по лотереям, приобретенным в «Россельхозбанке»
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"}>
                    Выплата выигрыша в лотерею в сумме до 1 000 рублей (включительно) осуществляется в месте покупки
                    билета.
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    Выплата выигрыша в лотерею в сумме свыше 1 000 рублей выплачивается банковским переводом в срок не
                    более 30 дней после предъявления выигрышного билета.
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    Для получения выигрыша свыше 1 000 рублей вышлите в заказном письме c описью вложения:
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"}>
                    — оригинал выигрышного билета (билетов),
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"}>
                    — ксерокопию гражданского паспорта (разворот 2 и 3 страниц и страницу с регистрацией),
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"}>
                    — заполненное <Link href={docs.statement.link}>заявление на выплату выигрыша</Link>, включающее в
                    том числе ИНН заявителя (при наличии), полные банковские реквизиты для перевода выигрыша
                    (наименование банка и его местонахождение, БИК, ИНН банка, р/сч., корр./сч.), контактный телефон и
                    электронный адрес получателя,
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    — <Link href={docs.agreement.link}>согласие на обработку персональных данных.</Link>
                </TextAdaptive>
                <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                    Адрес: 115114 г. Москва АЯ 1БЦ-1 ООО «Спортивные Лотереи».
                </TextAdaptive>
                <div>
                    <TextAdaptive size={"text16"} tag={"p"} weight={"demiBold"} className={"mb16"}>
                        Выигрыши по лотереям, приобретенным в «Почте России»
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} tag={"p"} weight={"medium"}>
                        Выплата выигрыша в лотерею в сумме до 15 000 рублей осуществляется в месте покупки билета.
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                        Выплата выигрыша в лотерею в сумме равной или превышающей 15 000 рублей выплачивается банковским
                        переводом в срок не более 30 дней после предъявления выигрышного билета.
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                        Выплата выигрыша в лотерею в сумме равной или превышающей 15 000 рублей выплачивается банковским
                        переводом в срок не более 30 дней после предъявления выигрышного билета.
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                        Для получения выигрыша в сумме равной или превышающей 15 000 рублей вышлите в заказном письме c
                        описью вложения:
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} tag={"p"} weight={"medium"}>
                        — оригинал выигрышного билета (билетов),
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} tag={"p"} weight={"medium"}>
                        — ксерокопию гражданского паспорта (разворот 2 и 3 страниц и страницу с регистрацией),
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} tag={"p"} weight={"medium"}>
                        — заполненное <Link href={docs.statement.link}>заявление на выплату выигрыша</Link>, включающее
                        в том числе ИНН заявителя (при наличии), полные банковские реквизиты для перевода выигрыша
                        (наименование банка и его местонахождение, БИК, ИНН банка, р/сч., корр./сч.), контактный телефон
                        и электронный адрес получателя,
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                        — <Link href={docs.agreement.link}>согласие на обработку персональных данных.</Link>
                    </TextAdaptive>
                    <TextAdaptive size={"text16"} tag={"p"} weight={"medium"} className={"mb16"}>
                        Адрес: 115114 г. Москва АЯ 1БЦ-1 ООО «Спортивные Лотереи».
                    </TextAdaptive>
                </div>
            </div>
        ),
        col: 8,
        desktopXL: 10,
        tablet: 12,
        mobile: 12,
    },
];
