import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Breadcrumbs } from "~atoms/breadcrumbs";
import { BuyTicketsCardBingo } from "~organisms/buy-tickets-card";
import { LotteryDataBingo } from "~organisms/lottery-data";
import LotteryTicketsBar from "~organisms/lottery-tickets-bar/lottery-tickets-bar";
import { Page } from "~templates/page";
import { useResetTickets } from "~pages/lottery/_hooks/useResetTickets";
import { useInitTickets } from "~pages/lottery/bingo-lottery/_hooks/useInitTickets";
import { ticketOptions } from "~pages/lottery/bingo-lottery/common/helpers";
import { LogoStyled } from "~pages/lottery/digital-lottery/lottery-5in50/theme";
import { LinkBackStyled } from "~pages/lottery/momentary-lottery/common/theme";
import {
    bingoAlias,
    LOTTERY,
    LOTTERY_BINGO_3X3,
    LOTTERY_BINGO_3X3_HISTORY,
    LOTTERY_BINGO_3X3_HISTORY_DETAILS,
    LOTTERY_BINGO_3X3_RULES,
} from "~constants/routes";
import { ticketsBingoType } from "~constants/tickets-type";
import useAppHistory from "~hooks/useAppHistory";
import { useAppScreen } from "~hooks/useAppScreen";
import { useAppSelector } from "~hooks/useAppSelector";
import { useElementOnScreen } from "~hooks/useElementOnScreen";
import { crumbs, tabs } from "./helpers";
import History from "./history/history";
import Rules from "./rules/rules";
import {
    BingoContent,
    BuyTicketsBarStyled,
    BuyTicketsCardWrapper,
    HeaderBlock,
    HeaderContentWrapper,
    LogoWrapper,
    TabGroupStyled,
    TicketsAside,
} from "./theme";
import headImg from "../../assets/bingo/3x3/head.png";
import { HistoryDetails } from "../../common/history-details";
import { LogoSkeletonStyled } from "../../common/legal-info/theme";
import { TicketsPage } from "../common/tickets-page";

const observerOptions = {
    root: null,
    rootMargin: "50px 0px 50px 0px",
    threshold: 0.8,
};

const col = { mainContent: { desktopXL: 8 }, asideContent: { desktopXL: 4 } };

const Bingo3x3 = () => {
    const typeScreen = useAppScreen();
    const isActive = useAppSelector(
        (state) => state.lotteries.data.find((item) => item.id === bingoAlias.b3x3)?.active ?? true,
    );
    const { data, isLotteriesLoaded } = useAppSelector((state) => state.lotteries);
    const [isCardVisible, cardRef] = useElementOnScreen<HTMLDivElement>(observerOptions);
    const logoSrc = useAppSelector((state) => state.tickets[ticketsBingoType.b3x3].logoSrc);
    const productId = useAppSelector((state) => state.tickets[ticketsBingoType.b3x3].productId);
    const init = useAppSelector((state) => state.tickets[ticketsBingoType.b3x3].initTicket);
    const name = useAppSelector((state) => state.tickets[ticketsBingoType.b3x3].name);

    const { initTickets } = useInitTickets({
        alias: ticketOptions[ticketsBingoType.b3x3].alias,
        ticketType: ticketsBingoType.b3x3,
        size:
            typeScreen === "mobile"
                ? ticketOptions[ticketsBingoType.b3x3].sizeMobile
                : ticketOptions[ticketsBingoType.b3x3].size,
    });
    const { resetTickets } = useResetTickets();
    const { color } = useContext(ThemeContext);
    const { go } = useAppHistory();
    const location = useLocation();

    const directionOption = "horizontal";
    const isHistoryDetailsPath = location.pathname.indexOf(productId!) > 0;
    const isCardShow =
        (location.pathname === LOTTERY_BINGO_3X3 || typeScreen.startsWith("desktop")) && !isHistoryDetailsPath;
    const activePath = location.pathname.startsWith(LOTTERY_BINGO_3X3_HISTORY)
        ? LOTTERY_BINGO_3X3_HISTORY
        : location.pathname;

    const handleClick = useCallback(
        (url) => {
            go(url);
        },
        [go],
    );

    useEffect(() => {
        if (!init) {
            void initTickets();
        }
    }, [init, initTickets]);

    useEffect(() => resetTickets, [resetTickets]);

    const tabsData = useMemo(
        () => (isActive ? tabs : tabs.filter((item) => item.id !== LOTTERY_BINGO_3X3)),
        [isActive],
    );

    const mainContent = (
        <BingoContent className="mb48--tablet mb32--mobile">
            <Switch>
                {isActive && (
                    <Route
                        path={LOTTERY_BINGO_3X3}
                        render={() => <TicketsPage ticketType={ticketsBingoType.b3x3} />}
                        exact
                    />
                )}

                <Route path={LOTTERY_BINGO_3X3_RULES} render={() => <Rules isActive={isActive} />} exact />
                <Route path={LOTTERY_BINGO_3X3_HISTORY} component={History} exact />
                <Route path={LOTTERY_BINGO_3X3_HISTORY_DETAILS} component={HistoryDetails} exact />
                {isActive ? <Redirect to={LOTTERY_BINGO_3X3} /> : <Redirect to={LOTTERY_BINGO_3X3_RULES} />}
            </Switch>
            {typeScreen.startsWith("desktop") && (
                <LotteryTicketsBar
                    className="mt32"
                    title="Другие лотереи"
                    direction={directionOption}
                    lotteryType={"bingo-3x3"}
                />
            )}
        </BingoContent>
    );

    const asideContent = (
        <TicketsAside className="mb64 mb32--tablet mb0--mobile">
            {isActive && isLotteriesLoaded && (
                <BuyTicketsCardWrapper ref={cardRef} className="mb32" show={isCardShow}>
                    <BuyTicketsCardBingo className="buy-ticket-card" ticketType={ticketsBingoType.b3x3} />
                </BuyTicketsCardWrapper>
            )}

            {!typeScreen.startsWith("desktop") && (
                <LotteryTicketsBar title={"Другие лотереи"} direction={directionOption} lotteryType={"bingo-3x3"} />
            )}
            {isActive && (
                <BuyTicketsBarStyled
                    ticketType={ticketsBingoType.b3x3}
                    show={!isCardVisible && !isHistoryDetailsPath}
                />
            )}
        </TicketsAside>
    );

    const headerBlock = useMemo(
        () => (
            <HeaderBlock>
                {typeScreen === "mobile" ? (
                    <LinkBackStyled
                        href={isHistoryDetailsPath ? LOTTERY_BINGO_3X3_HISTORY : LOTTERY}
                        className="mb32"
                    />
                ) : (
                    <Breadcrumbs data={crumbs(location.pathname, productId!, name)} className="mb24 mb16--tablet" />
                )}
                {isActive ? (
                    <HeaderContentWrapper className="mb48--tablet">
                        <LogoWrapper className="mb32 mb24--tablet">
                            {logoSrc ? <LogoStyled src={logoSrc} alt="Лотерея bingo3x3" /> : <LogoSkeletonStyled />}
                        </LogoWrapper>
                        {data.length ? <LotteryDataBingo ticketType={ticketsBingoType.b3x3} /> : null}
                    </HeaderContentWrapper>
                ) : (
                    <LogoWrapper className="mb32 mb24--tablet">
                        {logoSrc ? <LogoStyled src={logoSrc} alt="Лотерея bingo3x3" /> : <LogoSkeletonStyled />}
                    </LogoWrapper>
                )}

                <TabGroupStyled
                    active={activePath}
                    onClick={handleClick}
                    tabs={tabsData}
                    role={"link"}
                    color={color.text.inverted}
                    size={3}
                    loading={!isLotteriesLoaded}
                />
            </HeaderBlock>
        ),
        [
            typeScreen,
            isHistoryDetailsPath,
            location.pathname,
            productId,
            name,
            isActive,
            logoSrc,
            data.length,
            activePath,
            handleClick,
            tabsData,
            isLotteriesLoaded,
            color.text.inverted,
        ],
    );

    return (
        <Page
            mainContent={mainContent}
            asideContent={asideContent}
            col={col}
            headerBlock={headerBlock}
            headerWithImg
            headerImg={headImg}
        />
    );
};

export default Bingo3x3;
