import styled from "styled-components";
import { ButtonAdaptive } from "~atoms/button-adaptive";
import { Box } from "~atoms/flex-box";
import { Text } from "~atoms/text";
import { Title } from "~atoms/title";
import { adaptive } from "~tokens/screen";
import { INDENT } from "~tokens/sizes";
import { fontSize12, fontSize14, fontSize16, fontSize18, fontSize20 } from "~tokens/typography";

export const FieldNumbersWrapper = styled(Box)``;

export const FieldNumbersBody = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -${INDENT.x6};
    ${adaptive.maxWidth.desktopXXL} {
        margin: -${INDENT.xxxxs};
    }
    ${adaptive.maxWidth.desktopXL} {
        margin: -${INDENT.xxxxxs};
    }
`;

export const FieldNumbersHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const TitleText = styled(Title)`
    ${adaptive.maxWidth.desktopXXL} {
        ${fontSize16};
    }
    ${adaptive.maxWidth.desktopXL} {
        ${fontSize20};
    }
    ${adaptive.maxWidth.tablet} {
        ${fontSize18};
    }
`;

export const CountText = styled(Text)`
    ${adaptive.maxWidth.desktopXXL} {
        ${fontSize12};
    }
    ${adaptive.maxWidth.desktopXL} {
        ${fontSize14};
    }
    ${adaptive.maxWidth.tablet} {
        ${fontSize12};
    }
`;

export const BoxNumbers = styled.div<{ isActive: boolean }>`
    width: 54px;
    height: 54px;
    border-radius: 8px;
    margin: ${INDENT.x6};

    background: ${({ isActive, theme }) =>
        isActive ? theme.color.const.plum.normal : theme.color.const.gsSolid["100"]};

    button,
    button:hover {
        color: ${({ isActive, theme }) =>
            isActive ? theme.color.const.raspberry.lightest : theme.color.const.gsSolid["600"]};
    }

    ${adaptive.maxWidth.desktopXXL} {
        width: 32px;
        height: 32px;
        & span {
            transform: scale(0.8);
        }
        margin: ${INDENT.xxxxs};
    }

    ${adaptive.maxWidth.desktopXL} {
        width: 44px;
        height: 44px;
        & span {
            transform: scale(1);
        }
        margin: ${INDENT.xxxxxs};
    }

    ${adaptive.maxWidth.tablet} {
        width: 42px;
        height: 42px;
    }
    ${adaptive.maxWidth.mobileSmall} {
        width: 36px;
        height: 36px;
    }
`;

export const ButtonNumber = styled(ButtonAdaptive)`
    padding: 0;
    width: 100%;
    height: 100%;
`;
