import React, { ReactNode, useContext } from "react";
import { ThemeContext } from "styled-components";
import { BackgroundHead } from "~atoms/background-head";
import { Grid } from "~atoms/grid";
import { Col } from "~atoms/grid/col";
import { Row } from "~atoms/grid/row";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { ScrollTopButton } from "~molecules/scroll-top-button";
import { HeaderContent, PageWrapper } from "./theme";

type TPageCol = {
    desktop?: number;
    desktopXL?: number;
    tablet?: number;
    mobile?: number;
};

type TPageProps = {
    mainContent: ReactNode;
    asideContent?: ReactNode;
    title?: ReactNode;
    headerWithImg?: boolean;
    defaultBackground?: boolean;
    headerImg?: string;
    headerBlock?: ReactNode;
    headerChildren?: ReactNode;
    headerBackground?: string;
    headerHeight?: string;
    col?: { mainContent?: TPageCol; asideContent?: TPageCol };
};

const Page = ({
    mainContent,
    asideContent,
    headerImg,
    headerWithImg,
    defaultBackground,
    headerBackground,
    headerHeight,
    headerBlock,
    headerChildren,
    title,
    col,
}: TPageProps) => {
    const { color } = useContext(ThemeContext);
    return (
        <PageWrapper className={"container-app"}>
            <BackgroundHead
                withImg={headerWithImg}
                img={headerImg}
                background={headerBackground}
                height={headerHeight}
                withDefaultBackground={defaultBackground}
            >
                {headerChildren}
            </BackgroundHead>
            <HeaderContent className="mb32 mb24--tablet mt16">
                {headerBlock}
                {!!title && (
                    <TitleAdaptive tag="h2" weight="medium" size="title38" color={color.text.inverted}>
                        {title}
                    </TitleAdaptive>
                )}
            </HeaderContent>
            <Grid className="mb64">
                <Row>
                    <Col
                        col={col?.mainContent?.desktop || (asideContent ? 9 : 12)}
                        desktopXL={col?.mainContent?.desktopXL || (asideContent ? 9 : 12)}
                        tablet={col?.mainContent?.tablet || 12}
                        mobile={col?.mainContent?.mobile || 12}
                    >
                        {mainContent}
                    </Col>
                    {!!asideContent && (
                        <Col
                            col={col?.asideContent?.desktop || 3}
                            desktopXL={col?.asideContent?.desktopXL || 3}
                            tablet={col?.asideContent?.tablet || 12}
                            mobile={col?.asideContent?.mobile || 12}
                        >
                            {asideContent}
                        </Col>
                    )}
                </Row>
            </Grid>
            <ScrollTopButton />
        </PageWrapper>
    );
};

export default Page;
