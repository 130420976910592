import { getMoneyFormat } from "~helpers/format";

export const topFields = (date: string, time: string) => {
    return [
        {
            className: "mb8",
            label: "Дата тиража:",
            value: date,
        },
        {
            className: "mb8",
            label: "Время тиража:",
            value: time,
        },
        {
            className: "",
            label: "Место проведения:",
            value: "г. Москва",
        },
    ];
};

export const bottomFields = (ticketCount: number, winAmount: number, jackpotAmount: number) => {
    return [
        {
            className: "mb12",
            label: `Число билетов, принявших участие в розыгрыше:`,
            value: ticketCount,
        },
        {
            className: "mb12",
            label: `Общая сумма выигрышей:`,
            value: `${getMoneyFormat(winAmount)} ₽`,
        },
        {
            className: "mb4",
            label: "Суперприз:",
            value: `${getMoneyFormat(jackpotAmount)} ₽`,
        },
    ];
};
