import styled from "styled-components";
import { Text } from "~atoms/text";
import { adaptive } from "~tokens/screen";
import { fontWeightBold } from "~tokens/typography";

export const TotalText = styled(Text)`
    font-size: 80px;
    line-height: 112px;
    font-feature-settings: initial;
    font-variant-numeric: initial;
    white-space: nowrap;

    ${fontWeightBold}
    ${adaptive.maxWidth.desktopXXL} {
        font-size: 55px;
        line-height: 77px;
    }
`;

export const PrizeFundWrapper = styled.section`
    display: block;
`;
