import styled from "styled-components";
import { BLACK, PURPLE } from "~tokens/colors";
import { INDENT } from "~tokens/sizes";

export const SocialMediaAreaWrapper = styled.div``;

export const SocialMediaIconsWrapper = styled.div`
    display: flex;
    justify-content: start;
`;

export const UlStyled = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: ${INDENT.none} -${INDENT.xxxs};
`;

export const LiStyled = styled.li`
    cursor: pointer;
    margin: ${INDENT.none} ${INDENT.xxxs};
    height: 39px;
`;

export const LinkStyled = styled.a`
    color: ${BLACK};
    position: relative;
    display: inline-block;

    &:visited {
        color: ${BLACK};
    }

    &:hover {
        color: ${PURPLE};
    }
`;
