import styled from "styled-components";
import { adaptive } from "~assets/styles/tokens/screen";
import { Skeleton } from "~atoms/skeleton";
import { INDENT } from "~tokens/sizes";

const HeaderBlock = styled.div``;

const LogoWrapper = styled.div`
    margin-right: ${INDENT.m};
`;

const TicketContent = styled.div``;

const SkeletonStyled = styled(Skeleton)`
    width: 100%;
    height: 668px;
    border-radius: 16px;

    ${adaptive.maxWidth.tablet} {
        height: 881px;
    }

    ${adaptive.maxWidth.desktopXXL} {
        height: 664px;
    }
`;

const TicketAside = styled.div`
    display: flex;
    flex-direction: column;
`;

const HeaderWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;

    ${adaptive.maxWidth.tablet} {
        flex-direction: column;
        align-items: flex-start;

        > ul {
            order: 2;
        }
    }
`;

export { HeaderWrap, TicketAside, TicketContent, SkeletonStyled, LogoWrapper, HeaderBlock };
