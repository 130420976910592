import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { WHITE } from "~assets/styles/tokens/colors";
import { INDENT } from "~assets/styles/tokens/sizes";
import { text12M } from "~assets/styles/tokens/typography";

export const StyledTooltip = styled(ReactTooltip)`
    &.custom-tooltip {
        max-width: 222px;
        ${text12M};
        color: ${({ theme }) => theme.color.text.primary};
        padding: ${INDENT.xxs};
        background-color: ${WHITE};
        border-radius: 8px;
        box-shadow: 0px 2px 15px 0px #00000026;
        opacity: 1 !important;
    }
`;
