import React from "react";
import { DefaultTheme } from "styled-components";
import { Status } from "~molecules/status";
import { TPaymentStatus } from "~types/my-tickets";
import { getStatusProps } from "./helpers";

type TPaymentBlock = {
    registrationStatus: TPaymentStatus;
    paymentStatus: TPaymentStatus;
    theme: DefaultTheme;
};

const PaymentBlock = ({ registrationStatus, paymentStatus, theme }: TPaymentBlock) => {
    const { color, text, iconColor, icon } = getStatusProps(registrationStatus, paymentStatus, theme);
    const Icon = icon;
    return (
        <>
            <Status size="text16" color={color} icon={<Icon />} iconColor={iconColor}>
                {text}
            </Status>
        </>
    );
};

export default PaymentBlock;
