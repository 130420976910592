import { useCallback } from "react";
import { isDateExpired } from "~helpers/format";
import { TBingoTypes, TEdition } from "~store/tickets/types";
import { TBingoAlias } from "~types/products";
import { otherErrors } from "~constants/error-messages";
import useAppApi from "~hooks/useAppApi";
import { useAppStore } from "~hooks/useAppStore";
import { useMutationHandles } from "~hooks/useMutationHandles";
import { useNotifications } from "~hooks/useNotifications";

type TChangesArray = {
    path: string;
    value: string | number | null | undefined | TEdition[] | TEdition;
};

export const useGetDraw = (alias: TBingoAlias, ticketType: TBingoTypes) => {
    const { handleFieldManyChange } = useMutationHandles("tickets");
    const { products } = useAppApi();
    const store = useAppStore();
    const { add } = useNotifications();
    const getDraw = useCallback(async () => {
        const { tickets } = store.getState();
        const changesArray: TChangesArray[] = [];

        let productId = tickets[ticketType].productId;

        const editionsSelected = tickets[ticketType].editionsSelected.filter((item) => !isDateExpired(item.saleDate));

        if (!productId) {
            const { result: fetchedProduct, error: errorFetchedProduct } = await products.getProductByAlias(alias);
            if (errorFetchedProduct) {
                add({ message: errorFetchedProduct?.data?.message ?? otherErrors.getProduct });
                return null;
            }
            productId = fetchedProduct && fetchedProduct.productId;
            if (!productId) return null;
        }

        const { result, error } = await products.getDraws(productId);
        if (error) {
            add({ message: error?.data?.message ?? otherErrors.getDraw });
            return null;
        }
        if (result.length) {
            changesArray.push({ path: `${ticketType}.editions`, value: result });
            editionsSelected.length
                ? changesArray.push({ path: `${ticketType}.editionsSelected`, value: editionsSelected })
                : changesArray.push({
                      path: `${ticketType}.editionsSelected`,
                      value: [result.find((item) => !isDateExpired(item.saleDate))!],
                  });
        }

        handleFieldManyChange(changesArray);
    }, [ticketType, store, products, alias, handleFieldManyChange, add]);

    return { getDraw };
};
