import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Grid } from "~atoms/grid";
import { Row } from "~atoms/grid/row";
import { TextAdaptive } from "~atoms/text-adaptive";
import { TitleAdaptive } from "~atoms/title-adaptive";
import { getMoneyFormat } from "~helpers/format";
import { PaymentIcons } from "./payment-icons";
import {
    ButtonStyled,
    ButtonText,
    BuyTicketsCardWrapper,
    CardContent,
    CardHeader,
    CardNumbers,
    CardNumbersBlock,
    CardTotal,
    ColStyled,
    LineDotted,
} from "./theme";

type TBuyTicketsCardView = {
    amount: number;
    handleBuyNow: () => void;
    className?: string;
    ticketsCount?: number;
    editionsCount?: number;
    withoutEdition?: boolean;
};

const BuyTicketsCardView = ({
    className,
    ticketsCount,
    editionsCount,
    amount,
    handleBuyNow,
    withoutEdition,
}: TBuyTicketsCardView) => {
    const { color } = useContext(ThemeContext);

    return (
        <BuyTicketsCardWrapper className={className}>
            <CardHeader>
                <TitleAdaptive tag="h2" weight="medium" size="title24" color={color.text.headline}>
                    выбрано
                </TitleAdaptive>
            </CardHeader>
            <CardContent>
                <CardNumbersBlock className="mb24 mb16--mobile">
                    <CardNumbers className="mb24 mb16--tablet">
                        <TextAdaptive size="text16" tag="span" className="mr4" color={color.text.primary}>
                            Билетов
                        </TextAdaptive>
                        <LineDotted />
                        <TextAdaptive size="text16" tag="p" className="ml4 mr0--tablet" color={color.text.primary}>
                            {ticketsCount}
                        </TextAdaptive>
                    </CardNumbers>
                    {!withoutEdition && (
                        <CardNumbers className="mb24 mb16--mobile">
                            <TextAdaptive size="text16" tag="span" className="mr4" color={color.text.primary}>
                                Количество тиражей
                            </TextAdaptive>
                            <LineDotted />
                            <TextAdaptive
                                size="text16"
                                tag="span"
                                className="ml4 mr0--tablet"
                                color={color.text.primary}
                            >
                                {editionsCount}
                            </TextAdaptive>
                        </CardNumbers>
                    )}
                </CardNumbersBlock>
                <CardTotal className="mb20 mb12--mobile">
                    <TitleAdaptive tag="h2" size="title24" weight="medium" color={color.text.primary}>
                        Итого
                    </TitleAdaptive>
                    <TitleAdaptive tag="h2" size="title24" weight="medium" color={color.text.primary}>
                        {getMoneyFormat(amount)} ₽
                    </TitleAdaptive>
                </CardTotal>
                <Grid className="mt12--tablet">
                    <Row>
                        <ColStyled col={12} tablet={6} mobile={12} className="mauto--tablet">
                            <ButtonStyled size="large" intent onClick={handleBuyNow} className="mb4">
                                <ButtonText tag="span" weight="medium" size="text16">
                                    Оплатить сейчас
                                </ButtonText>
                            </ButtonStyled>
                            <PaymentIcons />
                        </ColStyled>
                    </Row>
                </Grid>
            </CardContent>
        </BuyTicketsCardWrapper>
    );
};

export default BuyTicketsCardView;
