import { useCallback, useContext } from "react";
import Loader from "~atoms/loader/loader";
import { ModalWindowContext } from "~molecules/modal-window/modal-window";
import { EmailBlock } from "~organisms/buy-tickets-card/email-block";
import { WidgetBlock } from "~organisms/buy-tickets-card/widget-block";
import { useGetBalance } from "~pages/profile/_hooks/useGetBalance";
import { useSendPayout } from "~pages/wallet/_hooks/useSendPayout";
import { isIdentityAccepted } from "~pages/wallet/validations";
import { VerificationBlock } from "~pages/wallet/verification-block";
import { otherErrors, payoutStatus } from "~constants/error-messages";
import { useAppSelector } from "~hooks/useAppSelector";
import { useNotifications } from "~hooks/useNotifications";

export const usePayoutLogic = () => {
    const { sendPayout } = useSendPayout();
    const { handleGetBalance } = useGetBalance();
    const { add } = useNotifications();
    const { onClose, setContent } = useContext(ModalWindowContext);
    const initProfile = useAppSelector((state) => state.profile.data !== null);
    const email = useAppSelector((state) => state.profile.data?.email);
    const identity = useAppSelector((state) => state.profile.data?.identity);

    const handleEmailChangeSuccess = useCallback(() => {
        onClose();
    }, [onClose]);

    const handleWidgetSuccess = useCallback(() => {
        void handleGetBalance();
    }, [handleGetBalance]);

    const handlePayout = useCallback(
        async (amount: string) => {
            setContent({
                component: Loader,
            });

            if (!initProfile || !identity) return add({ message: otherErrors.getProfile });

            if (!email) {
                return setContent({
                    component: EmailBlock,
                    props: { email, handleSuccess: handleEmailChangeSuccess },
                    options: { btnClose: true, onClickClose: onClose },
                });
            }

            if (!isIdentityAccepted(identity?.basic) && !isIdentityAccepted(identity?.full)) {
                return setContent({
                    component: VerificationBlock,
                    props: {
                        code: payoutStatus.VERIFICATION_MISSED.code,
                    },
                    options: { btnClose: true, onClickClose: onClose, borderRadius: 16, btnVariant: "secondary" },
                });
            }
            const payoutAmount = Number(amount);

            const { error, result } = await sendPayout("CARD4LOT", payoutAmount);
            if (error) {
                add({ message: error?.data?.message ?? otherErrors.sendPayout });
                return onClose();
            }
            if (result && result.paymentToken && result.paymentId) {
                const { paymentId, paymentToken } = result;
                setContent({
                    component: WidgetBlock,
                    props: { paymentId, paymentToken, handleSuccess: handleWidgetSuccess },
                    options: { onClickClose: onClose },
                });
            }
        },
        [
            add,
            email,
            handleEmailChangeSuccess,
            handleWidgetSuccess,
            identity,
            initProfile,
            onClose,
            sendPayout,
            setContent,
        ],
    );

    return { handlePayout };
};
