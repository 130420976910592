import React from "react";
import classNames from "classnames";
import { adapters } from "~adapters";
import { DigitalCombinations } from "~organisms/digital-combinations";
import { TMyTicketParsed } from "~store/my-tickets/types";
import { TBingoFields } from "~store/tickets/types";
import { TProductType, TAlias, TBingoAlias } from "~types/products";
import { BingoCombo } from "./bingo";
import { getBingoBox } from "./helpers";
import { CombinationInfoWrapper, LogoImg, BingoWrap } from "./theme";

type TTicketPageProps = {
    type: TProductType;
    alias: TAlias;
    logo?: string;
    className?: string;
} & Pick<TMyTicketParsed, "numbers" | "numbersWin">;

const TicketHead = ({ className, logo, numbers, numbersWin, type, alias }: TTicketPageProps) => {
    const digital = type === "DIGITAL";
    const options = getBingoBox(digital, alias);
    const parsedNumbers = !digital ? adapters.products.getBingoMapping[alias as TBingoAlias](numbers[0]) : numbers;
    const parsedWinNumbers = !digital
        ? adapters.products.getBingoMapping[alias as TBingoAlias](numbersWin[0])
        : numbersWin;
    return (
        <CombinationInfoWrapper className={className} $alias={alias} $type={type}>
            <LogoImg
                className={classNames({ "mb32 mb12--mobile dg-img": digital, "mb16 mb8--mobile": !digital })}
                src={logo}
                alt="Логотип"
                $type={type}
            />
            {digital ? (
                <DigitalCombinations
                    numbers={parsedNumbers as number[][]}
                    numbersWin={parsedWinNumbers as number[][]}
                />
            ) : (
                <BingoWrap alias={alias}>
                    <BingoCombo
                        main={(parsedNumbers as TBingoFields).main}
                        sub={(parsedNumbers as TBingoFields).sub}
                        selectNumbers={(parsedWinNumbers as TBingoFields).main}
                        options={options}
                    />
                </BingoWrap>
            )}
        </CombinationInfoWrapper>
    );
};

export default TicketHead;
